<template>
  <div class="dnd">
    <div
      class="dragndrop"
      @dragover.prevent="enter"
      @dragenter.prevent="enter"
      @dragleave.prevent="leave"
      @dragend.prevent="leave"
      @drop.prevent="drop"
      :class="{ 'dragndrop--dragged': isDraggedOver }"
    >
      <form>
        <input
          id="file"
          type="file"
          name="files[]"
          class="dragndrop__input"
          @change="select"
          ref="input"
          multiple
        />
        <label
          for="file"
          class="dragndrop__header"
          :class="{ 'dragndrop__header--compact': files.length > 0 }"
        >
          <strong>Drag files here</strong> or click to select files.
        </label>
      </form>
    </div>
    <uploads :files="files"></uploads>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Uploads from "./Uploads.vue";

import { Evt } from "../../lib/helpers/Evt.js";
import _ from "underscore";
import { parseErrors } from "../../lib/helpers/function.js";
import { mapActions } from "vuex";

let CancelToken = axios.CancelToken;

export default {
  props: ["params"],
  data() {
    return {
      files: [],
      isDraggedOver: false,
      expiryTime: null,
      breakUpload: false,
    };
  },
  mounted() {
    Evt.listen("set-expiry-date", (expiryTime) => {
      this.expiryTime = expiryTime;
      console.log("after listen: ", this.expiryTime);
    });

    Evt.listen("file-delete", (fileObject) => {
      console.log("REMOVED FILE WITH HASH ID: ", fileObject.id);
      let idx = _.findIndex(this.files, (file) => {
        return file.id === fileObject.id;
      });
      this.files.splice(idx, 1);
      // TODO should also now cancel further upload for this file
    });
  },
  methods: {
    ...mapActions(["postDocumentAction"]),
    enter() {
      this.isDraggedOver = true;
    },

    leave() {
      this.isDraggedOver = false;
    },

    drop(e) {
      this.leave();
      this.addFiles(e.dataTransfer.files);
    },

    select() {
      this.addFiles(this.$refs.input.files);
      this.$refs.input.value = "";
    },

    addFiles(files) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.storeMeta(file);
      }
    },
    cancelAll() {
      console.log("UPLOAD FORM CMPNT: cancelling all files");
      this.files.forEach((file) => {
        if (!file.finished) {
          // file.canceller('User cancelled upload for all files.')
          this.$set(file, "cancelled", true);
        }
      });
    },
    removeAll() {
      this.breakUpload = true;
      this.files = [];
      this.cancelAll();
      setTimeout(() => {
        this.breakUpload = false;
      }, 800);
    },

    async uploadStart(ctx) {
      this.$emit("upload-change", true);
      for (const file of this.files) {
        /**
         * When clicking upload all, upload only files
         * not previously marked as finished.
         */
        if (this.breakUpload) {
          break;
        }
        if (!file.finished && !file.cancelled /* || !file.inProgress */) {
          this.removePreviousFileStyles(file.id);
          try {
            const res = await this.upload(file, ctx);
            if (res.data) {
              // TODO see why it enters here on error
              Evt.fire("shouldRefreshFilesUploaded", res.data);
            }
          } catch (thrown) {
            if (axios.isCancel(thrown)) {
              console.log("Request cancelled", thrown.message);
            } else {
              // handle error
              console.log(thrown);
            }
            break;
          }
        }
      }
      // Enable the button again
      this.$emit("upload-change", false);
    },
    async upload(fileObject, ctx) {
      console.info("uploaded started...");

      let form = new FormData();

      form.append("files[]", fileObject.file);
      form.append("id", fileObject.id);

      /**
       * Send expiry time along with files it it exists!
       */
      if (!_.isNull(this.expiryTime)) {
        form.append("expiry_date", this.expiryTime);
      }

      // emit upload init event...
      Evt.fire("fileUploadInit");

      // switch url based on role
      if (this.$can("upload-document-files")) {
        let url =
          this.$user.role === "temp"
            ? `documenttypes/${this.params.docId}/upload-files`
            : `documents/${this.params.docId}/temps/${this.params.tempId}/upload-files`;
        console.log("User uploading file has role of: ", this.$user.role);
        try {
          const params = {
            url,
            form,
            config: {
              params: { include: "files, filesCount" },

              cancelToken: new CancelToken(function (cancel) {
                fileObject.canceller = cancel;
              }),

              onUploadProgress: (progressEvent) => {
                Evt.fire("fileUploadProgress", fileObject, progressEvent);
              },
            },
          };
          const response = await this.postDocumentAction(params);
          Evt.fire("fileUploadFinished", fileObject);
          return response;
        } catch (err) {
          // Emit event for failed!
          ctx.errors.record(err);
          Evt.fire("fileUploadFailed", fileObject);

          let errs = parseErrors(err);
          this.$toasted.error(errs).goAway(4500);

          throw err;
          // return err // special type of axios Cancel
        }
      }
    },

    removePreviousFileStyles(fileId) {
      this.files.forEach((file) => {
        if (file.id === fileId) {
          this.$set(file, "cancelled", false);
          file.failed = false;
          file.finished = false;
          file.inProgress = false;
          return;
        }
      });
    },

    /**
     * Collect some useful data that we might need in UI
     * and embrace it under fileObject
     */
    storeMeta(file) {
      // create file object
      let fileObject = this.generateFileObject(file);

      fileObject.id = uuidv4();
      return fileObject;
    },

    generateFileObject(file) {
      /**
       * Add to local state files variable
       * and at the same time return fileObject from its position
       */
      let fileObjectIndex =
        this.files.push({
          id: null,
          file: file,
          progress: 0,
          failed: false,
          loadedBytes: 0,
          totalBytes: 0,
          timeStarted: new Date().getTime(),
          secondsRemaining: 0,
          finished: false,
          inProgress: false,
          cancelled: false,
          canceller: null,
        }) - 1;

      /**
       * Return the newly created file object...
       */
      return this.files[fileObjectIndex];
    },
  },

  components: { Uploads },
};
</script>
<style scoped>
.dragndrop {
  --dragndrop-min-height: 200px;
  width: 100%;
  min-height: var(--dragndrop-min-height);
  margin-top: 1em;
  background-color: #f8f8f8;
  position: relative;
  border: 3px dashed rgba(0, 0, 0, 0.2);
}

.dragndrop--dragged {
  border-color: #333;
}

.dragndrop__input {
  display: none;
}

.dragndrop__header {
  display: block;
  font-family: Arial, sans-serif;
  font-size: 1.1em;
  color: #555;
  text-align: center;
  margin: calc(var(--dragndrop-min-height) / 2) 20px;
}

.dragndrop__header:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dragndrop__header--compact {
  margin: calc(var(--dragndrop-min-height) / 4) 20px;
}
</style>
