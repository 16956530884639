<template lang='pug'>
.client-invoice-master(v-if="$can('view-client-custom-id')")
  .field
    label.label Client id
    input.input(
      type="number",
      placeholder="Please enter Client id here",
      v-model.number="clientForm.custom_client_id",
      :disabled="isDisabledClientId",
      @input="errors.clear('custom_client_id')"
    )
  //- span.help.is-danger(
  //-   v-if="errors.has('custom_client_id')",
  //-   v-html="errors.get('custom_client_id')"
  //- )
  button.button.is-generic-app-teal.is-caps-lock.is-tiny(
    :disabled="!isChangedClientId",
    v-if="isVisiblePatching",
    @click="onSaveClientId"
  )
    span Save master client id
</template>

<script>
import { mapActions } from 'vuex';
import { Errors } from '../../../lib/helpers/Errors';
export default {
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    isVisiblePatching: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      clientForm: {},
      errors: new Errors(),
    };
  },
  computed: {
    isDisabledClientId () {
      return !this.$can('edit-client-custom-id');
    },
    isChangedClientId () {
      return this.clientData.custom_client_id !== this.clientForm.custom_client_id;
    },
  },
  mounted () {
    this.setupClientDataForm();
  },
  methods: {
    ...mapActions({
      putClientData: "locationClientManagement/putClientData",
    }),
    setupClientDataForm () {
      this.clientForm = { ...this.clientData };
    },
    async onSaveClientId () {
      const params = {
        clientId: this.clientData.id,
        params: {
          custom_client_id: this.clientForm.custom_client_id,
        },
      };
      try {
        await this.putClientData(params);
        this.$toasted.success("Client updated").goAway(2000);
        this.isSavingClient = false;
        this.isLockedInputs = true;
        this.$emit('client-updated', this.clientData);
      } catch (err) {
        this.$toasted.error("Failed updating client data").goAway(2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.client-invoice-master {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}
</style>