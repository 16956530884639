<template lang="pug">
.copy-holiday-wrapper
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .text-message 
    p {{ textMessage }}
  .from-to 
    .from
      label.label Copy from year
        //- input.input(
        //-   placeholder="Please enter the year you wish to copy",
        //-   v-model="from",
        //-   maxlength="4"
        //- )
      multi-select#input(
        :options="filtratedYears",
        track-by="id",
        label="label",
        v-model="selectedYear",
        placeholder="Please select the year you wish to copy",
        :multiple="false",
        :close-on-select="true"
      )
    .to
      label.label New year
      input.input(
        placeholder="Please enter the year you wish to create",
        v-model="to",
        maxlength="4",
        @input="checkYearInput"
      )
  .warn-msg 
    p {{ wrnMsg }}
  .submit 
    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      @click="showWarningMessage",
      :disabled="isDisabled"
    )
      span Submit
    button.button.is-outlined.is-caps-lock(@click="callModalClose") Cancel
</template>

<script>
import { Errors } from "../../../lib/helpers/Errors.js";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function.js";
import MultiSelect from "vue-multiselect";

export default {
  props: ["modalProps"],
  components: {
    MultiSelect,
  },
  data() {
    return {
      selectedYear: undefined,
      from: "",
      to: "",
      isDisabled: true,
      wrnMsg: "",
      textMessage:
        "Please select the year from which you wish to copy the list of public holiday dates. Then, enter the year which you wish to copy the public holidays to. This will create the new public holidays in the system, which can be edited if needed. You cannot copy public holidays to a past year.",
    };
  },
  computed: {
    filtratedYears() {
      return this.modalProps.years.map((year, index) => {
        return { id: index, label: year };
      });
    },
    displayYear() {
      return this.selectedYear && this.selectedYear.label
        ? this.selectedYear.label
        : "";
    },
    isYearInputValid() {
      const currentYear = new Date().getFullYear();
      if (this.to.length === 4 && !isNaN(this.to) && this.to >= currentYear) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      copyHolidays: "copyHolidays",
    }),
    callModalClose() {
      this.$emit("cancel-modal");
    },
    async copyPublicHoliday() {
      try {
        const params = {
          from: this.displayYear,
          to: this.to,
        };
        this.modalProps.newYear = this.to;
        const res = await this.copyHolidays(params);
        this.callModalClose();
        this.$emit("refresh-data", this.modalProps);
      } catch (err) {
        if (err.response.data.message) {
          this.warnMsg = err.response.data.message;
          return;
        }
        const errs = parseErrors(err);
        this.wrnMsg = errs;
      }
    },
    checkYearInput() {
      this.isDisabled = !this.isYearInputValid;
    },
    showWarningMessage() {
      return new Promise(() => {
        const alert = {
          title: this.modalProps.warnMsg,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: this.copyPublicHoliday,
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-holiday-wrapper {
  display: flex;
  flex-direction: column;
  .text-message {
    text-align: center;
    padding: 10px 50px 5px 50px;
  }
  .from-to {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px 0 15px 0;
    .from {
    }
    .to {
      .input {
        min-height: 39px;
        min-width: 277px;
      }
    }
  }
  .warn-msg {
    color: red;
    display: flex;
    justify-content: center;
  }
  .submit {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0 5px 0;
  }
}
</style>
