var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"password-policy-wrapper"},[_c('span',{staticClass:"validation-new-password"},[_c('p',{class:{
          correct: _vm.passwordValidationStore.passwordValidation.length.isValid && _vm.allValidate,
          inInputNotCorrect: !_vm.passwordValidationStore.passwordValidation.length.isValid && _vm.allValidate,
        }},[_vm._v("8 chars ")]),_c('p',{class:{
          correct: _vm.passwordValidationStore.passwordValidation.upperCase.isValid && _vm.allValidate,
          inInputNotCorrect: !_vm.passwordValidationStore.passwordValidation.upperCase.isValid && _vm.allValidate,
        }},[_vm._v(" Uppercase letter ")]),_c('p',{class:{
          correct: _vm.passwordValidationStore.passwordValidation.lowerCase.isValid && _vm.allValidate,
          inInputNotCorrect: !_vm.passwordValidationStore.passwordValidation.lowerCase.isValid && _vm.allValidate,
        }},[_vm._v(" Lowercase letter ")]),_c('p',{class:{
          correct: _vm.passwordValidationStore.passwordValidation.number.isValid && _vm.allValidate,
          inInputNotCorrect: !_vm.passwordValidationStore.passwordValidation.number.isValid && _vm.allValidate,
        }},[_vm._v(" Number ")]),_c('p',{class:{
          correct:
            _vm.passwordValidationStore.passwordValidation.specialCharacter.isValid && _vm.allValidate,
            inInputNotCorrect: !_vm.passwordValidationStore.passwordValidation.specialCharacter.isValid && _vm.allValidate,
        }},[_vm._v(" Special char")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }