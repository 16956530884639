<template lang="pug">
.rates-manage-default-rates
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .top
    .bar.left
      .control(v-if="canDisplayFilter('client', type)")
        template
          label.label {{ getFilterData("client", type).label }}
          //- style="width: 350px",
          multi-select#client.client-select(
            :options="clients",
            :searchable="true",
            :options-limit="999",
            select-label="",
            track-by="id",
            label="name",
            :loading="isLoadingClients",
            :multiple="false",
            :value="selectedClient",
            :placeholder="getFilterData('client', type).placeholder",
            @select="onSelectClient",
            @remove="removeSelectedClient"
          ) 
      .control(
        v-if="canDisplayFilter('client_child', type) && selectedClient && type.unique_code === 'GLBNRML'"
      )
        //- v-if="selectedClient && type.unique_code === 'GLBNRML'"
        label.label {{  getFilterData("client_child", type)?.label || 'Specific client'  }}
        multi-select#client.client-select(
          :options="clientsUsingRates",
          :searchable="true",
          :options-limit="999",
          select-label="",
          track-by="id",
          label="name",
          :loading="isLoadingClientUsing",
          :multiple="false",
          :placeholder="getFilterData('client_child', type)?.placeholder || 'Specific client'"
        )
      .control(v-if="isShouldShowRates && canDisplayFilter('category', type)")
        template
          label.label {{ getFilterData("category", type).label }}
          multi-select.category-select(
            :options="rateCategories",
            :searchable="true",
            :options-limit="999",
            select-label="",
            selected-label="",
            deselect-label="",
            track-by="id",
            label="name",
            :multiple="false",
            :loading="!rateCategories.length",
            :value="selectedColumnCategoryOption",
            :placeholder="getFilterData('category', type).placeholder",
            @input="onChangeFilterCategory"
          )
      .control.active-date(v-if="isShouldShowRates")
        label.label Active date
        uni-date-picker(
          v-model="activeDateRate",
          :prop-config="dateConfig",
          placeholder="Enter date"
        )
      button.button.is-generic-app-teal(@click="onResetActiveDate")
        span Reset date
      template(v-if="canShowRateModal && isShouldShowRates")
        button.button.is-generic-app-teal(@click="onChangeRateModal") 
          //- |INPUT VALUES
          | Bulk input
      template
        button.button.is-generic-app-teal(
          @click="reviewRates(false)",
          :disabled="!showReviewRates"
        ) 
          | Review active rates
          //- beli
      template
        button.button.is-generic-app-teal(
          @click="reviewRates(true)",
          :disabled="!showReviewRates"
        ) 
          | Review pending rates
          //-  zuti
      //- template(
      //-   v-if="isShouldShowRates && maxEffectiveDate && !isLoadingMaxEffectiveDate && canDisplayFilter('maxEffectiveDate')"
      //- )
      //-   p.control
      //-     label.label Max effective date
      //-     span {{ formatDateToLocal(maxEffectiveDate) }}
      //- .loading-wrapper.mer(v-if="isLoadingMaxEffectiveDate")
      //-   img(src="@/assets/images/comps/loader.svg")
    .bar.right(v-if="isShouldShowRates")
      .switch-placeholder
        .control.action.switch-changed-only
          .switch-wrap
            label.lbl Show changed rates only
            switches(
              v-model="isShowChangedOnly",
              :disabled="isChangingRowsDisplay",
              theme="bulma",
              color="blue",
              :emitOnMount="false"
            )
          .loader(v-if="isChangingRowsDisplay")
      template(v-if="isAddingNewRow")
        p.control.action
          button.button.is-generic-app-teal(
            @click="saveRow",
            title="Apply and save pending row ('CTRL + ALT + S')"
          ) Save
        p.control.action
          button.button.is-generic-app-teal(@click="isAddingNewRow = false") Cancel
      p.control.action.action-2
        button.button.is-generic-app-teal(
          title="Create a new rate ('CTRL + ALT + N')",
          :disabled="isAddingNewRow",
          @click="addNewRateRow"
        )
          img(src="@/assets/images/comps/Plus.svg")
          span(style="margin-left: 5px") New Rate
      .control.action
        .action-col
          button.button.is-generic-app-teal(
            :disabled="!canApplyChanges",
            @click="onApplyChanges"
          ) Activate Rates
  .filters-2
    .right-side
      button.button.is-danger.is-low.is-tiny(
        v-if="undoPendingRates.length",
        @click="onUndoMultipleRates"
      )
        span(style="margin-left: 5px") Undo selected rates
  .bottom
    rates-manage-rates-table(
      :key="uniqueRatesTableKey",
      :sector="sector",
      :client="selectedClient",
      :categoryId="selectedColumnCategoryOption?.id",
      :row-data="rowData",
      :is-adding-row="isAddingNewRow",
      :fetch-rate-data-co="fetchRateDataCo",
      :new-created-row="newCretedRow",
      :show-changed-only="isShowChangedOnlyDelayed",
      :type="type",
      :rate-date-selected="activeDateRate",
      :undoPendingRates="undoPendingRates",
      v-if="isShouldShowRates",
      @rate-pending-change="canApplyChanges = $event",
      @change-subcategory="onChangeSubcategory",
      @added-new-row="addedNewRowAfterPost",
      @change-pending-undo-rates="onChangePendingUndoRates"
    )
    div(v-if="selectedClient && selectedClient.cli_id_useratesfrom") 
      | This client is using rates from a global rate file which can be altered #[a(:data-id="selectedClient.cli_id_useratesfrom", @click="changeSelectedClient(selectedClient)") here]
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    icon="",
    :size="modalSize",
    :scrollable="true"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      :client="selectedClient",
      :type="type",
      @cancel-modal="modalVisible = false",
      @rates-updated="onRatesUpdated",
      @rates-finalized="onRatesFinalized"
    )
</template>

<script>
import MultiSelect from "vue-multiselect";
import RatesManageRatesTable from "./RatesManageRatesTable.vue";
import RatesManageFinalize from "./RatesManageFinalize.vue";
import RatesChangeValueModal from "./RatesChangeValueModal.vue";
import { mapActions, mapState } from "vuex";
import { parseErrors } from "@/lib/helpers/function";
import { getFormattedDateTime } from "@/lib/helpers/helpers";
import { SHIFT_TYPES } from "../../../../shared/mapData";
import {
  canDisplayColumn,
  canDisplayFilter,
  getFilterData,
} from "@/components/Rates/components/Rates/RatesTableFuncs";
import store from "@/vuex/store.js";
import apiTs from "@/api/apiTs";

export default {
  name: "ratesManageRates",
  components: {
    MultiSelect,
    RatesManageRatesTable,
    RatesManageFinalize,
    RatesChangeValueModal,
  },
  props: {
    rateDateSelected: String,
    allRateTypes: Array,
    sector: [Object, null],
    type: Object,
  },
  data() {
    return {
      undoPendingRates: [],
      selectedClient: null,
      isAddingNewRow: false,
      canApplyChanges: false,
      rowData: {},
      clients: [],
      fetchRateDataCo: 0,
      newCretedRow: null,
      // maxEffectiveDate: null,
      lastUsedParamsEffectiveDate: {},
      isShowChangedOnly: false,
      isShowChangedOnlyDelayed: false,
      isChangingRowsDisplay: false,
      isLoadingClients: false,
      // isLoadingMaxEffectiveDate: false,
      modalVisible: false,
      ratesData: null,
      canLoadRateTable: false,
      dateConfig: {
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        allowInput: false,
        enableTime: false,
      },
      activeDateRate: null,
      canDisplayFilter,
      getFilterData,
      loadingReviewRates: false,
      isLoadingClientUsing: false,
      clientsUsingRates: [],
    };
  },
  computed: {
    ...mapState({
      rateCategories: (state) => state.rateCategories,
    }),
    showReviewRates() {
      // this.$can('manage-rates')
      // console.log(this.selectedClient);
      // console.log(this.type);
      if (this.loadingReviewRates) {
        return !this.loadingReviewRates;
      } else if (this.type?.type !== "NRML") {
        return true;
      } else if (this.type?.type === "NRML" && this.selectedClient) {
        return true;
      } else return false;
    },
    isShouldShowRates() {
      if (!this.activeDateRate || !this.canLoadRateTable) return false;
      if (this.canDisplayFilter("client", this.type) && !this.selectedClient) {
        // Don't load rates because if client exists, it's mandatory
        return false;
      }

      if (this.type.type === SHIFT_TYPES.normal.key) {
        return !this.selectedClient?.cli_id_useratesfrom;
      }
      return true;
    },
    selectedColumnCategoryOption() {
      const categoryId = this.$route.params.cat_id;
      if (categoryId) {
        return this.rateCategories.find(
          (rc) => rc.id === parseInt(categoryId, 10)
        );
      }
      return null;
    },
    uniqueRatesTableKey() {
      return `${this.type.type}-${this.activeDateRate}-${
        this.selectedClient?.id || 0
      }`;
    },
    canShowRateModal() {
      if (!this.type?.columns) return false;
      const hasAnyMultiVisible = Object.values(this.type.columns).some(
        (colVal) => colVal.multi?.visible
      );
      return hasAnyMultiVisible;
    },
  },
  watch: {
    isAddingNewRow(val) {
      if (!val) {
        this.rowData = {};
      }
    },
    isShowChangedOnly(val) {
      if (val) {
        this.isShowChangedOnlyDelayed = val;
      } else {
        this.isChangingRowsDisplay = true;

        setTimeout(() => {
          // First timeout is needed because loader needs to be displayed before filtering function occurs
          this.isShowChangedOnlyDelayed = val;
          setTimeout(() => {
            // Second timeout is used for when the filter rows rendering is done
            this.isChangingRowsDisplay = false;
          }, 1000);
        }, 200);
      }
    },
  },
  mounted() {
    this.rateDateSelected && (this.activeDateRate = this.rateDateSelected);
    this.tryGetClients();
    // if (this.type.type === SHIFT_TYPES.oncall.key) {
    //   this.fetchEffectiveDate();
    // }
    document.addEventListener("keydown", this.addNewRowShortcut);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.addNewRowShortcut);
  },
  methods: {
    ...mapActions({
      getRatesClients: "getRatesClients",
      getGlobalRatesClients: "getGlobalRatesClients",
      postRates: "postRates",
      postGlobalRates: "postGlobalRates",
      // getMaxEffectiveDate: "getMaxEffectiveDate",
      reviewRatesM: "reviewRates",
      reviewRatesGlobal: "reviewRatesGlobal",
    }),
    async fetchClientUsingRates(client) {
      this.isLoadingClientUsing = true;
      const params = {
        clientId: client.id,
        type: this.type.type,
      };
      try {
        const res = await apiTs.getClientUsingRates(params);
        this.clientsUsingRates = res.data.data;
      } catch (err) {
        console.warn(err.message);
      } finally {
        this.isLoadingClientUsing = false;
      }
    },
    async tryGetClients() {
      if (this.type.type === SHIFT_TYPES.normal.key) {
        this.isLoadingClients = true;
        const params = {
          // includes: ['locations'].join(','),
          type: this.type.type,
          unique_code: this.type.unique_code,
          per_page: 999,
        };

        const getApiActionKey =
          this.type.unique_code !== "GLBNRML"
            ? "getRatesClients"
            : "getGlobalRatesClients";
        try {
          const res = await this[getApiActionKey](params);
          this.clients = prepareClients(res.data.data);
          this.checkSetClientFromUrl(this.$route.params.client_id);
          this.isLoadingClients = false;
        } catch (err) {
          this.isLoadingClients = false;
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(2500);
        }
      }
      this.canLoadRateTable = true;
    },
    async addNewRowShortcut(evt) {
      if (this.isShouldShowRates) {
        // Shows the dropdown menu after displaying
        if (evt.ctrlKey && evt.altKey && evt.code === "KeyN") {
          this.isAddingNewRow = true;
        } else if (evt.ctrlKey && evt.altKey && evt.code === "KeyS") {
          const isOk = await this.saveRow();
          if (isOk) {
            this.isAddingNewRow = true; // Reopen adding new row
          }
        }
      }
    },
    onChangeRateModal() {
      this.showModal(
        "rates-change-value-modal",
        { data: { AA: 23 } },
        "Update input values",
        800
      );
    },
    onResetActiveDate() {
      this.activeDateRate = this.rateDateSelected;
    },
    async reviewRates(e = false) {
      this.loadingReviewRates = true;
      // console.log(e);
      const params = {
        // set params - kada se dobije info o apiju

        // if you want report with yellow rates you should send true else send false
        for_pending_rates: e,
        type: this.type.type,
        date: this.activeDateRate,
      };
      if (this.type.type === "NRML") {
        params.cli_id = this.selectedClient.id;
      }
      //  set api link kada se dobije sa beka u reviewRates 410 redu api/index.js
      try {
        let res = null;
        if (this.type.unique_code === "GLBNRML") {
          res = await this.reviewRatesGlobal(params);
        } else {
          res = await this.reviewRatesM(params);
        }
        if (res)
          this.downloadCsvFile(
            res.data,
            res.headers["content-type"],
            res.headers["x-multi-file-name"]
          );
      } catch (err) {
        console.log(err);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      } finally {
        this.loadingReviewRates = false;
      }
    },
    downloadCsvFile(file, type, name = "file.xlsx") {
      // // const csvContent = file.map(e => e.join(",")).join("\n");
      // const blob = new Blob([file], { type: 'text/csv;charset=utf-8;' });
      // const encodedUri = URL.createObjectURL(blob);
      const blob = new Blob([file], {
        type: type, // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const encodedUri = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // async fetchEffectiveDate(payload = {}) {
    //   const params = {
    //     ...this.lastUsedParamsEffectiveDate,
    //     cat_id_parent:
    //       this.selectedColumnCategoryOption &&
    //       this.selectedColumnCategoryOption.id,
    //     cat_id:
    //       payload.subCatId !== undefined
    //         ? payload.subCatId
    //         : this.lastUsedParamsEffectiveDate.cat_id,
    //     type: this.type.type,
    //   };

    //   if (this.type.type === SHIFT_TYPES.normal.key) {
    //     params.cli_id = this.selectedClient?.id;
    //     params.inc_id = null; // Unused
    //   }

    //   this.lastUsedParamsEffectiveDate = params;
    //   this.isLoadingMaxEffectiveDate = true;
    //   try {
    //     const res = await this.getMaxEffectiveDate(params);
    //     this.maxEffectiveDate = res.data.rat_from;
    //     this.isLoadingMaxEffectiveDate = false;
    //   } catch (err) {
    //     this.isLoadingMaxEffectiveDate = false;
    //     this.maxEffectiveDate = null;
    //     console.log("Error", err.message);
    //   }
    // },
    onChangeSubcategory(evt) {
      const payload = { subCatId: evt?.id };
      // this.fetchEffectiveDate(payload);
    },
    onChangeFilterCategory(evt) {
      console.log("[Cat] Dropdown change", evt);
      const id = evt ? evt.id : undefined;
      this.$router
        .push({
          params: {
            cat_id: id,
          },
        })
        .catch({});
      // this.fetchEffectiveDate();
    },
    // formatDateToLocal(dateObj) {
    //   return getFormattedDateTime(dateObj.date);
    // },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = 650
    ) {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalSize = modalSize;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    confirmDelete(name = `this change`) {
      return new Promise((resolve) => {
        const alert = {
          title: `Do you wish to undo "${name}"?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
    async onUndoMultipleRates() {
      const cliId = this.selectedClient?.id;
      const payload = {
        type: this.type.type,
        cli_id: cliId,
        rates: this.undoPendingRates.map((id) => ({ id })),
      };
      const apiName =
        this.type.unique_code !== "GLBNRML"
          ? "deleteMultipleSpecificRates"
          : "deleteMultipleGlobalRates";
      try {
        await this.confirmDelete();

        const res = await store.dispatch(apiName, payload);
        const msg = res.data?.message || "";
        this.$toasted.success(msg).goAway(2500);
        this.undoPendingRates = [];
        this.onRatesUpdated();
      } catch (err) {
        console.warn(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    async onApplyChanges() {
      // FINALIZE
      this.isAddingNewRow = false;

      this.showModal(
        "rates-manage-finalize",
        {},
        "Finalize and activate rates"
      ); // old "Finalize and apply changes"
    },
    async saveRow() {
      // POST
      // console.log('rowdata', this.rowData);

      const params = {
        cat_id:
          this.rowData.cat_id && typeof this.rowData.cat_id === "object"
            ? this.rowData.cat_id.id
            : this.rowData.cat_id,
        rat_code: this.rowData.rat_code,
        rat_key: this.rowData.rat_key,
        rat_payscale: this.rowData.rat_payscale,
        rat_salary: this.rowData.rat_salary,
        rat_nethours: this.rowData.rat_nethours,
        rat_aldays: this.rowData.rat_aldays,
        rat_phdays: this.rowData.rat_phdays,
        type: this.type.type,
      };

      if (this.type.type === SHIFT_TYPES.normal.key) {
        params.cli_id = this.selectedClient.id;
        params.inc_id = this.rowData.inc_id?.id || this.rowData.inc_id;
      }

      // Used for sending dynamic column value for POST
      // if (this.rowData.special) {
      //   // Dynamic column
      //   params.cit_id = this.rowData.special.id
      //   params.rvl_value = this.rowData.rvl_value
      // }

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML" ? "postRates" : "postGlobalRates";
      try {
        const res = await this[getApiActionKey](params);
        this.addNewRowToTableAfterCreate(res.data.data);
        this.$toasted.success("Rate has been created.").goAway(2500);
        this.isAddingNewRow = false;
        return true;
      } catch (err) {
        console.log(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
        return false;
      }
    },
    changeSelectedClient(clientObj) {
      const newCliId = clientObj.cli_id_useratesfrom;
      const uniqueCode = clientObj.rate_type_unique_code;
      this.setSelectedClient(newCliId, uniqueCode || "GLBNRML");
    },
    addNewRowToTableAfterCreate(newRow) {
      this.newCretedRow = newRow;
    },
    addNewRateRow() {
      this.isAddingNewRow = !this.isAddingNewRow;
    },
    checkSetClientFromUrl(id) {
      const parsedId = parseInt(id, 10);
      if ((parsedId || parsedId === 0) && !isNaN(parsedId)) {
        const foundClient = this.clients.find(
          (client) => client.id === parsedId
        );
        if (foundClient) {
          this.selectedClient = foundClient;
          if (
            this.canDisplayFilter("client_child", this.type) &&
            this.type.unique_code === "GLBNRML"
          ) {
            this.fetchClientUsingRates(this.selectedClient);
          }
        } else {
          console.warn("Client not found!", parsedId);
        }
      }

      // if (this.selectedClient) {
      //   this.fetchEffectiveDate();
      // }
    },
    onSelectClient(evt) {
      this.setSelectedClient(evt.id);
    },
    setSelectedClient(clientId, newRateType = "") {
      const routerPayload = {
        client_id: clientId,
        cat_id: null,
      };
      if (newRateType) {
        routerPayload.type = newRateType;
      }

      this.$router.replace({
        params: routerPayload,
      });
      this.checkSetClientFromUrl(clientId);
      this.canApplyChanges = false;
      console.log("SEL cli", clientId, newRateType);
    },
    onRatesUpdated() {
      this.modalVisible = false;
      this.fetchRateDataCo++;
    },
    onRatesFinalized() {
      this.modalVisible = false;
      this.fetchRateDataCo++;
      // this.fetchEffectiveDate();
    },
    removeSelectedClient() {
      console.log("rem");
      this.$router.replace({
        params: {
          client_id: null,
          cat_id: null,
        },
      });
      this.selectedClient = null;
    },
    addedNewRowAfterPost() {
      this.newCretedRow = null;
    },
    onChangePendingUndoRates(rateId) {
      const indexRate = this.undoPendingRates.findIndex((id) => rateId === id);
      if (indexRate === -1) {
        this.undoPendingRates.push(rateId);
      } else {
        this.undoPendingRates.splice(indexRate, 1);
      }
    },
  },
};

// -----------------------
// Harmless utils
// -----------------------

/*
  Prepares list of clients by altering each client object slightly
  Currently this allows for:
    - Name outlining wether the client is virtual or not (by adding an emoji wrench infront of it)

*/
function prepareClients(clientList) {
  for (let i = 0; i < clientList.length; i++) {
    if (clientList[i].is_virtual) {
      clientList[i].name = "🔧 " + clientList[i].name;
    } else if (!clientList[i].cli_id_useratesfrom) {
      clientList[i].name = "📋 " + clientList[i].name;
    }
  }
  return clientList;
}

// -----------------------
// Utils end
// -----------------------
</script>

<style lang="scss" scoped>
.rates-manage-default-rates {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  gap: 20px;

  .active-date {
    min-width: 100px;

    ::v-deep {
      input {
        // height: 100%;
        height: 40px;
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .client-select {
      width: auto;
      min-width: 350px;
    }

    .category-select {
      width: auto;
      width: 300px;
    }

    .bar {
      display: flex;
    }

    .bar.left {
      gap: 10px;
      align-items: flex-start;

      .control {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      button {
        align-self: flex-end;
      }
    }

    .bar.right {
      gap: 10px;
      align-items: flex-end;
    }
  }

  .filters-2 {
    display: flex;
    gap: 5px;

    .right-side {
      margin-left: auto;
    }
  }

  .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    height: 100%;

    > *:not(.loader) {
      min-width: 350px;
      height: 100%;
      // max-height: calc(100vh - 400px); // Fixed
      width: 100%;
    }
  }

  .switch-changed-only {
    .loader {
      border: 5px solid #dbdbdb;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      width: 2rem;
      height: 2rem;
    }

    .switch-wrap {
      margin-right: 5px;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 150px;
    }

    .vue-switcher {
      margin-left: 10px;
    }
  }

  .control {
    margin: 0;
  }

  .action {
    display: flex;
    align-items: center;

    &.action-2 {
      margin-left: 10px;
    }
  }

  .loading-wrapper {
    &.mer {
      padding: 32px 0 0 0;
    }
  }

  .action-col {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
</style>
