/* eslint-disable @typescript-eslint/no-explicit-any */
export class ShiftScheduleFilters {
    // TODO --- TO BE USED IN FUTURE
    sectors = [];
    client = [];
    subcategories = [];
    locations = [];
    date_from = null;
    date_to = null;
    status = "";
    shiftId = "";
    temp = "";
    externalTssId = "";
    client_external_id = "";
    payroll_shift_statuses = [];
    constructor(filObj = {}) {
        this.updateFilters(filObj);
    }
    updateFilters(filObj = {}) {
        "sectors" in filObj && (this.sectors = filObj.sectors);
        "client" in filObj && (this.client = filObj.client);
        "subcategories" in filObj && (this.subcategories = filObj.subcategories);
        "locations" in filObj && (this.locations = filObj.locations);
        "date_from" in filObj && (this.date_from = filObj.date_from);
        "date_to" in filObj && (this.date_to = filObj.date_to);
        "status" in filObj && (this.status = filObj.status);
        "shiftId" in filObj && (this.shiftId = filObj.shiftId);
        "temp" in filObj && (this.temp = filObj.temp);
        "externalTssId" in filObj && (this.externalTssId = filObj.externalTssId);
        "payroll_shift_statuses" in filObj &&
            (this.payroll_shift_statuses = filObj.payroll_shift_statuses);
    }
}
