<template lang="pug">
- var commonInputStyle = ["input", "is-medium", "is-fullwidth"];
  .user-settings-form
    div(v-if="errors.any() && !success")
      p.notification.is-danger There was an error, unable to process request

    div(v-if="success")
      p.notification.is-success Operation completed successfuly.

    form(
      autocomplete="off",
      @submit.prevent="changeSettings",
      @keydown="clearErrors"
    )
      label.label Name
      p.control
        input(
          v-model="settings.name",
          class=commonInputStyle,
          :class="{ 'is-danger': errors.get('name') }",
          type="text",
          name="name"
        )
      span.help.is-danger(
        v-if="errors.has('name')",
        v-html="errors.get('name')"
      )
      label.label Change password
      p.control
        input(
          v-model="settings.current_password",
          class=commonInputStyle,
          type="password",
          name="current_password",
          placeholder="Current password"
        )
        span.help.is-danger(
          v-if="errors.has('current_password')",
          v-html="errors.get('current_password')"
        )
      p.control
        input(
          v-model="settings.password",
          class=commonInputStyle,
          type="password",
          name="password",
          placeholder="Enter new password"
        )
        span.validation-new-password
          PasswordPolicy(
            :passwordCharacters="settings.password",
            @password-valid="handlePasswordIsValid"
          )
        //- span.help.is-danger(
        //-   v-if="!passwordValidationStore.passwordValidation.length.isValid"
        //- ) {{ passwordValidationStore.passwordValidation.length.label }}
      p.control
        input(
          v-model="settings.password_confirmation",
          class=commonInputStyle,
          type="password",
          name="password",
          placeholder="Confirm new password"
        )
        span.help.is-danger(
          v-if="errors.has('password')",
          v-html="errors.get('password')"
        )
      template(v-if="!$can('edit-profile')")
        label.label Change PIN
        p.control
          input(
            class=commonInputStyle,
            type="password",
            placeholder="Current PIN",
            name="current_pin",
            v-model="settings.current_pin"
          )
          span.help.is-danger(
            v-if="errors.has('current_pin')",
            v-html="errors.get('current_pin')"
          )
        p.control
          input(
            class=commonInputStyle,
            type="password",
            name="pin",
            placeholder="Enter new PIN",
            v-model="settings.pin"
          )
        p.control
          input(
            class=commonInputStyle,
            type="password",
            placeholder="Confirm new PIN",
            name="pin",
            v-model="settings.pin_confirmation"
          )
          span.help.is-danger(
            v-if="errors.has('pin')",
            v-html="errors.get('pin')"
          )

      label.label Email
      p.control
        input.is-disabled(
          class=commonInputStyle,
          type="email",
          :value="settings.email"
        )
      p(
        :class="{ showMessagePasswordConfirmation: isShowMessagePasswordConfirmation, hideMessagePasswordConfirmation: isHideMessagePasswordConfirmation }"
      ) {{ passwordConformationMessage }}
      .buttons-centered
        button.button.is-small.is-generic-app-blue.is-caps-lock(
          type="submit",
          :disabled="disableConfirm"
        ) Save &amp; Close
</template>
<script>
import { Errors } from "../../lib/helpers/Errors.js";
import auth from "../../lib/auth.js";
import { Evt } from "../../lib/helpers/Evt.js";
import PasswordPolicy from "./PasswordPolicyValidation.vue";

export default {
  components: {
    PasswordPolicy,
  },
  data() {
    return {
      passwordConformationMessage: `The "Save and close" button will become available once the password criteria is met and the identical password is entered in both fields.`,
      isShowMessagePasswordConfirmation: false,
      isHideMessagePasswordConfirmation: true,
      submitDisabled: false,
      success: false,
      settings: {
        name: null,
        email: null,
        current_password: null,
        password: null,
        password_confirmation: null,
        current_pin: null,
        pin: null,
        pin_confirmation: null,
        password_is_valid: false,
      },
      errors: new Errors(),
    };
  },
  watch: {
    success: function () {
      setTimeout(
        function () {
          this.callModalClose();
        }.bind(this),
        1000
      );
    },
  },
  created() {
    this.settings.name = auth.user.profile.name;
    this.settings.email = auth.user.profile.email;
  },
  computed: {
    disableConfirm() {
      // return (
      //   this.settings.password &&
      //   (!this.settings.password_is_valid ||
      //     this.settings.password_confirmation !== this.settings.password)
      // );

      if (!this.settings.password) {
        this.isShowMessagePasswordConfirmation = false;
        return false;
      } else {
        this.isShowMessagePasswordConfirmation = true;
        if (
          !(
            this.settings.password_is_valid &&
            this.settings.password_confirmation === this.settings.password
          )
        ) {
          return true;
        } else {
          this.isShowMessagePasswordConfirmation = false;
          return false;
        }
      }
    },
  },
  methods: {
    handlePasswordIsValid(data) {
      this.settings.password_is_valid = data.isPasswordValid;
    },
    clearErrors(e) {
      let fieldHasError = this.errors.get(e.target.name);
      if (fieldHasError) {
        this.errors.clear(e.target.name);
      }
    },
    changeSettings() {
      let data = {};
      // Don't add email (It's fixed value)
      const excludeKeys = ["email"];
      /**
       * Send filtered-out non-null data only...
       */
      Object.keys(this.settings).forEach((setting) => {
        if (
          this.settings[setting] !== null &&
          this.settings[setting] !== "" &&
          !excludeKeys.includes(setting)
        ) {
          data[setting] = this.settings[setting];
        }
      });
      if (!data.current_pin && data.pin) {
        this.$toasted.error("Current pin invalid").goAway(1500);
        return;
      }
      if (data.pin && !data.pin_confirmation) {
        this.$toasted.error("Confirmation pin invalid").goAway(1500);
        return;
      }
      auth.changeSettings(this, data);
    },
    callModalClose() {
      Evt.fire("disposeModal");
      this.$emit("cancel-modal");
    },
  },
};
</script>
<style lang="scss">
.user-settings-form {
  width: 100%;
  .hideMessagePasswordConfirmation {
    color: red;
    display: none;
  }
  .showMessagePasswordConfirmation {
    color: red;
    display: block;
    text-align: center;
  }
  // .control {
  //   .validation-new-password {
  //     color: #c2c2c2;
  //     display: flex;
  //     .correct {
  //       color: #2cb117;
  //     }
  //   }
  // }
  // .validation-password {
  //   color: red;
  // }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}
</style>
