<template lang="pug">
#manage-next-of-kin
  .form-content
    template(v-if="!isLoadingNextOfKin")
      .rows
        .row(v-for="(row, index) in rows", :key="index")
          .group-input
            label.label(v-if="index == 0") Name
            input.input.is-medium(
              :value="row.fullname",
              type="text",
              :class="{ error: isInputErrorValidation('fullname', row) }",
              placeholder="Name",
              @input="onChangeInput($event, row, 'fullname')"
            )
          .group-input
            label.label(v-if="index == 0") Relation type
            multi-select(
              :options="relationshipOptions",
              :close-on-select="true",
              :allow-empty="false",
              select-label="",
              selected-label="",
              deselect-label="",
              track-by="id",
              label="name",
              placeholder="Choose relation type",
              :value="getRelativeType(row)",
              @select="onSelectRelationship($event, row)"
            )
          //- @select="errors.clear('gender')"
          .group-input
            label.label(v-if="index == 0") Phone
            input.input.is-medium(
              :value="row.phone",
              type="text",
              :class="{ error: isInputErrorValidation('phone', row) }",
              placeholder="Phone",
              @input="onChangeInput($event, row, 'phone')"
            )
          .remove-wrap
            img(
              src="../../../assets/images/comps/remove.svg",
              title="Remove",
              @click="onRemoveRow(index)"
            )
      button.button.is-generic-app-blue.is-caps-lock.is-low.add(
        @click="onAddNewRow"
      )
        span Add new row
    .loader-wrap(v-if="isLoadingNextOfKin")
      img.btn-loader(src="../../../assets/images/comps/loader.svg")
      span Loading...
  .action.buttons-centered
    button.button.is-generic-app-blue.is-caps-lock.save(
      @click="onSave",
      :disabled="!isAnyInputChanged"
    )
      span Confirm
    button.button.is-caps-lock.cancel(@click="onClose")
      span Cancel
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";
export default {
  components: {
    MultiSelect,
  },
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rows: this.modalProps.relatives.map((relative) => ({
        relativetype_id: relative.relativetype_id,
        fullname: relative.name,
        phone: relative.phone,
      })),
      relationshipOptions: [],
      isAnyInputChanged: false,
      isLoadingNextOfKin: false,
    };
  },
  mounted() {
    this.initialRowSetup();
  },
  methods: {
    ...mapActions({
      getRelationshipTypes: "getRelationshipTypes",
    }),
    onSave() {
      this.saveFunc();
    },
    onClose() {
      this.$emit("cancel-modal");
    },
    onAddNewRow() {
      this.addBlankRow();
    },
    async onRemoveRow(index) {
      try {
        await this.confirmAlert("remove next of kin");
        this.rows.splice(index, 1);
        this.isAnyInputChanged = true;
      } catch (err) {
        // ignored
      }
    },
    onChangeInput(evt, row, key) {
      const value = evt.target.value;
      this.$set(row, key, value);
      const currentTouchedKeys = new Set(row.__touchedKeys || []);
      currentTouchedKeys.add(key);
      this.$set(row, "__touchedKeys", currentTouchedKeys);
      this.isAnyInputChanged = true;
    },
    onSelectRelationship(evt, row) {
      this.$set(row, "relativetype_id", evt.id);
      this.isAnyInputChanged = true;
    },
    async fetchRelationshipTypes() {
      const params = {};
      try {
        const res = await this.getRelationshipTypes(params);
        this.relationshipOptions = res.data.data;
      } catch (err) {
        console.log("Error", err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(4500);
      }
    },
    getRelativeType(row) {
      const relativetype_id = row.relativetype_id;
      const foundObj = this.relationshipOptions.find(
        (ro) => ro.id === relativetype_id
      );
      if (foundObj) {
        return foundObj;
      }
      return null;
    },
    saveFunc() {
      const isValidInputs = this.checkInputsValidity();
      if (!isValidInputs) {
        this.$toasted
          .error(
            "Some input values are not valid. Please fix before proceeding"
          )
          .goAway(4500);
        return;
      }

      const formattedRows = this.rows.map((row) => {
        // fullname [fe] -> name [db]
        return {
          relativetype_id: row.relativetype_id,
          phone: row.phone,
          name: row.fullname,
        };
      });
      this.$emit("save-next-of-kin", formattedRows);
    },
    addBlankRow() {
      const payload = {
        fullname: "",
        relativetype_id: null,
        phone: null,
      };
      this.rows.push(payload);
    },
    checkInputsValidity() {
      // True is valid
      const allErrorInputs = this.$el.querySelectorAll("input.error");
      const isAnyInputInvalid = allErrorInputs.length > 0;
      if (isAnyInputInvalid) {
        return false;
      }
      const someFieldNotFilled = this.rows.some((row) => {
        return !row.relativetype_id || !row.fullname || !row.phone;
      });
      return !someFieldNotFilled;
    },
    async initialRowSetup() {
      this.isLoadingNextOfKin = true;
      await this.fetchRelationshipTypes();
      this.isLoadingNextOfKin = false;
      if (this.rows.length === 0) {
        this.addBlankRow();
      }
    },
    isInputErrorValidation(key, rowObj) {
      const keyValue = rowObj[key];
      const touchedKeys = new Set(rowObj.__touchedKeys || []);
      switch (key) {
        case "fullname":
          return touchedKeys.has(key) && !keyValue;
        case "phone":
          return touchedKeys.has(key) && !keyValue;
        default:
          return false;
      }
    },
    confirmAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#manage-next-of-kin {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .rows {
      display: flex;
      flex-direction: column;
      gap: 10px;
      min-height: 300px;
      max-height: 400px;
      overflow: auto;
      padding: 0 10px 5px 0;
      // Bottom padding because of the scroll
      width: 100%;

      .row {
        display: flex;
        gap: 10px;

        input {
          height: 40px;

          &.error {
            background: #ffedf0;
            border: 1px solid crimson;
          }
        }

        .group-input {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .remove-wrap {
          width: 50px;
          align-self: flex-end;
          margin: 0 0 4px 10px;

          img {
            cursor: pointer;
          }
        }
      }
    }

    .loader-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 100%;
    }

    .button {
      align-self: flex-start;
    }
  }
}
</style>
