<template lang="pug">
.edit-profile-wrap
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    icon="",
    :size="modalSize",
    :scrollable="modalScrollable"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @save-next-of-kin="onSaveNextOfKin",
      @cancel-modal="modalVisible = false"
    )
  form.mt(autocomplete="off")
    .lost-center
      .load-wrap(
        style="display: flex; justify-content: center",
        v-if="isFetchTempLoading"
      )
        img.profile-loading(src="../assets/images/comps/loader.svg")
      .lost-shift
        //- p.control(data-cell="name")
        //-   label.label Name
        //-   input.input.is-medium(
        //-     v-model="user.name",
        //-     type="text",
        //-     placeholder="NAME"
        //-   )
        p.control(data-cell="first_name")
        label.label First name
        input.input.is-medium(
          v-model="user.first_name",
          type="text",
          placeholder="FIRST NAME"
        )
        p.control(data-cell="last_name")
        label.label Last name
        input.input.is-medium(
          v-model="user.last_name",
          type="text",
          placeholder="LAST NAME"
        )
        p.control(data-cell="nickname")
        label.label Known as
        input.input.is-medium(
          v-model="user.nickname",
          type="text",
          placeholder="KNOWN AS"
        )

        p.control.tss-wtd(data-cell="tss-wrap")
          span.sub(data-cell="tss")
            label.label External system id number:
            input.input.is-medium(
              v-model="user.tssId",
              type="text",
              placeholder="External system id number"
            )
          span.sub(data-cell="average")
            label.label Current average work hours:
            input.input.is-medium(
              :value="formatHours(user.wtd_avg_work_hours)",
              type="text",
              placeholder="Current average work hours",
              :disabled="true"
            )
          span.sub(v-if="user.wtd_avg_work_hours > 48") 
            label.label WTD Expected return date:
            input.input.is-medium(
              :value="formatExpectedReturnDate(user.wtd_expected_return_date)",
              type="text",
              placeholder="WTD Expected return date",
              :disabled="true"
            )

        p.control(data-cell="date-of-birth")
          label.label Date of birth:
          input.input.is-medium(
            v-model="user.dateOfBirth",
            type="text",
            placeholder="DATE OF BIRTH"
          )
          span.help.is-danger(
            v-if="errors.has('date_of_birth')",
            v-html="errors.get('date_of_birth')"
          )

        p.control(data-cell="home-address")
          label.label Home Address Line 1
          input.input.is-medium(
            v-model="user.homeAddress",
            type="text",
            placeholder="HOME ADDRESS LINE 1"
          )

        p.control(data-cell="home-address-2")
          label.label Home Address Line 2
          input.input.is-medium(
            v-model="user.homeAddress2",
            type="text",
            placeholder="HOME ADDRESS LINE 2"
          )
        p.control(data-cell="home-address-3")
          label.label Home Address Line 3
          input.input.is-medium(
            v-model="user.homeAddress3",
            type="text",
            placeholder="HOME ADDRESS LINE 3"
          )

        p.control(data-cell="id")
          label.label Agency Worker #:
          input.input.is-medium(
            v-model="user.id",
            :disabled="true",
            type="text",
            placeholder="WORKER NUMBER"
          )
        p.control(v-if="canUseHolidayRequests" data-cell="holiday_balance_monetary")
          label.label Holiday balance in monetary value:
          input.input.is-medium(
            v-model="user.holiday_balance_monetary",
            :disabled="true",
            type="text",
            placeholder="HOLIDAY BALANCE MONETARY"
          )
        p.control(v-if="canUseHolidayRequests" data-cell="holiday_balance_days_hours")
          label.label Holiday balance in days / hours:
          .input-info(v-if="canUseHolidayRequests")
            input.input.is-medium(
              v-model="user.holiday_balance_days_hours",
              :disabled="true",
              type="text",
              placeholder="HOLIDAY BALANCE DAYS"
            )
            span.info(
              style="margin-left: 10px",
              v-tooltip="{ content: 'Please Note: The days and hours are calculated based on the day rate of the shift which was most recently worked. If a worker is working multiple sub categories this may not be 100% accurate. Please use this as a guide', classes: 'tooltip-width-400' }"
            )
              span.fa.fa-info.popup
        p.control(data-cell="contract_hours")
          label.label Contracted hours
          input.is-medium.input.is-medium(
            type="number",
            :class="{ 'is-danger': errors.get('email') }",
            placeholder="CONTRACTED HOURS",
            v-model="user.contract_hours"
          )
          span.help.is-danger(
            v-if="errors.has('contract_hours')",
            v-html="errors.get('contract_hours')"
          )
        p.control(data-cell="contract_start")
          label.label Contracted Start Date
          date-picker.is-medium.special-wide(
            :date="user.contract_start",
            :option="dateTimeOption",
            v-if="user.contract_start"
          )
          span.help.is-danger(
            v-if="errors.has('contract_start')",
            v-html="errors.get('contract_start')"
          )
        .control(v-if="$can('manage-temp-tags')")
          label.label Tags
          multi-select(
            :options="allTags",
            :close-on-select="false",
            multiple,
            track-by="id",
            label="name",
            select-label="",
            selected-label="",
            deselect-label="",
            :value="user.tags",
            placeholder="SELECT TAG",
            :disabled="!gPermissions.canEditTemp",
            @select="onSelectTag",
            @remove="onRemoveTag"
          )
          span.help.is-danger(
            v-if="errors.has('tags')",
            v-html="errors.get('tags')"
          )
        .control(data-cell="subcategories")
          label.label Increment point # / Subcategories:
          template(v-for="increment in newestIncrements")
            .blue-button-action(
              data-cell="category-increment",
              @click="showIncrementsModal(increment)"
            ) 
              span.label(:title="increment.category.name") {{ increment.category.name }}
              span.inc(:title="increment.name") {{ increment.name }}
          .blue-button-action(@click="showIncrementsModal()")
            span.label Add new

        p.control(data-cell="mobile")
          label.label Mobile:
          input.input.is-medium(
            v-model="user.mobilePhone",
            type="text",
            placeholder="MOBILE"
          )

        p.control(data-cell="phone")
          label.label Phone:
          input.input.is-medium(
            v-model="user.homePhone",
            type="text",
            placeholder="PHONE"
          )
        p.control(data-cell="email")
          label.label Email
          input.input.is-medium(
            v-model="user.email",
            type="text",
            placeholder="EMAIL",
            autocomplete="off"
          )
          span.help.is-danger(
            v-if="errors.has('email')",
            v-html="errors.get('email')"
          )

        .control(v-if="gPermissions.canRelativeView")
          label.label Next of kin:
          template(v-for="relativePerson in user.relatives")
            .blue-button-action(@click="onAddNewRelative(relativePerson)") 
              span.label(:title="relativePerson.name") {{ (relativePerson.name || "").slice(0, 50) }}
          .blue-button-action(
            @click="onAddNewRelative",
            v-if="!user.relatives || user.relatives.length === 0"
          )
            span.label Add new

        p.control
          label.label Gender
          multi-select(
            :options="genderOptions",
            :close-on-select="true",
            track-by="key",
            label="label",
            select-label="",
            selected-label="",
            deselect-label="",
            :value="getSelectedGender",
            placeholder="Select gender",
            :disabled="!gPermissions.canEditTemp",
            @select="onSelectGender"
          )
          span.help.is-danger(
            v-if="errors.has('gender')",
            v-html="errors.get('gender')"
          )
        p.control
          label.label Password
          input.input.is-medium(
            v-model="pass.one",
            type="password",
            placeholder="PASSWORD",
            autocomplete="new-password"
          )
          span.help.is-danger(
            v-if="errors.has('password')",
            v-html="errors.get('password')"
          )
        PasswordPolicy(
          :passwordCharacters="pass.one",
          @password-valid="handlePasswordIsValid"
        )
        p.control
          label.label Confirm Password
          input.input.is-medium(
            v-model="pass.two",
            type="password",
            placeholder="CONFIRM PASSWORD",
            autocomplete="new-password"
          )

        //- p.control
          label.label Subcategories:
          multi-select(
              :options='optionsSubcategories',
              :multiple="true",
              :close-on-select="false",
              track-by="id",
              label="name",
              group-label="category",
              group-values="subs",
              v-model="selectedSubcategories",
              placeholder="PICK A SUBCATEGORY",
              @select="errors.clear('subcategories')"
              )
          span.help.is-danger(v-if="errors.has('subcategories')", v-html="errors.get('subcategories')")

        p.control
          label.label Specialities:
          multi-select(
            :options="optionsSpecialities",
            :multiple="true",
            :close-on-select="false",
            track-by="id",
            label="name",
            v-model="selectedSpecialities",
            placeholder="PICK A FEW",
            :disabled="!gPermissions.canEditTemp",
            @select="errors.clear('specialities')"
          )
          span.help.is-danger(
            v-if="errors.has('specialities')",
            v-html="errors.get('specialities')"
          )

        p.control
          label.label County:
          multi-select(
            :options="optionsCounties",
            :multiple="false",
            :allow-empty="false",
            :close-on-select="true",
            track-by="id",
            label="name",
            v-model="selectedRegion",
            placeholder="CHOOSE COUNTY",
            :disabled="!gPermissions.canEditTemp",
            @select="errors.clear('region_id')"
          )
          span.help.is-danger(
            v-if="errors.has('region_id')",
            v-html="errors.get('region_id')"
          )

        .control(v-if="$can('view-client-assigned-report')")
          label.label Preferred County:
          multi-select(
            :options="optionsCounties",
            :multiple="false",
            :allow-empty="false",
            :close-on-select="true",
            track-by="id",
            label="name",
            v-model="selectedPreferredRegions",
            placeholder="CHOOSE PREFERRED COUNTY",
            :disabled="!gPermissions.canEditTemp",
            @select="errors.clear('preferred_regions')"
          )
          span.help.is-danger(
            v-if="errors.has('preferred_regions')",
            v-html="errors.get('preferred_regions')"
          )

        p.control(
          style="display: flex; justify-content: space-between; margin: 20px 0 8px",
          v-if="hasAllowanceAgency"
        )
          label.label Community allowance
          span.rectangle.group
            switches(
              v-model="user.hasCommunityAllowance",
              theme="bulma",
              color="blue",
              :emitOnMount="false"
            )
              //- @input="toggleClientReport"
        p.control(
          style="display: flex; justify-content: space-between; margin: 20px 0 8px"
        )
          //- v-if="$can('can_create_own_shift')"
          label.label Self submission of shifts
          span.rectangle.group
            switches(
              v-model="user.canCreateOwnShift",
              theme="bulma",
              color="blue",
              :emitOnMount="false"
            )
        p.control(
          style="display: flex; justify-content: space-between; margin: 20px 0 8px",
          v-if="canUseOnCallShifts"
        )
          label.label Standby / On call shifts
          span.rectangle.group
            switches(
              v-model="user.hasOnCall",
              theme="bulma",
              color="blue",
              :emitOnMount="false"
            )
        p.control(
          style="display: flex; justify-content: space-between; margin: 20px 0 8px",
          v-if="canUseSleepoverShifts"
        )
          label.label Sleepover shifts
          span.rectangle.group
            switches(
              v-model="user.hasSleepover",
              theme="bulma",
              color="blue",
              :emitOnMount="false"
            )
        p.control(
          style="display: flex; justify-content: space-between; margin: 20px 0 8px",
          v-if="canUseExpenses"
        )
          label.label Expenses
          span.rectangle.group
            switches(
              v-model="user.hasExpenses",
              theme="bulma",
              color="blue",
              :emitOnMount="false"
            )

        //- p.control(
        //-   style="display: flex; justify-content: space-between; margin: 20px 0 8px"
        //- )
        //-   //- v-if="$can('can_create_own_shift')"
        //-   label.label Self submission of shifts
        //-   span.rectangle.group
        //-     switches(
        //-       v-model="user.canCreateOwnShift",
        //-       theme="bulma",
        //-       color="blue",
        //-       :emitOnMount="false"
        //-     )
        p.control(data-cell="eircode")
          label.label {{ getEirCodeLabelKey }}:
          input.input.is-medium(
            v-model="user.eircode",
            type="text",
            :placeholder="getEirCodeLabelKey"
          )

        p.control(date-cell="mileageTraveled", v-if="canUseExpenses")
          label.label Mileage traveled
          input.input.is-medium(
            :value="`${user.mileageTraveled || 0} KM`",
            type="text",
            placeholder="Mileage traveled",
            :disabled="true"
          )

        gnib-doc-selection(
          canViewTempDetails,
          :errors="errors",
          :form="user",
          :selectedGnibDocId="selectedGnibDocId",
          :selectedGnibMaxWeekValue="selectedGnibMaxWeekValue",
          :isModifiedGnibDocs="isModifiedGnibDocs",
          v-if="(gPermissions.canEditTemp || gPermissions.canViewTempDetails) && hasGnibAgency",
          @select-gnib-doc="onSelectGnibDoc",
          @remove-gnib-doc="onRemoveGnibDoc",
          @input-gnib-hours="onInputGnib"
        ) 
        p(
          :class="{ showMessagePasswordConfirmation: isShowMessagePasswordConfirmation, hideMessagePasswordConfirmation: isHideMessagePasswordConfirmation }"
        ) {{ passwordConformationMessage }}
        template(v-if="gPermissions.canEditTemp")
          button.button.is-generic-app-teal.is-fullwidth.is-caps-lock(
            @click.prevent="submitEdit",
            :class="{ 'is-disabled': false }",
            :disabled="disableConfirm"
          )
            i.fa.fa-check
            | Save Changes
        profile-shifts-table(:logs="tempShifts", :isLoading="isLoadingLog")
        pagination(
          v-if="pagination && pagination.total > 0",
          :current-page="pagination.current_page",
          :total-pages="pagination.total_pages",
          @page-changed="pageHasChanged"
        )
</template>
<script>
import _ from "underscore";
import moment from "moment";
import { Evt } from "./../lib/helpers/Evt.js";
import { mapGetters, mapActions, mapState } from "vuex";
import MultiSelect from "vue-multiselect";
import ProfileShiftsTable from "./tables/ProfileShiftsTable.vue";
import {
  pickNonFalsyKeepZero,
  getFormattedTime,
  parseErrors,
} from "./../lib/helpers/function.js";
import { Errors } from "./../lib/helpers/Errors.js";
import EditIncrementsModalContent from "./in-modal/increments/EditIncrements.vue";
import ManageNextOfKin from "./in-modal/all-candidates/ManageNextOfKin.vue";
import Pagination from "./Pagination.vue";
import { GENDER_MAP } from "../shared/mapData.js";
import calendarOptions from "./common/calendarOptions";
import GnibDocSelection from "@/components/shared/GnibDocSelection.vue";
import PasswordPolicy from "@/components/forms/PasswordPolicyValidation.vue";

export default {
  name: "editProfile",
  components: {
    MultiSelect,
    ProfileShiftsTable,
    EditIncrementsModalContent,
    ManageNextOfKin,
    GnibDocSelection,
    Pagination,
    PasswordPolicy,
  },
  data() {
    return {
      passwordConformationMessage: `The "SAVE CHANGES" button will become available once the password criteria is met and the identical password is entered in both fields.`,
      isShowMessagePasswordConfirmation: false,
      isHideMessagePasswordConfirmation: true,
      password_is_valid: false,
      tempShifts: [],
      isLoadingLog: false,

      user: {
        mileageTraveled: 0,
      },
      allTags: [],
      pass: {
        one: "",
        two: "",
      },
      originalEmail: "",

      selectedSpecialities: [],
      // selectedSubcategories: [],

      selectedRegion: null,
      selectedPreferredRegions: null,

      errors: new Errors(),

      modalVisible: false,
      modalSize: 920,
      modalScrollable: true,
      isFetchTempLoading: false,
      genderOptions: GENDER_MAP,
      dateTimeOption: null,
      selectedGnibDocId: null,
      selectedGnibMaxWeekValue: null,
      isModifiedGnibDocs: false,
    };
  },
  computed: {
    ...mapGetters({
      optionsSpecialities: "getAllSpecialities",
      optionsCounties: "reports/getCounties",
      userProfile: "getUserProfile",
      tempProfile: "getTempProfile",
      temps: "getTemps",
      pagination: "getPagination",
    }),
    disableConfirm() {
      if (!this.pass.one) {
        this.isShowMessagePasswordConfirmation = false;
        return false;
      } else {
        this.isShowMessagePasswordConfirmation = true;
        if (!(this.password_is_valid && this.pass.two === this.pass.one)) {
          return true;
        } else {
          this.isShowMessagePasswordConfirmation = false;
          return false;
        }
      }
    },
    canUseHolidayRequests() {
      return this.initialStatus.can_use_holiday_requests;
    },
    hasAllowanceAgency() {
      return this.initialStatus.can_view_allowances;
    },
    hasGnibAgency() {
      return this.initialStatus.show_gnib_rules;
    },
    getEirCodeLabelKey() {
      return this.$store.getters.getColumnNameLocl("usr_eircode");
    },
    newestIncrements() {
      var newestIncrementsArr = [];
      var currDate = moment();
      // Group by test
      var groupBy = _.groupBy(this.user.increments, (increment) => {
        return increment.category.id;
      });
      _.each(groupBy, (group) => {
        var orderedTempIncrementsByStartDate = _.sortBy(group, (item) => {
          return moment(item.startDate, "YYYY-MM-DD HH:mm:ss").valueOf();
        });

        // Find the active one by going from the back until startDate is not after today
        var len = orderedTempIncrementsByStartDate.length;
        // var pushed = false
        let increment = null;
        // console.warn('inlist', this.user.increments, orderedTempIncrementsByStartDate);
        for (var i = len - 1; i >= 0; i--) {
          increment = orderedTempIncrementsByStartDate[i];
          // console.warn('inc', increment, i, len);
          if (increment.deletedAt) {
            continue;
          }

          // Only adds for profile subs that are already started
          if (currDate.diff(increment.startDate) >= 0) {
            newestIncrementsArr.push(increment);
            // console.warn('ADDING', newestIncrementsArr, increment);
            // pushed = true
            break;
          }
        }

        // If we went through the whole for-loop without finding anything 'active', just push the last element from the group
        // if (pushed === false) {
        //   newestIncrementsArr.push(orderedTempIncrementsByStartDate[len - 1])
        //   console.warn(newestIncrementsArr, increment);
        // }
      });
      return newestIncrementsArr;
    },
    emailChanged() {
      return this.user.email !== this.originalEmail;
    },
    getSelectedGender() {
      if (!this.user.gender) {
        return null;
      }
      return this.genderOptions.find((go) => go.key === this.user.gender);
    },
  },
  created() {
    this.fetchTempData();
    this.fetchShiftsLog();

    this.fetchAllCategoriesList();
    this.fetchAllSpecialitiesList();
    this.fetchRegions();

    if (this.$can("manage-temp-tags")) this.fetchAllTags();
  },
  mounted() {
    // TODO: Implement Vuex storage for "Edit Candidate" and remove this
    Evt.listen("incrementDataChanged", () => {
      this.fetchTempData();
    });
    // Evt.listen('removeSubCatAndSave', (data) => { this.removeAndSaveSubcat(data) })
    this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
    this.dateTimeOption.type = "day";
    this.dateTimeOption.format = "DD/MM/YYYY";
    this.dateTimeOption.placeholder = "CONTRACTED START DATE";

    this.checkSetPermissionInputs();
  },
  beforeDestroy() {
    Evt.off("incrementDataChanged");
    // Evt.off('removeSubCatAndSave')
  },
  methods: {
    ...mapActions({
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchAllSpecialitiesList: "fetchAllSpecialitiesList",
      fetchUserProfile: "fetchUserProfile",
      fetchTempProfile: "fetchTempProfile",
      fetchRegions: "reports/fetchRegions",
      fetchTempShiftsLog: "fetchTempShiftsLog",
      editUserAction: "editUserAction",
      getTempTags: "getTempTags",
      fetchCompliances: "fetchCompliances",
    }),
    // resetCategories () {
    //   console.log('reset categories')
    //   this.$store.commit('CLEAR_CATEGORIES')
    //   this.selectedSubcategories = null
    // },
    handlePasswordIsValid(data) {
      this.password_is_valid = data.isPasswordValid;
    },
    onAddNewRelative(relativePerson) {
      if (!this.gPermissions.canEditTemp) return;

      console.log("Person clicked", relativePerson);
      const payload = {
        relatives: JSON.parse(JSON.stringify(this.user.relatives)),
        person: relativePerson,
      };
      this.showModal(
        "manage-next-of-kin",
        payload,
        "Next of kin management",
        750,
        false
      );
    },
    onSelectTag(evt) {
      this.user.tags.push(evt);
      this.errors.clear("tag");
    },
    onRemoveTag(evt) {
      const deletingTagIndex = this.user.tags.findIndex(
        (tag) => tag.id === evt.id
      );
      this.user.tags.splice(deletingTagIndex, 1);
    },
    onSelectGnibDoc(evtPayload) {
      this.isModifiedGnibDocs = true;
      this.selectedGnibDocId = evtPayload.doc;
      this.selectedGnibMaxWeekValue = evtPayload.hours;
    },
    onRemoveGnibDoc(isModified = true) {
      this.selectedGnibMaxWeekValue = null;
      this.selectedGnibDocId = null;
      this.isModifiedGnibDocs = isModified;
    },
    onInputGnib(evtPayload) {
      this.selectedGnibMaxWeekValue = evtPayload.hours;
      if (!this.selectedGnibDocId) {
        this.selectedGnibDocId = evtPayload.doc;
      }
      this.errors.clear("gnib_max_week_hours");
    },
    checkSetPermissionInputs() {
      if (!this.gPermissions.canEditTemp) {
        const inputs = this.$el?.querySelectorAll("input");
        if (inputs?.length) {
          inputs.forEach((val) => {
            val.disabled = true;
          });
        }
      }
    },
    formatHours(hours) {
      if (hours) {
        const fixed = hours.toFixed(2);
        return fixed;
      }
      return hours;
    },
    formatExpectedReturnDate(dateObj) {
      // Example: 2020-05-03 00:00:00
      // Format to: 07/04/2020 13:57
      const formatted = moment(dateObj.date, "YYYY-MM-DD HH:mm:ss").format(
        "DD/MM/YYYY HH:mm"
      );
      return formatted;
    },
    fillFields() {
      if (this.tempProfile && this.tempProfile.data) {
        this.user = this.tempProfile.data;
        this.fillUserStartDate();
        this.originalEmail = this.tempProfile.data.email;
        this.selectedRegion = this.tempProfile.data.region;
        this.selectedPreferredRegions = this.tempProfile.data.preferredRegions
          .length
          ? this.tempProfile.data.preferredRegions[0]
          : null;
        // this.selectedSubcategories = this.user.subcategories
        this.selectedSpecialities = this.user.specialities;
        this.selectedGnibDocId = this.user.gnib_dtp_id;
        this.selectedGnibMaxWeekValue = this.user.gnib_max_week_hours;
      } else if (this.userProfile && this.userProfile.email) {
        this.user = this.userProfile;
        this.originalEmail = this.userProfile.email;
        this.selectedRegion = this.userProfile.region;
        this.selectedPreferredRegions = this.userProfile.preferredRegions.length
          ? this.userProfile.preferredRegions[0]
          : null;
        // this.selectedSubcategories = this.user.subcategories
        this.selectedSpecialities = this.user.specialities;
        this.selectedGnibDocId = this.user.gnib_dtp_id;
        this.selectedGnibMaxWeekValue = this.user.gnib_max_week_hours;
      } else {
        console.log("No data", this.tempProfile, this.userProfile);
      }
    },
    fillUserStartDate() {
      if (!this.user.contract_start) {
        this.user.contract_start = {};
      } else {
        const tempStart = moment(this.user.contract_start, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        let startObj = {
          time: tempStart,
        };
        this.user.contract_start = startObj;
      }
    },
    formatDateLocToIso(isoDate) {
      return getFormattedTime(isoDate, "YYYY-MM-DD", "DD/MM/YYYY");
    },
    submitEdit() {
      const bothEmpty = !this.pass.one.trim() && !this.pass.two.trim();
      const notEmptyAndEqual =
        this.pass.one.trim() &&
        this.pass.two.trim() &&
        this.pass.one.trim() === this.pass.two.trim();
      // Message user
      if (!bothEmpty && !notEmptyAndEqual) {
        this.$toasted.error("Passwords do not match.").goAway(1500);
        return;
      }

      if (this.isUserAgeInvalid()) {
        this.$toasted
          .error("Candidate must be more than 18 years old.")
          .goAway(1500);
        return;
      }

      let postParams = {
        id: this.user.id,
        // name: this.user.name,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        nickname: this.user.nickname,
        home_address: this.user.homeAddress,
        home_address_2: this.user.homeAddress2,
        home_address_3: this.user.homeAddress3,
        mobile_phone: this.user.mobilePhone,
        home_phone: this.user.homePhone,
        eircode: this.user.eircode,
        date_of_birth: this.formatDateLocToIso(this.user.dateOfBirth),
        tss_id: this.user.tssId,
        gender: this.user.gender,
        region_id: this.selectedRegion ? this.selectedRegion.id : null,
        preferred_regions:
          this.selectedPreferredRegions && this.selectedPreferredRegions.id
            ? [this.selectedPreferredRegions]
            : [],
        has_community_allowance: this.user.hasCommunityAllowance,
        can_create_own_shift: this.user.canCreateOwnShift,
        // subcategories: this.selectedSubcategories,
        specialities: this.selectedSpecialities,
        contract_start: this.formatDateLocToIso(this.user.contract_start.time),
        contract_hours: this.user.contract_hours,
        tags: this.user.tags,
      };

      if (this.emailChanged) {
        postParams.email = this.user.email;
      }

      if (notEmptyAndEqual) {
        postParams.password = this.pass.one;
      }

      if (this.selectedGnibDocId) {
        postParams.include = "gnibConfig";
        postParams.gnib_dtp_id = this.selectedGnibDocId;
        if (this.selectedGnibMaxWeekValue) {
          postParams.gnib_max_week_hours = this.selectedGnibMaxWeekValue;
        }
      } else {
        postParams.include = "gnibConfig";
        postParams.delete_gnib_config = true;
      }

      if (this.gPermissions.canRelativeView) {
        postParams.relatives = this.user.relatives;
      }

      if (this.canUseOnCallShifts) {
        postParams.has_on_call = this.user.hasOnCall;
      }

      if (this.canUseSleepoverShifts) {
        postParams.has_sleepover = this.user.hasSleepover;
      }

      if (this.canUseExpenses) {
        postParams.has_expenses = this.user.hasExpenses;
      }

      const tempParams = pickNonFalsyKeepZero(postParams);

      if (!tempParams.nickname) {
        tempParams.nickname = "";
      }

      this.patchUser(tempParams);
    },
    // removeAndSaveSubcat (subCatId) {
    //   const params = {
    //     subcategories: [{ id: subCatId }]
    //   }
    //   this.patchUser(params)
    // },
    async patchUser(preParams = {}) {
      const postParams = { ...preParams };
      const params = {
        url: "temps/" + this.$route.params.tempId,
        params: postParams,
      };
      try {
        await this.editUserAction(params);
        this.$toasted.info("Profile updated!").goAway(1500);
        this.errors.clear();
        this.originalEmail = this.user.email;
        this.pass.one = "";
        this.pass.two = "";

        await this.fetchTempData();

        // Reset GNIB docs so it doesn't resend on next post
        // this.onRemoveGnibDoc(false);
      } catch (error) {
        if (error.response.data.errors) {
          this.errors.record(error.response.data.errors);
          this.$toasted.error(error.response.data.message).goAway(1500);
        } else if (error.response.data.message) {
          this.$toasted.error(error.response.data.message).goAway(1500);
        } else {
          console.log("Error editing profile", error.message);
          let errs = parseErrors(error);
          this.$toasted.error(errs || "Error editing profile").goAway(4500);
        }
      }
    },
    isUserAgeInvalid() {
      let currentDate = moment();
      let userBirthDate = moment(
        this.formatDateLocToIso(this.user.dateOfBirth)
      );
      let userAge = currentDate.diff(userBirthDate, "years");
      if (userAge < 18) {
        return true;
      } else {
        return false;
      }
    },
    showIncrementsModal(increment) {
      if (!this.gPermissions.canEditTemp) return;

      let incrementData = {};
      let categoryForIncrement = {};
      let labelModal = "Create subcategory and increments";
      if (!increment) {
        console.log(" >>> new subcat and increment");
      } else {
        labelModal = "Set increments for " + increment.category.name;
        incrementData.clickedIncrement = increment;
        categoryForIncrement = _.find(this.user.categories, (category) => {
          return category.id === increment.category.id;
        });
        // Increments DATA get called inmodal with specific API
        // incrementData.allIncrements = categoryForIncrement.increments

        incrementData.tempIncrements = _.filter(
          this.user.increments,
          (increment) => {
            return increment.category.id === categoryForIncrement.id;
          }
        );
        incrementData.category = categoryForIncrement;
      }

      incrementData.tempId = this.$route.params.tempId;
      incrementData.userObj = this.user;

      console.log("FOUND CATEGORY :: ", categoryForIncrement.name);

      this.showModal(
        "edit-increments-modal-content",
        incrementData,
        labelModal
      );
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = "920",
      modalScrollable = true
    ) {
      console.log("Data we should send to a modal popup...", modalData);
      this.modalScrollable = modalScrollable;
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalSize = modalSize;
      this.modalTitle = modalTitle;
      this.modalVisible = true;
    },
    /**
     * TODO do a clean-up on this whole file, kick-out all logic related to if temp was logged in
     */
    async fetchTempData() {
      this.isFetchTempLoading = true;
      const includes = [
        "region",
        "subcategories",
        "specialities",
        "clients.incrementRates",
        "categories.increments",
        "increments",
        "wtd",
        "preferredRegions",
        "tags",
        "holidayBalance",
        "gnibConfig",
        "tempOnCallInfo",
        "tempSleepoverInfo",
        "tempExpensesInfo",
      ];

      if (this.gPermissions.canRelativeView) {
        includes.push("relatives");
      }

      if (this.canUseExpenses) {
        includes.push("mileageTraveled");
      }

      const paramsIncludes = includes.join(",");

      try {
        await this.fetchUserTempWrapper({
          tempId: this.$route.params.tempId,
          include: paramsIncludes,
        });
        this.isFetchTempLoading = false;
      } catch (err) {
        this.isFetchTempLoading = false;
      } finally {
        this.isFetchTempLoading = false;
      }

      this.fillFields();
    },
    async fetchAllTags() {
      try {
        const res = await this.getTempTags();
        this.allTags = res.data.data;
      } catch (error) {
        console.warn(error);
      }
    },
    fetchUserTempWrapper(objProp) {
      if (this.$can("view-profile")) {
        // Temp user
        return this.fetchUserProfile(objProp);
      } else {
        return this.fetchTempProfile(objProp);
      }
    },
    async fetchShiftsLog(page = 1) {
      if (this.$can("view-profile")) {
        // Disable for temp
        return;
      }

      let params = this.getShiftsLogParams(page);
      this.isLoadingLog = true;
      try {
        const response = await this.fetchTempShiftsLog(params);
        this.tempShifts = response.data.data;
        this.isLoadingLog = false;
      } catch (err) {
        console.warn(err.message);
      }
    },
    getShiftsLogParams(page) {
      return {
        id: this.$route.params.tempId,
        page,
        include: "shift.location.client",
      };
    },
    pageHasChanged(pageNum) {
      this.fetchShiftsLog(pageNum);
    },
    onSaveNextOfKin(evt) {
      this.modalVisible = false;
      this.user.relatives = evt;
    },
    onSelectGender(evt) {
      this.$set(this.user, "gender", evt.key);
      this.errors.clear("gender");
    },
  },
};
</script>
<style lang="scss" scoped>
$lightgray: #7c91ae;
$border-color: #9bb4c1;

.edit-profile-wrap {
  width: 100%;
  form.mt {
    p.control.tss-wtd {
      display: flex;

      > * {
        width: 100%;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .label {
      color: $lightgray;
      font-size: 12px;
    }
    .input {
      box-sizing: border-box;
      border: 1px solid $border-color;
      border-radius: 4px;
      box-shadow: none;
    }
  }
  .input-info {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0.75rem;
  }
}
</style>
<style lang="scss">
.edit-profile-wrap {
  width: 100%;
  .hideMessagePasswordConfirmation {
    color: red;
    display: none;
  }
  .showMessagePasswordConfirmation {
    color: red;
    display: block;
    text-align: center;
  }

  .profile-loading {
    margin: 0 20px;

    .button.is-loading:after {
      border: 2px solid #220ef0;
    }
  }
}
</style>
