<template lang="pug">
.newInvoice
  center.single-invoice(v-if="!createForAllClients")
    label.label Client
    multi-select(
      :options="clients",
      track-by="id",
      label="name",
      v-model="selectedClient",
      :multiple="false",
      :showLabels="false"
    )
  center.all-clients(v-else-if="!isLoadingClients")
    .control(v-if="!isCreatingAllInvoices") Do you want to create an invoice for each of the {{ clients.length }} clients?
    progress-bar(
      :currentProgress="currentProgress",
      v-if="isCreatingAllInvoices && !areInvoicesCreated"
    )
    .control(v-if="areInvoicesCreated")
      span There is no unprocessed shifts for&nbsp
      span(
        @click="showNotCreatedInvoices = true",
        style="font-weight: bold; color: #6591aa; cursor: pointer"
      ) {{ noInvoices.length }} clients.
    table.table(v-show="showNotCreatedInvoices", style="color: #000")
      thead
        tr
          th
          th Client
      tbody
        tr(v-for="(invoice, index) in noInvoices")
          td {{ index + 1 }}
          td {{ invoice.name }}
  .loading-wrapper(v-if="createForAllClients && isLoadingClients")
    img(src="../../../assets/images/comps/loader.svg")
  .center-action(:class="{ 'noinvoice-preview': showNotCreatedInvoices }")
    button.button.is-generic-app-blue.left(
      style="width: 100px",
      @click="confirmCreateInvoice",
      :disabled="isCreatingAllInvoices || (createForAllClients && isLoadingClients)",
      v-if="!areInvoicesCreated"
    ) Create
    button.button.is-danger.right(
      data-cancel,
      style="width: 100px",
      @click="closeModal"
    ) Close
</template>

<script>
import MultiSelect from "vue-multiselect";
import ProgressBar from "./ProgressBar.vue";
import { mapGetters, mapActions } from "vuex";
import { Evt } from "../../../lib/helpers/Evt.js";

export default {
  components: {
    MultiSelect,
    ProgressBar,
  },
  props: ["modalProps"],
  data() {
    return {
      clients: [],
      selectedClient: "",
      isCreatingAllInvoices: false,
      isCreatedAnyInvoice: false,
      currentProgress: 0,
      noInvoices: [],
      isCreatingAllInvoicesClosed: false,
      areInvoicesCreated: false,
      showNotCreatedInvoices: false,
      isLoadingClients: true,
    };
  },
  computed: {
    ...mapGetters({
      getAllClients: "getAllClients",
    }),

    createForAllClients() {
      return this.modalProps.createForAllClients;
    },
  },
  watch: {
    getAllClients: function () {
      this.fillClients();
    },
  },
  mounted() {
    this.fetchClientsList({ per_page: 999 }).then(() => {
      this.isLoadingClients = false;
    });
  },
  methods: {
    ...mapActions({
      fetchClientsList: "fetchClientsList",
      createInvoice: "invoices/createInvoice",
    }),

    fillClients() {
      this.clients = this.getAllClients.map((client) => {
        return {
          id: client.id,
          name: client.name,
        };
      });
    },

    createInvoicePerClient() {
      var id = this.selectedClient.id;
      if (id === undefined) {
        this.$toasted.error("Please select a client").goAway(2000);
        return;
      }
      Evt.fire("createInvoice", id);
      this.$emit("disposeModal");
    },
    async createInvoicesForAllClients(i) {
      if (this.isCreatingAllInvoicesClosed) {
        return;
      }
      if (i === this.clients.length) {
        this.areInvoicesCreated = true;
        this.currentProgress = 100;
        this.$emit("fetchInvoices");
        return;
      }
      let currClientId = this.clients[i].id;
      try {
        await this.createInvoice(currClientId);
        console.log("then", i);
        this.isCreatedAnyInvoice = true;
        this.currentProgress = ((i + 1) * 100) / (this.clients.length - 1);
        this.createInvoicesForAllClients(++i);
      } catch (err) {
        console.log("catch", i);
        if (err?.response?.data?.status_code === 403) {
          this.$toasted
            .error("You're unauthorized for this action")
            .goAway(5000);
          this.closeModal();
        } else {
          this.noInvoices.push(this.clients[i]);
          this.currentProgress = ((i + 1) * 100) / (this.clients.length - 1);
          this.createInvoicesForAllClients(++i);
        }
      }
    },
    confirmCreateInvoice() {
      if (this.createForAllClients) {
        this.isCreatingAllInvoicesClosed = false;
        this.isCreatingAllInvoices = true;
        this.createInvoicesForAllClients(0);
      } else {
        this.createInvoicePerClient();
      }
    },
    closeModal() {
      this.isCreatingAllInvoices = false;
      this.isCreatingAllInvoicesClosed = true;
      this.areInvoicesCreated = false;
      this.$emit("disposeModal", this.isCreatedAnyInvoice);
      this.isCreatedAnyInvoice = false;
    },
  },
};
</script>

<style scoped lang="scss">
.loading-wrapper {
  padding: 20px;
}

.newInvoice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.all-clients {
  padding-top: 3em;
}

.noinvoice-preview {
  position: relative;

  .right {
    right: 0px;
  }
}

.single-invoice {
  width: 75%;
}

.center-action {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px 40px;
}
</style>

<style lang="scss">
.all-clients {
  .progress-bar-wrapper {
    margin-top: -0.7em;

    .progress-bar {
      width: 100%;
    }
  }
}
</style>
