import { default as axios } from "../config.js";
import { packLaravelArray } from "../../lib/helpers/function";
import qs from "query-string";

const api = {
  rejectionReasons: () => {
    return axios.get("/document-rejection-reasons");
  },

  rejectTempDocument: ({
    tempId,
    documentId,
    documentRejectionReasonId,
    include,
  }) => {
    return axios.post(
      `/documents/${documentId}/temps/${tempId}/reject?include=${include}`,
      {
        document_rejection_reason_id: documentRejectionReasonId,
      }
    );
  },
  approveTempDocument: ({ tempId, documentId, include }) => {
    return axios.post(
      `/documents/${documentId}/temps/${tempId}/approve?include=${include}`
    );
  },

  fetchCompliances: (q) => {
    const tempPayload = JSON.parse(JSON.stringify(q));
    let fullRequestUrl = "/documenttypes";

    const laravelArr = ["clients"];
    for (const larKey of laravelArr) {
      if (tempPayload[larKey]) {
        const packed = packLaravelArray(larKey, tempPayload[larKey]);
        if (packed !== "") {
          // If first
          if (fullRequestUrl.indexOf("/?") === -1) {
            fullRequestUrl = fullRequestUrl + "?" + packed;
          } else {
            fullRequestUrl = fullRequestUrl + "&" + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete tempPayload[larKey];
      }
    }

    const morePayload = {};
    if (q.compliance_type) {
      // GNIB only used for now
      morePayload.compliance_type = q.compliance_type;
    }

    return axios.get(fullRequestUrl, {
      params: {
        ...morePayload,
        include: q.includes,
        page: q.page,
        sort_by_field: "dtp_id",
        sort_by_direction: "desc",
        optimized: q.optimized || false,
      },
    });
  },

  fetchDocumentsForApproval: (params = {}) => {
    const queryParams = qs.stringify(params);
    return axios.get(`/documents-for-approval?${queryParams}`);
  },

  fetchTempDocuments: (params = {}) => {
    params.url = "/documenttypes";
    return axios.get(`${params.url}`, {
      params: {
        include: params.include,
        temp_id: params.tempId,
        page: params.page,
      },
    });
  },
  fetchOneTempDocuments: (params = {}) => {
    return axios.get(`/documenttypes/${params.docTypeId}`, { params });
  },

  createDocument: (payload) => {
    return axios.post("/documenttypes", payload);
  },

  removeDocument: (id) => {
    return axios.delete(`/documenttypes/${id}`);
  },

  deleteFiles: (documentId, tempId, files, includes) => {
    return axios.delete(
      `documents/${documentId}/temps/${tempId}/delete-files`,
      {
        data: {
          files: files,
          include: includes,
        },
      }
    );
  },

  patchDocument: (docId, payload = {}) => {
    return axios.patch(`/documenttypes/${docId}`, payload, {
      params: {
        include: "locations,categories,roles",
      },
    });
  },

  changeExpiryDate: (payload) => {
    const { id, temp, newExpiryDate } = payload;
    const url = `/documents/${id}/temps/${temp.id}/upload-files`;
    return axios({
      url,
      method: "POST",
      params: {
        include: "categories,filesCount",
      },
      data: {
        expiry_date: newExpiryDate,
      },
    });
  },
  updateExpiryDate: (payload) => {
    const { id, temp, newExpiryDate } = payload;
    const url = `/documents/${id}/temps/${temp.id}/update-expiry`;
    return axios({
      url,
      method: "POST",
      params: {
        include: "categories,filesCount",
      },
      data: {
        expiry_date: newExpiryDate,
      },
    });
  },

  dlDocumentReport: (params) => {
    let url = "/documents/temps/report";

    const laravelArr = ["clients", "categories", "subcategories", "sectors"];
    for (const larKey of laravelArr) {
      if (params[larKey]) {
        const packed = packLaravelArray(larKey, params[larKey]);
        if (packed !== "") {
          // If first
          if (url.indexOf("?") === -1) {
            url = url + "?" + packed;
          } else {
            url = url + "&" + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete params[larKey];
      }
    }

    return axios.get(url, {
      responseType: "arraybuffer",
      params,
    });
  },
};

export default api;
