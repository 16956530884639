
import api from '../../api/clientUserManagement';

const state = {
};

const getters = {
};

const actions = {
  registerTemp: ({commit}, payload) => {
    return api.registerTemp(payload).then(response => {
      console.warn('action: register temp', response);
      // commit if there is something to commit
    });
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
