<template lang="pug">
.dialog-container
  .inputs
    .field
      label.label Link
      p.control
        input.input(
          type="text",
          placeholder="Please type in your info link here",
          v-model="infoLink"
        )
  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      type="submit",
      @click.prevent="saveInfoLink"
    ) Save
    button.button.is-small.is-outlined.is-caps-lock(
      @click.prevent="callModalClose"
    ) Cancel
</template>
<script>
import { parseErrors } from "../../../lib/helpers/function.js";
import { mapActions } from "vuex";

export default {
  props: ["modalProps"],
  data() {
    return {
      infoLink: null,
    };
  },
  mounted() {
    this.infoLink = this.modalProps.infoLink;
  },
  methods: {
    ...mapActions(["patchDocument"]),
    callModalClose() {
      this.$emit("cancel-modal");
    },
    saveInfoLink() {
      this.patchDocument({
        docId: this.modalProps.docId,
        info_link: this.infoLink,
      })
        .then(() => {
          this.$toasted
            .info("Info link updated!", { theme: "outline" })
            .goAway(1500);
          this.callModalClose();
          this.$emit("get-documents");
        })
        .catch((error) => {
          const errs = parseErrors(error);
          this.$toasted.error(errs).goAway(4500);
        });
    },
  },
};
</script>
<style lang="scss">
.dialog-container {
  width: 100%;
}

.inputs {
  display: flex;
  justify-content: center;

  .field {
    width: 100%;
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}
</style>
