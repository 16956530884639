<template lang="pug">
#rates-manage-rates
  .loading-wrap(v-if="isLoadingRateTypes")
    .loading-wrapper
      img.client-loading(src="@/assets/images/comps/loader.svg")
  template(v-if="!isLoadingRateTypes")
    .top
      .bar.left
        p.control(v-if="hasMoreRateTypes")
          label.label Rate Card Type
          //- label.label Rates type
          //- style="width: 350px",
          multi-select#client.client-select(
            :options="rateTypesOptions",
            :searchable="true",
            :options-limit="999",
            select-label="",
            deselect-label="",
            track-by="unique_code",
            label="label",
            :allow-empty="false",
            :multiple="false",
            :value="selectedRateType",
            placeholder="SELECT RATE TYPE",
            @select="setSelectedRateType"
          )
        p.control.rule-book
          a(
            target="_blank",
            title="You can download the manual for rate management here",
            style="padding-bottom: 6px",
            @click.prevent="onClickRuleBook"
          )
            span Rate management rule book
    template(v-if="selectedRateType")
      rates-manage-default-rates(
        :rate-date-selected="rateDateSelected",
        :allRateTypes="rateTypesOptions",
        :type="selectedRateType",
        :sector="sector",
        :key="selectedRateType.unique_code"
      )
</template>

<script>
import store from "@/vuex/store";
import MultiSelect from "vue-multiselect";
import RatesManageDefaultRates from "./Rates/RatesManageDefaultRates.vue";
import { mapActions } from "vuex";
import rates_config from "../../../test_json/rates_config.json";
import moment from "moment";

export default {
  name: "RatesManageRatesPage",
  components: {
    MultiSelect,
    RatesManageDefaultRates,
  },
  props: {
    sector: [Object, null],
  },
  data() {
    return {
      isLoadingRateTypes: true,
      rateTypesFront: [],
      rateDateSelected: null,
    };
  },
  computed: {
    selectedRateType() {
      return (
        this.rateTypesOptions.find(
          (item) => item.unique_code === this.$route.params?.type
        ) || this.rateTypesOptions[0]
      );
    },
    generateRuleBook() {
      const baseUrl = process.env.VUE_APP_API_URL_DEV;
      return `${baseUrl}/api/rates/rulebook`;
    },
    hasMoreRateTypes() {
      return this.rateTypesOptions?.length;
    },
    rateTypesOptions() {
      return this.rateTypesFront;
      // const typeKeys = Object.keys(this.rateTypesFront);
      // if (typeKeys.length) {
      //   const globalTypes = this.rateTypesFront.global_rate_types;
      //   const specificTypes = this.rateTypesFront.specific_rate_types;
      //   return [...globalTypes, ...specificTypes];
      // }
      // return [];
    },
  },
  async beforeRouteEnter(to, from, next) {
    const categoriesRateArr = store.state.rateCategories;
    const sectorSelect = store.state.rateSectorSelected;

    const failedRouteCheck = () => {
      console.log(
        "[Cancelled] rate increment route",
        categoriesRateArr,
        sectorSelect
      );
      next({
        name: "RatesManageCategories",
      });
    };

    const hasCats =
      Array.isArray(categoriesRateArr) && categoriesRateArr.length;
    if (hasCats && sectorSelect) {
      next();
    } else {
      setTimeout(async () => {
        try {
          // WIP [4 Delayed] Add progress loader for rates table
          const secRes = await store.dispatch("getRatesSectors");
          const sectors = secRes.data.data;
          const hasOneSector = Array.isArray(sectors) && sectors.length === 1;
          if (hasOneSector) {
            const params = {
              sct_id: sectors[0] && sectors[0].id,
            };
            await store.dispatch("ratePickSector", sectors[0]);
            await store.dispatch("getRatesCategories", params);
            next();
          } else {
            failedRouteCheck();
          }
        } catch (err) {
          console.warn(err.message);
          failedRouteCheck();
        }
      }, 1000);
    }
  },
  mounted() {
    this.rateDateSelected = moment().format("YYYY-MM-DD");
    this.fetchRatesTableFront();
  },
  methods: {
    ...mapActions({
      getRatesFrontConfig: "getRatesFrontConfig",
      postFileBlob: "postFileBlob",
    }),
    async fetchRatesTableFront() {
      const params = {
        date: this.rateDateSelected,
      };
      try {
        const res = await this.getRatesFrontConfig(params);
        const ratesConfig = res.data.data;
        this.rateTypesFront = ratesConfig;
        // if (!this.hasMoreRateTypes) {
        //   this.setRateTypeClient();
        // }
        this.isLoadingRateTypes = false;
      } catch (err) {
        console.warn(err.message);
        this.isLoadingRateTypes = false;
      }
    },
    // setRateTypeClient() {
    //   // const data = {
    //   // }
    //   // this.setSelectedRateType(data)
    // },
    setSelectedRateType(evt) {
      // this.selectedRateType = evt;

      this.$router
        .replace({
          params: {
            type: evt.unique_code,
            client_id: null,
            cat_id: null,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    },
    removeSelectedRateType(evt) {
      // this.selectedRateType = null;

      const params = { ...this.$route.params };
      delete params.type;
      delete params.client_id;
      delete params.cat_id;

      this.$router
        .replace({
          params,
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    },
    async onClickRuleBook() {
      const params = {
        link: "rates/rulebook",
      };
      try {
        const res = await this.postFileBlob(params);
        const pdfType = "application/pdf";
        const blob = new Blob([res.data], { type: pdfType });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        URL.revokeObjectURL(url);
      } catch (err) {
        console.log(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#rates-manage-rates {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;

    .client-select {
      width: auto;
      min-width: 350px;
    }

    .bar {
      display: flex;
    }

    .bar.left {
      gap: 10px;
      align-items: flex-start;

      .control {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      .rule-book {
        justify-content: flex-end;
      }
    }

    .bar.right {
      gap: 10px;
      align-items: flex-end;
    }
  }

  ::v-deep {
    .multiselect__single {
      overflow: hidden;
    }
  }

  .control {
    margin: 0;
  }

  .action {
    display: flex;
    align-items: center;

    &.action-2 {
      margin-left: 10px;
    }
  }
}
</style>
