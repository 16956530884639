<template lang="pug">
.form.journal-form(
  :class="{ anima: isFlashForm }",
  @animationend="onAnimationEnd"
)
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :headless="false",
    size="650"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false"
    )
  .left-side
    .top-section
      p.control
        label.label Subject
        multi-select(
          :options="subjectTypes",
          track-by="id",
          label="name",
          select-label="",
          selected-label="",
          deselect-label="",
          placeholder="Subject",
          :value="form.notetypes",
          @select="onSelectSubject",
          @remove="onRemoveSubject"
        )
        span.help.is-danger(
          v-if="errors.has('notetype_id')",
          v-html="errors.get('notetype_id')"
        )
      p.control(v-if="isTempPage")
        label.label Client
        multi-select(
          :options="clients",
          track-by="id",
          label="name",
          select-label="",
          selected-label="",
          deselect-label="",
          v-model="form.client",
          placeholder="Client"
        )
        span.help.is-danger(
          v-if="errors.has('client_id')",
          v-html="errors.get('client_id')"
        )
      p.control(v-if="isClientPage")
        label.label Temp
        multi-select(
          :options="formattedAgencyWorker",
          :loading="isLoadingTemp",
          track-by="id",
          label="name",
          select-label="",
          selected-label="",
          deselect-label="",
          v-model="form.temp",
          placeholder="Temp",
          @search-change="asyncFindWorker"
        )
        span.help.is-danger(
          v-if="errors.has('client_id')",
          v-html="errors.get('client_id')"
        )
    .bottom-section
      .private-checkbox(v-if="canCreatePrivateNotes")
        label.label Private note
          input(
            v-model="form.isprivate",
            type="checkbox",
            style="margin-left: 10px"
          )
        span.help.is-danger(
          v-if="errors.has('isprivate')",
          v-html="errors.get('isprivate')"
        )
      button.button.is-generic-app-blue.is-caps-lock.is-low(
        @click.prevent="onClickUploadFiles",
        :disabled="isSaveDisabled"
      ) 
        span Toggle files
        //- span(style="font-weight: bold", v-if="form.files.length") ({{ form.files.length }})
        span.fa.fa-upload
      button.button.is-generic-app-blue.is-caps-lock.is-low(
        :disabled="isSaveDisabled",
        :class="{ 'is-loading': isSaveDisabled }",
        v-if="canSaveEditAction",
        @click.prevent="onClickSave"
      ) {{ getSubmitLabel }}
      button.button.is-generic-app-blue.is-caps-lock.is-low(
        :disabled="isSaveDisabled",
        :class="{ 'is-loading': isSaveDisabled }",
        @click.prevent="onResetClick"
      ) Reset
      //- button(@click='onClickCancel') Cancel

  transition(name="slide-expand")
    .upload-wrapper(v-if="isUploadVisible")
      journal-simple-upload(
        :files="form.files",
        @change-files="onFilesChanged",
        @remove-file="onRemoveFile"
      )
  .right-side
    .control
      label.label Note
      textarea.input.is-medium(
        v-model="form.note",
        type="text",
        placeholder="Note"
      )
      .char-number-overlay(
        v-if="getAreaCharNumber",
        :class="{ offset: errors.has('note') }"
      ) {{ getAreaCharNumber }}
      span.help.is-danger(
        v-if="errors.has('note')",
        v-html="errors.get('note')"
      )
  .progress-bar
    .line(:style="progressUpdate")
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import _ from "underscore";
import MultiSelect from "vue-multiselect";
import JournalSimpleUpload from "./Upload/JournalSimpleUpload.vue";
import { mapActions, mapGetters } from "vuex";
import { Errors } from "../../lib/helpers/Errors";
import { parseErrors } from "../../lib/helpers/function";

export default {
  components: {
    MultiSelect,
    JournalSimpleUpload,
  },
  props: {
    clientId: {
      type: [Number, String],
    },
    tempId: {
      type: [Number, String],
    },
    subjectTypes: {
      type: Array,
      default: () => [],
    },
    clients: {
      type: Array,
      default: () => [],
    },
    pendingRow: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultFormData: {
        notetypes: null,
        client: null,
        temp: null,
        note: null,
        isprivate: false,
        files: [],
      },
      form: {},
      modalVisible: false,
      errors: new Errors(),
      isCheckSubjectTypeActive: true,
      isSaveDisabled: false,
      isUploadVisible: false,
      isFlashForm: false,
      progressCompleted: {},
      isLoadingTemp: false,
    };
  },
  computed: {
    ...mapGetters({
      agencyWorkers: "getAgencyWorkers",
    }),
    canSaveEditAction() {
      if (this.clientId) {
        if (this.getIsFormEdit) {
          return this.gPermissions.canJournalClientEdit;
        }
        return this.gPermissions.canJournalClientSave;
      } else if (this.tempId) {
        if (this.getIsFormEdit) {
          return this.gPermissions.canJournalTempEdit;
        }
        return this.gPermissions.canJournalTempSave;
      }
      return false;
    },
    canCreatePrivateNotes() {
      if (this.isClientPage) {
        return this.gPermissions.canJournalClientCreatePrivate;
      } else if (this.isTempPage) {
        return this.gPermissions.canJournalTempCreatePrivate;
      }
      return false;
    },
    canViewPrivateNotes() {
      if (this.isClientPage) {
        return this.gPermissions.canJournalClientViewPrivate;
      } else if (this.isTempPage) {
        return this.gPermissions.canJournalTempViewPrivate;
      }
      return false;
    },
    getSubmitLabel() {
      if (this.getIsFormEdit) {
        return "Update";
      }
      return "Submit";
    },
    getIsFormEdit() {
      return this.form.id;
    },
    formattedAgencyWorker() {
      return this.agencyWorkers.map((aw) => {
        return {
          ...aw,
          name: `${aw.name} (${aw.tssId || "No external system id"})`,
        };
      });
    },
    isClientPage() {
      return Boolean(this.clientId);
    },
    isTempPage() {
      return Boolean(this.tempId);
    },
    getAreaCharNumber() {
      return (this.form.note && this.form.note.length) || 0;
    },
    progressUpdate() {
      let totalProgress = 0;
      const perFileProgressArr = Object.values(this.progressCompleted);
      if (perFileProgressArr.length) {
        const totalProgressCombined = perFileProgressArr.reduce((acc, curr) => {
          return acc + curr;
        }, 0);
        totalProgress = totalProgressCombined / perFileProgressArr.length;
      }

      return {
        width: totalProgress,
      };
    },
  },
  watch: {
    subjectTypes: {
      handler(val) {
        if (
          this.isCheckSubjectTypeActive &&
          this.subjectTypes.length &&
          !this.form.notetypes
        ) {
          this.isCheckSubjectTypeActive = false;
          this.setFirstSubject();
        }
      },
      immediate: true,
    },
    pendingRow: {
      handler(valObj) {
        this.setCheckFormData();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.setCheckFormData();
  },
  methods: {
    ...mapActions({
      findAgencyWorker: "findAgencyWorker",
      postNotes: "postNotes",
      postOneNote: "postOneNote",
    }),
    onAnimationEnd() {
      this.isFlashForm = false;
    },
    onClickUploadFiles() {
      this.isUploadVisible = !this.isUploadVisible;
    },
    onFilesChanged(filesEvt) {
      // const parsedFiles = [...filesEvt].map(eF => {
      //   return {
      //     fileName: eF.name,
      //     fileSize: eF.size,
      //   };
      // });
      // this.form.files // This will be always empty
      this.form.files = [...this.form.files, ...filesEvt];
    },
    onRemoveFile(id) {
      const index = this.form.files.findIndex((fileObj) => fileObj.id === id);
      if (index !== -1) {
        this.form.files.splice(index, 1);
      }
    },
    setCheckFormData() {
      if (Object.keys(this.pendingRow).length === 0) {
        this.setDefaultForm();
      } else {
        // When it triggers, update the form data
        this.setDataFromPending();
      }
    },
    setDefaultForm() {
      this.form = JSON.parse(JSON.stringify(this.defaultFormData));
    },
    setDataFromPending() {
      const valObj = this.pendingRow;
      const foundClient = this.clients.find(
        (client) => client.id === valObj.client_id
      );

      this.$set(this.form, "id", valObj.id);
      // this.form.id = valObj.id;
      this.form.notetypes = valObj.notetypes;
      if (foundClient) {
        this.form.client = this.clients.find(
          (client) => client.id === valObj.client_id
        );
      } else {
        this.form.client = null;
      }

      this.form.temp = valObj.temp || null;
      this.form.notetypes = valObj.notetypes;
      this.form.note = valObj.note;
      this.form.isprivate = valObj.isprivate;

      this.isFlashForm = false;
      this.$nextTick(() => {
        this.isFlashForm = true;
      });
    },
    onResetClick() {
      this.setDefaultForm();
      this.$emit("reset-form");
    },
    onAddedRow(evt) {
      this.clientInvoiceList.unshift(evt);
    },
    confirmAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async checkConfirmAlert() {
      if (this.getIsFormEdit) {
        try {
          await this.confirmAlert("update this note");
          return true;
        } catch (err) {
          // ignored
        }
        // This will never occur because promise doesn't reject
        return false;
      }
      return true;
    },
    async onClickSave() {
      const isConfirmed = await this.checkConfirmAlert();
      if (!isConfirmed) {
        return;
      }

      this.isSaveDisabled = true;

      const wholeParams = {};
      const params = new FormData();
      params.append(
        "notetype_id",
        this.form.notetypes && this.form.notetypes.id
      );
      params.append("isClient", this.isClientPage);
      params.append("for_client_journal", Boolean(this.clientId));

      if (this.form.note) {
        params.append("note", this.form.note);
      }
      // if (this.canCreatePrivateNotes || this.canViewPrivateNotes) {
      if (this.canCreatePrivateNotes) {
        params.append("isprivate", this.form.isprivate ? 1 : 0);
      }
      if (this.tempId) {
        params.append("temp_id", this.tempId);

        if (this.form.client && this.form.client.id) {
          // Backend doesn't accept empty values
          params.append("client_id", this.form.client.id);
        }
      } else if (this.clientId) {
        params.append("client_id", this.clientId);

        if (this.form.temp && this.form.temp.id) {
          // Backend doesn't accept empty values
          params.append("temp_id", this.form.temp.id);
        }
      }

      if (Array.isArray(this.form.files) && this.form.files.length) {
        this.form.files.forEach((fileObj) => {
          params.append("files[]", fileObj.file);
        });
        // params.append('files[]', this.form.files.map(fileObj => fileObj.file));
      }

      this.errors.clear();
      try {
        wholeParams.fd = params;
        let label = "Note created successfully";
        if (this.getIsFormEdit) {
          wholeParams.id = this.form.id;
          label = "Note edited successfully";
          await this.postOneNote(wholeParams);
        } else {
          await this.postNotes(wholeParams);
        }
        this.$emit("saved-form", params);
        this.$toasted.success(label).goAway(2000);
        this.setDefaultForm();
        this.isSaveDisabled = false;
      } catch (err) {
        console.warn(err.message);
        this.isSaveDisabled = false;
        const errs = parseErrors(err, "", true);
        if (errs) {
          this.$toasted.error(errs).goAway(6500);
        }

        if (err.response.data.errors) {
          this.errors.record(err.response.data.errors);
        }
      }
    },
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    setFirstSubject() {
      this.onSelectSubject(this.subjectTypes[0]);
      this.defaultFormData.notetypes = this.subjectTypes[0];
    },
    onSelectSubject(evt) {
      this.form.notetypes = evt;
    },
    onRemoveSubject(evt) {
      this.form.notetypes = null;
    },
    asyncFindWorker: _.debounce(async function (query) {
      if (query === "") return;
      this.isLoadingTemp = true;
      const params = {
        ...{ query },
        originallySelectedWorker: "",
      };
      await this.findAgencyWorker(params);
      this.isLoadingTemp = false;
    }, 400),
  },
};
</script>

<style lang="scss" scoped>
.journal-form {
  display: flex;
  gap: 10px;
  min-width: 800px;
  border: 1px solid silver;
  padding: 20px;
  position: relative;

  &.anima {
    animation-name: flash;
    animation-duration: 2s;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    // width: 100%;
    width: max-content;

    .top-section {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .upload-wrapper {
    min-width: 200px;
  }

  .right-side {
    // width: 100%;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .control {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      textarea {
        min-height: 170px;
        height: 100%;
      }
    }

    .char-number-overlay {
      position: absolute;
      // bottom: 5px;
      // right: 20px;
      bottom: 12px;
      right: 12px;
      padding: 0 3px;
      background: rgba(0, 0, 0, 0.2);
      color: white;
      user-select: none;

      &.offset {
        bottom: 32px;
      }
    }
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .private-checkbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .label {
        white-space: nowrap;
      }
    }

    .label {
      display: flex;
      align-items: center;
      margin: 0;
    }

    span {
      font-size: 14px;
    }

    .button {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    > .button:last-child {
      margin-left: auto;
    }
  }

  .progress-bar {
    display: flex;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;

    .line {
      background: var(--color-primary);
    }
  }

  @keyframes flash {
    0% {
      background-color: rgba(218, 214, 33, 0.363);
    }
    100% {
      background-color: transparent;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    min-width: unset;
  }
}
</style>
