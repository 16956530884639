/* eslint-disable no-unused-vars */

import { default as axios } from "../../api/config.js";
import _ from "underscore";
import moment from "moment";
const UPDATE_COSTCENTER_PROPERTY = "UPDATE_COSTCENTER_PROPERTY";
const UPDATE_CLIENT_PROPERTY = "UPDATE_CLIENT_PROPERTY";
const UPDATE_CLIENT = "UPDATE_CLIENT";

function setClientCategoryType(client) {
  if (client.locations.length === 0) {
    client.category_type = "";
    return;
  }

  let initialCatId = 0;
  let name = "";
  for (let i = 0; i < client.locations.length; i++) {
    for (let j = 0; j < client.locations[i].categories.length; j++) {
      if (client.locations[i].categories[j].id !== initialCatId) {
        if (initialCatId !== 0) {
          client.category_type = "mixed";
          return;
        } else {
          initialCatId = client.locations[i].categories[j].id;
          name = client.locations[i].categories[j].name;
        }
      }
    }
  }
  client.category_type = name;
}

/**
 * Keep this component's api methods locally
 */
const api = {
  fetchClients: (q) => {
    return axios.get("/clients", {
      params: { include: q.includes, page: q.page, optimise: q.optimise },
    });
  },
  fetchClientsOptimized: (params) => {
    return axios.get("/clients-optimized", {
      params: {
        include: "defaultShiftTimes",
        page: params.page,
        search: params.search,
        active: params.active,
      },
    });
  },

  fetchClientDetails: (q) => {
    return axios.get(`/clients/${q.id}/detail`, {
      params: { include: q.include },
    });
  },
  fetchClientInfo: (q) => {
    return axios.get(`/clients/${q.id}`, {
      params: { include: q.include },
    });
  },

  fetchRegions: (q) => {
    return axios.get("/regions", {
      params: { include: q.includes },
    });
  },

  fetchSectors: (q) => {
    return axios.get("/sectors", {
      params: { include: q.includes, for_client: q.forClient },
    });
  },

  fetchAllowances: (q) => {
    return axios.get("/calc-engines", {
      params: { include: q.includes },
    });
  },
  // OLD API
  // fetchAllowances: (q) => {
  //   return axios.get('/allowances', {
  //     params: { include: q.includes }
  //   })
  // },

  fetchServiceFeeTypes: () => {
    return axios({
      url: "/service-fee-calculation-types",
      method: "GET",
    });
  },

  fetchCategoryFees: (id) => {
    // return axios({
    //   url: 'http://localhost:8080/src/test_json/category_fees.json',
    //   method: 'GET'
    // })
    return axios.get(`/clients/${id}/service-fees-per-category`);
  },

  fetchAgencies: (q) => {
    return axios.get("/agencies", {
      params: { include: q.includes },
    });
  },
  updateCostCenter: (ccId, payload, include = "") => {
    const defaultInclude = "locationOnCallInfo,locationSleepoverInfo" + include;
    return axios.put(`/locations/${ccId}`, payload, {
      params: {
        // include: 'users,temps,categories.subcategories,region'
        include: defaultInclude,
      },
    });
  },

  createCostCenter: (payload) => {
    return axios.post(`/locations`, payload, {
      params: {
        // include: 'users,temps,categories.subcategories,region'
        include:
          "categories.subcategories,locationOnCallInfo,locationSleepoverInfo",
      },
    });
  },

  // Client Management > Copy location
  postCloneLocation: (params) =>
    axios.post(`locations/${params.id}/copy-location`, params, {
      params: {
        include:
          "users,temps,categories.subcategories,locationOnCallInfo,locationSleepoverInfo,region,defaultShiftTimes",
      },
    }),

  createClient: (payload) => {
    return axios.post(`/clients`, payload, {
      params: {
        include: [
          "locations.users",
          "locations.temps",
          "locations.categories.subcategories",
          "locations.locationOnCallInfo",
          "locations.locationSleepoverInfo",
          "region",
          "sectors",
          "calcEngine",
        ].join(","),
      },
    });
  },
  updateClient: (clientId, payload) => {
    return axios.put(`/clients/${clientId}`, payload, {
      params: {
        // This includes are required for updated values
        include: ["region", "calcEngine", "sectors"].join(","),
      },
    });
  },
  createDefaultShiftTime: (clientId, payload) => {
    console.log("Invoked createDefaultShiftTime API :: ", clientId, payload);
    return axios.post(`/default-shift-times`, {
      client_id: clientId,
      name: payload.name,
      start_time: payload.startTime,
      end_time: payload.endTime,
    });
  },
  deleteDefaultShiftTime: (shiftId) => {
    return axios.delete(`/default-shift-times/${shiftId}`);
  },
};

const state = {
  clients: [],
  counties: [],
  sectors: [],
  agencies: [],
  allowances: [],
  serviceFeeTypes: [],
  serviceCategoryFeeTypes: [],
  defaultShiftTimes: [],
  // Pagination made local because it collides with fetchTemps api call
  pagination: null,
};

const getters = {
  getClients: (state) => state.clients,
  getCounties: (state) => state.counties,
  getSectors: (state) => {
    const traverseSubs = (sectors) => {
      return sectors.reduce((acc, curr) => {
        if (curr.selectable) {
          // Add obj sector
          acc.push(curr);
        } else if (curr.subsectors) {
          const subSec = traverseSubs(curr.subsectors);
          // const subWithParent = { ...curr }
          // subWithParent.subsectors = [...subSec]
          acc.push(...subSec);
        }
        return acc;
      }, []);
    };

    const createSectorParents = (subs) => {
      return subs.reduce((acc, curr) => {
        if (!acc.includes(curr.sectorParent.name)) {
          acc.push(curr.sectorParent.name);
        }
        return acc;
      }, []);
    };

    const subs = traverseSubs(state.sectors);
    const parentSubs = createSectorParents(subs);

    const getSubsWithParent = (parents) => {
      const allSubs = [];
      for (const parentName of parents) {
        const genSubs = [];
        for (const sub of subs) {
          if (sub.sectorParent.name === parentName) {
            genSubs.push(sub);
          }
        }
        const genSubObj = {
          name: parentName,
          subs: genSubs,
        };
        allSubs.push(genSubObj);
      }
      return allSubs;
    };
    return getSubsWithParent(parentSubs);
  },
  getFlatSectors: (state) => {
    const traverseSubs = (sectors) => {
      return sectors.reduce((acc, curr) => {
        if (curr.selectable) {
          // Add obj sector
          acc.push(curr);
        } else if (curr.subsectors) {
          const subSec = traverseSubs(curr.subsectors);
          // const subWithParent = { ...curr }
          // subWithParent.subsectors = [...subSec]
          acc.push(...subSec);
        }
        return acc;
      }, []);
    };

    return traverseSubs(state.sectors);
  },
  getAllSectors: (state) => state.sectors,
  getAgencies: (state) => state.agencies,
  getAllowances: (state) => state.allowances || [],
  getServiceFeeTypes: (state) => state.serviceFeeTypes,
  getPagination: (state) => state.pagination,
  // getDefaultShiftTimes: (state) => state.defaultShiftTimes,
  getServiceCategoryFeeTypes: (state) => {
    state.serviceCategoryFeeTypes.forEach((category) => {
      category.service_fees.sort((a, b) => {
        return new Date(a.valid_from) - new Date(b.valid_from);
      });
    });
    return state.serviceCategoryFeeTypes;
  },
};

const actions = {
  fetchClients: ({ commit }, q) => {
    return api
      .fetchClients(q)
      .then((response) => {
        commit("STORE_CLIENTS", response.data);
        commit("SET_PAGINATION", response.data);
        return Promise.resolve();
      })
      .catch(() => console.log("LCM: Something went terribly wrong."));
  },

  fetchClientsOptimized: ({ commit }, params = {}) => {
    return api
      .fetchClientsOptimized(params)
      .then((response) => {
        commit("STORE_CLIENTS", response.data);
        commit("SET_PAGINATION", response.data);
        return Promise.resolve();
      })
      .catch(() => console.log("LCM: Something went terribly wrong."));
  },

  fetchClientDetails: async ({ commit }, q) => {
    try {
      const response = await api.fetchClientDetails(q);
      commit("SET_CLIENTS", { add: response.data.data, id: q.id });
      return response;
    } catch (err) {
      console.log("LCM: Something went terribly wrong.");
    }
    return null;
  },
  fetchClientInfo: async ({ commit }, q) => {
    try {
      return await api.fetchClientInfo(q);
    } catch (err) {
      console.log("LCM: Something went terribly wrong. 2");
      throw err;
    }
  },

  fetchRegions: ({ commit }, q = { includes: "" }) => {
    api
      .fetchRegions(q)
      .then((response) => {
        commit("STORE_COUNTIES", response.data);
      })
      .catch(() =>
        console.log("LCM: Something went wrong while fetching counties.")
      );
  },

  fetchSectors: ({ commit }, q = { includes: "" }) => {
    return api
      .fetchSectors(q)
      .then((response) => {
        commit("STORE_SECTORS", response.data);
      })
      .catch(() =>
        console.log("LCM: Something went wrong while fetching sectors.")
      );
  },

  fetchAllowances: ({ commit }, q = { includes: "" }) => {
    api
      .fetchAllowances(q)
      .then((response) => {
        commit("STORE_ALLOWANCES", response.data);
      })
      .catch(() =>
        console.log("LCM: Something went wrong while fetching allowances.")
      );
  },

  fetchServiceFeeTypes: ({ commit }, params) => {
    // fee category types for multiselect
    api.fetchServiceFeeTypes().then((response) => {
      commit("STORE_SERVICE_FEE_TYPES", response.data);
    });
  },

  fetchCategoryFees: ({ commit }, params) => {
    // fee per category for modal
    return api.fetchCategoryFees(params).then((response) => {
      commit("STORE_CATEGORY_SERVICE_FEE_TYPES", response.data);
    });
  },

  fetchAgencies: ({ commit }, q = { includes: "" }) => {
    api
      .fetchAgencies(q)
      .then((response) => {
        commit("STORE_AGENCIES", response.data);
      })
      .catch(() =>
        console.log("LCM: Something went wrong while fetching allowances.")
      );
  },

  createClient: ({ commit }, clientData) => {
    return new Promise((resolve, reject) => {
      api
        .createClient(clientData)
        .then((response) => {
          commit("ADD_NEW_CLIENT", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateCostCenterSubcategories: ({ commit }, { ccId, ids }) => {
    console.log("Updating costcenter ", ids);
    return api
      .updateCostCenter(
        ccId,
        {
          subcategories: ids,
        },
        ",categories.subcategories"
      )
      .then((response) => {
        try {
          commit(UPDATE_COSTCENTER_PROPERTY, {
            data: { id: ccId, ids, type: "subcategories" },
            patch: response.data,
          });
          return Promise.resolve("true");
        } catch (e) {
          console.log(e);
        }
      })
      .catch(() => {
        console.log("Update costcenter failed for ", ccId);
      });
  },

  updateCostCenterTemps: ({ commit }, { ccId, ids }) => {
    return api
      .updateCostCenter(ccId, { temps: ids })
      .then((response) => {
        try {
          commit(UPDATE_COSTCENTER_PROPERTY, {
            data: { id: ccId, ids, type: "temps" },
            patch: response.data,
          });
        } catch (e) {
          console.log(e);
        }
      })
      .catch(() => {
        console.log("Update costcenter failed for ", ccId);
      });
  },

  updateCostCenterUsers: ({ commit }, { ccId, ids }) => {
    return api.updateCostCenter(ccId, { users: ids }).then((response) => {
      commit(UPDATE_COSTCENTER_PROPERTY, {
        data: { id: ccId, ids, type: "users" },
        patch: response.data,
      });
    });
  },
  async updateCostCenter({ commit }, { id, payload, include = "" }) {
    try {
      const response = await api.updateCostCenter(id, payload, include);
      commit(UPDATE_COSTCENTER_PROPERTY, {
        data: { id, type: "name" },
        patch: response.data,
      });
      return response;
    } catch (err) {
      console.log(err.message);
      throw err;
    }
  },

  createCostCenterForClient: ({ commit }, params) => {
    console.log(
      "Invoked createCostCenterForClient: ",
      params.clientId,
      params.name
    );
    const client_id = params.clientId;
    return api.createCostCenter({ client_id, ...params }).then((response) => {
      commit(UPDATE_CLIENT_PROPERTY, {
        data: { id: client_id, type: "newCostCenter" },
        patch: response.data.data,
      });
    });
  },
  postCloneLocation: ({ commit }, params) => {
    return api.postCloneLocation(params).then((response) => {
      commit(UPDATE_CLIENT_PROPERTY, {
        data: { id: params.clientId, type: "newCostCenter" },
        patch: response.data.data,
      });
    });
  },

  updateClientAllowance: async ({ commit }, { clientId, calcengine_id }) => {
    const response = await api.updateClient(clientId, { calcengine_id });
    commit(UPDATE_CLIENT_PROPERTY, {
      data: { id: clientId, type: "updateAllowance" },
      patch: response.data.data,
    });
    return response;
  },
  updateClientAutoRepush: async ({ commit }, payload) => {
    const response = await api.updateClient(payload.clientId, payload.params);
    commit(UPDATE_CLIENT_PROPERTY, {
      data: { id: payload.clientId, type: "updateAutoRepush" },
      patch: response.data.data,
    });
    return response;
  },
  putClientStatus: async ({ commit }, { clientId, params }) => {
    try {
      const response = await api.updateClient(clientId, params);
      commit(UPDATE_CLIENT_PROPERTY, {
        data: { id: clientId, type: "updateClientStatus" },
        patch: response.data.data,
      });
      return response;
    } catch (error) {
      throw error.response.data.errors;
    }
  },
  putClientData: async ({ commit }, { clientId, params }) => {
    try {
      const response = await api.updateClient(clientId, params);
      commit(UPDATE_CLIENT, { patch: response.data.data });
      return response;
    } catch (error) {
      throw error.response.data.errors;
    }
  },
  updateCostCenterAllowance: async (
    { commit },
    { ccId, type, hasAllowanceParObj }
  ) => {
    const response = await api.updateCostCenter(ccId, hasAllowanceParObj);
    commit(UPDATE_COSTCENTER_PROPERTY, {
      data: { id: ccId, type },
      patch: response.data,
    });
    return response;
  },
  createDefaultShiftTime: async ({ commit }, { clientId, payload }) => {
    console.log("Invoked createDefaultShiftTime ACTION :: ", clientId, payload);
    console.log("je l ovde stigao client id", clientId);
    const response = await api.createDefaultShiftTime(clientId, payload);
    commit(UPDATE_CLIENT_PROPERTY, {
      data: { id: clientId, type: "defaultShiftTimes" },
      patch: response.data.data,
    });
    return response;
  },
  deleteDefaultShiftTime: async ({ commit }, { clientId, shiftId }) => {
    const response = await api.deleteDefaultShiftTime(shiftId);
    // TODO: Update location property
    commit(UPDATE_CLIENT_PROPERTY, {
      data: {
        id: clientId,
        shiftId: shiftId,
        type: "deleteDefaultShiftTimes",
      },
    });
    return response;
  },
};

const mutations = {
  STORE_CLIENTS(state, payload) {
    state.clients = payload.data;
    // In case client has no allowance,
    // set allowance to null

    for (let i = 0; i < state.clients.length; i++) {
      const aClient = state.clients[i];
      if (!_.has(aClient, "allowance")) {
        aClient.allowance = null;
      }
    }
  },

  STORE_COUNTIES(state, payload) {
    state.counties = payload.data;
  },

  STORE_SECTORS(state, payload) {
    state.sectors = payload.data;
  },

  STORE_ALLOWANCES(state, payload) {
    state.allowances = payload.data;
  },

  STORE_SERVICE_FEE_TYPES(state, payload) {
    // tipovi fees
    state.serviceFeeTypes = payload.data;
  },

  STORE_CATEGORY_SERVICE_FEE_TYPES(state, payload) {
    payload.data.forEach((category) => {
      category.showMore = false;
      category.service_fees.forEach((type) => {
        type.old_valid_from = type.valid_from;
        type.service_fee_calculation_type.old_id =
          type.service_fee_calculation_type.id;
        type.disbaled = false;
        // Meta is used only for internal UI purposes and shouldn't be sent to the API
        type.meta = {
          dateHelper: {
            time: moment(type.valid_from, "YYYY-MM-DD HH:mm:ss")
              .format("DD/MM/YYYY HH:mm:ss")
              .valueOf(),
          },
        };
      });
    });
    state.serviceCategoryFeeTypes = payload.data;
  },

  STORE_NEW_SERVICE_FEES(state, payload) {
    const categoryId = payload.data.id;
    const serviceFees = payload.data.serviceFees;
    for (let i = 0; i < payload.data.serviceFees.length; i++) {
      payload.data.serviceFees[i].new = true;
      payload.data.serviceFees[i].meta = {
        dateHelper: { time: payload.data.serviceFees[i].valid_from },
      };
    }

    for (let j = 0; j < state.serviceCategoryFeeTypes.length; j++) {
      if (state.serviceCategoryFeeTypes[j].id === categoryId) {
        state.serviceCategoryFeeTypes[j].service_fees.push(...serviceFees);
      }
    }
  },

  UPDATE_SERVICE_FEES(state, payload) {
    const categoryId = payload.data.id;
    const index = payload.data.index;
    for (let i = 0; i < state.serviceCategoryFeeTypes.length; i++) {
      if (state.serviceCategoryFeeTypes[i].id === categoryId) {
        state.serviceCategoryFeeTypes[i].service_fees.splice(index, 1);
      }
    }
  },

  STORE_AGENCIES(state, payload) {
    state.agencies = payload.data;
  },

  SET_PAGINATION(state, { data, meta }) {
    state.pagination = meta.pagination;
  },

  UPDATE_COSTCENTER_PROPERTY(state, { data, patch }) {
    console.log("Patch :: ", patch, state.clients);
    let location = {};
    const categories = patch.data.categories;
    let clientChanged = null;
    // Find the location in all clients
    for (const client of state.clients) {
      if (!client.locations) continue;
      for (const tempCC of client.locations) {
        if (tempCC.id === data.id) {
          location = tempCC;
          clientChanged = client;
          break;
        }
      }
    }

    if (data.type === "subcategories") {
      location["categories"] = categories;
      setClientCategoryType(clientChanged);
    } else if (data.type === "temps") {
      location["temps"] = patch.data.temps;
    } else if (data.type === "users") {
      location["users"] = patch.data.users;
    } else if (data.type === "hasLocationAllowance") {
      location["hasLocationAllowance"] = patch.data.hasLocationAllowance;
    } else if (data.type === "hasSecureAllowance") {
      location["hasSecureAllowance"] = patch.data.hasSecureAllowance;
    } else if (data.type === "hasCommunityAllowance") {
      location["hasCommunityAllowance"] = patch.data.hasCommunityAllowance;
    } else {
      console.log("Patching other value: ", data.type, patch);
      location.name = patch.data.name;
      location.archived = patch.data.archived;
    }
  },

  SET_CLIENTS(state, payload) {
    const clients = state.clients;
    clients.forEach((client) => {
      if (client.id === payload.id) {
        for (const prop in payload.add) {
          client[prop] = payload.add[prop];
        }
      }
    });
    console.log("STATE ", state.clients);
  },
  UPDATE_CLIENT_PROPERTY(state, { data, patch }) {
    const client = state.clients.find((client) => client.id === data.id);
    console.log("CLIENT :: ", client);
    if (!client) {
      return;
    }

    if (data.type === "newCostCenter") {
      client.locations.push(patch);
    } else if (data.type === "updateAllowance") {
      client["allowance"] = patch.allowance;
    } else if (data.type === "updateAutoRepush") {
      client.autorepushactive = patch.autorepushactive;
      client.autorepushminutes = patch.autorepushminutes;
    } else if (data.type === "defaultShiftTimes") {
      if (client["defaultShiftTimes"] === undefined) {
        client["defaultShiftTimes"] = [];
      }
      client["defaultShiftTimes"].push(patch);
    } else if (data.type === "deleteDefaultShiftTimes") {
      const ind = _.findIndex(client["defaultShiftTimes"], {
        id: data.shiftId,
      });
      client["defaultShiftTimes"].splice(ind, 1);
    } else if (data.type === "updateClientStatus") {
      client.active = patch.active;
      // client.status = patch.status
    }
  },
  ADD_NEW_CLIENT(state, payload) {
    state.clients.push(payload.data);
  },
  UPDATE_CLIENT(state, { patch }) {
    const fClientIndex = state.clients.findIndex(
      (client) => client.id === patch.id
    );
    if (fClientIndex !== -1) {
      const mergedData = { ...state.clients[fClientIndex], ...patch };
      state.clients.splice(fClientIndex, 1, mergedData);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
