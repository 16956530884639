
import api from '../../api/calendar';
import _ from 'underscore';
import moment from 'moment';

import auth from '../../lib/auth.js';

const state = {
  days: {},
  monthDateStrings: [],
};

const getters = {
  getCalendarDays: state => {
    return state.days;
  },
  /**
   * Formats api reponse to contain day in a week,
   * along with each object returned
   */
  getCalendarDaysFormatted: state => {
    if (!state.days) {
      // Failsafe
      return {};
    }

    let today = moment();
    let stateDays = JSON.parse(JSON.stringify(state.days));

    _.map(stateDays, (day, key, stateDays) => {
      const m = moment(key);
      stateDays[key]['weekday'] = m.format('dddd');
      /**
       * mark days that fall in past
       */
      stateDays[key]['in-past'] = !m.isAfter(today) && !m.isSame(today, 'd');
    });
    return stateDays;
  },
  /**
   * Prepares data to be rendered into calendar cells
   */
  mapDateStringsToApi: (state, getters) => {
    return state.monthDateStrings.map(daystring => {
      let today = moment();
      let m = moment(daystring);
      let areThereResults = getters.getCalendarDaysFormatted[daystring];

      if (areThereResults === undefined) {
        return { [daystring]: { 'weekday': m.format('dddd'), 'in-past': !m.isAfter(today) && !m.isSame(today, 'd') } };
      }
      return { [daystring]: areThereResults };
    });
  },
};

const actions = {
  setCalendarDaysArr ({ commit }, days = {}) {
    const params = {
      data: days,
    };
    commit('STORE_DAYS', params);
  },
  getShiftsForDateRange: ({ commit }, { payload, cancelTokenSource }) => {
    let url = 'admin/calendar';
    if (payload.tempId) {
      url = `temps/${payload.tempId}/calendar`;
    } else if (auth && auth.user.role === 'temp') {
      url = 'calendar';
    }

    return api.getShiftsForDateRange(url, payload, cancelTokenSource)
      .then(response => {
        if (response.status === 200) {
          commit('STORE_DAYS', response.data);
          return Promise.resolve();
        }
      })
      .catch(error => console.log(error));
  },

  getShiftsForSingleDay: ({ commit }, payload) => {
    let url = 'admin/calendar-date-shifts';
    return api.getShiftsForDay(url, payload)
      .then(response => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch(error => console.log(error));
  },

  fillMonthDateStrings: ({ commit }, payload) => {
    const daysInMonth = [];

    let startWith = null;
    let { cursor } = payload;
    /**
    * if monthGiven, use that instead of current month
    * TODO refactor this one... to many ifs
    */
    if (cursor) {
      if (cursor < 0) {
        startWith = moment().subtract(Math.abs(cursor), 'months').startOf('month');
      } else if (cursor > 0) {
        startWith = moment().add(Math.abs(cursor), 'months').startOf('month');
      } else {
        startWith = moment().startOf('month');
      }
    } else {
      startWith = moment().startOf('month');
    }

    // eslint-disable-next-line no-unused-vars
    _.times(startWith.daysInMonth(), function (n) {
      daysInMonth.push(startWith.format('YYYY-MM-DD'));
      startWith.add(1, 'day');
    });

    commit('SET_MONTH_DATE_STRINGS', daysInMonth);
    return Promise.resolve('');
  },

  saveAvailability ({ commit }, params) {
    return api.saveAvailability(params);
  },
  saveMultiAvailabilities ({ commit }, params) {
    return api.saveMultiAvailabilities(params);
  },
  getOneAvailability ({ commit }, params) {
    return api.getOneAvailability(params);
  },
};

const mutations = {
  STORE_DAYS (state, { data }) {
    state.days = data;
  },

  SET_MONTH_DATE_STRINGS (state, daysInMonth) {
    state.monthDateStrings = daysInMonth;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
