<template lang="pug">
.invoices
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :scrollable="modalScrollable",
    size="600"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @fetchInvoices="fetchInvoicesAfterCreate",
      @cancel-modal="modalVisible = false",
      @disposeModal="closeModal"
    )
  section.section
    h1.title.is-3
      i.icon-Billing
      | Invoices
  uni-tabs(:tabs="filteredTabs", v-if="filteredTabs.length > 1")
  router-view(
    :hospitals="hospitals",
    @filterInvoices="filterInvoices",
    @show-create-invoice-modal="onShowCreateInvoice",
    @page-has-changed="pageHasChanged"
  )
</template>

<script>
import { Evt } from "../../lib/helpers/Evt.js";
import { mapGetters, mapActions, mapState } from "vuex";
// import TabAllInvoicedShifts from './TabAllInvoicedShifts.vue'
import BillingDetails from "../in-modal/invoices/BillingDetails.vue";
import CreateInvoice from "../in-modal/invoices/CreateInvoice.vue";

export default {
  name: "InvoicesPage",
  components: {
    BillingDetails,
    CreateInvoice,
  },
  data() {
    return {
      modalVisible: false,
      inmodal: "billing-details",
      hospitals: [],
      pageNum: 1,
      selectedClient: { id: 0 },
      modalScrollable: true,
    };
  },
  computed: {
    ...mapGetters({
      getAllClients: "getAllClients",
      getBillingDetails: "invoices/getBillingDetails",
      activeTab: "getActiveTab",
    }),
    tabs() {
      return [
        {
          label: "Invoices",
          routeName: "tab-invoices",
          permission: "view-billing",
        },
        {
          label: "Holiday Balances",
          routeName: "PayBillHolidayBalances",
          permission: "view-holiday-requests-billing",
          systemPermissions: ["can_use_holiday_requests"],
        },
        {
          label: "Expenses",
          routeName: "PayBillExpenses",
          permission: "view-expenses-billing",
          systemPermissions: ["can_use_expenses"],
        },
        // {
        //   label: 'ALL INVOICED SHIFTS',
        //   routeName: TabAllInvoicedShifts
        // },
      ];
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
  },
  watch: {
    $route(comp, oldComp) {
      if (comp.name !== oldComp.name) {
        this.resetPageNumber(); // This will trigger route change because of query change

        if (comp.name === "tab-invoices") {
          this.fetchWrapperInvoices();
        } else if (comp.name === "TabAllInvoicedShifts") {
          // Unused
          this.fetchWrapperShiftInvoices();
        }
      }
    },
    getAllClients: function () {
      this.fillOptionsClients();
    },
  },
  created() {
    if (this.$route.name === "InvoicesPage") {
      const firstElRoute = this.filteredTabs?.[0]?.routeName || "tab-invoices";
      this.$router.replace({
        name: firstElRoute,
      });
    }
  },
  mounted() {
    this.invokeFetching(true);

    Evt.listen("getBillingDetails", (id) => {
      this.fetchBillingDetails(id).then(() => {
        this.showModal(
          "billing-details",
          { billingDetails: this.getBillingDetails },
          "Billing Details"
        );
      });
    });
    Evt.listen("fetchBackup", (invoice) => this.fetchBackup(invoice));
    Evt.listen("fetchPayAndBill", (invoice) => this.fetchPayAndBill(invoice));
    Evt.listen("fetchPDF", (invoice) => this.fetchPDF(invoice));
    Evt.listen("deleteInvoice", async (invoice) => {
      try {
        await this.deleteInvoice(invoice);
      } catch (err) {
        const msg = err?.response?.data?.message;
        this.$toasted.error(msg || "Error deleting invoice").goAway(4000);
      }
      this.fetchWrapperInvoices({ clientId: this.selectedClient.id });
    });
    Evt.listen("showNewInvoiceModal", (createForAllClients) => {
      const modalTitle = createForAllClients
        ? "Create invoices for all clients"
        : "Create Invoice";
      this.showModal(
        "createInvoice",
        { createForAllClients },
        modalTitle,
        false
      );
    });
    this.fetchClientsList({ per_page: 999 });
    Evt.listen("backupInvoicedShift", (data) => this.backupInvoicedShift(data));
    Evt.listen("createInvoice", (id) => {
      this.createInvoice(id)
        .then(() => {
          this.$toasted.info("Invoice created").goAway(1500);
          this.fetchWrapperInvoices({ clientId: this.selectedClient.id });
        })
        .catch((err) => {
          const msg = err?.response?.data?.message;
          this.$toasted.error(msg || "Error creating invoice").goAway(4000);
        });
    });
  },
  beforeDestroy() {
    Evt.off("getBillingDetails");
    Evt.off("fetchBackup");
    Evt.off("fetchPayAndBill");
    Evt.off("fetchPDF");
    Evt.off("deleteInvoice");
    Evt.off("showNewInvoiceModal");
    Evt.off("backupInvoicedShift");
    Evt.off("createInvoice");
  },
  methods: {
    pageHasChanged(pageNum) {
      this.pageNum = pageNum || 1;
      this.invokeFetching();
    },
    invokeFetching(isCheckPageFromRoute = false) {
      if (isCheckPageFromRoute && this.$route.query.page) {
        this.pageNum = this.$route.query.page || 1;
      }

      if (this.$route.name === "tab-invoices") {
        this.fetchWrapperInvoices({ clientId: this.selectedClient.id });
      } else if (this.$route.name === "TabAllInvoicedShifts") {
        this.fetchWrapperShiftInvoices();
      }
    },
    resetPageNumber(pageNum = 1) {
      this.pageNum = pageNum;
      this.$router.replace({ query: { page: pageNum } });
    },
    fetchInvoicesAfterCreate() {
      this.resetPageNumber();
      this.fetchWrapperInvoices({ clientId: this.selectedClient.id });
    },
    filterInvoices(client) {
      if (client) {
        this.selectedClient = client;
      } else {
        this.selectedClient = { id: 0 };
      }
      this.resetPageNumber();
      this.fetchWrapperInvoices({ clientId: this.selectedClient.id });
    },
    ...mapActions({
      fetchInvoices: "invoices/fetchInvoices",
      fetchClientsList: "fetchClientsList",
      fetchBackup: "invoices/fetchBackup",
      fetchPayAndBill: "invoices/fetchPayAndBill",
      fetchPDF: "invoices/fetchPDF",
      fetchShiftInvoices: "invoices/fetchShiftInvoices",
      deleteInvoice: "invoices/deleteInvoice",
      fetchBillingDetails: "invoices/fetchBillingDetails",
      createInvoice: "invoices/createInvoice",
      backupInvoicedShift: "invoices/backupInvoicedShift",
    }),
    fillOptionsClients() {
      this.hospitals = this.getAllClients.map((client) => {
        return {
          id: client.id,
          name: client.name,
        };
      });
    },
    showModal(modal, modalData, modalTitle, modalScrollable = true) {
      this.modalScrollable = modalScrollable;
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle;
      this.modalVisible = true;
    },
    closeModal(data) {
      // check if some invoice had been created before the user closed the modal, to update current information in the table
      if (data) {
        this.fetchWrapperInvoices({ clientId: this.selectedClient.id });
      }
      this.modalVisible = false;
    },
    fetchWrapperInvoices(params = {}) {
      // Adds page num
      return this.fetchInvoices({ ...params, page: this.pageNum });
    },
    fetchWrapperShiftInvoices(params = {}) {
      // Adds page num
      return this.fetchShiftInvoices({ ...params, page: this.pageNum });
    },
    onShowCreateInvoice(createForAllClients) {
      const modalTitle = createForAllClients
        ? "Create invoices for all clients"
        : "Create Invoice";
      this.showModal(
        "createInvoice",
        { createForAllClients },
        modalTitle,
        false
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$color--light-blue: #8399a6;
.control {
  i {
    top: 10px;
    position: absolute;
    right: 20px;
    font-size: 14px;
    color: $color--light-blue;
  }
}

.icon-Billing {
  margin-top: 10px;
  margin-right: 5px;
}
</style>
<style lang="scss">
.invoices {
  .modal-content {
    overflow: unset;
  }

  .panel-block {
    max-height: 750px;
    overflow-y: unset;
    align-items: baseline;
  }
}
</style>
