<template lang="pug">
#client-contacts-info
  .spacer-top
    .field(v-if="!canShowContactType")
    .field.contact-type(v-if="canShowContactType")
      label.label Type
      multi-select(
        :options="contactTypeOptions",
        track-by="name",
        label="label",
        :close-on-select="true",
        select-label="",
        selected-label="",
        deselect-label="",
        :value="contactTypeSelected",
        placeholder="Select contact type",
        @select="onChangeContactType"
      )
    .field.contact-type(v-if="canShowCategoryField")
      label.label Category
      multi-select(
        :options="categoriesOptions",
        track-by="id",
        label="name",
        :close-on-select="true",
        select-label="",
        selected-label="",
        deselect-label="",
        :loading="isLoadingCats",
        :value="selectedCategory",
        placeholder="Select category",
        @input="onInputCategory"
      )
  router-view(:category="selectedCategory", v-if="canDisplayRoute")
</template>

<script>
import api from "@/api";

export default {
  name: "ClientContactsInfo",
  data() {
    return {
      contactTypeOptions: [
        {
          name: "contacts",
          label: "Contacts",
          routeName: "ClientContactsDefaultPage",
        },
        {
          name: "shift-confirm",
          label: "Shift confirmation contacts",
          routeName: "ClientContactsShiftConfirmPage",
          permission: "view-shift-confirmation-contacts",
          systemPermissions: ["can_use_shift_confirmation_engine"],
        },
      ],
      categoriesOptions: [],
      isLoadingCats: false,
    };
  },
  computed: {
    filteredTabs() {
      return this.contactTypeOptions.filter((item) => {
        const hasPerm =
          "permission" in item
            ? typeof item.permission === "string"
              ? this.$can(item.permission)
              : item.permission
            : true;
        const hasSysPerm = item.systemPermissions?.length
          ? item.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = item.conditions?.length
          ? item.conditions.every(Boolean)
          : true;

        const customCheck = this.shiftConfirmEnablement;
        return (hasPerm && hasSysPerm && hasCondition) || customCheck;
      });
    },
    canShowContactType() {
      return this.filteredTabs.length > 1;
    },
    canShowCategoryField() {
      return false;
      // TODO Uncomment later, when backend if completed
      // return this.contactTypeSelected.name === "shift-confirm";
    },
    contactTypeSelected() {
      const currRouteName = this.$route.name;
      return this.filteredTabs.find((type) => type.routeName === currRouteName);
    },
    selectedCategory() {
      const currRouteQuery = this.$route.query;
      const catObj = this.categoriesOptions.find(
        (item) => item.id === parseInt(currRouteQuery.cat, 10)
      );
      return catObj ?? null;
    },
    clientId() {
      return this.$route.params.id;
    },
    canDisplayRoute() {
      if (this.canShowCategoryField && !this.selectedCategory) {
        return false;
      }
      return true;
    },
  },
  watch: {
    canShowCategoryField: {
      handler(val) {
        if (val && !this.categoriesOptions.length) {
          this.fetchCategories();
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChangeContactType(evt) {
      const currentQuery = this.$route.query;
      this.$router
        .push({ name: evt.routeName, query: currentQuery })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    },
    onInputCategory(evt) {
      const tempQuery = { ...this.$route.query };
      tempQuery.cat = evt ? evt.id : undefined;

      this.$router
        .push({ name: this.$route.name, query: tempQuery })
        .catch((err) => {
          console.log("Invalid route :: ", err.message, this.$route);
        });
    },
    async fetchCategories() {
      this.isLoadingCats = true;
      const params = { client_id: this.clientId };
      try {
        const res = await api.getClientCategories(params);
        this.categoriesOptions = res.data.data;
      } catch (err) {
        console.log("Error", err.message);
      } finally {
        this.isLoadingCats = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#client-contacts-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  position: relative;
  height: 100%;

  .contact-type {
    min-width: 250px;
  }

  .spacer-top {
    display: flex;
    // justify-content: space-between; // Don't use
    align-items: center;
    gap: 10px;

    // input {
    //   box-shadow: none;
    //   border: 1px solid #9bb4c1;
    //   height: 37px;
    // }

    .field {
      position: relative;
      min-height: 40px;

      &.search {
        width: 25%;
      }

      i {
        top: 10px;
        position: absolute;
        right: 15px;
        font-size: 14px;
      }
    }
  }
}
</style>
