<template lang="pug">
#compliance
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    icon="clock-o",
    minWidth="550",
    size="700"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false",
      @refresh-data="onRefreshData"
    )
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  section.section
    h1.title.is-3
      i.fa.icon-Compliance-management
      | Documents awaiting approval
    table.table.is-striped(
      data-section="awaiting-approval",
      @click="info($event.target)"
    )
      thead
        tr
          th NAME
          th CATEGORIES
          th DOCUMENT
          th(style="width: 250px") TAGS
          th SUBMISSION DATE
          th EXPIRY DATE
          th STATUS
          th VIEW
          th REJECT
      tfoot
      tbody
        tr(v-if="isLoadingMiniUpperDocs")
          td.mini-loader(colspan="100")
            img(src="../../assets/images/comps/loader.svg")
        tr(
          v-if="docs && !loadingUpperTable",
          v-for="{ id, name, categories, filesCount, expiryDate, createdAt, temp, canEditExpiry, canUpload, canViewFile } in docs"
        )
          td(data-cell="tempName") {{ temp.name }}
          td(data-cell="categories", style="font-style: italic") {{ categories | commaSeparated }}
          td(data-cell="name") {{ name }}
          td.tags-cell(:title="getTempTagNames(temp)") 
            template(v-if="Array.isArray(temp.tags) && temp.tags.length > 0") {{ getTempTagNames(temp) }}
          td(data-cell="filesCreatedAt") {{ (createdAt && formatDate(createdAt)) || "-" }}
          td(data-cell="expiryDate", v-if="expiryDate")
            button.button.is-danger.is-tiny.is-low.is-outlined(
              v-if="canEditExpiry",
              v-tooltip="'Click to set new expiry date.'",
              @click="showModal('change-expiry-date', { id, temp, expiryDate, context: 'documentsForApproval' }, 'Set new expiry date')"
            ) {{ getExpiryDateFormatted(expiryDate) }}
            span(v-else) {{ getExpiryDateFormatted(expiryDate) }}
          td(data-cell="notExpiryDate", v-if="!expiryDate") -
          td(data-cell="validate")
            button.button.is-caps-lock.is-tiny.is-low(
              v-if="canUpload",
              @click="approve({ tempId: temp.id, documentId: id, modifiers: { context: 'documentsForApproval', remove: true, action: 'validate' } })"
            ) validate
          td(data-cell="check")
            button.button.is-caps-lock.is-generic-app-blue.is-tiny.is-low(
              v-if="canViewFile && filesCount",
              @click="showModal('check-temp-documents', { temp: temp.id, document: id }, 'Check Temp Documents')"
            )
              i.fa.fa-eye
              | check
          td(data-cell="reject")
            button.button.is-generic-app-reject.is-caps-lock.is-tiny.is-low.clickable(
              v-if="canUpload",
              style="font-weight: bold",
              @click="showModal('reject-temp-document', { tempId: temp.id, documentId: id, modifiers: { context: 'documentsForApproval', remove: true, action: 'reject' } }, 'Reject Temp Document')"
            ) reject
        tr(v-if="loadingUpperTable")
          td.center-text(colspan="100")
            img(src="../../assets/images/comps/loader.svg")

  section.section
    h1.title.is-3
      i.fa.icon-Compliance-management
      |
      | Compliance by candidate
    .columns
      .column
        .columns
          .column
            p.control
              multi-select#Sectors(
                :options="getSectors",
                track-by="id",
                label="name",
                placeholder="SELECT REGION",
                :multiple="true",
                v-model="query.selectedSectors",
                group-label="name",
                group-values="subs",
                :showLabels="false"
              )
                //- @input="isChangedSectorsList"
          .column
            p.control
              multi-select#Client(
                :options="filteredClients",
                :multiple="true",
                track-by="id",
                label="name",
                v-model="query.selectedClients",
                placeholder="SELECT CLIENT"
              )
                span(slot="noResult") Nothing found.
          .column
            p.control
              multi-select(
                :options="filteredCategories",
                :multiple="true",
                track-by="id",
                label="category",
                placeholder="SELECT CATEGORY",
                v-model="query.selectedCategories",
                :showLabels="false"
              )
          .column
            p.control
              multi-select(
                :options="filteredSubcategories",
                :multiple="true",
                track-by="id",
                label="name",
                placeholder="SELECT SUBCATEGORY",
                v-model="query.selectedSubcategories",
                group-label="category",
                group-values="subs",
                :showLabels="false"
              )
        .columns
          .column
            p.control
              multi-select#Statuses(
                :options="optionsStatus",
                track-by="id",
                label="label",
                :allow-empty="false",
                v-model="query.selectedStatus",
                placeholder="SELECT STATUS",
                :showLabels="false"
              )
              //- span.info(
              //-   v-tooltip="{content:`The status filter is only applicable to the 'Client Activity Report'.`, classes: 'report-status-tooltip'}"
              //- )
              //-   span.fa.fa-info.popup
          .column
            date-picker(
              :date="query.workedSince",
              :option="customDateTimeOption"
            )
          .column
            p.control
              input.input(
                type="text",
                placeholder="Search",
                v-model="query.search"
              )
          .column
            p.control(
              v-tooltip="'Select client to be able to download the Report'"
            )
              button.button.is-generic-app-blue(
                :class="{ 'is-loading': isProcessingReport }",
                :disabled="loadingLowerTable || isProcessingReport",
                @click="downloadReport"
              ) GENERATE DOCUMENT REPORT
                //- query.selectedClients.length === 0
    table.table.is-striped(
      data-section="candidate-compliance",
      @click="info($event.target)"
    )
      thead
        tr
          th TSS ID
          th WORKER NAME
          th STATUS
          th GRADE
          th PHONE NUMBER
          th MAIL
          //- th  COST CENTRES
          th ACTION
      tfoot
      tbody
        tr(v-if="temps && !loadingLowerTable", v-for="temp in temps")
          td(data-cell="id") {{ temp.tssId }}
          td(data-cell="name") {{ temp.name }}
          td
            span(:style="setStatus(temp.status)") {{ getStatusText(temp.status) }}
          //- take off that limit later!
          td(
            data-cell="subcategories",
            :title="temp.subcategories | commaSeparated"
          ) {{ temp.subcategories | limit(2) | commaSeparated }}
          td(data-cell="homePhone") {{ temp.homePhone }}
          td(data-cell="email") {{ temp.email }}
          //- td {{ temp.locations | commaSeparated }}
          td(data-cell="locations")
            router-link.button.is-generic-app-blue.is-caps-lock.is-tiny.is-low(
              :to="{ name: 'temp-compliance', params: { tempId: temp.id } }"
            )
              i.fa.fa-eye
              | check
        tr(v-if="loadingLowerTable")
          td.center-text(colspan="100")
            img(src="../../assets/images/comps/loader.svg")
    pagination(
      v-if="pagination",
      :total-pages="pagination.total_pages",
      :current-page="pagination.current_page",
      @page-changed="pageHasChanged"
    )
</template>
<script>
import calendarOptions from "../common/calendarOptions.js";
import { mapActions, mapGetters } from "vuex";
import CheckTempDocuments from "../in-modal/compliances/CheckTempDocuments.vue";
import RejectTempDocument from "../in-modal/compliances/RejectTempDocument.vue";
import ChangeExpiryDate from "../in-modal/compliances/ChangeExpiryDate.vue";
import MultiSelect from "vue-multiselect";
import Pagination from "../Pagination.vue";

import { Evt } from "../../lib/helpers/Evt.js";
import { getFormattedTime, parseErrors } from "../../lib/helpers/function.js";
import _ from "underscore";

export default {
  name: "Compliance",
  components: {
    MultiSelect,
    CheckTempDocuments,
    RejectTempDocument,
    ChangeExpiryDate,
    Pagination,
  },
  data() {
    return {
      dateTimeOption: null,
      MultiShift: null,

      modalVisible: false,
      loadingUpperTable: true,
      loadingLowerTable: true,
      currentPage: 1,

      optionsClient: [],

      query: {
        search: "",
        selectedSectors: [],
        selectedClients: [],
        selectedCategories: [],
        selectedSubcategories: [],
        selectedStatus: null,
        workedSince: {
          time: "",
        },
      },

      dlReportDisabled: true,
      isProcessingReport: false,
      isLoadingMiniUpperDocs: false,

      optionsStatus: [
        {
          id: 0, // This will fallthrough
          label: "All",
          backColor: "white",
        },
        {
          id: 1,
          label: "Active",
          backColor: "#23d160",
        },
        {
          id: 2,
          label: "Review",
          color: "black",
          backColor: "#ffdb4a",
        },
        {
          id: 3,
          label: "Inactive",
          backColor: "#ff2b56",
        },
        {
          id: 4,
          label: "Approval",
          color: "black",
          backColor: "#a8bc46",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      temps: "getTemps",
      getSectors: "locationClientManagement/getSectors",
      allCategories: "getCategoriesWithSubcategories",
      docs: "getDocumentsForApproval",
      pagination: "getPagination",
      clientsAvailable: "getAllClients", // for dl report filter
    }),
    serverWorkedSince() {
      return this.query.workedSince.time
        ? getFormattedTime(
            this.query.workedSince.time,
            "YYYY-MM-DD",
            "DD/MM/YYYY"
          ) // outfmt and infmt in that order.
        : null;
    },
    customDateTimeOption() {
      return Object.assign({}, this.dateTimeOption, {
        placeholder: "Worked since?",
      });
    },
    filteredClients() {
      const subCatIdsArr = this.query.selectedSubcategories.map(
        (subCat) => subCat.id
      );
      const catIdsArr = this.query.selectedCategories.map(
        (catObj) => catObj.id
      );

      let cliArr = JSON.parse(JSON.stringify(this.optionsClient));

      //SELECTED REGION
      if (this.query.selectedSectors.length > 0) {
        const selectedSectorIdsArr = this.query.selectedSectors.map(
          (secObj) => secObj.id
        );
        cliArr = this.optionsClient.filter((cliObj) => {
          return cliObj.sectors.find((sectorObj) =>
            selectedSectorIdsArr.includes(sectorObj.id)
          );
        });
      }
      //SELECTED SUBCATEGORY
      if (this.query.selectedSubcategories.length > 0) {
        return cliArr.filter((cliObj) => {
          const catArr = cliObj.categories.map(
            (catObj) => catObj.subcategories
          );
          for (let i = 0; i < catArr.length; i++) {
            for (let j = 0; j < catArr[i].length; j++) {
              if (subCatIdsArr.includes(catArr[i][j].id)) {
                return cliObj;
              }
            }
          }
        });
      }
      //SELECTED CATEGORY
      if (this.query.selectedCategories.length > 0) {
        return cliArr.filter((cliObj) => {
          if (
            cliObj.categories.find((catObj) => catIdsArr.includes(catObj.id))
          ) {
            return cliObj;
          }
        });
      }

      return cliArr;
    },
    filteredCategories() {
      if (
        this.query.selectedClients.length > 0 &&
        this.query.selectedSubcategories.length > 0
      ) {
        const catArrs = this.query.selectedClients.map(
          (cliObj) => cliObj.categories
        );
        const uniqueCatIds = [];
        const categoriesFromClients = [];

        for (let i = 0; i < catArrs.length; i++) {
          for (let j = 0; j < catArrs[i].length; j++) {
            if (!uniqueCatIds.includes(catArrs[i][j].id)) {
              const catObj = JSON.parse(JSON.stringify(catArrs[i][j]));
              catObj.subs = catObj.subcategories;
              catObj.category = catObj.abbreviation;
              categoriesFromClients.push(catObj);
              uniqueCatIds.push(catObj.id);
            }
          }
        }

        const subCatParentIdsArr = this.query.selectedSubcategories.map(
          (subCat) => subCat.catParentId || subCat.parent_id
        );
        const uniqueCatParentIds = [...new Set(subCatParentIdsArr)];
        return categoriesFromClients.filter((catObj) => {
          if (uniqueCatParentIds.includes(catObj.id)) return catObj;
        });
      }

      if (this.query.selectedClients.length > 0) {
        const catArrs = this.query.selectedClients.map(
          (cliObj) => cliObj.categories
        );
        const uniqueCatIds = [];
        const categoriesFromClients = [];

        for (let i = 0; i < catArrs.length; i++) {
          for (let j = 0; j < catArrs[i].length; j++) {
            if (!uniqueCatIds.includes(catArrs[i][j].id)) {
              const catObj = JSON.parse(JSON.stringify(catArrs[i][j]));
              catObj.subs = catObj.subcategories;
              catObj.category = catObj.abbreviation;
              categoriesFromClients.push(catObj);
              uniqueCatIds.push(catObj.id);
            }
          }
        }
        return categoriesFromClients;
      }

      if (this.query.selectedSubcategories.length > 0) {
        const subCatParentIdsArr = this.query.selectedSubcategories.map(
          (subCat) => subCat.catParentId || subCat.parent_id
        );
        const uniqueCatParentIds = [...new Set(subCatParentIdsArr)];
        return this.allCategories.filter((catObj) => {
          if (uniqueCatParentIds.includes(catObj.id)) return catObj;
        });
      }

      return this.allCategories;
    },
    filteredSubcategories() {
      if (this.query.selectedCategories.length > 0)
        return this.query.selectedCategories;

      if (this.query.selectedClients.length > 0) return this.filteredCategories;

      return this.allCategories;
    },
  },
  watch: {
    "query.selectedSectors": {
      handler() {
        this.query.selectedClients = [];
        this.query.selectedCategories = [];
        this.query.selectedSubcategories = [];
      },
    },
    // Watches for any query prop changes, and triggers fireSearch() method
    // thus sending query string params with API call.
    query: {
      handler() {
        // Reset page and search for temps with criteria that has been set so far.
        this.currentPage = 1;
        this.fireSearch();
      },
      deep: true,
    },
    /**
     * When clients available from store, fill in the options box.
     * This is for Report download. (filtered by client id)
     */
    clientsAvailable() {
      this.fillOptionsClient();
    },
  },
  created() {
    this.setupDateTimePickerOpt();
  },
  mounted() {
    this.currentPage = parseInt(this.$route.query.page, 10) || 1;
    this.loadingUpperTable = true;
    /**
     * When no files present, remove document from local state only
     * Not sending DELETE request to API, because document still should exist, just unlinked.
     */
    Evt.listen("all-files-deleted", this.remove);
    /**
     * Get temp docs
     */
    this.fetchDocumentsForApproval({
      include: "categories,filesCount,tags",
    }).then(() => {
      this.loadingUpperTable = false;
    });
    this.loadLowerTable();

    const clientIncludes = [
      "sectors",
      "clientSubcategories",
      "categories.subcategories",
    ].join();
    this.fetchClientsList({
      includes: clientIncludes,
      per_page: 999,
    });

    this.fetchRejectionReasons();

    this.fetchSectors({
      includes: ["clients", "clients.locations"].join(","),
    });

    this.fetchAllCategoriesList();
  },
  beforeDestroy() {
    Evt.off("all-files-deleted");
  },
  methods: {
    ...mapActions({
      fetchDocumentsForApproval: "fetchDocumentsForApproval",
      fetchAllTemps: "fetchAllTemps",
      fetchSectors: "locationClientManagement/fetchSectors",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchRejectionReasons: "fetchRejectionReasons",
      approveTempDocument: "approveTempDocument",
      removeDocumentForApproval: "removeDocumentForApproval",
      fetchClientsList: "fetchClientsList",
      dlDocumentReport: "dlDocumentReport",
    }),
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "DD/MM/YYYY";
    },

    fireSearch: _.debounce(function () {
      this.loadLowerTable();
    }, 400),
    loadLowerTable() {
      const searchQuery = {};

      this.bindApiParamsToObj(searchQuery);

      this.loadingLowerTable = true;
      /**
       * Get all temps, including the query object as criteria.
       */
      this.fetchAllTemps({
        include: "subcategories",
        // include: 'locations.client,subcategories',
        page: this.currentPage,
        ...searchQuery,
      }).then(() => {
        this.loadingLowerTable = false;
      });
    },
    bindApiParamsToObj(paramsObj) {
      // Add search criteria to query object.
      if (this.query.search !== "") {
        paramsObj.name = this.query.search;
      }
      // Add `selected sectors` to query object.
      if (this.query.selectedSectors.length > 0) {
        paramsObj.sectors = this.query.selectedSectors.map((secObj) => ({
          id: secObj.id,
        }));
        paramsObj.clients = this.filteredClients.map((clientObj) => ({
          id: clientObj.id,
        }));
      }
      // Add `selected clients` to query object.
      if (this.query.selectedClients.length > 0) {
        paramsObj.clients = this.query.selectedClients.map((clientObj) => ({
          id: clientObj.id,
        }));
      }
      // Add `selected categories` to query object.
      if (this.query.selectedCategories.length > 0) {
        paramsObj.categories = this.query.selectedCategories.map((catObj) => ({
          id: catObj.id,
        }));
      }
      // Add `selected subcategories` to query object.
      if (this.query.selectedSubcategories.length > 0) {
        paramsObj.subcategories = this.query.selectedSubcategories.map(
          (subcatObj) => ({ id: subcatObj.id })
        );
      }
      // Add `date from` to query object.
      if (this.serverWorkedSince) {
        paramsObj.worked_since = this.serverWorkedSince;
      }
      // Add 'status' to query object.
      if (
        this.query.selectedStatus !== null &&
        this.query.selectedStatus.id > 0
      ) {
        paramsObj.status = this.query.selectedStatus.id;
      }
    },
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = `${modalTitle}`;
      this.modalVisible = true;
    },
    /**
     * Approve temp document
     * TODO show better feedback to user!
     */
    approve({ tempId, documentId, modifiers }) {
      const vm = this;
      const validateAlert = {
        title: "Approve this document?",
        message: "",
        type: "warning",
        useConfirmBtn: true,
        customConfirmBtnText: "Confirm",
        customConfirmBtnClass: "button is-generic-app-blue",
        customCloseBtnText: "Cancel",
        customCloseBtnClass: "button is-outlined",
        onConfirm() {
          vm.confirmApprove({ tempId, documentId, modifiers });
        },
      };
      this.$refs.simplert.openSimplert(validateAlert);
    },

    confirmApprove({ tempId, documentId, modifiers }) {
      this.approveTempDocument({
        tempId,
        documentId,
        modifiers,
      })
        .then(() => {
          this.$toasted
            .info("Document approved!", { theme: "outline" })
            .goAway(1500);
        })
        .catch((error) => {
          const errs = parseErrors(error);
          this.$toasted.error(errs).goAway(4500);
        });
    },

    // argument unpacked as `document` but assigned under new identifier `id`
    // silly but works!
    async remove({ document: id }) {
      try {
        const msg = await this.removeDocumentForApproval({
          id: id,
          message: "Document deleted!",
        });
        this.$toasted
          .info(msg, {
            theme: "outline",
          })
          .goAway(1500);
      } catch (err) {
        console.log("error", err.message);
      }
    },
    fillOptionsClient() {
      this.optionsClient = this.clientsAvailable.map((client) => {
        return {
          name: client.name,
          id: client.id,
          sectors: client.sectors,
          categories: client.categories,
        };
      });
    },
    async downloadReport() {
      if (!this.query.selectedClients.length === 0) return;

      try {
        this.isProcessingReport = true;
        const params = {};
        this.bindApiParamsToObj(params);
        await this.dlDocumentReport(params);
        this.isProcessingReport = false;
      } catch (err) {
        this.$toasted.error("No data for download").goAway(2000);
        this.isProcessingReport = false;
      }
    },
    pageHasChanged(pageNum) {
      this.currentPage = pageNum;
      this.loadLowerTable();
    },
    formatDate(dateTime) {
      return getFormattedTime(dateTime, "DD/MM/YYYY HH:mm:ss");
    },
    getExpiryDateFormatted(date) {
      return getFormattedTime(date, "DD/MM/YYYY");
    },
    setStatus(id) {
      const statusElements = [
        {
          id: 1,
          label: "Active",
          backColor: "#23d160",
        },
        {
          id: 2,
          label: "Review",
          color: "black",
          // backColor: '#ffdb4a'
          backColor: "#caae3e",
        },
        {
          id: 3,
          label: "Inactive",
          backColor: "#ff2b56",
        },
        {
          id: 4,
          label: "Approval",
          color: "black",
          backColor: "#a8bc46",
        },
      ];
      return {
        fontWeight: "bold",
        color: statusElements.find((statusEl) => statusEl.id === id).backColor,
      };
    },
    getStatusText(s) {
      let c;
      switch (s) {
        case 1:
          c = "Active";
          break;
        case 2:
          c = "Review";
          break;
        case 3:
          c = "Inactive";
          break;
        case 4:
          // Manager approval/Compliance Exception
          c = "Approval";
          break;
      }
      return c;
    },
    info: () => {
      // console.log(e)
    },
    getTempTagNames(temp) {
      return temp.tags.map((tag) => tag.name).join(", ");
    },
    async onRefreshData() {
      this.isLoadingMiniUpperDocs = true;
      await this.fetchDocumentsForApproval({
        include: "categories,filesCount,tags",
      });
      this.isLoadingMiniUpperDocs = false;
    },
  },
};
</script>
<style scoped lang="scss">
.button {
  .fa.fa-eye {
    font-size: 14px;
    margin-right: 10px;
    margin-top: 1px;
  }
}

.center-text {
  text-align: center;
  height: 150px;
  background: #f0f6f6;
  width: 50px;
}

.mini-loader {
  text-align: center;
  height: 60px;
}

// .columns {
//   align-items: flex-start;
// }
</style>
<style lang="scss">
#compliance {
  .modal-content {
    .panel {
      justify-content: center;
      height: 90vh;
    }
  }
}
</style>
