<template lang="pug">
.are-attached
  .field
    p.control
      button.button.is-generic-app-blue.is-outlined(
        @click="setFilter('today')",
        :class="{ 'is-active': active === 'today' }"
      )
        strong Today
  .field
    p.control
      button.button.is-generic-app-blue.is-outlined(
        @click="setFilter('tomorrow')",
        :class="{ 'is-active': active === 'tomorrow' }"
      )
        strong Tomorrow
  .field
    p.control
      button.button.is-generic-app-blue.is-outlined(
        @click="setFilter('this-week')",
        :class="{ 'is-active': active === 'this-week' }"
      )
        strong This week
  .field
    p.control
      button.button.is-generic-app-blue.is-outlined(
        @click="setFilter('next-week')",
        :class="{ 'is-active': active === 'next-week' }"
      )
        strong Next week
  .field
    p.control
      button.button.is-generic-app-blue.is-outlined(
        @click="setFilter('reset')"
      )
        strong Reset
</template>
<script>
import moment from "moment";
export default {
  model: {
    prop: "groupFilter",
    event: "click",
  },
  props: {
    groupFilter: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    active: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // active: '',
      format: "YYYY-MM-DD",
      m: moment(),
    };
  },
  methods: {
    f(what) {
      return what.format(this.format);
    },
    setFilter(period) {
      // this.active = period
      let p = ((period) => {
        switch (period) {
          case "today":
            return {
              from: this.f(this.m),
              to: this.f(this.m),
            };
          case "tomorrow":
            return {
              from: this.f(moment().add(1, "days")),
              to: this.f(moment().add(1, "days")),
            };
          case "this-week":
            return {
              from: this.f(moment().day(1)),
              to: this.f(moment().day(7)),
            };
          case "next-week":
            return {
              from: this.f(moment().day(1).add(7, "days")),
              to: this.f(moment().day(7).add(7, "days")),
            };
          default:
            return {
              from: "",
              to: "",
            };
        }
      })(period);
      this.$emit("click", p);
      this.$emit("change-active", period);
    },
  },
};
</script>
<style lang="scss">
.button.is-generic-app-blue.is-outlined:hover,
.button.is-generic-app-blue.is-outlined:focus {
  background: transparent;
  color: var(--color-primary-10);
}

.button.is-generic-app-blue.is-active,
.button.is-generic-app-blue:active {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.82);
  // background-color #2669b0
  // border-color #2669b0
  // color #fff
}

.are-attached {
  display: flex;

  button {
    transition: background-color 0.4s;
    opacity: 0.8;
    border-radius: 0;
    border-left: 0;
  }

  .field:first-child button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 1px solid;
  }

  .field:last-child button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>
