<template lang="pug">
tr.backup-latest
  td
    modal(
      v-if="isModalVisible",
      :title="'Downloading...'",
      :size="450",
      @close="closeModal"
    )
      progress-bar(
        :currentProgress="currentProgress",
        v-if="!isBackupComplete"
      )
      .backup-fail(v-if="isBackupComplete && failedToBackup.length > 0")
        p Backup failed for the following invoices:
        table.table(style="color: #000")
          thead
            tr
              th #
              th Invoice ID
              th Client
          tbody
            tr(v-for="(invoice, index) in failedToBackup")
              td {{ index + 1 }}
              td {{ invoice.id }}
              td {{ invoice.client }}
        button.button.is-danger(@click="closeModal") Close
  td
  td
  td
  td(
    :colspan="permissions.canDownloadAllCsv || permissions.canDownloadAllExcel ? 1 : 2"
  )
  td(v-if="permissions.canDownloadAllCsv")
    button.button.is-tiny.is-generic-app-teal(
      @click="backupLatestInvoices(callBackupCSV)"
    ) Download all CSV
    //- button.button.is-tiny.is-generic-app-teal(
    //-   @click="backupNewLatestInvoices('csv')"
    //- ) Download all CSV
  td(v-if="permissions.canDownloadAllExcel")
    button.button.is-tiny.is-generic-app-teal(
      @click="backupLatestInvoices(callBackupExcel)"
    ) Download all Excel
  //- td
    button.button.is-tiny.is-generic-app-teal(@click="backupLatestInvoices(callBackupPDF)")|Download all PDF
  td(v-if="permissions.canDeleteInvoice")
    //- Placeholder delete
</template>

<script>
import { mapActions } from "vuex";
import JSZip from "jszip";
import ProgressBar from "../in-modal/invoices/ProgressBar.vue";
import moment from "moment";

export default {
  props: ["invoices"],
  components: {
    ProgressBar,
  },
  data() {
    return {
      isModalVisible: false,
      currentProgress: 0,
      isBackupComplete: false,
      failedToBackup: [],
      jszip: new JSZip(),
      zipNameBase: "",
    };
  },
  computed: {
    permissions() {
      return {
        canDeleteInvoice: this.$can("delete-invoice"),
        canDownloadAllCsv: this.$can("invoice-download-all-csv"),
        canDownloadAllExcel: this.$can("invoice-download-all-excel"),
      };
    },
  },
  methods: {
    ...mapActions({
      fetchLatestInvoices: "invoices/fetchLatestInvoices",
      backupInvoiceCSV: "invoices/backupInvoiceCSV",
      backupInvoicePDF: "invoices/backupInvoicePDF",
      backupInvoiceExcel: "invoices/backupInvoiceExcel",
    }),
    // backupNewLatestInvoices () {
    //   // Unused
    // },
    backupLatestInvoices(callBackup) {
      this.resetBackupState();
      let invoicedInLastWeek = [];
      this.currentProgress = 0;
      this.fetchLatestInvoices()
        .then((response) => {
          invoicedInLastWeek = response.data.invoices;
          if (!invoicedInLastWeek) {
            this.$toasted
              .error("Something went wrong. Please try again.")
              .goAway(2000);
            return;
          }
          if (invoicedInLastWeek && invoicedInLastWeek.length === 0) {
            this.$toasted
              .error("There are no invoiced shifts from the last week.")
              .goAway(3000);
            return;
          }
          this.isModalVisible = true;
          callBackup(invoicedInLastWeek, 0);
        })
        .catch((err) => {
          const msg = err?.response?.data?.message;
          this.$toasted
            .error(msg || "Something went wrong. Please try again.")
            .goAway(2000);
          return;
        });
    },
    shouldStopBackup(invoicedInLastWeek, i) {
      return !this.isModalVisible || invoicedInLastWeek.length === i;
    },
    async finishBackup() {
      this.isBackupComplete = true;
      // reset state if user closes modal before the function is called from Promise (global data might be polluted - ie. files in jszip)
      if (!this.isModalVisible) {
        this.resetBackupState();
        return;
      }
      await this.downloadZip();
      this.$nextTick(() => {
        if (this.failedToBackup.length === 0) {
          this.closeModal();
        }
      });
    },
    getInvoiceTitle(invoice) {
      let lastInvoiceNumber = invoice.number
        ? ` - inv${invoice.number}`
        : ` - inv${invoice.id}`;
      const cleanClientName = invoice.clientName.replace(/\//g, "__");
      return (
        (invoice.clientCustomId ? `${invoice.clientCustomId} - ` : "") +
        `${cleanClientName}${lastInvoiceNumber}`
      );
    },
    async callBackupCSV(invoicedInLastWeek, indexFunc) {
      const i = indexFunc;
      this.zipNameBase = "backup-excel-all";
      if (this.shouldStopBackup(invoicedInLastWeek, i)) {
        this.finishBackup();
        return;
      }
      let id = invoicedInLastWeek[i].id;
      let client = invoicedInLastWeek[i].clientName;
      try {
        const response = await this.backupInvoiceCSV(id);
        let blob = new Blob([response.data], { type: "application/csv" });
        let fileTitle = this.getInvoiceTitle(invoicedInLastWeek[i]);
        await this.jszip.file(`${fileTitle}.csv`, blob);
        console.warn(
          "new backupoo",
          fileTitle,
          id,
          i,
          await blob.slice(0, 25).text()
        );
        this.currentProgress = ((i + 1) * 100) / invoicedInLastWeek.length;
        this.callBackupCSV(invoicedInLastWeek, i + 1);
      } catch (err) {
        this.currentProgress = ((i + 1) * 100) / invoicedInLastWeek.length;
        this.failedToBackup.push({ id, client });
        this.callBackupCSV(invoicedInLastWeek, i + 1);
      }
    },
    async callBackupExcel(invoicedInLastWeek, indexFunc) {
      const i = indexFunc;
      this.zipNameBase = "pay&bill-all";
      if (this.shouldStopBackup(invoicedInLastWeek, i)) {
        this.finishBackup();
        return;
      }
      let id = invoicedInLastWeek[i].id;
      let client = invoicedInLastWeek[i].clientName;
      try {
        const response = await this.backupInvoiceExcel(id);
        let blob = new Blob([response.data], { type: "application/csv" });
        let fileTitle = this.getInvoiceTitle(invoicedInLastWeek[i]);
        await this.jszip.file(`${fileTitle}.csv`, blob);
        console.warn("new backup > excel", fileTitle, id, i);
        this.currentProgress = ((i + 1) * 100) / invoicedInLastWeek.length;
        this.callBackupExcel(invoicedInLastWeek, i + 1);
      } catch (err) {
        this.currentProgress = ((i + 1) * 100) / invoicedInLastWeek.length;
        this.failedToBackup.push({ id, client });
        this.callBackupExcel(invoicedInLastWeek, i + 1);
      }
    },

    // callBackupPDF (invoicedInLastWeek, indexFunc) {
    // const i = indexFunc
    //   this.zipNameBase = 'invoice-all'
    //   if (this.shouldStopBackup(invoicedInLastWeek, i)) {
    //     this.finishBackup()
    //     return
    //   }
    //   let id = invoicedInLastWeek[i].id
    //   let client = invoicedInLastWeek[i].clientName
    //   this.backupInvoicePDF(id)
    //     .then((response) => {
    //       let blob = new Blob([response.data], { type: 'application/pdf' })
    //       let fileTitle = this.getInvoiceTitle(invoicedInLastWeek[i])
    //       await this.jszip.file(`${fileTitle}.pdf`, blob)
    //       this.currentProgress = (i + 1) * 100 / invoicedInLastWeek.length
    //       this.callBackupPDF(invoicedInLastWeek, i + 1)
    //     })
    //     .catch(() => {
    //       this.currentProgress = (i + 1) * 100 / invoicedInLastWeek.length
    //       this.failedToBackup.push({ id, client })
    //       this.callBackupPDF(invoicedInLastWeek, i + 1)
    //     })
    // },

    async downloadZip() {
      this.currentProgress = 100;
      if (
        Object.keys(this.jszip.files).length === 0 &&
        this.jszip.files.constructor === Object
      ) {
        console.log("no files ", this.jszip.files);
        return;
      }
      try {
        const content = await this.jszip.generateAsync({ type: "blob" });
        let blob = new Blob([content]);
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        let currDate = moment().format("DD-MM-YYYY");
        a.download = `${this.zipNameBase} - ${currDate}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (err) {
        console.log("Downloading Zip Failed: ", err.message);
      }
    },
    resetBackupState() {
      this.jszip = new JSZip();
      this.currentProgress = 0;
      this.isBackupComplete = false;
      this.failedToBackup = [];
    },
    closeModal() {
      this.resetBackupState();
      this.isModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.backup-fail {
  margin: 10px auto;
  width: 90%;
  text-align: center;
}

.backup-fail p {
  margin: 8px;
}
</style>

<style lang="scss">
.backup-latest .panel-block {
  height: 200px;
}

.backup-latest .progress-bar-wrapper {
  padding-top: 30px;
}
</style>
