<template lang="pug">
.wrapper
  .dialog-container(v-if="!isClientSubmitted")
    .form-input-wrap
      .control
        .field
          label.label Name
          .control
            input.input(
              type="text",
              placeholder="Please enter Client name here",
              v-model="clientName",
              @input="errors.clear('name')"
            )
      span.help.is-danger(
        data-cell="name_error",
        v-if="errors.has('name')",
        v-html="errors.get('name')"
      )
      template(v-if="$can('view-client-custom-id')")
        .control
          .field
            label.label Client id
            .control
              input.input(
                type="number",
                :disabled="isDisabledClientId",
                placeholder="Please enter Client id here",
                v-model.number="customClientId",
                @input="errors.clear('custom_client_id')"
              )
        span.help.is-danger(
          data-cell="custom_client_id",
          v-if="errors.has('custom_client_id')",
          v-html="errors.get('custom_client_id')"
        )
      .control
        .field
          label.label Region
          multi-select(
            :options="sectors",
            :multiple="true",
            :close-on-select="false",
            track-by="id",
            label="name",
            v-model="selectedClientSector",
            placeholder="SELECT CLIENT REGION",
            @select="errors.clear('sector_id')"
          )
      span.help.is-danger(
        v-if="errors.has('sector_id')",
        v-html="errors.get('sector_id')"
      )
      //- .control
        .field
          label.label CHO Area
          multi-select(
            :options="optionsClientType",
            @select="errors.clear('type')",
            track-by="id",
            label="name",
            v-model="selectedType",
            placeholder="SELECT CHO AREA"
          )
      span.help.is-danger(
        v-if="errors.has('type')",
        v-html="errors.get('type')"
      )
      .control
        .field
          label.label Rate Type
          multi-select(
            :options="rateTypeOptions",
            track-by="id",
            label="name",
            v-model="selectedRateType",
            placeholder="SELECT RATE TYPE",
            @select="onSelectRateType"
          )
      span.help.is-danger(
        v-if="errors.has('rate_client_action')",
        v-html="errors.get('rate_client_action')"
      )
      .control(v-if="isRateTypeSelectedValid")
        .field
          label.label Rate Clients
          multi-select(
            :options="rateClientsOptions",
            :loading="isRateClientLoading",
            track-by="id",
            label="name",
            v-model="selectedRateClient",
            placeholder="SELECT RATE CLIENT",
            @select="errors.clear('rate_client_id')"
          )
      span.help.is-danger(
        v-if="errors.has('rate_client_id')",
        v-html="errors.get('rate_client_id')"
      )
      .control
        .field
          label.label Service Fee Calculation Type
          multi-select(
            :options="feeTypes",
            @select="errors.clear('service_fee_calculation_type_id')",
            track-by="id",
            label="name",
            v-model="selectedServiceFeeCalculationType",
            placeholder="SELECT SERVICE FEE CALCULATION TYPE"
          )
      span.help.is-danger(
        data-cell="service_fee_calculation_type_id_error",
        v-if="errors.has('service_fee_calculation_type_id')",
        v-html="errors.get('service_fee_calculation_type_id')"
      )
      .control
        .field
          label.label Rules
          multi-select(
            :options="allowances",
            @select="errors.clear('calcengine_id')",
            track-by="id",
            label="name",
            v-model="selectedClientAllowance",
            placeholder="SELECT CLIENT RULES"
          )
      span.help.is-danger(
        v-if="errors.has('calcengine_id')",
        v-html="errors.get('calcengine_id')"
      )
      .control
        .field
          label.label Address Line 1
          .control
            input.input(
              type="text",
              placeholder="Please enter Client address here",
              v-model="addressLine1",
              @input="errors.clear('address_line_1')"
            )
      span.help.is-danger(
        data-cell="address_line_1_error",
        v-if="errors.has('address_line_1')",
        v-html="errors.get('address_line_1')"
      )
      .control
        .field
          label.label Address Line 2
          .control
            input.input(
              type="text",
              placeholder="Please enter Client address here",
              v-model="addressLine2",
              @input="errors.clear('address')"
            )
      span.help.is-danger(
        v-if="errors.has('address')",
        v-html="errors.get('address')"
      )
      .control
        .field
          label.label County
          multi-select(
            :options="counties",
            @select="errors.clear('region_id')",
            track-by="id",
            label="name",
            v-model="selectedClientCounty",
            placeholder="SELECT CLIENT COUNTY"
          )
      span.help.is-danger(
        data-cell="region_id_error",
        v-if="errors.has('region_id')",
        v-html="errors.get('region_id')"
      )
      .control
        .field
          label.label {{ getEirCodeLabelKey }}
          .control
            input.input(
              type="text",
              :placeholder="'Please enter Client ' + getEirCodeLabelKey + ' here'",
              v-model="eircode",
              @input="errors.clear('eircode')"
            )
      span.help.is-danger(
        data-cell="eircode_error",
        v-if="errors.has('eircode')",
        v-html="errors.get('eircode')"
      )
      .control
        .field
          label.label Billing Contact Name
          .control
            input.input(
              type="text",
              placeholder="Please enter Client contact name here",
              v-model="billingContactName",
              @input="errors.clear('billing_contact_name')"
            )
      span.help.is-danger(
        data-cell="billing_contact_name_error",
        v-if="errors.has('billing_contact_name')",
        v-html="errors.get('billing_contact_name')"
      )
      .control
        .field
          label.label Billing Contact Title
          .control
            input.input(
              type="text",
              placeholder="Please enter Client contact title here",
              v-model="billingContactTitle",
              @input="errors.clear('billing_contact_title')"
            )
      span.help.is-danger(
        data-cell="billing_contact_title_error",
        v-if="errors.has('billing_contact_title')",
        v-html="errors.get('billing_contact_title')"
      )
      .control
        .field
          label.label Billing Contact E-mail
          .control
            input.input(
              type="text",
              placeholder="Please enter Client e-mail here",
              v-model="billingContactEmail",
              @input="errors.clear('billing_contact_email')"
            )
      span.help.is-danger(
        data-cell="billing_contact_email_error",
        v-if="errors.has('billing_contact_email')",
        v-html="errors.get('billing_contact_email')"
      )
      .control
        .field
          label.label Phone
          .control
            input.input(
              type="text",
              placeholder="Please enter Client Phone here",
              v-model="clientPhone"
            )
      span.help.is-danger(
        v-if="errors.has('phone')",
        v-html="errors.get('phone')"
      )
    .buttons-centered
      button.button.is-generic-app-blue.is-caps-lock(
        data-cell="submit-button",
        type="submit",
        @click.prevent="clientSubmit",
        :disabled="isCreatingClient"
      )
        img.btn-loader(
          src="../../../assets/images/comps/loader.svg",
          v-if="isCreatingClient"
        )
        span CREATE
      button.button.is-outlined.is-caps-lock(
        data-cell="cancel-button",
        @click.prevent="callModalClose"
      ) CANCEL
  finance-page-redirect(
    v-if="isClientSubmitted",
    :clientData="newClientData",
    @modal-close="callModalClose"
  )
</template>

<script>
import { Errors } from "../../../lib/helpers/Errors.js";
import { mapActions, mapGetters } from "vuex";
import { RATE_TYPE_OPTIONS } from "./rateData.js";
import FinancePageRedirect from "./FinancePageRedirect.vue";

import MultiSelect from "vue-multiselect";
import { parseErrors } from "../../../lib/helpers/function.js";

export default {
  components: {
    MultiSelect,
    FinancePageRedirect,
  },
  data() {
    return {
      clientName: "",
      customClientId: null,
      addressLine1: "",
      addressLine2: "",
      selectedClientCounty: "",
      selectedClientSector: [],
      selectedType: "",
      selectedClientAllowance: "",
      // selectedAgency: [],
      selectedServiceFeeCalculationType: "",
      eircode: "",
      billingContactName: "",
      billingContactTitle: "",
      billingContactEmail: "",
      clientPhone: "",
      isRateClientLoading: false,

      // optionsClientType: [
      //   { name: 'Area 1', id: 1 },
      //   { name: 'Area 2', id: 2 },
      //   { name: 'Area 3', id: 3 },
      //   { name: 'Area 4', id: 4 },
      //   { name: 'Area 5', id: 5 },
      //   { name: 'Area 6', id: 6 },
      //   { name: 'Area 7', id: 7 },
      //   { name: 'Area 8', id: 8 },
      //   { name: 'Area 9', id: 9 },
      //   { name: 'Childrens Hospital Group', id: 10 },
      //   { name: 'Corporate', id: 11 },
      //   { name: 'Dublin/Midlands HG', id: 12 },
      //   { name: 'Ireland East', id: 13 },
      //   { name: 'RCSI', id: 14 },
      //   { name: 'Saolta', id: 15 },
      //   { name: 'South/South West HG', id: 16 },
      //   { name: 'Tusla', id: 17 },
      //   { name: 'UL Hospital Group', id: 18 },
      //   { name: 'Voluntary', id: 19 },
      //   { name: 'Other', id: 20 }
      // ],
      isCreatingClient: false,
      selectedRateType: null,
      selectedRateClient: null,
      rateTypeOptions: RATE_TYPE_OPTIONS,
      rateClientsOptions: [],
      errors: new Errors(),

      newClientData: null,
      isClientSubmitted: false,
    };
  },
  computed: {
    ...mapGetters({
      counties: "locationClientManagement/getCounties",
      sectors: "locationClientManagement/getFlatSectors",
      allowances: "locationClientManagement/getAllowances",
      agencies: "locationClientManagement/getAgencies",
      feeTypes: "locationClientManagement/getServiceFeeTypes",
    }),
    getEirCodeLabelKey() {
      return this.$store.getters.getColumnNameLocl("usr_eircode");
    },
    isRateTypeSelectedValid() {
      if (this.selectedRateType && this.selectedRateType.id) {
        const validRateTypeIdsForNextSection = [1, 2];
        return validRateTypeIdsForNextSection.includes(
          this.selectedRateType.id
        );
      }
      return false;
    },
    isDisabledClientId() {
      // Always must be present [Only when editing perm check]
      // return !this.$can('edit-client-custom-id')
      return false;
    },
    hasPermission() {
      return this.$can("manage-rates");
    },
  },
  mounted() {
    this.fetchRegions();
    this.fetchSectors();
    if (this.gPermissions.canViewRulesCalcEng) {
      this.fetchAllowances();
    }
    this.fetchAgencies();
    this.fetchServiceFeeTypes();
  },
  methods: {
    ...mapActions({
      createClient: "locationClientManagement/createClient",
      fetchRegions: "locationClientManagement/fetchRegions",
      fetchAgencies: "locationClientManagement/fetchAgencies",
      fetchSectors: "locationClientManagement/fetchSectors",
      fetchAllowances: "locationClientManagement/fetchAllowances",
      fetchServiceFeeTypes: "locationClientManagement/fetchServiceFeeTypes",
      getRatesClientsForClientManagement: "getRatesClientsForClientManagement",
    }),
    callModalClose() {
      this.$emit("cancel-modal");
    },
    async clientSubmit() {
      const postParams = {
        region_id: this.selectedClientCounty.id,
        // sector_id: this.selectedClientSector.id,
        sectors:
          Array.isArray(this.selectedClientSector) &&
          this.selectedClientSector.map((sec) => ({ id: sec.id })),
        type: this.selectedType.id,
        calcengine_id: this.selectedClientAllowance.id,
        name: this.clientName,
        // custom_client_id: this.customClientId,
        address_line_1: this.addressLine1,
        address_line_2: this.addressLine2,
        eircode: this.eircode,
        billing_contact_name: this.billingContactName,
        billing_contact_title: this.billingContactTitle,
        billing_contact_email: this.billingContactEmail,
        phone: this.clientPhone,
        service_fee_calculation_type_id:
          this.selectedServiceFeeCalculationType.id,
        rate_client_action: this.selectedRateType && this.selectedRateType.id,
        rate_client_id: this.selectedRateClient && this.selectedRateClient.id,
      };
      if(this.customClientId){
        postParams.custom_client_id = this.customClientId;
      }
      this.isCreatingClient = true;
      try {
        const res = await this.createClient(postParams);
        this.newClientData = res.data;
        this.isClientSubmitted = true;
        this.isCreatingClient = false;
        this.$toasted.success("New client created").goAway(2000);
        if (!this.hasPermission) {
          this.callModalClose();
        }
      } catch (error) {
        this.isCreatingClient = false;
        if (error.response?.data?.errors) {
          this.errors.record(error.response.data.errors);
        }

        const msg = parseErrors(error, "", true);
        this.$toasted.error(msg).goAway(2000);
      }
    },
    onSelectRateType() {
      this.selectedRateClient = null;
      this.errors.clear("rate_client_action");
      this.$nextTick(() => {
        // This is because the computed is done after the event
        if (this.isRateTypeSelectedValid) {
          this.fetchRateClients();
        }
      });
    },
    async fetchRateClients() {
      const id = this.selectedRateType && this.selectedRateType.id;
      this.rateClientsOptions = [];
      this.isRateClientLoading = true;
      const params = {};
      if (id === 1) {
        params.system = 1;
      }
      try {
        const res = await this.getRatesClientsForClientManagement(params);
        this.rateClientsOptions = res.data.data;
      } catch (err) {
        console.log("Error >> ", err.message);
      } finally {
        this.isRateClientLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
// .location-client-management {
//   .modal {
//     .modal-content {
//       .panel-block {
//         padding: 0;
//       }
//     }
//   }
// }
</style>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
  overflow: hidden;

  .form-input-wrap {
    display: flex;
    flex-direction: column;
    overflow: auto;
    // padding: 0 1.3em;

    label {
      margin-top: 0;
    }
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

table.no-border {
  border: 0px;
}

.btn-loader {
  height: 18px;
  padding-right: 4px;
}
</style>
