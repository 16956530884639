<template lang="pug">
div
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :headless="false",
    icon="clock-o",
    size="750"
  )
    component(
      @cancel-modal="modalVisible = false",
      :is="inmodal",
      :modalProps="modalData"
    )
  section.section
    h1.title.is-3
      i.fa.icon-Compliance-documents
      | My Documents
    //- .block.is-pulled-right.controls(v-if='enableLanePre')
      transition(name="fade")
        span(v-show="add_new")
          button.button.is-generic-app-blue(@click="createTrigger++") SAVE
          button.button.is-generic-app-blue(@click="add_new = false") CANCEL
      span
        button.button.is-generic-app-light-blue.new-document(
          @click="add_new = true",
          :disabled="add_new"
        )
          img(src="../../assets/images/comps/Plus.svg")
          span NEW DOCUMENT
  table.table.is-striped
    thead
      tr
        th DOCUMENT
        th EXPIRY DATE
        th SUBMISSION DATE
        th UPLOADED
        th STATUS
        th ACTION
        th VIEW
    tfoot
    tbody(v-if="isLoading")
      tr
        td.is-loading(colspan="7")
          img(src="../../assets/images/comps/loader.svg")
    tbody(v-else-if="docs")
      tr(v-for="(doc, index) in docs", :key="index")
        td {{ doc.name }}
        td {{ doc.expiryDate ? expiryDate(doc) : "-" }}
        td {{ submissionDate(doc) }}
        td {{ doc.filesCount ? "Yes" : "No" }}
        td
          .flexcell
            transition(name="zoom", appear)
              span.dot(:style="{ backgroundColor: status(doc.status).hex }")
            span.generic-app-tag.is-caps-lock.text-smaller {{ status(doc.status).label }}
        td.button-group
          //- depending on weather it hasExpiry, we provide an additional input in modal to set expiry date for files uploaded
          button.button.is-caps-lock.is-generic-app-blue.is-tiny.is-low.clickable(
            @click="showModal('upload-temp-documents', { docId: doc.id, tempId: doc.temp.id, hasExpiry: doc.expiration_months > 0 ? true : false, expiryDate: doc.expiryDate }, 'Upload temp documents')"
          ) upload
        td
          button.button.is-caps-lock.is-tiny.is-generic-app-blue.is-low.clickable(
            v-show="doc.filesCount",
            @click="showModal('check-temp-documents', { temp: doc.temp.id, document: doc.id }, 'Check Temp Documents')"
          ) check
    tbody(v-else)
      tr
        td.has-text-centered(colspan="7") Loading...
  pagination(
    v-if="pagination && pagination.total_pages",
    :total-pages="pagination.total_pages",
    :current-page="pagination.current_page",
    @page-changed="pageHasChanged"
  )
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getFormattedTime } from "../../lib/helpers/function.js";
import _ from "underscore";

import { STATUS_COLORS } from "../config/colors.js";

import Pagination from "../Pagination.vue";
import CheckTempDocuments from "../in-modal/compliances/CheckTempDocuments.vue";
import UploadTempDocuments from "../in-modal/compliances/UploadTempDocuments.vue";
// import DocumentNewRow from './DocumentNewRow.vue'

import { Evt } from "../../lib/helpers/Evt.js";

export default {
  components: {
    UploadTempDocuments,
    CheckTempDocuments,
    // DocumentNewRow,
    Pagination,
  },
  data() {
    return {
      modalVisible: false,
      isLoading: false,
      // add_new: false, // add new document row
      // createTrigger: 0,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      docs: "getTempDocuments",
      pagination: "getPagination",
    }),
  },
  mounted() {
    Evt.listen("disposeModal", () => {
      console.error("called outdated bus");
      this.modalVisible = false;
    });
  },
  created() {
    this.isLoading = true;
    const params = this.getFetchDocumentsParams();
    this.fetchTempDocuments(params).then(() => {
      this.isLoading = false;
    });
  },
  beforeDestroy() {
    Evt.off("disposeModal");
  },
  methods: {
    ...mapActions(["fetchTempDocuments"]),
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    getFetchDocumentsParams() {
      return {
        include: "filesCount",
        page: this.currentPage,
      };
    },
    pageHasChanged(pageNum) {
      this.currentPage = pageNum;
      const params = this.getFetchDocumentsParams();
      this.isLoading = true;
      this.fetchTempDocuments(params).then(() => {
        this.isLoading = false;
      });
    },
    status(status) {
      const statusObj = _.findWhere(STATUS_COLORS, { code: status.code });
      if (statusObj) {
        return {
          hex: statusObj.hex,
          label: statusObj.label,
        };
      }
      return {};
    },
    submissionDate(doc) {
      if (doc.filesCount) {
        const time = doc.createdAt;
        return getFormattedTime(time, "DD/MM/YYYY HH:mm");
      } else {
        return "-";
      }
    },
    expiryDate(doc) {
      if (doc.expiryDate) {
        return getFormattedTime(doc.expiryDate, "DD/MM/YYYY");
      }
    },
    updateTempDocument(documentObject, newData) {
      const idx = _.findIndex(this.docs, (doc) => {
        return (
          doc.id === documentObject.document &&
          doc.temp.id === documentObject.temp
        );
      });
      // dispatch an action to change state in store.compliance.tempDocuments!
      this.$store.dispatch("replaceTempDocument", {
        idx: idx,
        data: newData.data,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.flexcell {
  display: flex;
  align-items: center;
}

.dot {
  border-radius: 7px;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 0.8em;
}

.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

td.button-group {
  .button {
    margin-right: 1em;
  }
}

.is-loading {
  text-align: center;
  background: #f2f7fa;
}
</style>
