import axios from "axios";
import Vue from "vue";
import auth from "../lib/auth.js";
import Jwt from "../lib/helpers/Jwt.js";

let ApiURL = window.ApiURL;
if (process.env.NODE_ENV !== "production") {
  ApiURL = process.env.VUE_APP_API_URL_DEV;
  window.ApiURL = ApiURL;
}

console.log("ApiUrl ::", ApiURL);
const instance = axios.create({
  baseURL: ApiURL + "/api/",
});

instance.interceptors.request.use((config) => {
  config.headers["Authorization"] = Jwt.getAuthHeader();
  config.headers["Accept"] = "application/json";
  config.headers["Content-Type"] = "application/json";
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // Reacting to API exception - if the status code if 403 && message is 'Your account is permanently banned.'
    // it means that user has red DNR status and should automatically be logged out of the system
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    const errObject = error.response && error.response.data;
    if (errObject && errObject.status_code === 403) {
      const TIMEOUT_TIME = 7000;
      const msg = errObject.error_message || errObject.message;
      const signoutAndToast = async (msg) => {
        await auth.signout();
        sessionStorage.showSignInMessage = msg;
        // Toast is displayed in sign in component once
        // Vue.toasted.error(msg).goAway(TIMEOUT_TIME);
      };

      if (errObject.command === "red_dnr") {
        signoutAndToast(msg);
      } else if (errObject.command === "credentials_changed") {
        // msg = 'Your credentials have been changed. Please login using the new credentials.'
        signoutAndToast(msg);
      } else if (errObject.command === "force_logout") {
        signoutAndToast(msg);
      } else if (errObject.message === "Your account is permanently banned.") {
        signoutAndToast(msg);
      } else if (errObject.message === "You must login with new credentials.") {
        signoutAndToast(msg);
      } else if (errObject.command) {
        console.warn("New API Command Config:", errObject.command);
        Vue.toasted.info(msg).goAway(TIMEOUT_TIME);
      }
      // So it doesn't display multiple errors
      const newError = { ...error };
      delete newError.response.data.message;
      return Promise.reject(newError);
    }
    return Promise.reject(error);
  }
);

export default instance;
