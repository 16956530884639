<template lang="pug">
#rates-increments-table
  ul
    li.inc-row.create
      input.input(
        v-model="newIncrement",
        type="text",
        placeholder="New increment name"
      )
      .add(
        :class="{ disabled: !newIncrement || isSavingForm }",
        title="Add new increment",
        @click.stop="onCreateIncrement"
      )
        .fa.fa-plus
  ul#increments
    li.inc-row(v-for="increment in increments", :key="increment.id")
      rates-increments-node(
        :category="category",
        :increment="increment",
        :node-select-evt="nodeSelectEvt",
        :selected-increment="selectedIncrement",
        @change="$emit('change')"
      )
</template>

<script>
import RatesIncrementsNode from "./RatesIncrementsNode.vue";
import { mapActions } from "vuex";
import { parseErrors } from "../../../../lib/helpers/function";
export default {
  components: {
    RatesIncrementsNode,
  },
  props: {
    selectedIncrement: [Object, null],
    nodeSelectEvt: Function,
    category: {
      type: Object,
      required: true,
    },
    increments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newIncrement: "",
      isSavingForm: false,
    };
  },
  methods: {
    ...mapActions({
      postRatesIncrements: "postRatesIncrements",
    }),
    onCreateIncrement() {
      if (this.newIncrement && !this.isSavingForm) {
        this.applyForm();
      }
    },
    async applyForm() {
      this.isSavingForm = true;
      const params = {
        catId: this.category.id,
        inc_name: this.newIncrement,
      };

      try {
        await this.postRatesIncrements(params);
        this.$toasted.info("New increment created").goAway(2000);
        this.$emit("change");
        this.newIncrement = "";
        this.isSavingForm = false;
      } catch (err) {
        console.log(err.message);
        this.isSavingForm = false;
        let errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
      console.log("apply", params);
    },
  },
};
</script>

<style lang="scss" scoped>
#rates-increments-table {
  $gray-element: rgba(10, 10, 10, 0.2);

  ul {
    // max-height: calc(100vh - 400px);
    overflow: auto;

    &#increments {
      overflow-y: scroll;
      height: 100%;
      margin-top: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: unset;
    }
  }

  .inc-row {
    min-width: 300px;

    &.create {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // input {
      // width: 300px;
      // }

      .add {
        // color: gray;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 17px;

        .fa {
          color: darken($color: $gray-element, $amount: 10);
        }

        &:not(.disabled) {
          cursor: pointer;

          &:hover {
            .fa {
              color: lighten($color: royalblue, $amount: 10);
            }
          }
        }

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
