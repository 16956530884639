import moment from "moment";
export function getFormattedDateTime(datetime, inputFormat = "YYYY-MM-DD HH:mm:ss", includeAdditional = "") {
    const m = moment(datetime, inputFormat);
    let outputFormat = "DD/MM/YYYY";
    if (includeAdditional === "seconds") {
        outputFormat = "DD/MM/YYYY HH:mm:ss";
    }
    else if (includeAdditional === "time") {
        outputFormat = "DD/MM/YYYY HH:mm";
    }
    return m.isValid() ? m.format(outputFormat) : null;
}
export function getAllFlatRec(arr = []) {
    return arr.reduce((acc, curr) => {
        if (typeof curr.id === "number") {
            acc.push(curr);
        }
        if (curr.children?.length) {
            const items = getAllFlatRec(curr.children);
            acc.push(...items);
        }
        return acc;
    }, []);
}
export function getAllIdsRec(arr = []) {
    return arr.reduce((acc, curr) => {
        if (typeof curr.id === "number") {
            acc.push(curr.id);
        }
        if (curr.children?.length) {
            const ids = getAllIdsRec(curr.children);
            acc.push(...ids);
        }
        return acc;
    }, []);
}
