<template lang="pug">
table.table
  edit-popup(
    :data="popup.data",
    :levels="popup.levels",
    :offset="popup.offset",
    :target="popup.element",
    :isLoading="popup.isLoading",
    :isSaving="isSavingPopupForm",
    ref="popup",
    v-if="popup.show",
    @close="popupClose",
    @editPopup="popup.save"
  )
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    icon="",
    :size="modalSize",
    :scrollable="true"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false",
      @activate="onChangeStatusUser($event, 1)",
      @deactivate="onChangeStatusUser($event, 3)"
    )
  thead
    tr
      //- th Select All
      th NAME
        //- sort-buttons(column-name="name", v-model="selectedSortColumn")
      th EMAIL
        //- sort-buttons(column-name="email", v-model="selectedSortColumn")
      th LOCATION
      th LAST ACTION
      th STATUS
  tbody
    tr(v-if="loading")
      td.center-text(colspan="100")
        img(src="@/assets/images/comps/loader.svg")
    tr(v-for="user in users", v-if="users && !loading")
      td {{ user.name }}
      td {{ user.email }}
      td.locations
        span.generic-app-tag.is-wide-tag.popup-hook(
          @click="showPopup(['locations'], $event.target, user)",
          v-tooltip="'Click to fill in'",
          v-show="loadingLocationsUserId !== user.id"
        ) 
          strong {{ getUserLocationsCount(user) }}
          img(src="../../../assets/images/comps/Olovka.svg")
        .loading-wrapper(v-if="loadingLocationsUserId === user.id")
          img(src="@/assets/images/comps/loader.svg")
      td {{ formatDate(user.lastAction, "DD/MM/YYYY HH:mm") }}
      td.activate-user
        span.button.is-caps-lock.is-tiny.is-low(
          @click.stop="onChangeStatusAction(user)",
          :class="[setStatus(user.status)]"
        ) {{ getStatusText(user.status) }}
</template>

<script>
import _ from "underscore";
import EditPopup from "../../EditPopup.vue";
import EditUserStatus from "../../ClientUserManagement/EditUserStatus.vue";
import {
  flatten,
  getFormattedTime,
  parseErrors,
} from "../../../lib/helpers/function";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  components: {
    EditPopup,
    EditUserStatus,
  },
  props: {
    loading: {
      type: Boolean,
    },
    users: {
      type: Array,
      required: true,
    },
    sectors: {
      type: Array,
    },
    includes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      popup: {
        active_user_id: null,
        offset: {
          offsetX: 20,
          offsetY: 55,
        },
        element: null,
        show: false,
        data: null,
        levels: [],
        save: (data, totalCount) => {
          return this.refreshPopupState(data, totalCount);
        },
        isLoading: false,
      },
      userLocations: {},
      cancelTokenPopup: null,
      loadingLocationsUserId: null,
      modalSize: 750,
      modalVisible: false,
      isSavingPopupForm: false,
    };
  },
  methods: {
    ...mapActions({
      getFetchUsers: "getFetchUsers",
      patchUsers: "patchUsers",
    }),
    onChangeStatusAction(user) {
      this.showModal("edit-user-status", { user }, `User's status`, 650);
    },
    setStatus(s) {
      let c;
      switch (s) {
        case 1:
        default:
          c = "is-success";
          break;
        // case 2:
        //   c = 'is-warning'
        //   break
        case 3:
          c = "is-danger";
          break;
      }
      return c;
    },
    getStatusText(s) {
      let c;
      switch (s) {
        case 1:
        default:
          c = "Active";
          break;
        // case 2:
        //   c = 'Review'
        //   break
        case 3:
          c = "Inactive";
          break;
        // case 4:
        //   c = 'Approval'
        //   break
      }
      return c;
    },
    popupClose() {
      this.popup.show = false;
    },
    async onChangeStatusUser(user, statusCode = 1) {
      this.modalVisible = false;
      console.log("Activation invoked for ID :: ", user.id);
      const payload = {};
      payload.id = user.id;
      payload.data = { status: statusCode };
      payload.query = { include: this.includes };
      try {
        await this.patchUsers(payload);
        // Needs to refresh page because of the status sorting
        this.$emit("patch-user");
      } catch (err) {
        const errs = parseErrors(err, "Error saving", false);
        this.$toasted.error(errs).goAway(4500);
      }
    },
    getUserLocationsCount(user) {
      return user.clientLocationCount || 0;
    },
    async fetchLocationDataFromTemp(tempId) {
      this.resetPreviousLocationToken();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelTokenLocations = source;

      const params = {
        query: {
          "temps[]": [tempId],
          include: "locations",
        },
        cancelTokenSource: source,
      };

      try {
        const res = await this.getTempLocations(params);
        this.$set(this.tempLocationList, tempId, res.data.data?.[0] || {});
        return true;
      } catch (err) {
        console.warn("ERR", err.message);
      }
      return false;
    },
    formatDate: getFormattedTime,
    resetPreviousPopupToken() {
      if (this.cancelTokenPopup !== null) {
        this.cancelTokenPopup.cancel();
        this.cancelTokenPopup = null;
      }
    },
    async fetchLocations(userId) {
      this.resetPreviousPopupToken();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelTokenPopup = source;

      const params = {
        query: {
          "users[]": [userId],
          include: ["locations", "withArchived"].join(),
          // include: "locations",
        },
        cancelTokenSource: source,
      };

      try {
        const res = await this.getFetchUsers(params);
        this.$set(this.userLocations, userId, res.data.data?.[0] || {});
        return true;
      } catch (err) {
        console.warn(err.message);
      }
      return false;
    },
    async showPopup(levels, e, user) {
      this.loadingLocationsUserId = null;
      // console.log(levels)
      // console.log(e)

      let apiCheckedIds = [];

      if (_.indexOf(levels, "subcategories") >= 0) {
        apiCheckedIds = this.findCheckedItems("subcategories", user);
        // console.log('API CHECKED IDS :: ', apiCheckedIds)

        this.popup.data = this.getSubcategoriesByCategories(
          this.categories,
          apiCheckedIds
        );
        console.log(this.popup.data);
      } else if (_.indexOf(levels, "locations") >= 0) {
        this.loadingLocationsUserId = user.id;
        const isSuccess = await this.fetchLocations(user.id);
        if (isSuccess) {
          const userLocs = this.userLocations[user.id];
          if (userLocs) {
            this.popup.isLoading = true;
            this.$nextTick(async () => {
              await this.waitForClientData();
              const apiCheckedIds = this.findCheckedItems(
                "locations",
                userLocs
              );
              const checkedIdObjects = this.getCheckedCCs(
                this.sectors,
                apiCheckedIds
              );
              const clientLocations = this.packDataForPopup(checkedIdObjects);
              this.$set(this.popup, "data", clientLocations);
              this.popup.isLoading = false;
            });
          } else {
            console.error("User not found", user.id, user);
            this.loadingLocationsUserId = null;
            return;
          }
        } else {
          this.loadingLocationsUserId = null;
          return;
        }
      }

      this.popup.levels = levels;
      this.popup.active_user_id = user["id"];
      this.popup.show = true;
      this.popup.element = e;
      this.loadingLocationsUserId = null;
      console.log(user.id);
      console.log("popup data", this.popup);
    },
    getSubcategoriesByCategories(categories, ids = []) {
      return _.each(categories, (category) =>
        category.subcategories.map((subcat) => {
          subcat.checked = _.contains(ids, subcat.id);
          if (subcat.checked) {
            // console.log('subcat id :: ', subcat.id)
          }
        })
      );
    },
    getCheckedCCs(sectors, ids = []) {
      return _.each(sectors, (sector) => {
        // console.log('Checking Sector :: ', sector)
        return _.each(sector.clients, (client) => {
          // console.log('Checking Client :: ', client)
          return _.each(client.locations, (location) => {
            // console.log('Checking CC :: ', location)
            location.checked = _.contains(ids, location.id);
          });
        });
      });
    },
    findCheckedItems(type, user) {
      const checkedItems = [];
      if (type === "subcategories") {
        user["subcategories"]?.map((subcategory) => {
          checkedItems.push(subcategory["id"]);
        });
      }
      if (type === "locations") {
        user["locations"]?.map((location) => {
          checkedItems.push(location["id"]);
        });
      }
      return checkedItems;
    },
    packDataForPopup(sectors) {
      const packed = [];
      _.each(sectors, (sector) => {
        _.each(sector.clients, (client) => {
          packed.push(client);
        });
      });
      return packed;
    },
    async refreshPopupState({ data, entity }, totalCount) {
      this.isSavingPopupForm = true;
      console.log("entity", entity[0], totalCount);
      // If we were editing subcategories
      if (entity[0] === "subcategories") {
        const ids = data.map((category) => {
          return category.subcategories
            .filter((subcat) => {
              if (subcat.checked === true) {
                return subcat.id;
              }
            })
            .map((subcat) => subcat.id);
        });

        const subcategories = flatten(ids).map((id) => ({ id: id }));

        if (this.popup.active_user_id !== 0) {
          try {
            await this.sendPatch(
              this.popup.active_user_id,
              {
                subcategories: subcategories,
                client_id: this.sectors[0].clients?.[0]?.id,
              },
              "Subcategories updated successfully!"
            );
            this.isSavingPopupForm = false;
            return;
          } catch (err) {
            const errs = parseErrors(err, "Error saving", true);
            this.$toasted.error(errs).goAway(4500);
            this.isSavingPopupForm = false;
            return;
          }
        }
        this.isSavingPopupForm = false;
        return; // safety measure
      } else if (entity[0] === "locations") {
        // If we were editing locations
        // Popup data is still a hierarchy, even though we will most likely only have only one root
        const ids = data.map((rootCC) => {
          return rootCC.locations
            .filter((location) => {
              if (location.checked === true) {
                return location.id;
              }
            })
            .map((location) => location.id);
        });
        const locationIds = flatten(ids).map((id) => ({ id: id }));
        console.log("locationIds ::", locationIds);

        try {
          await this.sendPatch(
            this.popup.active_user_id,
            {
              locations: locationIds,
              client_id: this.sectors[0].clients?.[0]?.id,
            },
            "CostCentres updated successfully!"
          );
          this.isSavingPopupForm = false;
          return;
        } catch (err) {
          const errs = parseErrors(err, "Error saving", true);
          this.$toasted.error(errs).goAway(4500);
          this.isSavingPopupForm = false;
          return;
        }
      }
    },
    async sendPatch(id, payload, msg) {
      const params = {
        id: id,
        data: payload,
        query: { include: this.includes },
      };
      try {
        await this.patchUsers(params);
        if (msg !== undefined) {
          this.$toasted.info(msg).goAway(1500);
        }
        this.popupClose();
      } catch (err) {
        console.log(err.message);
      }
    },
    async waitForClientData() {
      return new Promise((resolve) => {
        const recheck = (clients) => {
          setTimeout(async () => {
            if (!clients || (clients && clients.length === 0)) {
              console.log("Recheck data");
              recheck(this.sectors[0].clients);
            } else {
              resolve();
            }
          }, 1000);
        };

        recheck(this.sectors[0].clients);
      });
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = "750"
    ) {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalSize = modalSize;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  .is-wide-tag {
    min-width: 50px;
    display: inline-flex;
    justify-content: space-between;
  }

  .generic-app-tag {
    position: relative;
    background-color: #e3ebed;
    border-radius: 3px;
    padding: 0.2em 0.5em;
  }
}
</style>
