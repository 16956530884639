<template lang="pug">
table.table
  thead
    tr
      th
        span REPORT TITLE
        i.fa.fa-long-arrow-up.fa-rotate-180(
          :class="{ active: sort === 'asc' }",
          @click="sortReports('asc')"
        )
        i.fa.fa-long-arrow-up(
          :class="{ active: sort === 'desc' }",
          @click="sortReports('desc')"
        )
      th.is-narrow
        span ACTION
        //- i.fa.fa-long-arrow-up.fa-rotate-180
        //- i.fa.fa-long-arrow-up
      th.is-narrow
        span EXCEL
        //- i.fa.fa-long-arrow-up.fa-rotate-180
        //- i.fa.fa-long-arrow-up
  tbody
    tr(v-for="(report, index) in sortedReports", :key="index")
      td
        span {{ report.label }}
      td.is-narrow
        span
          button.button.is-success.is-caps-lock.is-tiny(
            @click="preview(report, index)",
            :disabled="isPreviewingReport",
            :class="{ 'is-loading': isPreviewingReport }"
          )
            i.fa.fa-eye
            span PREVIEW
      td.is-narrow
        button.button.is-generic-app-blue.is-small(
          @click="download(report, index)",
          :disabled="report.isLoading",
          :class="{ 'is-loading': report.isLoading }"
        )
          i.fa.fa-file-text-o
          span DOWNLOAD
</template>

<script>
export default {
  props: ['getReports', 'search', 'isPreviewingReport'],
  data () {
    return {
      sort: '',
    };
  },
  computed: {
    filteredReports () {
      if (Array.isArray(this.getReports)) {
        if (this.search) {
          return this.getReports.filter(report => report.label.toLowerCase().includes(this.search.toLowerCase()));
        }
        return this.getReports;
      }
      return [];
    },
    sortedReports () {
      const tempArr = [...this.filteredReports];
      if (this.sort === 'asc') {
        return tempArr.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      } else if (this.sort === 'desc') {
        return tempArr.sort((a, b) => {
          if (a.name < b.name) {
            return 1;
          } else if (a.name > b.name) {
            return -1;
          }
          return 0;
        });
      }
      return tempArr;
    },
  },
  methods: {
    sortReports (side) {
      if (side === this.sort) {
        this.sort = '';
      } else {
        this.sort = side;
      }
    },
    // eslint-disable-next-line no-unused-vars
    preview (report, index) {
      this.$emit('preview', { report });
    },
    // eslint-disable-next-line no-unused-vars
    download (report, index) {
      this.$emit('download', { report });
    },
  },
};

</script>

<style lang="scss" scoped>
$color--light-gray: #b7bbbd;
$color--dark-gray: #7c91ae;

// .is-disabled {
//   disabled: true;
// }

th {
  span {
    margin-right: 5px;
    color: $color--dark-gray;
  }

  .fa {
    font-size: 15px;
    margin-top: 3px;
    color: $color--light-gray;
  }
}

.fa-long-arrow-up {
  &.active {
    color: var(--color-primary);
  }
}

td {
  .fa.fa-eye {
    font-size: 15px;
    // position: relative;
    // left: -45px;
  }

  // button {
  //   span {
  //     position: relative;
  //     left: 15px;
  //   }
  // }

  .fa.fa-file-text-o {
    font-size: 15px;
    // position: relative;
    // left: -61px;
  }
}
</style>
