<template lang="pug">
#security-contact-page
  .loading-wrap(v-if="isLoading")
    .loading-wrapper
      img(src="../../../assets/images/comps/loader.svg")
  //- ul(ref="list", v-if="contactTypes && contactTypes.length && !isLoading")
  //-   li(v-for="(ct, index) in contactTypes", :key="ct.id")
  //-     input(
  //-       :true-value="false",
  //-       :false-value="true",
  //-       :disabled="isLockedInputs && ct.id",
  //-       v-if="ct.id",
  //-       type="checkbox",
  //-       v-model="ct.hidden"
  //-     )
  //-       //- @change="onChangeCheckbox(ct)"
  //-     input.input(
  //-       v-model="ct.name",
  //-       type="text",
  //-       :disabled="isLockedInputs && ct.id",
  //-     )
  //-       //- @blur="onChangeName($event, ct)"
  //-     .remove-wrap
  //-       span.fa.fa-times(v-if="!ct.id", @click="onRemoveEmptyRow(index)")
  .table-wrap(
    ref="list",
    v-if="contactTypes && contactTypes.length && !isLoading"
  )
    table.table(:style="!isLockedInputs ? 'border-bottom: none;' : ''")
      thead
        tr
          th(colspan="100") 
            .flex-wrap(style="display: flex; gap: 10px")
              .completed
                span To be edited:&nbsp;
                span(style="color: black") {{ toBeEditedCount }}
              .errors
                span Success:&nbsp;
                span(style="color: green") {{ successfullCount }}
              .total
                span Errors:&nbsp;
                span(style="color: darkred") {{ errorsCount }}
        tr
          th
            span Title
          th
            span Hidden
          th
            span Status
      tbody
        tr(v-for="(ct, index) in contactTypes", :key="ct.id")
          td
            input.input(
              :value="ct.name",
              type="text",
              :disabled="isLockedInputs && ct.id",
              :style="!ct.id ? 'width: calc(100% - 22px)' : ''",
              @input="bindValue(index, 'name', $event.target.value)"
            )
            .remove-wrap(:style="!ct.id ? 'display:inline;' : ''")
              span.fa.fa-times(v-if="!ct.id", @click="onRemoveEmptyRow(index)")
          td
            input(
              :true-value="false",
              :false-value="true",
              :disabled="isLockedInputs && ct.id",
              v-if="ct.id",
              type="checkbox",
              :checked="ct.hidden",
              @change="bindValue(index, 'hidden', $event.target.checked)"
            )
          td
            span(v-if="ct.error", style="color: darkred") {{ ct.error }}
            span(v-if="ct.success", style="color: green") {{ ct.success }}
      tfoot(v-if="!isLockedInputs")
        tr.add-row
          td
            button.button.is-generic-app-blue.is-caps-lock(
              type="button",
              :disabled="isSavingData",
              @click.prevent="onAddNew"
            )
              span Add new
          td
          td
  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(
      type="button",
      :disabled="isSavingData || !isLockedInputs",
      v-if="isLockedInputs && contactTypes.length",
      @click.prevent="isLockedInputs = false"
    )
      span Unlock

    button.button.is-generic-app-blue.is-caps-lock(
      type="button",
      :disabled="isSavingData || isLockedInputs",
      v-if="!isLockedInputs && contactTypes.length",
      @click.prevent="isLockedInputs = true"
    )
      span Lock

    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      :disabled="isSavingData || isLockedInputs || toBeEditedCount === 0",
      v-if="!isLockedInputs",
      @click.prevent="onDataSubmit"
    )
      img.btn-loader(
        src="../../../assets/images/comps/loader.svg",
        v-if="isSavingData"
      )
      span Save
</template>

<script>
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";
export default {
  data() {
    return {
      contactTypes: [],
      oldContactTypes: [],
      isLoading: false,
      isSavingData: false,
      isLockedInputs: true,
      successfullCount: 0,
      errorsCount: 0,
    };
  },
  computed: {
    toBeEditedCount() {
      let count = 0;
      for (let i = 0; i < this.contactTypes.length; i++) {
        if (this.contactTypes[i].id === undefined) {
          count++;
        } else {
          for (const key in this.contactTypes[i]) {
            if (this.contactTypes[i][key] !== this.oldContactTypes[i][key]) {
              count++;
              break;
            }
          }
        }
      }
      return count;
    },
  },
  mounted() {
    this.fetchAllContacts();
  },
  methods: {
    ...mapActions({
      postClientContactsTypes: "postClientContactsTypes",
      postClientContactsTypesVisibility: "postClientContactsTypesVisibility",
      putClientContactsTypes: "putClientContactsTypes",
      getClientContactsTypesList: "getClientContactsTypesList",
    }),
    async fetchAllContacts() {
      const params = {
        getAllContactTypes: true,
      };
      this.isLoading = true;
      try {
        const res = await this.getClientContactsTypesList(params);
        this.contactTypes = res.data.data;
        this.oldContactTypes = JSON.parse(JSON.stringify(this.contactTypes));
        this.isLoading = false;
      } catch (err) {
        console.log("Error", err.message);
      }
    },
    async onChangeCheckbox(ct) {
      if (!ct.id) {
        // This is a case when there are pending new types
        return;
      }

      const params = {
        id: ct.id,
      };
      try {
        const res = await this.postClientContactsTypesVisibility(params);
        this.$toasted.success(res.data.message).goAway(2500);
      } catch (err) {
        console.log("Error", err.message);
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
    },
    async onChangeName(evt, ct) {
      ct.name = evt.target.value;

      if (!ct.id) {
        // This is a case when there are pending new types (or if it's an invalid input name)
        this.createNewContactType(evt, ct);
        return;
      }

      const params = {
        id: ct.id,
        name: ct.name,
      };
      try {
        await this.putClientContactsTypes(params);
        this.$toasted.success("Successfully changed").goAway(2500);
      } catch (err) {
        console.log("Error", err.message);
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
    },
    onAddNew() {
      this.contactTypes.push({
        name: "",
        hidden: false,
      });

      this.$nextTick(() => {
        // Scroll to bottom
        const sh = this.$refs.list.scrollHeight;
        const ch = this.$refs.list.clientHeight;
        this.$refs.list.scrollTop = sh - ch;
      });
    },
    onRemoveEmptyRow(index) {
      this.contactTypes.splice(index, 1);
    },
    onDataSubmit() {
      this.submitData();
    },
    async submitData() {
      let newOrEditedContacts = this.getNewOrEditedContactsArr();

      this.isSavingData = true;
      let successfullCountTemp = 0;
      let errorsCountTemp = 0;
      console.log(newOrEditedContacts);
      for (let i = 0; i < newOrEditedContacts.length; i++) {
        let index = newOrEditedContacts[i].index;
        delete newOrEditedContacts[i].index;

        let res;
        try {
          let newCt;
          if (newOrEditedContacts[i].id) {
            res = await this.putClientContactsTypes(newOrEditedContacts[i]);
            newCt = res.data.data;
            newCt.success = "Successfully edited";
          } else {
            res = await this.postClientContactsTypes(newOrEditedContacts[i]);
            newCt = res.data.data;
            newCt.success = "Successfully added";
          }
          this.contactTypes.splice(index, 1, newCt);
          this.oldContactTypes.splice(index, 1, { ...newCt });
          successfullCountTemp++;
          // this.$toasted.success('Successfully changed').goAway(2500)
        } catch (err) {
          errorsCountTemp++;
          this.isSavingData = false;
          console.log("Error", err.message);
          const errs = parseErrors(err);
          let newCt = { ...this.contactTypes[index] };
          if (errs) {
            newCt.error = errs;
          } else if (err.response.data.message) {
            newCt.error = err.response.data.message;
          }
          this.contactTypes.splice(index, 1, newCt);
          this.oldContactTypes.splice(index, 1, { ...newCt });
        }
      }
      this.successfullCount = successfullCountTemp;
      this.errorsCount = errorsCountTemp;
      this.isSavingData = false;
    },
    getNewOrEditedContactsArr() {
      let newArr = [];
      for (let i = 0; i < this.contactTypes.length; i++) {
        //clear statuses
        delete this.contactTypes[i].success;
        delete this.contactTypes[i].error;
        let ctObj = { ...this.contactTypes[i] };
        //index key is used to target errors
        ctObj.index = i;
        //new contact
        if (this.contactTypes[i].id === undefined) {
          newArr.push(ctObj);
          //editing contact
        } else {
          for (const key in this.contactTypes[i]) {
            if (this.contactTypes[i][key] !== this.oldContactTypes[i][key]) {
              newArr.push(ctObj);
              break;
            }
          }
        }
      }
      return newArr;
    },
    async createNewContactType(evt, ct) {
      if (!ct.name) {
        // If invalid name
        return;
      }

      const params = {
        name: ct.name,
      };
      try {
        const res = await this.postClientContactsTypes(params);
        this.contactTypes = res.data.data;
        this.$toasted.success("Successfully added").goAway(2500);
      } catch (err) {
        console.log("Error", err.message);
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
    },
    bindValue(index, key, value) {
      this.contactTypes[index][key] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
#security-contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;

  .table-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    width: 50%;
    max-height: 60vh;

    table {
      border-top: none;
      position: relative;
      margin-bottom: 0;
      .remove-wrap {
        .fa {
          margin-left: 5px;
          margin-top: 4px;
          cursor: pointer;
        }
      }
      tbody {
        tr {
          border: 1px solid #dde6eb;
        }
      }
      td {
        min-height: 100%;
      }
    }

    thead {
      tr:first-child {
        th {
          width: 20%;
          height: 36.2px;
          top: 0px;
        }
      }
      tr {
        th {
          top: 36px;
          height: 36.2px; // So it goes behind other row
        }
        th:not(:first-child) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td:not(:first-child) {
          text-align: center;
        }
      }
    }
    tfoot,
    tfoot th,
    tfoot td {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #dde6eb;
      border-bottom: 1px solid #dde6eb;
    }

    th {
      border-top: 1px solid #dde6eb;
      border-bottom: unset;
      position: sticky;
      z-index: 1;
    }
  }

  // ul {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-around;
  //   gap: 14px 10px;
  //   width: 600px;
  //   overflow: auto;

  //   li {
  //     display: flex;
  //     gap: 8px;
  //     align-items: center;

  //     .remove-wrap {
  //       width: 50px;

  //       .fa {
  //         cursor: pointer;
  //       }
  //     }
  //   }

  //   input[type="checkbox"] {
  //     width: 14px;
  //   }

  //   input[type="text"] {
  //     width: 200px;
  //   }
  // }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
}
</style>
