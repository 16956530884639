<template lang="pug">
.details
  table
    tbody
      tr
        th DATE
        td {{ billingDetails.date }}
      tr
        th TIME
        td {{ billingDetails.time }}
      tr
        th TOTAL HOURS
        td {{ billingDetails.totalHours }}
      tr
        th BREAKS
        td {{ billingDetails.breaks }}
      tr
        th TOTAL HOURS WORKED
        td --
      tr
        th AGENCY WORKER
        td {{ billingDetails.tempName }}
      tr
        th INCREMENT
        td {{ billingDetails.increment }}
      tr
        th CATEGORY
        td {{ billingDetails.category }}
      tr
        th CLIENT
        td {{ billingDetails.clientName }}
      tr
        th WARD
        td Nursing Admin
  center(style="border-bottom: 1px solid #405168")
    table.bill
      tbody
        //- tr
          th RATE
          td(style="background-color:#f2f7fa")
            div 15.39 &euro;
            div 15.39 &euro;
        tr
          th TOTAL HOURS
          td
            div {{ billingDetails.totalHours }}
        //- tr
          th GROSS PAY
          td(style="background-color:#f2f7fa")
            div 15.39 &euro;
            div 15.39 &euro;
        tr
          th ANNUAL LEAVE
          td
            div {{ billingDetails.annualLeave }} &euro;
        tr
          th HOLIDAY
          td(style="background-color:#f2f7fa")
            div {{ billingDetails.holiday }} &euro;
        tr
          th PRSI
          td
            div {{ billingDetails.prsi }} &euro;
        tr
          th SERVICE FEE
          td(style="background-color:#f2f7fa")
            div {{ billingDetails.fee }} &euro;
        tr
          th VAT
          td
            div {{ billingDetails.vat }} &euro;
        tr
          th(style="background-color: #10ADFF; color: white;") TOTAL
          td(style="background-color: #10ADFF; font-size: 25px;color: white;")
            //- div {{ billingDetails.total }} &euro;
            div {{ billingDetails.total }} &euro;
  center
    button.button.closeBtn(style="width:100px", @click="closeModal") Close
</template>

<script>
export default {
  props: ['modalProps'],
  data () {
    return {
      billingDetails: this.modalProps.billingDetails,
    };
  },
  methods: {
    closeModal () {
      this.$emit('cancel-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border: none;

  td {
    border: none;
    background-color: #f2f7fa;
    width: 50%;
    padding: 3px 0px;
    padding-left: 10px;
  }

  th {
    color: #7c91ae;
    text-align: right;
    width: 50%;
    padding: 3px 0px;
    padding-right: 10px;
  }
}

table.bill {
  width: 95%;
  border: none;
  padding: 30px 0px;

  tr {
    height: 42px;
  }

  th {
    background-color: white;
    width: 30%;
  }

  td {
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    color: #2669b0;
    text-align: center;
    width: 80%;

    div {
      box-sizing: border-box;
      display: inline-block;
      width: 50%;
      text-align: center;
    }
  }

  tr:nth-child(even) {
    background-color: red;
  }
}

.closeBtn {
  color: #2669b0;
  border-color: #2669b0;
  margin-top: 0;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.details {
  width: 100%;
}
</style>
