<template lang="pug">
.dialog-container(data-cell='edit-user-status' @click.stop="")
  .columns.is-multiline.spacer-align
    .column(v-for="statusEl in filteredStatuses")
      button.button.is-small.is-fullwidth.is-caps-lock(
        data-cell="status-buttons"
        :disabled="isLoading",
        :style="getButtonStyle(statusEl)",
        :class="spinner(statusEl.id)",
        type="submit",
        @click.prevent="callActionButton(statusEl.id)"
      ) {{ statusEl.label }}
  transition(name="fade")
    .delete-section(v-if="isDeleteDialogActive")
      span.action-message
        span Are you sure you want to change the user's status
        span.user-name(
          :title="modalProps.user.name",
          v-if="modalProps.user && modalProps.user.name"
        ) {{ modalProps.user.name }}
        span ?
      span.action-buttons
        a.button.is-small.is-confirm(@click="statusPreLogic()" data-cell="yes-button") YES
        a.button.is-small.is-deny(
          @click="lastStatusSelected = null; isDeleteDialogActive = false"
          data-cell="no-button"
        ) NO
  .buttons-centered
    button.button.is-small.is-outlined.is-caps-lock(
      @click.prevent="$emit('cancel-modal')"
      data-cell="cancel-button"
    ) Cancel
</template>

<script>
  import MultiSelect from 'vue-multiselect';
  import {
    mapActions
  } from 'vuex';
  export default {
    props: ['modalProps'],
    data () {
      return {
        dateTimeOption: null,
        isLoading: false,
        activeStatus: null,
        lastStatusSelected: null,
        isDeleteDialogActive: false,
        statusElements: [{
            id: 1,
            value: true,
            label: 'Active',
            backColor: '#23d160',
          },
          {
            id: 3,
            value: false,
            label: 'Inactive',
            backColor: '#ff2b56',
          },
        ],
      };
    },
    computed: {
      filteredStatuses () {
        return this.statusElements.filter(() => {
          return true;
          // return statusEl.id !== this.currentStatus
        });
      },
      spinner () {
        return function (button) {
          return {
            'is-loading': this.isLoading && button === this.activeStatus,
          };
        };
      },
    },
    methods: {
      ...mapActions({
        // putUserStatus: 'locationUserManagement/putUserStatus'
      }),
      callActionButton (statusId) {
        this.lastStatusSelected = statusId;
        this.isDeleteDialogActive = true;
      },
      statusPreLogic (statusId) {
        this.isDeleteDialogActive = false;
        // statusId could be mouseevent if handled differently
        const id = statusId || this.lastStatusSelected;
        if (!id) {
          console.error('Invalid ID', statusId, this.lastStatusSelected);
        }

        const foundObj = this.statusElements.find(el => el.id === id);
        if (foundObj && foundObj.action) {
          foundObj.action();
        } else {
          this.updateStatus(foundObj);
        }
      },
      async updateStatus ({id}) {
        if (id === 3) {
          this.$emit('deactivate', this.modalProps.user);
        } else if (id === 1) {
          this.$emit('activate', this.modalProps.user);
        }

        // this.isLoading = true
        // this.activeStatus = statusId
        // const apiParams = {
        //   userId: this.modalProps.user.id,
        //   params: {
        //     active: statusId
        //   }
        // }
        // try {
        //   await this.putUserStatus(apiParams)
        //   this.callModalClose()
        //   this.isLoading = false
        // } catch (err) {
        //   console.log(err.message);
        // }
      },
      callModalClose () {
        this.$emit('cancel-modal');
      },
      getButtonStyle (status) {
        return {
          color: status.color || 'white',
          backgroundColor: status.backColor,
        };
      },
    },
    components: {
      MultiSelect,
    },
  };
</script>
<style lang="scss">
.modal-content {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;

  .columns {
    flex-direction: column;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
}

.inputs {
  display: flex;
  justify-content: center;

  .field {
    width: 100%;
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

label {
  margin-top: 0.5em;
}

.spacer-align {
  margin: 20px 0;
}

div.delete-section {
  display: flex;
  border-top: 1px solid rgba(#405168, 0.15);
  border-bottom: 1px solid rgba(#405168, 0.15);
  align-items: center;
  justify-content: center;

  .action-message {
    display: flex;
    gap: 5px;
    margin-right: 1em;
    color: #405168;

    .user-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
    }
  }

  .action-buttons {
    a.button {
      border: none;
      margin: 0 0.3em;
    }
  }

  .is-confirm,
  .is-deny {
    font-weight: 900;
    font-size: 1.1em;
  }

  .is-confirm {
    color: #ff3b3b;
  }

  .is-deny {
    color: #2669b0;
  }
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}
</style>