<template lang="pug">
#notitcation-centre-edit-signature
  .template-name
    h2 Signature name
    input.input.is-medium(
      v-model="localSignature.name"
    )
  .content-wrap
    h2 Signature body
    quill-editor(
      ref="myQuillEditor"
      v-model="localSignature.data"
    )
  .button-line
    button.button.is-generic-app-blue.is-caps-lock(
      v-if="true"
      @click.prevent="onAddOrEditSignature"
    )
      | {{ modalProps? 'Edit signature' : 'Create signature' }}
    button.button.is-generic-app-blue.is-caps-lock(
      @click.prevent="$emit('dispose-modal');"
    )
      | Cancel
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
  components: {
    quillEditor,
  },
  props: {
    modalProps: {
      required: true,
    },
  },
  data () {
    return {
      localSignature: null,
    };
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.localSignature = JSON.parse(JSON.stringify(this.modalProps)) || {
        name: '',
        data: '',
      };
    },
    onAddOrEditSignature () {
      if (!this.isFormValid()) {
        return;
      }

      if (this.modalProps) {
        this.$emit("edit-signature", this.localSignature);
      } else {
        this.$emit("add-signature", this.localSignature);
      }

      this.$emit("dispose-modal");
    },
    isFormValid () {
      if (this.localSignature.name.length === 0) {
        this.$toasted.error('Signature must have a name.').goAway(5000);
        return false;
      }

      if (this.localSignature.data.length === 0) {
        this.$toasted.error('Signature must have a body.').goAway(5000);
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
#notitcation-centre-edit-signature {
  display: flex;
  flex-direction: column;
  gap: 3em;
  min-height: 35vh;
  padding: 1em 0 2em 0;
  .template-name {
    display: flex;
    flex-direction: column;
  }
  .button-line {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
}
</style>