<template lang='pug'>
#security-admin-deactivation-page
  .center
    .text
      span Automated admin deactivation (number of weeks)
    img.loader(
      src="../../../assets/images/comps/loader.svg",
      v-if="isLoadingSecuritySec"
    )
    template(v-if="!isLoadingSecuritySec")
      input.input(
        data-cell="number-of-weeks",
        v-model.number="dataVal",
        type="number",
        min="0",
        :class="{ 'is-error': isDataError }",
        @keyup.enter="onSave"
      )
      button.button.is-generic-app-light-blue(
        :disabled="isDataError",
        @click="onSave"
      ) Save new value
    hr(style="width: 100%")
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data () {
    return {
      dataVal: null,
      isLoadingSecuritySec: true,
    };
  },
  computed: {
    isDataError () {
      return !(parseInt(this.dataVal, 10) >= 0);
    },
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getInactiveSecurity: 'getInactiveSecurity',
      putInactiveSecurity: 'putInactiveSecurity',
    }),
    async fetchData () {
      const params = {};
      try {
        const res = await this.getInactiveSecurity(params);
        this.dataVal = res.data.data;
        this.isLoadingSecuritySec = false;
      } catch (err) {
        console.log(err.message);
        this.isLoadingSecuritySec = false;
      }
    },
    async onSave () {
      try {
        await this.confirmAlert('change the number of weeks');
      } catch (error) {
        return;
      }

      const params = {
        interval: this.dataVal,
      };
      try {
        await this.putInactiveSecurity(params);
        this.$toasted.success('Success').goAway(1500);
      } catch (err) {
        console.log(err.message);
        if (err.response && err.response.data && err.response.data.message) {
          this.$toasted.error(err.response.data.message).goAway(2500);
        }
      }
    },
    confirmAlert (strAct) {
      return new Promise((resolve,) => {
        let alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: '',
          type: 'warning',
          useConfirmBtn: true,
          customConfirmBtnText: 'Confirm',
          customConfirmBtnClass: 'button is-danger',
          customCloseBtnText: 'Cancel',
          customCloseBtnClass: 'button is-outlined',
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#security-admin-deactivation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  overflow: hidden;

  .center {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px 10px;
    align-items: center;
  }

  .loader {
    $size: 2rem;
    width: $size;
    height: $size;
    max-height: $size;
    max-width: $size;
  }
}
</style>