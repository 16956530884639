<template lang="pug">
.create-holiday-wrapper
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .text-message 
    p {{ textMessage }}
  .create-holiday-inputs 
    .holiday-name.hol
      label.label Name
        input.input(
          type="text",
          placeholder="Please enter holiday name",
          v-model="name",
          maxlength="170"
        )
    .holiday-date.hol
      label.label Date
        //- input.input(
        //-   v-model="date",
        //-   placeholder="Select date",
        //-   @input="addDash",
        //-   @keydown.delete="deleteChar",
        //-   maxlength="10"
        //- )
        flat-pickr(
          v-model="date",
          placeholder="Select date",
          :config="datePickerConfig"
        )
  .warn-msg 
    p(v-if="warnMsg") {{ warnMsg }}
  .confirm 
    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      @click="showWarningMessage"
    )
      span Create
    button.button.is-outlined.is-caps-lock(@click="callModalClose") Cancel
</template>

<script>
import { Errors } from "../../../lib/helpers/Errors.js";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function.js";
import moment from "moment";
import api from "../../../api";
import flatPickr from "vue-flatpickr-component";

export default {
  props: ["modalProps"],
  components: {
    flatPickr,
  },
  data() {
    return {
      date: "",
      name: "",
      warnMsg: "",
      textMessage:
        "Please type the name of the public holiday you wish to create. Then, select a date from the date picker. You cannot create a public holiday on a date which is already set up as public holiday in the system.",
    };
  },
  computed: {
    // isYearInputValid() {
    //   const currentYear = new Date().getFullYear();
    //   if (this.date.length === 10) {
    //     return true;
    //   }
    //   return false;
    // },
    datePickerConfig() {
      return {
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        enableTime: false,
        altInput: true,
        time_24hr: true,
      };
    },
  },
  methods: {
    ...mapActions({
      postCreatePublicHoliday: "postCreatePublicHoliday",
    }),
    callModalClose() {
      this.$emit("cancel-modal");
    },
    // addDash() {
    //   let date = this.date;
    //   if (date.length === 2) {
    //     date = `${date}-`;
    //     this.date = date;
    //   }
    //   if (date.length === 5) {
    //     date = `${date}-`;
    //     this.date = date;
    //   }
    // },
    // prepareForSend() {
    // this.date = moment(this.date, "DD-MM-YYYY").format("YYYY-MM-DD");
    // },
    deleteChar() {
      this.date = this.date.slice(0, -1);
    },
    async createNewHoliday() {
      try {
        // this.prepareForSend();
        const params = {
          hld_date: this.date,
          hld_name: this.name,
        };
        const res = await this.postCreatePublicHoliday(params);
        this.callModalClose();
        this.$emit("refresh-data", this.modalProps);
      } catch (err) {
        if (err.response.data.message) {
          this.warnMsg = err.response.data.message;
          return;
        }
        let errs = parseErrors(err);
        this.warnMsg = errs;
      }
    },
    showWarningMessage() {
      return new Promise(() => {
        let alert = {
          title: this.modalProps.warnMsg,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: this.createNewHoliday,
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-holiday-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  .text-message {
    text-align: center;
    padding: 10px 50px 5px 50px;
  }
  .create-holiday-inputs {
    display: flex;
    flex-direction: row;
    margin: 10px 0px 5px 0px;
    .hol {
      margin: 0 5px 0 5px;
    }
  }
  .warn-msg {
    color: red;
  }
  .confirm {
    button {
      margin: 0 10px 5px 10px;
    }
  }
}
</style>
