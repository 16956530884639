import { render, staticRenderFns } from "./Uploads.vue?vue&type=template&id=739295bb&scoped=true"
import script from "./Uploads.vue?vue&type=script&lang=js"
export * from "./Uploads.vue?vue&type=script&lang=js"
import style0 from "./Uploads.vue?vue&type=style&index=0&id=739295bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_2c597f7d109fe0052e634bf327ce7ff6/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "739295bb",
  null
  
)

export default component.exports