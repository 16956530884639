export const jsonify = function (value) {
  return JSON.stringify(value);
};

export const limit = function (value, limit) {
  if (value) {
    return value.slice(0, limit);
  }
};

export const commaSeparated = function (value) {
  if (value) {
    return value.map((e) => e.name).join(", ");
  }
};
