<template lang='pug'>
section#manage-rates
  h1.title.is-3
    i.fa.icon-rate-manage
    | Rate Management
  rates-page-tabs
  router-view.inner-page
</template>

<script>
import RatesPageTabs from './components/RatesPageTabs.vue';
export default {
  name: 'ManageRates',
  components: {
    RatesPageTabs,
  },
};
</script>

<style lang="scss" scoped>
#manage-rates {
  padding: 3rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;

  > .inner-page {
    margin-top: 20px;
    overflow: hidden;
    height: 100%;
  }
}
</style>