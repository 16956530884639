import api from "../../api/compliance";
import _ from "underscore";

import auth from "../../lib/auth.js";

const state = {
  rejectionReasons: [],
  complianceDocuments: [],
  documentsForApproval: [],
  tempDocuments: [],
};

const getters = {
  getComplianceDocs: (state) => state.complianceDocuments,
  getDocumentsForApproval: (state) => state.documentsForApproval,
  getRejectionReasons: (state) => state.rejectionReasons,
  getTempDocuments: (state) => state.tempDocuments,
};

const actions = {
  /**
   * Fill in rejection reasons
   */
  fetchRejectionReasons: ({ commit }) => {
    return api.rejectionReasons().then((response) => {
      commit("FETCH_REJECTION_REASONS", response.data);
    });
  },

  /**
   * Create new Document
   */
  createDocument: ({ dispatch }, payload) => {
    return api.createDocument(payload);
  },

  /**
   * Reject temp document
   */
  rejectTempDocument: (
    { commit },
    { tempId, documentId, documentRejectionReasonId, modifiers }
  ) => {
    console.log(
      tempId,
      documentId,
      documentRejectionReasonId,
      modifiers.context,
      modifiers.remove,
      modifiers.action
    );
    return api
      .rejectTempDocument({
        tempId,
        documentId,
        documentRejectionReasonId,
        include: "filesCount",
      })
      .then(({ data: { data } }) => {
        console.log(modifiers, "modifiers reject");
        // if (modifiers.remove) {
        //   commit('REJECT_TEMP_DOCUMENT', {
        //     data,
        //     tempId,
        //     context: modifiers.context
        //   })
        // }
        // updates status to rejected, but doesn't remove document from state!
        if (modifiers.action && modifiers.action === "reject") {
          console.log("status should be updated to: " + modifiers.action);
          // constant is 4 for 'rejected'
          // to find and update document in state (mutation),
          // use data.id (document id), data.temp.id (temp id) and data.status (status)
          data.context = modifiers.context;
          commit("CHANGE_DOCUMENT_STATUS", data);
        }
        if (modifiers.remove) {
          commit("REJECT_TEMP_DOCUMENT", {
            data,
            tempId,
            context: modifiers.context,
          });
        }
      });
  },

  approveTempDocument: ({ commit }, { tempId, documentId, modifiers }) => {
    return api
      .approveTempDocument({ tempId, documentId, include: "filesCount" })
      .then(({ data: { data } }) => {
        // not an error, the mutation does the right thing!
        // if (modifiers.remove) {
        //   commit('REJECT_TEMP_DOCUMENT', {
        //     data,
        //     tempId,
        //     context: modifiers.context
        //   })
        // }
        // updates status but does not remove document from state!
        if (modifiers.action && modifiers.action === "validate") {
          data.context = modifiers.context;
          commit("CHANGE_DOCUMENT_STATUS", data);
        }
        if (modifiers.remove) {
          commit("REJECT_TEMP_DOCUMENT", {
            data,
            tempId,
            context: modifiers.context,
          });
        }
      });
  },

  fetchCompliances: ({ commit }, q) => {
    return api.fetchCompliances(q).then((response) => {
      commit("FETCH_COMPLIANCES", response.data);
      commit("SET_PAGINATION", response.data, { root: true });
      return response;
    });
  },

  editComplianceOptional: ({ commit }, params) => {
    commit("EDIT_COMPLIANCE_OPTIONAL", params);
  },

  fetchDocumentsForApproval: ({ commit }, params) => {
    return api.fetchDocumentsForApproval(params).then((response) => {
      commit("FETCH_DOCUMENTS_FOR_APPROVAL", response.data);
      return response;
    });
  },

  /**
   * admin fethes temp documents
   *
   */
  fetchTempDocuments: ({ commit }, params) => {
    state.tempDocuments = [];
    return api.fetchTempDocuments(params).then((response) => {
      commit("FETCH_TEMP_DOCUMENTS", response.data);
      commit("SET_PAGINATION", response.data, { root: true });
      return response;
    });
  },
  fetchOneTempDocuments: ({ commit }, params) => {
    return api.fetchOneTempDocuments(params);
  },

  replaceTempDocument: ({ commit }, payload) => {
    // replace whole document in temp documents table
    commit("REPLACE_TEMP_DOCUMENT", payload);
  },

  patchDocument: ({ commit }, patchWith) => {
    return api
      .patchDocument(patchWith.docId, _.omit(patchWith, "docId"))
      .then(({ data: { data } }) => {
        commit("UPDATE_DOCUMENT_PROPERTY", {
          data,
          patch: _.omit(patchWith, "docId"),
        });
      });
  },
  changeExpiryDate: ({ commit }, payload) => {
    return api
      .changeExpiryDate(payload)
      .then((response) => {
        response.data.data.context = payload.modifiers.context;
        commit("CHANGE_DOCUMENT_STATUS", response.data.data);
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  updateExpiryDate: ({ commit }, payload) => {
    return api
      .updateExpiryDate(payload)
      .then((response) => {
        response.data.data.context = payload.modifiers.context;
        commit("CHANGE_DOCUMENT_STATUS", response.data.data);
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  removeDocument: ({ commit }, payload) => {
    return api.removeDocument(payload.id).then((response) => {
      commit("REMOVE_DOCUMENT", payload);
      return response;
    });
  },
  removeDocumentForApproval: ({ commit }, payload) => {
    return new Promise((resolve) => {
      commit("REMOVE_DOCUMENT_FOR_APPROVAL", payload);
      resolve(payload.message);
    });
  },
  deleteFiles: ({ commit }, payload) => {
    return api
      .deleteFiles(
        payload.documentId,
        payload.tempId,
        payload.files,
        payload.include
      )
      .then((response) => {
        commit("DELETE_FILES", response);
        return response;
      });
  },

  /**
   * Downloads report for selected client
   * in Compliance by Candidate table (lower one)
   */
  dlDocumentReport: (state, payload) => {
    return api.dlDocumentReport(payload).then((response) => {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Report.xlsx";
      link.click();
    });
  },
};

const mutations = {
  DELETE_FILES(state, { data }) {
    // should update state to reflect changes after file deletion
    console.log(data);
  },

  FETCH_REJECTION_REASONS(state, { data }) {
    state.rejectionReasons = data;
  },

  FETCH_COMPLIANCES(state, { data }) {
    state.complianceDocuments = data;
  },

  EDIT_COMPLIANCE_OPTIONAL(state, params) {
    for (let i = 0; i < state.complianceDocuments.length; i++) {
      if (state.complianceDocuments[i].id === params.id) {
        state.complianceDocuments[i].optional = params.val;
        break;
      }
    }
  },

  FETCH_DOCUMENTS_FOR_APPROVAL(state, { data }) {
    state.documentsForApproval = data;
  },

  FETCH_TEMP_DOCUMENTS(state, { data }) {
    state.tempDocuments = data;
  },

  UPDATE_DOCUMENT_PROPERTY(state, { data, patch }) {
    const doc = _.findWhere(state.complianceDocuments, { id: data.id });
    /**
     * TODO find better way of doing this based on different props that might
     * be available
     */
    if ("info_link" in patch) {
      doc.infoLink = patch.info_link;
    }
    if ("locations" in patch) {
      doc.locations = data.locations;
    }
    if ("categories" in patch) {
      doc.categories = data.categories;
    }
    if ("roles" in patch) {
      doc.roles = data.roles;
    }
  },

  /**
   * payload: {
   *   data,
   *   tempId
   * }
   * Remove from state temp doc where doc id = data.id && temp.id = tempId
   */
  REJECT_TEMP_DOCUMENT(state, payload) {
    // let allDocs = state.documentsForApproval
    const allDocs = state[payload.context];
    const docToRemove = _.find(allDocs, function (doc) {
      return doc.id === payload.data.id && doc.temp.id === payload.tempId;
    });
    allDocs.splice(_.indexOf(allDocs, docToRemove), 1);
  },

  CHANGE_DOCUMENT_STATUS(state, payload) {
    const docId = payload.id;
    const tempId = payload.temp.id;

    // let foundIndex = _.findIndex(state[ payload.context ], function (doc) {
    //   return doc.id === docId && doc.temp.id === tempId
    // })
    let foundIndex = null;
    for (let i = 0; i < state[payload.context].length; i++) {
      const doc = state[payload.context][i];
      if (doc.id === docId && doc.temp.id === tempId) {
        foundIndex = i;
      }
    }
    // state update for this document
    if (foundIndex !== null) {
      state[payload.context].splice(foundIndex, 1, payload);
    } else {
      console.log("Not found index for ", docId, tempId);
    }
  },

  REPLACE_TEMP_DOCUMENT(state, payload) {
    state.tempDocuments[payload.idx] = payload.data;
  },

  /*
   * TODO change those mutation string to follow naming convention used through
   * the whole project
   */

  REMOVE_DOCUMENT(state, payload) {
    const index = _.findIndex(state.complianceDocuments, function (doc) {
      return doc.id === payload.id;
    });
    state.complianceDocuments.splice(index, 1);
  },

  /**
   * TODO join this one with the one above, because of similarity
   * only the context is changed.
   */
  REMOVE_DOCUMENT_FOR_APPROVAL(state, payload) {
    const index = state.documentsForApproval.findIndex(
      (doc) => doc.id === payload.id
    );
    state.documentsForApproval.splice(index, 1);
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
