<template lang="pug">
.edit-holiday-wrapper
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .text-message 
    p {{ textMessage }}
  .inputs
    label.label Name
      input.input(
        type="text",
        placeholder="Holiday name",
        v-model="hld_name",
        maxlength="170"
      )
    label.label Date
      //- input.input(
      //-   type="text",
      //-   placeholder="Select date",
      //-   v-model="hld_new_date",
      //-   @input="addDash",
      //-   @keydown.delete="deleteChar",
      //-   maxlength="10"
      //- )
      flat-pickr(
        v-model="hld_new_date",
        placeholder="Select date",
        :config="datePickerConfig"
      )
  .warrning-message 
    p(v-if="warnMsg") {{ warnMsg }}
  .buttons 
    button.button.is-danger.is-caps-lock(@click="showWarningMessage") Delete
    button.button.is-generic-app-blue.is-caps-lock(
      @click="showWarningMessage2"
    ) Save
    button.button.is-outlined.is-caps-lock(@click="callModalClose") Cancel
</template>

<script>
import { Errors } from "../../../lib/helpers/Errors.js";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function.js";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  props: ["modalProps"],
  components: {
    flatPickr,
  },
  data() {
    return {
      hld_date: "",
      hld_new_date: "",
      hld_name: "",
      warnMsg: "",
      textMessage:
        "Please type the name to edit the public holiday name. Select the date you wish to change this public holiday to. Please note that you cannot create a holiday on a date which is already set up as holiday in the system.",
    };
  },
  computed: {
    datePickerConfig() {
      return {
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        enableTime: false,
        altInput: true,
        time_24hr: true,
      };
    },
  },
  mounted() {
    this.setDataOnMounted();
  },
  methods: {
    ...mapActions({
      updateHoliday: "updateHoliday",
      deleteHoliday: "deleteHoliday",
    }),
    prepareForSend() {
      this.hld_date = moment(this.hld_date, "DD-MM-YYYY").format("YYYY-MM-DD");
    },
    callModalClose() {
      this.$emit("cancel-modal");
    },
    setDataOnMounted() {
      this.hld_date = this.modalProps.hld_date;
      this.hld_new_date = moment(this.modalProps.hld_date, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
      this.hld_name = this.modalProps.hld_name;
    },
    async updatePublicHoliday() {
      this.prepareForSend();
      const params = {
        hld_date: this.hld_date,
        hld_new_date: this.hld_new_date,
        hld_name: this.hld_name,
      };
      try {
        const res = await this.updateHoliday(params);
        this.callModalClose();
        this.$emit("refresh-data", this.modalProps);
      } catch (err) {
        if (err.response.data.message) {
          this.warnMsg = err.response.data.message;
          this.hld_date = this.modalProps.hld_date;
          return;
        }
        const errs = parseErrors(err);
        this.warnMsg = errs;
        this.hld_date = this.modalProps.hld_date;
        this.hld_new_date = this.modalProps.hld_date;
      }
    },
    async deletePublicHoliday() {
      // this.prepareForSend();
      const params = {
        hld_date: this.hld_new_date,
      };
      try {
        const res = await this.deleteHoliday(params);
        this.callModalClose();
        this.$emit("refresh-data", this.modalProps);
      } catch (err) {
        if (err.response.data.message) {
          this.warnMsg = err.response.data.message;
          return;
        }
        const errs = parseErrors(err);
        this.warnMsg = errs;
      }
    },
    showWarningMessage() {
      return new Promise(() => {
        const alert = {
          title: this.modalProps.warnMsg,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: this.deletePublicHoliday,
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    showWarningMessage2() {
      return new Promise(() => {
        const alert = {
          title: this.modalProps.warnMsg,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: this.updatePublicHoliday,
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-holiday-wrapper {
  display: flex;
  flex-direction: column;
  .text-message {
    text-align: center;
    padding: 10px 50px 5px 50px;
  }
  .inputs {
    gap: 20px;
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: center;
  }
  .warrning-message {
    display: flex;
    justify-content: center;
    color: red;
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0 5px 0;
  }
}
</style>
