
export class Errors {

  constructor () {
    this.errors = {};
  }
  all () {
    let errArray = [];
    Object.keys(this.errors).map(key => {
      errArray.push(this.errors[key]);
    });
    return errArray[0];
  }
  /**
   * Accessor for laravel formatted validation errors
   */
  get (field) {
    if (this.errors[field]) {
      let msgs = `
        <p class="msg">
          ${this.errors[field].join('</p><p>')}
        </p>
      `;
      return msgs;
    }
  }
  /**
  * Populate the errors object with validation errors from backend
  */
  record (errors) {
    this.errors = errors;
  }
  /**
   * Clears out the field, when user starts to type after failed validation
   */
  clear (field) {
    if (field) {
      delete this.errors[field];
    } else {
      this.errors = {};
    }
  }
  clearLike (field) {
    for (const key of Object.keys(this.errors)) {
      if (key.indexOf(field) !== -1) {
        delete this.errors[key];
      }
    }
  }
  /**
   * Check if field exists (do not look in prototype)
   */
  has (field) {
    const objectToCheck = this.errors || {};
    if (!this.errors) {
      console.warn('No errors object', this.errors);
    }
    return Object.prototype.hasOwnProperty.call(objectToCheck, field);
  }
  // If it has any part of the string
  hasLike (field) {
    return Boolean(Object.keys(this.errors).find(key => key.indexOf(field) !== -1));
  }
  /**
  * Check if we have any error
  */
  any () {
    return Object.keys(this.errors).length > 0;
  }
}
