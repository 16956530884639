<template lang="pug">
.dialog-container.repush-for-temps
  label.label.checkbox
    input(
      type="checkbox",
      v-model="regardlessAvailability",
      :true-value="0",
      :false-value="2"
    )
    //- span Select all, regardless of availability
    span All candidates regardless of availability
  .action-bar
    label(v-if="tempsForShift.length")
      button.button.is-generic-app-teal(@click="selectAllAvailable") Select all available
    input.input(
      v-model="searchField",
      placeholder="Search by name",
      title="Search by name",
      type="text",
      @input="searchWorkers"
    )
  .inputs
    .field
      label.label
        span Select agency workers for booking
        span(
          style="margin-left: 10px",
          v-if="selectedForPush && selectedForPush.length"
        ) ( Selected workers: {{ selectedForPush.length }} )
      p.control
  table.table.is-striped
    thead
      tr
        th Select
        th Name
        th Response status
    tbody
      tr(
        v-for="temp in tempsForShift",
        v-if="!isLoading && tempsForShift.length !== 0"
      )
        td
          input(type="checkbox", :value="temp.id", v-model="selectedForPush")
        td(:class="{ preferred: temp.is_temp_preferred == 1 }") {{ temp.name }}
        td
          span.generic-app-tag(
            :style="{ backgroundColor: repushStatus(temp.response).hex }"
          ) {{ temp.response ? repushStatus(temp.response).label : "-" }}
      tr(v-if="!isLoading && !tempsForShift.length")
        td(colspan="3") None available
      tr(v-if="isLoading")
        td.center-text(colspan="12")
          img(src="../../assets/images/comps/loader.svg")

  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      :disabled="isButtonDisabled",
      @click.prevent="push"
    ) Book
    button.cancel-btn.button.is-small.is-outlined.is-caps-lock(
      @click.prevent="callModalClose"
    ) Cancel
</template>

<script>
import { debounce } from "underscore";
import { mapActions, mapGetters } from "vuex";
import { parseErrors } from "../../lib/helpers/function.js";
// import _ from 'underscore'
import { REPUSH_STATUS } from "../config/shifts.js";

export default {
  props: ["modalProps"],
  data() {
    return {
      shift: null,
      isLoading: false,
      isButtonDisabled: false,
      selectedForPush: [],
      regardlessAvailability: 2,
      searchField: null,
    };
  },
  computed: {
    ...mapGetters({ tempsForShift: "getTempsForShift" }),
  },
  watch: {
    regardlessAvailability: function () {
      this.selectedForPush = [];
      this.fetchTemps(this.shift.id);
    },
  },
  mounted() {
    this.shift = this.modalProps.shift;
    this.fetchTemps(this.shift.id);
  },
  methods: {
    ...mapActions(["fetchTempsForShift", "pushToTemps"]),
    push() {
      this.isButtonDisabled = true;
      // push to checked temps
      const temps = this.selectedForPush.map((id) => ({ id: id }));
      this.pushToTemps({
        id: this.shift.id,
        temps: temps,
        availability: this.regardlessAvailability,
      })
        .then(() => {
          this.$toasted.info("Repush successful!").goAway(2500);
          this.callModalClose();
        })
        .catch((error) => {
          const errs = parseErrors(error);
          this.$toasted.error(errs).goAway(4500);
        })
        .finally(() => {
          this.isButtonDisabled = false;
        });
    },
    repushStatus: (status) => {
      const statusObj = REPUSH_STATUS.find(
        (statusObj) => statusObj.status === status
      );
      // let statusObj = _.findWhere(REPUSH_STATUS, { status: status })
      if (statusObj) {
        return {
          hex: statusObj.hex,
          label: statusObj.label,
        };
      }
      return {
        hex: "",
        label: "",
      };
    },
    fetchTemps(id) {
      this.isLoading = true;
      this.fetchTempsForShift({
        id,
        availability: this.regardlessAvailability,
        name: this.searchField,
        shift_type: this.shift.type,
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          const errs = parseErrors(error);
          this.$toasted.error(errs).goAway(4500);
        });
    },
    callModalClose() {
      this.$emit("dispose-modal");
    },
    selectAllAvailable() {
      const arr = [];
      for (const temp of this.tempsForShift) {
        if (temp.status !== 2) {
          arr.push(temp.id);
        }
      }
      this.selectedForPush = arr;
    },
    searchWorkers: debounce(function () {
      this.fetchTemps(this.shift.id);
    }, 200),
  },
};
</script>
<style lang="scss">
.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.modal-content {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
  padding-top: 1em;
}

.action-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  label {
    margin: 0;
  }
}

.inputs {
  display: flex;
  justify-content: center;

  .field {
    width: 100%;
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

label {
  margin-top: 0.5em;
}

td.preferred {
  font-weight: bold;
}
</style>
