<template lang="pug">
.schedule-tabs
  section.section
    h1.title.is-3
      i.fa.icon-Shift-Schedule
      | {{ auth.user.role === "temp" ? "My Shifts" : "Shift Schedule" }}
    uni-tabs(
      style="margin-bottom: 1.5rem",
      :tabs="filteredTabs",
      :active-tab="getActiveTabParsed",
      @tab-clicked="onTabClicked"
    )
    shift-schedule-list(
      :isFirstLoad="isFirstLoad",
      :predefinedDateFrom="predefinedDateFrom",
      :activeTab="getActiveTabProp",
      :filtersGlobal="filtersGlobal",
      :cancelTokenShifts="cancelTokenShifts",
      :cancelTokenShiftsPagination="cancelTokenShiftsPagination",
      :actionArr="actionArr",
      :key="getActiveTabParsed && getActiveTabParsed.name",
      :isLoadingClient="isLoadingClient",
      :shift-filters-instance="shiftFiltersInstance",
      @cancel-shift-token="cancelTokenShifts = $event",
      @cancel-shift-page-token="cancelTokenShiftsPagination = $event",
      @update-filter-global="emitFilters",
      @change-first-load="isFirstLoad = $event",
      @change-predefined-date="predefinedDateFrom = $event"
    )
</template>
<script>
import ShiftScheduleList from "./ShiftScheduleList.vue";
import ShiftDetails from "./ShiftDetails.vue";
import { STATUS } from "../config/shifts.js";
import auth from "../../lib/auth.js";
import { mapActions } from "vuex";

export default {
  name: "shiftScheduleTabs",
  components: {
    ShiftScheduleList,
    ShiftDetails,
  },
  props: ["filtersGlobal", "actionArr", "shiftFiltersInstance"],
  data() {
    return {
      auth: auth,
      cancelTokenShifts: null,
      cancelTokenShiftsPagination: null,
      isLoadingClient: false,
      status: {
        pending: STATUS.PENDING,
        active: STATUS.ACTIVE,
        completed: STATUS.COMPLETED,
        never_filled: STATUS.NEVER_FILLED,
        cancelled: STATUS.CANCELLED,
      },
      isFirstLoad: true,
      predefinedDateFrom: true,
    };
  },
  computed: {
    tabs() {
      const resetParams = {
        filter: undefined,
        view: undefined,
        dash_filter: undefined,
        calendar: undefined,
        shift_id: undefined,
      };
      return [
        {
          label: "PENDING",
          name: "pending",
          routeName: "shiftSchedule",
          routeQuery: {
            status: "1",
            page: 1,
            ...resetParams,
          },
        },
        {
          label: "ACTIVE",
          name: "active",
          routeName: "shiftSchedule",
          routeQuery: {
            status: "2",
            page: 1,
            ...resetParams,
          },
        },
        {
          label: "COMPLETED",
          name: "completed",
          routeName: "shiftSchedule",
          routeQuery: {
            status: "3",
            page: 1,
            ...resetParams,
          },
        },
        {
          label: "NEVER FILLED",
          name: "never_filled",
          routeName: "shiftSchedule",
          routeQuery: {
            status: "4",
            page: 1,
            ...resetParams,
          },
        },
        {
          label: "CANCELLED",
          name: "cancelled",
          routeName: "shiftSchedule",
          routeQuery: {
            status: "5",
            page: 1,
            ...resetParams,
          },
        },
      ];
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        // Custom code exit
        const isBlockedTemp = ["never_filled", "cancelled"];
        if (isBlockedTemp.includes(tab.name)) {
          if (auth.user.role === "temp") {
            return false;
          }
        }

        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
    getActiveTabParsed() {
      const foundTab = this.filteredTabs.find(
        (tab) =>
          parseInt(tab.routeQuery.status, 10) ===
          parseInt(this.$route.query.status, 10)
      );
      return foundTab || this.filteredTabs?.[0] || null;
    },
    getActiveTabProp() {
      const parseTabProp = (tab) => {
        return {
          name: tab.label,
          status: tab.routeQuery.status,
        };
      };
      // Compat func
      if (this.getActiveTabParsed) {
        return parseTabProp(this.getActiveTabParsed);
      } else if (this.filteredTabs.length) {
        return parseTabProp(this.filteredTabs[0]);
      }
      console.warn("Invalid ss tabs");
      return {};
    },
  },
  beforeRouteEnter(to, from, next) {
    // When coming from /submit-shift view, reset activeTab.
    if (from.fullPath === "/submit-shift") {
      next({
        name: to.name,
        query: {
          ...to.query,
          status: 1,
        },
      });
    }
    if (!to.query.status) {
      next({
        name: to.name,
        query: {
          ...to.query,
          status: 1,
        },
      });
    }
    next();
  },
  created() {
    this.checkFetchClients();
    this.initFetch();
  },
  mounted() {
    // Used to store vuex selected tab
    this.onTabClicked(this.getActiveTabParsed);
  },
  methods: {
    ...mapActions({
      fetchClientsList: "fetchClientsList",
      fetchAllCategoriesList: "fetchAllCategoriesList",
    }),
    emitFilters(params) {
      this.$emit("update-filter-global", params);
    },
    onTabClicked(tab) {
      // Compat func
      this.$store.commit("SET_ACTIVE_TAB", {
        name: tab.label,
        status: parseInt(tab.routeQuery.status, 10),
      });
    },
    async checkFetchClients() {
      if (this.auth.user.role && this.auth.user.role !== "temp") {
        //--- Only one of admins may require this information, for CostCenters filter to work
        this.isLoadingClient = true;
        try {
          const clientIncludes = ["locations", "withArchived"].join();
          await this.fetchClientsList({
            includes: clientIncludes,
            per_page: 999,
          });
          this.isLoadingClient = false;
        } catch (err) {
          this.isLoadingClient = false;
        }
      }
    },
    initFetch() {
      this.fetchAllCategoriesList();
    },
  },
};
</script>
<style></style>
