export function getFilterData(key, type) {
    if (!type.meta)
        return null;
    try {
        if (key in type.meta) {
            // @ts-expect-error
            return type.meta[key];
        }
    }
    catch (err) {
        console.warn("Error", err.message, key);
    }
    // console.warn("Missing implementation meta", key);
    return null;
}
export function getColumnData(key, type, isModal = false) {
    if (isModal) {
        // @ts-expect-error
        return type.columns[key].multi;
    }
    if (key in type.columns) {
        // @ts-expect-error
        return type.columns[key].single;
    }
    console.warn("Missing implementation column", key);
    return null;
}
export function canDisplayFilter(key, type) {
    return getFilterData(key, type)?.visible || false;
}
export function canDisplayColumn(key, type, isModal = false) {
    try {
        return getColumnData(key, type, isModal)?.visible || false;
    }
    catch (err) {
        console.warn("ERROR", err.message, key);
    }
    return false;
}
export function getCalcItems(key, type, isModal = false) {
    if (isModal) {
        // @ts-expect-error
        return type["calcitems"][key].multi;
    }
    if (key in type["calcitems"]) {
        // @ts-expect-error
        return type["calcitems"][key].single;
    }
    console.warn("Missing implementation calcitems", key);
    return null;
}
