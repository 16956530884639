import _ from "underscore";

export default {
  SET_INITIAL_AGENCY_STATUS(state, data) {
    state.initialStatus = data;
  },
  CLEAR_LOCATIONS(state) {
    state.costs = [];
  },
  CLEAR_CATEGORIES(state) {
    state.categories = [];
  },
  SET_PAGINATION(state, { meta }) {
    state.pagination = meta.pagination;
  },
  CLEAR_PAGINATION(state) {
    state.pagination = [];
  },
  FETCH_ALL_TEMPS(state, { data, meta }) {
    state.temps = data;
    state.pagination = meta.pagination;
  },
  SET_CLIENTS(state, { data }) {
    state.clients = data;
  },
  SET_CLIENTS_ACTIVITY_TAGS(state, data) {
    // console.log(data)
    state.clientsShiftActivityTags = data;
  },
  STORE_LOCATIONS_PER_CLIENT(state, payload) {
    state.costs = payload.data;
  },

  FETCH_ALL_CATEGORIES(state, { data }) {
    state.allCategories = data;
  },

  FETCH_ALL_ROLES(state, { data }) {
    state.allRoles = data;
  },
  FETCH_MIN_ROLES(state, { data }) {
    state.minRoles = data;
  },

  FETCH_SPECIALITIES(state, { data }) {
    state.allSpecialities = data;
  },

  FETCH_USER_PROFILE(state, { data }) {
    state.userProfile = data;
  },

  FETCH_TEMPS(state, { data, meta }) {
    console.log(state);
    console.log(data);
    console.log(meta);
    state.temps = data;
    state.pagination = meta.pagination;
  },
  VALIDATE_PASSWORD(state, data) {
    // console.log(state);
    // console.log(data);
    // console.log(meta);
    state.passwordValidationStore = data;
    // state.pagination = meta.pagination;
  },

  CLEAR_TEMPS(state) {
    state.temps = [];
    state.pagination = null;
  },

  PATCH_TEMP(state, payload) {
    const apiObj = payload.data.data;
    const whichTemp = _.findIndex(state.temps, { id: apiObj.id });
    console.log("PATCHING TEMP :: ", state.temps[whichTemp].id);
    console.log("WITH :: ", apiObj);
    state.temps.splice(whichTemp, 1, apiObj);
  },

  PATCH_TEMP_INCREMENTS(state, payload) {
    const apiObj = payload.data;
    console.log("API OBJ :: ", apiObj);
    console.log("STATE TEMPS :: ", state.temps);
    // In case there are no temps in vuex, do nothing
    if (state.temps.length > 0) {
      const whichTemp = _.findIndex(state.temps, { id: apiObj.id });
      console.log("FOUND TEMP INDEX :: ", whichTemp);
      state.temps[whichTemp].increments = apiObj.increments;
    }
  },

  /**
   * originallySelectedWorker: keeps reference on originally selected worker
   * to be able to easily remove it
   */
  FETCH_AGENCY_WORKERS(state, data) {
    state.agency_workers = data;
  },

  FETCH_TEMP_PROFILE(state, data) {
    state.tempProfile = data;
  },

  PATCH_USER(state, payload) {
    const apiObj = payload.data.data;
    if (Array.isArray(state.users) && state.users?.length) {
      const index = state.users.findIndex((user) => user.id === apiObj.id);
      console.log("PATCHING INDEX:: ", index);
      Object.entries(apiObj).forEach(([key, val]) => {
        state.users[index][key] = val;
      });
    }
  },
  REMOVE_USER_FROM_LIST(state, id) {
    if (state.users && Array.isArray(state.users) && state.users.length) {
      const userIndex = state.users.findIndex((user) => user.id === id);
      if (userIndex !== -1) {
        state.users.splice(userIndex, 1);
      }
    }
  },

  FETCH_USERS(state, data) {
    state.users = data.data;
    state.pagination = data.meta.pagination;
  },

  CREATE_USER(state, data) {
    state.users.push(data.data);
  },

  DELETE_USER(state, data) {
    const who = _.findIndex(state.users, { id: data.id });
    state.users.splice(who, 1);
  },

  CLEAR_USERS(state) {
    state.users = [];
    state.pagination = null;
  },

  STORE_RATES_SECTORS(state, data) {
    state.rateSectors = data;
  },
  STORE_RATES_CATEGORIES(state, data) {
    state.rateCategories = data;
  },
  RATE_PICK_SECTOR(state, data) {
    state.rateSectorSelected = data;
  },
  SET_COLUMN_NAME_DATA(state, data) {
    state.columnNamesLocl.api = data;
  },
};
