import { default as axios } from "./config.js";
import { packLaravelArray } from "@/lib/helpers/function";
// import { IMeta } from "@/TS/commonTypes";
function appendUrl(p) {
    let url = "";
    const laravelArr = Object.keys(p);
    for (const larKey of laravelArr) {
        if (p[larKey]) {
            const packed = packLaravelArray(larKey, p[larKey]);
            if (packed !== "") {
                // If first
                if (url.indexOf("?") === -1) {
                    url = url + "?" + packed;
                }
                else {
                    url = url + "&" + packed;
                }
            }
            // Has to be called (delete) so it doesn't send duplicate params
            delete p[larKey];
        }
    }
    return url;
}
export default {
    // Shift Details
    getShiftQuote: (p) => axios.get(`shifts/${p.id}/get-quote`, {
        params: p,
    }),
    postShiftQuote: (p) => axios.post(`shifts/${p.id}/confirm-quote`, p),
    getShiftQuoteReport: (p) => axios.post(`shifts/${p.id}/get-quote-report`, p, {
        responseType: "arraybuffer",
    }),
    getTempsShift: (p) => axios.get(`temps-for-shift/${p.id}?name=${p.name}`, { params: p }),
    postConfirmShift: (p) => axios.post(`shifts/${p.id}/confirm-temp`, p),
    // Rate management
    getClientUsingRates: (p) => axios.get(`global-rates/clients/${p.clientId}/clients-using-rates`, {
        params: p,
    }),
    // Shift contacts
    getShiftContacts: (p) => {
        if (p.categories?.length) {
            const url = appendUrl({
                categories: p.categories,
            });
            return axios.get(`shift-confirmation-contacts${url}`, {
                params: p,
            });
        }
        return axios.get("shift-confirmation-contacts", { params: p });
    },
    postClientContacts: (p) => axios.post(`shift-confirmation-contacts`, p),
    putClientContacts: (p) => axios.put(`shift-confirmation-contacts/${p.id}`, p),
    deleteClientContacts: (p) => axios.delete(`shift-confirmation-contacts/${p.id}`),
    getClientNotifInterval: (p) => axios.get(`clients/${p.client_id}/email-intervals`, { params: p }),
    postClientNotifInterval: (p) => axios.post(`clients/${p.client_id}/email-intervals`, p),
    putClientNotifInterval: (p) => axios.put(`clients/${p.client_id}/email-intervals/${p.id}`, p),
    deleteClientNotifInterval: (p) => axios.delete(`clients/${p.client_id}/email-intervals/${p.id}`),
    // Shift Activity Tags
    getShiftActivityTagsForClient: (p) => axios.get(`clients/${p.client_id}/shift-activity-tags`, { params: p }),
};
