<template lang="pug">
div
  table.table
    thead
      th
        span INVOICE ID
      th
        span DATE
      th
        span TOTAL AMOUNT
      th
        span CREATED AT
      th.is-narrow
        span(v-if="$can('expenses-invoice-download-backup-excel')") BACKUP EXCEL
      th.is-narrow
        span(v-if="$can('expenses-invoice-download-pay-and-bill')") EXCEL
    tbody(v-if="!loading")
      tr(v-for="(data, index) in filteredList")
        td(data-cell="number")
          span # {{ data.number }}
        td(data-cell="name")
          span {{ data.name }}
        td(data-cell="amount")
          span {{ data.totalAmount }}
        td(data-cell="createdAt")
          span {{ data.createdAt }}
        td.is-narrow(data-cell="download")
          button.button.is-tiny.is-generic-app-blue(
            :title="getLastDownloadDate(data.lastBackup)",
            :disabled="loadingBackupExcelId === data.id",
            v-if="$can('expenses-invoice-download-backup-excel')",
            @click="onClickDownloadBackupExcel(data, index)"
          ) BACKUP
        td.is-narrow(data-cell="download")
          button.button.is-tiny.is-generic-app-blue(
            :title="getLastDownloadDate(data.lastPayAndBill)",
            :disabled="downloadingItemId",
            v-if="$can('expenses-invoice-download-pay-and-bill')",
            @click="onClickDownloadPayBill(data, index)"
          ) PAY &amp; BILL
      tr.empty(v-if="filteredList.length === 0")
        td(colspan="100", style="text-align: center") No data in table
    tbody(v-if="loading")
      tr
        td.center-text(colspan="10")
          img(src="@/assets/images/comps/loader.svg")
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapActions } from "vuex";
import { parseErrors, createZipFromFiles } from "@/lib/helpers/function";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      downloadingItemId: null,
      loadingBackupExcelId: null,
    };
  },
  computed: {
    filteredList() {
      return this.list;
    },
  },
  methods: {
    ...mapActions({
      getPayBillExpensesGroupDownload: "getPayBillExpensesGroupDownload",
      getPayBillExpensesBackup: "getPayBillExpensesBackup",
    }),
    async fetchGroupDownload(id) {
      try {
        const res = await this.getPayBillExpensesGroupDownload({ id });
        if (Array.isArray(res.data.invoices) && res.data.invoices?.length) {
          const filesArr = [];
          for (let index = 0; index < res.data.invoices.length; index++) {
            const invoice = res.data.invoices[index];
            const appendName = index + 1 === 1 ? "" : ` - ${index}`;
            const name = `${this.getInvoiceTitle(invoice)}${appendName}.xlsx`;
            const blob = await this.downloadBackupExcel(invoice.id, name);
            if (blob) {
              filesArr.push({ name, blob });
            } else {
              console.error("No blob found", id, name);
            }
          }
          const zipBlob = await createZipFromFiles(filesArr);
          if (zipBlob) {
            this.downloadBackupExcelZip(zipBlob);
          }
        }
      } catch (err) {
        console.warn(err.message);
      }
      // Evt.fire("showNewInvoiceModal", createForAll);
    },
    async downloadBackupExcel(id, name) {
      try {
        const res = await this.getPayBillExpensesBackup({ id });
        let blob = new Blob([res.data], { type: "application/xlsx" });
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement("a");
        // a.href = url;
        // a.download = `${name}.xlsx`;
        // a.click();
        // window.URL.revokeObjectURL(url);
        return blob;
      } catch (err) {
        console.warn(err.message);
      }
      return null;
    },
    async onClickDownloadBackupExcel(item) {
      this.loadingBackupExcelId = item.id;
      await this.fetchGroupDownload(item.id);
      this.loadingBackupExcelId = null;
    },
    async downloadBackupExcelZip(zipBlob) {
      const nameBase = "backup-excel-all";
      const a = document.createElement("a");
      document.body.appendChild(a);
      try {
        let blob = new Blob([zipBlob]);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        let currDate = moment().format("DD-MM-YYYY");
        a.download = `${nameBase} - ${currDate}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error(err.message);
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
      document.body.removeChild(a);
    },
    async onClickDownloadPayBill(item) {
      this.downloadingItemId = item.id;
      const name = "pay-and-bill.csv";
      const apiUrl = `/api/invoices/expenses/${item.id}/${name}`;
      const a = document.createElement("a");
      document.body.appendChild(a);
      try {
        const response = await axios.get(apiUrl, {
          responseType: "arraybuffer",
          params: {
            timestamp: new Date().getTime(),
          },
        });
        let blob = new Blob([response.data], { type: "application/csv" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.downloadingItemId = null;
      } catch (err) {
        console.error(err.message);
        this.downloadingItemId = null;
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
      document.body.removeChild(a);
    },
    getLastDownloadDate(item) {
      // const dateObj = item.lastDownload;
      const dateObj = item;
      if (!dateObj) {
        return "";
        // return "Not downloaded yet";
      }
      return (
        "Last downloaded at:\n" +
        moment(dateObj.date).format("DD/MM/YY HH:mm:ss")
      );
    },
    getInvoiceTitle(invoice) {
      let lastInvoiceNumber = invoice.number
        ? ` - inv${invoice.number}`
        : ` - inv${invoice.id}`;
      const cleanClientName = invoice.clientName.replace(/\//g, "__");
      return (
        (invoice.clientCustomId ? `${invoice.clientCustomId} - ` : "") +
        `${cleanClientName}${lastInvoiceNumber}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.columns {
  padding-left: 10px;
}

td.is-narrow {
  width: 120px !important;
}

th.is-narrow {
  width: 120px !important;
}

$color--dark-gray: #7c91ae;

th {
  span {
    margin-right: 5px;
    color: $color--dark-gray;
  }
}

td {
  button {
    span {
      position: relative;
      left: 15px;
    }
  }
}
</style>
