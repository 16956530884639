<template lang="pug">
form.timesheet.mt
  .lost-center
    .lost-shift
      .notification.is-danger(v-if="errors.any()")
        //- span.delete
        | Storing shift request failed.
      p.control
        multi-select#Client(
          :options="clientsAvailable",
          track-by="id",
          label="name",
          @input="onClientSelect",
          @select="initLocations",
          @remove="resetLocations",
          v-model="selectedClient",
          :loading="isClientsLoading",
          placeholder="SELECT CLIENT"
        )
      span.help.is-danger(
        v-if="errors.has('client_id')",
        v-html="errors.get('client_id')"
      )
      p.control
        multi-select#Cost(
          :options="optionsCost",
          @select="initCategories",
          @remove="resetCategories",
          :loading="isCCLoading",
          track-by="id",
          label="name",
          v-model="selectedLocation",
          placeholder="SELECT WARD"
        )
      span.help.is-danger(
        v-if="errors.has('location_id')",
        v-html="errors.get('location_id')"
      )

      label.label SUBCATEGORY
      p.control
        multi-select(
          :options="optionsCategories",
          :close-on-select="true",
          track-by="id",
          label="name",
          group-label="category",
          group-values="subs",
          v-model="selectedCategory",
          placeholder="GRADE",
          @select="errors.clear('subcategory_id')"
        )
        //- :multiple="false",
        //- :group-select="maxAllowedSubcategories === 1 ? false : true",
        //- :max="maxAllowedSubcategories"
        //- @remove="selectedCategory = { name: null, id: null }",
        span.help.is-danger(
          v-if="errors.has('subcategory_id')",
          v-html="errors.get('subcategory_id')"
        )

      p.control
        multi-select#ShiftRequestReason(
          :options="optionsShiftRequestReasons",
          track-by="id",
          label="reason",
          v-model="selectedShiftRequestReason",
          placeholder="SELECT REASON FOR REQUEST"
        )

      p.control(v-if="filteredShiftTypes.length > 1")
        label.label.is-caps-lock(style="margin: 20px 0") Select shift type
        multi-select(
          :options="filteredShiftTypes",
          v-model="selectedShiftType",
          track-by="id",
          label="name",
          deselect-label="",
          :allow-empty="false",
          placeholder="Select shift type",
          @select="resetCalendar",
          @remove="resetCalendar"
        )
      .time-interval
        .label-and-interval
          p(style="width: 324px") SHIFT START TIME
          flat-pickr.input.is-medium.column.is-5(
            v-if="isMultipleShifts && !isSleepoverShift",
            placeholder="When?",
            :config="flatpickrConfigLineOfShifts",
            v-model="fromToTime.startTime.time",
            @input="errors.clear('start_time')"
          )
        .label-and-interval
          p SHIFT END TIME
          flat-pickr.input.is-medium.column.is-5(
            v-if="isMultipleShifts && !isSleepoverShift",
            placeholder="When?",
            :config="flatpickrConfigLineOfShifts",
            v-model="fromToTime.endTime.time",
            @input="clearTimeErrors"
          )
      .label-and-interval.explanation
        p.lanel-message(v-if="isMultipleShifts && !isSleepoverShift") * Please enter the shift time before selecting the date.
      template(v-if="!isSleepoverShift")
        p.control
          custom-flatpickr(
            :no-disable-date="true",
            :selected-dates="selectedDays",
            :start-time="fromToTime.startTime",
            :end-time="fromToTime.endTime",
            :hc-start-time="hcTime.startTime",
            :hc-end-time="hcTime.endTime",
            @days-selected="onSelectedDays",
            @day-for-change="onDayForChangeSelection",
            :class="{ disabled: !isEnabledCalendar }"
          )
            //- Calendar Timesheet entry
      template(v-if="selectedDays.length")
        .content
          .h4.card-header-icon CREATED SHIFTS
          .shifts-table
            .shifts-table--header
              .shift-row
                .shift-cell.id ID
                .shift-cell.date Date
                .shift-cell.time Time
                .shift-cell.total-hours Total hours
                .shift-cell.on-call(v-if="isOnCallShift") Standby / On call
                .shift-cell.on-call(v-if="isSleepoverShift") Sleepover
                .shift-cell.req-shift(colspan="2", width="15%") Number of required shifts
                .shift-cell.button-cell
            .shifts-table--body
              .shift-row(v-for="(shift, index) in shiftsList")
                .shift-cell.id(v-text="shift.number")
                .shift-cell.date(v-text="shift.date")
                .shift-cell.time(v-text="shift.time")
                .shift-cell.total-hours(v-text="getShiftTotalHours(shift)")
                .shift-cell.on-call(
                  v-if="isOnCallShift",
                  v-text="shift.shift_parts"
                )
                .shift-cell.on-call(
                  v-if="isSleepoverShift",
                  v-text="shift.shift_parts"
                )
                .shift-cell.req-shift
                  input.input(
                    type="number",
                    :value="shift.numberOfShifts",
                    min="1",
                    max="50",
                    @change="onNumberOfShiftsChange(shift.id, $event)"
                  )
                .shift-cell.button-cell
                  img.remove-shift-btn(
                    src="./../../assets/images/comps/delete-larger.svg",
                    @click="removeShift(shift.id)"
                  )
        span.total-hours(v-if="getTotalMultipleHours")
          span Total hours:
          span {{ getTotalMultipleHours }}
        hr
        //- span.help.is-danger.has-text-centered.time-errors(v-if="errorMessage.exists" v-html="errorMessage.text")

      p.control
        .pickers
          //- Sleepover
          //- p SHIFT START TIME
          flat-pickr.input.is-medium.column.is-5(
            placeholder="SHIFT START TIME",
            :config="flatPickerConfigSleepover",
            v-model="fromToTime.startTime.time",
            v-if="isSleepoverShift",
            key="sleep1",
            @on-change="onChangeSleepoverShiftStart"
          )
          flat-pickr.input.is-medium.column.is-5(
            placeholder="SHIFT END TIME",
            :config="flatPickerConfigSleepover",
            v-model="fromToTime.endTime.time",
            v-if="isSleepoverShift",
            key="sleep2",
            @on-change="onChangeSleepoverShiftEnd"
          )
        //- from-to-datepicker(
        //-   v-model="fromToTime",
        //-   :format="'DD/MM/YYYY HH:mm'",
        //-   :mode="'day'",
        //-   start-label="SHIFT START TIME",
        //-   end-label="SHIFT END TIME",
        //-   @change-start-time="onChangeSleepoverShiftStart",
        //-   @change-end-time="onChangeSleepoverShiftEnd",
        //-   v-if="isSleepoverShift",
        //-   key="sleep"
        //- )
        //- from-to-datepicker(
        //-   v-model="fromToTime",
        //-   format="HH:mm",
        //-   mode="hour",
        //-   start-label="SHIFT START TIME",
        //-   end-label="SHIFT END TIME",
        //-   @change-start-time="errors.clear('start_time')",
        //-   @change-end-time="errors.clear('end_time')",
        //-   v-else-if="!isSleepoverShift",
        //-   key="not-sleep"
        //- )

        //- Time sheet entry Time

        //- format="DD/MM/YYYY HH:mm"
        //- :is-end-date-time-visible="!escort",
        span.help.is-danger(
          slot="start-time-errors",
          v-if="errors.has('start_time')",
          v-html="errors.get('start_time')"
        )
        span.help.is-danger(
          slot="end-time-errors",
          v-if="errors.has('end_time')",
          v-html="errors.get('end_time')"
        )
        span.help.is-danger(
          v-else-if="errors.has('shifts.0.end_time')",
          v-html="errors.get('shifts.0.end_time')"
        )
        span.help.is-danger(
          v-if="errors.has('shifts')",
          v-html="errors.get('shifts')"
        )
      .control.oncall(v-if="isSleepoverShift")
        label.label.is-caps-lock(style="margin: 10px 0") Sleepover?
        .pickers
          flat-pickr.input.is-medium.column.is-5(
            placeholder="START TIME",
            @on-change="onChangeSleepoverStart",
            :config="flatPickerConfigSleepover",
            v-model="sleepoverTime.startTime.time",
            key="sleepover-date1"
          )
          flat-pickr.input.is-medium.column.is-5(
            placeholder="END TIME",
            @on-change="onChangeSleepoverEnd",
            :config="flatPickerConfigSleepover",
            v-model="sleepoverTime.endTime.time",
            key="sleepover-date2"
          )
        //- from-to-datepicker(
        //-   v-model="sleepoverTime",
        //-   :format="'DD/MM/YYYY HH:mm'",
        //-   :mode="'day'",
        //-   @change-start-time="onChangeSleepoverStart",
        //-   @change-end-time="onChangeSleepoverEnd",
        //-   key="sleepover-date"
        //- )
        span.help.is-danger(
          slot="start-time-errors",
          v-if="errors.has('shifts.0.shift_parts.0.from')",
          v-html="errors.get('shifts.0.shift_parts.0.from')"
        )
        span.help.is-danger(
          slot="end-time-errors",
          v-if="errors.has('shifts.0.shift_parts.0.to')",
          v-html="errors.get('shifts.0.shift_parts.0.to')"
        )
      p.control.has-addons.mb.shift-times(v-if="!isSleepoverShift")
        a.button.is-medium.is-outlined(
          :class="[{ 'is-active': index === defaultShiftIdx }, { custom: !isDefaultShift(index) }]",
          v-for="(shift, index) in allShifts",
          :id="'shiftTime' + index",
          @click.prevent="toggleShift(shift, index)"
        )
          span {{ shift.name }}

      p.control.is-horizontal.layout_a
        label.label.c1
          label.ctrl.ctrl--checkbox
            input.ctrl--checkbox(
              type="checkbox",
              name="specialShift",
              v-model="cbxSpecialShift"
            )
            .ctrl__status
            span.is-caps-lock Special shift?
        input.input(
          type="text",
          :disabled="cbxSpecialShift == false",
          v-model.trim="specialShiftName",
          placeholder="PATIENT INITIALS"
        )
      .control.oncall(v-if="isOnCallShift")
        label.label.is-caps-lock(style="margin: 10px 0") Were you on call during this period?
        //- TODO [1] Remove from-to-datepicker in future
        //- from-to-datepicker(
        //-   v-model="hcTime",
        //-   format="HH:mm",
        //-   mode="hour",
        //-   key="on-call"
        //- )
        .time-interval
          .label-and-interval
            p(style="width: 324px") SHIFT START TIME
            flat-pickr.input.is-medium.column.is-5(
              placeholder="When?",
              :config="flatpickrConfigSharedLineOfShifts",
              v-model="hcTime.startTime.time",
              key="on-call1"
            )
          .label-and-interval
            p SHIFT END TIME
            flat-pickr.input.is-medium.column.is-5(
              placeholder="When?",
              v-model="hcTime.endTime.time",
              :config="flatpickrConfigSharedLineOfShifts",
              key="on-call2"
            )
        span.help.is-danger(
          slot="start-time-errors",
          v-if="errors.has('shifts.0.shift_parts.0.from')",
          v-html="errors.get('shifts.0.shift_parts.0.from')"
        )
        span.help.is-danger(
          slot="end-time-errors",
          v-if="errors.has('shifts.0.shift_parts.0.to')",
          v-html="errors.get('shifts.0.shift_parts.0.to')"
        )
      p.control
        input.input.is-medium(
          v-model="requestId",
          type="text",
          placeholder="ENTER PO IF APPLICABLE"
        )
      p.control(v-if="$can('create-gender-specific-shifts')")
        label.label Gender
        multi-select(
          :options="genderOptionsFiltered",
          :close-on-select="true",
          track-by="key",
          label="label",
          select-label="",
          selected-label="",
          deselect-label="",
          :value="getSelectedGender",
          placeholder="Select gender",
          @select="onSelectGender"
        )
        span.help.is-danger(
          v-if="errors.has('gender')",
          v-html="errors.get('gender')"
        )
      p.control(v-if="canViewTags && hasClientTags")
        label.label Tags
        multi-select(
          :options="activityTagsList",
          :value="tagSelected",
          select-label="",
          selected-label="",
          deselect-label="",
          track-by="id",
          label="name",
          placeholder="Select Tag",
          @select="updateTags"
        )
        span.help.is-danger(
          v-if="errors.has('gender')",
          v-html="errors.get('gender')"
        )
      p.control(v-if="!isRound3Tag")
        multi-select(
          :options="agencyWorkers",
          :close-on-select="true",
          :searchable="true",
          :loading="isLoadingAgencyWorker",
          track-by="id",
          label="name",
          v-model="selectedAgencyWorker",
          placeholder="TYPE TO FIND AGENCY WORKER",
          @search-change="asyncFindWorker",
          :internal-search="false",
          @select="errors.clearLike('temp_id')"
        )
      span.help.is-danger(v-if="errors.hasLike('temp_id')") Temp field is invalid
      //- v-html="errors.get('temp_id')
      p.control
        comment-box(
          v-model="notes",
          max-chars="550",
          :progress-count="true",
          :progress-bar="true"
        )
      p.control
        button.button.is-generic-app-teal.is-fullwidth.is-caps-lock(
          @click.prevent="shiftCheck",
          :disabled="isSubmitted"
        )
          i.fa.fa-check(v-show="!isSubmitted")
          img.btn-loader(
            src="../../assets/images/comps/loader.svg",
            v-if="isSubmitted"
          )
          | Submit
</template>
<script>
import SubmitShiftForm from "./SubmitShiftForm.vue";
import SubmitMultipleShiftsForm from "./SubmitMultipleShiftsForm.vue";
import { mapGetters, mapActions } from "vuex";
// import { Errors } from '../../lib/helpers/Errors.js'
import MultiSelect from "vue-multiselect";
import CommentBox from "../CommentBox.vue";
import { pickNonFalsyKeepZero, findPos } from "../../lib/helpers/function.js";
import _ from "underscore";
import moment from "moment";
import { Evt } from "../../lib/helpers/Evt.js";
import Vue from "vue";
import { GENDER_MAP } from "../../shared/mapData";
import { ON_CALL_SHIFT_ID, SLEEPOVER_SHIFT_ID } from "./shiftTypes";
import apiTs from "@/api/apiTs";

export default {
  name: "TimesheetEntry",
  mixins: [SubmitShiftForm, SubmitMultipleShiftsForm],
  components: {
    MultiSelect,
    CommentBox,
  },
  data() {
    return {
      isLoadingAgencyWorker: false,
      isTimesheetEntry: true,
      selectedAgencyWorker: null,
      shiftSleepover: {
        start: "",
        end: "",
      },
      sleepoverDateTime: {
        start: "",
        end: "",
      },
      // fromToTime: {
      //   startTime: { time: '' },
      //   endTime: { time: '' }
      // },
      // startDate: { time: '' },
      // endDate: { time: '' },

      // errors: new Errors(),
      hcTime: {
        startTime: { time: "" },
        endTime: { time: "" },
      },
      sleepoverTime: {
        startTime: { time: "" },
        endTime: { time: "" },
      },

      selectedShiftRequestReason: null,

      // selectedClient: null,
      selectedLocation: null,
      selectedCategory: null,
      canUseShiftActivityTags: false,
      //
      // cbxSpecialShift: false,
      // specialShiftName: '',

      // notes: '',
      //
      // buttons: {
      //   Morning: false,
      //   Afternoon: false,
      //   Day: false,
      //   Night: false
      // },
      //
      // timePresetStart: {Morning: '07:00', Afternoon: '12:00', Day: '14:00', Night: '19:45'},
      // timePresetEnd: {Morning: '12:00', Afternoon: '17:00', Day: '19:00', Night: '07:00'},

      selectedDays: [],
      requestId: null,
      isSubmitted: false,
      selecetedGender: null,
      genderOptions: GENDER_MAP,
      activityTagsList: [],
      tagSelected: null,
    };
  },

  /**
   * When switching between tabs, data should be reinitialised
   */
  beforeDestroy() {
    this.resetFields();
  },

  mounted() {
    this.isTimesheetEntry = true;
    this.fetchShiftRequestReasons();

    /**
     * if any of toggle/period buttons clicked, do a table reset!
     */
    this.$watch(
      "buttons",
      function () {
        // this.resetTempsTable() // TODO no reset temps table here...
      },
      { deep: true }
    );

    if (this.canViewTags) {
      // This has to be loaded if has agency tags, no client checks here
      this.fetchShiftsActivityTags();
    }
  },
  created() {
    Evt.listen("confirmShift", () => {
      if (this.isTimesheetEntry) {
        this.submitForm();
        this.$emit("cancel-modal");
        Evt.fire("disposeModal");
      }
    });
  },
  computed: {
    ...mapGetters({
      optionsShiftRequestReasons: "getShiftRequestReasons",
      // clientsAvailable: 'getAllClients',
      // fillRelatedFields: 'fillRelatedFields',
      // fillCategories: 'fillCategories',
      // optionsCategories: 'getCategories',
      // optionsCost: 'getCosts',
      agencyWorkers: "getAgencyWorkers",
    }),
    // canManageTags() {
    //   return (
    //     this.initialStatus.can_use_shift_activity_tags &&
    //     this.$can("edit-shift-activity-tag")
    //   );
    // },
    canViewTags() {
      return (
        this.initialStatus.can_use_shift_activity_tags &&
        (this.$can("view-shift-activity-tag") ||
          this.$can("edit-shift-activity-tag"))
      );
    },
    hasClientTags() {
      // return this.selectedClient?.canUseShiftActivityTags;
      return this.canUseShiftActivityTags;
    },
    isRound3Tag() {
      const selectedTag = this.tagSelected;
      if (selectedTag) {
        return (
          selectedTag.id ===
          this.activityTagsList.find((obj) => obj.name === "Round 3")?.id
        );
      }
      return false;
    },
    flatPickerConfigSleepover() {
      return {
        altInput: true,
        altFormat: "d/m/Y H:i",
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        time_24hr: true,
      };
    },
    shiftsList() {
      return _.map(this.selectedDays, (day, index) => {
        return {
          id: day.id,
          number: index + 1,
          date: moment(day.date).format("DD/MM/YYYY"),
          costCenterName: this.selectedLocation?.name || "",
          shift_parts_time: day.shift_parts_time,
          shift_parts: day.shift_parts,
          numberOfShifts: day.numberOfShifts,
          time: this.getTimeIntervalString(day.startTime, day.endTime),
          dayObj: day,
        };
      });
    },
    isEnabledCalendar() {
      return this.fromToTime?.startTime.time && this.fromToTime?.endTime.time;
    },
    shiftSleepoverList() {
      const formatDate = (str) => {
        const formatStr = "DD/MM/YYYY HH:mm";
        const outputFormat = "YYYY-MM-DD HH:mm:ss";
        return str && moment(str, formatStr).format(outputFormat);
      };

      const shiftParts = [
        {
          from: formatDate(this.sleepoverDateTime.start),
          to: formatDate(this.sleepoverDateTime.end),
          type: "SLPO",
        },
      ];

      const payload = {
        start_time: formatDate(this.shiftSleepover.start),
        end_time: formatDate(this.shiftSleepover.end),
        location_id: this.selectedLocation?.id,
        number_of_shifts: 1,
        temp_id: this.selectedAgencyWorker?.id,
      };
      const params = this.generateShiftsArrayPayload(payload);

      if (this.sleepoverDateTime.start && this.sleepoverDateTime.end) {
        params[0].shift_parts = shiftParts;
      }
      return params;
    },
    genderOptionsFiltered() {
      return [{ key: null, label: "All" }, ...this.genderOptions];
    },
    getSelectedGender() {
      const genderKey =
        (this.selectedGender && this.selectedGender.key) || null;
      // if (!this.selectedGender) {
      //   return null
      // }
      return this.genderOptionsFiltered.find((go) => go.key === genderKey);
    },
  },
  watch: {
    /**
     * Special shift name
     */
    cbxSpecialShift() {
      if (!this.cbxSpecialShift) {
        this.specialShiftName = "";
      }
    },
    hcTime: {
      deep: true,
      handler() {
        if (
          this.isOnCallShift &&
          this.hcTime.startTime.time &&
          this.hcTime.endTime.time
        ) {
          this.setOnCallToDays();
        }
      },
    },
    sleepoverTime: {
      deep: true,
      handler() {
        if (
          this.isSleepoverShift &&
          this.sleepoverTime.startTime.time &&
          this.sleepoverTime.endTime.time
        ) {
          this.setSleepoverToDays();
        }
      },
    },
    // handles changing shift type
    // ONCL and SLEEPOVER are handled only
    // if STANDARD shift is selected, default block will run
    selectedShiftType: {
      deep: true,
      handler() {
        switch (this.selectedShiftType.id) {
          case ON_CALL_SHIFT_ID: {
            this.setOnCallToDays();
            break;
          }
          case SLEEPOVER_SHIFT_ID: {
            this.setSleepoverToDays();
            break;
          }
          default: {
            this.removeShiftPartsFromAllDays();
            break;
          }
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "findAgencyWorker",
      "createTimesheetEntry",
      "fetchShiftRequestReasons",
      "getShiftsActivityTags",
    ]),

    async fetchShiftsActivityTags() {
      try {
        const res = await this.getShiftsActivityTags();
        this.activityTagsList = res.data.data;
        this.tagSelected = this.activityTagsList[0];
      } catch (e) {
        console.warn("error", e);
      }
    },
    async fetchActivityTagsForClient() {
      if (!this.canViewTags) {
        return false;
      }

      if (!this.selectedClient) {
        console.warn("ERROR CLIENT");
        return false;
      }

      const params = {
        client_id: this.selectedClient.id,
      };
      try {
        const res = await apiTs.getShiftActivityTagsForClient(params);
        this.canUseShiftActivityTags = res.data.canUseShiftActivityTags;
      } catch (err) {
        console.warn(err.message);
      }
      return true;
    },
    updateTags(e, itemId) {
      this.tagSelected = e;
    },
    generateShiftsArrayPayload(params) {
      const payload = {
        start_time: params.start_time,
        end_time: params.end_time,
        location_id: params.location_id,
        number_of_shifts: params.number_of_shifts,
      };

      if (!this.isRound3Tag) {
        payload.temp_id = params.temp_id;
      }

      return [payload];
    },
    asyncFindWorker: _.debounce(async function (query) {
      if (query === "") return;
      this.isLoadingAgencyWorker = true;
      const params = {
        ...{
          query,
          gender: this.selectedGender && this.selectedGender.key,
          has_on_call: this.isOnCallShift ? 1 : 0,
          has_sleepover: this.isSleepoverShift ? 1 : 0,
        },
        originallySelectedWorker: "",
      };
      await this.findAgencyWorker(params);
      this.isLoadingAgencyWorker = false;
    }, 400),

    shiftCheck() {
      const shiftStart = this.fromToTime.startTime.time;
      const shiftEnd = this.fromToTime.endTime.time;

      const isDateEmpty = Boolean(shiftStart === "" || shiftEnd === "");

      const diffMS = moment(shiftEnd, "YYYY-MM-DD HH:mm:ss").diff(
        moment(shiftStart, "YYYY-MM-DD HH:mm:ss")
      );
      const diffHrs = moment.duration(diffMS).asHours();

      if (diffHrs <= 24 || this.isMultipleShifts || isDateEmpty) {
        this.submitForm();
      } else {
        this.openModal();
      }
    },
    getShifts() {
      if (this.isSleepoverShift) {
        if (this.shiftSleepover.start && this.shiftSleepover.end) {
          return this.shiftSleepoverList;
        } else {
          this.$toasted.error("You must select some days first!").goAway(1500);
          return [];
        }
      }

      const shifts = [];
      _.each(this.selectedDays, (day) => {
        if (!_.isDate(day.startTime) || !_.isDate(day.endTime)) {
          return;
        }
        const startDateTime = moment(day.startTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const endDateTime = moment(day.endTime).format("YYYY-MM-DD HH:mm:ss");
        const payload = {
          start_time: startDateTime,
          end_time: endDateTime,
          temp_id: this.selectedAgencyWorker?.id || null,
          location_id: this.selectedLocation?.id,
          number_of_shifts: day.numberOfShifts,
          // start_time: this.serverStartTime,
          // end_time: this.serverEndTime,
          // number_of_shifts: this.selectedReqShifts
        };
        const shiftObj = this.generateShiftsArrayPayload(payload)[0];

        // .shift_parts include times for both "ONCL" AND "SLEEPOVER" times
        if (
          day.shift_parts &&
          day.shift_parts_time?.from &&
          day.shift_parts_time?.to &&
          startDateTime &&
          endDateTime
        ) {
          const startDay = moment(day.startTime);
          const endDay = moment(day.endTime);

          shiftObj.shift_parts = [
            // function formatDateForShiftPart throws an error is params are invalid
            {
              from: this.formatDateForShiftPart(
                startDay,
                endDay,
                day.shift_parts_time.from,
                "shifts.0.shift_parts.0.from"
              ),
              to: this.formatDateForShiftPart(
                startDay,
                endDay,
                day.shift_parts_time.to,
                "shifts.0.shift_parts.0.to"
              ),
              type: this.selectedShiftType.code,
            },
          ];
        }

        shifts.push(shiftObj);
      });
      return shifts;
    },
    // This method throws and error on invalid params and breaks the program
    formatDateForShiftPart(shiftStart, shiftEnd, partTime, errorKey) {
      const shiftStartMoment = moment(shiftStart);
      const shiftEndMoment = moment(shiftEnd);
      let partTimeMoment = moment(
        `${shiftStartMoment.format("YYYY-MM-DD")} ${partTime}:00`
      );

      const isSameDay = shiftStartMoment.day() === shiftEndMoment.day();
      if (!isSameDay && shiftStartMoment.hour() > 24) {
        const errorMsg = "Shift can not last longer than 24 hours.";
        const errorObj = {};
        errorObj[errorKey] = [errorMsg];
        this.errors.record(errorObj);
        throw errorMsg;
      }

      if (isSameDay) {
        return `${shiftStartMoment.format("YYYY-MM-DD")} ${partTime}:00`;
      }

      // if its not the same day
      if (partTimeMoment < shiftStartMoment) {
        partTimeMoment = moment(
          `${shiftEnd.format("YYYY-MM-DD")} ${partTime}:00`
        );
      }

      if (partTimeMoment > shiftEndMoment) {
        const timeFormatted = partTimeMoment.format("HH:mm");
        const errorMsg = `Shift part (${timeFormatted}) must be inside shift interval.`;
        const errorObj = {};
        errorObj[errorKey] = [errorMsg];
        this.errors.record(errorObj);
        throw errorMsg;
      }

      return partTimeMoment.format("YYYY-MM-DD HH:mm:ss");
    },
    async submitForm() {
      if (!this.selectedDays.length && !this.isSleepoverShift) {
        this.$toasted.error("You must select some days first!").goAway(1500);
        return;
      }

      const postParams = {
        client_id: this.selectedClient && this.selectedClient.id,
        subcategory_id: this.selectedCategory && this.selectedCategory.id,
        notes: this.notes,
        shift_request_reason_id: this.selectedShiftRequestReason
          ? this.selectedShiftRequestReason.id
          : null,
        request_id: this.requestId,
        special_shift_name: this.specialShiftName,
        shifts: this.getShifts(),
        gender: this.selectedGender && this.selectedGender.key,
      };

      postParams.shift_type = this.selectedShiftType.code;

      if (this.tagSelected && this.canViewTags && this.hasClientTags) {
        postParams.shift_activity_tag_id = this.tagSelected.id;
      }

      console.log(postParams);

      this.isSubmitted = true;

      try {
        const cleanParams = pickNonFalsyKeepZero(postParams);
        const response = await this.createTimesheetEntry(cleanParams);
        const data = response.data;
        console.log("treci", data);
        this.$toasted.info("Shift created successfully!").goAway(1500);
        this.resetFields();
        this.isSubmitted = false;
      } catch (error) {
        this.isSubmitted = false;
        let err = "";
        if (error?.response?.data?.errors) {
          err = error.response.data.errors;
          // custom error message
          if (err?.shifts) {
            err.shifts = ["Shift time is required"];
            this.$toasted.error("Shift time is required").goAway(3000);
          }
          this.errors.record(err);
          Vue.nextTick(function () {
            const notification = document.querySelector(".notification");
            window.scroll(0, findPos(notification));
          });
        } else if (error?.response?.data?.message) {
          err = error.response.data.message;
          this.$toasted.error(err).goAway(3000);
        } else {
          console.warn(error, error.response);
          this.$toasted.error("An error occured").goAway(3000);
        }
      }
    },
    resetFields() {
      this.selectedShiftRequestReason = null;
      this.selectedClient = null;
      this.selectedCategory = null;

      this.resetLocations();
      this.fromToTime.startTime = { time: "" };
      this.fromToTime.endTime = { time: "" };

      this.hcTime.startTime = { time: "" };
      this.hcTime.endTime = { time: "" };
      this.sleepoverTime.startTime = { time: "" };
      this.sleepoverTime.endTime = { time: "" };

      this.selectedAgencyWorker = null;

      this.cbxSpecialShift = false;
      this.specialShiftName = "";

      this.notes = "";

      this.errors.clear(); // empty out errors
      this.populateShiftTimes();
      this.defaultShiftIdx = -1;

      this.selectedDays = [];
      this.sleepoverDateTime = { start: "", end: "" };
      this.shiftSleepover = { start: "", end: "" };
    },
    onSelectGender(evt) {
      this.selectedGender = evt;
      this.errors.clear("gender");
      this.selectedAgencyWorker = null;
    },

    setOnCallToDays() {
      let dayToChange;
      let dayToChangeIndex;
      for (const [index, day] of this.selectedDays.entries()) {
        if (day.isForChange) {
          dayToChange = { ...day };
          dayToChangeIndex = index;
          break;
        }
      }
      if (this.hcTime.startTime.time && this.hcTime.endTime.time) {
        if (dayToChange) {
          dayToChange.shift_parts_time = {
            from: this.hcTime.startTime.time,
            to: this.hcTime.endTime.time,
          };

          dayToChange.shift_parts = `${this.hcTime.startTime.time} - ${this.hcTime.endTime.time}`;
          this.selectedDays.splice(dayToChangeIndex, 1, dayToChange);
        } else {
          this.selectedDays = this.selectedDays.map((day) => {
            day.shift_parts_time = {
              from: this.hcTime.startTime.time,
              to: this.hcTime.endTime.time,
            };

            day.shift_parts = `${this.hcTime.startTime.time} - ${this.hcTime.endTime.time}`;

            return day;
          });
        }
      } else {
        this.removeShiftPartsFromAllDays();
      }
    },
    setSleepoverToDays() {
      let dayToChange;
      let dayToChangeIndex;
      for (const [index, day] of this.selectedDays.entries()) {
        if (day.isForChange) {
          dayToChange = { ...day };
          dayToChangeIndex = index;
          break;
        }
      }

      if (
        this.sleepoverTime.startTime.time &&
        this.sleepoverTime.endTime.time
      ) {
        if (dayToChange) {
          dayToChange.shift_parts_time = {
            from: this.sleepoverTime.startTime.time,
            to: this.sleepoverTime.endTime.time,
          };

          dayToChange.shift_parts = `${this.sleepoverTime.startTime.time} - ${this.sleepoverTime.endTime.time}`;
          this.selectedDays.splice(dayToChangeIndex, 1, dayToChange);
        } else {
          this.selectedDays = this.selectedDays.map((day) => {
            day.shift_parts_time = {
              from: this.sleepoverTime.startTime.time,
              to: this.sleepoverTime.endTime.time,
            };

            day.shift_parts = `${this.sleepoverTime.startTime.time} - ${this.sleepoverTime.endTime.time}`;

            return day;
          });
        }
      } else {
        this.removeShiftPartsFromAllDays();
      }
    },
    removeShiftPartsFromAllDays() {
      this.selectedDays = this.selectedDays.map((day) => {
        delete day.shift_parts;
        delete day.shift_parts_time;
        return day;
      });
    },
    onChangeSleepoverStart(evt) {
      const date = evt[0];
      if (date) {
        const formatedEvt = moment(date).format("DD/MM/YYYY HH:mm");
        this.sleepoverDateTime.start = formatedEvt;
      }
    },
    onChangeSleepoverEnd(evt) {
      const date = evt[0];
      if (date) {
        const formatedEvt = moment(date).format("DD/MM/YYYY HH:mm");
        this.sleepoverDateTime.end = formatedEvt;
      }
    },
    onChangeSleepoverShiftStart(evt) {
      const date = evt[0];
      if (date) {
        const formatedEvt = moment(date).format("DD/MM/YYYY HH:mm");
        this.shiftSleepover.start = formatedEvt;
      }
    },
    onChangeSleepoverShiftEnd(evt) {
      const date = evt[0];
      if (date) {
        const formatedEvt = moment(date).format("DD/MM/YYYY HH:mm");
        this.shiftSleepover.end = formatedEvt;
      }
    },
    resetCalendar() {
      this.selectedDays = [];
      this.fromToTime.startTime.time = "";
      this.fromToTime.endTime.time = "";
      this.shiftSleepover.start = "";
      this.shiftSleepover.end = "";
    },
  },
};
</script>
<style lang="scss">
.total-hours {
  display: flex;
  gap: 5px;
}

.timesheet {
  float: none;
  .label-and-interval.explanation {
    margin: 0px 0 5px 0;
    .lanel-message {
      background-color: #ffffff;
      font-size: 1.1rem;
      font-weight: 700;
      line-height: 1.5;
    }
  }
}
.cov-datepicker {
  font-size: 18px;
  padding: 10px;
  border: 1px solid #e2e7ea !important;
  border-radius: 5px !important;
  box-shadow: unset !important;
}
.errorDate .cov-datepicker {
  border: 1px solid #e41616 !important;
}
</style>
<style lang="scss" scoped>
$border-color: #9bb4c1;
$primary-color: #2669b0;

.p1 {
  margin-left: auto;
  align-self: flex-end;
}
.b1 {
  color: white;
  font-weight: bold;
  height: 46px;
}

.oncall {
  padding: 10px;
  border: 1px solid $border-color;
  border-radius: 4px;
}

svg {
  margin-right: 0.5em;
}
.b1:after {
  content: "";
  position: absolute;
  left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 15.6px 9px 0;
  border-color: transparent #ebbc12 transparent transparent;
}
.c1 span {
  margin-left: 0.5em;
}
.layout_a {
  align-items: center;
}
.layout_a label {
  margin-bottom: 0;
}
.layout_a > input {
  margin: 0 auto 0 1em;
  max-width: 50%;
}
.layout_a.mod > input {
  margin: 0 0 0 auto;
}

.mt {
  margin-top: 40px;
}
.mb {
  margin-bottom: 40px;
}

.control {
  &.mb {
    margin-bottom: 30px;
  }
}
.wraps {
  flex-wrap: wrap;
}
.input {
  border-color: $border-color !important;
}
.is-issue {
  align-self: flex-end;
  margin-top: 1em;
}

.fa.fa-search {
  margin-right: 0.5em;
}
.select,
.multiselect {
  width: 100%;
  select,
  input {
    border: 1px solid $border-color;
    height: 3em;
    width: 100%;
    opacity: 0.6;
    font-size: 15px;
    font-weight: 500;
    color: #405168;
  }
  &:after {
    width: 0;
    height: 0;

    margin-top: -0.075em;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #559eba;
    border-bottom: none;
    transform: rotate(0);
  }
}
.control.has-addons .button {
  flex: 1;
  color: $primary-color;
  font-size: 15px;
  border: 1px solid $border-color;
  height: 42px;
  margin-bottom: 10px;

  &.is-active {
    background-color: #edf5f5;
  }
}

.shift-period span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shift-period.is-generic-app-orange,
.shift-period.is-generic-app-blue {
  pointer-events: none;
}

.shift-period.is-generic-app-blue.b1:after {
  border-color: transparent $primary-color transparent transparent;
}
.shift-times {
  display: block;
}

.shift-times:after {
  content: "";
  display: block;
  clear: both;
}

.shift-times .button {
  float: left;
  min-width: 25%;
}

.shift-times .custom {
  min-width: 1;
  margin-top: 10px;
}
.time-interval {
  display: flex;
  .explanation {
    font-size: 28px;
    margin: 20px 0 0 0;
    cursor: pointer;
  }
  .label-and-interval {
    p {
      font-weight: 700;
      line-height: 1.5;
      color: hsl(0deg, 0%, 21%);
    }
    .input {
      width: 96%;
      margin: 0 10px 10px 0;
    }
  }
}

.pickers {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}
</style>
