<template lang="pug">
.control
  template(v-if="gnibDocs && gnibDocs.length")
    label.label GNIB documents (max week hours)
    //- .gnib-doc(v-for='doc in gnibDocs')
    .gnib-doc
      multi-select(
        :options="gnibDocs",
        :close-on-select="true",
        :disabled="!gPermissions.canEditTemp",
        track-by="id",
        label="name",
        select-label="",
        selected-label="",
        deselect-label="",
        :value="selectedGnibDocument",
        placeholder="No GNIB document selected",
        @select="onSelectGnibDoc",
        @remove="onRemoveGnibDoc()"
      )
      //- .label
      //-   span {{doc.name}}
      multi-select(
        :options="getGnibMaxWeek(selectedGnibDocument)",
        :close-on-select="true",
        :allow-empty="false",
        :disabled="!gPermissions.canEditTemp",
        track-by="hours",
        label="label",
        select-label="",
        selected-label="",
        deselect-label="",
        :value="selectedGnibMaxWeek",
        placeholder="Select GNIB max week hours",
        v-if="selectedGnibDocument",
        @input="onInputGnib"
      )
  span.help.is-danger(
    v-if="errors.has('gnib_max_week_hours')",
    v-html="errors.get('gnib_max_week_hours')"
  )
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions } from "vuex";
export default {
  components: {
    MultiSelect,
  },
  props: {
    errors: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    isModifiedGnibDocs: {
      type: Boolean,
      required: true,
    },
    selectedGnibDocId: {
      required: true,
      validator: (prop) => prop === null || typeof prop === "number",
    },
    selectedGnibMaxWeekValue: {
      required: true,
      validator: (prop) => prop === null || typeof prop === "number",
    },
  },
  data() {
    return {
      gnibDocs: [],
    };
  },
  computed: {
    selectedGnibDocument() {
      if (this.selectedGnibDocId) {
        return this.gnibDocs.find((doc) => doc.id === this.selectedGnibDocId);
      } else if (this.form?.gnib_dtp_id && !this.isModifiedGnibDocs) {
        return this.gnibDocs.find((doc) => doc.id === this.form.gnib_dtp_id);
      }
      return null;
    },
    selectedGnibMaxWeek() {
      if (this.selectedGnibDocument) {
        const maxWeekOptions = this.getGnibMaxWeek(this.selectedGnibDocument);
        if (this.selectedGnibMaxWeekValue) {
          return maxWeekOptions.find(
            (mw) => mw.hours === this.selectedGnibMaxWeekValue
          );
        } else if (this.form?.gnib_max_week_hours && !this.isModifiedGnibDocs) {
          return maxWeekOptions.find(
            (mw) => mw.hours === this.form.gnib_max_week_hours
          );
        }
      }
      return null;
    },
  },
  mounted() {
    this.fetchGnibDocs();
  },
  methods: {
    ...mapActions({ fetchCompliances: "fetchCompliances" }),
    async fetchGnibDocs() {
      try {
        const params = {
          includes: ["locations", "categories", "roles"].join(","),
          page: 1,
          optimized: true,
          compliance_type: "GNIB",
        };
        const res = await this.fetchCompliances(params);
        this.gnibDocs = res.data.data || [];
      } catch (err) {
        console.log("ERR", err.message);
      }
    },
    getGnibMaxWeek(doc) {
      return (
        doc?.GNIB_config?.max_week_hours?.map((mw) => {
          return {
            ...mw,
          };
        }) || []
      );
    },
    onSelectGnibDoc(evt) {
      const payload = {
        doc: evt.id,
      };

      const maxWeeksGnibArr = this.getGnibMaxWeek(evt);
      payload.hours =
        maxWeeksGnibArr.length === 1 ? maxWeeksGnibArr[0].hours : null;

      this.$emit("select-gnib-doc", payload);
    },
    onRemoveGnibDoc(isModified = true) {
      this.$emit("remove-gnib-doc", isModified);
    },
    onInputGnib(evt) {
      const payload = {
        doc: this.selectedGnibDocument.id,
        hours: evt?.hours,
      };
      this.$emit("input-gnib-hours", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.gnib-doc {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;

  .label {
    width: 100%;
  }
}
</style>
