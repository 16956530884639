<template>
  <div>
    <div
      class="dragndrop__status"
      v-if="files.length"
    >
      <!--
			 <ul class="list-inline">
			 	<li class="list-inline__item">Files: {{ files.length }}</li>
			 	<li class="list-inline__item">Percentage: {{ overallProgress }}%</li>
			 	<li class="list-inline__item list-inline__item--last">Time remaining: 00:00</li>
			 </ul>
             -->
    </div>
    <file
      v-for="file in files"
      :file="file"
      :key="file.id"
    ></file>
  </div>
</template>

<script>

import File from './File.vue';
import { Evt } from '../../lib/helpers/Evt.js';
import timeremaining from './timeremaining.js';

export default {
  props: [
    'files'
  ],
  data () {
    return {
      overallProgress: 0,
      interval: null,
    };
  },

  methods: {
    unfinishedFiles () {
      let files = [];

      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].finished || this.files[i].cancelled || this.files[i].failed) {
          continue;
        }

        files.push(this.files[i]);
      }
      return files;
    },

    updateOverallProgress () {
      let unfinishedFiles = this.unfinishedFiles();
      let totalProgress = 0;

      unfinishedFiles.forEach(file => {
        totalProgress += file.progress;
      });

      this.overallProgress = parseInt(totalProgress / unfinishedFiles.length || 0);
    },

    updateTimeRemaining () {
      this.unfinishedFiles().forEach(file => {
        file.secondsRemaining = timeremaining.calculate(
          file.totalBytes,
          file.loadedBytes,
          file.timeStarted
        );
      });
    },
  },

  mounted () {
    Evt.listen('fileUploadProgress', (fileObject, e) => {
      this.updateOverallProgress();
    });
    Evt.listen('fileUploadInit', () => {
      // if (!this.interval) {
      //   this.interval = setInterval(() => {
      //     console.log('updateTimeRemaining called... from setInterval()')
      //     this.updateTimeRemaining()
      //   }, 1000)
      // }
    });
  },

  components: { File },
};
</script>
<style scoped>
.dragndrop__status {
  text-align: center;
  padding: 20px;
}
.list-inline {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-inline__item {
  display: inline;
  margin-right: 20px;
}
.list-inline__item--last {
  margin-right: 0;
}
</style>
