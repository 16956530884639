<template lang="pug">
table.table
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  thead
    tr
      th NAME
      th TITLE
      th PHONE NUMBER
      th EMAIL
      th UNIT/WARD/DEPARTMENT
      th NOTE
      th(v-if="gPermissions.canEditContact") EDIT
      th(v-if="gPermissions.canDeleteContact") DELETE
  tbody
    tr(v-if="loading")
      td.center-text(colspan="100")
        img(src="../../../assets/images/comps/loader.svg")
    tr(v-for="contact in contacts", v-if="contacts && !loading")
      td {{ contact.name }}
      td {{ getContactTitle(contact) }}
      td {{ contact.phone }}
      td {{ contact.email }}
      td {{ getContactLocations(contact) }}
      td {{ contact.note }}
      td(v-if="gPermissions.canEditContact")
        button.button.is-generic-app-teal.is-caps-lock.is-tiny(
          @click="onEdit(contact)"
        ) Edit
      td(v-if="gPermissions.canDeleteContact")
        button.button.is-danger.is-caps-lock.is-tiny(
          @click="onDelete(contact)"
        ) Delete
</template>

<script>
import { parseErrors } from "../../../lib/helpers/function";
import { mapActions } from "vuex";
export default {
  props: {
    loading: {
      type: Boolean,
    },
    contacts: {
      type: Array,
      required: true,
    },
    includes: {
      type: String,
      default: "",
    },
    contactTypes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions({
      deleteClientContacts: "deleteClientContacts",
    }),
    onEdit(contact) {
      const payload = { contact };
      this.$emit("contact-edit", payload);
    },
    async onDelete(contact) {
      try {
        await this.confirmDelete();
        this.deleteContact(contact);
      } catch (err) {
        console.log("error", err.message);
      }
    },
    confirmDelete(name = "this contact") {
      return new Promise((resolve) => {
        const alert = {
          title: `Do you wish to remove "${name}"?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
    async deleteContact(contact) {
      const params = { ...contact };
      try {
        await this.deleteClientContacts(params);
        this.$emit("patch-user");
      } catch (err) {
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    getContactTitle(contact) {
      if (this.contactTypes.length) {
        const foundCt = this.contactTypes.find(
          (ct) => ct.id === contact.contact_type_id
        );
        if (foundCt) {
          return foundCt.name;
        }
      }
      return null;
    },
    getContactLocations(contact) {
      if (Array.isArray(contact.locations)) {
        return contact.locations.map((loc) => loc.name).join(", ");
      }
      return "";
    },
    popupClose() {
      this.popup.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  .is-wide-tag {
    min-width: 50px;
    display: inline-flex;
    justify-content: space-between;
  }

  .generic-app-tag {
    position: relative;
    background-color: #e3ebed;
    border-radius: 3px;
    padding: 0.2em 0.5em;
  }
}
</style>
