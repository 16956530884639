<template lang="pug">
#notitcation-centre-load-templates
  .template-name
    h2 Choose a template
    multi-select(
      :options="modalProps",
      track-by="id",
      label="name",
      placeholder="SELECT A TEMPLATE",
      v-model="selectedTemplate",
      :showLabels="false",
    )
  .template-wrap(
    v-if="!isTemplateLoading"
  )
    .template-subject(
      v-if="selectedTemplate"
    )
      h2 Template subject
      input.input.is-medium(
        v-model="subject"
        disabled
      )
    .content-wrap(
      v-if="selectedTemplate"
    )
      h2 Template content
      .textarea(
        v-html="content"
      )
    .files(
      v-if="emailAttachments.length > 0"
    )
      h2 Attachments
      .attachments
        .attachment(
          v-for="(file, index) in emailAttachments"
        )
          span {{ file.name }}
  .spinner(
    v-if="isTemplateLoading"
  )
    img(
      class="btn-loader"
      src="../../../assets/images/comps/loader.svg"
      alt="loader"
    ) 
  .button-line
    button.button.is-generic-app-blue.is-caps-lock(
      v-if="selectedTemplate"
      @click.prevent="onLoadTemplate"
    )
      | Load template
    button.button.is-generic-app-blue.is-caps-lock(
      @click.prevent="$emit('dispose-modal');"
    )
      | Cancel
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions } from 'vuex';
export default {
  components: {
    MultiSelect,
  },
  props: {
    modalProps: {
      required: true,
    },
  },
  data () {
    return {
      selectedTemplate: null,
      isTemplateLoading: false,
      content: null,
      subject: null,
      emailAttachments: [],
    };
  },
  watch: {
    selectedTemplate: {
      deep: true,
      handler () {
        this.getTemplateContent();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchTemplateContent: 'fetchTemplateContent',
    }),
    onLoadTemplate () {
      this.$emit("load-template", {
        ...this.selectedTemplate,
        content: this.content,
        subject: this.subject,
        emailAttachments: this.emailAttachments,
      });
      this.$emit("dispose-modal");
    },
    async getTemplateContent () {
      this.isTemplateLoading = true;

      const params = {
        id: this.selectedTemplate.id,
      };
      try {
        const res = await this.fetchTemplateContent(params);
        this.content = res.data.data.data.data.body;
        this.subject = res.data.data.data.data.subject;
        this.emailAttachments = res.data.data.files;
      } catch (error) {
        console.log(error);
      } finally {
        this.isTemplateLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#notitcation-centre-load-templates {
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 35vh;
  padding: 1em 0 2em 0;
  .template-name {
    display: flex;
    flex-direction: column;
  }
  .content-wrap {
    display: flex;
    flex-direction: column;
    .textarea {
      height: 300px;
      overflow: auto;
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
  .spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
    img {
      width: 10%;
    }
  }
  h2 {
    text-align: center;
  }
  textarea {
    resize: none;
  }
}
</style>