export const GENDER_MAP = [
  { key: "M", label: "Male" },
  { key: "F", label: "Female" },
  { key: "O", label: "Other" },
  { key: "U", label: "Prefer not to say" },
];

export const SHIFT_TYPES = {
  normal: {
    key: "NRML",
    label: "Normal",
  },
  oncall: {
    key: "ONCL",
    label: "On call",
  },
  sleepover: {
    key: "SLPO",
    label: "Sleepover",
  },
  awaken: {
    key: "AWKE",
    label: "Awaken",
  },
};
