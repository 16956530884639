<template lang="pug">
#holiday-request
  .title Update holiday request status
  .content
    holiday-request-settings-clients
    holiday-request-settings-categories
    //- span.help.is-danger(
    //-   data-cell="holiday-request-error",
    //-   v-if="errors.has('holiday-request')",
    //-   v-html="errors.get('holiday-request')"
    //- )
</template>

<script>
// import { Errors } from "../../../lib/helpers/Errors";
import HolidayRequestSettingsClients from "./HolidayRequestSettingsClients.vue";
import HolidayRequestSettingsCategories from "./HolidayRequestSettingsCategories.vue";
export default {
  components: {
    HolidayRequestSettingsClients,
    HolidayRequestSettingsCategories,
  },
  // data() {
  //   return {
  //     errors: new Errors(),
  //   };
  // },
};
</script>

<style lang="scss" scoped>
#holiday-request {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 20px 0;
  gap: 20px;
  overflow: hidden;

  .title {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    gap: 20px 40px;
    justify-content: center;
    overflow: hidden;
  }
}
</style>
