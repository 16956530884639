<template lang="pug">
.signin
  header
    img(:src="getGlobalImageLogoApi", alt="")
    //- img(src='../../assets/images/cpltabs.png' alt='')
    p.subtitle.is-5 Please enter your registered Email address. A recovery link will be sent to the email provided.
  hr
  //- // <div class="notification is&#45;danger" v&#45;if="errors.any()">
  //- // <p>There was an error, unable to sign in with those credentials.</p>
  //- // </div>
  div
    section(v-if="message")
      p.success {{ message }}
    section.section--inputs
      p.control
        input.input.is-medium(
          type="email",
          autocomplete="new-password",
          @input="handleEmailInput($event)",
          @keyup.enter="submit"
        )
        span.help.is-danger(
          v-if="errors.has('email')",
          v-html="errors.get('email')"
        )
      .field.control.is-pulled-right
        router-link(:to="{ name: 'signin' }") Back to log in page
      button.button.is-generic-app-blue(
        :class="{ 'is-loading': isLoading }",
        @click="submit",
        @keydown="errors.clear($event.target.name)"
      ) SEND
</template>
<script>
import auth from "../../lib/auth.js";
import { Errors } from "../../lib/helpers/Errors.js";

export default {
  name: "ForgotPassword",
  data() {
    return {
      message: "",
      success: false,
      email: "",
      errors: new Errors(),
      isLoading: false,
    };
  },
  methods: {
    async submit() {
      this.isLoading = true;
      try {
        const data = await auth.getRecoveryLink(this, this.email);
        this.message = data.data.message;
        this.isLoading = false;
        // this.$toasted.success('Reset password link successfully sent.').goAway(2500)
      } catch (err) {
        this.message = "";
        this.isLoading = false;
        // this.errors.all().forEach(error => {
        //   this.$toasted.error(error).goAway(2500)
        // })
      }
    },
    handleEmailInput(event) {
      this.email = event.target.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.success {
  color: green;
}
</style>
