<template lang="pug">
#permission-settings-page
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .title Permission management
  .content2
    .bar
      .group
        label.label Role
        multi-select(
          :options="rolesPerms",
          :multiple="false",
          :close-on-select="true",
          select-label="",
          deselect-label="",
          :allow-empty="false",
          track-by="id",
          label="label",
          :value="selectedRole",
          placeholder="Filter by role",
          title="Filter by role",
          :loading="isLoadingRoles",
          @select="onSelectRole"
        )
      template(v-if="selectedRole")
        .group
          label.label Filter
          input.input(
            :value="searchProp",
            placeholder="Filter permissions",
            @input="onInputSearch"
          )
        .changed-perm-group
          span Number of changed permissions:&nbsp;
            strong {{ permisionChangedCount }}
          button.button.is-small.is-generic-app-blue.is-caps-lock(
            :disabled="permisionChangedCount === 0",
            @click="resetCountInvoker++"
          )
            span Reset changes
    router-view(
      :reset-count-invoker="resetCountInvoker",
      :list="getPermList",
      :key="selectedRole && selectedRole.id",
      v-if="selectedRole",
      @changed="onChangePerms",
      @click-save="onClickSave"
    )
    .loading-wrap(v-if="isLoadingPermList")
      .loading-wrapper
        img(src="@/assets/images/comps/loader.svg")
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions } from "vuex";
import { Errors } from "../../../lib/helpers/Errors";
import { findRecordErrors, parseErrors } from "../../../lib/helpers/function";
export default {
  name: "PermissionSettingsPage",
  components: {
    MultiSelect,
  },
  data() {
    return {
      rolesPerms: [],
      permisionChangedCount: 0,
      resetCountInvoker: 0,
      isLoadingRoles: true,
      isLoadingPermList: false,
      errors: new Errors(),
    };
  },
  computed: {
    getPermList() {
      return this.selectedRole?.["permissions-groups"] || [];
    },
    selectedRole() {
      if (this.$route.params.id) {
        return this.rolesPerms.find(
          (role) => role.id === parseInt(this.$route.params.id, 10)
        );
      }
      return null;
    },
    searchProp() {
      return this.$route.query.q;
    },
  },
  mounted() {
    this.checkSetLoadingPerms(true);
    this.fetchRolesWithPermissions();
  },
  methods: {
    ...mapActions({
      getRolesPermissions: "getRolesPermissions",
      postRolesPermissions: "postRolesPermissions",
    }),
    onChangePerms(evt) {
      this.permisionChangedCount = evt;
    },
    async onClickSave(evtCheckedPageIds) {
      const formatParamsSave = () => {
        const getCheckedItems = (role) => {
          return role["permissions-groups"].reduce((acc, curr) => {
            // Handled permissions because sometimes it's an empty arr, should be obj
            if (curr.permissions?.length) {
              for (const perm of curr.permissions) {
                if (perm.checked) {
                  acc.push({ id: perm.id });
                }
              }
            }
            return acc;
          }, []);
        };

        return this.rolesPerms.reduce((acc, curr) => {
          const isSameRole = this.selectedRole?.id === curr.id;
          if (isSameRole) {
            acc.push({
              id: curr.id,
              permissions: evtCheckedPageIds
                .filter((id) => typeof id === "number")
                .map((id) => ({ id })),
            });
          } else {
            acc.push({
              id: curr.id,
              permissions: getCheckedItems(curr),
            });
          }
          return acc;
        }, []);
      };

      const params = {
        roles: formatParamsSave(),
      };
      try {
        const res = await this.postRolesPermissions(params);
        this.$toasted.success(res.data.message).goAway(2000);
        this.fetchRolesWithPermissions();
      } catch (err) {
        console.warn(err.message);
        const errors = findRecordErrors(err);
        if (errors) {
          this.errors.record(errors);
          this.$toasted
            .show("Please correct the following errors.")
            .goAway(2500);
          this.errors.all().map((e) => {
            this.$toasted.error(e).goAway(2500);
          });
        } else {
          console.warn(err.response.data.message);
          let errs = parseErrors(err, "Error saving", true);
          this.$toasted.error(errs).goAway(4500);
        }
      }
    },
    async onSelectRole(evt) {
      const title =
        "You have unsaved changes, are you sure you want to change roles?";
      try {
        if (this.permisionChangedCount) {
          await this.confirmActionAlert("", title);
        }

        this.$router.push({
          name: "PermissionListManage",
          params: {
            id: evt.id,
          },
          query: this.$route.query,
        });
      } catch (err) {
        console.warn(err.message);
      }
    },
    onInputSearch(evt) {
      this.$router.push({
        query: {
          ...this.$route.query,
          q: evt.target.value,
        },
      });
    },
    async fetchRolesWithPermissions() {
      const params = {};
      try {
        const res = await this.getRolesPermissions(params);
        this.rolesPerms = res.data.data || [];
        this.isLoadingRoles = false;
        this.checkSetLoadingPerms(false);
      } catch (err) {
        console.warn(err.message);
      }
    },
    checkSetLoadingPerms(isLoading) {
      if (this.$route.params.id && isLoading) {
        this.isLoadingPermList = isLoading;
      } else {
        this.isLoadingPermList = false;
      }
    },
    confirmActionAlert(name = `apply changes?`, fullText = "") {
      const title = fullText || `Are you sure you want to ${name}`;
      return new Promise((resolve) => {
        let alert = {
          title,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#permission-settings-page {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 10px 0;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .title {
    display: flex;
    justify-content: center;
  }

  .content2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0px 0;
    gap: 20px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    min-width: 300px;
    width: 100%;

    .bar {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      width: 100%;

      .group {
        width: 300px;
      }

      input {
        min-height: 40px;
        width: 300px;
        margin: 0 auto;
      }

      .changed-perm-group {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
