<template lang="pug">
#rates-increments
  .top
    p.control
      label.label Category
      multi-select.category-select(
        :options="categories",
        :value="selectedCategory",
        placeholder="Select category",
        track-by="id",
        label="name",
        select-label="",
        deselect-label="",
        @select="setSelectedCategory",
        @remove="removeSelectedCategory"
      )
  .bottom
    .loader(v-if="isLoadingCats")
    template(v-if="!isLoadingCats")
      rates-increments-table(
        :category="selectedCategory",
        :increments="allIncrementsPoints",
        :selected-increment="selectedFormData.increment",
        :node-select-evt="setNodeSelected",
        v-if="selectedCategory",
        @change="refreshResetDataTree"
      )
      .form-placeholder
        transition(name="slide-right", mode="out-in")
          .placeholder-loader(v-if="firstTimeLoad")
            rates-increments-form(
              :key="selectedCategory && selectedCategory.id && selectedFormData && selectedFormData.increment && selectedFormData.increment.name",
              :category="selectedCategory",
              :form-data="selectedFormData",
              v-if="selectedFormData && selectedFormData.command",
              @change="refreshResetDataTree",
              @cancel="cancelFormView"
            )
</template>

<script>
import MultiSelect from "vue-multiselect";
import RatesIncrementsTable from "./Increments/RatesIncrementsTable.vue";
import RatesIncrementsForm from "./Increments/RatesIncrementsForm.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import store from "../../../vuex/store.js";
export default {
  name: "ratesManageIncrements",
  components: {
    MultiSelect,
    RatesIncrementsTable,
    RatesIncrementsForm,
  },
  data () {
    return {
      selectedCategory: null,
      selectedFormData: {},
      allIncrementsPoints: [],
      isLoadingCats: false,
      firstTimeLoad: false,
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.rateCategories,
    }),
    ...mapGetters({}),
  },
  beforeRouteEnter (to, from, next) {
    const categoriesRateArr = store.state.rateCategories;
    const sectorSelect = store.state.rateSectorSelected;
    if (
      Array.isArray(categoriesRateArr) &&
      categoriesRateArr.length &&
      sectorSelect
    ) {
      next();
    } else {
      console.log(
        "[Cancelled] rate increment route",
        categoriesRateArr,
        sectorSelect
      );
      next({
        name: "RatesManageCategories",
      });
    }
  },
  methods: {
    ...mapActions([
      "postTempIncrement",
      "deleteTempIncrement",
      "getRatesIncrements",
    ]),
    refreshResetDataTree () {
      this.selectedFormData = {};
      this.fetchIncrementsPoints();
    },
    async fetchIncrementsPoints () {
      if (
        !(
          Array.isArray(this.allIncrementsPoints) &&
          this.allIncrementsPoints.length
        )
      ) {
        // If only changing sectors [Reload tree]
        this.isLoadingCats = true;
      }
      const params = {
        catId: this.selectedCategory.id,
      };
      try {
        const res = await this.getRatesIncrements(params);
        this.allIncrementsPoints = this.formatIncrements(res.data.data);
        this.isLoadingCats = false;
        // console.log('increment points', this.allIncrementsPoints);
      } catch (err) {
        this.isLoadingCats = false;
        console.log(err.message);
      }
    },
    formatIncrements (dataArr) {
      // Sort increments
      return dataArr.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
    },
    setSelectedCategory (evtCat) {
      this.selectedFormData = {};
      this.selectedCategory = evtCat;
      this.fetchIncrementsPoints();
    },
    removeSelectedCategory () {
      this.selectedCategory = null;
      this.selectedFormData = {};
    },
    setNodeSelected (params) {
      this.selectedFormData = params;
      if (!this.firstTimeLoad) {
        this.$nextTick(() => {
          this.firstTimeLoad = true;
        });
      }
    },
    cancelFormView () {
      this.selectedFormData = {};
      this.firstTimeLoad = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#rates-increments {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: center;

    .category-select {
      width: auto;
      min-width: 300px;
    }
  }

  .bottom {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;

    .loader {
      width: 30px;
      height: 30px;
      max-height: 30px;
      max-width: 30px;
    }

    > *:not(.loader) {
      flex: 0 0 40%;
      min-width: 350px;
      height: 100%;

      // max-height: calc(100vh - 400px); // Fixed
    }

    > *:first-child {
      margin-right: 20px;
    }

    > *:last-child {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .top,
    .bottom {
      overflow: auto;
      justify-content: flex-start;
    }
  }

  .slide-right-enter-active {
    transition: all 0.3s ease;
  }

  .slide-right-leave-active {
    transition: all 0.4s ease;
  }

  .slide-right-enter,
  .slide-right-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }
}
</style>
