import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=eca0ec8a&lang=pug"
import script from "./Pagination.vue?vue&type=script&setup=true&lang=ts"
export * from "./Pagination.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=eca0ec8a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_2c597f7d109fe0052e634bf327ce7ff6/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports