<template lang="pug">
#client-contact-confirm-edit
  form(
    autocomplete="off",
    @submit.prevent="onSave",
    @keydown="errors.clear($event.target.name)"
  )
    .field
      label Name
      input.input(
        v-model="form.name",
        placeholder="Name",
        name="name",
        type="text"
      )
      span.help.is-danger(
        v-if="errors.has('name')",
        v-html="errors.get('name')"
      )
    //- .field
    //-   label Title
    //-   multi-select.column-select(
    //-     :options="contactTitles",
    //-     :searchable="true",
    //-     :options-limit="999",
    //-     select-label="",
    //-     selected-label="",
    //-     deselect-label="",
    //-     track-by="id",
    //-     label="name",
    //-     :multiple="false",
    //-     placeholder="Contact Title",
    //-     name="contact_type_id",
    //-     :value="parseContactTitle",
    //-     @select="onContactTitleSelect"
    //-   )
    //-   span.help.is-danger(
    //-     v-if="errors.has('contact_type_id')",
    //-     v-html="errors.get('contact_type_id')"
    //-   )
    //- .field
    //-   label Phone Number
    //-   input.input(
    //-     v-model="form.phone",
    //-     placeholder="Phone number",
    //-     name="phone",
    //-     type="text"
    //-   )
    //-   span.help.is-danger(
    //-     v-if="errors.has('phone')",
    //-     v-html="errors.get('phone')"
    //-   )
    .field
      label Email
      input.input(
        v-model="form.email",
        placeholder="Email",
        name="email",
        type="email"
      )
      span.help.is-danger(
        v-if="errors.has('email')",
        v-html="errors.get('email')"
      )
    //- .field
    //-   label Note
    //-   textarea.input(
    //-     v-model="form.note",
    //-     placeholder="Note",
    //-     name="note",
    //-     type="text"
    //-   )
    //-   span.help.is-danger(
    //-     v-if="errors.has('note')",
    //-     v-html="errors.get('note')"
    //-   )
    .field
      label Default contact
      //- input.ctrl--checkbox(
      //-   v-model="form.default",
      //-   placeholder="Default contact",
      //-   name="default",
      //-   type="checkbox",
      //-   style="margin-right: auto"
      //- )
      switches(
        style="margin: 19px 0 10px 0",
        theme="bulma",
        color="blue",
        v-model="form.default",
        name="default",
        placeholder="Default contact"
      )
      span.help.is-danger(
        v-if="errors.has('default')",
        v-html="errors.get('default')"
      )
    .buttons-centered
      button.button.is-small.is-generic-app-blue.is-caps-lock(
        @click.once.prevent="onSave",
        :disabled="isLoading"
      )
        .loading-wrapper(v-if="isLoading")
          img(src="../../../assets/images/comps/loader.svg")
        span Save
      button.button.is-small.is-outlined.is-caps-lock(
        @click.prevent="onCancel"
      ) Cancel
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";
import _ from "underscore";
import { Errors } from "../../../lib/helpers/Errors";
import apiTs from "@/api/apiTs";
export default {
  components: {
    MultiSelect,
  },
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        client_id: this.modalProps.client_id,
        ...this.modalProps.contact,
        default: !!this.modalProps.contact.contact_default,
        category_id: this.modalProps.category_id,
      },
      // contactTitles: [],
      errors: new Errors(),
      isLoading: false,
    };
  },
  computed: {
    // unfilteredContactTitles() {
    //   return this.modalProps.contactTypes || [];
    // },
    // parseContactTitle() {
    //   const foundCt = this.unfilteredContactTitles.find(
    //     (ct) => ct.id === this.form.contact_type_id
    //   );
    //   if (foundCt) {
    //     return foundCt;
    //   }
    //   return null;
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // await this.fetchContactTitles();
    },
    // async fetchContactTitles(params = {}) {
    //   try {
    //     const res = await this.getClientContactsTypesList(params);
    //     this.contactTitles = res.data.data;
    //   } catch (err) {
    //     console.log(err.message);
    //   }
    // },
    onSave() {
      this.saveAction();
    },
    onCancel() {
      this.$emit("cancel-modal");
    },
    // onContactTitleSelect(evt) {
    //   this.errors.clear("contact_type_id");
    //   this.$set(this.form, "contact_type_id", evt.id);
    // },
    async saveAction() {
      const payload = _.omit({ ...this.form }, "title");
      this.isLoading = true;
      try {
        await apiTs.putClientContacts(payload);
        this.$emit("refresh-data");
      } catch (err) {
        console.log("Error", err.message);
        if (err.response.data.errors) {
          this.errors.record(err.response.data.errors);
        } else if (err.response.data.message) {
          this.$toasted.error(err.response.data.message).goAway(1500);
        } else {
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(2500);
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#client-contact-confirm-edit {
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 5px 5px;

    .field {
      display: flex;
      flex-direction: column;
      gap: 5px;

      input:not([type="checkbox"]),
      textarea {
        height: 40px;
      }
    }
  }
}
.buttons-centered {
  button {
    display: flex;
    justify-content: space-evenly;
  }
}
.loading-wrapper {
  padding-top: 3px;
  img {
    height: 20px;
    width: 20px;
  }
}
</style>
