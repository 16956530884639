<template lang="pug">
.activity-clients
    .title Shifts activity tags
    .header
        .left
            label
            input(type="checkbox", :checked="selectAll", @click="onSelectAll")
            span Clients
            span(v-if="checkedItemIds.length")
                span ({{ checkedItemIds.length }} Checked)
        .right
            .fa.fa-refresh(
            title="Undo",
            @click="fetchClients(); selectAll = false"
            )
    input.input(v-model="search", placeholder="Filter Clients")
    .loading-wrap(v-if="isLoading")
        .loading-wrapper
            img.client-loading(src="@/assets/images/comps/loader.svg")
    template(v-if="!isLoading")
        checkbox-list-menu(
            :list="transformedList",
            :checkedList="checkedItemIds",
            :expandedItems="expandedItems",
            :has-arrow="false",
            @click="onClickedItem",
            @checked="onCheckedItem"
        )
        .buttons-centered
            button.button.is-small.is-generic-app-blue.is-caps-lock(
            type="submit",
            @click.prevent="onClickSave"
            ) Save
    simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { findRecordErrors, parseErrors } from "../../../lib/helpers/function";
export default {
    data() {
        return {
            checkedItemIds: [],
            expandedItems: [],
            selectAll: false,
            isLoading: false,
            search: "",
        };
    },
    computed: {
        ...mapGetters({
            clients: "getAllClientsActivityTags",
        }),
        transformedList() {

            const searchLower = this.search.trim().toLowerCase();

            const recFilter = (arr = [], includeParentNode = true) => {
                return arr.reduce((acc, item) => {
                    if (includeParentNode) {
                        if (item.name.toLowerCase().includes(searchLower)) {
                            acc.push(item);
                        }
                    } else {
                        acc.push(item);
                    }
                    return acc;
                }, []);
            };

            return recFilter(this.clients, true);
        },
    },
    created() {
        this.fetchClients();
    },
    mounted() {
        
    },
    watch: {
        // clients: {
        //     handler(val) {
        //         console.log(val)
        //     },
        //     deep: true,
        // }
    },
    methods: {
        ...mapActions({
            fetchClientsListActivityTags: "fetchClientsListActivityTags",
            postClientsListActivityTags: "postClientsListActivityTags"
        }),
        onSelectAll(evt) {
            this.selectAll = evt.target.checked;

            if (this.selectAll) {
                this.checkedItemIds = this.getAllIdsRec(this.transformedList);
            } else {
                this.checkedItemIds = [];
            }
        },
        onClickedItem(evt) {
            if (evt.children?.length) {
                const itemIndex = this.expandedItems.findIndex(
                    (eItem) => evt.id === eItem
                );
                if (itemIndex !== -1) {
                    this.expandedItems.splice(itemIndex, 1);
                } else {
                    this.expandedItems.push(evt.id);
                }
            } else {
                // QoL toggle
                this.onCheckedItem({
                    item: evt,
                });
            }
        },
        onCheckedItem(evt) {
            const isItemChecked =
                this.checkedItemIds.findIndex((cItem) => cItem === evt.item.id) !== -1;

            let childrenIds = [];
            const children = evt.item.children;
            if (children?.length) {
                childrenIds = this.getAllIdsRec(children);
            }
            const isAllChildrensChecked = childrenIds.every((childId) =>
                this.checkedItemIds.includes(childId)
            );

            const checkItemAndChildren = () => {
                const selSet = new Set(this.checkedItemIds);
                selSet.add(evt.item.id);

                for (const id of childrenIds) {
                    selSet.add(id);
                }
                this.checkedItemIds = Array.from(selSet);
            };

            const uncheckItemAndChildren = () => {
                this.checkedItemIds = this.checkedItemIds.filter(
                    (cItem) => cItem !== evt.item.id && !childrenIds.includes(cItem)
                );
            };

            if (evt.checked) {
                checkItemAndChildren();
            } else if (evt.checked === false) {
                uncheckItemAndChildren();
            } else if (!isItemChecked) {
                if (children?.length && !isAllChildrensChecked) {
                    checkItemAndChildren();
                } else if (!children?.length) {
                    checkItemAndChildren();
                }
            } else {
                uncheckItemAndChildren();
            }
        },
        getAllIdsRec(arr = []) {
            return arr.reduce((acc, curr) => {
                acc.push(curr.id);
                if (curr.children?.length) {
                    const ids = this.getAllIdsRec(curr.children);
                    acc.push(...ids);
                }
                return acc;
            }, []);
        },
        async fetchClients() {
            this.isLoading = true;
            const params = {};
            try {
                await this.fetchClientsListActivityTags({
                    per_page: 999,
                });
                this.isLoading = false;
                this.setChecked(this.clients);
            } catch (err) {
                this.isLoading = false;
                console.log("Error", err.message);
            }
        },
        setChecked(arr) {
            this.checkedItemIds = [];
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].client_is_using_shift_activity_tags === 1) {
                    this.checkedItemIds.push(arr[i].id)
                }
            }
        },
        async onClickSave() {
            try {
                await this.confirmActionAlert("save activate tags status");
                this.saveActivityTagsRequest();
            } catch (err) {
                // ignored
            }
        },
        confirmActionAlert(strAct) {
            return new Promise((resolve) => {
                const alert = {
                    title: `Are you sure you want to ${strAct}?`,
                    message: "",
                    type: "warning",
                    useConfirmBtn: true,
                    customConfirmBtnText: "Confirm",
                    customConfirmBtnClass: "button is-danger",
                    customCloseBtnText: "Cancel",
                    customCloseBtnClass: "button is-outlined",
                    onConfirm: () => {
                        resolve();
                    },
                };
                this.$refs.simplert.openSimplert(alert);
            });
        },
        async saveActivityTagsRequest() {
            const params = {
                clients: this.checkedItemIds.map((cItem) => ({ id: cItem })),
            };
            console.log(params)
            try {
                const res = await this.postClientsListActivityTags(params);
                console.log(res)
                // this.$toasted.success(res.data.message).goAway(2000);
            } catch (err) {
                console.warn(err.message);
                const errors = findRecordErrors(err);
                if (errors) {
                    this.errors.record(errors);
                    this.$toasted
                        .show("Please correct the following errors.")
                        .goAway(2500);
                    this.errors.all().map((e) => {
                        this.$toasted.error(e).goAway(2500);
                    });
                } else {
                    const errs = parseErrors(err, "Error saving", true);
                    console.warn(err.response.data.message);
                    this.$toasted.error(errs).goAway(4500);
                }
            } finally {
                this.fetchClients()
            }
            
        },
    },
};
</script>
    
<style lang="scss" scoped>
.activity-clients {
    width: 400px;

    display: flex;
    flex-direction: column;
    align-self: center;
    /* padding: 20px 0; 
        gap: 20px; */
    overflow: hidden;

    .header {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        user-select: none;

        .left {
            display: flex;
            gap: 10px;

            label {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        .right {
            display: flex;
            align-items: center;

            .fa {
                font-size: 14px;
                cursor: pointer;

                &:hover {
                    color: black;
                }
            }
        }
    }

    >input {
        min-height: 30px;
        height: 30px;
    }

    .checkbox-list-menu {
        overflow: auto;
        height: 100%;
    }

    .loading-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px 0;
    }

    .buttons-centered {
        margin-top: auto;
    }
}
</style>
    