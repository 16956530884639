<template lang='pug'>
li.file-row(:class="{ valid: isValid }")
  .name
    span {{ file.name }}
    //- span.title {{ file.name }} - {{ file.size }} - {{ file.type }}
    i.fa.fa-times(title="Remove File", @click="$emit('remove', file)")
</template>

<script>
export default {
  props: {
    file: {
      type: File,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.file-row {
  &.valid {
    color: green;
  }

  &:not(.valid) {
    color: red;
  }

  .name {
    display: flex;
    gap: 10px;

    .fa-times {
      display: flex;
      align-items: center;
      color: #b9b3aa;
      cursor: pointer;

      &:hover {
        color: darkred;
      }
    }
  }
}
</style>