<template lang="pug">
section#manage-file-upload.section
  h1.title.is-3
    i.fa.icon-file-cloud-upload
    | File uploader
  uni-tabs(:tabs="tabs")
  .content
    router-view
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
export default {
  name: "FileUploaderSection",
  computed: {
    tabs() {
      return [
        {
          label: "Timesheet",
          routeName: "bulkUploadTimesheetShifts",
        },
        {
          label: "Open shifts",
          routeName: "bulkUploadOpenShifts",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
#manage-file-upload {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px 1.5rem 10px;
  gap: 20px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    // width: 500px;
    gap: 10px 10px;
    margin: 0;
  }

  .loader {
    $size: 2rem;
    width: $size;
    height: $size;
    max-height: $size;
    max-width: $size;
  }
}
</style>
