<template lang="pug">
#notification-centre-form.section
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :size="modalSize"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @load-template="loadTemplate",
      @save-template="saveTemplate",
      @edit-signature="editSignature",
      @add-signature="addSignature",
      @commit-list="onCommitEmailFrom",
      @dispose-modal="onDisposeModal"
    )
  .filters-wrap
    h2 {{ "Select " + getMessageFromFormType + " recipient(s)" }}
    .filters
      p.control
        multi-select(
          :multiple="true",
          :options="agencyWorkers",
          :close-on-select="true",
          :searchable="true",
          track-by="id",
          label="name",
          v-model="query.selectedAgencyWorkers",
          placeholder="TYPE TO FIND AGENCY WORKER",
          :internal-search="false",
          @search-change="asyncFindWorker"
        )
          //- :loading="isLoading",
          //- @select="errors.clearLike('temp_id')"
      p.control
        multi-select#Sectors(
          :options="getSectors",
          :loading="isSectorLoading",
          track-by="id",
          label="name",
          placeholder="SELECT REGION",
          :multiple="true",
          v-model="query.selectedSectors",
          group-label="name",
          group-values="subs",
          :showLabels="false"
        )
      p.control
        multi-select#Client(
          :options="filteredClients",
          :loading="isLoadingLocations",
          :multiple="true",
          track-by="id",
          label="name",
          v-model="query.selectedClients",
          placeholder="SELECT CLIENT"
        )
          span(slot="noResult") Nothing found.
      p.control
        multi-select(
          :options="filteredCategories",
          :multiple="true",
          track-by="id",
          label="category",
          placeholder="SELECT CATEGORY",
          v-model="query.selectedCategories",
          :showLabels="false"
        )
      p.control
        multi-select(
          :options="filteredSubcategories",
          :multiple="true",
          track-by="id",
          label="name",
          placeholder="SELECT SUBCATEGORY",
          v-model="query.selectedSubcategories",
          group-label="category",
          group-values="subs",
          :showLabels="false"
        )
      p.control
        multi-select#Statuses(
          :options="optionsStatus",
          :multiple="true",
          track-by="id",
          label="label",
          :value="query.selectedStatus",
          @input="onSelectStatus",
          placeholder="SELECT STATUS",
          :showLabels="false"
        )
        span.error(
          v-if="(query.selectedStatus.length === 0) & !isTempSelected"
        )
          | Status is required
  .editor-wrap
    h2 {{ "Write " + getMessageFromFormType }}
    .input-bar
      input.control.input.is-medium(
        style="height: 40px",
        v-model.trim="subject",
        placeholder="SUBJECT"
      )
      template(v-if="isEmail")
        p.control
          multi-select(
            :options="emailFromList",
            :multiple="false",
            track-by="id",
            label="email",
            placeholder="SELECT EMAIL FROM",
            v-model="selectedEmailFrom",
            :showLabels="false"
          )
        //- button.button.is-generic-app-blue.is-caps-lock(@click='onAddEmailFrom')
          span Manage emails from list
    .editor-signature(
      :class="{ 'hide-bottom-border': signatureContent.length > 0 }"
    )
      quill-editor(
        ref="myQuillEditor",
        :options="editorOptions",
        :content="messageContent",
        @change="onEditorChange"
      )
        //- v-model.trim="messageContent"
        #toolbar(slot="toolbar")
          span.ql-formats
            button.ql-bold(v-tooltip="'Bold (CTRL+B)'")
            button.ql-italic(v-tooltip="'Italic (CTRL+I)'")
            button.ql-underline(v-tooltip="'Underline (CTRL+U)'")
            button.ql-strike(v-tooltip="'Strikethrough'")
          span.ql-formats
            button.ql-blockquote(v-tooltip="'Blockquote'")
            button.ql-code-block(v-tooltip="'Code block'")
            button.ql-header(value="1", v-tooltip="'Header 1'")
            button.ql-header(value="2", v-tooltip="'Header 2'")
          span.ql-formats
            button.ql-list(value="ordered", v-tooltip="'Toggle numbered list'")
            button.ql-list(value="bullet", v-tooltip="'Toggle bulleted list'")
            button.ql-script(value="sub", v-tooltip="'Subscript'")
            button.ql-script(value="super", v-tooltip="'Superscript'")
          span.ql-formats
            button.ql-indent(value="+1", v-tooltip="'Increase indent'")
            button.ql-indent(value="-1", v-tooltip="'Decrease indent'")
          span.ql-formats
            button.ql-direction(value="rtl", v-tooltip="'Rotate alignment'")
          span.ql-formats
            .ql-wrap(
              v-tooltip="'Font size'",
              style="width: 108px; display: inline-block"
            )
              select.ql-size
            .ql-wrap(
              v-tooltip="'Font name'",
              style="width: 108px; display: inline-block"
            )
              select.ql-font
          span.ql-formats
            .ql-wrap(v-tooltip="'Font color'", style="display: inline-block")
              select.ql-color
            .ql-wrap(
              v-tooltip="'Highlight color'",
              style="display: inline-block"
            )
              select.ql-background
          span.ql-formats
            .ql-wrap(v-tooltip="'Header'", style="display: inline-block")
              select.ql-header
          span.ql-formats
            .ql-wrap(
              v-tooltip="'Text alignment'",
              style="display: inline-block"
            )
              select.ql-align
          span.ql-formats
            button.ql-clean
          span.ql-formats
            button.ql-link(v-tooltip="'Hyperlink'")
            button.ql-image(v-tooltip="'Add image'")
            button.ql-video(v-tooltip="'Add video embed'")
      .signature(v-if="signatureContent.length > 0")
        .sig-content(v-html="signatureContent")
        .remove-wrap(@click="signatureContent = ''")
          img(src="../../assets/images/comps/delete.svg", alt="delete icon")
          span Remove signature
    .attachments
      .attachment(v-for="(file, index) in emailAttachments")
        span {{ file.name }}
        .remove-wrap
          span.fa.fa-times(
            style="cursor: pointer",
            @click="onRemoveAttachment(index)"
          )
    .signatures(v-if="isEmail && $can('create-notificationcentre-messages')")
      span(@click="showSignatures = !showSignatures")
        img(src="../../assets/images/comps/Olovka.svg", alt="pencil image")
        | {{ showSignatures ? "Hide signatures" : "Show signatures" }}
      transition(name="fade")
        notification-centre-form-signature-table(
          v-if="showSignatures",
          :signatures="signatures",
          :isLoading="isSignatureLoading",
          @use-signature="onUseSignature",
          @add-signature="onAddSignature",
          @edit-signature="onEditSignature",
          @delete-signature="onDeleteSignature"
        )
    .buttons
      input#attachment(
        ref="attachment",
        type="file",
        hidden,
        @change="onAddAttachment"
      )
      button.button.is-generic-app-blue.is-caps-lock(
        v-if="$can('create-notificationcentre-messages')",
        :class="{ 'is-loading': isSendingMessage }",
        :disabled="isSendingMessage",
        @click.prevent="onSendMessage"
      )
        | {{ "Send " + getMessageFromFormType }}
      label.button.is-generic-app-blue.is-caps-lock(
        v-if="isEmail",
        for="attachment"
      )
        | Attach Files
      button.button.is-generic-app-blue.is-caps-lock(
        v-if="templates.length > 0 && $can('view-notificationcentre-templates')",
        @click.prevent="onLoadTemplate"
      )
        | Load template
      button.button.is-generic-app-blue.is-caps-lock(
        v-if="messageContent !== '' && $can('create-notificationcentre-templates')",
        @click.prevent="onSaveTemplate"
      )
        | {{ "Save " + getMessageFromFormType + " template" }}
  .sent-emails(v-if="true")
    h2 Review sent {{ this.getMessageFromFormType }}s
    .table-wrap(ref="list")
      table.table
        thead
          tr
            th
              span Date
            //- th
            //-   span Created by ID
            th
              span Selected Filters
            th
              span Created By
            th
              span Subject
            th
              span {{ this.capitalize(this.getMessageFromFormType) }} body
            th(v-if="$route.path === '/notification-centre/message-centre'")
              span Viewed by Workers
        tbody(v-if="sentMessages && sentMessages.length > 0")
          tr(v-for="(message, index) in sentMessages", :key="index")
            td
              p.control2
                | {{ message.created_at.date }}
            //- td
              p.control2
                | {{ message.created_by_id }}
            td
              p.control2
                button.button.is-generic-app-blue.is-caps-lock.is-tiny(
                  :disabled="!message.filters",
                  @click="onShowFiltersUsed(message)",
                  v-tooltip="{ content: getFiltersUsed(message), placement: 'left', classes: 'tooltip-width-600' }"
                ) {{ `Show Filters` }}
            td
              p.control2
                | {{ message.created_by_name }}
            td
              p.control2
                | {{ message.data.title }}
            td
              p.control2
                button.button.is-generic-app-blue.is-caps-lock.is-tiny(
                  @click="onShowMessageBody(message.data.body)",
                  v-tooltip="{ content: message.data.body, placement: 'left', classes: 'tooltip-width-600' }"
                ) {{ `Show ${getMessageFromFormType} body` }}
            td(v-if="$route.path === '/notification-centre/message-centre'")
              p.control2
                button.button.is-generic-app-blue.is-caps-lock.is-tiny(
                  @click="showModalListOfWorkers(message.data.fcm_data.data._ntf_id)",
                  v-tooltip="{ content: 'Workers who have seen the notification', placement: 'left', classes: 'tooltip-width-600' }"
                ) Viewed by Workers
        tbody(v-if="isSentMessagesLoading")
          tr
            td.center-text(colspan="100")
              img(src="../../assets/images/comps/loader.svg")
        tbody(v-if="sentMessages.length === 0 && !isSentMessagesLoading")
          tr
            td(colspan="100")
              | No data in table
      pagination.notification-center-pagination(
        v-if="notificationCentreLogsPagination && !isSentMessagesLoading",
        :total-pages="notificationCentreLogsPagination.total_pages",
        :current-page="notificationCentreLogsPagination.current_page",
        @page-changed="pageHasChanged"
      )
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import MultiSelect from "vue-multiselect";
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

import NotificationCentreSaveTemplate from "../in-modal/notification-centre/NotificationCentreSaveTemplate.vue";
import NotificationCentreLoadTemplate from "../in-modal/notification-centre/NotificationCentreLoadTemplate.vue";
import NotificationCentreFormSignatureTable from "./NotificationCentreFormSignatureTable.vue";
import NotificationCentreAddEditSignature from "../in-modal/notification-centre/NotificationCentreAddEditSignature.vue";
import NotificationEmailFromList from "./NotificationEmailFromList.vue";
import NotificationFiltersUsed from "./NotificationFiltersUsed.vue";
import NotificationCentreMessageBody from "../in-modal/notification-centre/NotificationCentreMessageBody.vue";
import ListOfWorkers from "./ListOfWorkers.vue";
import Pagination from "../Pagination.vue";

import optionsStatus from "./optionsStatus";

import { parseErrors } from "../../lib/helpers/function";

export default {
  components: {
    quillEditor,
    MultiSelect,
    NotificationCentreSaveTemplate,
    NotificationCentreLoadTemplate,
    NotificationCentreFormSignatureTable,
    NotificationCentreAddEditSignature,
    NotificationEmailFromList,
    NotificationCentreMessageBody,
    NotificationFiltersUsed,
    ListOfWorkers,
    Pagination,
  },
  props: {
    isEmail: {
      type: Boolean,
    },
    isPushMessage: {
      type: Boolean,
    },
  },
  setup() {
    async function showModalListOfWorkers(ntf_id) {
      const payload = {
        notification_batch: ntf_id,
      };
      this.showModal("ListOfWorkers", payload, `List of workers`);
    }
    return { showModalListOfWorkers };
  },
  data() {
    return {
      perPaginationPage: 15,
      notificationCentreLogsPagination: { total_pages: 1, current_page: 1 },
      messageContent: "",
      subject: "",
      emailAttachments: [],
      signatures: [],
      signatureContent: "",
      signatureToDelete: null,
      templates: [],
      selectedEmailFrom: null,
      selectedTemplateId: null,

      query: {
        selectedSectors: [],
        selectedClients: [],
        selectedCategories: [],
        selectedSubcategories: [],
        selectedAgencyWorkers: [],
        // active status selected by default
        selectedStatus: [
          {
            id: 1,
            label: "Active",
            backColor: "#23d160",
          },
        ],
      },

      optionsClient: [],

      sentMessages: [],

      modalVisible: false,
      isLoadingLocations: false,
      isSectorLoading: false,
      isSendingMessage: false,
      isSentMessagesLoading: false,
      isSignatureLoading: false,

      showSignatures: false,

      modalSize: null,

      editorOptions: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      emailFromList: [],
    };
  },
  computed: {
    ...mapGetters({
      pagination: "getPagination",
      temps: "getTemps",
      getSectors: "locationClientManagement/getSectors",
      allCategories: "getCategoriesWithSubcategories",
      clientsAvailable: "getAllClients",
      agencyWorkers: "getAgencyWorkers",
    }),
    isEmailOrNotificationCode() {
      return this.isEmail ? "EMAL" : "NOTF";
    },
    isTempSelected() {
      return this.query.selectedAgencyWorkers.length > 0;
    },
    filteredClients() {
      const subCatIdsArr = this.query.selectedSubcategories.map(
        (subCat) => subCat.id
      );
      const catIdsArr = this.query.selectedCategories.map(
        (catObj) => catObj.id
      );

      let cliArr = JSON.parse(JSON.stringify(this.optionsClient));

      //SELECTED REGION
      if (this.query.selectedSectors.length > 0) {
        const selectedSectorIdsArr = this.query.selectedSectors.map(
          (secObj) => secObj.id
        );
        cliArr = this.optionsClient.filter((cliObj) => {
          return cliObj.sectors.find((sectorObj) =>
            selectedSectorIdsArr.includes(sectorObj.id)
          );
        });
      }
      //SELECTED SUBCATEGORY
      if (this.query.selectedSubcategories.length > 0) {
        return cliArr.filter((cliObj) => {
          const catArr = cliObj.categories.map(
            (catObj) => catObj.subcategories
          );
          for (let i = 0; i < catArr.length; i++) {
            for (let j = 0; j < catArr[i].length; j++) {
              if (subCatIdsArr.includes(catArr[i][j].id)) {
                return cliObj;
              }
            }
          }
        });
      }
      //SELECTED CATEGORY
      if (this.query.selectedCategories.length > 0) {
        return cliArr.filter((cliObj) => {
          if (
            cliObj.categories.find((catObj) => catIdsArr.includes(catObj.id))
          ) {
            return cliObj;
          }
        });
      }

      return cliArr;
    },
    filteredCategories() {
      if (
        this.query.selectedClients.length > 0 &&
        this.query.selectedSubcategories.length > 0
      ) {
        const catArrs = this.query.selectedClients.map(
          (cliObj) => cliObj.categories
        );
        const uniqueCatIds = [];
        const categoriesFromClients = [];

        for (let i = 0; i < catArrs.length; i++) {
          for (let j = 0; j < catArrs[i].length; j++) {
            if (!uniqueCatIds.includes(catArrs[i][j].id)) {
              const catObj = JSON.parse(JSON.stringify(catArrs[i][j]));
              catObj.subs = catObj.subcategories;
              catObj.category = catObj.abbreviation;
              categoriesFromClients.push(catObj);
              uniqueCatIds.push(catObj.id);
            }
          }
        }

        const subCatParentIdsArr = this.query.selectedSubcategories.map(
          (subCat) => subCat.catParentId || subCat.parent_id
        );
        const uniqueCatParentIds = [...new Set(subCatParentIdsArr)];
        return categoriesFromClients.filter((catObj) => {
          if (uniqueCatParentIds.includes(catObj.id)) return catObj;
        });
      }

      if (this.query.selectedClients.length > 0) {
        const catArrs = this.query.selectedClients.map(
          (cliObj) => cliObj.categories
        );
        const uniqueCatIds = [];
        const categoriesFromClients = [];

        for (let i = 0; i < catArrs.length; i++) {
          for (let j = 0; j < catArrs[i].length; j++) {
            if (!uniqueCatIds.includes(catArrs[i][j].id)) {
              const catObj = JSON.parse(JSON.stringify(catArrs[i][j]));
              catObj.subs = catObj.subcategories;
              catObj.category = catObj.abbreviation;
              categoriesFromClients.push(catObj);
              uniqueCatIds.push(catObj.id);
            }
          }
        }
        return categoriesFromClients;
      }

      if (this.query.selectedSubcategories.length > 0) {
        const subCatParentIdsArr = this.query.selectedSubcategories.map(
          (subCat) => subCat.catParentId || subCat.parent_id
        );
        const uniqueCatParentIds = [...new Set(subCatParentIdsArr)];
        return this.allCategories.filter((catObj) => {
          if (uniqueCatParentIds.includes(catObj.id)) return catObj;
        });
      }

      return this.allCategories;
    },
    filteredSubcategories() {
      if (this.query.selectedCategories.length > 0)
        return this.query.selectedCategories;

      if (this.query.selectedClients.length > 0) return this.filteredCategories;

      return this.allCategories;
    },
    getMessageFromFormType() {
      if (this.isEmail) return "email";
      if (this.isPushMessage) return "message";
      return null;
    },
    optionsStatus() {
      if (this.isEmail) {
        return optionsStatus;
      } else if (this.isPushMessage) {
        // remove 'inactive' status for push messages
        return optionsStatus.filter((option) => option.id !== 3);
      }
      return null;
    },
  },
  watch: {
    clientsAvailable() {
      this.fillOptionsClient();
    },
    $route: {
      handler(val, oldVal) {
        if (
          val.query.page &&
          oldVal.query.page &&
          val.query.page !== oldVal.query.page
        ) {
          this.getMessages(val.query.page);
        }
      },
      deep: true,
    },
    isEmail: {
      handler() {
        this.clearData();
        this.getTemplates();
        // get messages fires from query watcher
        // this.getMessages();
      },
    },
    query: {
      deep: true,
      async handler() {
        await this.getMessages();
      },
    },
    isTempSelected: {
      handler(isTrue) {
        if (isTrue) {
          // this.query.selectedSectors = [];
          // this.query.selectedClients = [];
          // this.query.selectedCategories = [];
          // this.query.selectedSubcategories = [];
          // this.query.selectedStatus = [];
        } else {
          this.query.selectedStatus = [
            {
              id: 1,
              label: "Active",
              backColor: "#23d160",
            },
          ];
        }
      },
    },
  },
  mounted() {
    this.isLoadingLocations = true;
    const clientIncludes = [
      "sectors",
      "clientSubcategories",
      "categories.subcategories",
    ].join();
    this.fetchClientsList({
      includes: clientIncludes,
      per_page: 999,
    }).then(() => (this.isLoadingLocations = false));

    this.isSectorLoading = true;
    this.fetchSectors({
      includes: ["clients", "clients.locations"].join(","),
    }).then(() => (this.isSectorLoading = false));

    this.fetchAllCategoriesList();

    this.getTemplates();

    this.getMessages();

    this.getSignatures();
    this.fetchEmailFrom();
  },
  methods: {
    ...mapActions({
      fetchDocumentsForApproval: "fetchDocumentsForApproval",
      fetchAllTemps: "fetchAllTemps",
      fetchSectors: "locationClientManagement/fetchSectors",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchTemplates: "fetchTemplates",
      postTemplate: "postTemplate",
      fetchClientsList: "fetchClientsList",
      findAgencyWorker: "findAgencyWorker",
      fetchMessages: "fetchMessages",
      postMessage: "postMessage",
      fetchSignatures: "fetchSignatures",
      fetchSignatureData: "fetchSignatureData",
      postSignature: "postSignature",
      putSignature: "putSignature",
      deleteSignature: "deleteSignature",
      getEmailFrom: "getEmailFrom",
    }),
    onEditorChange(evt) {
      this.messageContent = evt.html;
    },
    async getTemplates() {
      if (this.$can("view-notificationcentre-templates")) {
        const templateType = {
          type: this.isEmailOrNotificationCode,
        };
        try {
          const res = await this.fetchTemplates(templateType);
          this.templates = res.data.data;
        } catch (error) {
          this.handleErrors(error);
        }
      }
    },
    pageHasChanged(clickedPage) {
      this.getMessages(clickedPage);
    },
    async getMessages(page = null) {
      if (this.$can("view-notificationcentre-messages")) {
        this.isSentMessagesLoading = true;
        this.sentMessages = [];

        const paramsObj = this.prepareSearchParams();
        paramsObj.type = this.isEmailOrNotificationCode;
        paramsObj.with_tags = true;
        paramsObj.per_page = this.perPaginationPage;
        paramsObj.page = page || this.$route.query.page || 1;
        try {
          const res = await this.fetchMessages(paramsObj);
          this.notificationCentreLogsPagination = res.data.meta;

          if (this.isPushMessage) {
            res.data.data.map((msgObj) => {
              // firstly parse JSON data if needed
              if (typeof msgObj.data === "string") {
                const parsedData = JSON.parse(msgObj.data);
                msgObj.data = parsedData;
              }

              // secondly transform based on fcm_data key
              if (msgObj.data.fcm_data) {
                msgObj.data.body = msgObj.data.fcm_data.data.body;
                msgObj.data.title = msgObj.data.fcm_data.data.title;
              } else if (!msgObj.data.fcm_data) {
                msgObj.data.body = msgObj.data.data.body;
                msgObj.data.title = msgObj.data.data.title;
              }

              return msgObj;
            });
          }
          this.sentMessages = res.data.data;
          this.isSentMessagesLoading = false;
        } catch (error) {
          this.handleErrors(error);
          this.isSentMessagesLoading = false;
        }
      }
    },
    async getSignatures() {
      this.isSignatureLoading = true;
      try {
        const res = await this.fetchSignatures();
        this.signatures = res.data.data;
      } catch (error) {
        this.handleErrors();
      } finally {
        this.isSignatureLoading = false;
      }
    },
    async fetchEmailFrom() {
      try {
        const res = await this.getEmailFrom();
        this.emailFromList = res.data.data || [];
      } catch (err) {
        console.warn(err.message);
      }
    },
    onAddAttachment(filesEvt) {
      this.emailAttachments.push(filesEvt.target.files[0]);
      this.$refs.attachment.value = "";
    },
    onRemoveAttachment(index) {
      this.emailAttachments.splice(index, 1);
    },
    async onSendMessage() {
      if (!this.isMessageValid()) {
        return;
      }

      const alert = {
        title: `Are you sure you want to send this ${
          this.isEmail ? "email" : "push notification"
        }?`,
        message: "",
        type: "warning",
        useConfirmBtn: true,
        customConfirmBtnText: "Confirm",
        customConfirmBtnClass: "button is-danger",
        customCloseBtnText: "Cancel",
        customCloseBtnClass: "button is-outlined",
        onConfirm: this.sendMessage,
      };
      this.$refs.simplert.openSimplert(alert);
    },
    async sendMessage() {
      const params = this.prepareParams();
      this.isSendingMessage = true;
      try {
        await this.postMessage(params);

        await this.getMessages();

        this.clearData();

        this.$toasted
          .success(
            this.capitalize(this.getMessageFromFormType) + " sent succesfully."
          )
          .goAway(2500);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.isSendingMessage = false;
      }
    },
    isMessageValid() {
      if (this.query.selectedStatus.length === 0 && !this.isTempSelected) {
        this.$toasted.error("Please select candidate status.").goAway(2500);
        return false;
      }

      if (this.subject.length === 0) {
        this.$toasted.error("Please enter message subject.").goAway(2500);
        return false;
      }
      if (this.messageContent.length === 0) {
        this.$toasted.error("Please enter message body.").goAway(2500);
        return false;
      }

      return true;
    },
    onSelectStatus(statuses) {
      const isAllOptionSelected =
        statuses.findIndex((status) => status.id === 0) > -1;

      // if 'all' is selected with rest of the options being already selected - unselect all
      if (
        this.query.selectedStatus.length === this.optionsStatus.length - 1 &&
        isAllOptionSelected
      ) {
        this.query.selectedStatus = [];
        return;
      }

      // if 'all' is selected and not all options are selected manually - select all
      if (isAllOptionSelected) {
        this.query.selectedStatus = this.optionsStatus.filter(
          (status) => status.id !== 0
        );
        return;
      }

      // default handler
      this.query.selectedStatus = statuses;
    },
    async onUseSignature({ id }) {
      if (this.$can("create-notificationcentre-messages")) {
        try {
          const res = await this.fetchSignatureData({ id });
          this.signatureContent = res.data.data.data;
          this.showSignatures = false;
        } catch (error) {
          this.handleErrors(error);
        }
      }
    },
    onAddSignature() {
      this.showModal(
        "NotificationCentreAddEditSignature",
        null,
        "Add signature"
      );
    },
    // onAddEmailFrom() {
    //   this.showModal(
    //     "NotificationEmailFromList",
    //     { emailList: this.emailFromList },
    //     "Manage emails from list"
    //   );
    // },
    async addSignature(signature) {
      if (this.$can("create-notificationcentre-messages")) {
        try {
          await this.postSignature(signature);
          await this.getSignatures();
          this.$toasted.success("Signature added.").goAway(2500);
        } catch (error) {
          this.handleErrors(error);
        }
      }
    },
    async onCommitEmailFrom(evtList) {
      this.onDisposeModal();
      await this.fetchEmailFrom();
    },
    async onEditSignature(signature) {
      if (this.$can("create-notificationcentre-messages")) {
        let res;
        try {
          res = await this.fetchSignatureData({ id: signature.id });
          console.log(res);
        } catch (error) {
          this.handleErrors(error);
        }

        this.showModal(
          "NotificationCentreAddEditSignature",
          res.data.data,
          "Edit signature"
        );
      }
    },
    async editSignature(signature) {
      if (this.$can("create-notificationcentre-messages")) {
        try {
          await this.putSignature(signature);
          this.$toasted.success("Signature edited.").goAway(2500);
          this.$toas;
        } catch (error) {
          this.handleErrors(error);
        }
      }
    },
    onDeleteSignature(signature) {
      this.signatureToDelete = signature;
      const alert = {
        title: `Are you sure you want to delete this signature?`,
        message: "",
        type: "warning",
        useConfirmBtn: true,
        customConfirmBtnText: "Confirm",
        customConfirmBtnClass: "button is-danger",
        customCloseBtnText: "Cancel",
        customCloseBtnClass: "button is-outlined",
        onConfirm: this.removeSignature,
      };
      this.$refs.simplert.openSimplert(alert);
    },
    async removeSignature() {
      if (this.$can("create-notificationcentre-messages")) {
        try {
          await this.deleteSignature(this.signatureToDelete);
          this.$toasted.success("Signature deleted.").goAway(2500);
          await this.getSignatures();
        } catch (error) {
          this.handleErrors();
        }
      }
    },
    onSaveTemplate() {
      this.showModal(
        "NotificationCentreSaveTemplate",
        {
          message: this.messageContent,
          subject: this.subject,
          emailAttachments: this.emailAttachments,
        },
        "Save template"
      );
    },
    onLoadTemplate() {
      this.showModal(
        "NotificationCentreLoadTemplate",
        this.templates,
        "Load template"
      );
    },
    async saveTemplate(templateObj) {
      const formData = new FormData();
      formData.append("type", this.isEmailOrNotificationCode);
      formData.append("name", templateObj.name);
      formData.append("subject", this.subject);
      formData.append("content", this.messageContent);
      if (
        Array.isArray(this.emailAttachments) &&
        this.emailAttachments.length
      ) {
        this.emailAttachments.forEach((file) => {
          formData.append("files[]", file);
        });
      }

      try {
        await this.postTemplate(formData);
        this.$toasted
          .success("Template has been successfully created.")
          .goAway(2500);
        this.getTemplates();
      } catch (error) {
        this.handleErrors(error);
      }
    },
    onShowMessageBody(msgBody) {
      this.modalSize = 850;
      this.showModal(
        "NotificationCentreMessageBody",
        msgBody,
        `${this.capitalize(this.getMessageFromFormType)} content`
      );
    },
    clearData() {
      this.messageContent = "";
      this.subject = "";
      this.emailAttachments = [];
      this.selectedTemplateId = null;

      this.query = {
        selectedSectors: [],
        selectedClients: [],
        selectedCategories: [],
        selectedSubcategories: [],
        selectedAgencyWorkers: [],
        selectedStatus: [
          {
            id: 1,
            label: "Active",
            backColor: "#23d160",
          },
        ],
      };
    },
    loadTemplate(templateObj) {
      // Loading another template over the exisiting one overwrites it
      const emailAttachmentsWithoutTemplateFiles = this.emailAttachments.filter(
        (file) => !file.id
      );
      this.emailAttachments = [
        ...emailAttachmentsWithoutTemplateFiles,
        ...templateObj.emailAttachments,
      ];

      this.subject = templateObj.subject;
      this.messageContent = templateObj.content;
      this.selectedTemplateId = templateObj.id;
    },
    fillOptionsClient() {
      this.optionsClient = this.clientsAvailable.map((client) => {
        return {
          name: client.name,
          id: client.id,
          sectors: client.sectors,
          categories: client.categories,
        };
      });
    },
    prepareParams() {
      const paramsObj = this.prepareSearchParams();

      // rest of the params
      paramsObj.title = this.subject;

      if (this.isEmail) {
        // Email params
        paramsObj.body = {
          data: {
            title: this.subject,
            body: this.messageContent + this.signatureContent,
          },
        };
        paramsObj.urlPart = "emails";
        paramsObj.attachments = this.emailAttachments;
        if (this.selectedEmailFrom?.email) {
          paramsObj.email_from = this.selectedEmailFrom.email;
        }
        if (this.selectedTemplateId) {
          paramsObj.template_id = this.selectedTemplateId;
        }
      } else if (this.isPushMessage) {
        // Notification params
        paramsObj.body = {
          data: {
            notification: {
              body: this.subject,
            },
            data: {
              title: this.subject,
              body: this.messageContent + this.signatureContent,
            },
          },
        };
        paramsObj.urlPart = "notifications";
      }

      return paramsObj;
    },
    prepareSearchParams() {
      const paramsObj = {};
      // Add `selected sectors` to query object.
      if (this.query.selectedSectors.length > 0) {
        paramsObj.sectors = this.query.selectedSectors.map((secObj) => ({
          id: secObj.id,
        }));
        // paramsObj.clients = this.filteredClients.map(clientObj => ({ id: clientObj.id }));
      }
      // Add `selected clients` to query object.
      if (this.query.selectedClients.length > 0) {
        paramsObj.clients = this.query.selectedClients.map((clientObj) => ({
          id: clientObj.id,
        }));
      }
      // Add `selected categories` to query object.
      if (this.query.selectedCategories.length > 0) {
        paramsObj.categories = this.query.selectedCategories.map((catObj) => ({
          id: catObj.id,
        }));
      }
      // Add `selected subcategories` to query object.
      if (this.query.selectedSubcategories.length > 0) {
        paramsObj.subcategories = this.query.selectedSubcategories.map(
          (subcatObj) => ({ id: subcatObj.id })
        );
      }
      // Add `selected agency workers` to query object.
      if (this.query.selectedAgencyWorkers.length > 0) {
        paramsObj.temps = this.query.selectedAgencyWorkers.map((tempObj) => ({
          id: tempObj.id,
        }));
      }
      // Add 'status' to query object.
      if (this.query.selectedStatus.length > 0) {
        paramsObj.statuses = this.query.selectedStatus.map((status) => ({
          id: status.id,
        }));
      }
      return paramsObj;
    },
    asyncFindWorker: _.debounce(function (query) {
      if (query === "") return;
      const params = {
        ...{
          query,
          // gender: this.selectedGender && this.selectedGender.key
        },
        originallySelectedWorker: "",
      };
      this.findAgencyWorker(params);
    }, 400),
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    onDisposeModal() {
      this.modalSize = null;
      this.modalVisible = false;
    },
    getFiltersUsed(message) {
      if (message.filters) {
        const arrayFilters = Object.entries(message.filters);
        return this.parseMessageFiltersHtml(arrayFilters);
      }
      return null;
    },
    parseMessageFiltersHtml(filters = []) {
      const div = document.createElement("div");
      div.classList.add("custom-filters-list");
      for (const [fKey, fValArr] of filters) {
        const ul = document.createElement("ul");
        const label = document.createElement("label");
        label.innerText = fKey.slice(0, 1).toUpperCase() + fKey.slice(1);
        ul.appendChild(label);

        for (const value of fValArr) {
          const li = document.createElement("li");
          li.innerText = value;
          ul.appendChild(li);
        }

        div.appendChild(ul);
      }
      return div;
    },
    onShowFiltersUsed(message) {
      this.modalSize = 850;
      if (message.filters) {
        const arrayFilters = Object.entries(message.filters);
        this.showModal(
          "NotificationFiltersUsed",
          this.parseMessageFiltersHtml(arrayFilters).outerHTML,
          `Selected filters content`
        );
      }
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    handleErrors(error) {
      const errs = parseErrors(error);
      if (errs.length === 0)
        this.$toasted.error(error.response.data.message).goAway(3000);
      if (Array.isArray(errs)) {
        for (let i = 0; i < errs.length; i++) {
          this.$toasted.error(errs[i]).goAway(3000);
        }
      } else {
        this.$toasted.error(errs).goAway(3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#notification-centre-form.section {
  display: flex;
  flex-direction: column;
  gap: 3em;
  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1em 2em;
  }
  tbody {
    td,
    th {
      width: 5%;
      &.content {
        width: 80%;
        overflow: auto;
        p {
          width: 100%;
        }
      }

      .control2 {
        min-width: 100px;
      }
    }
  }
  .editor-wrap {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 100%;
    .input-bar {
      display: flex;
      gap: 1em;
    }

    .editor-signature {
      .signature {
        padding: 12px 15px;
        resize: none;
        border: 1px solid rgb(204, 204, 204);
        border-top: none;
        .remove-wrap {
          display: flex;
          align-items: center;
          gap: 0.5em;
          span {
            font-weight: 600;
            font-size: 0.9em;
            color: rgb(219, 48, 48);
            cursor: pointer;
          }
        }
      }
    }
    .attachments {
      display: flex;
      gap: 1em;
      .attachment {
        display: flex;
        gap: 1em;
      }
    }
    .signatures {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      span {
        font-size: 1.1em;
        font-weight: 600;
        color: var(--color-primary);
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.3em;
        img {
          width: 1em;
          height: 1em;
        }
      }
    }
    .buttons {
      display: flex;
      gap: 1em;
      label {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
          "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      }
    }
  }
}
.control {
  width: 270px;
  .error {
    display: inline-block;
    color: red;
    padding-top: 5px;
    padding-left: 8px;
  }
}
.notification-center-pagination {
  :deep(.pagination-list) {
    list-style: none !important;
    margin: 0;
  }
}
</style>

<style lang="scss">
/* margin fix */
#notification-centre-form.section {
  .multiselect__content {
    margin: 0;
  }

  .ql-tooltip {
    // bottom: 100px !important;
    left: calc(50% - (307px / 2)) !important;
    height: max-content;
  }
  .ql-formats {
    .ql-wrap {
      margin-bottom: -3px;
      margin-top: 2px;
    }
  }
  .editor-signature {
    &.hide-bottom-border {
      .ql-container {
        border-bottom: none;
      }
    }
  }
}
</style>
