<template lang='pug'>
.bulk-upload-wrap
  .top-action(:class="{ center: !isDisplayShiftDataTable }")
    .left-side
      button.button.is-generic-app-teal(
        :class="{ 'is-loading': hasInitialBlockLoading }",
        :disabled="hasInitialBlockLoading || isBlockedLoadingFromInterval",
        v-if="!isAllFilesValidForUpload",
        @click="onClickFilePicker"
      ) Upload shifts
      ul(v-if="filesToBeUploaded.length && !progressData.pending")
        bulk-upload-file-row(
          v-for="(file, fIndex) in filesToBeUploaded",
          :key="fIndex",
          :file="file",
          @remove="removeFile"
        )
        //- :isValid="checkValidFile(file)",
      template(v-if="isAllFilesValidForUpload")
        button.button.is-generic-app-teal(
          :class="{ 'is-loading': isUploadingFile }",
          :disabled="isUploadingFile || isBlockedLoadingFromInterval",
          v-if="!progressData.pending",
          @click="onClickUploadFile"
        ) Upload file
    .right-side(v-if="isDisplayShiftDataTable")
      bulk-upload-download-csv(page-sub="open-shifts")
  .center-action
    bulk-upload-shift-list(
      :list="shiftData",
      :progressData="progressData",
      shiftType="open-shifts",
      v-if="isDisplayShiftDataTable"
    )
    .progress-message(
      v-if="!hasInitialBlockLoading && (isBlockedLoadingFromInterval || filesToBeUploaded.length)"
    )
      .desc(v-if="isAlreadyLoadingInternal")
        span Shift creation in progress. Please wait before trying to upload a new file.
      bulk-upload-progress-wrap(:progressData="progressData")
  .bottom-action 
  input.input-file(
    type="file",
    ref="inputUpload",
    :disabled="isBlockedLoadingFromInterval",
    :multiple="false",
    @change="onChangeFileUpload"
  )
  //- :accept="acceptedFormat",
</template>

<script>
import { mapActions } from 'vuex';
import BulkUploadFileRow from './BulkUploadFileRow.vue';
import BulkUploadShiftList from './BulkUploadShiftList.vue';
import BulkUploadProgressWrap from './BulkUploadProgress/BulkUploadProgressWrap.vue';
import BulkUploadDownloadCsv from './BulkUploadDownloadCsv.vue';
import { FILE_UPLOAD_STATUS } from './UploaderConstants.js';
export default {
  name: 'bulkUploadOpenShifts',
  components: {
    BulkUploadFileRow,
    BulkUploadShiftList,
    BulkUploadDownloadCsv,
    BulkUploadProgressWrap,
  },
  data () {
    return {
      filesToBeUploaded: [],
      // validExtensionFormat: ['csv', 'xls', 'xlsx'],
      errorList: [],
      progressArr: [],
      shiftData: [],
      progressInterval: null,
      hasInitialBlockLoading: true,
      isUploadingFile: false,
      undead: false,
    };
  },
  computed: {
    // acceptedFormat () {
    //   return this.validExtensionFormat.map(ext => `.${ext}`).join(',')
    // },
    isAllFilesValidForUpload () {
      // return this.filesToBeUploaded.length && this.filesToBeUploaded.every(fileObj => this.checkValidFile(fileObj))
      return this.filesToBeUploaded.length;
    },
    isAlreadyLoadingInternal () {
      const foundPending = this.progressArr.find(pa => pa.status === 'pending');
      return Boolean(foundPending);
    },
    isBlockedLoadingFromInterval () {
      // Used for manual override [ >> from pending ]
      return this.hasInitialBlockLoading || this.isAlreadyLoadingInternal;
      // return false
    },
    hasShiftData () {
      return this.shiftData && this.shiftData.length;
    },
    isDisplayShiftDataTable () {
      return !this.isBlockedLoadingFromInterval && this.hasShiftData;
    },
    progressData () {
      const foundPending = this.progressArr.find(pa => pa.status === 'pending') || {};
      const foundCompleted = this.progressArr.find(pa => pa.status === 'completed') || {};
      const foundError = this.progressArr.find(pa => pa.status === 'error') || {};
      const foundTotal = this.progressArr.find(pa => pa.status === 'total') || {};
      const progressObj = {
        pending: foundPending.count || 0,
        completed: foundCompleted.count || 0,
        error: foundError.count || 0,
        total: foundTotal.count || 0,
      };
      return progressObj;
    },
  },
  watch: {
    isBlockedLoadingFromInterval (val, oldVal) {
      if (val && !oldVal) {
        // Reset table data if a process is started somewhere
        console.log('[Info] >> Reseted table data on progress update');
        this.resetTableData();
      }
    },
  },
  mounted () {
    this.getCurrentMetaDataProgress({ metaOnly: true });
  },
  deactivated () {
    // Maybe not used
    this.clearIntervalFunc(true);
  },
  beforeDestroy () {
    this.undead = true;
    this.clearIntervalFunc(true);
  },
  destroyed () {
    this.clearIntervalFunc(true);
  },
  methods: {
    ...mapActions({
      getBulkUploadProgressOpenShifts: 'getBulkUploadProgressOpenShifts',
      postFileShiftsOpenShifts: 'postFileShiftsOpenShifts',
    }),
    async setupPageIntervalProgress (isSetIntervalOnly = false, timeout = 5000) {
      if (!isSetIntervalOnly) {
        await this.checkShouldLoadInfo();
      }
      this.clearIntervalFunc();

      this.progressInterval = setTimeout(() => {
        // Behaves like interval > called withing func

        // Pause interval for API duration
        this.clearIntervalFunc();
        this.getCurrentMetaDataProgress({ metaOnly: true });
      }, timeout);
    },
    async getCurrentMetaDataProgress (params = {}) {
      // Supported keys:
      // info: true,
      // metaOnly: true,
      // showErrors: false
      if (this.undead) {
        console.log('[Error] - Undead component invoked');
        // This is because it seems axios revives the function and the component triggering the recurion
        return;
      }

      // Check if Shift Data is blank and when combined with all completed progress
      // Force [data] download [ This case can be used when mounting comp or when initial file uploaded ]
      const isNeededToDownloadData = () => !this.hasShiftData && this.progressData.total && !this.progressData.pending;
      if (isNeededToDownloadData()) {
        params.info = true;
        params.metaOnly = false;
        params.showErrors = true;
      }

      try {
        const res = await this.getBulkUploadProgressOpenShifts(params);
        if (Object.prototype.hasOwnProperty.call(params, 'info')) {
          // If called from non-meta check
          this.shiftData = res.data.data;
        }
        this.progressArr = res.data.meta;

        // Initial block on load before any API whole page
        this.hasInitialBlockLoading = false;

        if (isNeededToDownloadData()) {
          // Reset file upload dialog
          this.filesToBeUploaded = [];

          // Immediate data download or immediate check
          this.getCurrentMetaDataProgress();
        } else {
          this.setupPageIntervalProgress(true);
        }
      } catch (err) {
        console.log(err.message);

        // Initial block on load before any API whole page
        this.hasInitialBlockLoading = false;
      }
    },
    async checkShouldLoadInfo () {
      if (this.isBlockedLoadingFromInterval) {
        // Check meta
        await this.getCurrentMetaDataProgress({ metaOnly: true });
      } else {
        // Check data + meta
        await this.getCurrentMetaDataProgress({
          info: true,
          metaOnly: false,
          showErrors: true,
        });
      }
    },
    getProgressIdFromLabel (str) {
      const fObj = FILE_UPLOAD_STATUS.find(fs => fs.label === str);
      if (fObj) {
        return fObj;
      }
      console.warn('Invalid progress label');
      return {};
    },
    onClickFilePicker () {
      this.$refs.inputUpload.click();
    },
    onChangeFileUpload (evt) {
      this.filesToBeUploaded = [...evt.target.files];
      // Clear the input so we can reupload same file [If there is a need for that]
      this.$refs.inputUpload.value = '';
      this.resetTableData();

      // Clear progress and check interval from previous table data
      this.clearIntervalFunc();
      this.progressArr = [];

      // Clear previous file table value
      this.errorList = [];
    },
    // checkValidFile (fileObj) {
    //   const firstCheckExt = this.checkValidFormatExtension(fileObj)
    //   const secondCheckHeaders = true // Not used
    //   return firstCheckExt && secondCheckHeaders
    // },
    // checkValidFormatExtension (fileObj) {
    //   const fileExt = this.getFileExtension(fileObj.name)
    //   return this.validExtensionFormat.includes(fileExt)
    // },
    removeFile (fileEvt) {
      // Removes all files that have similar timestamp [Timestamp edge case]
      this.filesToBeUploaded = this.filesToBeUploaded.filter(file => file.lastModified !== fileEvt.lastModified);
    },
    getFileExtension (name) {
      const splitted = name.split('.');
      if (splitted.length >= 2) {
        // If there is at least one dot
        return splitted[splitted.length - 1];
      }
      return null;
    },
    onClickUploadFile () {
      this.uploadFileShifts();
    },
    async uploadFileShifts () {
      // Only takes one file from Arr
      const fdParams = new FormData();
      fdParams.append('file', this.filesToBeUploaded[0]);

      this.isUploadingFile = true;

      try {
        const res = await this.postFileShiftsOpenShifts(fdParams);
        this.errorList = res.data.errorList;
        this.$toasted.info(res.data.message).goAway(2000);
        this.isUploadingFile = false;
        this.setupPageIntervalProgress(true, 0);
      } catch (err) {
        console.log(err.message);
        this.isUploadingFile = false;
        const defaultErrMsg = 'Unexpected error while trying to upload file.';
        const errorMsg = err.response && err.response.data && err.response.data.message || defaultErrMsg;
        this.$toasted.error(errorMsg).goAway(3500);
      }
    },
    resetTableData () {
      this.shiftData = [];
    },
    clearIntervalFunc (isShowConsole = false) {
      if (isShowConsole) {
        // This is when comp is inactive
        // console.log('Clearing bulk upload timeout', this.progressInterval);
      }
      clearTimeout(this.progressInterval);
      // clearInterval(this.progressInterval)
      this.progressInterval = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-upload-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 10px;

  .top-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;

    &.center {
      justify-content: center;
    }

    .left-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  .center-action {
    overflow: hidden;
  }

  .bottom-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .progress-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .input-file {
    visibility: collapse;
    position: absolute;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}
</style>