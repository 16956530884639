<template lang="pug">
.location-client-management
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    icon="",
    size="600",
    :scrollable="true"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false"
    ) 
  section.section
    h1.title.is-3
      i.fa.icon-Location-client-management
      | Client Management
    .spacer-top
      .columns
        .column.is-3
          .field
            p.control
              input.input(
                @input="fireSearch",
                v-model="searchName",
                placeholder="Search clients",
                title="Search clients"
              )
              i.fa.fa-search
        .column.is-2
          multi-select(
            :options="statusesOptions",
            :multiple="false",
            :close-on-select="true",
            select-label="",
            deselect-label="",
            :allow-empty="false",
            track-by="id",
            label="name",
            v-model="searchStatus",
            placeholder="Filter by status",
            title="Filter by status",
            @select="fireSearch"
          )
        .column.is-7
          button.button.is-generic-app-light-blue.new-document.is-pulled-right(
            data-cell="new-client-button",
            v-if="gPermissions.canClientProfileCreate",
            @click="addClient"
          )
            img(src="../../assets/images/comps/Plus.svg")
            span(style="margin-left: 5px") NEW CLIENT
    //- .block.is-pulled-right.controls
      span
        button.button.is-generic-app-light-blue.new-document(
          @click="addClient"
        )
          img(src="../../assets/images/comps/Plus.svg")
          span(style="margin-left: 5px;") NEW CLIENT
    .table-wrap
      transition(name="fade")
        clients-table(
          :clients="clients",
          v-if="!this.loading",
          @on-show-modal="onShowModal",
          @changed-status="onChangedStatus"
        ) 
  .loading-container.center(v-if="loading")
    img(src="../../assets/images/comps/loader.svg")
  pagination(
    v-if="pagination",
    :total-pages="pagination.total_pages",
    :current-page="currentPage",
    @page-changed="pageHasChanged"
  )
</template>
<script>
import axios from "axios";
import ClientsTable from "./ClientsTable.vue";
import NewClientModalContent from "../in-modal/location-client-mgmt/NewClient.vue";
import MultiSelect from "vue-multiselect";
import ClientCopyCostCenter from "./ClientCopyCostCenter.vue";

import _ from "underscore";
import Pagination from "../Pagination.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "locationClientManagement",
  components: {
    ClientsTable,
    NewClientModalContent,
    ClientCopyCostCenter,
    MultiSelect,
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      cancelToken: null,
      searchName: "",
      searchStatus: {
        id: 1,
        value: null,
        name: "All",
      },
      statusesOptions: [
        {
          id: 1,
          value: null,
          name: "All",
        },
        {
          id: 2,
          value: true,
          name: "Active",
        },
        {
          id: 3,
          value: false,
          name: "Inactive",
        },
      ],
      modalVisible: false,
      loading: false,
    };
  },
  activated() {
    console.log("[KA] - >> Location Client");
  },
  mounted() {
    console.log("[INIT] - >> Location Client");

    this.loading = true;
    this.clearUsers();
    if (this.gPermissions.canViewRulesCalcEng) {
      this.fetchAllowances();
    }
    this.fetchUsers();
    this.fetchServiceFeeTypes();
    this.currentPage = parseInt(this.$route.query.page, 10) || 1;
    this.fetchClientWrap({ page: this.currentPage });
  },
  computed: {
    ...mapGetters({
      clients: "locationClientManagement/getClients",
      categories: "getAllCategories", // job types
      pagination: "locationClientManagement/getPagination",
    }),
  },
  methods: {
    ...mapActions({
      fetchClients: "locationClientManagement/fetchClientsOptimized",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchAllowances: "locationClientManagement/fetchAllowances",
      fetchUsers: "fetchUsers",
      clearUsers: "clearUsers",
      fetchServiceFeeTypes: "locationClientManagement/fetchServiceFeeTypes",
    }),
    onShowModal(evt) {
      this.showModal(evt.modal, evt.modalData, evt.modalTitle);
    },
    onChangedStatus() {
      this.callFilteringApi();
    },
    addClient() {
      console.log("addClient fired.");
      // TODO: Create new input row?
      this.showModal("new-client-modal-content", {}, "New Client Form");
    },
    showModal: function (modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    pageHasChanged(pageNum) {
      console.log("Page Change Invoked", pageNum);
      this.loading = true;
      this.currentPage = pageNum;
      this.fetchClientWrap();
    },
    fireSearch: _.debounce(function () {
      this.callFilteringApi();
    }, 300),
    async callFilteringApi() {
      this.currentPage = 1;
      this.$set(this.$route.query, "page", 1);

      this.loading = true;
      if (this.cancelToken !== null) {
        this.cancelToken.cancel();
        this.cancelToken = null;
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelToken = source;
      const params = {
        cancelTokenSource: source,
      };
      // Needs to be cancellable API for token
      this.fetchClientWrap(params);
    },
    async fetchClientWrap(defParams = {}) {
      const params = Object.assign({}, defParams, {
        page: defParams.page || this.currentPage,
        search: this.searchName || null,
        active: this.searchStatus && this.searchStatus.value,
      });
      try {
        await this.fetchClients(params);
        this.currentPage = this.pagination.current_page; // Get page from API
        this.loading = false;
      } catch (err) {
        console.log("Error fetching clients", err.message);
        // this.loading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.location-client-management {
  > section {
    height: 100%;
    display: flex;
    flex-direction: column;

    .table-wrap {
      display: flex;
      overflow: auto;
      height: 100%;

      table {
        height: fit-content;
      }
    }
  }
}

.loading-container {
  width: 100%;
}

.spacer-top {
  margin: 0 0 20px 0;
}

input {
  box-shadow: none;
  border: 1px solid #9bb4c1;
  height: 37px;
}

.control {
  i {
    top: 10px;
    position: absolute;
    right: 15px;
    font-size: 14px;
  }
}

.center {
  text-align: center;
  height: 100px;
  background: #f0f6f6;

  img {
    width: 50px;
    height: 100px;
  }
}
</style>
