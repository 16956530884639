<template lang="pug">
#client-approvers-info
  .spacer-top
    .columns
      .column.is-3
        .field
          p.control
            input.input(
              v-model="searchName",
              placeholder="Search by name",
              @input="onCallSearchFilter"
            )
            i.fa.fa-search
  section
    client-approvers-table(
      :loading="loading",
      :users="users",
      :sectors="sectors",
      :includes="includes.join(',')",
      @patch-user="onPatchUser"
    )
    pagination(
      v-if="pagination",
      :total-pages="pagination.total_pages",
      :current-page="currentPage",
      @page-changed="onPageHasChanged"
    )
</template>

<script>
import _ from "underscore";
import ClientApproversTable from "./Approvers/ClientApproversTable.vue";
import Pagination from "../Pagination.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ClientApproversInfo",
  components: {
    ClientApproversTable,
    Pagination,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchName: "",
      users: [],
      loading: false,
      currentPage: 1,
      includes: ["roles", "clientLocationCount", "clientSubcategoryCount"],
      selectedSortColumn: {
        column: "name", // Back requires this sort for double sorting
        // column: 'status',
        // column: 'active',
        direction: "asc",
      },
    };
  },
  computed: {
    ...mapGetters({
      pagination: "getPagination",
    }),
    sectors() {
      return [
        {
          name: "",
          id: 1,
          clients: [this.clientData],
        },
      ];
    },
  },
  mounted() {
    this.fetchApprovers({});
  },
  methods: {
    ...mapActions({
      fetchUsers: "fetchUsers",
    }),
    async fetchApprovers({ page = 1 }) {
      this.currentPage = page;
      this.$set(this.$route.query, "page", page);

      let fetchParams = Object.assign(
        {},
        {
          statusOverrideSort: true, // Special sort by >> ['action', 'name']
          sort_by_field: this.selectedSortColumn.column || null,
          sort_by_direction: this.selectedSortColumn.direction || null,
          clients: [this.clientData.id],
          include: this.includes.join(","),
          name: this.searchName || null,
          page,
        }
      );

      try {
        this.loading = true;
        const res = await this.fetchUsers(fetchParams);
        this.users = res.data.data;
        this.loading = false;
      } catch (err) {
        console.log("Error", err.message);
        this.loading = false;
      }
    },
    onCallSearchFilter: _.debounce(function () {
      this.callSearchApi();
    }, 500),
    onPatchUser() {
      const payload = {
        page: this.currentPage,
      };
      this.fetchApprovers(payload);
    },
    onPageHasChanged(pageNum) {
      this.currentPage = pageNum;
      this.fetchApprovers({ page: this.currentPage });
    },
    async callSearchApi() {
      this.fetchApprovers({});
    },
  },
};
</script>

<style lang="scss" scoped>
#client-approvers-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 20px;

  .spacer-top {
    input {
      box-shadow: none;
      border: 1px solid #9bb4c1;
      height: 37px;
    }

    .control {
      i {
        top: 10px;
        position: absolute;
        right: 15px;
        font-size: 14px;
      }
    }
  }
}
</style>
