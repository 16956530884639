<template lang='pug'>
multi-select.sector-select(
  :options="sectors",
  :value="selectedSector",
  placeholder="Select sector",
  track-by="id",
  label="name",
  select-label="",
  deselect-label="",
  v-on="inputListeners"
)
</template>

<script>
import MultiSelect from 'vue-multiselect';
export default {
  inheritAttrs: false,
  components: {
    MultiSelect,
  },
  props: {
    sectors: {
      type: Array,
      default: () => [],
    },
    selectedSector: [Object, null],
  },
  computed: {
    inputListeners () {
      return { ...this.$listeners };
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>