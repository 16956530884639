<template lang="pug">
.permission-list
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  checkbox-list-menu(
    :class="{ filtered: this.searchVal }",
    :list="transformedList",
    :changed-checked-list="changedCheckedList",
    :checked-list="checkedItemIds",
    :expanded-items="expandedItems",
    :is-disabled-list="isLockedInputs",
    :is-vertical-list="false",
    :display-children-count="true",
    :depth-classes="depthClasses",
    @click="onClickedItem",
    @checked="onCheckedItem"
  )
  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      :disabled="!isLockedInputs",
      v-if="isLockedInputs",
      @click.prevent="isLockedInputs = false"
    ) Unlock
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      :disabled="isLockedInputs || changedCheckedList.length === 0 || isSaveLoading",
      @click.prevent="onClickSave"
    ) Save
</template>

<script>
export default {
  name: "PermissionListManage",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [Number, String],
      required: true,
    },
    resetCountInvoker: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checkedItemIds: [],
      expandedItems: [],
      isSaveLoading: false,
      isLockedInputs: true,
    };
  },
  computed: {
    searchVal() {
      return this.$route.query.q || "";
    },
    transformedList() {
      const searchLower = this.searchVal?.trim().toLowerCase();
      // Using hidden 'ss' query for debugging

      const recFilter = (
        arr = [],
        includeParentNode = true,
        hideEmptyPar = false
      ) => {
        return arr.reduce((acc, item) => {
          if (includeParentNode) {
            const labelLower =
              "ss" in this.$route.query
                ? item.name.toLowerCase()
                : `${item.label.toLowerCase()}${item.name.toLowerCase()}`; // Filtering both at the same time
            if (labelLower.includes(searchLower)) {
              const newItem = {
                ...item,
                ogName: item.name,
                name:
                  "ss" in this.$route.query
                    ? item.name
                    : item.label || item.name,
                children: item.permissions,
              };
              delete newItem.permissions;
              acc.push(newItem);
            }
          } else if (item.permissions?.length) {
            const filteredPerms = recFilter(item.permissions);
            if (
              filteredPerms.length ||
              (!hideEmptyPar && !filteredPerms.length)
            ) {
              const newItem = {
                ...item,
                ogName: item.name,
                name: this.$route.query.ss
                  ? item.name
                  : item.label || item.name,
                id: "parent-cat-" + item.id,
                children: filteredPerms,
              };
              delete newItem.permissions;
              acc.push(newItem);
            }
          }
          return acc;
        }, []);
      };

      return recFilter(this.list, false, true);
    },
    groupIds() {
      return this.transformedList.map((group) => group.id);
    },
    originalCheckedList() {
      return this.list.reduce((acc, curr) => {
        if (curr.permissions?.length) {
          const originalCheckedList = curr.permissions
            .filter((item) => item.checked)
            .map((item) => item.id);
          acc.push(...originalCheckedList);
        }
        return acc;
      }, []);
    },
    changedCheckedList() {
      const firstChanged = this.originalCheckedList.filter(
        (id) => !this.checkedItemIds.includes(id)
      );
      const secondChanged = this.checkedItemIds.filter(
        (id) =>
          !this.originalCheckedList.includes(id) && !this.groupIds.includes(id)
      );

      const changedIds = firstChanged.concat(secondChanged);
      return [...new Set(changedIds)];
    },
    depthClasses() {
      return {
        0: ["flex-column"],
        1: ["flex-column"],
      };
    },
  },
  watch: {
    list: {
      handler() {
        this.setCheckedItems();
      },
      deep: true,
    },
    changedCheckedList: {
      handler() {
        this.$emit("changed", this.changedCheckedList.length);
      },
      deep: true,
    },
    resetCountInvoker(val) {
      if (val) {
        const previousItems = this.checkedItemIds.filter(
          (id) => !this.changedCheckedList.includes(id)
        );
        const newItems = this.changedCheckedList.filter(
          (id) => !this.checkedItemIds.includes(id)
        );
        this.checkedItemIds = previousItems.concat(newItems);
      }
    },
  },
  mounted() {
    this.expandChildren();
    this.setCheckedItems();
  },
  methods: {
    onClickedItem(evt) {
      if (evt.children?.length) {
        const itemIndex = this.expandedItems.findIndex(
          (eItem) => evt.id === eItem
        );
        if (itemIndex !== -1) {
          this.expandedItems.splice(itemIndex, 1);
        } else {
          this.expandedItems.push(evt.id);
        }
      } else {
        // QoL toggle
        this.onCheckedItem({
          item: evt,
        });
      }
    },
    onCheckedItem(evt) {
      if (this.isLockedInputs) {
        return;
      }

      const isItemChecked =
        this.checkedItemIds.findIndex((cItem) => cItem === evt.item.id) !== -1;

      let childrenIds = [];
      const children = evt.item.children;
      if (children?.length) {
        childrenIds = this.getAllIdsRec(children);
      }
      const isAllChildrensChecked = childrenIds.every((childId) =>
        this.checkedItemIds.includes(childId)
      );

      const checkItemAndChildren = () => {
        const selSet = new Set(this.checkedItemIds);
        selSet.add(evt.item.id);

        for (const id of childrenIds) {
          selSet.add(id);
        }
        this.checkedItemIds = Array.from(selSet);
      };

      const uncheckItemAndChildren = () => {
        this.checkedItemIds = this.checkedItemIds.filter(
          (cItem) => cItem !== evt.item.id && !childrenIds.includes(cItem)
        );
      };

      if (evt.checked) {
        checkItemAndChildren();
      } else if (evt.checked === false) {
        uncheckItemAndChildren();
      } else if (!isItemChecked) {
        if (children?.length && !isAllChildrensChecked) {
          checkItemAndChildren();
        } else if (!children?.length) {
          checkItemAndChildren();
        }
      } else {
        uncheckItemAndChildren();
      }
    },
    getAllIdsRec(arr = []) {
      return arr.reduce((acc, curr) => {
        acc.push(curr.id);
        if (curr.children?.length) {
          const ids = this.getAllIdsRec(curr.children);
          acc.push(...ids);
        }
        return acc;
      }, []);
    },
    async onClickSave() {
      try {
        await this.confirmActionAlert("save changed permissions?");
        this.isSaveLoading = true;
        this.$emit("click-save", this.checkedItemIds);
      } catch (err) {
        // ignored
      }
      this.isSaveLoading = false;
    },
    expandChildren() {
      this.expandedItems = this.transformedList.map((item) => item.id);
    },
    setCheckedItems() {
      this.checkedItemIds = [...this.originalCheckedList];
    },
    confirmActionAlert(name = `apply changes?`, fullText = "") {
      const title = fullText || `Are you sure you want to ${name}`;
      return new Promise((resolve) => {
        const alert = {
          title,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.permission-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > .checkbox-list-menu {
    overflow: auto;
    height: 100%;
    padding: 0 2px 0 0;
    gap: 10px;

    &.root {
      &::v-deep {
        &.filtered {
          > .node.expanded {
            min-height: 100px;
          }
        }
        &:not(.filtered) {
          > .node.expanded {
            min-height: 200px;
          }
        }
        .node {
          max-height: 300px;
        }
      }
    }
  }
}
</style>
