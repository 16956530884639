<template lang="pug">
#client-contact-edit
  form(
    autocomplete="off",
    @submit.prevent="onSave",
    @keydown="errors.clear($event.target.name)"
  )
    .field
      label Name
      input.input(
        v-model="form.name",
        placeholder="Name",
        name="name",
        type="text"
      )
      span.help.is-danger(
        v-if="errors.has('name')",
        v-html="errors.get('name')"
      )
    .field
      label Title
      multi-select.column-select(
        :options="contactTitles",
        :searchable="true",
        :options-limit="999",
        select-label="",
        selected-label="",
        deselect-label="",
        track-by="id",
        label="name",
        :multiple="false",
        placeholder="Contact Title",
        name="contact_type_id",
        :value="parseContactTitle",
        @select="onContactTitleSelect"
      )
      span.help.is-danger(
        v-if="errors.has('contact_type_id')",
        v-html="errors.get('contact_type_id')"
      )
    .field
      label Phone Number
      input.input(
        v-model="form.phone",
        placeholder="Phone number",
        name="phone",
        type="text"
      )
      span.help.is-danger(
        v-if="errors.has('phone')",
        v-html="errors.get('phone')"
      )
    .field
      label Email
      input.input(
        v-model="form.email",
        placeholder="Email",
        name="email",
        type="email"
      )
      span.help.is-danger(
        v-if="errors.has('email')",
        v-html="errors.get('email')"
      )
    .field
      label Unit
      multi-select.column-select(
        :options="locations",
        :searchable="true",
        :options-limit="999",
        select-label="",
        selected-label="",
        deselect-label="",
        track-by="id",
        label="name",
        :multiple="true",
        placeholder="Unit",
        name="locations",
        :value="form.locations",
        @select="onLocationSelect",
        @remove="onRemoveLocation"
      )
      span.help.is-danger(
        v-if="errors.has('locations')",
        v-html="errors.get('locations')"
      )
    .field
      label Note
      textarea.input(
        v-model="form.note",
        placeholder="Note",
        name="note",
        type="text"
      )
      span.help.is-danger(
        v-if="errors.has('note')",
        v-html="errors.get('note')"
      )
    .buttons-centered
      button.button.is-small.is-generic-app-blue.is-caps-lock(
        @click.once.prevent="onSave",
        :disabled="isLoading"
      )
        .loading-wrapper(v-if="isLoading")
          img(src="../../../assets/images/comps/loader.svg")
        span Save
      button.button.is-small.is-outlined.is-caps-lock(
        @click.prevent="onCancel"
      ) Cancel
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";
import _ from "underscore";
import { Errors } from "../../../lib/helpers/Errors";
export default {
  components: {
    MultiSelect,
  },
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        client_id: this.modalProps.client_id,
        ...this.modalProps.contact,
        locations: [],
      },
      contactTitles: [],
      locations: [],
      errors: new Errors(),
      isLoading: false,
    };
  },
  computed: {
    unfilteredContactTitles() {
      return this.modalProps.contactTypes || [];
    },
    parseContactTitle() {
      const foundCt = this.unfilteredContactTitles.find(
        (ct) => ct.id === this.form.contact_type_id
      );
      if (foundCt) {
        return foundCt;
      }
      return null;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      putClientContacts: "putClientContacts",
      fetchClientDetails: "locationClientManagement/fetchClientDetails",
      getClientContactsTypesList: "getClientContactsTypesList",
    }),
    async init() {
      await this.getClientLocations();
      await this.fetchContactTitles();
      this.mapExistingLocations();
    },
    async getClientLocations() {
      try {
        const params = {
          id: this.modalProps.client_id,
          include: "locations",
        };
        const res = await this.fetchClientDetails(params);
        this.locations = res.data.data.locations;
      } catch (err) {
        console.log(err.message);
      }
    },
    async fetchContactTitles(params = {}) {
      try {
        const res = await this.getClientContactsTypesList(params);
        this.contactTitles = res.data.data;
      } catch (err) {
        console.log(err.message);
      }
    },
    mapExistingLocations() {
      if (this.modalProps.contact.locations) {
        for (let i = 0; i < this.modalProps.contact.locations.length; i++) {
          const locObj = {
            name: this.modalProps.contact.locations[i].name,
            id: this.modalProps.contact.locations[i].id,
          };
          this.form.locations.push(locObj);
        }
      }
    },
    onSave() {
      this.saveAction();
    },
    onCancel() {
      this.$emit("cancel-modal");
    },
    onContactTitleSelect(evt) {
      this.errors.clear("contact_type_id");
      this.$set(this.form, "contact_type_id", evt.id);
    },
    onLocationSelect(evt) {
      this.errors.clear("locations");
      const locObj = {
        name: evt.name,
        id: evt.id,
      };
      this.form.locations.push(locObj);
      // this.$set(this.form, "locations", evt)
    },
    onRemoveLocation(evt) {
      this.removeLocation(evt);
    },
    removeLocation(evt) {
      for (let i = 0; i < this.form.locations.length; i++) {
        if (this.form.locations[i].id === evt.id) {
          this.form.locations.splice(i, 1);
          return;
        }
      }
    },
    async saveAction() {
      const payload = _.omit({ ...this.form }, "title");
      this.isLoading = true;
      try {
        await this.putClientContacts(payload);
        this.$emit("refresh-data");
      } catch (err) {
        console.log("Error", err.message);
        if (err.response.data.errors) {
          this.errors.record(err.response.data.errors);
        } else if (err.response.data.message) {
          this.$toasted.error(err.response.data.message).goAway(1500);
        } else {
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(2500);
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#client-contact-edit {
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 5px 5px;

    .field {
      display: flex;
      flex-direction: column;
      gap: 5px;

      input,
      textarea {
        height: 40px;
      }
    }
  }
}
.buttons-centered {
  button {
    display: flex;
    justify-content: space-evenly;
  }
}
.loading-wrapper {
  padding-top: 3px;
  img {
    height: 20px;
    width: 20px;
  }
}
</style>
