<template lang="pug">
div
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :headless="false",
    icon="clock-o",
    size="700"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false",
      @refresh-data="onRefreshData"
    )
  section.section
    h1.title.is-3
      span(style="cursor: pointer", @click="$router.go(-1)")
        svg(
          xmlns="http://www.w3.org/2000/svg",
          width="13",
          height="23",
          viewBox="0 0 13 23"
        )
          path(
            fill="#7591AE",
            stroke="#7991AE",
            d="M1.44295652,10.3728261 L10.8125217,1.00326087 C11.0429565,0.772826087 11.0429565,0.40326087 10.8125217,0.172826087 C10.582087,-0.0576086957 10.2125217,-0.0576086957 9.98208696,0.172826087 L0.195130435,9.95978261 C-0.0353043478,10.1902174 -0.0353043478,10.5597826 0.195130435,10.7902174 L9.98208696,20.5728261 C10.0951304,20.6858696 10.2473043,20.7467391 10.3951304,20.7467391 C10.5429565,20.7467391 10.6951304,20.6902174 10.8081739,20.5728261 C11.0386087,20.3423913 11.0386087,19.9728261 10.8081739,19.7423913 L1.44295652,10.3728261 Z",
            transform="translate(1 1)"
          )
      span.sep
      i.fa.icon-Compliance-management
      |
      | Agency Worker Documents
    table.table.is-striped
      thead
        tr
          th DOCUMENT
          th EXPIRY DATE
          th SUBMISSION DATE
          th UPLOADED
          th STATUS
          th ACTION
          th VIEW
      tfoot
      tbody(v-if="isLoading")
        tr
          td.is-loading(colspan="7")
            img(src="../../assets/images/comps/loader.svg")
      tbody(v-else)
        tr(v-if="docs", v-for="doc in docs")
          td(data-cell="name") {{ doc.name }}
          td(
            data-cell="expiryDate",
            v-if="doc.expiryDate && doc.canEditExpiry"
          )
            button.button.is-danger.is-tiny.is-low.is-outlined(
              v-tooltip="'Click to set new expiry date.'",
              @click="showModal('change-expiry-date', { id: doc.id, temp: doc.temp, expiryDate: doc.expiryDate, context: 'tempDocuments' }, 'Set new expiry date')"
            ) {{ expiryDate(doc) }}
          td(
            data-cell="expiryDate",
            v-else-if="doc.expiryDate && !doc.canEditExpiry"
          ) {{ expiryDate(doc) }}
          td(data-cell="notExpiryDate", v-else) -
          td(data-cell="submissionDate") {{ submissionDate(doc) }}
          td(data-cell="uploaded") {{ doc.filesCount ? "Yes" : "No" }}
          td(data-cell="status")
            .flexcell
              transition(name="zoom", appear)
                span.dot(:style="{ backgroundColor: status(doc.status).hex }")
              span.generic-app-tag.is-caps-lock.text-smaller {{ status(doc.status).label }}
          td.button-group(data-cell="action-btn", v-if="doc.canUpload")
            //- depending on weather it hasExpiry, we provide an additional input in modal to set expiry date for files uploaded
            button.button.is-caps-lock.is-generic-app-blue.is-tiny.is-low.clickable(
              @click="showModal('upload-temp-documents', { docId: doc.id, tempId: doc.temp.id, hasExpiry: doc.expiration_months > 0 ? true : false, expiryDate: doc.expiryDate }, 'Upload temp documents')"
            ) upload
            button.button.is-caps-lock.is-generic-app-blue.is-tiny.is-low(
              @click="approve({ tempId: doc.temp.id, documentId: doc.id, modifiers: { context: 'tempDocuments', remove: false, action: 'validate' } })",
              v-show="!doc.isApproved && doc.filesCount"
            ) approve
            //- TODO make conditional for show/hide approve/reject buttons depend on status instead.
            button.button.is-caps-lock.is-generic-app-reject.is-tiny.is-low.clickable(
              v-show="doc.status.code !== 'REJE' && doc.filesCount && !doc.rejectionReason",
              @click="showModal('reject-temp-document', { tempId: doc.temp.id, documentId: doc.id, modifiers: { context: 'tempDocuments', remove: false, action: 'reject' } }, 'Reject Temp Document')"
            ) reject
          td.button-group(data-cell="action-btn", v-else)
          td
            button.button.is-caps-lock.is-tiny.is-generic-app-blue.is-low.clickable(
              v-show="doc.filesCount && doc.canViewFile",
              @click="onClickCheckFileModal(doc)",
              :class="{ 'is-loading': loadingCheckButtonId === doc.id }"
            ) check
        tr(v-else)
          td.has-text-centered(colspan="7") Loading...
    pagination(
      v-if="pagination && pagination.total_pages",
      :total-pages="pagination.total_pages",
      :current-page="pagination.current_page",
      @page-changed="pageHasChanged"
    )
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getFormattedTime, parseErrors } from "../../lib/helpers/function.js";
import _ from "underscore";

import { STATUS_COLORS } from "../config/colors.js";

import CheckTempDocuments from "../in-modal/compliances/CheckTempDocuments.vue";
import UploadTempDocuments from "../in-modal/compliances/UploadTempDocuments.vue";
import RejectTempDocument from "../in-modal/compliances/RejectTempDocument.vue";

import ChangeExpiryDate from "../in-modal/compliances/ChangeExpiryDate.vue";
import Pagination from "../Pagination.vue";

import { Evt } from "../../lib/helpers/Evt.js";

export default {
  name: "temp-compliance",
  components: {
    CheckTempDocuments,
    UploadTempDocuments,
    RejectTempDocument,
    ChangeExpiryDate,
    Pagination,
  },
  data() {
    return {
      modalVisible: false,
      isLoading: false,
      loadingCheckButtonId: null,
    };
  },
  computed: {
    ...mapGetters({
      docs: "getTempDocuments",
      pagination: "getPagination",
    }),
    // docs () {
    //   return this.$store.state.compliance.tempDocuments
    // }
  },
  created() {
    if (this.$route.params.tempId) {
      this.isLoading = true;
      let params = this.getTempDocumentsParams(1);
      this.fetchTempDocuments(params).then(() => {
        this.isLoading = false;
      });
    } else {
      this.$router.replace("compliance");
    }
  },
  mounted() {
    Evt.listen("disposeModal", () => {
      console.error("called outdated bus");
      this.modalVisible = false;
    });
    Evt.listen("all-files-deleted", this.updateTempDocument);
    this.fetchRejectionReasons();
  },
  beforeDestroy() {
    Evt.off("disposeModal");
    Evt.off("all-files-deleted");
  },
  methods: {
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    onRefreshData() {
      this.pageHasChanged(this.pagination.current_page || 1);
    },
    async onClickCheckFileModal(doc) {
      this.loadingCheckButtonId = doc.id;

      try {
        this.showModal(
          "check-temp-documents",
          { temp: doc.temp.id, document: doc.id },
          "Check Temp Documents"
        );
        this.loadingCheckButtonId = null;
      } catch (err) {
        console.warn(err.message);
        this.loadingCheckButtonId = null;
      }
    },
    getTempDocumentsParams(pageNum = 1) {
      return {
        tempId: this.$route.params.tempId,
        include: "filesCount",
        page: pageNum,
      };
    },

    pageHasChanged(pageNum) {
      let params = this.getTempDocumentsParams(pageNum);
      this.isLoading = true;
      this.fetchTempDocuments(params).then(() => {
        this.isLoading = false;
      });
    },

    status: function (status) {
      const statusObj = _.findWhere(STATUS_COLORS, { code: status.code });
      if (statusObj) {
        return {
          hex: statusObj.hex,
          label: statusObj.label,
        };
      }
      return {};
    },

    submissionDate(doc) {
      if (doc.filesCount) {
        let time = doc.createdAt;
        return getFormattedTime(time, "DD/MM/YYYY HH:mm");
      } else {
        return "-";
      }
    },
    expiryDate(doc) {
      if (doc.expiryDate) {
        return getFormattedTime(doc.expiryDate, "DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    approve(docData) {
      let { tempId, documentId, modifiers } = docData;

      this.approveTempDocument({
        tempId,
        documentId,
        modifiers,
      })
        .then(() => {
          this.$toasted
            .info("Document approved!", { theme: "outline" })
            .goAway(1500);
        })
        .catch((error) => {
          let errs = parseErrors(error);
          this.$toasted.error(errs).goAway(4500);
        });
    },

    updateTempDocument(documentObject, newData) {
      let idx = _.findIndex(this.docs, (doc) => {
        return (
          doc.id === documentObject.document &&
          doc.temp.id === documentObject.temp
        );
      });
      // dispatch an action to change state in store.compliance.tempDocuments!
      this.$store.dispatch("replaceTempDocument", {
        idx: idx,
        data: newData.data,
      });
    },

    ...mapActions([
      "fetchTempDocuments",
      "approveTempDocument",
      "fetchRejectionReasons",
    ]),
  },
};
</script>
<style lang="scss" scoped>
span.sep {
  border: 1px solid #d6e0e5;
  margin: 0 0.8em;
}

.flexcell {
  display: flex;
  align-items: center;
}

.dot {
  border-radius: 7px;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 0.8em;
}

.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

td.button-group {
  .button {
    margin: 0 1em 3px 0;
  }
}

.is-loading {
  text-align: center;
  background: #f2f7fa;
}
</style>
