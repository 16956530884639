<template lang="pug">
.report-preview
  h1.title.is-3 {{ title }}
  h2.subtitle(v-for="subtitle in subtitles") {{ subtitle }}
  report-preview-table(
    v-for="(table, index) in tables",
    :key="index",
    :table="table",
    style="margin-bottom: 20px"
  )
  div(style="width: 100%; height: 50px")
    pagination(
      :total-pages="getPagination.totalPages",
      :current-page="getPagination.currentPage",
      @page-changed="pageHasChanged"
    )
  button.button.is-danger(@click="closeModal") Close
</template>

<script>
import ReportPreviewTable from "./ReportPreviewTable.vue";
import Pagination from "../../Pagination.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["modalProps"],

  data() {
    return {
      data: this.modalProps.previewData,
    };
  },

  computed: {
    ...mapGetters({
      getPagination: "reports/getPagination",
    }),

    title() {
      return this.getPagination.currentPageData["page_name"];
    },

    subtitles() {
      return this.getPagination.currentPageData["page_header"];
    },

    tables() {
      return this.getPagination.currentPageData["tables"];
    },
  },

  methods: {
    ...mapActions({
      fetchPageHasChanged: "reports/fetchPageHasChanged",
    }),

    closeModal() {
      this.$emit("cancel-modal");
    },

    pageHasChanged(pageNum) {
      this.fetchPageHasChanged(pageNum);
    },
  },

  components: {
    ReportPreviewTable,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.report-preview {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 10px;

  button {
    float: right;
    margin-bottom: 15px;
  }

  .title {
    text-align: center;
    margin: 10px 0px;
  }

  .subtitle {
    text-align: center;
    margin: 10px 0px;
  }
}
</style>
