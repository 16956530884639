import TempCalendar from '../../components/TempCalendar/Calendar.vue';
import TempDocument from '../../components/TempDocuments/TempDocument.vue';
import TempProfile from '../../components/TempProfile/Edit.vue';

export default [
  {
    path: '/temp-calendar',
    name: 'temp-calendar',
    component: TempCalendar,
    meta: {
      requiresAuth: true,
      permission: 'view-temp-calendar',
      fail: '/error',
    },
  },
  {
    path: '/temp-documents',
    name: 'temp-documents',
    component: TempDocument,
    meta: {
      requiresAuth: true,
      permission: 'view-temp-documents',
      fail: '/error',
    },
  },
  {
    path: '/temp-profile',
    name: 'temp-profile',
    component: TempProfile,
    meta: {
      requiresAuth: true,
      permission: 'edit-profile',
      fail: '/error',
    },
  }
];
