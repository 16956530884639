import { default as axios } from "../config.js";

const api = {
  /**
   * POST /temps - registering new temp
   */
  registerTemp: (params = {}) => {
    const url = "/temps";
    return axios.post(url, params);
  },
};

export default api;
