export default [
  {
    id: 0, // This will fallthrough
    label: 'All',
    backColor: 'white',
  },
  {
    id: 1,
    label: 'Active',
    backColor: '#23d160',
  },
  {
    id: 2,
    label: 'Review',
    color: 'black',
    backColor: '#ffdb4a',
  },
  {
    id: 3,
    label: 'Inactive',
    backColor: '#ff2b56',
  },
];