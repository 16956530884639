import Vue from "vue";
import Vuex from "vuex";

/**
 * These are the shared ones, extracted into files for readability.
 */
import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

/**
 * View specific states.
 */
import dashboard from "./modules/dashboard.js";
import compliance from "./modules/compliance.js";
import shift from "./modules/shift.js";
import locationClientManagement from "./modules/locationClientManagement.js";
import clientUserManagement from "./modules/clientUserManagement.js";
import calendar from "./modules/calendar.js";
import reports from "./modules/reports.js";
import invoices from "./modules/invoices.js";

Vue.use(Vuex);

/**
 * TODO make this shared state... by moving all non-common stuff into component own modules.
 *
 * all view specfic states will be imported as modules, for
 * a better organization.
 */
const state = {
  message: "Message from Vuex",
  initialStatus: null,
  clients: [],
  clientsShiftActivityTags: [],
  costs: [],
  categories: [],
  allCategories: [], // all available categories
  allRoles: [], // all available Roles
  minRoles: [],
  allSpecialities: [],
  userProfile: [],
  tempProfile: [],
  columnNamesLocl: {
    default: {
      usr_eircode: "Eircode",
    },
    api: [],
  },

  temps: [],

  tempsSelected: [], // remembers state of checked boxes over multiple pages! TODO

  pagination: null, // shared pagination object across all entities
  agency_workers: [], // when async find a worker via the input field, we store them in this array.
  users: [],

  rateSectors: [],
  rateSectorSelected: null,
  rateCategories: [],
  passwordValidationStore: {
    passwordValidation: {
      length: { isValid: false },
      upperCase: { isValid: false },
      lowerCase: { isValid: false },
      number: { isValid: false },
      specialCharacter: { isValid: false },
    },
  }, // Password validation
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    dashboard,
    compliance,
    shift,
    locationClientManagement,
    clientUserManagement,
    reports,
    invoices,
    calendar,
  },
});
