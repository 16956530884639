<template lang="pug">
#client-documents-info
  <modal v-if="modalVisible" @close="modalVisible = false" :title="modalTitle" icon="" size="350">
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false"
    ) 
  </modal>

  edit-popup(
    v-if="popup.show",
    @close="popupClose",
    @editPopup="popup.save",
    :data="popup.data",
    :levels="popup.levels",
    :offset="popup.offset",
    :target="popup.element",
    :isLoading="popup.isLoading",
    :isDisabled="true",
    ref="popup"
  )

  section.section
    table.table.is-striped
      thead
        tr
          th Document name
          th Applicable wards
          th Applicable categories
          th Visibility
          th Has expiry?
          th Automatic expiry?
          th Deadline for push/email?
          th Is optional?
          //- th Info link
      tfoot
      tbody
        tr(
          v-if="docs && !loading",
          :id="id",
          v-for="{ id, name, locationCount, categoryCount, categories, roles, hasAllowancies, expirationEffect, expirationLength, hasExpiry, notificationDeadline, hoursLimited, optional, infoLink } in docs"
        )
          td
            | {{ name }}
          td
            span.generic-app-tag.is-wide-tag.popup-hook(
              v-tooltip.info.top.bounce="'Locations'",
              @click="showPopup(['locations'], $event.target)"
            ) <strong>{{ locationCount }}</strong>
              img(src="../../assets/images/comps/Olovka.svg")
          td
            span.generic-app-tag.is-wide-tag.popup-hook(
              @click="showPopup(['categories'], $event.target)"
            ) <strong>{{ categoryCount }}</strong>
              img(src="../../assets/images/comps/Olovka.svg")
          td
            span.generic-app-tag.is-wide-tag.popup-hook(
              @click="showPopup(['roles'], $event.target)"
            ) <strong>{{ roles.length }}</strong>
              img(src="../../assets/images/comps/Olovka.svg")
          //- td {{ hoursLimited? hoursLimited + ' hours' : '-' }}
          td {{ hasExpiry ? "Yes" : "No" }}
          td
            span.generic-app-tag.generic-app-tag--expiry {{ expirationLength ? +expirationLength + " months" : "-" }}
          //- td(style="") {{expirationEffectLabel(expirationEffect)}}
          td
            span.generic-app-tag.generic-app-tag--deadline {{ notificationDeadline ? +notificationDeadline + " days" : "-" }}
          td
            input(
              type="checkbox",
              :checked="optional",
              disabled,
              @change="setOptionality(id, optional)"
            )
          //- td
          //-   span.clickable(
          //-     @click="showModal('edit-info-link', { docId: id, infoLink: infoLink }, 'Edit Info Link')"
          //-   )
          //-     img(src="../../assets/images/comps/info.svg")
        tr(v-if="loading")
          td.center-text(colspan="12")
            img(src="../../assets/images/comps/loader.svg")
        tr(v-if="!loading && docs && docs.length === 0")
          td.center-text(colspan="12")
            | No data in table.
    pagination(
      v-if="pagination",
      :total-pages="pagination.total_pages",
      :current-page="pagination.current_page",
      @page-changed="pageHasChanged"
    )
</template>
<script>
import _ from "underscore";
import MultiSelect from "vue-multiselect";
import EditInfoLink from "../in-modal/compliances/EditInfoLink.vue";
import Pagination from "../Pagination.vue";

import EditPopup from "../EditPopup.vue";

import { mapActions, mapGetters } from "vuex";

import { flatten } from "../../lib/helpers/function.js";

export default {
  name: "Documents",
  components: {
    EditInfoLink,
    MultiSelect,
    EditPopup,
    Pagination,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      debugInfo: {},
      newDocumentTemplate: {
        id: 0,
        name: "",
        locations: [],
        categories: [],
        roles: [],
        hoursLimited: 0,
        hasExpiry: false,
        expirationLength: null,
        expirationEffect: {
          label: "No Effect",
          type: 1,
        },
        infoLink: "",
        notificationDeadline: null,
        optional: false,
      },
      penBoxRequest: {
        locationsIds: [],
        categoriesIds: [],
        rolesIds: [],
      },
      freshValues: {
        locations: 0,
        categories: 0,
        roles: 0,
      },
      popup: {
        active_document_id: null,
        offset: {
          offsetX: 20,
          offsetY: 55,
        },
        element: null,
        show: false,
        data: null,
        levels: [],
        save: (data, totalCount) => {
          return this.refreshPopupState(data, totalCount);
        },
        isLoading: false,
      },
      add_new: false,
      modalVisible: false,
      rowHeight: "",
      filters: {
        name: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getComplianceDocs: "getComplianceDocs",
      clients: "getAllClients",
      categories: "getAllCategories", // job types
      roles: "getAllRoles", // Roles aka Visibility
      pagination: "getPagination",
    }),
    parsedCats() {
      return this.mapSubcategoriesToCategories(this.categories);
    },
    docs() {
      const dcArr = this.getComplianceDocs;
      const tempArr = [];
      for (const dc of dcArr) {
        const transformedObj = {
          id: dc.id,
          name: dc.name,
          locationCount: dc.locationCount,
          categories: dc.categories,
          categoryCount: dc.categoryCount,
          roles: dc.roles,
          hasAllowancies: null, // PRESET
          expirationEffect: true, // PRESET > Not used?
          expirationLength: dc.expiration_months,
          hasExpiry: dc.expiration_months > 0, // PRESET
          notificationDeadline: dc.notification_days,
          hoursLimited: dc.expiration_months,
          optional: dc.optional,
          infoLink: dc.info_link,
        };
        tempArr.push(transformedObj);
      }
      return tempArr;
    },
  },
  mounted() {
    this.getDocs();

    const clientIncludes = ["locations", "withArchived"].join();
    this.fetchClientsList({
      includes: clientIncludes,
      per_page: 999,
    }).then(() => {
      this.newDocumentTemplate.locations = this.getLocationsByClients(
        this.clients
      );
    });

    this.fetchAllCategoriesList();

    this.fetchAllRolesList().then(() => {
      this.newDocumentTemplate.roles = this.getRoles(this.roles);
    });
  },
  methods: {
    ...mapActions({
      fetchCompliances: "fetchCompliances",
      editComplianceOptional: "editComplianceOptional",
      patchDocument: "patchDocument",
      fetchClientsList: "fetchClientsList",
      fetchClients: "locationClientManagement/fetchClientsOptimized",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchAllRolesList: "fetchAllRolesList",
      removeDocument: "removeDocument",
      fetchDocumentLocationsPerClient: "fetchDocumentLocationsPerClient",
      fetchDocumentCategoriesPerClient: "fetchDocumentCategoriesPerClient",
    }),
    async getDocs() {
      this.loading = true;
      try {
        const params = {
          includes: ["locations", "categories", "roles"].join(","),
          page: 1,
          optimized: true,
          clients: [{ id: this.clientData.id }],
        };
        await this.fetchCompliances(params);
        this.loading = false;
      } catch (err) {
        console.log("ERR", err.message);
        this.loading = false;
      }
    },
    forceRefreshSamePage() {
      const currentPage = this.pagination?.current_page || 1;
      this.pageHasChanged(currentPage);
    },

    pageHasChanged(pageNum) {
      this.loading = true;
      this.fetchCompliances({
        includes: ["locations", "categories", "roles"].join(","),
        page: pageNum,
        optimized: true,
        clients: [{ id: this.clientData.id }],
      }).then(() => {
        // this.setValidationTypesDrop(data)
        this.loading = false;
      });
    },

    setOptionality(id, val) {
      val = !val;
      this.patchDocument({
        docId: id,
        optional: val,
      });

      this.editComplianceOptional({
        id,
        val,
      });
    },
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },

    popupClose() {
      this.popup.show = false;
    },
    updateTotalCount(prop, count) {
      this.freshValues[prop] = count;
    },

    refreshPopupState({ data, entity }, totalCount) {
      console.log("ENTITY :: ", entity[0]);
      if (entity[0] === "roles") {
        const ids = data[0].roles
          .filter((role) => {
            if (role.checked === true) return role.id;
          })
          .map((role) => role.id);

        /**
         * Prepare for api
         */
        const roles = flatten(ids).map((id) => ({ id: id }));

        if (this.popup.active_document_id !== 0) {
          this.patchDocument({
            docId: this.popup.active_document_id,
            roles: roles,
          }).then(() => {
            this.$toasted.info("Roles Updated!").goAway(1500);
          });
        } else {
          // new doc
          this.newDocumentTemplate.roles = data[0].roles;
          this.updateTotalCount("roles", totalCount);
          /**
           * Keep roles ids, for post request!
           */
          this.penBoxRequest.rolesIds = roles;
        }

        return; // safety measure
      } else if (entity[0] === "categories") {
        console.warn(data);

        const ids = data.map((category) => {
          return category.categories
            .filter((subcat) => {
              if (subcat.checked === true) {
                return subcat.id;
              }
            })
            .map((subcat) => subcat.id);
        });

        const categories = flatten(ids).map((id) => ({ id: id }));

        if (this.popup.active_document_id !== 0) {
          this.patchDocument({
            docId: this.popup.active_document_id,
            categories,
          }).then(() => {
            this.$toasted.info("Categories updated successfully!").goAway(1500);
            this.forceRefreshSamePage();
          });
        } else {
          /**
           * NEW DOCUMENT
           */
          this.newDocumentTemplate.categories = data;
          this.updateTotalCount("categories", totalCount);
          this.penBoxRequest.categoriesIds = categories;
        }

        return; // safety measure
      } else if (entity[0] === "locations") {
        // TODO send allowances alongside locations ids
        // take cc ids from data where checked property is true...
        const ids = data.map((client) => {
          return client.locations
            .filter((cc) => {
              if (cc.checked === true) {
                return cc.id;
              }
            })
            .map((cc) => ({
              id: cc.id,
              hasAllowance: cc.hasComplianceAllowance,
            }));
        });
        const centres = flatten(ids).map((o) => ({
          id: o.id,
          has_allowance: o.hasAllowance,
        }));
        /**
         * If we are not creating NEW DOCUMENT, patch resource.
         */
        if (this.popup.active_document_id !== 0) {
          this.patchDocument({
            docId: this.popup.active_document_id,
            locations: centres,
          }).then(() => {
            this.$toasted.info("Wards Updated!").goAway(1500);
            this.forceRefreshSamePage();
          });
        } else {
          /**
           * NEW DOCUMENT
           */
          console.log(data);
          this.newDocumentTemplate.locations = data;
          this.updateTotalCount("locations", totalCount);
          this.penBoxRequest.locationsIds = centres;
        }
        return; // safety measure
      }
    },

    /**
     * Set status `checked` for wards that have checked state for this document
     * @param docId Document id for which we collect data into 'cc'
     */
    async waitForDataArrToLoad(variableToCheckPaths, cancelTokenFuncCheck) {
      // Generic reusable func
      function deepFind(obj, path) {
        const paths = path.split(".");
        let current = obj;

        for (let i = 0; i < paths.length; ++i) {
          if (current[paths[i]] == undefined) {
            return undefined;
          } else {
            current = current[paths[i]];
          }
        }
        return current;
      }

      return new Promise((resolve, reject) => {
        const recheckFunc = () => {
          const tVal = setTimeout(() => {
            const dataToLoadArr = deepFind(this, variableToCheckPaths);
            if (
              !dataToLoadArr ||
              (dataToLoadArr && dataToLoadArr.length === 0)
            ) {
              if (cancelTokenFuncCheck && cancelTokenFuncCheck()) {
                clearTimeout(tVal);
                reject();
              } else {
                console.log("Recheck data");
                recheckFunc();
              }
            } else {
              resolve();
            }
          }, 1000);
        };
        recheckFunc();
      });
    },
    getLocationsByClients(clients, ids = []) {
      if (!clients || !Array.isArray(clients)) {
        console.warn("clients >> ", clients);
        return clients;
      }

      const noRefClients = JSON.parse(JSON.stringify(clients));
      _.each(noRefClients, (_client) =>
        _client.locations.map((cc) => {
          cc.checked = false;
          cc.hasComplianceAllowance = false;
          _.each(ids, function (o) {
            if (o.id === cc.id) {
              cc.checked = true;
              cc.hasComplianceAllowance = o.hasComplianceAllowance;
            }
          });
        })
      );
      const clientWards = [];
      for (let i = 0; i < noRefClients.length; i++) {
        if (noRefClients[i].name === this.clientData.name) {
          clientWards.push(noRefClients[i]);
          break;
        }
      }
      return clientWards;
    },
    mapSubcategoriesToCategories(
      categories = [],
      checkedSubCategories,
      checkedIds = []
    ) {
      const newCats = [...this.categories];
      // newCats.forEach(_category => {
      //   // Copy-rename
      //   _category.categories = _category.subcategories

      //   _category.categories.map(subcat => {
      //     subcat.checked = _.contains(checkedIds, subcat.id)
      //     if (subcat.checked) {
      //       // console.log('subcat id :: ', subcat.id)
      //     }
      //   })
      // })

      //TODO refactor this logic
      //cycle through all categories
      for (let i = 0; i < newCats.length; i++) {
        //populate categories with all subcategories (to prevent the category list from being filled with only checked subcategories)
        newCats[i].categories = [...newCats[i].subcategories];
        //set checked to false to all subcats
        //TODO refactor this loop.
        for (let l = 0; l < newCats[i].categories.length; l++) {
          newCats[i].categories[l].checked = false;
        }
        //cycle through all subcategories that are checked (api returns data that is applicable to the particular doc)
        for (let j = 0; j < checkedSubCategories.length; j++) {
          //if subcategory belong to the category
          if (newCats[i].id === checkedSubCategories[j].parent_id) {
            //go through all subcategories and fill with checked
            for (let k = 0; k < newCats[i].categories.length; k++) {
              if (newCats[i].categories[k].id === checkedSubCategories[j].id) {
                checkedSubCategories[j].checked = true;
                newCats[i].categories[k] = checkedSubCategories[j];
              }
            }
          }
        }
      }

      return newCats;
    },
    /**
     * When ids param not passed, all values will be deselected
     */
    getRoles(roles, ids = []) {
      const _roles = JSON.parse(JSON.stringify(roles));
      _.each(_roles, (_role) => {
        _role.checked = _.contains(ids, _role.id);
      });
      return _roles;
    },
    async showPopup(levels, e) {
      // find the row we are acting upon...
      const docIdStr = e.closest("tr").getAttribute("id");
      let doc_id = 0;
      if (docIdStr) {
        doc_id = Number.parseInt(docIdStr);
      }
      let api_checked_ids = [];

      /**
       * First PENBOX, locations within clients logic
       */
      if (_.indexOf(levels, "locations") >= 0) {
        /**
         * If not NEW DOCUMENT
         */
        if (doc_id !== 0) {
          this.popup.isLoading = true;
          this.$nextTick(async () => {
            const response = await this.fetchDocumentLocationsPerClient({
              id: doc_id,
              clients: [{ id: this.clientData.id }],
            });
            const doc = _.findWhere(this.docs, { id: doc_id });
            doc.locations = response.data;
            // Find checked ward for this document id
            api_checked_ids = this.findCheckedItemsImproved(
              "locations",
              doc_id
            );
            // finds matches for wards that have selected state set to true
            await this.waitForDataArrToLoad("clients");
            const clientLocations = await this.getLocationsByClients(
              this.clients,
              api_checked_ids
            );
            this.$set(this.popup, "data", clientLocations);
            this.popup.isLoading = false;
          });
        } else {
          this.popup.isLoading = true;
          this.$nextTick(async () => {
            await this.waitForDataArrToLoad("newDocumentTemplate.locations");
            this.popup.data = this.newDocumentTemplate.locations;
            this.popup.isLoading = false;
          });
        }
      } else if (_.indexOf(levels, "categories") >= 0) {
        this.popup.isLoading = true;
        if (doc_id !== 0) {
          // api_checked_ids = this.findCheckedItems('categories', doc_id)
          // console.log('API CHECKED IDS :: ', api_checked_ids)
          this.$nextTick(async () => {
            const checkedSubCategories =
              await this.fetchDocumentCategoriesPerClient({
                id: doc_id,
                clients: [{ id: this.clientData.id }],
              });
            this.popup.data = this.mapSubcategoriesToCategories(
              this.categories,
              checkedSubCategories.data
            );
            this.popup.isLoading = false;
          });
        } else {
          this.popup.data = this.newDocumentTemplate.categories;
        }
      } else if (_.indexOf(levels, "roles") >= 0) {
        /**
         * third penbox, roles
         */
        if (doc_id !== 0) {
          // console.log('not new row')
          /**
           * collect ids for checked states from api
           */
          api_checked_ids = this.findCheckedItems("roles", doc_id);

          this.popup.data = [
            {
              id: 0,
              name: "Roles",
              roles: this.getRoles(this.roles, api_checked_ids),
            },
          ];
        } else {
          // console.log('new row')
          this.popup.data = [
            {
              id: 0,
              name: "Roles",
              roles: this.newDocumentTemplate.roles,
            },
          ];
        }
      }

      this.popup.levels = levels;
      this.popup.active_document_id = doc_id;
      this.popup.show = true;
      this.popup.element = e;
    },

    findCheckedItemsImproved(type, id) {
      return _.findWhere(this.docs, { id: id })[type].map((t) => ({
        id: t.id,
        hasComplianceAllowance: t.hasComplianceAllowance,
      }));
    },
    findCheckedItems(type, id) {
      return _.findWhere(this.docs, { id: id })[type].map((t) => t.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}

.controls {
  .button {
    margin-left: 0.5em;
  }
}

td {
  vertical-align: middle;

  .flexer {
    display: flex;
    align-items: center;

    span:first-child {
      align-self: center;
      display: flex;
      flex: 0 0 15px;
      align-items: center;
      margin-right: 1em;
      cursor: pointer;
    }
  }
}

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.generic-app-tag.generic-app-tag--deadline {
  background-color: #d2ecf5;
}

.generic-app-tag.generic-app-tag--expiry {
  background-color: #ffcc6a;
}

.new-document {
  img {
    margin-right: 15px;
  }
}
</style>
<style lang="scss">
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.center-text {
  text-align: center;
  height: 150px;
  background: #f0f6f6;
  img {
    width: 50px;
  }
}
</style>
