<template lang="pug">
div
  .three-filter
    #sectors.field
      label.label
        | Region
      p.control
        multi-select(
          :options="optionsSector",
          :hide-selected="true",
          v-model="isSelected.sector",
          @select="sectorSelected",
          @remove="sectorRemoved",
          :close-on-select="false",
          select-label="",
          :multiple="true",
          group-label="name",
          group-values="subs",
          trackBy="id",
          label="name"
        )
          span(slot="noResult")
            span 
    #clients.field(
      v-if="isSelected.sector && Array.isArray(isSelected.sector) && isSelected.sector.length"
    )
      label.label
        | Client
      p.control
        multi-select(
          :options="optionsClient",
          v-model="isSelected.client",
          @select="clientSelected",
          @remove="clientRemove",
          select-label="",
          trackBy="id",
          label="name"
        )
          span(slot="noResult")
    #categories.field(v-if="isSelected.client")
      label.label
        | Category
      p.control
        multi-select(
          :options="optionsCategory",
          v-model="isSelected.category",
          @select="categorySelected",
          @remove="categoryRemoved",
          select-label="",
          trackBy="id",
          label="name"
        )
          span(slot="noResult")
</template>

<script>
import MultiSelect from "vue-multiselect";
import { default as axios } from "../../api/config.js";

export default {
  // model: {
  //   prop: 'value',
  //   event: 'threefilter:update'
  // },
  props: {
    sectors: {
      type: Array,
    },
    threeData: {
      type: Object,
      required: false,
    },
    resetFilters: {
      type: Boolean,
      required: true,
    },
  },
  components: { MultiSelect },

  data() {
    return {
      isSelected: {
        sector: [],
        client: null,
        category: null,
      },
      optionsClient: [],
      optionsCategory: [],
      disabledSelectedWatcher: false,
    };
  },
  watch: {
    resetFilters() {
      this.isSelected = {
        sector: [],
        client: null,
        category: null,
      };
    },
    isSelected: {
      handler: function (newObj) {
        if (this.disabledSelectedWatcher) {
          return;
        }
        this.sectorSelectedFunc();

        // Parse object and map values to API params
        let queryObject = Object.keys(newObj).reduce(function (acc, next) {
          if (newObj[next]) {
            // Transform for API to consume in POST as query object
            if (next === "category") {
              acc["categories[][id]"] = newObj[next].id;
            } else if (next === "client") {
              acc[next + "s[][id]"] = newObj[next].id;
            } else {
              acc[next + "s[][id]"] = newObj[next];
            }
          }
          return acc;
        }, {});
        this.$emit("threefilter:update", queryObject);
      },
      deep: true,
    },
  },
  mounted() {
    this.setupPreviousFilter();
  },
  methods: {
    setupPreviousFilter() {
      this.disabledSelectedWatcher = true;
      this.isSelected.sector = this.threeData["sectors[][id]"] || [];
      const clientID = this.threeData["clients[][id]"];
      const categoryID = this.threeData["categories[][id]"];

      const setClient = () => {
        if (this.isSelected.sector && Array.isArray(this.isSelected.sector)) {
          for (const sector of this.isSelected.sector) {
            if (sector.clients) {
              for (const client of sector.clients) {
                if (client.id === clientID) {
                  return client;
                }
              }
            }
          }
        }
        return null;
      };

      const setCategory = () => {
        for (const category of this.optionsCategory) {
          if (category.id === categoryID) {
            return category;
          }
        }
        return null;
      };

      this.disabledSelectedWatcher = true;
      this.$set(this.isSelected, "sector", this.threeData["sectors[][id]"]);
      this.sectorSelectedFunc();

      this.$set(this.isSelected, "client", setClient());
      if (this.isSelected.client) {
        this.clientSelected(this.isSelected.client, true).then(() => {
          this.$set(this.isSelected, "category", setCategory());
        });
      } else {
        this.$set(this.isSelected, "category", null);
      }

      this.disabledSelectedWatcher = false; // Renable watcher
    },
    sectorSelectedFunc() {
      const filterClients = () => {
        if (!this.isSelected.sector) {
          // console.warn('Empty sectors', this.isSelected.sector);
          return [];
        }
        const temp = [];

        for (const sector of this.isSelected.sector) {
          temp.push(...sector.clients);
        }

        // const temp = [
        //   { id: 1, name: "1" },
        //   { id: 6, name: "6" },
        //   { id: 2, name: "2" },
        //   { id: 4, name: "4" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 4, name: "4" },
        //   { id: 7, name: "7" },
        //   { id: 3, name: "3" },
        //   { id: 7, name: "7" },
        //   { id: 2, name: "2" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 3, name: "3" },
        //   { id: 3, name: "3" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 7, name: "7" },
        //   { id: 9, name: "9" },
        //   { id: 9, name: "9" },
        // ];

        for (let index = 0; index < temp.length; index++) {
          // console.log(temp[index].id);
          for (let index2 = 0; index2 < temp.length; index2++) {
            if (index === index2) {
              continue;
            }
            if (temp[index].id === temp[index2].id) {
              temp.splice(index2, 1);
              if (index > 0) index--;
              // temp[index].id = "brisi";
            }
          }
        }

        // const temp2 = [];
        // const clientMap = {};
        // const isSelectedCopy = JSON.parse(JSON.stringify(this.isSelected));
        // for (const sector of isSelectedCopy.sector) {
        //   sector.clients = temp;
        // }

        // for (const sector of isSelectedCopy.sector) {
        //   for (const client of sector.clients) {
        //     if (client.id in clientMap === false) {
        //       temp2.push(client);
        //       clientMap[client.id] = true;
        //     }
        //   }
        // }
        // console.log(clientMap);

        return temp;
      };

      this.optionsClient = filterClients();
    },
    sectorSelected(selected) {
      // ++ sector
      const originalSectors = this.isSelected.sector || [];
      const newArr = [...originalSectors, selected];
      this.$set(this.threeData, "sectors[][id]", newArr);

      // Reset selected client (only if it doesn't contain client) on each sector change
      if (
        this.isSelected.client &&
        !newArr.some((sector) =>
          sector.clients.some(
            (client) => client.id === this.isSelected.client.id
          )
        )
      ) {
        console.log("Resetting client and category");
        this.isSelected.client = null;
        this.isSelected.category = null;
      }

      this.$emit("search-three-drop", { id: "sec-add", val: selected });
    },
    sectorRemoved(selected) {
      // -- sector
      const originalSectors = this.isSelected.sector || [];
      const sectorFilter =
        Array.isArray(originalSectors) &&
        originalSectors.filter((sector) => sector.id !== selected.id);
      this.$set(this.threeData, "sectors[][id]", sectorFilter || []);

      // Reset selected client (only if it doesn't contain client) on each sector change
      if (
        this.isSelected.client &&
        !sectorFilter.some((sector) =>
          sector.clients.some(
            (client) => client.id === this.isSelected.client.id
          )
        )
      ) {
        console.log("Resetting client and category");
        this.isSelected.client = null;
        this.isSelected.category = null;
      }

      this.$emit("search-three-drop", { id: "sec-rem", val: selected });
    },
    clientSelected(selected, isBlockEvent) {
      // Reset selected category on each Client change
      this.isSelected.category = null;
      let clientId = selected.id;

      // ++ client
      this.$set(this.threeData, "clients[][id]", clientId);

      if (!isBlockEvent) {
        this.$emit("search-three-drop", { id: "cli-add", val: selected });
      }

      // Make API request to get applicable categories for this client
      return axios.get(`/categories?client_id=${clientId}`).then((response) => {
        this.optionsCategory = response.data.data;
      });
    },
    clientRemove(selected) {
      // -- client
      this.$set(this.threeData, "clients[][id]", null);

      this.$emit("search-three-drop", { id: "cli-rem", val: selected });
    },
    categorySelected(selected) {
      console.log(">>>", selected);
      // ++ category
      this.$set(this.threeData, "categories[][id]", selected);
      this.$emit("search-three-drop", { id: "cat-add", val: selected });
    },
    categoryRemoved(selected) {
      console.log(">>>", selected);
      // -- category
      this.$set(this.threeData, "categories[][id]", null);
      this.$emit("search-three-drop", { id: "cat-rem", val: selected });
    },
  },

  computed: {
    optionsSector() {
      return this.sectors;
    },
  },
};
</script>
<style lang="scss">
.three-filter {
  display: flex;

  .field {
    max-width: 700px;
  }

  p.control {
    margin-right: 1em;
  }
}
</style>
