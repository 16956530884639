<template lang="pug">
.dialog-container
  .inputs
    .field
      label.label Temp Name
      p.control
        multi-select(
          :options="agencyWorkers",
          :close-on-select="true",
          :searchable="true",
          :preserve-search="true",
          :options-limit="3",
          :loading="isLoading",
          track-by="id",
          label="name",
          v-model="selectedAgencyWorker",
          placeholder="TYPE TO FIND AGENCY WORKER",
          :internal-search="false"
          @search-change="findWorkerFunc"
        )
          span(slot="noResult") Nothing found.
  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      :disabled="isSaveLoading",
      @click.prevent="saveTempName"
    ) Save
    //- :disabled="!selectedAgencyWorker || isSaveLoading",
    button.button.is-small.is-outlined.is-caps-lock(
      @click.prevent="callModalClose"
    ) Cancel
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import { parseErrors } from "../../lib/helpers/function.js";
import _ from "underscore";
import { SHIFT_TYPES } from "../../shared/mapData.js";

export default {
  props: ["modalProps", "isSaveLoading"],
  data() {
    return {
      selectedAgencyWorker: null,
      originallySelectedWorker: null,
      isLoading: false,
    };
  },
  mounted() {
    if (this.modalProps.temp && this.modalProps.temp.id) {
      this.selectedAgencyWorker = this.modalProps.temp;
    }
    // keep reference of previous value, and send it to multi-select as option
    this.originallySelectedWorker = { ...this.selectedAgencyWorker };
  },
  computed: {
    ...mapGetters({
      // fetch all agency workers, this will populate the list for when someone tries to search
      // for a specific temp worker
      agencyWorkers: "getAgencyWorkers",
    }),
    // agencyWorkersWithCurrent () {
    //   if (!this.selectedAgencyWorker) {
    //     return this.agencyWorkers
    //   }

    //   const elIndex = this.agencyWorkers.findIndex(worker => worker.id === this.selectedAgencyWorker.id)
    //   if (elIndex === -1) {
    //     return [{ ...this.selectedAgencyWorker }, ...this.agencyWorkers]
    //   }

    //   const tempArr = [...this.agencyWorkers].splice(elIndex, 1)
    //   tempArr.unshift(this.agencyWorkers[elIndex])
    //   return tempArr
    // }
  },
  methods: {
    ...mapActions(["findAgencyWorker", "patchShift"]),
    saveTempName() {
      this.$emit("is-save-loading", true);
      this.patchShift({
        id: this.modalProps.shift.id,
        data: {
          // IMPORTANT NOTE if null is sent as temp_id, that actually deletes the temp assigned for this shift
          temp_id: this.selectedAgencyWorker?.id || null,
        },
      })
        .then(() => {
          this.$emit("is-save-loading", false);
          this.callModalClose();
        })
        .catch((error) => {
          this.$emit("is-save-loading", false);
          let errs = parseErrors(error);
          this.$toasted.error(errs).goAway(4500);
        });
    },
    callModalClose() {
      this.$emit("dispose-modal");
    },
    findWorkerFunc(query) {
      this.isLoading = true;
      this.asyncFindWorker(query);
    },
    asyncFindWorker: _.debounce(
      async function (query) {
        await this.findAgencyWorker({
          query,
          originallySelectedWorker: this.originallySelectedWorker,
          has_on_call: this.modalProps.shift.type === SHIFT_TYPES.oncall.key,
          has_sleepover:
            this.modalProps.shift.type === SHIFT_TYPES.sleepover.key,
          shift_type: this.modalProps.shift.type,
        });
        this.isLoading = false;
      },
      400 // wait for this long for user to stop typing... before executing axios request.
    ),
  },
  components: { MultiSelect },
};
</script>
<style lang="scss">
.modal-content {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
}

.inputs {
  display: flex;
  justify-content: center;

  .field {
    width: 100%;
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

label {
  margin-top: 0.5em;
}
</style>
