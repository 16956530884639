<template lang="pug">
.break-times-form
  //- .header
  .form-content
    p.control
      label.label Category
      multi-select.category-select(
        :options="breakTimesCategories",
        select-label="",
        selected-label="",
        deselect-label="",
        track-by="id",
        label="name",
        :allow-empty="false",
        :value="selectedCategoryOption",
        placeholder="FILTER CATEGORY",
        @select="onChangeCategory"
      )
    template(v-if="selectedCategoryOption")
      //- .last-changed-by(v-if="breakListSorted.length")
        span(v-html="generateTitle(breakListSorted[0])")
      .rows
        .row(v-for="(breakRow, index) in breakListSorted", :key="index")
          .group-input
            label.label(v-if="index == 0") From (hours)
            input.input(
              :value="breakRow.shift_length_from",
              type="text",
              :disabled="true"
            )
          .group-input
            label.label(v-if="index == 0") To (hours)
            input.input(
              :value="breakRow.shift_length_to",
              :class="{ error: isInputErrorValidation('shift_length_to', breakRow) }",
              type="number",
              min="1",
              max="99",
              @input="onInputField($event, 'shift_length_to', breakRow, index)"
            )
          .group-input
            label.label(v-if="index == 0") Break (minutes)
            input.input(
              :value="breakRow.break_length",
              :class="{ error: isInputErrorValidation('break_length', breakRow) }",
              type="number",
              @input="onInputField($event, 'break_length', breakRow, index)"
            )
          .info(
            v-tooltip="{ content: generateTitle(breakRow), classes: 'report-status-tooltip' }"
          )
            span.fa.fa-info.popup
          .remove-wrap
            img(
              src="../../assets/images/comps/remove.svg",
              title="Remove",
              @click="onRemoveRow(breakRow, index)"
            )
        .row(v-if="isMaxBreakValueSet")
          .group-input
            label.label(v-if="breakListSorted.length == 0") From (hours)
            input.input(
              :value="parseMaxTo(getBiggestFromObj, '+')",
              type="text",
              :disabled="true"
            )
          .group-input
            input(style="visibility: hidden")
          .group-input
            label.label(v-if="breakListSorted.length == 0") Break (minutes)
            input.input(
              :value="maxBreakValue",
              type="number",
              @input="onChangeMaxBreakValue($event)"
            )
          .remove-wrap(v-if="breakListSorted.length == 0")
            img(
              src="../../assets/images/comps/remove.svg",
              title="Remove",
              @click="onRemoveGeneralRow"
            )
        .row(v-if="!isMaxBreakValueSet")
          p This category is using the default break times.
      button.button.is-generic-app-blue.is-caps-lock.is-low.add(
        @click="onAddNewRow"
      )
        span Add new row
  .action.buttons-centered
    button.button.is-generic-app-blue.is-caps-lock.save(
      @click="onSave",
      :disabled="!isAnyInputChanged"
    )
      span Save
    button.button.is-generic-app-blue.is-caps-lock.save(
      @click="onApply",
      :disabled="!isAnyInputChanged"
    )
      span Apply
    button.button.is-caps-lock.cancel(@click="onClose")
      span Cancel
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { parseErrors } from "../../lib/helpers/function.js";
import MultiSelect from "vue-multiselect";
export default {
  components: {
    MultiSelect,
  },
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      breakList: [],
      breakTimesCategories: [],
      selectedCategoryOption: null,
      maxBreakValue: null,
      isMaxBreakValueSet: null,
      isAnyInputChanged: false,
      errorInputList: [],
    };
  },
  computed: {
    breakListSorted() {
      return [...this.breakList].sort((a, b) =>
        a.from > b.from ? 1 : a.from < b.from ? -1 : 0
      );
    },
    getBiggestFromObj() {
      if (Array.isArray(this.breakList) && this.breakList.length) {
        return this.breakList.reduce((acc, curr) =>
          curr.shift_length_to > acc.shift_length_to ? curr : acc
        );
      }
      return {};
    },
    clientId() {
      return this.modalProps.id;
    },
  },
  mounted() {
    this.fetchBreakTimes();
  },
  methods: {
    ...mapActions({
      getBreakTimes: "getBreakTimes",
      patchBreakTimes: "patchBreakTimes",
    }),
    onChangeCategory(evt) {
      this.fetchBreakTimes();
      this.selectedCategoryOption = evt;
      const { list, maxBreakObj } = this.parseBreakTimesArr(
        this.selectedCategoryOption.break_times
      );
      this.breakList = list;
      // this.maxBreakValue = maxBreakObj.break_length || null
      // 0 is a valid break time, so it checks if its 0
      if (!isNaN(parseInt(maxBreakObj.break_length))) {
        this.maxBreakValue = parseInt(maxBreakObj.break_length);
        this.isMaxBreakValueSet = true;
      } else {
        this.maxBreakValue = null;
        this.isMaxBreakValueSet = false;
      }
      // if (this.breakList.length === 0) {
      //   this.addBlankRow()
      // }
    },
    onAddNewRow() {
      if (this.isMaxBreakValueSet) {
        this.addBlankRow();
      } else {
        this.isMaxBreakValueSet = true;
        this.maxBreakValue = ""; //so the checkInputsValidity can catch the error
      }
    },
    async onSave() {
      this.saveBreakTimes();
    },
    onApply() {
      this.saveBreakTimes(false);
    },
    onClose() {
      this.$emit("cancel-modal");
    },
    async onRemoveRow(rowData, rowIndex) {
      try {
        await this.confirmAlert("remove break time");

        // Order is important
        const value = this.breakList[rowIndex].shift_length_from;
        if (rowIndex < this.breakList.length - 1) {
          console.warn(value);
          // Update if it has more rows
          this.$set(this.breakList[rowIndex + 1], "shift_length_from", value);
        }

        this.breakList.splice(rowIndex, 1);
        this.isAnyInputChanged = true;
      } catch (err) {
        // ignored
      }
    },
    onRemoveGeneralRow() {
      this.removeGeneralRow();
    },
    async removeGeneralRow() {
      await this.confirmAlert("remove break time");
      this.maxBreakValue = null;
      this.isAnyInputChanged = true;
      this.isMaxBreakValueSet = false;
    },
    onInputField(evt, keyField, breakRow, index) {
      const value = evt.target.value;
      let valueParsed = null;
      if (value) {
        valueParsed = parseInt(value, 10);
      }
      this.$set(breakRow, keyField, valueParsed);
      if (keyField === "shift_length_to") {
        // Update from value for next row
        if (index < this.breakList.length - 1) {
          this.$set(
            this.breakList[index + 1],
            "shift_length_from",
            valueParsed
          );
        }
      }
      this.isAnyInputChanged = true;
    },
    onChangeMaxBreakValue(evt) {
      this.maxBreakValue = evt.target.value;
      this.isAnyInputChanged = true;
    },
    async saveBreakTimes(isSave = true) {
      const isValidInputs = this.checkInputsValidity();
      if (!isValidInputs) {
        this.$toasted
          .error(
            "Some input values are not valid. Please fix before proceeding"
          )
          .goAway(4500);
        return;
      }

      const params = {
        client_id: this.clientId,
        break_categories: [
          {
            id: this.selectedCategoryOption.id,
            max_break_value: this.maxBreakValue,
            breaks: this.generateCategoriesForApi(),
          },
        ],
      };
      try {
        await this.patchBreakTimes(params);
        this.$toasted.success("Successfully changed").goAway(1000);
        this.isAnyInputChanged = false;
        if (isSave) {
          this.onClose();
        }
      } catch (err) {
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(4500);
      }
    },
    async fetchBreakTimes() {
      const params = { id: this.clientId };
      try {
        const res = await this.getBreakTimes(params);
        this.breakTimesCategories = res.data.data;
      } catch (err) {
        console.log("Error", err.message);
      }
    },
    addBlankRow() {
      const payload = {
        shift_length_from: this.parseMaxTo(this.getBiggestFromObj) || 0,
        shift_length_to: null,
        break_length: null,
      };
      this.breakList.push(payload);
    },
    generateCategoriesForApi() {
      // Generate without "from"
      return this.breakList.map((oneBreak) => {
        return {
          to: oneBreak.shift_length_to,
          value: oneBreak.break_length,
        };
      });
    },
    generateTitle(rowObj) {
      const formatDate = (str) => moment(str).format("DD/MM/YYYY HH:mm:ss");
      const formatKeys = (str) =>
        (str[0].toUpperCase() + str.slice(1)).replace("_", " ");

      const dateUsed = rowObj.updated || rowObj.created;
      const obj = {
        updated_by: rowObj.user && rowObj.user.name,
        updated_at: dateUsed && formatDate(dateUsed),
      };
      const jString = Object.entries(obj)
        .filter((el) => el[1])
        .map((el) => `${formatKeys(el[0])}: <b>${el[1] || ""}</b>`)
        .join("</br>");
      return jString;
    },
    checkInputsValidity() {
      // True is valid
      const allErrorInputs = this.$el.querySelectorAll("input.error");
      const isAnyInputInvalid = allErrorInputs.length > 0;
      if (isAnyInputInvalid) {
        return false;
      }
      const someFieldNotFilled = this.breakList.some(
        (oneBreak) => oneBreak.break_length === null
      );
      return !someFieldNotFilled && this.maxBreakValue !== "";
    },
    parseBreakTimesArr(breakTimesArr = []) {
      const tempArr = [...breakTimesArr];
      const maxBreakObjIndex = tempArr.findIndex(
        (oneBreak) => oneBreak.shift_length_to === 100
      );
      let maxBreakObj = {};
      if (maxBreakObjIndex !== -1) {
        maxBreakObj = tempArr.splice(maxBreakObjIndex, 1)[0];
      }
      return {
        list: tempArr,
        maxBreakObj: maxBreakObj,
      };
    },
    parseMaxTo(bigObj, strAppend = "") {
      if (bigObj.shift_length_to) {
        return `${bigObj.shift_length_to}${strAppend}`;
      }
      return `0${strAppend}`;
    },
    isInputErrorValidation(key, rowObj) {
      const valueTo = rowObj[key];
      const valueFrom = rowObj.shift_length_from;
      switch (key) {
        case "shift_length_to":
          if (!valueTo) {
            return false;
          }
          if (valueTo < 1 || valueTo > 99) {
            return true;
          }
          if (valueTo <= valueFrom) {
            return true;
          }
          break;
        case "break_length":
          return false;
        default:
          return false;
      }
    },
    confirmAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.break-times-form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // max-height: 500px;
    // overflow: auto;

    .rows {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .row {
        display: flex;
        gap: 10px;
        align-items: flex-end;

        .group-input {
          display: flex;
          flex-direction: column;
        }

        .info {
          margin: 0 20px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .remove-wrap {
          align-self: flex-end;
          margin: 0 0 4px 10px;

          img {
            cursor: pointer;
          }
        }

        input {
          width: 100px;

          &.error {
            background: #ffedf0;
            border: 1px solid crimson;
          }
        }
      }
    }

    button {
      align-self: flex-start;
    }
  }

  .action {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}
</style>
