<template lang="pug">
.journal-export-report
  button.button.is-generic-app-blue.is-caps-lock(
    title="Date ranges must be selected before exporting",
    :disabled="!isValidExport",
    @click="onClickExport"
  ) Export report
</template>

<script>
import { mapActions } from "vuex";
import { getFormattedTime, parseErrors } from "../../lib/helpers/function";
export default {
  props: {
    clientId: {
      type: [Number, String],
    },
    tempId: {
      type: [Number, String],
    },
    filter: {
      type: Object,
      required: true,
    },
    journalList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isValidExport() {
      const isTimeValid = this.filter.from.time && this.filter.to.time;
      // const hasAnyJournal = this.journalList.length;
      return isTimeValid;
      // return hasAnyJournal && isTimeValid;
    },
    canViewPrivateNotes() {
      if (this.clientId) {
        return this.gPermissions.canJournalClientViewPrivate;
      } else if (this.tempId) {
        return this.gPermissions.canJournalTempViewPrivate;
      }
      return false;
    },
    canViewSystemNotes() {
      if (this.clientId) {
        return this.gPermissions.canJournalClientViewSystem;
      } else if (this.tempId) {
        return this.gPermissions.canJournalTempViewSystem;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getNoteReport: "getNoteReport",
    }),
    async onClickExport() {
      const dateForApi = (t) => getFormattedTime(t, "YYYY-MM-DD", "DD/MM/YYYY");
      const params = {
        notetypes:
          this.filter.notetypes &&
          this.filter.notetypes.map((nt) => {
            return { id: nt.id };
          }),
        date_from: dateForApi(this.filter.from.time),
        date_to: dateForApi(this.filter.to.time),
        search: this.filter.note,
      };

      if (this.clientId) {
        // Client management
        params.for_client_journal = true;
        params.clients = [{ id: parseInt(this.clientId, 10) }];
        if (this.filter.temps.length) {
          params.temps = this.filter.temps.map((temp) => {
            return { id: temp.id };
          });
        }
      }
      if (this.tempId) {
        // Temp management
        // params.temp_id = this.tempId;
        params.for_client_journal = false;
        params.temps = [{ id: parseInt(this.tempId, 10) }];
        if (this.filter.clients.length) {
          params.clients = this.filter.clients.map((client) => {
            return { id: client.id };
          });
        }
      }
      if (this.canViewSystemNotes) {
        params.withsystem = Boolean(this.filter.withsystem);
      }
      if (this.canViewPrivateNotes) {
        params.isprivate = Boolean(this.filter.isprivate);
      }

      try {
        const res = await this.getNoteReport(params);
        // let blob = new Blob([res.data], {
        //   // type: "application/vnd.ms-excel",
        //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // });
        const fileName = "notes.xlsx";
        const a = document.createElement("a");
        const url = URL.createObjectURL(res.data);
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      } catch (err) {
        // Doesn't work because parsing AXIOS to blob
        const errs = parseErrors(err, "Failed to download report.", true);
        this.$toasted.error(errs).goAway(1500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.journal-export-report {
  display: flex;
  // flex-direction: column;
  gap: 15px;
  align-items: center;

  button {
    height: 2.8em;
  }
}
</style>
