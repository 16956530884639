/* eslint camelcase: "off" */

const token_name = 'jwt_token';

const Jwt = {

  setToken (token, expiration) {
    localStorage.setItem(token_name, token);
    localStorage.setItem('jwtTokenExpiration', expiration);
  },

  destroyToken () {
    localStorage.removeItem(token_name);
    localStorage.removeItem('jwtTokenExpiration');
  },

  getToken () {
    let token = localStorage.getItem(token_name);
    let expiration = localStorage.getItem('jwtTokenExpiration');

    // let expirationDate = new Date(Number.parseInt(expiration) * 1000)
    // console.log('Expiration date for this token: ', expirationDate)

    if (!token || !expiration) return null;

    if (Number.parseInt(expiration) < Date.now() / 1000) {
      this.destroyToken();
      return null;
    } else {
      return token;
    }
  },

  getAuthHeader () {
    return 'Bearer' + this.getToken();
  },

  loggedIn () {
    return this.getToken();
  },
};

export default Jwt;

