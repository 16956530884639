import Vue from "vue";
export class ExpensesTableData {
    agn_id;
    rate_values;
    upr_created;
    upr_from;
    upr_id;
    upr_to;
    upr_updated;
    upt_id;
    usr_id;
    constructor(objData) {
        this.agn_id = objData.agn_id;
        this.rate_values = objData.rate_values || [];
        this.upr_created = objData.upr_created;
        this.upr_from = objData.upr_from;
        this.upr_id = objData.upr_id;
        this.upr_to = objData.upr_to;
        this.upr_updated = objData.upr_updated;
        this.upt_id = objData.upt_id;
        this.usr_id = objData.usr_id;
        return this;
    }
    addNewRow(index = 0, from = null, to = null, columns = []) {
        const rowInstance = new ExpensesTableRow(from, to, columns);
        return this.addRowInstance(index, rowInstance);
    }
    addRowInstance(index = 0, rowInstance) {
        this.rate_values.splice(index, 0, rowInstance);
        return this;
    }
    addNewColumn(index = 0, from = null, to = null, value = null, variant = "") {
        const columnInstance = new ExpensesTableColumn(from, to, value, variant);
        return this.addColumnInstance(index, columnInstance);
    }
    addColumnInstance(index = 0, columnInstance) {
        for (const row of this.rate_values) {
            const uniqueCol = JSON.parse(JSON.stringify(columnInstance));
            row.columns.splice(index, 0, uniqueCol);
        }
        return this;
    }
    updateColumnValue(index = 0, key, value) {
        if (!key) {
            console.warn("Invalid key");
            return;
        }
        for (const row of this.rate_values) {
            Vue.set(row.columns[index], key, value);
        }
        return this;
    }
    removeRow(index = 0) {
        if (Array.isArray(this.rate_values) && this.rate_values.length > 0) {
            this.rate_values.splice(index, 1);
            return true;
        }
        else {
            console.warn("Can't remove row: ", index, this.rate_values);
            return false;
        }
    }
    removeColumn(index = 0) {
        const rateValArr = this.rate_values || [];
        for (const row of rateValArr) {
            if (Array.isArray(row.columns) && row.columns.length > 0) {
                row.columns.splice(index, 1);
            }
            else {
                console.warn("Can't remove column: ", index, row);
                return false;
            }
        }
        return true;
    }
}
export class ExpensesTableColumn {
    urv_id;
    urv_range2from;
    urv_range2to;
    urv_value;
    urv_variant;
    constructor(from = null, to = null, value = null, variant = "", id) {
        if (id != void 0) {
            this.urv_id = id;
        }
        this.urv_range2from = from;
        this.urv_range2to = to;
        this.urv_value = value;
        this.urv_variant = variant;
        return this;
    }
}
export class ExpensesTableRow {
    urv_range1from;
    urv_range1to;
    columns;
    urv_variant;
    constructor(from = null, to = null, columns = [], variant = "") {
        this.urv_range1from = from;
        this.urv_range1to = to;
        this.columns = columns;
        this.urv_variant = variant;
        return this;
    }
}
