<template lang="pug">
.cc-pref-container
  .search-bar
    input.input(
      v-model.trim="searchText",
      type="text",
      placeholder="Filter by client or ward",
      style="margin: 10px 0",
      autofocus,
      ref="searchInput"
    )
  .table-wrap
    table.generic-app-cc-pref
      tr
        th(colspan=2) WARD
        th
          .preferred PREFERRED
          .nonpreferred NON-PREFERRED
        th(style="width: 200px") REASON
      template(v-for="sector in filteredSectors")
        tr
          td.ccp-name
            label.ctrl.ctrl--checkbox(
              @click.prevent="checkTriggered('sector', undefined, sector, ifAnyChecked('sector', sector))"
            ) {{ sector.name }}
              input.ctrl--checkbox(
                type="checkbox",
                :checked="isAllChecked('sector', sector)"
              )
              .ctrl__status(
                :class="{ 'status--halfstate': ifAnyChecked('sector', sector) }"
              )
          td.expandable(@click.prevent="expandElement(sector, 'sector')")
            span
              .circle {{ getNumCheckedLeaves("sector", sector) }}
            span(style="margin-left: 15px")
              i.fa.fa-caret-up(v-if="checkSectorFoldState(sector)")
              i.fa.fa-caret-down(v-if="!checkSectorFoldState(sector)")
          td.align-state.fixed-height
            tri-state(
              v-show="ifAnyChecked('sector', sector)",
              @click.stop.prevent="",
              :value="getPreferredValue('sector', sector)",
              @input="sectorPreferenceChanged(sector, ...arguments)",
              color="accent"
            )
          td &nbsp
        template(v-for="client in sectorClientsMapped(sector.clients)")
          //- transition(name="fade-sh")
          tr(v-if="checkSectorFoldState(sector)", :key="client.id")
            td.ccp-name.pad-left
              label.ctrl.ctrl--checkbox(
                @click.prevent="checkTriggered('client', sector, client, ifAnyChecked('client', client))"
              ) {{ client.name }}
                input.ctrl--checkbox(
                  type="checkbox",
                  :checked="isAllChecked('client', client)"
                )
                .ctrl__status(
                  :class="{ 'status--halfstate': ifAnyChecked('client', client) }"
                )
            td.expandable(@click.prevent="expandElement(client, 'client')")
              .circle {{ getNumCheckedLeaves("client", client) }}
              span(style="margin-left: 15px")
                i.fa.fa-caret-up(v-if="checkClientFoldState(client)")
                i.fa.fa-caret-down(v-if="!checkClientFoldState(client)")
            td.align-state.fixed-height
              tri-state(
                v-show="ifAnyChecked('client', client)",
                @click.stop.prevent="",
                :value="getPreferredValue('client', client)",
                @input="clientPreferenceChanged(client, ...arguments)",
                color="accent"
              )
            td &nbsp
          transition-group(name="fade-sh", tag="tbody")
            tr.location-group(
              v-for="location in filteredLocations(client.locations)",
              v-if="checkClientFoldState(client) && checkSectorFoldState(sector)",
              :key="location.id",
              :class="{ disabled: isLocationDisabled(location) }"
            )
              td.ccp-name.pad-left-2
                label.ctrl.ctrl--checkbox {{ location.name }}
                  input.ctrl--checkbox(
                    type="checkbox",
                    v-model="location.checked",
                    :disabled="isLocationDisabled(location)",
                    @change="invokeEmitChange($event, location)"
                  )
                  .ctrl__status(:class="{ 'status--halfstate': false }")
              td
              td.align-state.fixed-height
                tri-state(
                  v-show="location.checked",
                  @click.stop.prevent="",
                  :value="getPreferredValue('location', location)",
                  :disabled="isLocationDisabled(location)",
                  @input="locationPreferenceChanged(location, ...arguments)",
                  color="accent"
                )
              td
                input.input(
                  type="text",
                  v-if="shouldShowReason('location', location, client)",
                  @change="info(location)",
                  v-model="location.reason"
                )

  .buttons-centered(v-if="!manageAction")
    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      :disabled="isLoadingSave",
      @click.prevent="saveAndClose"
    ) SAVE
    button.button.is-outlined.is-caps-lock(
      @click.prevent="$emit('cancel-modal')"
    ) CANCEL
</template>

<script>
import TriState from "../../TriStateSwitch.vue";
import _ from "underscore";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";

export default {
  name: "LocationPref",
  components: {
    TriState,
  },
  props: {
    initialSectors: {
      type: Array,
      default: () => [],
    },
    manageAction: Boolean,
    modalProps: Object,
  },
  data() {
    return {
      // sectors: JSON.parse(JSON.stringify(this.modalProps.sectors)),
      sectorClientArray: [],
      expandedNodes: {
        sectors: [],
        clients: [],
      },
      tempId: JSON.parse(JSON.stringify(this.modalProps.tempId)),
      isLoadingSave: false,
      searchText: "",
      searchTextLazy: "",
    };
  },
  computed: {
    filteredSectors() {
      // Needs to filter out sectors that don't have at least one [location] || [client]
      return this.sectors.filter((sector) => {
        const hasClient =
          Array.isArray(sector.clients) && sector.clients.length;
        if (hasClient) {
          return sector.clients.some((client) => {
            const hasLocs =
              Array.isArray(client.locations) && client.locations.length;
            if (hasLocs) {
              return this.searchTextLazy
                ? this.isClientOrLocFound(client)
                : true;
            }
          });
        }
        return false;
      });
    },
    sectors() {
      return JSON.parse(JSON.stringify(this.initialSectors));
    },
  },
  watch: {
    searchText: _.debounce(function (val) {
      this.searchTextLazy = val;
    }, 300),
  },
  mounted() {
    this.prepareData();
    console.log("SECTORS :: ", JSON.stringify(this.modalProps.sectors));
    this.$refs.searchInput.focus();
  },
  methods: {
    ...mapActions(["patchTemp"]),
    filteredLocations(locations) {
      // if (this.searchTextLazy) {
      //   return locations.filter(loc => loc.name.includes(this.searchTextLazy))
      // }
      return locations;
    },
    isClientOrLocFound(client) {
      const isClientFound = client.name
        .toLowerCase()
        .includes(this.searchTextLazy.toLowerCase());
      const isLocFound =
        Array.isArray(client.locations) &&
        client.locations.some((loc) =>
          loc.name.toLowerCase().includes(this.searchTextLazy.toLowerCase())
        );
      return isClientFound || isLocFound;
    },
    sectorClientsMapped(clients) {
      const clIds = clients.map((client) => client.id);
      return this.sectorClientArray.filter((mapClient) => {
        const hasMappedClient = clIds.includes(mapClient.id);
        const isFilterText = this.searchTextLazy
          ? this.isClientOrLocFound(mapClient)
          : true;
        return hasMappedClient && isFilterText;
      });
    },
    sectorPreferenceChanged(sector, value) {
      console.log("Sector Preference Changed");
      this.onPreferenceChanged("sector", sector, value);
    },
    clientPreferenceChanged(client, value) {
      console.log("Client Preference Changed");
      this.onPreferenceChanged("client", client, value);
    },
    locationPreferenceChanged(location, value) {
      const isDisabled = this.isLocationDisabled(location);
      if (isDisabled) return;

      console.log(location.isTempPreferred, value);
      // location.isTempPreferred = value
      this.onPreferenceChanged("location", location, value);
    },
    onPreferenceChanged(objectType, object, value) {
      console.log("ON PREFERENCE CHANGED ::", objectType, object, value);
      let i = null;
      let len = null;
      if (objectType === "location") {
        this.$set(object, "isTempPreferred", value);

        const payload = {
          id: object.id,
          is_temp_preferred: value,
        };
        this.$emit("temp-pref", payload);

        return;
      } else if (objectType === "sector") {
        const clients = this.sectorClientsMapped(object.clients) || [];
        len = clients.length;
        for (const client of clients) {
          this.onPreferenceChanged("client", client, value);
        }
        return;
      } else if (objectType === "client") {
        len = object.locations !== undefined ? object.locations.length : 0;
        for (i = 0; i < len; i++) {
          this.onPreferenceChanged("location", object.locations[i], value);
        }
        return;
      }
    },
    prepareData() {
      let i = null;
      let j = null;
      const lenS = this.sectors.length;
      // Remove clients that have no locations for each sector
      for (i = lenS - 1; i >= 0; i--) {
        let lenC =
          this.sectors[i].clients === undefined
            ? 0
            : this.sectors[i].clients.length;
        for (j = lenC - 1; j >= 0; j--) {
          const client = this.sectors[i].clients[j];
          if (client.locations === undefined || client.locations.length === 0) {
            this.sectors[i].clients.splice(j, 1);
          }
        }

        // Remove sectors which have no clients
        lenC =
          this.sectors[i].clients === undefined
            ? 0
            : this.sectors[i].clients.length;
        if (lenC === 0) {
          this.sectors.splice(i, 1);
        }
      }

      // Set up clients array
      const tempArr = [];
      const map = new Map();
      for (const sector of this.sectors) {
        for (const client of sector.clients) {
          if (!map.has(client.id)) {
            map.set(client.id, true);
            tempArr.push(client);
          }
        }
      }
      this.sectorClientArray = tempArr;
    },
    isLocationDisabled(location) {
      return Boolean(location.archived);
    },
    invokeEmitChange(evt, location) {
      const isDisabled = this.isLocationDisabled(location);
      if (isDisabled) return;

      const payload = {
        id: location.id,
        value: evt.target.checked,
      };
      this.$emit("cost-centre-check-change", payload);
    },
    checkTriggered(objectType, parent, child, checkValue) {
      const checkAll = !checkValue;
      if (objectType === "sector") {
        const clients = this.sectorClientsMapped(child.clients);
        for (const client of clients) {
          this.checkTriggered("client", child, client, checkValue);
        }
        return;
      } else if (objectType === "client") {
        for (const location of child.locations) {
          this.$set(location, "checked", checkAll);

          const payload = {
            id: location.id,
            value: checkAll,
          };
          this.$emit("cost-centre-check-change", payload);
        }
        return;
      }
    },
    isAllChecked(objectType, object) {
      if (objectType === "client") {
        return (object.locations || []).every((loc) => loc.checked);
      }
      if (objectType === "sector") {
        const clients = this.sectorClientsMapped(object.clients) || [];
        return clients.every((cli) => this.ifAnyChecked("client", cli));
      }
    },
    ifAnyChecked(objectType, object) {
      if (objectType === "client") {
        return (object.locations || []).some((loc) => loc.checked);
      } else if (objectType === "sector") {
        // If sector is object
        const clients = this.sectorClientsMapped(object.clients) || [];
        return clients.some((cli) => this.ifAnyChecked("client", cli));
      }
    },
    getNumCheckedLeaves(objectType, object) {
      if (objectType === "client") {
        return Array.isArray(object.locations)
          ? object.locations.filter((loc) => loc.checked).length
          : 0;
      } else if (objectType === "sector") {
        let sum = 0;
        const clients = this.sectorClientsMapped(object.clients) || [];
        for (const client of clients) {
          sum = sum + this.getNumCheckedLeaves("client", client);
        }
        return sum;
      }
    },
    getPreferredValue(objectType, object) {
      let i = null;
      let len = null;
      let firstTypeFound;
      // If object is location
      if (objectType === "location") {
        return !Object.prototype.hasOwnProperty.call(
          object,
          "isTempPreferred"
        ) || object.isTempPreferred === null
          ? "mix"
          : object.isTempPreferred;
      }
      // If object is client
      if (objectType === "client") {
        len = object.locations !== undefined ? object.locations.length : 0;
        for (i = 0; i < len; i++) {
          const CC = object.locations[i];
          // Remember first value we find
          if (i === 0) {
            firstTypeFound = this.getPreferredValue("location", CC);
          } else {
            // Compare the first value to every other one
            // If it's different at any point, return 'mix'
            if (firstTypeFound !== this.getPreferredValue("location", CC)) {
              return "mix";
            }
          }
        }
        // If we hadn't returned mix, return the value
        return firstTypeFound;
      }
      // If sector is object
      if (objectType === "sector") {
        const clients = this.sectorClientsMapped(object.clients) || [];
        len = clients !== undefined ? clients.length : 0;
        for (i = 0; i < len; i++) {
          const client = clients[i];
          // Remember first value we find
          if (i === 0) {
            firstTypeFound = this.getPreferredValue("client", client);
          } else {
            // Compare the first value to every other one
            // If it's different at any point, return 'mix'
            if (firstTypeFound !== this.getPreferredValue("client", client)) {
              return "mix";
            }
          }
        }

        // If we hadn't returned mix, return the value
        return firstTypeFound;
      }
    },
    // eslint-disable-next-line no-unused-vars
    shouldShowReason(objectType, object, parent) {
      // If object is location
      if (objectType === "location") {
        return (
          this.getPreferredValue(objectType, object) === false && object.checked
        );
      }
    },
    checkSectorFoldState(sector) {
      return this.expandedNodes.sectors.includes(sector.id);
    },
    checkClientFoldState(client) {
      return this.expandedNodes.clients.includes(client.id);
    },
    saveAndClose() {
      this.isLoadingSave = true;
      const locationsToSend = [];
      // _.each(this.sectors, sector => {
      _.each(this.sectorClientArray, (client) => {
        _.each(client.locations, (location) => {
          // Skip the unchecked ones
          if (location.checked === false) return;
          const id = location["id"];
          let isTempPreferred;
          if (location["isTempPreferred"] === true) {
            isTempPreferred = true;
          }
          if (location["isTempPreferred"] === false) {
            isTempPreferred = false;
          }
          const costCtrObjForApi = {};
          costCtrObjForApi["id"] = id;
          costCtrObjForApi["reason"] = location.reason;
          if (isTempPreferred !== undefined) {
            costCtrObjForApi["is_temp_preferred"] = isTempPreferred;
          }

          locationsToSend.push(costCtrObjForApi);
        });
      });
      // })

      console.log("Shooting at API ::", locationsToSend);

      this.patchTemp({
        id: this.tempId,
        data: { locations: locationsToSend },
        query: { include: "consultant,locations" },
      })
        .then(() => {
          this.$toasted.info("Wards Updated!").goAway(1500);
          this.isLoadingSave = false;
          this.$emit("dispose-modal");
        })
        .catch((err) => {
          this.isLoadingSave = false;
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(1500);
        });
    },
    expandElement(element, type) {
      if (type === "sector") {
        const index = this.expandedNodes.sectors.findIndex(
          (nodeId) => nodeId === element.id
        );
        if (index === -1) {
          this.expandedNodes.sectors.push(element.id);
        } else {
          this.expandedNodes.sectors.splice(index, 1);
        }
      } else if (type === "client") {
        const index = this.expandedNodes.clients.findIndex(
          (nodeId) => nodeId === element.id
        );
        if (index === -1) {
          this.expandedNodes.clients.push(element.id);
        } else {
          this.expandedNodes.clients.splice(index, 1);
        }
      } else {
        console.log(">>> undefined", element, type);
      }
    },
    info(location) {
      const payload = {
        id: location.id,
        reason: location.reason,
      };
      this.$emit("reason-change", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;
$color--generic-app-unchecked: #3f5273;
$color--cpl: #30bb26;

.cc-pref-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .table-wrap {
    overflow: auto;
  }
}

table.generic-app-cc-pref {
  tr {
    th {
      text-align: center;
      color: #7c91ae;
      font-size: 13px;
      font-weight: 900;
      border-width: 0 0 1px;

      div.preferred {
        color: $color--cpl;
      }

      div.nonpreferred {
        color: $color--generic-app-unchecked;
      }
    }

    td {
      padding-left: 10px;
      border-bottom: 0px;
    }

    td.ccp-name {
      width: 250px;
    }

    td.pad-left {
      padding-left: 25px;
    }

    td.pad-left-2 {
      padding-left: 40px;
    }

    td.expandable {
      cursor: pointer;
      min-width: 70px;
      display: flex;
      align-items: center;
      margin: auto 0;
      padding: 10px;
    }

    td.fixed-height {
      height: 37px;
    }

    td.align-state {
      text-align: center;
    }
  }
}

.ctrl {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.ctrl__status {
  position: absolute;
  top: 5px;
  left: 0;
  height: 12px;
  width: 12px;
  background: $color--white;
  border: 1px solid $color--secondary;
  border-radius: 2px;

  .ctrl input:checked ~ & {
    background: $color--primary;
  }

  .ctrl input:disabled ~ & {
    background: $color--light-grey;
    opacity: 0.6;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;

    .ctrl input:checked ~ & {
      display: block;
    }

    .ctrl--checkbox & {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid $color--white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .ctrl--checkbox input:disabled ~ & {
      border-color: $color--dark-grey;
    }
  }
}

.status--halfstate {
  background: linear-gradient(
    to right bottom,
    $color--white 50%,
    $color--primary 50%
  );
}

.location-group {
  &.disabled {
    opacity: 0.7;
  }
}

.circle {
  border-radius: 50%;
  background: $color--light-grey;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: $color--dark-blue;
  text-align: center;
  font-weight: 500;
  display: inline-block;
}

.fade-sh-enter-active,
.fade-sh-leave-active {
  transition: all 0.3s ease;
}

.fade-sh-enter,
.fade-sh-leave-to {
  opacity: 0;
}

.modal {
  label {
    margin-top: 0px;
  }
}
</style>
