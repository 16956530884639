<template lang="pug">
.table-wrap(@scroll="onTableScroll")
  table.table.sticky
    thead
      tr
        th
        th.subject-column
          span Subject
        th.client-column(v-if="isTempPage")
          span Client
        th.temp-column(v-if="isClientPage")
          span Candidate
        th
          span Admin
        th
          span Journal Entry
        th.is-narrow
          span Created
        th.is-narrow
          span Attachments
        th.is-narrow(v-if="canEditAction")
          span Action
        //- th(v-if="canEditAction")
        //-   span Delete
    tbody
      tr(
        :class="{ private: journalItem.isPrivate }",
        v-for="(journalItem, index) in filteredJournalList",
        :key="index"
      )
        td
          span.fa.fa-eye-slash(
            title="Private note",
            v-if="journalItem.isPrivate"
          )
          span.fa.fa-cogs(
            title="System note",
            v-else-if="isSystemNote(journalItem)"
          )
          span.fa.fa-envelope(
            title="Email note",
            v-else-if="isEmailNote(journalItem)"
          )
          span.fa.fa-bell(
            title="Notification note",
            v-else-if="isNotifNote(journalItem)"
          )
        td
          span {{ getSubject(journalItem) }}
        td(v-if="isTempPage")
          span {{ getClient(journalItem) }}
        td(v-if="isClientPage")
          span {{ getTemp(journalItem) }}
        td
          span(:title="getAdminEmail(journalItem)") {{ getAdminName(journalItem) }}
        td.has-pre-tag
          span(v-if="isEmailNote(journalItem) || isNotifNote(journalItem)")
            button.button.is-generic-app-blue.is-caps-lock.is-tiny(
              @click="onShowMessageBody(journalItem)",
              v-tooltip="{ content: journalItem.note, placement: 'left', classes: 'tooltip-width-600' }"
            ) {{ `Show message form` }}
          span(v-else)
            pre {{ journalItem.note }}
        td.is-narrow
          span {{ getTime(journalItem) }}
        td.is-narrow
          button.button.is-generic-app-blue.is-caps-lock.is-tiny.is-low(
            v-if="journalItem.noteFiles && journalItem.noteFiles.length",
            :title="'Files: ' + journalItem.noteFiles.length",
            @click="onFileCheck(journalItem)"
          ) Check
        td.is-narrow
          button.button.is-generic-app-blue.is-caps-lock.is-tiny.is-low(
            v-if="canEditAction && canEditNote(journalItem)",
            @click="onEdit(journalItem)"
          ) Edit
        //- td(v-if="canEditAction")
        //-   button.button.is-danger.is-caps-lock.is-tiny(
        //-     @click="onDelete(journalItem)"
        //-   ) Delete
      tr.empty(v-if="filteredJournalList.length === 0")
        td(colspan="100", style="text-align: center") No data in table
  .pagination
    pagination.notification-center-pagination(
      v-if="journalPagination",
      :total-pages="journalPagination.total_pages",
      :current-page="journalPagination.current_page",
      @page-changed="pageHasChanged"
    )
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "../Pagination.vue";
import {
  momentDateTimeFromApi,
  parseDateObjWithTimeZone,
  parseErrors,
} from "../../lib/helpers/function";
export default {
  components: {
    Pagination,
  },
  props: {
    journalList: {
      type: Array,
      default: () => [],
    },
    journalPagination: {
      type: Object,
    },
    subjectTypes: {
      // Unused now
      type: Array,
      default: () => [],
    },
    clients: {
      type: Array,
      default: () => [],
    },
    clientId: {
      type: [Number, String],
    },
    tempId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      scrollTableOffset: {
        from: 0,
        to: 100,
      },
      globalScrollBusy: false,
      perPaginationPage: 15,
      clickedPageForPagination: 1,
      // journalPagination: { total_pages: 1, current_page: 1 },
    };
  },
  computed: {
    ...mapGetters({
      pagination: "getPagination",
    }),
    isClientPage() {
      return Boolean(this.clientId);
    },
    isTempPage() {
      return Boolean(this.tempId);
    },
    filteredJournalList() {
      // return this.journalList.slice(
      //   this.scrollTableOffset.from,
      //   this.scrollTableOffset.to
      // );
      return this.journalList || [];
    },
    canEditAction() {
      if (this.isClientPage) {
        return this.gPermissions.canJournalClientEdit;
      } else if (this.isTempPage) {
        return this.gPermissions.canJournalTempEdit;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getNotificationCentreChildLogDetails:
        "getNotificationCentreChildLogDetails",
    }),
    pageHasChanged(clickedPage) {
      this.$emit("clicked-journal-page", clickedPage);
      // this.clickedPageForPagination = clickedPage;
      // this.getMessages();
    },
    onEdit(jItem) {
      this.$emit("edit-row", jItem);
    },
    onFileCheck(jItem) {
      this.$emit("file-check", jItem);
    },
    // onDelete(jItem) {
    //   this.$emit("delete-row", jItem);
    // },
    getSubject(jItem) {
      if (this.isSystemNote(jItem)) {
        // System note
        return "System";
      }
      // else if (jItem.subject && (this.isEmailNote(jItem) || this.isNotifNote(jItem))) {
      //   return jItem.subject;
      // }
      return jItem.subject || jItem.noteType?.name;
    },
    getTemp(jItem) {
      if (jItem.temp && jItem.temp.name) {
        return jItem.temp.name;
      }
      return "";
    },
    getClient(jItem) {
      const clientObj =
        this.clients.length &&
        this.clients.find((cl) => cl.id === jItem.client_id);
      if (clientObj) {
        return clientObj.name;
      }
      return "";
      // return "N/A";
    },
    getTime(jItem) {
      // const dateUsed = jItem.updated_at || jItem.created_at;
      const dateUsed = jItem.created_at;
      const dateIso = parseDateObjWithTimeZone(dateUsed);
      const parsedDate = momentDateTimeFromApi(dateIso);
      return parsedDate;
    },
    getAdminEmail(jItem) {
      return jItem.created_by_email || jItem.adminCreated?.email || "";
    },
    getAdminName(jItem) {
      return jItem.created_by_name || jItem.adminCreated?.name || "";
    },
    isEmailNote(jItem) {
      return jItem.type === "notification_centre_EMAL";
    },
    isNotifNote(jItem) {
      return jItem.type === "notification_centre_NOTF";
    },
    isSystemNote(jItem) {
      return (
        jItem.id === 0 && !this.isEmailNote(jItem) && !this.isNotifNote(jItem)
      );
    },
    canEditNote(jItem) {
      return (
        !this.isSystemNote(jItem) &&
        !this.isEmailNote(jItem) &&
        !this.isNotifNote(jItem) &&
        jItem.canEditJournal
      );
    },
    async fetchDataForEmailBody(jItem) {
      const params = {
        log_id: jItem.id,
        temp_id: jItem.temp_id,
        for_client_journal: Boolean(this.clientId),
      };
      try {
        const res = await this.getNotificationCentreChildLogDetails(params);
        return res.data?.data;
      } catch (err) {
        console.warn(err.message);
        const errs = parseErrors(
          err,
          "There was a problem displaying journal entry",
          true
        );
        this.$toasted.error(errs).goAway(2500);
      }
      return null;
    },
    async onShowMessageBody(jItem) {
      const resData = await this.fetchDataForEmailBody(jItem);
      this.$emit("show-html-body", resData);
    },
    onTableScroll(evt) {
      // Unused [Waiting Backend]
      // this.onScrollCheckLogic(evt);
    },
    scrollInvoke() {
      const offsetNum = 100;
      if (this.scrollTableOffset.to < this.journalList.length - offsetNum) {
        console.warn("GO!");
        this.scrollTableOffset.to += 100;
      } else {
        this.scrollTableOffset.to = this.journalList.length;
      }
    },
    onScrollCheckLogic(evt, ratioToCheck = 95, delay = 500) {
      if (!this.scrollInvoke) {
        console.warn("No scrolling logic implemented");
        return;
      }

      if (this.globalScrollBusy || !evt) {
        return;
      }

      const scrollTop = evt.target.scrollTop;
      const scrollHeight = evt.target.scrollHeight;
      const clientHeight = evt.target.clientHeight;
      const totalScrollDist = scrollHeight - clientHeight;

      const ratioScrolled = Math.round(scrollTop / (totalScrollDist / 100));
      if (ratioScrolled > ratioToCheck) {
        this.globalScrollBusy = true;
        this.scrollInvoke();

        setTimeout(() => {
          this.globalScrollBusy = false;
        }, delay);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrap {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;

  .pagination {
    justify-content: right;
  }
  .table {
    tr.private {
      background: #f7f7f7;
    }

    .subject-column {
      min-width: 200px;
    }
    .client-column,
    .temp-column {
      min-width: 200px;
    }
    .has-pre-tag {
      max-width: 1000px;

      span {
        width: 100%;
        pre {
          width: 100%;
          background: transparent;

          white-space: pre-wrap; /* css-3 */
          white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: -o-pre-wrap; /* Opera 7 */
          word-wrap: break-word;

          font-family: "Lato", sans-serif;
          font-size: 1rem;
        }
      }
    }

    &.sticky {
      border-collapse: separate;
      border-spacing: 0;
    }
  }
}
</style>
