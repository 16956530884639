
import { default as axios } from '../config.js';
import {
  packLaravelArray
} from '../../lib/helpers/function.js';

// import qs from 'query-string'

const api = {

  /**
   * @params:
   *   dateFrom starting date
   *   dateTo   ending date
   *
   * @return:
   *   array of shift objects, for each date in a specified range
   */
  getShiftsForDateRange: (url, params = {}, cancelTokenSource) => {
    const payload = {
      date_from: params.dateFrom,
      date_to: params.dateTo,
      'clients[][id]': params['clients[][id]'],
      locations: params.location,
      show_available_shifts: params.show_available_shifts,
    };

    const tempPayload = Object.assign({}, payload);
    let fullRequestUrl = url;

    const laravelArr = ['locations'];
    for (const larKey of laravelArr) {
      if (tempPayload[larKey]) {
        var packed = packLaravelArray(larKey, tempPayload[larKey]);
        if (packed !== '') {
          // If first
          if (fullRequestUrl && fullRequestUrl.indexOf('/?') === -1) {
            fullRequestUrl = fullRequestUrl + '?' + packed;
          } else {
            fullRequestUrl = fullRequestUrl + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete tempPayload[larKey];
      }
    }

    console.log('params.url in api call: ', fullRequestUrl);
    return axios.get(fullRequestUrl, {
      params: tempPayload,
      cancelToken: cancelTokenSource.token,
    });
  },

  /**
   * @params:
   *   date single day in YYYY-MM-DD format
   *
   * @return:
   *   shift objects, for specific date, filter by Open, Filled & ApprovalNeeded
   */
  getShiftsForDay: (url, params = {}) => {
    const payload = {
      date: params.date,
      'clients[][id]': params['clients[][id]'],
      locations: params.location,
    };

    const tempPayload = Object.assign({}, payload);
    let fullRequestUrl = url;

    const laravelArr = ['locations'];
    for (const larKey of laravelArr) {
      if (tempPayload[larKey]) {
        var packed = packLaravelArray(larKey, tempPayload[larKey]);
        if (packed !== '') {
          // If first
          if (fullRequestUrl && fullRequestUrl.indexOf('/?') === -1) {
            fullRequestUrl = fullRequestUrl + '?' + packed;
          } else {
            fullRequestUrl = fullRequestUrl + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete tempPayload[larKey];
      }
    }

    console.log('params.url in api call: ', fullRequestUrl);
    return axios.get(fullRequestUrl, {
      params: tempPayload,
    });
  },

  /**
   * @params:
   *   date starting date
   *   is_available_day  boolean
   *   is_available_night  boolean
   *
   * @return:
   *   array of availabilities
   */
  saveAvailability: (payload) => {
    const { date, is_available_day, is_available_night, tempId } = payload;
    let url = 'availabilities';
    if (tempId) {
      url = `temps/${tempId}/availabilities`;
    }
    return axios.post(url, {
      date,
      is_available_day,
      is_available_night,
    });
  },
  saveMultiAvailabilities: q => {
    let url = 'availabilities';
    if (q.tempId) {
      url = `temps/${q.tempId}/availabilities`;
    }
    return axios({
      url: url,
      method: 'POST',
      data: q,
    });
  },
  getOneAvailability: q => {
    let url = 'availabilities';
    if (q.tempId) {
      url = `temps/${q.tempId}/availabilities`;
    }
    delete q.tempId;
    return axios({
      url: url,
      params: q,
    });
  },
};

export default api;
