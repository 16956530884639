import Vue from "vue";
import router from "@/router.js";
import Jwt from "./helpers/Jwt.js";
import axios from "axios";
import { Evt } from "./helpers/Evt.js";
import jwtDecode from "jwt-decode";
import { default as axiosConfig } from "../api/config.js";
// import qs from 'query-string'

export default {
  name: "auth",

  user: {
    authenticated: false,
    profile: null,
    role: null,
  },

  check() {
    const jwt = Jwt.getToken();

    if (jwt !== null) {
      const jwtd = jwtDecode(jwt);

      this.user.authenticated = true;

      if (this.user.authenticated) {
        Evt.fire("setAccessList", { permissions: jwtd.permissions });
      }

      this.user.profile = {
        name: jwtd.name,
        email: jwtd.email,
      };
      this.user.role = jwtd.roles[0]; // TODO
      /**
       * make logged in user available throughout the app
       */
      Vue.prototype.$user = this.user;
      /**
       * in case someone needs it!
       */
      return jwtd;
    }
  },
  async refreshToken(route) {
    if (route.meta.authComp) {
      console.log("> Auth comp, cancel");
      return null;
    }

    const jwtd = this.check();
    if (!jwtd) {
      console.log("== No token to refresh");
      return null;
    }

    try {
      // const res = await axiosConfig.post('auth/refresh')
      const res = await Vue.axios.post("api/auth/refresh");
      console.log("[RT] >> Token fetched");
      const newToken = res.data.token;
      const decodedToken = jwtDecode(newToken);
      Jwt.setToken(newToken, decodedToken.exp);
      axios.defaults.headers.common["Authorization"] = Jwt.getAuthHeader();
      Evt.fire("setAccessList", {
        permissions: decodedToken.permissions,
        route,
      });
      console.log("[RT] >> Token set");
      return res;
    } catch (err) {
      console.warn(err.message);
    }
    return null;
  },
  register: async function (
    context,
    name,
    email,
    password,
    passwordConfirmation,
    homeAddress,
    mobilePhone
  ) {
    try {
      await Vue.axios.post("api/auth/register", {
        name: name,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        home_address: homeAddress,
        mobile_phone: mobilePhone,
      });
      return (context.success = true);
    } catch (error) {
      context.errors.record(error.response.data.errors);
    }
  },
  signin(context, email, password) {
    // const d = new FormData()
    // d.append('email', email)
    // d.append('password', password)
    // const d = { email: email, password: password }
    // const qsd = qs.stringify(d)
    // console.log(qsd)

    return Vue.axios
      .post("api/auth/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        const decodedToken = jwtDecode(response.data.token);
        Jwt.setToken(response.data.token, decodedToken.exp);
        axios.defaults.headers.common["Authorization"] = Jwt.getAuthHeader();

        const jwtd = this.check(); // remove this call to .check() when we make root dashboard comp

        if (this.user.authenticated) {
          console.log("on sign in :: ", jwtd);
          Evt.fire("setAccessList", { permissions: jwtd.permissions });
          /**
           * if user logged in does not have a role of temp
           */
          if (jwtd.roles.indexOf("temp") === -1) {
            /**
             * TODO find some decent solution...
             * set prefix /admin for all routes... maybe!?
             */
            console.warn("admin priveleges :: ON");
            console.log(axios.defaults);
          }
        }

        // announce an event on successful log in...
        // Evt.fire('signedin')

        if (context.$route.query.redirect) {
          router.push({ path: context.$route.query.redirect });
        } else {
          if (jwtd.roles.indexOf("temp") === -1) {
            router.push({
              path: "/dashboard",
            });
          } else {
            router.push({
              path: "/temp-calendar",
            });
          }
        }
      })
      .catch((error) => {
        const errorData = error.response.data;
        if (errorData.status_code !== 403) {
          // check if contains `errors` object.
          if (Object.prototype.hasOwnProperty.call(errorData, "errors")) {
            context.errors.record(errorData.errors);
            return;
          } else {
            context.errors.record({
              0: [errorData.message],
            });
            return Promise.reject();
          }
        } else {
          const msg = errorData.error_message || errorData.message;
          context.errors.record({
            403: [msg || "Access forbidden"],
          });
          return Promise.reject();
        }
      });
  },
  /*
   *   Forgot password
   */
  getRecoveryLink(ctx, email) {
    // API call for password recovery
    // Link format: /password/reset/e99944a4748e7a6b9a7d4aba607b506a4bf63638500fe99d2e80b3c6ca73418c
    // Clear any previous errors
    ctx.errors.clear();
    return Vue.axios
      .post("api/auth/recovery", { email })
      .then((data) => {
        ctx.success = true;
        return Promise.resolve(data);
      })
      .catch((error) => {
        ctx.success = false;
        ctx.errors.record(error.response.data.errors);
        return Promise.reject(error);
      });
  },

  // Reset password dialog from recovery link.
  resetPassword(ctx, payload) {
    return Vue.axios.post("api/auth/reset", payload).catch((error) => {
      ctx.errors.record(error.response.data.errors);
      return Promise.reject(error);
    });
  },

  changeSettings(ctx, data) {
    if (Jwt.loggedIn()) {
      axiosConfig
        .patch("settings", data)
        .then((response) => {
          ctx.success = true;
          // refresh user data, so they reflect in DOM
          this.user.profile = response.data.data;

          // Fire off an event, for who may be interested in listening...
          Evt.fire("settings_changed");
        })
        .catch((error) => ctx.errors.record(error.response.data.errors));
    }
  },

  async signout() {
    try {
      await this.blacklistToken();
      router.push({
        name: "signin",
      });
    } catch (err) {
      console.log(">> signout msg", err.message);
    }
  },
  async blacklistToken() {
    axios.defaults.headers.common["Authorization"] = Jwt.getAuthHeader();

    await axios.post("api/auth/logout");
    console.log("blacklisting token...");
    Jwt.destroyToken();
    this.user.authenticated = false;
    this.user.profile = null;
  },
};
