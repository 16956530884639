import { default as axios } from "../config.js";

import { packLaravelArray } from "../../lib/helpers/function.js";

const api = {
  /*
   * Fetch Counties
   */
  fetchRegions: (params = {}) => {
    return axios.get("/regions", {
      params: { include: params.includes, non_empty: params.nonEmpty },
    });
  },
  /*
   * Fetch Sectors
   */
  fetchSectors: (params = {}) => {
    return axios.get("/sectors", {
      params: {
        include: params.includes,
        non_empty: params.nonEmpty,
        for_client: params.forClient,
      },
    });
  },
  /*
   *  Client specific categories
   */
  fetchClientCategories: (params) => {
    // Fetch client specific categories
    return axios.get("/categories", {
      params: {
        include: params.include,
        clients: params.clients,
      },
    });
  },
  fetchReportList: (params) =>
    axios({
      url: "reports",
      params: {
        ...params,
        timestamp: new Date().getTime(),
      },
    }),
  fetchReports: (param) => {
    // var url = param.report.report.urlPreview
    var url = param.report.report.previewendpoint;

    var fullRequestUrl = url;
    console.log("FULL REQUEST URL :: ", fullRequestUrl);
    var thingsToPack = [
      "clients",
      "categories",
      "subcategories",
      "locations",
      "temps",
    ];
    for (var i = 0; i < thingsToPack.length; i++) {
      var keyToPack = thingsToPack[i];
      console.log(param[keyToPack]);
      if (!param[keyToPack]) {
        continue;
      }
      var packed = packLaravelArray(keyToPack, param[keyToPack]);
      if (packed !== "") {
        // If first
        if (fullRequestUrl === url) {
          fullRequestUrl = fullRequestUrl + "?" + packed;
        } else {
          fullRequestUrl = fullRequestUrl + "&" + packed;
        }
      }
    }
    console.log("FULL REQUEST URL :: ", fullRequestUrl);
    return axios.get(`${fullRequestUrl}`, {
      params: {
        date_from: param.date_from,
        date_to: param.date_to,
        timestamp: new Date().getTime(),
        status: param.status,
      },
    });
  },

  downloadReport: (param) => {
    // var url = param.report.report.urlDownload
    var url = param.report.report.downloadendpoint;

    var fullRequestUrl = url;
    console.log("FULL REQUEST URL :: ", fullRequestUrl);
    var thingsToPack = [
      "clients",
      "categories",
      "subcategories",
      "locations",
      "temps",
    ];
    for (var i = 0; i < thingsToPack.length; i++) {
      var keyToPack = thingsToPack[i];
      if (!param[keyToPack]) {
        continue;
      }
      var packed = packLaravelArray(keyToPack, param[keyToPack]);
      if (packed !== "") {
        // If first
        if (fullRequestUrl === url) {
          fullRequestUrl = fullRequestUrl + "?" + packed;
        } else {
          fullRequestUrl = fullRequestUrl + "&" + packed;
        }
      }
    }

    return axios.get(`${fullRequestUrl}`, {
      params: {
        date_from: param.date_from,
        date_to: param.date_to,
        timestamp: new Date().getTime(),
        status: param.status,
      },
      responseType: "arraybuffer",
    });
  },
};

export default api;
