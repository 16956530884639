<template lang="pug">
.journal-simple-upload
  button.button.is-generic-app-blue.is-caps-lock.is-low(
    @click="onClickAddFile",
    v-if="isSelectionVisible"
  ) Select files
  ul
    li(v-for="fileObj in files")
      span.name(:title="fileObj.file.name") {{ fileObj.file.name }}
      //- span {{ fileObj.file.size }}
      span.remove(@click="onRemoveFile(fileObj)")
        img(src="../../../assets/images/comps/delete.svg", alt="")
  input.dragndrop__input(
    type="file",
    name="files[]",
    ref="input",
    multiple,
    @change="onSelectFile"
  )
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isSelectionVisible: false,
    };
  },
  mounted () {
    if (!this.files.length) {
      this.onClickAddFile();
    }
    this.isSelectionVisible = true;
  },
  methods: {
    onClickAddFile () {
      if (this.isDisabled) {
        return;
      }
      this.$refs.input.click();
    },
    onSelectFile (evt) {
      const files = [...evt.target.files].map(eFile => {
        return {
          id: uuidv4(),
          file: eFile,
        };
      });
      this.$emit('change-files', files);
      this.$refs.input.value = "";
    },
    onRemoveFile (file) {
      this.$emit('remove-file', file.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.journal-simple-upload {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 3px dashed rgba(0, 0, 0, 0.2);

  .button {
    width: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 5px;

    li {
      display: flex;
      justify-content: space-between;
      gap: 5px;

      .name {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .remove {
        min-width: 24px;
        width: 24px;
        cursor: pointer;
      }
    }
  }
}

.dragndrop__input {
  visibility: hidden;
}
</style>
