<template lang="pug">
section.section
  .columns(data-attr="filters")
    .field
      .control.hospital
        label.label Hospital
        multi-select(
          :options="hospitals",
          track-by="id",
          label="name",
          :showLabels="false",
          :multiple="false",
          @input="filterInvoices($event)",
          v-model="selectedHospital"
        )
    .field
      button.button.is-generic-app-teal(
        data-action,
        :disabled="closeModal",
        v-if="$can('create-invoice')",
        @click="showNewInvoiceModal(false)"
      )
        img(src="../../assets/images/comps/Plus.svg")
        | CREATE INVOICE
      button.button.is-generic-app-teal(
        data-action,
        :disabled="closeModal",
        @click="showNewInvoiceModal(true)",
        v-if="$can('create-invoices-for-all-clients')"
      )
        img(src="../../assets/images/comps/Plus.svg")
        | CREATE INVOICES FOR ALL CLIENTS
      button.button.help.is-generic-app-blue.is-outlined(
        v-tooltip.left="tooltipContent",
        v-if="canCreateAnyInvoice"
      ) HELP
    //- .field
      p.control
        input.input.has-icon-right(type="text", placeholder="Search")
        i#search.fa.fa-search
  table-invoices
  pagination(
    v-if="pagination",
    :total-pages="pagination.total_pages",
    :current-page="pagination.current_page",
    @page-changed="pageHasChanged"
  )
</template>

<script>
import Pagination from "../Pagination.vue";
import MultiSelect from "vue-multiselect";
import TableInvoices from "./TableInvoices.vue";
import { Evt } from "../../lib/helpers/Evt.js";
import { mapGetters } from "vuex";

export default {
  components: {
    MultiSelect,
    TableInvoices,
    Pagination,
  },
  props: ["hospitals", "closeModal"],
  data() {
    return {
      selectedHospital: null,
    };
  },
  computed: {
    ...mapGetters({
      pagination: "getPagination",
    }),
    canCreateAnyInvoice() {
      return (
        this.$can("create-invoice") ||
        this.$can("create-invoices-for-all-clients")
      );
    },
    tooltipContent() {
      return {
        content: `Click on top the top right "Create Invoice" button.  In new popup window fill in all required fields. After that click on the "Create" button.  The system will create an invoice for all shifts that have been made in the last 6 months.  If you want to check which shifts are processed or not just click on "All invoiced shifts" button.`,
        classes: `invoices-help-content`,
      };
    },
  },
  methods: {
    showNewInvoiceModal(createForAll) {
      Evt.fire("showNewInvoiceModal", createForAll);
    },
    filterInvoices(e) {
      this.$emit("filterInvoices", e);
    },
    pageHasChanged(pageNum) {
      this.$emit("page-has-changed", pageNum || 1);
    },
  },
};
</script>

<style lang="scss" scoped>
$color--light-gray: #dde6eb;
$color--light-blue: #8399a6;
$color--blue: #2669b0;
$color--border: #9bb4c1;
$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;
.columns {
  padding: 10px 0 0 10px;
}

.hospital {
  min-width: 300px;
}

button {
  margin-right: 15px;
}

.help {
  // color: #00a1fd;
  // border-color: #00a1fd;
  margin-top: 0;
  font-size: 15px;
}

.input {
  width: 200px;
  height: 40px;
}

#search {
  position: absolute;
  top: 7px;
  left: 175px;
}

img {
  margin-right: 5px;
}

.disabled {
  opacity: 0.5;

  .divider {
    padding: 25px 0 45px 0;
    border-top: 2px solid $color--light-gray;
    min-height: 50px;

    .breaker {
      position: absolute;
      width: 1px;
      height: 100px;
      background-color: $color--light-gray;
      right: 0;
      top: 35px;
    }
  }
}

.report-filters {
  .main-search {
    position: relative;
    padding-right: 25px;
    margin-right: 15px;

    .control {
      padding-top: 25px;
    }
  }

  .input {
    box-shadow: none;
    border: 1px solid $color--border;
    height: 37px;

    &::placeholder {
      color: $color--light-blue;
      font-size: 15px;
    }
  }

  .control {
    i {
      top: 10px;
      position: absolute;
      right: 20px;
      font-size: 14px;
      color: $color--light-blue;
    }
  }
}

.shift-filters {
  display: flex;
  margin-bottom: 19px;
  flex-wrap: wrap;
}

.field {
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  label {
    margin: 0 1em;
  }
}

.field:first-child {
  margin-right: auto;
}

.label {
  color: #7c91ae;
}

.help {
  .is-danger {
    position: relative;
    left: 150px;
    margin-bottom: 20px;
  }
}

.error {
  width: 100%;
  text-align: right;

  p {
    margin-right: 150px;
    margin-bottom: 10px;
  }
}
</style>

<style lang="scss">
.invoices-help-content {
  border-radius: 10px;
  // border: 1px solid var(--color-secondary-d20);
  border: transparent;
  background: white;
  margin: 60px 10px 0px 0px;
  width: 12%;
  font-size: 12pt;
}
</style>
