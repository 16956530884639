<template lang="pug">
<div class='manage-user' :class="{'edit-user': isEditUser}">
  h1.title.is-3
    span(style="cursor: pointer", @click="$router.go(-1)")
      svg(
        xmlns="http://www.w3.org/2000/svg",
        width="13",
        height="23",
        viewBox="0 0 13 23"
      )
        path(
          fill="#7591AE",
          stroke="#7991AE",
          d="M1.44295652,10.3728261 L10.8125217,1.00326087 C11.0429565,0.772826087 11.0429565,0.40326087 10.8125217,0.172826087 C10.582087,-0.0576086957 10.2125217,-0.0576086957 9.98208696,0.172826087 L0.195130435,9.95978261 C-0.0353043478,10.1902174 -0.0353043478,10.5597826 0.195130435,10.7902174 L9.98208696,20.5728261 C10.0951304,20.6858696 10.2473043,20.7467391 10.3951304,20.7467391 C10.5429565,20.7467391 10.6951304,20.6902174 10.8081739,20.5728261 C11.0386087,20.3423913 11.0386087,19.9728261 10.8081739,19.7423913 L1.44295652,10.3728261 Z",
          transform="translate(1 1)"
        )
    span.sep
    i.fa.icon-Edit-profile
    |
    | Edit Profile
  .loading-wrap(v-if="isLoading")
    .loading-wrapper
      img(src="../assets/images/comps/loader.svg")
    button.button.button.is-fullwidth.is-generic-app-teal.is-caps-lock(
      v-if="!isEditUser",
      @click="closeModal"
    ) Close
  form.mt.client-management(autocomplete="off", v-if="!isLoading")
    .lost-center
      .lost-shift
        p.control.name
          input.input.is-medium(
            v-model="user.name",
            type="text",
            placeholder="NAME",
            @input="inputFieldChanged('name')"
          )
        span.help.is-danger(
          v-if="errors.has('name')",
          v-html="errors.get('name')"
        )

        p.control
          input.input.is-medium(
            v-model="user.email",
            type="text",
            placeholder="EMAIL",
            @input="inputFieldChanged('email')",
            autocomplete="off"
          )
        span.help.is-danger(
          v-if="errors.has('email')",
          v-html="errors.get('email')"
        )

        p.control
          input.input.is-medium(
            v-model="user.password",
            type="password",
            placeholder="PASSWORD",
            @input="inputFieldChanged('password')",
            autocomplete="new-password"
          )
        span.help.is-danger(
          v-if="errors.has('password')",
          v-html="errors.get('password')"
        )
        PasswordPolicy(
          :passwordCharacters="user.password",
          @password-valid="handlePasswordIsValid"
        )

        p.control
          input.input.is-medium(
            v-model="user.confirmpass",
            type="password",
            placeholder="CONFIRM PASSWORD",
            @input="inputFieldChanged('confirmpass')",
            autocomplete="new-password",
            @change="errors.clear('confirmpassword')",
            :class="{ disable: confirmPasswordDisable }"
          )
        span.help.is-danger(
          v-if="errors.has('confirmpassword')",
          v-html="errors.get('confirmpassword')"
        )

        template(v-if="!isEditUser")
          p.control
            multi-select(
              :options="roles",
              :multiple="false",
              :close-on-select="true",
              @select="errors.clear('role_id')",
              track-by="id",
              label="label",
              v-model="user.userclass",
              @input="inputFieldChanged('userclass')",
              placeholder="SELECT USER CLASS"
            )
              span(slot="noResult") Nothing found.
          span.help.is-danger(
            v-if="errors.has('role_id')",
            v-html="errors.get('role_id')"
          )
            //-
              p.control
                multi-select(
                  :options='allJobCategories',
                  :multiple="true",
                  :close-on-select="false",
                  @select="errors.clear('subcategories')",
                  track-by="id",
                  label="name",
                  group-label="category",
                  group-values="locations",
                  v-model="user.jobs",
                  placeholder="SELECT APPLICABLE SUBCATEGORIES"
                )

          p.control
            treeselect(
              :load-options="loadOptions",
              :options="allJobCategories",
              :auto-load-root-options="false",
              v-model="user.jobs",
              :multiple="true",
              @open="errors.clear('subcategories')",
              @input="inputFieldChanged('jobs')",
              placeholder="SELECT APPLICABLE SUBCATEGORIES"
            )
              span(slot="noResult") Nothing found.
          span.help.is-danger(
            v-if="errors.has('subcategories')",
            v-html="errors.get('subcategories')"
          )

          p.control
            treeselect(
              :load-options="loadOptions",
              :options="locationsTreeselect",
              :auto-load-root-options="false",
              v-model="user.locations",
              :multiple="true",
              @open="errors.clear('locations')",
              @input="inputFieldChanged('locations')",
              placeholder="SELECT APPLICABLE WARDS"
            )
              span(slot="noResult") Nothing found.
          span.help.is-danger(
            v-if="errors.has('locations')",
            v-html="errors.get('locations')"
          )
        template
        //-
          p.control
            multi-select(
              :options='allLocations',
              :multiple="true",
              :close-on-select="false",
              @select="errors.clear('locations')",
              track-by="id",
              label="name",
              group-label="category",
              group-values="locations",
              v-model="user.locations",
              placeholder="SELECT APPLICABLE WARD"
            )
              span(slot="noResult") Nothing found.
          span.help.is-danger(v-if="errors.has('locations')", v-html="errors.get('locations')")
        //-
          p.control
            span  CAN SIGN OFF SHIFT TIMES?
            span.group
              label.radio
                input(type="radio" name="shifttimes" v-model="user.signoff" value="true")
                .check
                span YES
              label.radio
                input(type="radio" name="shifttimes" v-model="user.signoff" value="false")
                .check
                span NO

        p.control
          span ASSIGN PIN NUMBER
          span.rectangle.group
            input(
              type="text",
              @keyup="$event.target.nextElementSibling.focus()",
              @input="inputFieldChanged('pinArr')",
              @focus="$event.target.value = ''",
              maxlength="1",
              v-model="user.pinArr[0]"
            )
            input(
              type="text",
              @keyup="$event.target.nextElementSibling.focus()",
              @input="inputFieldChanged('pinArr')",
              @focus="$event.target.value = ''",
              maxlength="1",
              v-model="user.pinArr[1]"
            )
            input(
              type="text",
              @keyup="$event.target.nextElementSibling.focus()",
              @input="inputFieldChanged('pinArr')",
              @focus="$event.target.value = ''",
              maxlength="1",
              v-model="user.pinArr[2]"
            )
            input(
              type="text",
              @focus="$event.target.value = ''",
              maxlength="1",
              @input="inputFieldChanged('pinArr')",
              v-model="user.pinArr[3]"
            )
        span.help.is-danger(
          v-if="errors.has('pin')",
          v-html="errors.get('pin')"
        )
        //-
          p.control
            span CAN ACCESS TIME-SHEET ENTRY?
            span.group
              label.radio
                input(type="radio" name="timesheet" v-model="user.timesheet" value="true")
                .check
                span YES
              label.radio
                input(type="radio" name="timesheet" v-model="user.timesheet" value="false")
                .check
                span NO
        p.control(
          data-cell="report-toggles",
          v-for="(reportToggle, index) in reportTogglesFilter",
          :key="index"
        )
          label(style="text-transform: uppercase") {{ reportToggle.label }}
          span.group
            switches(
              v-model="user[reportToggle.key]",
              theme="bulma",
              color="blue",
              :emitOnMount="false",
              @input="toggleReport(reportToggle)"
            )
        p(
          v-if="isEditUser",
          :class="{ showMessagePasswordConfirmation: isShowMessagePasswordConfirmation, hideMessagePasswordConfirmation: isHideMessagePasswordConfirmation }"
        ) {{ passwordConformationMessage2 }}
        p(
          v-else,
          :class="{ showMessagePasswordConfirmation: isShowMessagePasswordConfirmation, hideMessagePasswordConfirmation: isHideMessagePasswordConfirmation }"
        ) {{ passwordConformationMessage }}
        .columns
          .column.is-12
            p.control
              button.button.is-fullwidth.is-generic-app-teal.is-caps-lock(
                @click.prevent="manageUserFunc",
                :class="{ 'is-disabled': false }",
                :disabled="disableConfirm || isLoadingManageUser"
              )
                i.fa.fa-check
                | <strong >{{ isEditUser ? "Save Changes" : "Create User" }}</strong>
  </div>
</template>
<script>
import MultiSelect from "vue-multiselect";
import Treeselect from "@riophae/vue-treeselect";

import { mapActions, mapGetters } from "vuex";
import { Errors } from "./../lib/helpers/Errors.js";
import { parseErrors } from "../lib/helpers/function";
import PasswordPolicy from "@/components/forms/PasswordPolicyValidation.vue";

export default {
  name: "EditUser",
  components: {
    MultiSelect,
    Treeselect,
    PasswordPolicy,
  },
  data() {
    return {
      passwordConformationMessage2: `The "SAVE CHANGES" button will become available once the password criteria is met and the identical password is entered in both fields.`,
      passwordConformationMessage: `The "CREATE USER" button will become available once the password criteria is met and the identical password is entered in both fields.`,
      isShowMessagePasswordConfirmation: false,
      isHideMessagePasswordConfirmation: true,
      confirmPasswordDisable: false,
      selectedClient: null,
      selectedUserclass: [],
      selectedLocations: [],
      isLoading: false,
      isLoadingManageUser: false,

      allJobCategories: null,
      allLocations: [],
      locationsTreeselect: null,
      user: {
        name: "",
        email: "",
        password: "",
        confirmpass: "",
        userclass: [], // User type
        jobs: [], // Job categories, subcategories
        locations: [], // Job locations
        signoff: false,
        pin: "",
        pinArr: [],
        timesheet: false,
        canViewUnbilledShiftsReport: false,
        canViewClientAssignedReport: false,
        canViewClientActivityReport: false,
        canViewClientUnassignedActivityReport: false,
        canViewUnprocessedShiftsReport: false,
        canViewAnnualLeaveBalancesReport: false,
        password_is_valid: false,
      },
      touchedKeys: new Set(),
      errors: new Errors(),
      editUserID: null,
      isEditUser: false,
      reportToggles: [
        {
          label: "Processed shifts report",
          key: "canViewUnbilledShiftsReport",
        },
        {
          label: "Client assigned report",
          key: "canViewClientAssignedReport",
        },
        {
          label: "Client activity report",
          key: "canViewClientActivityReport",
        },
        {
          label: "Client unassigned activity report",
          key: "canViewClientUnassignedActivityReport",
        },
        {
          label: "Holiday balance report",
          key: "canViewAnnualLeaveBalancesReport",
        },
        {
          label: "Unprocessed shifts report",
          key: "canViewUnprocessedShiftsReport",
          displayIfAdded: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      users: "getUsers",
      categories: "getAllCategories", // job types
      getCategories: "getCategories",
      roles: "getMinRoles", // Roles aka Visibility
      // sectors: 'locationClientManagement/getSectors',
      // sectors: 'locationClientManagement/getFlatSectors',
      clients: "getAllClients",
      pagination: "getPagination",
    }),
    disableConfirm() {
      // return (
      //   this.settings.password &&
      //   (!this.settings.password_is_valid ||
      //     this.settings.password_confirmation !== this.settings.password)
      // );

      if (!this.user.password) {
        this.isShowMessagePasswordConfirmation = false;
        return false;
      } else {
        this.isShowMessagePasswordConfirmation = true;
        if (
          !(
            this.user.password_is_valid &&
            this.user.confirmpass === this.user.password
          )
        ) {
          return true;
        } else {
          this.isShowMessagePasswordConfirmation = false;
          return false;
        }
      }
    },
    sectors() {
      return [
        {
          name: "",
          id: 1,
          clients: this.clients,
        },
      ];
    },
    pinCode() {
      return this.user.pinArr.join("");
    },
    reportTogglesFilter() {
      return this.reportToggles.filter((rt) => {
        if (rt.displayIfAdded) {
          return Object.prototype.hasOwnProperty.call(this.user, rt.key);
        } else if (rt.displayIfAdded === false) {
          return false;
        }
        return true;
      });
    },
  },
  watch: {
    $route(to) {
      this.editUserID = to.params.userId || null;
      this.isEditUser = Boolean(this.editUserID);
    },
    editUserID(id) {
      this.getUsers({ id }).then((res) => {
        if (res.data) {
          this.user.name = res.data.data.name;
          this.user.email = res.data.data.email;
          this.user.canViewUnbilledShiftsReport =
            res.data.data.canViewUnbilledShiftsReport;
          this.user.canViewClientAssignedReport =
            res.data.data.canViewClientAssignedReport;
          this.user.canViewClientActivityReport =
            res.data.data.canViewClientActivityReport;
          this.user.canViewClientUnassignedActivityReport =
            res.data.data.canViewClientUnassignedActivityReport;
          this.user.canViewAnnualLeaveBalancesReport =
            res.data.data.canViewAnnualLeaveBalancesReport;
          this.user.canViewUnprocessedShiftsReport =
            res.data.data.canViewUnprocessedShiftsReport;
          if (res.data.data.pin) {
            this.user.pinArr = res.data.data.pin.split("");
          }
        } else {
          console.error("Invalid response");
        }
      });
    },
  },
  mounted() {
    this.fetchAllCategoriesList().then(() => {
      this.allJobCategories = this.packForTreeselect(
        this.categories,
        "name",
        "subcategories"
      );
    });

    if (this.$route.params.userId) {
      this.editUserID = this.$route.params.userId;
    }
    this.isEditUser = Boolean(this.editUserID);

    if (!this.isEditUser) {
      // this.isLoading = true
      // Only load if creating new user [editing user doesn't change location]
      // this.fetchSectors({ includes: 'clients.locations' }).then(() => {
      const clientIncludes = ["locations"].join();
      this.fetchClientsList({
        includes: clientIncludes,
        per_page: 999,
      })
        .then(() => {
          console.log("Fetched Sectors.");
          this.allLocations = this.getLocationsFromSectors(this.sectors);
          this.locationsTreeselect = this.packForTreeselect(
            this.allLocations,
            "category",
            "locations"
          );
          this.isLoading = false;
        })
        .catch((err) => {
          console.log("[error]", err.message);
          this.isLoading = true;
        });
    }
  },
  methods: {
    ...mapActions({
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchAllRolesList: "fetchMinRoles",
      fetchClientsList: "fetchClientsList",
      // fetchSectors: 'locationClientManagement/fetchSectors',
      createUser: "createUser",
      patchUsers: "patchUsers",
      getUsers: "getUsers",
      clearUsers: "clearUsers",
    }),
    handlePasswordIsValid(data) {
      this.user.password_is_valid = data.isPasswordValid;
    },
    loadOptions(obj) {
      // console.log('Loading dropdown ', action, callback, instanceId);
      console.log("Loading dropdown", obj);
    },
    generateCategories(callback) {
      // Not used
      const rootOptions = this.allJobCategories;
      callback(null, rootOptions);
    },
    generateLocations(callback) {
      // Not used
      const rootOptions = this.locationsTreeselect;
      callback(null, rootOptions);
    },
    toggleReport(reportToggle) {
      console.log(
        `Report toggle: ${reportToggle.key}`,
        this.user[reportToggle.key]
      );
      this.inputFieldChanged(reportToggle.key);
    },
    manageUserFunc() {
      if (this.isEditUser) {
        this.editUserApi();
      } else {
        this.createNewUser();
      }
    },
    editUserApi() {
      let preparedData = {};
      if (this.user.password !== this.user.confirmpass) {
        this.errors.record({
          confirmpassword: [
            "The password and confirmed password do not match.",
          ],
        });
        return;
      }
      // this.isLoadingManageUser = true;

      preparedData = {};
      preparedData.id = this.$route.params.userId;
      preparedData.data = {};
      for (const key of this.touchedKeys) {
        const map = {
          confirmpass: "password_confirmation",
        };
        let keyStr = key;
        if (map[key]) {
          keyStr = map[key];
        }
        if (key === "pinArr") {
          preparedData.data.pin = this.pinCode;
        } else {
          preparedData.data[keyStr] = this.user[key];
        }
      }
      if (!Object.keys(preparedData.data).length) {
        return;
      }

      this.patchUsers(preparedData)
        .then(() => {
          this.$toasted.info("User edited successfully!").goAway(1500);
          this.touchedKeys.clear();
          this.getUsers({ id: this.editUserID });
          this.isLoadingManageUser = false;
        })
        .catch((errors) => {
          console.warn(errors);
          this.isLoadingManageUser = false;
          const errs = parseErrors(errors);
          if (errs) {
            this.$toasted.error(errs).goAway(1500);
          }
          this.errors.record(errors);
        });
    },
    createNewUser() {
      if (this.user.password !== this.user.confirmpass) {
        this.errors.record({
          confirmpassword: [
            "The password and confirmed password do not match.",
          ],
        });
        return;
      }
      this.isLoadingManageUser = true;

      const includes = ["roles", "locationCount", "subcategoryCount"].join();
      const preparedData = {
        data: {
          role_id: this.user.userclass.id,
          name: this.user.name,
          email: this.user.email,
          password: this.user.password,
          password_confirmation: this.user.confirmpass,
          canViewUnbilledShiftsReport: this.user.canViewUnbilledShiftsReport,
          canViewClientAssignedReport: this.user.canViewClientAssignedReport,
          canViewClientActivityReport: this.user.canViewClientActivityReport,
          canViewClientUnassignedActivityReport:
            this.user.canViewClientUnassignedActivityReport,
          canViewAnnualLeaveBalancesReport:
            this.user.canViewAnnualLeaveBalancesReport,
          canViewUnprocessedShiftsReport:
            this.user.canViewUnprocessedShiftsReport,
          subcategories: this.packFromTreeselect(
            this.user.jobs,
            this.categories,
            "subcategories"
          ),
          locations: this.packFromTreeselect(
            this.user.locations,
            this.allLocations,
            "locations"
          ),
          pin: this.pinCode,
        },
        query: includes,
      };

      this.createUser(preparedData)
        .then(() => {
          this.$toasted.info("User created successfully!").goAway(1500);
          this.resetFields();
          this.isLoadingManageUser = false;
          this.$emit("cancel-modal");
        })
        .catch((errors) => {
          this.isLoadingManageUser = false;
          this.handleAllErrorsToast(errors);
        });
    },
    handleAllErrorsToast(errors) {
      if (errors.errors) {
        const errs = parseErrors(errors.errors);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
        this.errors.record(errors.errors);
      } else if (errors.message) {
        this.$toasted.error(errors.message).goAway(3000);
      } else {
        this.$toasted.error("Unexpected error.").goAway(3000);
      }
    },
    closeModal() {
      this.$emit("cancel-modal");
    },
    resetFields() {
      this.user.name = "";
      this.user.email = "";
      this.user.password = "";
      this.user.confirmpass = "";
      this.user.userclass = [];
      this.user.jobs = [];
      this.user.locations = [];
      this.user.signoff = false;
      this.user.pin = "";
      this.user.pinArr = [];
      this.user.timesheet = false;
    },
    packFromTreeselect(selectedCategory, rootParams, childrenKey) {
      const selectedChildrenIds = [];
      const categoryIdMap = {};
      rootParams.forEach((category) => {
        categoryIdMap[category.id] = category;
      });
      selectedCategory.forEach((category) => {
        const isChild = (category + "").indexOf("$#") > 0;
        if (isChild) {
          // If child - then id is in format of ParentId$#ParentName$#ChildId
          // We take the child id, which is split[2]
          selectedChildrenIds.push({ id: parseInt(category.split("$#")[2]) });
        } else {
          categoryIdMap[category][childrenKey].forEach((subcategory) => {
            selectedChildrenIds.push({ id: subcategory.id });
          });
        }
      });
      console.warn(selectedCategory, selectedChildrenIds);
      return selectedChildrenIds;
    },
    packForTreeselect(rootParams, parentName, childrenKey) {
      const treeselectData = [];
      rootParams.forEach((param) => {
        const id = param.id;
        const label = param[parentName];
        const children = [];
        const parent = { id, label, children };
        param[childrenKey].forEach((rootChild) => {
          const id = parent.id + "$#" + parent.label + "$#" + rootChild.id;
          const label = rootChild.name;
          const child = { id, label };
          children.push(child);
        });
        treeselectData.push(parent);
      });
      return treeselectData;
    },
    getLocationsFromSectors(sectors) {
      const usedClients = [];
      const _sectors = JSON.parse(JSON.stringify(sectors));
      // console.log('SECTORS :: ', _sectors);
      const locations = [];
      _sectors.forEach((_sector) => {
        _sector.clients.map((client) => {
          if (!usedClients.includes(client.id)) {
            const groupingObj = {};
            groupingObj.id = client.id;
            groupingObj.category = client.name;
            groupingObj.locations = [];
            client.locations.map((location) => {
              groupingObj.locations.push(location);
            });
            locations.push(groupingObj);

            usedClients.push(client.id);
          }
        });
      });
      // console.log('Returning CCs: ', locations);

      return locations;
    },
    inputFieldChanged(keyStr) {
      if (keyStr) {
        this.touchedKeys.add(keyStr);
      }
    },
  },
};
</script>

<style lang="scss">
.manage-user {
  width: 100%;
  padding: 20px 4px;

  .hideMessagePasswordConfirmation {
    color: red;
    display: none;
  }
  .showMessagePasswordConfirmation {
    color: red;
    display: block;
    text-align: center;
  }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost-shift {
    width: calc(99.9% * 2 / 3 - (0px - 81px * 2 / 3));
    margin-left: calc(
      99.9% * (-1 / 6 * -1) - (63px - 31px * (-1 / 6 * -1)) + 30px
    ) !important;
  }
  span.sep {
    border: 1px solid #d6e0e5;
    margin: 0 0.8em;
  }

  &:not(.edit-user) {
    & > .title {
      display: none;
    }
  }
}
</style>
