<template lang="pug">
.dialog-container
  .columns.is-multiline.spacer-align
    .column(v-for="statusEl in filteredStatuses")
      button.button.is-small.is-fullwidth.is-caps-lock(
        :disabled="isLoading || checkApproval(statusEl)",
        :style="getButtonStyle(statusEl)",
        :class="spinner(statusEl.id)",
        type="submit",
        @click.prevent="callActionButton(statusEl.id)"
      ) {{ statusEl.label }}
  .column(v-if="(dnrStatus === 1 && isDisplayedDatePicker) || dnrStatus === 4")
    label Choose an expiry date for this approval.
    date-picker(
      :date="startTime",
      :option="dateTimeOption",
      v-if="dateTimeOption",
      title="Approval time",
      @change="datePickedChange",
      :isDisabled="checkApprovalDate"
    )
  .buttons-centered
    button.button.is-small.is-outlined.is-caps-lock(
      data-attr="cancel-btn",
      @click.prevent="$emit('cancel-modal')"
    ) Cancel
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions } from "vuex";
import calendarOptions from "./../common/calendarOptions.js";
import { getFormattedTime } from "../../lib/helpers/function.js";
export default {
  props: ["modalProps"],
  data() {
    return {
      dateTimeOption: null,
      dnrStatus: null,
      isLoading: false,
      activeStatus: null,
      isDisplayedDatePicker: false,
      startTime: {},
      tempData: {},
      statusElements: [
        {
          id: 1,
          label: "Active",
          backColor: "#23d160",
        },
        {
          id: 2,
          label: "Review",
          color: "black",
          backColor: "#ffdb4a",
        },
        {
          id: 3,
          label: "Inactive",
          backColor: "#ff2b56",
        },
        {
          id: 4,
          label: "Approval",
          color: "black",
          backColor: "#a8bc46",
          action: () => (this.isDisplayedDatePicker = true),
        },
      ],
    };
  },
  computed: {
    filteredStatuses() {
      return this.statusElements.filter((statusEl) => {
        if (statusEl.id === 4 && this.dnrStatus !== 1) {
          return false;
        }
        return statusEl.id !== this.dnrStatus;
      });
    },
    spinner() {
      return function (button) {
        return { "is-loading": this.isLoading && button === this.activeStatus };
      };
    },
    checkApprovalDate() {
      return !this.$can("manager-approval-status");
    },
  },
  mounted() {
    this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
    this.dateTimeOption.type = "day";
    this.dateTimeOption.format = "DD/MM/YYYY";
    this.dnrStatus = this.modalProps.dnrStatus;
    this.tempData = this.modalProps.temp;
    if (this.tempData.compliance_exception_to) {
      this.startTime = {
        time: getFormattedTime(
          this.tempData.compliance_exception_to,
          "DD/MM/YYYY"
        ),
      };
    }
  },
  methods: {
    ...mapActions(["patchTemp"]),
    callActionButton(statusId, date) {
      const foundObj = this.statusElements.find((el) => el.id === statusId);
      if (foundObj && foundObj.action) {
        foundObj.action();
      } else {
        this.updateDnr(statusId, date);
      }
    },
    checkApproval(statusEl) {
      return (
        (!this.$can("manager-approval-status") &&
          statusEl.label == "Approval") ||
        (this.dnrStatus &&
          this.dnrStatus == 4 &&
          !this.$can("manager-approval-status"))
      );
    },
    async updateDnr(status, date) {
      this.isLoading = true;
      this.activeStatus = status;
      try {
        await this.patchTemp({
          id: this.modalProps.tempId,
          data: {
            // name: this.modalProps.name,
            status: status,
            compliance_exception_to: date,
          },
          query: {
            include: "consultant,locations",
          },
        });
        this.callModalClose();
        this.isLoading = false;
      } catch (err) {
        console.log(err.message);
      }
    },
    datePickedChange(val) {
      if (val) {
        const date = this.startTime.time;
        const outputDate = getFormattedTime(date, "YYYY-MM-DD", "DD/MM/YYYY");
        this.updateDnr(1, outputDate);
      }
    },
    callModalClose() {
      this.$emit("dispose-modal");
    },
    getButtonStyle(status) {
      return {
        color: status.color || "white",
        backgroundColor: status.backColor,
      };
    },
  },
  components: { MultiSelect },
};
</script>
<style lang="scss">
.modal-content {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;

  .columns {
    flex-direction: column;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
}

.inputs {
  display: flex;
  justify-content: center;

  .field {
    width: 100%;
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

label {
  margin-top: 0.5em;
}

.spacer-align {
  margin: 20px 0 0 0;
}
</style>
