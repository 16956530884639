<template lang="pug">
.edit-increments-modal-wrap
  .increment-weeks-counter-section(
    v-if="gPermissions.canViewIncrementWeekCounter && canUseIncrementCo"
  )
    label.label Weekly Increment Counter
    .bottom-bar
      multi-select(
        :options="incrementWeekList",
        :multiple="false",
        :close-on-select="true",
        track-by="weeks_counter",
        label="weeks_counter",
        :value="selectedIncrementWeek",
        placeholder="Select increment week",
        :loading="loadingIncCounterValue",
        :disabled="!gPermissions.canEditIncrementWeekCounter",
        @select="onSelectIncrementWeek"
      )
      template(v-if="gPermissions.canEditIncrementWeekCounter")
        button.button.is-generic-app-blue.is-caps-lock(
          :disabled="isDisabledIncWeekBtn",
          @click="onResetIncrementWeek"
        )
          span Reset
        button.button.is-generic-app-blue.is-caps-lock(
          :disabled="isDisabledIncWeekBtn",
          @click="onClickUpdate"
        )
          span Update
  .field(style="display: flex", v-if="isModalSubCatName")
    label.label(for="show-deleted-increments") Show deleted increments:
      input#show-deleted-increments(
        style="margin-left: 10px",
        type="checkbox",
        v-model="isShowDeleted"
      )
  p.control
    .picker(v-if="!isModalSubCatName")
      label.label Subcategories:
      multi-select(
        :options="optionsSubcategories",
        :multiple="false",
        :close-on-select="true",
        track-by="id",
        label="name",
        group-label="category",
        group-values="subs",
        v-model="selectedSubcategories",
        placeholder="PICK A SUBCATEGORY",
        @select="errors.clear('subcategories')"
      )
      span.help.is-danger(
        v-if="errors.has('subcategories')",
        v-html="errors.get('subcategories')"
      )
    .sub-label(v-if="isModalSubCatName")
      label {{ modalSubcategoryLabel }}
      button.button.is-generic-app-blue(
        @click="removeSubcategory",
        data-cell="remove-subcategory"
      ) Remove subcategory
  p.control
    .field.table-wrap
      table.table
        thead
          tr
            th.center EFFECTIVE DATE
            th.center INCREMENT POINT
            th.center CREATED AT
            //- th.center CHANGED AT
            th.center DELETED AT
            th.center ACTION
        tbody
          tr(v-if="gPermissions.canEditTempIncrement")
            td(style="width: 200px")
              date-picker(:date="startDate", :option="dateTimeOption")
            td(style="width: 220px")
              multi-select(
                :options="allIncrementsPoints",
                track-by="id",
                label="name",
                v-model="newPending.incrementPoint",
                placeholder="Increment Point"
              )
            td
            td
            td.editincremets-center
              button.button.is-generic-app-blue.add-action(
                title="Add increment",
                @click="addNewIncrement",
                :disabled="!isPendingFilled"
              )
                span.fa.fa-plus
          tr(
            :class="{ deleted: increment.deletedAt }",
            v-for="increment in incrementsListTable"
          )
            td.editincremets-center(data-cell="start-date") {{ increment.startDate }}
            td.editincremets-center(data-cell="increment-point") {{ increment.name }}
            td.editincremets-center
              span(v-if="increment.createdAt")
                span(style="margin-right: 10px", data-cell="created-at") {{ increment.createdAt }}
                span.fa.fa-info-circle(
                  :style="{ visibility: increment.created_by ? 'unset' : 'hidden' }",
                  :title="updatedTitle(increment.created_by, 'Created')"
                )
            //- td.editincremets-center
            //-   span(v-if='increment.updatedAt')
            //-     span(style='margin-right: 10px') {{ increment.updatedAt }}
            //-     span.fa.fa-info-circle(
            //-       :style="{'visibility': increment.updated_by ? 'unset': 'hidden'}"
            //-       :title="updatedTitle(increment.updated_by, 'Updated')"
            //-       )
            td.editincremets-center
              span(v-if="increment.deletedAt")
                span(style="margin-right: 10px", data-cell="deleted-at") {{ increment.deletedAt }}
                span.fa.fa-info-circle(
                  :style="{ visibility: increment.deleted_by ? 'unset' : 'hidden' }",
                  :title="updatedTitle(increment.deleted_by, 'Deleted')"
                )
            td.editincremets-center
              button.button.remove-action(
                data-cell="delete-button",
                title="Delete increment",
                :style="{ visibility: increment.deletedAt ? 'hidden' : 'unset' }",
                v-if="gPermissions.canDeleteTempIncrement",
                @click="removeRefreshIncrement(increment)"
              )
                span.fa.fa-times
  p.control(style="display: inline-block", v-if="isModalSubCatName")
    label.label(for="update-category-increments") Update for whole category:
      input#update-category-increments(
        style="margin-left: 10px",
        type="checkbox",
        :checked="isWholeCategorySel",
        @click="changeForAllCategory"
      )
    .text-error
      span(v-for="(oneErrorText, index) in errorText", :key="index")
        div {{ oneErrorText }}
  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      @click.prevent="callModalClose"
    ) CLOSE
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
// import _ from 'underscore'
import { Evt } from "../../../lib/helpers/Evt.js";
import calendarOptions from "../../common/calendarOptions.js";
import moment from "moment";
import { mapGetters, mapActions, mapState } from "vuex";
import MultiSelect from "vue-multiselect";
import { Errors } from "../../../lib/helpers/Errors.js";
import {
  confirmApplyChanges,
  findRecordErrors,
  parseErrors,
} from "../../../lib/helpers/function.js";

export default {
  // TODO: Implement Vuex storage for "Edit Candidate"
  /*
   *  Receives the following data
   *  tempIncrements - All increments for this group belonging to this temp
   *  clickedIncrement - The actual clicked increment object
   *  tempId - The temp id
   */
  components: {
    MultiSelect,
  },
  props: ["modalProps"],
  data() {
    return {
      dateTimeOption: null,
      MultiShift: null,

      startDate: { time: "" },
      // Deep-clone the data so we don't end up messing with
      // vuex when we modify data for our purposes
      newPending: {
        incrementPoint: null,
      },
      newPendingIds: {
        cat: null,
        sub: null,
      },
      isShowDeleted: false,
      incrementData: this.modalProps,
      isWholeCategorySel: false,
      errors: new Errors(),
      selectedSubcategories: null,
      selectedIncrementWeek: null,
      allIncrementsPoints: [],
      // preparedData: {},
      errorText: [],
      incrementWeekValue: [],
      incrementWeekList: [],
      isDisabledIncWeekBtn: false,
      loadingIncCounterValue: false,
    };
  },
  computed: {
    ...mapGetters({
      optionsSubcategories: "getCategoriesWithSubcategories",
      tempProfile: "getTempProfile",
    }),
    canUseIncrementCo() {
      return this.initialStatus?.can_use_increment_counter;
    },
    isModalSubCatName() {
      const originalName =
        this.modalProps.category && this.modalProps.category.name;
      return originalName || this.incrementsListTable.length;
    },
    modalSubcategoryLabel() {
      const hasOutCat =
        this.modalProps.category && this.modalProps.category.name;
      const selSubName =
        this.selectedSubcategories && this.selectedSubcategories.name;
      if (selSubName) {
        return selSubName;
      } else if (hasOutCat) {
        return hasOutCat;
      }
      return "";
    },
    incrementsListTable() {
      return [...this.currentIncrementData].sort((a, b) => {
        if (a.startDate < b.startDate) {
          return 1;
        } else if (b.startDate < a.startDate) {
          return -1;
        }
        return 0;
      });
    },
    categoryId() {
      return (
        this.incrementData.clickedIncrement?.category?.parent_id ||
        this.newPendingIds.cat ||
        null
      );
    },
    subCatId() {
      return (
        this.incrementData.clickedIncrement?.category?.id ||
        this.newPendingIds.sub ||
        null
      );
    },
    currentIncrementData() {
      if (this.subCatId) {
        const subCatId = this.subCatId;
        // Filter by cats
        const catInt = this.incrementData.userObj.increments.filter(
          (el) => subCatId === el.category.id
        );
        return catInt.filter((el) => {
          if (this.isShowDeleted) {
            return el;
          } else {
            return !el.deletedAt;
          }
        });
      } else {
        return [];
      }
    },
    isPendingFilled() {
      const isIncPt = Boolean(this.newPending.incrementPoint);
      const isStartDate = Boolean(this.startDate.time);
      if (isIncPt && isStartDate) {
        return true;
      }
      return false;
    },
  },
  watch: {
    tempProfile: {
      handler() {
        this.$set(this.incrementData, "userObj", this.tempProfile.data);
      },
      deep: true,
    },
    isShowDeleted() {
      Evt.fire("incrementDataChanged");
    },
    selectedSubcategories: {
      handler() {
        this.newPendingIds = {
          cat:
            this.selectedSubcategories &&
            this.selectedSubcategories.catParentId,
          sub: this.selectedSubcategories && this.selectedSubcategories.id,
        };
        if (this.newPendingIds.cat) {
          this.fetchIncrementsPoints(this.newPendingIds.cat);
        } else {
          // Clear increments
          this.newPendingIds = {};
          this.allIncrementsPoints = [];
        }
      },
      deep: true,
    },
    isWholeCategorySel(val) {
      if (val) {
        this.updateWholeCategories(val);
      }
    },
  },
  created() {
    this.setupDateTimePickerOpt();
    if (this.gPermissions.canEditTempIncrement) {
      this.checkFetchIncrementsPoints();
    }

    if (
      this.canUseIncrementCo &&
      this.gPermissions.canViewIncrementWeekCounter
    ) {
      this.fetchIncrementWeeksData();
    }
  },
  methods: {
    ...mapActions([
      "postTempIncrement",
      "deleteTempIncrement",
      "getIncrements",
      "postUpdateForCategory",
      "getIncrementWeeksCounterValue",
      "getIncrementWeeksCounter",
      "postIncrementWeeksCounterValue",
    ]),
    async fetchIncrementWeeksData() {
      this.loadingIncCounterValue = true;
      await this.fetchIncrementWeeksRange();
      await this.fetchIncrementWeeksCounterValue();
    },
    async fetchIncrementWeeksRange() {
      try {
        const res = await this.getIncrementWeeksCounter();
        const { from, to } = res.data.increment_counter_range || {};
        const leng = to - from + 1;
        this.incrementWeekList = Array.from({ length: leng }).map(
          (val, ind) => {
            return {
              subcategory_id: this.subCatId,
              weeks_counter: ind + from,
            };
          }
        );
      } catch (err) {
        console.warn(err.message);
      }
    },
    async fetchIncrementWeeksCounterValue() {
      this.loadingIncCounterValue = true;
      const params = {
        id: this.incrementData.userObj.id,
        subcategory_id: this.subCatId,
      };
      try {
        const res = await this.getIncrementWeeksCounterValue(params);
        this.incrementWeekValue = res.data.data || [];
        if (this.incrementWeekValue.length) {
          this.selectedIncrementWeek = this.incrementWeekList.find(
            (inc) =>
              inc.weeks_counter === this.incrementWeekValue[0].weeks_counter
          );
        }
        this.loadingIncCounterValue = false;
      } catch (err) {
        this.loadingIncCounterValue = false;
        console.warn(err.message);
      }
    },
    checkFetchIncrementsPoints() {
      if (this.categoryId) {
        this.fetchIncrementsPoints(this.categoryId);
      }
    },
    async fetchIncrementsPoints(catId) {
      const params = {
        category_id: catId,
      };
      try {
        const res = await this.getIncrements(params);
        this.allIncrementsPoints = this.formatIncrements(res.data.data).sort();
        // console.log('increment points', this.allIncrementsPoints);
      } catch (err) {
        console.warn(err.message);
      }
    },
    formatIncrements(dataArr) {
      // Sort increments
      return dataArr.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
    },
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "DD/MM/YYYY";
      this.dateTimeOption.placeholder = "Select date";
    },
    async updateWholeCategories() {
      this.errorText = [];

      if (!this.startDate.time) {
        this.errorText.push("Effective date is not populated.");
      }

      const incPointId =
        this.newPending &&
        this.newPending.incrementPoint &&
        this.newPending.incrementPoint.id;
      if (!incPointId) {
        this.errorText.push("Increment point is not populated.");
      }

      if (this.errorText.length) {
        // Reset checkbox so it can be reselected when error is fixed

        this.isWholeCategorySel = false;
        return;
      }

      const startDate = moment(this.startDate.time, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );

      const params = {
        category_id: this.categoryId,
        start_date: startDate,
        increment_id: incPointId,
        temp_id: this.incrementData.userObj.id,
      };
      await this.postUpdateForCategory(params);
      Evt.fire("incrementDataChanged");
      this.callModalClose();
    },
    callModalClose() {
      this.$emit("cancel-modal");
    },
    addNewIncrement() {
      if (!this.isPendingFilled) {
        console.warn("Error adding new increment");
        return;
      }

      const addFunc = () => {
        const currDate = moment(this.startDate.time, "DD/MM/YYYY");
        const slicedDate = currDate.format("YYYY-MM-DD").slice(0, 10);
        const params = {
          category_id: this.subCatId,
          start_date: slicedDate,
          increment_id: this.newPending.incrementPoint.id,
          temp_id: this.incrementData.userObj.id,
        };
        this.postTempIncrement(params)
          .then(() => {
            // Reset obj
            for (const key of Object.keys(this.newPending)) {
              this.$set(this.newPending, key, null);
            }
            this.startDate.time = "";

            // Fetch new
            Evt.fire("incrementDataChanged");
          })
          .catch((err) => {
            console.warn(err.message);
            if (err.message && err.response.data.message) {
              this.$toasted.error(`${err.response.data.message}`).goAway(1500);
            }
          });
      };
      confirmApplyChanges(this, "create a new increment").then(() => {
        addFunc();
      });
    },
    removeRefreshIncrement(inc) {
      const remFunc = async () => {
        const params = {
          incrementTempId: inc.incrementTempId,
          temp_id: this.incrementData.userObj.id,
        };
        try {
          const isRemoved = await this.removeIncrementApi(params);
          if (isRemoved) {
            // Fetch new
            Evt.fire("incrementDataChanged");
          }
        } catch (err) {
          console.warn(err.message);
        }
      };

      confirmApplyChanges(this, "delete this increment").then(() => {
        remFunc();
      });
    },
    async removeIncrementApi(params) {
      try {
        await this.deleteTempIncrement(params);
        return true;
      } catch (err) {
        console.warn(err.message);
        if (err.message && err.response.data.message) {
          this.$toasted.error(`${err.response.data.message}`).goAway(1500);
        }
        return false;
      }
    },
    async removeSubcategory() {
      try {
        confirmApplyChanges(this, "delete this subcategory").then(async () => {
          // Delete all increments
          for (const inc of this.incrementsListTable) {
            const params = {
              incrementTempId: inc.incrementTempId,
              temp_id: this.incrementData.userObj.id,
            };

            await this.removeIncrementApi(params);
          }
          Evt.fire("incrementDataChanged");
          this.callModalClose();

          // Evt.fire('removeSubCatAndSave', this.subCatId)
        });
      } catch (err) {
        console.warn(err.message);
      }
    },
    async changeForAllCategory(evt) {
      const newStateChecked = evt.target.checked;
      if (newStateChecked === true) {
        evt.preventDefault();
        // const oldMsg = 'update increment for the subcategory in the current branch'
        const msg = "update the entire category for this candidate";
        try {
          await confirmApplyChanges(this, msg);
          this.isWholeCategorySel = true;
        } catch (err) {
          // ignored
        }
      } else {
        this.isWholeCategorySel = false;
      }
    },
    updatedTitle(incObjPerson, actText) {
      if (incObjPerson && typeof incObjPerson === "object") {
        return `${actText} by: ${this.handleNoStr(
          incObjPerson.name
        )} [${this.handleNoStr(incObjPerson.email)}]`;
      }
    },
    handleNoStr(str) {
      if (str) {
        return str;
      }
      return "";
    },
    onClickUpdate() {
      this.updateIncrementWeeks(true);
    },
    async updateIncrementWeeks(isPopup = false) {
      const processUpdateIncrementWeeks = async () => {
        this.isDisabledIncWeekBtn = true;
        const params = {
          id: this.incrementData.userObj.id,
          payload: {
            weeks_counter: this.selectedIncrementWeek?.weeks_counter,
            subcategory_id: this.subCatId,
          },
        };
        if (this.incrementWeekValue?.[0]?.id) {
          params.payload.id = this.incrementWeekValue?.[0]?.id;
        }
        try {
          const res = await this.postIncrementWeeksCounterValue(params);
          this.$toasted.info(res.data.message).goAway(2500);
          await this.fetchIncrementWeeksCounterValue();
          this.isDisabledIncWeekBtn = false;
        } catch (err) {
          this.isDisabledIncWeekBtn = false;
          console.warn(err.message);
          const errors = findRecordErrors(err);
          if (errors) {
            this.errors.record(errors);
            this.$toasted
              .show("Please correct the following errors.")
              .goAway(2500);
            this.errors.all().map((e) => {
              this.$toasted.error(e).goAway(2500);
            });
          } else {
            const errs = parseErrors(
              err,
              "Error saving weekly increment counter",
              true
            );
            console.warn(err.response.data.message);
            this.$toasted.error(errs).goAway(4500);
          }
        }
      };

      if (isPopup) {
        try {
          await confirmApplyChanges(this, "update increment counter");
          await processUpdateIncrementWeeks();
        } catch (err) {
          // ignored
        }
      } else {
        await processUpdateIncrementWeeks();
      }
    },
    async onSelectIncrementWeek(evt) {
      this.errors.clear("increment_weeks");
      this.selectedIncrementWeek = evt;
    },
    async onResetIncrementWeek() {
      try {
        await confirmApplyChanges(this, "reset increment counter");

        this.errors.clear("increment_weeks");
        const firstEl = this.incrementWeekList?.[0];
        if (firstEl) {
          this.selectedIncrementWeek = firstEl;
          this.updateIncrementWeeks();
        } else {
          console.warn("Cant reset increment counter", this.incrementWeekList);
        }
      } catch (err) {
        // ignored
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .increment-tag {
//   height: 22px;
//   width: 63px;
//   opacity: 0.76;
//   border-radius: 3px;
//   font-weight: bold;
//   margin: auto;

//   &.pending {
//     background-color: #d19f1c;
//   }

//   &.active {
//     background-color: #2cb0ff;
//   }

//   &.history {
//     background-color: #b6c6cd;
//   }

//   span {
//     &.tagname:hover + .cancel {
//       // Display the cancel button on whole tag mouseover
//       display: block;
//     }

//     &.tagname {
//       text-transform: uppercase;
//       width: 100%;
//       color: #ffffff;
//       font-family: Lato;
//       font-size: 11px;
//       font-weight: 900;
//       line-height: 13px;
//       display: inline-block;
//       text-align: center;
//     }
//   }

//   .wrapper {
//     position: relative;

//     &:hover .cancel {
//       // Display the cancel button on wrapper mouseover
//       display: block;
//     }

//     .cancel {
//       position: absolute;
//       top: -6px;
//       right: -6px;
//       border-radius: 20px;
//       background-color: red;
//       width: 12px;
//       height: 12px;
//       font-size: 9px;
//       color: white;
//       display: none;

//       &:hover {
//         background-color: gray;
//         cursor: pointer;
//         display: block;
//       }
//     }
//   }
// }

.text-error {
  color: crimson;
}

.sub-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

td.editincremets-center {
  text-align: center;
}

th.center {
  text-align: center;
}

.modal-content {
  width: 80%;
  margin: 0 auto;
  padding: 0px;
}
</style>

<style lang="scss">
.edit-increments-modal-wrap {
  .increment-weeks-counter-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 10px 0;
    border-bottom: 1px solid gray;

    .bottom-bar {
      display: flex;
      align-items: center;
      gap: 10px;

      .multiselect {
        width: 200px;
      }
    }
  }

  .cov-vue-date {
    display: flex;
    justify-content: center;

    input {
      font-size: 14px !important;
      font-weight: 400;
      background: white;
    }
  }
  .cov-datepicker {
    width: 180px;
    border: 1px solid #e8e8e8 !important;
    box-shadow: unset !important;
    height: 40px;
  }

  .table-wrap {
    max-height: calc(100vh - 200px);
    overflow: auto;
    min-height: 400px;

    tr {
      &.deleted {
        background: rgb(241, 241, 241);
        td {
          color: gray;
        }
      }
    }

    .fa-info-circle {
      transition: 0.2s ease all;
      // &:hover {
      //   color: royalblue;
      //   cursor: pointer;
      // }
    }
  }

  button {
    &.add-action {
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }

    &.remove-action {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: 0.2s ease all;

      &:hover {
        background: lightcoral;
        color: white;
      }
    }
  }
}
</style>
