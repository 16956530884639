<template lang="pug">
.password-policy-wrapper
  span.validation-new-password
    p(
      :class=`{
          correct: passwordValidationStore.passwordValidation.length.isValid && allValidate,
          inInputNotCorrect: !passwordValidationStore.passwordValidation.length.isValid && allValidate,
        }`
    )
      | 8 chars&nbsp;
    p(
      :class=`{
          correct: passwordValidationStore.passwordValidation.upperCase.isValid && allValidate,
          inInputNotCorrect: !passwordValidationStore.passwordValidation.upperCase.isValid && allValidate,
        }`
    )
      | &nbsp;Uppercase letter&nbsp;
    p(
      :class=`{
          correct: passwordValidationStore.passwordValidation.lowerCase.isValid && allValidate,
          inInputNotCorrect: !passwordValidationStore.passwordValidation.lowerCase.isValid && allValidate,
        }`
    )
      | &nbsp;Lowercase letter&nbsp;
    p(
      :class=`{
          correct: passwordValidationStore.passwordValidation.number.isValid && allValidate,
          inInputNotCorrect: !passwordValidationStore.passwordValidation.number.isValid && allValidate,
        }`
    )
      | &nbsp;Number&nbsp;
    p(
      :class=`{
          correct:
            passwordValidationStore.passwordValidation.specialCharacter.isValid && allValidate,
            inInputNotCorrect: !passwordValidationStore.passwordValidation.specialCharacter.isValid && allValidate,
        }`
    )
      | &nbsp;Special char
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "PasswordPolicy",
  props: {
    passwordCharacters: String,
  },
  data() {
    return {
      allValidate: false,
      inInputNotCorrect: false,
    };
  },
  computed: {
    ...mapState({
      passwordValidationStore: (state) => state.passwordValidationStore,
    }),
  },
  watch: {
    passwordCharacters: function (val) {
      // this.$emit("password-valid", param);
      if (val) {
        this.validatePasswordCriteria(val);
        this.allValidate = true;
      } else {
        this.allValidate = false;
        // param.password = val;
        // param.isPasswordValid = this.passwordValidationStore.passwordIsValid;
        // this.$emit("password-valid", param);
      }
    },
  },
  mounted() {
    // this.resetPasswordCriterium();
  },
  methods: {
    ...mapActions({
      passwordValidation: "passwordValidation",
    }),
    async validatePasswordCriteria(val) {
      let res = await this.passwordValidation(val);
      // console.log(res.data.data.passwordIsValid);
      let param = {
        password: val,
        isPasswordValid: res.data.data.passwordIsValid,
      };
      this.$emit("password-valid", param);
    },
  },
};
</script>
<style lang="scss">
.password-policy-wrapper {
  width: 100%;
  .validation-new-password {
    display: flex;
    justify-content: center;
    padding: 5px 0px 0px 0px;
    gap: 1%;
    color: #c2c2c2;
    .correct {
      color: #2cb117;
    }
    .inInputNotCorrect {
      color: rgb(228, 8, 8);
    }
  }
}
</style>
