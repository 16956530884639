import moment from "moment";
import _ from "underscore";
import { LOG_TYPE_TEMPLATE_MSGS } from "../../../src/components/config/shifts.js";
import JSZip from "jszip";

const pad = (value) => (value < 10 ? `0${value}` : value);

/**
 * Helper for select/unselect checkboxes.
 * Finds the difference between the two objects.
 *
 * Substracts o2 from o1
 */
const diffArrayOfObjects = (o1, o2) => {
  const ids = {};
  _.each(o2, (item) => {
    ids[item.id] = true;
  });
  console.log("different keys", ids);

  const diff = _.filter(
    o1,
    (val) => {
      return !ids[val.id];
    },
    o2
  );

  return diff;
};

export function parseObjWithStartEnd(inputObj) {
  const output = {};
  if (inputObj.startTime) {
    output.startTime = parseDateObjWithTimeZone(inputObj.startTime);
  }
  if (inputObj.endTime) {
    output.endTime = parseDateObjWithTimeZone(inputObj.endTime);
  }
  return output;
}

export function parseDateObjWithTimeZone(inputObj) {
  // WIP [BE] Add timezones when BE support it
  // Example
  // { "date": "2017-12-03 20:00:00", "timezone_type": 3, "timezone": "Europe/London" }
  if (inputObj && typeof inputObj === "object") {
    return inputObj.date;
  } else if (inputObj) {
    return inputObj;
  }
  return "";
}

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export function getObjectDiff(obj1, obj2) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));
  return diff;
}
// Tagged template function
export function html(pieces) {
  let result = pieces[0];
  const substitutions = [].slice.call(arguments, 1);
  for (let i = 0; i < substitutions.length; ++i) {
    result += substitutions[i] + pieces[i + 1];
  }

  return result;
}

/**
 * constructMessages Constructs set of messages for an array of changed properties.
 *
 * @param props $props Array of props which differ between OLD and NEW state.
 * @access public
 * @return void
 */
/* eslint no-eval: "off" */
export function constructMessages(props, ctx) {
  const result = [];
  _.each(props, (prop) => {
    const msg = eval('"' + LOG_TYPE_TEMPLATE_MSGS(ctx)[prop] + '"');
    if (msg !== "undefined") {
      result.push(msg);
    }
  });
  // console.log('modified props: ', props)
  return result;
}

/**
 * Filters out non falsy values, so no NULL, '', undefined, and 0 as well.
 */
const pickNonFalsy = _.partial(_.pick, _, _.identity);

/**
 * Same as above, but keeps the 0 values.
 */
const pickNonFalsyKeepZero = (objParams) => {
  return _.omit(objParams, (v) => {
    return _.isUndefined(v) || _.isNull(v) || v === "";
  });
};

/**
 * Helper to prepare errors for printing via Toasted (notifications)
 */
const parseErrors = (error, defaultMessage = "", isMessageFirst = false) => {
  if (error?.response?.data) {
    if (error.response.data instanceof Blob) {
      // const msg = await error.response.data.text()
      // We didn't parse blob because it async, we need to refactor the whole codebase
      return "No data for download";
    } else {
      const errs = error.response.data.errors;
      const msg = error.response.data.message;
      const getFirstErr = (errs) => {
        if (errs) {
          return _.values(errs).map((e) => e[0]);
        } else {
          return null;
        }
      };

      if (error.response.status === 403) {
        // Hardcoded error because axios fails to parse
        return "You're unauthorized for this action.";
      }

      if (isMessageFirst) {
        return msg || getFirstErr(errs);
      } else {
        return getFirstErr(errs) || msg;
      }
    }
  }
  return defaultMessage;
};
const findRecordErrors = (error) => {
  const errs = error?.response?.data?.errors;
  return errs || null;
};

export function confirmApplyChanges(
  thisProp,
  name = `apply changes?`,
  fullText = ""
) {
  if (typeof thisProp !== "object") {
    console.error("Invalid parameters : THIS", thisProp);
    throw Error("Invalid parameters");
  }
  const title = fullText ? fullText : `Are you sure you want to ${name}`;
  return new Promise((resolve) => {
    const alert = {
      title,
      message: "",
      type: "warning",
      useConfirmBtn: true,
      customConfirmBtnText: "Confirm",
      customConfirmBtnClass: "button is-danger",
      customCloseBtnText: "Cancel",
      customCloseBtnClass: "button is-outlined",
      onConfirm: () => resolve(),
    };
    thisProp.$refs.simplert.openSimplert(alert);
    setTimeout(() => {
      // Timeout because of the transition
      const el = thisProp.$refs.simplert.$el;
      const btns = el.querySelectorAll("button");
      if (btns.length) {
        btns[0].focus();
      }
    }, 600);
  });
}

/**
 * nonNull returns filtered copy of the object without null values
 *
 * @param o Object to filter
 * @return new object with properties that have nonNull values.
 */
export function nonNull(o) {
  const data = {};
  Object.keys(o).filter((k) => {
    if (o[k] != null) {
      data[k] = o[k];
    }
  });
  console.log(data);
  return data;
}

/**
 * Wrapper around underscore _.has() method
 * - prop can be a dot delimited string of nested properties...
 */
export function hasProperty(obj, prop) {
  return _.has(obj, prop);
}

export function momentTimeFromApi(time, format = "YYYY-MM-DD HH:mm:ss") {
  const m = moment(time, format);
  return m.isValid() ? m.format("HH:mm") : null;
}
export function momentDateFromApi(time, format = "YYYY-MM-DD HH:mm:ss") {
  const m = moment(time, format);
  return m.isValid() ? m.format("DD/MM/YYYY") : null;
}

export function momentDateTimeFromApi(
  datetime,
  inputFormat = "YYYY-MM-DD HH:mm:ss",
  isIncludeSeconds = false
) {
  const m = moment(datetime, inputFormat);
  let outputFormat = "DD/MM/YYYY HH:mm";
  if (isIncludeSeconds) {
    // outputFormat = "DD/MM/YYYY HH:mm:SS";
    outputFormat = "DD/MM/YYYY HH:mm:ss";
  }
  return m.isValid() ? m.format(outputFormat) : null;
}

/**
 * flatten
 *
 * @param arr $arr nested arrays, any level
 * @access public
 * @return flatten array
 */
export function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
}
/**
 * getFormattedTime
 *
 * @param time $time Time from API.
 * @param outfmt $outfmt Desired output format for passed in datetime.
 * @param inputfmt='YYYY-MM-DD HH:mm:ss' API expected input format.
 * @access public
 * @return Formatted datetime in desired format.
 */
export function getFormattedTime(time, outfmt, infmt = "YYYY-MM-DD HH:mm:ss") {
  const m = moment(time, infmt);
  return m.isValid() ? m.format(outfmt) : null;
}
export function getReversedFormattedTime(
  time,
  outfmt,
  infmt = "YYYY-MM-DD HH:mm:ss"
) {
  // Not working
  getFormattedTime(time, infmt, outfmt);
}
export function momentRangeFromApi(startTime, endTime, noEndTime) {
  const end = momentTimeFromApi(endTime)
    ? `${momentTimeFromApi(endTime)}h`
    : noEndTime;
  return `${momentTimeFromApi(startTime)}h - ${end}`;
}
/**
 * momentDiff Calculates time difference between given time boundaries
 *
 * @param startTime $startTime
 * @param endTime $endTime
 * @param default format='YYYY-MM-DD HH:mm', expected time format at input
 * @access public
 * @return void
 */
export function momentDiff(startTime, endTime, format = "YYYY-MM-DD HH:mm") {
  const ms = moment(endTime, format).diff(moment(startTime, format));
  const diff = moment.duration(ms);
  const hours = pad(Math.floor(diff.asHours()));
  let minutes = Number.parseInt(diff.asMinutes() - 60 * hours);

  if (Number.isNaN(hours) || Number.isNaN(minutes)) return null;

  minutes = pad(minutes);
  return `${hours}h:${minutes}m`;
}

/**
 * findPos
 *
 * FInds y value of given object
 */
const findPos = (obj) => {
  let curtop = 0;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while (obj === obj.offsetParent);
    return [curtop];
  }
};

export { pickNonFalsyKeepZero };
export { diffArrayOfObjects };
export { pickNonFalsy };
export { parseErrors };
export { findRecordErrors };
export { findPos };

/**
 *  packLaravelArray
 *
 *  Packs the array of objects for queryString best suited to Laravel validation,
 *  enumerating each of it's keys into an array.
 *  [{id: 1}, {id: 2}, {id: 3}] => name[][id]=1&name[][id]=2&name[][id]=3
 *  NOTE: DOES NOT PROCESS NESTING.
 */

export function packLaravelArray(name, objectArr) {
  const len = objectArr.length;
  if (len === 0) {
    return "";
  }
  let packedString = "";
  let packedSomething = false;
  for (let i = 0; i < len; i++) {
    const obj = objectArr[i];
    for (const key in obj) {
      if (typeof obj[key] === "function") {
        // Not serializable
        continue;
      }
      if (packedSomething === true) {
        packedString = packedString + "&";
      }
      packedString = packedString + (name + "[]" + "[" + key + "]=" + obj[key]);

      if (packedSomething === false) {
        packedSomething = true;
      }
    }
  }
  return packedString;
}

export function generateLaravelUrl(params, laravelArr, url) {
  let tempUrl = url;
  for (const larKey of laravelArr) {
    if (params[larKey]) {
      const packed = packLaravelArray(larKey, params[larKey]);
      if (packed !== "") {
        // If first
        if (tempUrl.indexOf("?") === -1) {
          tempUrl = tempUrl + "?" + packed;
        } else {
          tempUrl = tempUrl + "&" + packed;
        }
      }
      // Has to be called (delete) so it doesn't send duplicate params
      delete params[larKey];
    }
  }
  return tempUrl;
}

/**
 * Creates a zip files via JSZIP
 *
 * @param filesArr {{name:string, blob: any}}
 * @return Blob | null
 */
export async function createZipFromFiles(filesArr = []) {
  const jszip = new JSZip();
  if (filesArr.length === 0) {
    console.warn("No files to zip");
  }

  filesArr.forEach((file) => {
    jszip.file(file.name, file.blob);
  });

  if (
    Object.keys(jszip.files).length === 0 &&
    jszip.files.constructor === Object
  ) {
    console.log("no files ", jszip.files);
    return;
  }

  try {
    const content = await jszip.generateAsync({ type: "blob" });
    return content;
  } catch (err) {
    console.warn(err.message);
  }
  return null;
}
