<template lang='pug'>
.bulk-progress
  .track-bar
    .thumb-bar(:style="getProgressStyle")
</template>

<script>
/* {
  pending: [Num],
  completed: [Num],
  error: [Num],
  total: [Num],
} */
export default {
  props: {
    progressData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getProgressStyle () {
      let progressNum = 0;
      if (this.progressData.pending) {
        progressNum = this.progressData.total - this.progressData.pending;
      }
      return {
        width: `${progressNum}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-progress {
  height: 20px;
  width: 500px;

  .track-bar {
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 10px 2px #80808040;
    position: relative;
    overflow: hidden;
  }

  .thumb-bar {
    width: 0;
    background: lightblue;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transition: all 0.5s ease;
  }
}
</style>