<template lang="pug">
.expense-details-wrap
  //- table.table.is-bordered(v-if="expense")
  //-   tbody
  //-     tr(colspan=2)
  //-       th.one-td <span>SHIFT ID: {{ shift.id }}</span> <span>DATE: {{ shiftStartTime }}</span> <span>STATUS: {{ shift.status }}</span>
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  table.table.is-bordered(v-if="expense")
    tbody
      tr
        th ID
        td {{ expense.id }}
      //- tr
      //-   th NAME
      //-   td {{ expense.name }}
      tr
        th CLIENT NAME
        td {{ expense.client && expense.client.name }}
      tr
        th TEMP NAME
        td {{ expense.temp && expense.temp.name }}
      tr
        th DATE OF EXPENSE
        td {{ parseDate(expense.startTime || expense.date) }}
      tr
        th TYPE
        td {{ expense.expenseType && expense.expenseType.label }}
      tr(v-if="'engine_type' in expense")
        th ENGINE TYPE
        td {{ expense.engine_type }}
      tr(v-if="'distance' in expense")
        th DISTANCE
        td {{ parseDistance(expense.distance) }}
      tr.initial-value(
        v-if="'old_value' in expense && expense.old_value != expense.value"
      )
        th PROVISIONALLY CALCULATED AMOUNT
        td {{ parseExpenseValue(expense.old_value) }}
      tr
        th {{ activeTab.id === 3 ? "AMOUNT" : "APPROVED AMOUNT" }}
        td {{ parseExpenseValue(expense.value) }}
  .rejection-box(v-if="isRejecting")
    h2
      | Reason for rejection
      span Characters left: {{ rejectionTextCharsLeft }}
    textarea(v-model="rejectionText", :maxlength="REJECTION_TEXT_MAX_LENGTH")
  .confirm-box(v-if="checkSignOffMessages?.before_api_1 && !isRejecting")
    h2
      span {{ checkSignOffMessages?.before_api_1 }}
  .button-line(v-if="!isRejecting && checkSignOffMessages?.before_api_1")
    button.button.is-generic-app-red.is-danger(
      v-if="canAcceptSignOff",
      @click="signOffConfirmation"
    ) Confirm
    button.button.is-generic-app-blue(@click="giveUpSignOff") Cancel
  .button-line(v-if="!isRejecting && !checkSignOffMessages?.before_api_1")
    button.button.is-generic-app-blue(
      v-if="canAcceptSignOff",
      @click="onSubmitAccept"
    ) Accept
    button.button.is-generic-app-red.is-danger(@click="promptRejectionReason") Reject
  .button-line(v-if="isRejecting")
    button.button.is-danger(
      :disabled="!rejectionText",
      @click="onSubmitRejection"
    ) Reject
    button.button.is-generic-app-red(@click="handleRejectCancel") Cancel
</template>

<script>
import { parseErrors } from "@/lib/helpers/function";
import moment from "moment";
import { mapActions } from "vuex";
import api from "../../../api";

export default {
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      REJECTION_TEXT_MAX_LENGTH: 255,
      rejectionText: "",
      isRejecting: false,
      checkSignOffMessages: null,
    };
  },
  computed: {
    expense() {
      return this.modalProps.expense;
    },
    activeTab() {
      return this.modalProps.activeTab;
    },
    rejectionTextCharsLeft() {
      return this.REJECTION_TEXT_MAX_LENGTH - this.rejectionText.length;
    },
    canAcceptSignOff() {
      const expense = this.modalProps.expense;
      // const validStatuses = ["Pending", "Cancelled"];
      const validStatuses = ["Pending"];
      return expense?.status && validStatuses.includes(expense?.status);
      // if (Array.isArray(expense) && expense.length) {
      //   // return expense.some((exp) => exp.status !== "Pending");
      //   return expense.some((exp) => exp.status !== "Processed");
      // }
      // return false;
    },
  },
  methods: {
    ...mapActions({
      postExpensesSignOff: "postExpensesSignOff",
      postExpensesReject: "postExpensesReject",
      checkSignOff: "checkSignOff",
    }),
    promptRejectionReason() {
      this.isRejecting = true;
    },
    handleRejectCancel() {
      this.isRejecting = false;
    },
    async onSubmitAccept() {
      // this.submitAccept();
      this.callCheckSignOff();
    },
    async signOffConfirmation() {
      this.submitAccept();
    },
    async giveUpSignOff() {
      // this.checkSignOffMessages.before_api_1 = null;
      this.closeModal();
    },
    async callCheckSignOff() {
      const params = {
        id: this.expense.id,
      };
      try {
        const res = await this.checkSignOff(params);
        console.log(res.data.data);
        if (res.data.data?.before_api_1) {
          //in future will change and have more messages
          this.checkSignOffMessages = res.data.data;
        } else {
          this.submitAccept();
        }
      } catch (err) {
        console.warn(err);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    async submitAccept() {
      const params = {
        id: this.expense.id,
      };
      try {
        const res = await this.postExpensesSignOff(params);
        this.$toasted
          .success(res.data.message || "Successfully signed off expense")
          .goAway(2500);
        this.$emit("get-data");
        this.closeModal();
      } catch (err) {
        console.warn(err);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    async onSubmitRejection() {
      return new Promise(() => {
        const alert = {
          title: `Are you sure you want to reject this expense`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: this.submitRejection,
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async submitRejection() {
      const params = {
        id: this.expense.id,
        reject_reason: this.rejectionText,
      };
      try {
        const res = await this.postExpensesReject(params);
        this.$toasted
          .success(res.data.message || "Successfully rejected expense")
          .goAway(2500);
        this.$emit("get-data");
        this.closeModal();
      } catch (err) {
        console.warn(err);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    closeModal() {
      this.$emit("dispose-modal");
    },
    parseDate(dateStr, includeTime = true) {
      const timeFormat = includeTime ? " HH:mm" : "";
      return moment(dateStr).format(`DD-MM-YYYY${timeFormat}`);
    },
    parseExpenseValue(val) {
      const sfMonetaryValue = this.initialStatus.monetary_symbol;
      const shouldAddDecimals = val % 1 !== 0;
      const formatedVal = shouldAddDecimals ? val.toFixed(2) : val;
      return `${sfMonetaryValue} ${formatedVal || 0}`;
    },
    parseDistance(distance) {
      return distance ? `${distance} km` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.expense-details-wrap {
  display: flex;
  flex-direction: column;
  .rejection-box,
  .confirm-box {
    h2 {
      display: flex;
      justify-content: space-between;
    }
    textarea {
      width: 100%;
      height: 100px;
      resize: none;
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    button {
      margin: 0 10px;
    }
  }
  table {
    tr {
      &.initial-value {
        background-color: lightyellow;
      }
    }
  }
}
</style>
