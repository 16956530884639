export const SET_CLIENTS = "SET_CLIENTS";
export const SET_CLIENTS_ACTIVITY_TAGS = "SET_CLIENTS_ACTIVITY_TAGS";
export const FETCH_TEMPS = "FETCH_TEMPS";
export const FETCH_ALL_TEMPS = "FETCH_ALL_TEMPS";
export const CLEAR_TEMPS = "CLEAR_TEMPS";
export const FETCH_AGENCY_WORKERS = "FETCH_AGENCY_WORKERS";
export const FETCH_ALL_CATEGORIES = "FETCH_ALL_CATEGORIES";
export const FETCH_ALL_ROLES = "FETCH_ALL_ROLES";
export const FETCH_SPECIALITIES = "FETCH_SPECIALITIES";
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_TEMP_PROFILE = "FETCH_TEMP_PROFILE";
export const PATCH_USER = "PATCH_USER";

export const FETCH_SHIFTS = "FETCH_SHIFTS";
export const FETCH_SHIFT_HISTORY = "FETCH_SHIFT_HISTORY";
export const CLEAR_SHIFT_HISTORY = "CLEAR_SHIFT_HISTORY";
export const CHANGE_SHIFT_TIMES = "CHANGE_SHIFT_TIMES";
export const FETCH_CANCELLATION_REASONS = "FETCH_CANCELLATION_REASONS";
export const CANCEL_SHIFT = "CANCEL_SHIFT";

/**
 * COMPLIANCE
 */
export const FETCH_REJECTION_REASONS = "FETCH_REJECTION_REASONS";
export const REJECT_TEMP_DOCUMENT = "REJECT_TEMP_DOCUMENT";
export const FETCH_COMPLIANCES = "FETCH_COMPLIANCES";
export const UPDATE_DOCUMENT_PROPERTY = "UPDATE_DOCUMENT_PROPERTY";
export const FETCH_DOCUMENTS_FOR_APPROVAL = "FETCH_DOCUMENTS_FOR_APPROVAL";
export const FETCH_TEMP_DOCUMENTS = "FETCH_TEMP_DOCUMENTS";

// DASHBOARD
export const FETCH_CARDS = "FETCH_CARDS";

// Settings password validation
export const VALIDATE_PASSWORD = "VALIDATE_PASSWORD";
