import { default as axios } from "../config.js";
import _ from "underscore";
import { packLaravelArray } from "../../lib/helpers/function.js";

const api = {
  /**
   *  Implementation Notes
   *  Get shifts with default includes: shiftRequest, temp, cancelledBy, cancellationReason.
   *  params - defaults to an empty object, stringified using queryString
   *
   */
  fetchShifts: (payload = {}, cancelTokenSource) => {
    const tempPayload = Object.assign({}, payload);
    let fullRequestUrl = tempPayload.url;

    const laravelArr = ["sectors", "clients", "categories", "subcategories"];
    for (const larKey of laravelArr) {
      if (tempPayload[larKey]) {
        const packed = packLaravelArray(larKey, tempPayload[larKey]);
        if (packed !== "") {
          // If first
          if (
            fullRequestUrl === tempPayload.url &&
            fullRequestUrl.indexOf("/?") === -1
          ) {
            fullRequestUrl = fullRequestUrl + "?" + packed;
          } else {
            fullRequestUrl = fullRequestUrl + "&" + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete tempPayload[larKey];
      }
    }

    console.log("params.url in api call: ", fullRequestUrl);
    return axios.get(fullRequestUrl, {
      params: _.omit(tempPayload, "url"),
      cancelToken: cancelTokenSource.token,
    });
  },

  /**
   * Temps that can work on this shift
   */
  fetchTempsForShift: (id, params) => {
    return axios.get(`/temps-for-shift/${id}`, {
      params: {
        ...params,
        // availability: showRegardlessAvailability,
        per_page: 1000,
      },
    });
  },
  findTempsForShift: (q) => {
    return axios({
      url: "/temps-for-shift",
      method: "POST",
      // params: {
      // },
      data: Object.assign(q, {
        sort_by_field: "id",
        sort_by_direction: "asc",
        include: "subcategories,specialities,preferredRegions",
        //   includes: 'subcategories,specialities,preferredRegions'
      }),
    });
  },
  /**
   * Fetches log history per shift id (Shift details view)
   */
  fetchShiftHistory: (params) => {
    return axios.get(params.url, {
      params: {
        include: params.include,
      },
    });
  },

  shiftRequestReasons: () => {
    return axios.get("/shift-request-reasons");
  },

  cancellationReasons: () => {
    return axios.get("/shift-cancellation-reasons");
  },

  /**
   * : /shifts
   *  {
   *  "location_id": 0,
   *  "start_time": "string",
   *  "end_time": "string",
   *  "temp_id": 0,
   *  "notes": "string"
   *  }
   * Changes any of the above for shift identified by shiftId (Shift schedule view)
   * params will get patch data, all EXCEPT shiftId which was extracted from object
   * to be used in url.
   */
  patchShift: (id, payload = {}) => {
    return axios.patch(`/shifts/${id}`, payload, {
      params: {
        include: [
          "shiftRequest.subcategories",
          "shiftRequest.createdBy",
          "location.client.locations",
          "temp",
          "previousTemp",
          "shiftParts",
          "tags"
        ].join(","),
      },
    });
  },

  /**
   * Client sign off. ('Awaiting client' button on ShiftSchedule COMPLETED tab)
   */
  clientSignOff: (url, data) => {
    console.log(">> Hitting this: ", url);
    return axios.post(url, data, {
      params: {
        include: [
          "shiftRequest.subcategories",
          "shiftRequest.createdBy",
          "location.client.locations",
          "temp",
          "previousTemp",
          "signedOffBy",
          "shiftParts",
        ].join(","),
      },
    });
  },
  clientRecalculateShift: (url, data) => {
    console.log(">> Hitting this recalc: ", url);
    return axios.post(url, data, {
      params: {
        include: [
          "shiftRequest.subcategories",
          "shiftRequest.createdBy",
          "location.client.locations",
          "temp",
          "previousTemp",
          "signedOffBy",
          "shiftParts",
        ].join(","),
      },
    });
  },

  pushToTemps: (id, payload = {}) => {
    return axios.post(`/shifts/${id}/push`, payload);
  },
  /**
   * Expects: cancellation object {} as:
   * params {
   * shiftId - if of a shift to be cancelled
   * cancellation_reason_id - cancellation reason (predefined list of values)
   * }
   * shiftId Object which contains shift id to be cancelled.
   */
  cancelShift: (params) => {
    return axios.post(`/shifts/cancel`, params);
  },

  // Switch awaiting status for shift
  switchShift: (id, payload) => {
    return axios.post(`shifts/${id}/toggle-sign-off`, payload);
  },

  addShiftsToInvoices: (payload) => {
    return axios.post(`shifts/add-to-invoice`, payload);
  },
  // shifts activity tags
  getShiftsActivityTags: () => {
    return axios.get(`shift-activity-tags`);
  },
};

export default api;
