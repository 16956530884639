<template lang="pug">
.shift-confirm-interval-form
  //- .header
  .loading-wrapper(v-if="isLoadingCats", style="text-align: center")
    img(src="@/assets/images/comps/loader.svg")
  .no-results(v-else-if="!categoriesOptions.length")
    span No categories found
  template(v-else)
    .form-content
      p.control
        label.label Category
        multi-select.category-select(
          :options="categoriesOptions",
          select-label="",
          selected-label="",
          deselect-label="",
          track-by="id",
          label="name",
          :allow-empty="false",
          :value="selectedCategoryOption",
          placeholder="FILTER CATEGORY",
          @select="onChangeCategory"
        )
      template(v-if="selectedCategoryOption")
        .rows
          .row(style="margin: auto")
            .group-input
              label(title="Select to change to action based intervals") Action based
              switches(
                style="margin: 19px 0 10px 0",
                theme="bulma",
                color="blue",
                v-model="tempForm.actionBased",
                @click.native="onClickActionBased"
              )
            template(v-if="!tempForm.actionBased")
              .group-input
                label.label Interval (minutes)
                input.input(
                  v-model.number="tempForm.interval",
                  type="number",
                  :disabled="tempForm.actionBased",
                  @input="isAnyInputChanged = true",
                  @keyup.enter="onClickUpdate"
                )
            .remove-wrap
              img(
                src="../../assets/images/comps/remove.svg",
                v-if="tempForm.id",
                title="Clear interval",
                @click="onRemoveInterval"
              )
          .row
            span If neither of the two options is set, then the shift confirmation engine feature is not ON for this category for this client.
        //- button.button.is-generic-app-blue.is-caps-lock.is-low.add(
        //-   @click="onAddNewRow"
        //- )
        //-   span Add new row
  .action.buttons-centered
    button.button.is-generic-app-blue.is-caps-lock.save(
      v-if="categoriesOptions.length",
      @click="onClickUpdate",
      :disabled="!isAnyInputChanged"
    )
      span Update
      //- span {{ shouldCreateInterval ? "Add" : "Update" }}
    //- button.button.is-generic-app-blue.is-caps-lock.save(
    //-   @click="updateInterval(false)",
    //-   :disabled="!isAnyInputChanged"
    //- )
    //-   span Apply
    button.button.is-caps-lock.cancel(@click="onClose")
      span Close
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { parseErrors } from "../../lib/helpers/function.js";
import api from "@/api";
import apiTs from "@/api/apiTs";
export default {
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      breakList: [],
      categoriesOptions: [],
      selectedCategoryOption: null,
      isAnyInputChanged: false,
      errorInputList: [],
      intervalData: [],
      // selectedInterval: null,
      isLoadingCats: false,
      tempForm: {
        id: null,
        actionBased: false,
        interval: null,
      },
    };
  },
  computed: {
    breakListSorted() {
      return [...this.breakList].sort((a, b) =>
        a.from > b.from ? 1 : a.from < b.from ? -1 : 0
      );
    },
    getBiggestFromObj() {
      if (Array.isArray(this.breakList) && this.breakList.length) {
        return this.breakList.reduce((acc, curr) =>
          curr.shift_length_to > acc.shift_length_to ? curr : acc
        );
      }
      return {};
    },
    clientId() {
      return this.modalProps.id;
    },
    categoryId() {
      return this.selectedCategoryOption?.id;
    },
    selectedInterval() {
      if (this.intervalData?.length && this.selectedCategoryOption?.id) {
        return this.intervalData?.find(
          (item) => item.category_id === this.selectedCategoryOption.id
        );
      } else {
        return null;
      }
    },
    shouldCreateInterval() {
      return !this.selectedInterval;
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIntervals();
  },
  watch: {
    selectedInterval: {
      handler(val) {
        if (val) {
          this.tempForm.id = val.id;
          this.tempForm.actionBased = val.action_based;
          this.tempForm.interval = val.interval;
        } else {
          this.tempForm.id = null;
          this.tempForm.actionBased = false;
          this.tempForm.interval = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    onChangeCategory(evt) {
      // this.fetchCategories();
      this.selectedCategoryOption = evt;
    },
    onClose() {
      this.$emit("cancel-modal");
    },
    async onRemoveInterval() {
      try {
        await this.confirmAlert("remove interval");
        this.tempForm.interval = null;
        this.isAnyInputChanged = true;
        const p = {
          id: this.tempForm.id,
          client_id: this.clientId,
        };
        const res = await apiTs.deleteClientNotifInterval(p);
        this.$toasted.success("Success").goAway(1500);
        this.fetchIntervals();
      } catch (err) {
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    // onAddNewRow() {
    //   this.createInterval();
    // },
    async onClickUpdate() {
      let isSuccess = false;
      if (this.shouldCreateInterval) {
        isSuccess = await this.createInterval(true);
      } else {
        // Maybe false in future here
        isSuccess = await this.updateInterval(true);
      }

      if (isSuccess) {
        this.fetchIntervals();
      }
    },
    async createInterval(isSave = true) {
      if (!this.categoryId || !this.clientId) {
        console.error("No client / category", this.clientId, this.categoryId);
        return;
      }

      const p = {
        category_id: this.categoryId,
        client_id: this.clientId,
        interval: this.tempForm.interval || 0,
        action_based: this.tempForm.actionBased,
      };
      try {
        const res = await apiTs.postClientNotifInterval(p);
        this.$toasted.success("Successfully created").goAway(1000);
        console.warn(res.data);
        this.isAnyInputChanged = false;
        if (isSave) {
          this.onClose();
        }
        return true;
      } catch (err) {
        console.warn(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
        return false;
      }
    },
    // onInputField(evt, keyField, breakRow, index) {
    //   const value = evt.target.value;
    //   let valueParsed = null;
    //   if (value) {
    //     valueParsed = parseInt(value, 10);
    //   }
    //   this.$set(breakRow, keyField, valueParsed);
    //   if (keyField === "shift_length_to") {
    //     // Update from value for next row
    //     if (index < this.breakList.length - 1) {
    //       this.$set(
    //         this.breakList[index + 1],
    //         "shift_length_from",
    //         valueParsed
    //       );
    //     }
    //   }
    //   this.isAnyInputChanged = true;
    // },
    async updateInterval(isSave = true) {
      // const isValidInputs = this.checkInputsValidity();
      // if (!isValidInputs) {
      //   this.$toasted
      //     .error(
      //       "Some input values are not valid. Please fix before proceeding"
      //     )
      //     .goAway(4500);
      //   return;
      // }

      const params = {
        id: this.tempForm.id,
        client_id: this.clientId,
        action_based: this.tempForm.actionBased,
        interval: this.tempForm.interval || 0,
      };

      // if (this.tempForm.actionBased) {
      //   delete params.interval;
      // }

      try {
        await apiTs.putClientNotifInterval(params);
        this.$toasted.success("Successfully changed").goAway(1000);
        this.isAnyInputChanged = false;
        if (isSave) {
          this.onClose();
        }
        return true;
      } catch (err) {
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(4500);
        return false;
      }
    },
    async fetchCategories() {
      this.isLoadingCats = true;
      const params = { client_id: this.clientId };
      try {
        const res = await api.getClientCategories(params);
        this.categoriesOptions = res.data.data;
      } catch (err) {
        console.log("Error", err.message);
      } finally {
        this.isLoadingCats = false;
      }
    },
    async fetchIntervals() {
      const p = { client_id: this.clientId };
      try {
        const res = await apiTs.getClientNotifInterval(p);
        this.intervalData = res.data.data;
        // console.log(":: Intervals", this.intervalData);
      } catch (err) {
        console.warn(err.message);
      }
    },
    confirmAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    onClickActionBased(evt) {
      console.warn("Change action based", evt.target.checked);
      this.isAnyInputChanged = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.shift-confirm-interval-form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // max-height: 500px;
    // overflow: auto;

    .rows {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .row {
        display: flex;
        gap: 10px;
        align-items: flex-end;

        .group-input {
          display: flex;
          flex-direction: column;
        }

        .info {
          margin: 0 20px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .remove-wrap {
          align-self: flex-end;
          margin: 0 0 4px 10px;

          img {
            cursor: pointer;
          }
        }

        input {
          width: 100px;

          &.error {
            background: #ffedf0;
            border: 1px solid crimson;
          }
        }
      }
    }

    button {
      align-self: flex-start;
    }
  }

  .action {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}
</style>
