<template lang="pug">
table.table
  modal(
    v-if="inmodal",
    @close="inmodal = ''",
    :title="modalTitle",
    icon="",
    :size="modalSize",
    :scrollable="true",
    :show-close-button="true"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      :user="activeUserRemoval",
      @close="onCancelCloseModal",
      @cancel-modal="onCancelCloseModal",
      @confirm="onCancelCloseModal",
      @activate="onChangeStatusUser($event, 1)",
      @deactivate="onChangeStatusUser($event, 3)",
      @save-modal-reg="onSavingRegionsForm",
      @save-modal-cat="onSavingRegionsForm"
    )
  edit-popup(
    :data="popup.data",
    :levels="popup.levels",
    :offset="popup.offset",
    :target="popup.element",
    :isLoading="popup.isLoading",
    :isSaving="isSavingPopupForm",
    ref="popup",
    v-if="popup.show",
    @close="popupClose",
    @editPopup="popup.save"
  )
  thead
    tr
      //- th Select All
      th NAME
        sort-buttons(column-name="name", v-model="selectedSortColumn")
      th EMAIL
        sort-buttons(column-name="email", v-model="selectedSortColumn")
      th(style="min-width: 200px") USER CLASS
      th SUBCATEGORIES
      th LOCATION
      //-
        th DATE
      th # SHIFTS SENT
        //- sort-buttons(column-name="shiftsSent", v-model="selectedSortColumn")
      th LAST ACTION
      th(v-if="canSeeRegionModal") REGIONS
      th DETAILS
      th STATUS
      th DELETE
  tbody
    tr(v-if="loading")
      td.center-text(colspan="100")
        img(src="../../assets/images/comps/loader.svg")
    tr(v-for="user in users", v-if="users && !loading")
      td.username(:title="user.name") {{ user.name }}
      td(:title="user.email") {{ user.email }}
      td.roles
        multi-select(
          :options="roles",
          :multiple="false",
          :close-on-select="true",
          :allow-empty="false",
          select-label="",
          selected-label="",
          deselect-label="",
          track-by="id",
          label="label",
          :value="user.roles && user.roles[0]",
          placeholder="SELECT USER CLASS",
          @input="updateUserClass(user, $event)"
        )
          span(slot="noResult") Nothing found.
      td.subcats
        span.generic-app-tag.is-wide-tag.popup-hook(
          @click="showPopup(['subcategories'], $event.target, user)",
          v-tooltip="'Click to fill in'",
          v-show="loadingSubcategoriesUserId !== user.id"
        ) 
          strong {{ user.subcategoryCount || 0 }}
          img(src="../../assets/images/comps/Olovka.svg")
        .loading-wrapper(v-if="loadingSubcategoriesUserId === user.id")
          img(src="../../assets/images/comps/loader.svg")
      td.locations
        span.generic-app-tag.is-wide-tag.popup-hook(
          @click="showPopup(['locations'], $event.target, user)",
          v-tooltip="'Click to fill in'",
          v-show="loadingLocationsUserId !== user.id"
        ) 
          strong {{ user.locationCount || 0 }}
          img(src="../../assets/images/comps/Olovka.svg")
        .loading-wrapper(v-if="loadingLocationsUserId === user.id")
          img(src="../../assets/images/comps/loader.svg")
      //-
        td DATE
      td.shifts-sent {{ user.shiftsSent }}
      //- td {{ formatDate(user.lastAction, 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY') }}
      td {{ formatDate(user.lastAction, "DD/MM/YYYY HH:mm") }}
      td.regions(v-if="canSeeRegionModal")
        button.button.is-generic-app-blue.is-caps-lock.is-low.is-tiny(
          v-if="canViewRegionModal(user)",
          @click.stop="onClickOpenRegions(user)"
        )
          | Regions
      td.has-button
        router-link.button.is-generic-app-blue.is-caps-lock.is-low.is-tiny(
          :to="{ name: 'edit-user', params: { userId: user.id } }"
        ) details
      td.activate-user
        span.button.is-caps-lock.is-tiny.is-low(
          data-cell="status-button",
          @click.stop="onChangeStatusAction(user)",
          :class="[setStatus(user.status)]",
          :disabled="loadingStatus"
        ) {{ getStatusText(user.status) }}
      td.delete-cell
        button.button.is-danger.is-low.is-tiny(
          @click.stop="onClickDeleteUser(user)"
        )
          span DELETE
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>
<script>
import _ from "underscore";
import EditPopup from "../EditPopup.vue";
import {
  flatten,
  getFormattedTime,
  parseErrors,
} from "../../lib/helpers/function.js";
import MultiSelect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import { Evt } from "../../lib/helpers/Evt.js";
import EditUser from "../EditUser.vue";
import EditUserStatus from "./EditUserStatus.vue";
import ConfirmDeleteUser from "./ConfirmDeleteUser.vue";
import ManageRegionsModal from "./ManageRegionsModal.vue";
import axios from "axios";

export default {
  components: {
    EditPopup,
    EditUser,
    MultiSelect,
    ConfirmDeleteUser,
    EditUserStatus,
    ManageRegionsModal,
  },
  props: ["users", "includes", "loading", "searchStatus"],
  data() {
    return {
      DEBUG: {},
      popup: {
        active_user_id: null,
        offset: {
          offsetX: 20,
          offsetY: 55,
        },
        element: null,
        show: false,
        data: null,
        levels: [],
        save: (data, totalCount) => {
          return this.refreshPopupState(data, totalCount);
        },
        isLoading: false,
      },
      selectedSortColumn: {
        column: "",
        direction: "",
      },
      activeUserRemoval: {},
      loadingStatus: false,
      modalSize: 750,
      inmodal: "",
      cancelTokenPopup: null,
      loadingSubcategoriesUserId: null,
      loadingLocationsUserId: null,
      userSubcategories: {},
      userLocations: {},
      modalComponentName: "",
      isSavingPopupForm: false,
    };
  },
  computed: {
    ...mapGetters({
      categories: "getAllCategories", // job types
      roles: "getMinRoles", // Roles aka Visibility
      clients: "getAllClients",
      // sectors: 'locationClientManagement/getFlatSectors'
    }),
    sectors() {
      return [
        {
          name: "",
          id: 1,
          clients: this.clients,
        },
      ];
    },
    canSeeRegionModal() {
      return this.$can("edit-admin-permissions-per-entity");
    },
  },
  watch: {
    selectedSortColumn(value) {
      this.$emit("sort-results", value);
    },
  },
  created() {
    Evt.listen("confirmDeleteUser", () => {
      this.onCancelCloseModal();
    });
  },
  methods: {
    ...mapActions({
      getFetchUsers: "getFetchUsers",
      patchUsers: "patchUsers",
    }),
    onCancelCloseModal() {
      this.inmodal = "";
    },
    formatDate: getFormattedTime,
    async onChangeStatusUser(user, statusCode = 1) {
      this.inmodal = "";
      console.log("Activation invoked for ID :: ", user.id);
      const payload = {};
      payload.id = user.id;
      payload.data = { status: statusCode };
      payload.query = { include: this.includes };
      if (this.searchStatus.value !== null) {
        payload.__isSlice = true;
      }
      try {
        await this.patchUsers(payload);
      } catch (err) {
        const errs = parseErrors(err, "Error saving", false);
        this.$toasted.error(errs).goAway(4500);
      }
    },
    resetPreviousPopupToken() {
      if (this.cancelTokenPopup !== null) {
        this.cancelTokenPopup.cancel();
        this.cancelTokenPopup = null;
      }
    },
    async fetchSubcategories(userId) {
      this.resetPreviousPopupToken();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelTokenPopup = source;

      const params = {
        query: {
          "users[]": [userId],
          include: ["subcategories"].join(),
          // include: "subcategories",
        },
        cancelTokenSource: source,
      };

      try {
        const res = await this.getFetchUsers(params);
        this.$set(this.userSubcategories, userId, res.data.data?.[0] || {});
        return true;
      } catch (err) {
        console.warn(err.message);
      }
      return false;
    },
    async fetchLocations(userId) {
      this.resetPreviousPopupToken();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelTokenPopup = source;

      const params = {
        query: {
          "users[]": [userId],
          include: ["locations", "withArchived"].join(),
        },
        cancelTokenSource: source,
      };

      try {
        const res = await this.getFetchUsers(params);
        this.$set(this.userLocations, userId, res.data.data?.[0] || {});
        return true;
      } catch (err) {
        console.warn(err.message);
      }
      return false;
    },
    async showPopup(levels, e, user) {
      this.loadingSubcategoriesUserId = null;
      this.loadingLocationsUserId = null;

      if (_.indexOf(levels, "subcategories") >= 0) {
        this.loadingSubcategoriesUserId = user.id;
        const isSuccess = await this.fetchSubcategories(user.id);
        if (isSuccess) {
          const userCats = this.userSubcategories[user.id];
          if (userCats) {
            const apiCheckedIds = this.findCheckedItems(
              "subcategories",
              userCats
            );
            // console.log('API CHECKED IDS :: ', apiCheckedIds)

            this.popup.data = this.getSubcategoriesByCategories(
              this.categories,
              apiCheckedIds
            );
            console.log(this.popup.data);
          } else {
            console.error("User not found", user.id, user);
            this.loadingSubcategoriesUserId = null;
            const errs = parseErrors(null, "User is not found", true);
            this.$toasted.error(errs).goAway(4500);
            return;
          }
        } else {
          this.loadingSubcategoriesUserId = null;
          return;
        }
      } else if (_.indexOf(levels, "locations") >= 0) {
        this.loadingLocationsUserId = user.id;
        const isSuccess = await this.fetchLocations(user.id);
        if (isSuccess) {
          const userLocs = this.userLocations[user.id];
          if (userLocs) {
            this.popup.isLoading = true;
            this.$nextTick(async () => {
              await this.waitForClientData();
              const apiCheckedIds = this.findCheckedItems(
                "locations",
                userLocs
              );
              const checkedIdObjects = this.getCheckedCCs(
                this.sectors,
                apiCheckedIds
              );
              const clientLocations = this.packDataForPopup(checkedIdObjects);
              this.$set(this.popup, "data", clientLocations);
              this.popup.isLoading = false;
            });
          } else {
            console.error("User not found", user.id, user);
            this.loadingLocationsUserId = null;
            const errs = parseErrors(null, "User is not found", true);
            this.$toasted.error(errs).goAway(4500);
            return;
          }
        } else {
          this.loadingLocationsUserId = null;
          return;
        }
      }

      this.popup.levels = levels;
      this.popup.active_user_id = user["id"];
      this.popup.show = true;
      this.popup.element = e;
      this.loadingSubcategoriesUserId = null;
      this.loadingLocationsUserId = null;
      console.log(user.id);
      console.log("popup data", this.popup);
    },
    popupClose() {
      this.popup.show = false;
    },
    async refreshPopupState({ data, entity }, totalCount) {
      this.isSavingPopupForm = true;
      console.log("entity", entity[0], totalCount);
      // If we were editing subcategories
      if (entity[0] === "subcategories") {
        const ids = data.map((category) => {
          return category.subcategories
            .filter((subcat) => {
              if (subcat.checked === true) {
                return subcat.id;
              }
            })
            .map((subcat) => subcat.id);
        });

        const subcategories = flatten(ids).map((id) => ({ id: id }));

        if (this.popup.active_user_id !== 0) {
          try {
            await this.sendPatch(
              this.popup.active_user_id,
              { subcategories: subcategories },
              "Subcategories updated successfully!"
            );
            this.isSavingPopupForm = false;
            return;
          } catch (err) {
            const errs = parseErrors(err, "Error saving", true);
            this.$toasted.error(errs).goAway(4500);
            this.isSavingPopupForm = false;
            return;
          }
        }
        this.isSavingPopupForm = false;
        return; // safety measure
      } else if (entity[0] === "locations") {
        // If we were editing locations
        // Popup data is still a hierarchy, even though we will most likely only have only one root
        const ids = data.map((rootCC) => {
          return rootCC.locations
            .filter((location) => {
              if (location.checked === true) {
                return location.id;
              }
            })
            .map((location) => location.id);
        });
        const locationIds = flatten(ids).map((id) => ({ id: id }));
        console.log("locationIds ::", locationIds);

        try {
          await this.sendPatch(
            this.popup.active_user_id,
            { locations: locationIds },
            "CostCentres updated successfully!"
          );
          this.isSavingPopupForm = false;
          return;
        } catch (err) {
          const errs = parseErrors(err, "Error saving", true);
          this.$toasted.error(errs).goAway(4500);
          this.isSavingPopupForm = false;
          return;
        }
      }
    },
    findCheckedItems(type, user) {
      const checkedItems = [];
      if (type === "subcategories") {
        user["subcategories"]?.map((subcategory) => {
          checkedItems.push(subcategory["id"]);
        });
      }
      if (type === "locations") {
        user["locations"]?.map((location) => {
          checkedItems.push(location["id"]);
        });
      }
      return checkedItems;
    },
    getSubcategoriesByCategories(categories, ids = []) {
      return _.each(categories, (category) =>
        category.subcategories.map((subcat) => {
          subcat.checked = _.contains(ids, subcat.id);
          if (subcat.checked) {
            // console.log('subcat id :: ', subcat.id)
          }
        })
      );
    },
    getCheckedCCs(sectors, ids = []) {
      return _.each(sectors, (sector) => {
        // console.log('Checking Sector :: ', sector)
        return _.each(sector.clients, (client) => {
          // console.log('Checking Client :: ', client)
          return _.each(client.locations, (location) => {
            // console.log('Checking CC :: ', location)
            location.checked = _.contains(ids, location.id);
          });
        });
      });
    },
    async waitForClientData() {
      return new Promise((resolve) => {
        const recheck = (clients) => {
          setTimeout(async () => {
            if (!clients || (clients && clients.length === 0)) {
              console.log("Recheck data");
              recheck(this.sectors[0].clients);
            } else {
              resolve();
            }
          }, 1000);
        };

        recheck(this.sectors[0].clients);
      });
    },
    packDataForPopup(sectors) {
      const packed = [];
      _.each(sectors, (sector) => {
        _.each(sector.clients, (client) => {
          packed.push(client);
        });
      });
      return packed;
    },
    async sendPatch(id, payload, msg) {
      const params = {
        id: id,
        data: payload,
        query: { include: this.includes },
      };
      try {
        await this.patchUsers(params);
        if (msg !== undefined) {
          this.$toasted.info(msg).goAway(1500);
        }
        this.popupClose();
      } catch (err) {
        console.log("ERROR", err.message);
        const errs = parseErrors(err, "Error saving", false);
        this.$toasted.error(errs).goAway(4500);
      }
    },
    onSavingRegionsForm() {
      this.$emit("refresh-data", {});
    },
    getRoleForUser(user) {
      // console.log('role user', user);
      return user.roles && user.roles[0];
    },
    confirmActionAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async updateUserClass(user, evt) {
      // Warning msg
      try {
        await this.confirmActionAlert("update user role");

        this.$set(user, "roles", [evt]);
        if (user.roles?.[0]?.id) {
          // Only if changing value [Disable removing]
          this.sendPatch(
            user.id,
            { role_id: user.roles[0].id },
            "User class updated successfully!"
          );
        } else {
          console.error("Error changing user role", user, evt);
        }
      } catch (err) {
        // ignored
      }
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = "750"
    ) {
      console.log("Data we should send to a modal popup...", modalData);
      this.modalData = modalData;
      this.modalSize = modalSize;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.inmodal = modal;
    },
    onClickDeleteUser(user) {
      this.activeUserRemoval = user;
      this.showModal(
        "ConfirmDeleteUser",
        { user },
        `Confirm user removal`,
        450
      );
    },
    onChangeStatusAction(user) {
      // If using modal
      this.showModal("edit-user-status", { user }, `User's status`, 650);
      //  if(user.status === 3) {
      //    this.onChangeStatusUser(user, 1)
      //  } else if(user.status === 1) {
      //    this.onChangeStatusUser(user, 3)
      //  }

      // Directly changing
      // const changeStatusActionApi = async (status) => {
      //   this.loadingUserStatus = true
      //   const apiParams = {
      //     userId: user.id,
      //     params: {
      //       active: status
      //     }
      //   }
      //   try {
      //     await this.putUserStatus(apiParams)
      //     // If: the store doesn't get updated fast / API doesn't return the value
      //     // Then: update the value manually
      //     this.loadingUserStatus = false
      //   } catch (err) {
      //     console.log(err.message);
      //     this.loadingUserStatus = false
      //   }
      // }
      // if (user.active === false) {
      //   // Enable
      //   changeStatusActionApi(true)
      // } else {
      //   // Disable
      //   changeStatusActionApi(false)
      // }
    },
    onClickOpenRegions(user) {
      this.showModal("ManageRegionsModal", { user }, `Manage Regions`, 850);
    },
    setStatus(s) {
      let c;
      switch (s) {
        case 1:
        default:
          c = "is-success";
          break;
        // case 2:
        //   c = 'is-warning'
        //   break
        case 3:
          c = "is-danger";
          break;
      }
      return c;
    },
    getStatusText(s) {
      let c;
      switch (s) {
        case 1:
        default:
          c = "Active";
          break;
        // case 2:
        //   c = 'Review'
        //   break
        case 3:
          c = "Inactive";
          break;
        // case 4:
        //   c = 'Approval'
        //   break
      }
      return c;
    },
    canViewRegionModal(user) {
      return user.canAssignPermissionPerEntities;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.delete-btn {
  height: 20px;
  width: 64px;
  border-radius: 3px;
  background-color: #d20505;
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: baseline-top;

  &:hover {
    background-color: #f42727;
    cursor: pointer;
  }

  .content {
    height: 12px;
    width: 37px;
    color: #ffffff;
    font-family: Lato;
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    display: block;
    margin: 0 auto;
  }
}

.pin {
  height: 18px;
  width: 35px;
  color: #405168;
  font-family: Lato;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

img.pin-edit {
  margin-left: 2px;
  clear: right;
  display: inline-block;
  cursor: pointer;
}

td {
  &.username {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  &.subcats {
    height: 50px;

    .loading-wrapper {
      height: 20px;
      width: 35px;
      padding: 0 0 0 10px;
    }
  }

  &.locations {
    height: 50px;

    .loading-wrapper {
      height: 20px;
      width: 35px;
      padding: 0 0 0 10px;
    }
  }
}
</style>
