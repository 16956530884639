import api from "../../api/shifts";
import _ from "underscore";
import auth from "../../lib/auth.js";

const state = {
  shifts: [],
  lastShiftHistory: [],
  cancellationReasons: [],
  shiftRequestReasons: [],

  tempsForShift: [],

  // Tracks which tab is currently active in all tab interfaced components.
  activeTab: {
    name: "",
    status: null,
  },
  shiftFilter: {},
};

const getters = {
  getActiveTab: (state) => state.activeTab,

  getShiftRequestReasons: (state) => state.shiftRequestReasons,

  getCancellationReasons: (state) => state.cancellationReasons,

  // Find shift by id
  getShift: (state) => (id) => {
    return _.findWhere(state.shifts, { id: id });
  },

  getTempsForShift: (state) => state.tempsForShift,

  // Gives back all shifts from state
  getShifts: (state) => state.shifts,

  // Gives back shift history for the last fetched shift
  getShiftHistory: (state) => state.lastShiftHistory,

  getShiftFilterByTab: (state) => (id) => {
    return state.shiftFilter[
      Object.keys(state.shiftFilter).find((el) => parseInt(el) === id)
    ];
  },
};

const actions = {
  /**
   * SHIFT REQUEST VIEWS
   * Get shifts, satisfying the passed query param
   * api: /shifts{?query}
   * TODO logic to choose url based on role
   */
  fetchShifts: ({ commit }, { params, cancelTokenSource, raceObj }) => {
    if (!auth.user.role) return;
    if (auth && auth.user.role === "temp") {
      params.url = "/shifts";
    } else {
      params.url = "/admin/shifts";
    }
    return api
      .fetchShifts(params, cancelTokenSource)
      .then((response) => {
        if (raceObj.curr === raceObj.arr[raceObj.arr.length - 1]) {
          if (params.metaOnly === true) {
            commit("SET_PAGINATION", response.data, { root: true });
          } else {
            commit("FETCH_SHIFTS", response.data);
          }
        }
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  saveShiftFiltersToVuex: ({ commit }, { tab, params }) => {
    commit("STORE_SHIFT_FILTERS", { tab, params });
  },

  fetchTempsForShift: async ({ commit }, params) => {
    // get temps per shift (REPUSH action)
    const response = await api.fetchTempsForShift(params.id, params);
    // commit changed shift object into state (replace shift object)
    commit("STORE_TEMPS_FOR_SHIFT", response.data);
    return response;
  },

  // POST request to /temps-for-shifts. Used on Multiple Line of Shifts
  findTempsForShift: ({ commit }, q) => {
    return api
      .findTempsForShift(q)
      .then((response) => {
        commit("FETCH_TEMPS", response.data);
        return Promise.resolve();
      })
      .catch((error) => {
        console.log("error fetching temps for shifts", error);
        return Promise.reject(error);
      });
  },

  /**
   * Shift Details
   * Get shift by ID, with shift history included.
   *
   */
  fetchShiftHistory: ({ commit }, params) => {
    if (!auth.user.role) return;
    if (auth && auth.user.role === "temp") {
      params.url = `/shifts/${params.id}`;
    } else {
      params.url = `/admin/shifts/${params.id}`;
    }
    console.log(params.url);
    commit("CLEAR_SHIFT_HISTORY");
    return api.fetchShiftHistory(params).then(({ data: { data } }) => {
      commit("FETCH_SHIFT_HISTORY", data);
      return data;
    });
  },

  fetchShiftRequestReasons: ({ commit }) => {
    return api.shiftRequestReasons().then((response) => {
      commit("FETCH_SHIFT_REQUEST_REASONS", response.data);
    });
  },

  fetchCancellationReasons: ({ commit }) => {
    return api.cancellationReasons().then((response) => {
      commit("FETCH_CANCELLATION_REASONS", response.data);
    });
  },
  /**
   * PATCH shift based on shift ID
   * payload - Object holding properties used for patching
   * id - always exists, others may or may not exist based on which patch is performed.
   */
  patchShift: ({ commit }, payload) => {
    return api
      .patchShift(payload.id, payload.data)
      .then((response) => commit("PATCH_SHIFT_RET", response));
  },

  /**
   * Client sign off for a specific shift.
   */
  clientSignOff: ({ commit }, payload) => {
    // url already holds in itself `id` as well as the right api end-point to hit
    // depending on who is doing the sign-off.
    const { url, data } = payload;
    return api.clientSignOff(url, data).then((response) => {
      // mutatation for changes
      console.log("Updating shift in state 1", response.data.data);
      if (payload.tab === "ACTIVE") {
        commit("PATCH_SHIFT", response);
      } else if (payload.tab === "COMPLETED") {
        commit("PATCH_SHIFT_RET", response);
      } else {
        console.log("Shift signoff other ", payload);
      }
    });
  },
  clientRecalculateShift: ({ commit }, payload) => {
    // url already holds in itself `id` as well as the right api end-point to hit
    // depending on who is doing the sign-off.
    const { url, data } = payload;
    return api.clientRecalculateShift(url, data).then((response) => {
      // mutatation for changes
      console.log("Updating shift in state 2", response.data.data);
      if (payload.tab === "ACTIVE") {
        commit("PATCH_SHIFT", response);
      } else if (payload.tab === "COMPLETED") {
        commit("PATCH_SHIFT_RET", response);
      } else {
        console.log("Shift recalc other ", payload);
      }
    });
  },

  pushToTemps: ({ commit }, payload) => {
    console.log(payload);
    return api.pushToTemps(payload.id, payload);
  },

  cancelShift: ({ commit }, params) => {
    return api.cancelShift(params).then((response) => {
      commit("CANCEL_SHIFT", response.data);
      return Promise.resolve(response);
    });
  },
  switchAwaitingStatusForShift: async ({ commit }, payload) => {
    const { id } = payload;
    const response = await api.switchShift(id, payload);
    if (payload.tab === "ACTIVE") {
      commit("SWITCH_SHIFT", response.data);
    } else {
      commit("SWITCH_SHIFT_RET", response.data);
    }
    return await Promise.resolve(response.data.data.id);
  },
  addShiftsToInvoices: async ({ commit }, payload) => {
    return api.addShiftsToInvoices(payload);
  },
  getShiftsActivityTags: async ({ commit }) => {
    return api.getShiftsActivityTags();
  }
};

const mutations = {
  SET_ACTIVE_TAB(state, activeTab) {
    state.activeTab = activeTab;
  },

  FETCH_SHIFTS(state, { data, meta }) {
    state.shifts = data;
  },
  STORE_SHIFT_FILTERS(state, { tab, params }) {
    if (state.shiftFilter[tab] && typeof state.shiftFilter[tab] === "object") {
      state.shiftFilter[tab] = Object.assign(state.shiftFilter[tab], params);
    } else {
      state.shiftFilter[tab] = params;
    }
  },

  STORE_TEMPS_FOR_SHIFT(state, { data, meta }) {
    state.tempsForShift = data;
  },

  CLEAR_SHIFT_HISTORY(state) {
    state.lastShiftHistory = [];
  },

  FETCH_SHIFT_HISTORY(state, data) {
    // Grab history data from logs property
    state.lastShiftHistory = data.shiftLogs;
  },

  FETCH_SHIFT_REQUEST_REASONS(state, { data }) {
    state.shiftRequestReasons = data;
  },

  FETCH_CANCELLATION_REASONS(state, { data }) {
    state.cancellationReasons = data;
  },

  PATCH_SHIFT(state, payload) {
    const apiObj = payload.data.data;
    const idx = _.findIndex(state.shifts, { id: apiObj.id });
    state.shifts.splice(idx, 1);
  },

  PATCH_SHIFT_RET(state, payload) {
    const apiObj = payload.data.data;
    const idx = _.findIndex(state.shifts, { id: apiObj.id });
    state.shifts.splice(idx, 1, apiObj);
  },

  "clear-shifts"(state) {
    state.shifts = [];
  },

  CANCEL_SHIFT({ shifts }, { data }) {
    // data -- array of shifts id's to be cancelled/removed from state
    const arrRemoveShifts = data.map((shift) => shift.id);
    // delete canceled shift from store state
    _.each(arrRemoveShifts, (id) => {
      shifts.splice(_.indexOf(shifts, _.findWhere(shifts, { id: id })), 1);
    });
  },

  SWITCH_SHIFT(state, { data }) {
    const idx = _.findIndex(state.shifts, { id: data.id });
    state.shifts.splice(idx, 1);
  },
  SWITCH_SHIFT_RET(state, { data }) {
    const idx = _.findIndex(state.shifts, { id: data.id });
    state.shifts.splice(idx, 1, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
