<script>
import { SHIFT_TYPES } from "@/shared/mapData";
import { debounce } from "underscore";
import { mapActions, mapGetters } from "vuex";
import { parseErrors } from "../../lib/helpers/function.js";
// import _ from 'underscore'
import { REPUSH_STATUS } from "../config/shifts.js";

export default {
  props: ["modalProps"],
  data() {
    return {
      shift: null,
      isLoading: false,
      isButtonDisabled: false,
      selectedForPush: [],
      regardlessAvailability: 2,
      searchField: null,
    };
  },
  computed: {
    ...mapGetters({
      tempsForShift: "getTempsForShift",
    }),
  },
  mounted() {
    this.shift = this.modalProps.shift;
    this.fetchTemps(this.shift.id);
  },
  methods: {
    ...mapActions(["fetchTempsForShift", "patchShift"]),
    push() {
      this.isButtonDisabled = true;
      // push to checked temps
      const temps = this.selectedForPush.map((id) => ({ id: id }));
      this.patchShift({
        id: this.modalProps.shift.id,
        data: {
          // IMPORTANT NOTE if null is sent as temp_id, that actually deletes the temp assigned for this shift
          temp_id: this.selectedForPush[0] || null,
        },
      })
        .then(() => {
          this.$toasted
            .success("Success adding a temp to this shift")
            .goAway(2500);
          this.$emit("is-save-loading", false);
          this.callModalClose();
        })
        .catch((error) => {
          this.$emit("is-save-loading", false);
          const errs = parseErrors(error);
          this.$toasted.error(errs).goAway(4500);
        })
        .finally(() => {
          this.isButtonDisabled = false;
        });
    },
    tempStatus: (status) => {
      const statusObj = REPUSH_STATUS.find(
        (statusObj) => statusObj.status === status
      );
      // let statusObj = _.findWhere(REPUSH_STATUS, { status: status })
      if (statusObj) {
        return {
          hex: statusObj.hex,
          label: statusObj.label,
        };
      }
      return {
        hex: "",
        label: "",
      };
    },
    async fetchTemps(id) {
      this.isLoading = true;
      await this.fetchTempsForShift({
        id: this.shift.id,
        availability: this.regardlessAvailability,
        name: this.searchField,
        has_on_call: this.modalProps.shift.type === SHIFT_TYPES.oncall.key,
        has_sleepover: this.modalProps.shift.type === SHIFT_TYPES.sleepover.key,
        shift_type: this.modalProps.shift.type,
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          const errs = parseErrors(error);
          this.$toasted.error(errs).goAway(4500);
        });
    },
    callModalClose() {
      this.$emit("dispose-modal");
    },
    searchWorkers: debounce(async function (evt) {
      const query = evt.target.value;
      await this.fetchTempsForShift({
        id: this.shift.id,
        availability: this.regardlessAvailability,
        name: query,
        has_on_call: this.modalProps.shift.type === SHIFT_TYPES.oncall.key,
        has_sleepover: this.modalProps.shift.type === SHIFT_TYPES.sleepover.key,
        shift_type: this.modalProps.shift.type,
      }).catch((error) => {
        const errs = parseErrors(error);
        this.$toasted.error(errs).goAway(4500);
      });
    }, 200),
  },
};
</script>

<template lang="pug">
.dialog-container.temp-shift-name-manage
  .action-bar
    input.input(
      v-model="searchField",
      placeholder="Search by name",
      title="Search by name",
      type="text",
      @input="searchWorkers"
    )
  .inputs
    .field
      //- label.label
      //-   span Select agency workers for booking
      //-   span(
      //-     style="margin-left: 10px",
      //-     v-if="selectedForPush && selectedForPush.length"
      //-   ) 
      //-   |( Selected workers: {{ selectedForPush.length }} )
      p.control
  .table-wrap
    table.table.is-striped
      thead
        tr
          th Select
          th Name
          th Response status
      tbody
        tr(
          v-for="temp in tempsForShift",
          v-if="!isLoading && tempsForShift.length !== 0"
        )
          td
            input(
              type="checkbox",
              :value="temp.id",
              v-model="selectedForPush",
              v-if="selectedForPush.length === 0 || selectedForPush.findIndex((selId) => selId === temp.id) !== -1"
            )
          td(:class="{ preferred: temp.is_temp_preferred == 1 }") {{ temp.name }}
          td
            span.generic-app-tag(
              :style="{ backgroundColor: tempStatus(temp.response).hex }"
            ) {{ tempStatus(temp.response).label || "-" }}
        tr(v-if="!isLoading && !tempsForShift.length")
          td(colspan="3") No Available temps for this shift
        tr(v-if="isLoading")
          td.center-text(colspan="12")
            img(src="../../assets/images/comps/loader.svg")

  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      :disabled="isButtonDisabled",
      @click.prevent="push"
    ) Save
    button.cancel-btn.button.is-small.is-outlined.is-caps-lock(
      @click.prevent="callModalClose"
    ) Cancel
</template>

<style lang="scss" scoped>
.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.table-wrap {
  max-height: 70vh;
  overflow: auto;
}

.modal-content {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
  padding-top: 1em;
}

.action-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  label {
    margin: 0;
  }
}

.inputs {
  display: flex;
  justify-content: center;

  .field {
    width: 100%;
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

label {
  margin-top: 0.5em;
}

td.preferred {
  font-weight: bold;
}
</style>
