export const FILE_UPLOAD_STATUS = [
  {
    id: 1,
    label: 'Completed',
  },
  {
    id: 2,
    label: 'Pending',
  },
  {
    id: 3,
    label: 'Error',
  }
];