<template lang="pug">
#rates-increments-form
  .name Change increment name
  input.input(v-model="newName", autofocus, ref="nameInput", type="text")
  button.button.is-generic-app-teal(
    :class="{ 'is-loading': isSavingForm }",
    :disabled="!newName || isSavingForm",
    @click="onApplyForm"
  ) Apply
  button.button.cancel(@click="cancelForm") Cancel
</template>

<script>
import { parseErrors } from "../../../../lib/helpers/function";
import { mapActions } from "vuex";
export default {
  props: {
    category: [Object, null],
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newName: "",
      isSavingForm: false,
    };
  },
  computed: {
    increment() {
      return this.formData.increment;
    },
  },
  mounted() {
    this.$refs.nameInput.focus();
    if (this.formData.command === "select") {
      this.newName = this.increment.name;
    }
  },
  methods: {
    ...mapActions({
      patchRatesIncrements: "patchRatesIncrements",
      postRatesIncrements: "postRatesIncrements",
    }),
    cancelForm() {
      this.$emit("cancel");
    },
    async onApplyForm() {
      this.isSavingForm = true;
      const params = {
        catId: this.category.id,
        inc_name: this.newName,
      };

      try {
        if (
          this.formData.command === "select" &&
          this.increment &&
          this.increment.id
        ) {
          params.incId = this.increment.id;
          await this.patchRatesIncrements(params);
          this.$toasted.info("Increment changed").goAway(2000);
          this.$emit("change");
        } else {
          // Unused currently [Creating is via "rates increment tree" comp]
          await this.postRatesIncrements(params);
          this.$toasted.info("New increment created").goAway(2000);
          this.$emit("change");
        }
        this.isSavingForm = false;
      } catch (err) {
        console.log(err.message);
        this.isSavingForm = false;
        let errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
      console.log("apply", params);
    },
  },
};
</script>

<style lang="scss" scoped>
#rates-increments-form {
  .name {
    margin: 0 0 20px 0;
  }

  .cat-hidden {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .lbl {
      margin-right: 10px;
    }
  }

  .button {
    margin: 10px 0 0 0;

    &.cancel {
      margin-left: 10px;
    }
  }
}
</style>
