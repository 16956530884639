<template lang='pug'>
.auto-repush-modal
  .switch-group
    label Off
    tri-state(
      color="accent",
      :value="isEnabledAutoRepush",
      @input="onChangeAutoRepState",
      @click.native.once="isChangedOnceAutoRepushState = true"
    )
    label On
  .bottom-content(:class="{ 'is-loaded': isCompLoaded }")
    transition(name="fade-in", mode="out-in")
      .action-group(v-if="isEnabledAutoRepush", key="action")
        .input-wrap
          multi-select(
            :options="intervalOptions",
            :multiple="false",
            :close-on-select="true",
            track-by="name",
            label="name",
            select-label="",
            deselect-label="",
            v-model="limitValue",
            placeholder="Select interval (minutes)"
          )
    .action-wrap
      button.button.is-generic-app-blue.is-caps-lock(
        @click="saveAutoRepushLimit",
        :disabled="isDisabledSave"
      ) Save
      button.button.is-outlined.is-caps-lock(
        data-cancel,
        @click.prevent="callModalClose"
      ) Cancel
      //- .action-wrap(v-else, key="default")
      //-   button.button.is-outlined.is-caps-lock(@click.prevent="callModalClose") Close
</template>

<script>
import { mapActions } from 'vuex';
import TriState from '../TriStateSwitch.vue';
import MultiSelect from 'vue-multiselect';
export default {
  components: {
    TriState,
    MultiSelect,
  },
  props: {
    modalProps: {
      type: Object,
    },
  },
  data () {
    return {
      isCompLoaded: false,
      isEnabledAutoRepush: false,
      isChangedOnceAutoRepushState: false,
      isLoading: false,
      limitValue: null,
    };
  },
  computed: {
    intervalOptions () {
      const intervals = [30, 60, 120, 180, 240, 360];
      const userFriendlyIntervals = intervals.map(min => ({ name: `${min} minutes`, value: min }));
      // const start = 30
      // const perInc = 30
      // const limit = 180
      // const optionsCount = (limit - start) / perInc
      // const array = []
      // for (let index = 1; index <= optionsCount + 1; index++) {
      //   array.push(start * index)
      // }
      return userFriendlyIntervals;
    },
    isDisabledSave () {
      return (!this.limitValue && !this.isChangedOnceAutoRepushState) || this.isLoading;
    },
  },
  mounted () {
    this.isEnabledAutoRepush = this.modalProps.client.autoRepush || false;
    this.fetchCurrentAutoRepush();
    setTimeout(() => {
      // This is for canceling the initial transition
      this.isCompLoaded = true;
    }, 200);
  },
  methods: {
    ...mapActions({
      updateClient: "locationClientManagement/updateClientAutoRepush",
    }),
    async fetchCurrentAutoRepush () {
      if (this.modalProps.client.autorepushactive) {
        this.isEnabledAutoRepush = this.modalProps.client.autorepushactive;
      }
      // Set minutes to the previous modal option
      const value = this.modalProps.client.autorepushminutes;
      this.limitValue = this.intervalOptions.find(lv => lv.value === value);
    },
    async saveAutoRepushLimit () {
      this.isLoading = true;
      const payload = {
        clientId: this.modalProps.client.id,
        params: {
          autorepushactive: this.isEnabledAutoRepush,
        },
      };
      if (this.limitValue) {
        payload.params.autorepushminutes = this.limitValue.value;
      }
      try {
        await this.updateClient(payload);
        this.$toasted.info('Setting Auto Repush successful!').goAway(2500);
        this.callModalClose();
      } catch (err) {
        this.$toasted.error('There was an error setting Auto Repush interval!').goAway(3000);
        console.log('Error', err.message);
      } finally {
        this.isLoading = false;
      }
    },
    onChangeAutoRepState (evt) {
      this.isEnabledAutoRepush = evt;
    },
    callModalClose () {
      this.$emit('cancel-modal');
    },
  },
};
</script>

<style lang="scss">
.modal-content {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
.auto-repush-modal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  $transitionTime: 0.2s;

  @mixin flexCol {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .switch-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    label {
      margin: 0;
    }

    .tri-state-switch {
      margin-bottom: 6px;
    }
  }

  .bottom-content {
    @include flexCol;
    height: 100px;

    .action-wrap {
      display: flex;
      justify-content: center;
      gap: 15px;
    }
  }

  .action-group {
    @include flexCol;
    align-items: center;
  }

  .multiselect {
    width: 200px;
  }

  .is-loaded {
    .fade-in-enter-active,
    .fade-in-leave-active {
      transition: all $transitionTime ease;
    }

    .fade-in-enter,
    .fade-in-leave-to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}
</style>