<template lang="pug">
#client-invoice-list
  //- span This is an example for invoice list page
  span.row.empty-row
    .control-row
      label.label ID
      input.input(
        v-model="blankRow.split_customid",
        type="text",
        placeholder="ID"
      )
    .control-row
      label.label Name
      input.input(
        v-model="blankRow.split_name",
        type="text",
        placeholder="Name"
      )
    .control-row
      label.label Subcategory
      multi-select(
        :options="filteredCategories(blankRow)",
        :multiple="true",
        :close-on-select="false",
        select-label="",
        deselect-label="",
        track-by="id",
        label="name",
        group-label="name",
        group-values="subcategories",
        :group-select="true",
        placeholder="Select category",
        :value="parseItemCategories(blankRow)",
        @select="onSelectCategories($event, blankRow, false)",
        @remove="onRemoveCategories($event, blankRow, false)"
      )
    button.button.is-success.is-caps-lock(
      style="margin-top: auto",
      :disabled="!isPendingNewRow",
      @click="onAddBlankRow"
    ) Add row
  span.separator
  ul.pending-list
    li.row(v-for="(item, index) in list", :key="item.id")
      //- span {{ item.label }}
      .control-row
        input.input(
          v-model="item.split_customid",
          type="text",
          placeholder="ID",
          @input="setPendingForm()"
        )
      .control-row
        input.input(
          v-model="item.split_name",
          type="text",
          placeholder="Name",
          @input="setPendingForm()"
        )
      .control-row
        multi-select(
          :options="filteredCategories(item)",
          :multiple="true",
          :close-on-select="false",
          select-label="",
          deselect-label="",
          track-by="id",
          label="name",
          group-label="name",
          group-values="subcategories",
          :group-select="true",
          placeholder="Select category",
          :value="parseItemCategories(item)",
          @select="onSelectCategories($event, item)",
          @remove="onRemoveCategories($event, item)"
        )
      span
        span.fa.fa-times(@click="onDeleteRow($event, item, index)")
</template>

<script>
import MultiSelect from "vue-multiselect";
export default {
  components: {
    MultiSelect,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    isFormReady: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      selectedCategory: null,
      blankRow: {},
      resObs: null,
    };
  },
  computed: {
    flatSubcategories () {
      return this.categories.map((cat) => cat.subcategories).flat();
    },
    allUsedSubcategories () {
      const blankRowList = this.blankRow.split_categories || [];
      const originalList = this.list.map((item) => item.split_categories).flat();
      return blankRowList.concat(originalList);
    },
    isPendingNewRow () {
      return Object.keys(this.blankRow).length;
    },
  },
  mounted () {
    // this.setupListResizeObserver();
  },
  methods: {
    onDeleteRow (evt, item, index) {
      const payload = {
        item,
        index,
      };
      this.$emit("delete-row", payload);
    },
    setPendingForm (isTrue = true) {
      this.$emit('form-ready', isTrue);
    },
    onSelectCategories (evt, item, isPatch = true) {
      if (isPatch) {
        this.setPendingForm();
      }

      let values = null;
      if (Array.isArray(evt)) {
        values = evt.map(val => val.id);
      } else {
        values = [evt.id];
      }

      if (Array.isArray(item.split_categories)) {
        item.split_categories.push(...values);
      } else {
        this.$set(item, "split_categories", values);
      }
    },
    onRemoveCategories (evt, item, isPatch = true) {
      if (isPatch) {
        this.setPendingForm();
      }

      let values = null;
      if (Array.isArray(evt)) {
        values = evt.map(val => val.id);
      } else {
        values = [evt.id];
      }

      if (Array.isArray(item.split_categories)) {
        // This should always be true
        item.split_categories = item.split_categories.filter(
          (scId) => !values.includes(scId)
        );
      } else {
        console.warn("No subcategories array");
      }
    },
    onAddBlankRow () {
      if (this.isPendingNewRow) {
        this.$emit('added-row', this.blankRow);
        this.blankRow = {};
        this.setPendingForm();
      }
    },
    parseItemCategories (item) {
      const currSubs = item.split_categories || [];
      return currSubs.map((subId) =>
        this.flatSubcategories.find((fs) => fs.id === subId)
      );
    },
    filteredCategories (item) {
      const usedSubcategoriesByOtherInvoice = this.allUsedSubcategories.filter(
        (ausId) => !(item.split_categories || []).includes(ausId)
      );

      return this.categories.map(cat => {
        return {
          ...cat,
          subcategories: cat.subcategories.filter(sc => !usedSubcategoriesByOtherInvoice.includes(sc.id)),
        };
      });
    },
    // setupListResizeObserver () {
    //   this.resObs = new ResizeObserver(entries => {
    //     const el = document.querySelector('.pending-list');
    //     if (el && el.scrollHeight > el.clientHeight) {
    //       el.classList.add('auto');
    //     } else {
    //       el.classList.remove('auto');
    //     }
    //   });

    //   const el = document.querySelector('.pending-list');
    //   this.resObs.observe(el, {});
    // },
  },
};
</script>

<style lang="scss" scoped>
#client-invoice-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;

  .pending-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    // max-height: 640px;
    padding: 10px 0;
    overflow: auto;

    // &.auto {
    //   overflow: auto;
    // }
  }

  .row {
    display: flex;
    // align-items: center;
    gap: 10px;

    .control-row {
      display: flex;
      flex-direction: column;
    }

    .input {
      height: 40px;
    }

    .fa {
      // Don't center vertically
      // margin-top: 25px;
      cursor: pointer;

      &:hover {
        color: crimson;
      }
    }
  }

  .separator {
    width: 100%;
    border-top: 1px solid silver;
  }
}
</style>

<style lang="scss">
#client-invoice-list {
  .row {
    .multiselect {
      .multiselect__tags {
        width: 400px;
        max-height: 400px;
        overflow: auto;
      }
    }
  }
}
</style>