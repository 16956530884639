<template lang="pug">
#client-invoice-info
  client-invoice-master-id(
    :clientData="clientData",
    @client-updated="$emit('client-updated')"
  )
  client-invoice-list(
    :list="clientInvoiceList",
    :categories="clientCategories",
    :isFormReady="isFormReady",
    @form-ready="isFormReady = $event",
    @added-row="onAddedRow",
    @delete-row="onDeleteRow"
  )
  button.button.is-generic-app-teal.is-caps-lock(
    :disabled="!isFormReady",
    @click="onCreateInvoiceSplit"
  )
    span Save invoice splits
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import ClientInvoiceMasterId from './Invoice/ClientInvoiceMasterId.vue';
import ClientInvoiceList from "./Invoice/ClientInvoiceList.vue";
import { mapActions } from "vuex";
import { parseErrors } from '../../lib/helpers/function';
export default {
  components: {
    ClientInvoiceList,
    ClientInvoiceMasterId,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      clientInvoiceList: [],
      clientCategories: [],
      isFormReady: false,
    };
  },
  mounted () {
    this.fetchClientInvoiceList();
    this.fetchClientCategories();
  },
  methods: {
    ...mapActions({
      getClientCategories: "getClientCategories",
      getConfig: "getConfig",
      postInvoiceSplit: "postConfig",
    }),
    async onDeleteRow (evt) {
      try {
        await this.confirmDeleteAlert('delete the row');
        this.clientInvoiceList.splice(evt.index, 1);
        this.isFormReady = true;
      } catch (err) {
        // ignored
      }
    },
    onAddedRow (evt) {
      this.clientInvoiceList.unshift(evt);
    },
    confirmDeleteAlert (strAct) {
      return new Promise((resolve,) => {
        let alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: '',
          type: 'warning',
          useConfirmBtn: true,
          customConfirmBtnText: 'Confirm',
          customConfirmBtnClass: 'button is-danger',
          customCloseBtnText: 'Cancel',
          customCloseBtnClass: 'button is-outlined',
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async fetchClientInvoiceList () {
      const params = {
        ref_type: "cli",
        ref_id: this.clientData.id,
        key: "invoicesplit",
      };
      try {
        const res = await this.getConfig(params);
        this.clientInvoiceList = res.data.data || [];
      } catch (err) {
        console.error(err.message);
      }
    },
    async fetchClientCategories () {
      const params = {
        client_id: this.clientData.id,
      };
      try {
        const res = await this.getClientCategories(params);
        console.log(res.data);
        this.clientCategories = res.data.data || [];
      } catch (err) {
        console.log(err.message);
      }
    },
    async onCreateInvoiceSplit () {
      const params = {
        ref_type: "cli",
        ref_id: this.clientData.id,
        key: "invoicesplit",
        value: this.clientInvoiceList,
      };
      const label = 'save these changes?';

      try {
        await this.confirmApplyChanges(label);
        await this.postInvoiceSplit(params);
        this.$toasted.success('Successfully saved invoices').goAway(2500);
        this.isFormReady = false;
      } catch (err) {
        console.error(err.message);
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
    },
    confirmApplyChanges (name = `apply changes?`) {
      return new Promise((resolve) => {
        let alert = {
          title: `Are you sure you want to ${name}`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#client-invoice-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
  gap: 15px;
  align-items: center;
  height: 100%;
  overflow: hidden;
}
</style>
