<template lang="pug">
.holiday-categories
  .header
    .left
      label
        input(type="checkbox", :checked="selectAll", @click="onSelectAll")
        span Subcategories
        span(v-if="checkedSubcategories.length")
          span ({{ checkedSubcategories.length }} Checked)
    .right
      .fa.fa-refresh(
        title="Undo",
        @click="fetchHolidayRequestStatus(); selectAll = false"
      )
  input.input(v-model="search", placeholder="Filter Subcategories")
  .loading-wrap(v-if="isLoading")
    .loading-wrapper
      img.client-loading(src="@/assets/images/comps/loader.svg")
  template(v-if="!isLoading")
    checkbox-list-menu(
      :list="transformedList",
      :checkedList="checkedItemIds",
      :expandedItems="expandedItems",
      @click="onClickedItem",
      @checked="onCheckedItem"
    )
    .buttons-centered
      button.button.is-small.is-generic-app-blue.is-caps-lock(
        type="submit",
        @click.prevent="onClickSave"
      ) Save
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { findRecordErrors, parseErrors } from "../../../lib/helpers/function";
export default {
  data() {
    return {
      checkedItemIds: [],
      expandedItems: [],
      selectAll: false,
      isLoading: false,
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      categories: "getAllCategories",
    }),
    checkedSubcategories() {
      // Filters checked categories (parents)
      const catIds = this.transformedList.map((cat) => cat.id);
      return this.checkedItemIds.filter((cItem) => !catIds.includes(cItem));
    },
    transformedList() {
      const searchLower = this.search.trim().toLowerCase();

      const recFilter = (
        arr = [],
        includeParentNode = true,
        hideEmptyPar = false
      ) => {
        return arr.reduce((acc, item) => {
          if (includeParentNode) {
            if (item.name.toLowerCase().includes(searchLower)) {
              acc.push({
                ...item,
                children: item.subcategories,
                subcategories: undefined,
              });
            }
          } else if (item.subcategories?.length) {
            const filteredSubs = recFilter(item.subcategories);
            if (
              filteredSubs.length ||
              (!hideEmptyPar && !filteredSubs.length)
            ) {
              const newCat = {
                ...item,
                children: filteredSubs,
                subcategories: undefined,
              };
              acc.push(newCat);
            }
          }
          return acc;
        }, []);
      };

      return recFilter(this.categories, false, true);
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchHolidayRequestStatus();
  },
  methods: {
    ...mapActions({
      fetchAllCategoriesList: "fetchAllCategoriesList",
      getCategoriesHolidayRequest: "getCategoriesHolidayRequest",
      postCategoriesHolidayRequest: "postCategoriesHolidayRequest",
    }),
    onSelectAll(evt) {
      this.selectAll = evt.target.checked;

      if (this.selectAll) {
        this.checkedItemIds = this.getAllIdsRec(this.transformedList);
      } else {
        this.checkedItemIds = [];
      }
    },
    onClickedItem(evt) {
      if (evt.children?.length) {
        const itemIndex = this.expandedItems.findIndex(
          (eItem) => evt.id === eItem
        );
        if (itemIndex !== -1) {
          this.expandedItems.splice(itemIndex, 1);
        } else {
          this.expandedItems.push(evt.id);
        }
      } else {
        // QoL toggle
        this.onCheckedItem({
          item: evt,
        });
      }
    },
    onCheckedItem(evt) {
      const isItemChecked =
        this.checkedItemIds.findIndex((cItem) => cItem === evt.item.id) !== -1;

      let childrenIds = [];
      const children = evt.item.children;
      if (children?.length) {
        childrenIds = this.getAllIdsRec(children);
      }
      const isAllChildrensChecked = childrenIds.every((childId) =>
        this.checkedItemIds.includes(childId)
      );

      const checkItemAndChildren = () => {
        const selSet = new Set(this.checkedItemIds);
        selSet.add(evt.item.id);

        for (const id of childrenIds) {
          selSet.add(id);
        }
        this.checkedItemIds = Array.from(selSet);
      };

      const uncheckItemAndChildren = () => {
        this.checkedItemIds = this.checkedItemIds.filter(
          (cItem) => cItem !== evt.item.id && !childrenIds.includes(cItem)
        );
      };

      if (evt.checked) {
        checkItemAndChildren();
      } else if (evt.checked === false) {
        uncheckItemAndChildren();
      } else if (!isItemChecked) {
        if (children?.length && !isAllChildrensChecked) {
          checkItemAndChildren();
        } else if (!children?.length) {
          checkItemAndChildren();
        }
      } else {
        uncheckItemAndChildren();
      }
      this.selectAll = false;
    },
    getAllIdsRec(arr = []) {
      return arr.reduce((acc, curr) => {
        acc.push(curr.id);
        if (curr.children?.length) {
          const ids = this.getAllIdsRec(curr.children);
          acc.push(...ids);
        }
        return acc;
      }, []);
    },
    async fetchCategories() {
      this.isLoading = true;
      const params = {};
      try {
        await this.fetchAllCategoriesList(params);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("Error", err.message);
      }
    },
    async fetchHolidayRequestStatus() {
      const params = {};
      try {
        const res = await this.getCategoriesHolidayRequest(params);
        this.checkedItemIds = res.data.data || [];
      } catch (err) {
        console.warn(err.message);
      }
    },
    async onClickSave() {
      try {
        await this.confirmActionAlert("save holiday request status");
        this.saveToggleHolidayRequest();
      } catch (err) {
        // ignored
      }
    },
    confirmActionAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async saveToggleHolidayRequest() {
      const params = {
        payload: {
          subcategories: this.checkedSubcategories.map((cItem) => ({
            id: cItem,
          })),
        },
      };
      try {
        const res = await this.postCategoriesHolidayRequest(params);
        this.$toasted.success(res.data.message).goAway(2000);
      } catch (err) {
        console.warn(err.message);
        const errors = findRecordErrors(err);
        if (errors) {
          this.errors.record(errors);
          this.$toasted
            .show("Please correct the following errors.")
            .goAway(2500);
          this.errors.all().map((e) => {
            this.$toasted.error(e).goAway(2500);
          });
        } else {
          const errs = parseErrors(err, "Error saving", true);
          console.warn(err.response.data.message);
          this.$toasted.error(errs).goAway(4500);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.holiday-categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 400px;

  .header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    .left {
      display: flex;
      gap: 10px;

      label {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .fa {
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: black;
        }
      }
    }
  }

  > input {
    min-height: 30px;
    height: 30px;
  }

  .checkbox-list-menu {
    overflow: auto;
    height: 100%;
  }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }

  .buttons-centered {
    margin-top: auto;
  }
}
</style>
