<template lang="pug">
#client-profile-info
  //- .header
  form.form
    .left-side
      p.control
        .field
          label.label Name
          p.control
            input.input(
              type="text",
              placeholder="Please enter Client name here",
              v-model="clientForm.name",
              :disabled="isLockedInputs",
              @input="errors.clear('name')"
            )
      span.help.is-danger(
        v-if="errors.has('name')",
        v-html="errors.get('name')"
      )
      template(v-if="$can('view-client-custom-id')")
        p.control
          .field
            label.label Client id
            p.control
              input.input(
                type="number",
                placeholder="Please enter Client id here",
                :value="clientForm.custom_client_id",
                :disabled="true",
                @input="errors.clear('custom_client_id')"
              )
              //- 
                v-model.number="clientForm.custom_client_id",
                :disabled="isDisabledClientId || isLockedInputs",
        span.help.is-danger(
          v-if="errors.has('custom_client_id')",
          v-html="errors.get('custom_client_id')"
        )
      p.control
        .field
          label.label Region
          multi-select(
            :options="sectors",
            :multiple="true",
            :close-on-select="false",
            track-by="id",
            label="name",
            v-model="clientForm.sectors",
            placeholder="SELECT CLIENT REGION",
            :disabled="isLockedInputs",
            @select="errors.clear('sector_id')"
          )
      span.help.is-danger(
        v-if="errors.has('sector_id')",
        v-html="errors.get('sector_id')"
      )
      //- p.control
        .field
          label.label CHO Area
          multi-select(
            :options="optionsClientType",
            @select="errors.clear('type')",
            track-by="id",
            label="name",
            v-model="selectedType",
            placeholder="SELECT CHO AREA"
          )
      //- span.help.is-danger(v-if="errors.has('type')", v-html="errors.get('type')")
      p.control
        .field
          label.label Rate Type
          multi-select(
            :options="rateClientOptionsFiltered",
            track-by="id",
            label="name",
            v-model="selectedRateType",
            placeholder="SELECT RATE TYPE",
            :disabled="isLockedInputs",
            @select="onSelectRateType"
          )
      span.help.is-danger(
        v-if="errors.has('rate_type_id')",
        v-html="errors.get('rate_type_id')"
      )
      p.control
        .field(v-if="isRateTypeSelectedValid")
          label.label Rate Clients
          multi-select(
            :options="rateClientsOptions",
            :loading="isRateClientLoading",
            @select="errors.clear('rate_client_id')",
            track-by="id",
            label="name",
            v-model="selectedRateClient",
            placeholder="SELECT RATE CLIENT",
            :disabled="isLockedInputs"
          )
      span.help.is-danger(
        v-if="errors.has('rate_client_id')",
        v-html="errors.get('rate_client_id')"
      )
      //- p.control
        .field
          label.label Service Fee Calculation Type
          multi-select(
            :options="feeTypes",
            @select="errors.clear('service_fee_calculation_type_id')",
            track-by="id",
            label="name",
            v-model="selectedServiceFeeCalculationType",
            placeholder="SELECT SERVICE FEE CALCULATION TYPE"
          )
      //- span.help.is-danger(
        v-if="errors.has('service_fee_calculation_type_id')",
        v-html="errors.get('service_fee_calculation_type_id')"
      //- )
      //- p.control
        .field
          label.label Rules
          multi-select(
            :options="allowances",
            track-by="id",
            label="name",
            :value="clientForm.calcEngine",
            placeholder="SELECT CLIENT RULES",
            @select="selectAllowances"
          )
      span.help.is-danger(
        v-if="errors.has('calcengine_id')",
        v-html="errors.get('calcengine_id')"
      )
      p.control
        .field
          label.label Address Line 1
          p.control
            input.input(
              type="text",
              placeholder="Please enter Client address here",
              v-model="clientForm.addressLine1",
              :disabled="isLockedInputs",
              @input="errors.clear('address_line_1')"
            )
      span.help.is-danger(
        v-if="errors.has('address_line_1')",
        v-html="errors.get('address_line_1')"
      )
      p.control
        .field
          label.label Address Line 2
          p.control
            input.input(
              type="text",
              placeholder="Please enter Client address here",
              v-model="clientForm.addressLine2",
              :disabled="isLockedInputs",
              @input="errors.clear('address')"
            )
      span.help.is-danger(
        v-if="errors.has('address')",
        v-html="errors.get('address')"
      )
    .right-side
      p.control
        .field
          label.label County
          multi-select(
            :options="counties",
            @select="errors.clear('region_id')",
            track-by="id",
            label="name",
            v-model="clientForm.region",
            placeholder="SELECT CLIENT COUNTY",
            :disabled="isLockedInputs"
          )
      span.help.is-danger(
        v-if="errors.has('region_id')",
        v-html="errors.get('region_id')"
      )
      p.control
        .field
          label.label {{ getEirCodeLabelKey }}
          p.control
            input.input(
              type="text",
              :placeholder="'Please enter Client ' + getEirCodeLabelKey + ' here'",
              v-model="clientForm.eircode",
              :disabled="isLockedInputs",
              @input="errors.clear('eircode')"
            )
      span.help.is-danger(
        v-if="errors.has('eircode')",
        v-html="errors.get('eircode')"
      )
      p.control
        .field
          label.label Billing Contact Name
          p.control
            input.input(
              type="text",
              placeholder="Please enter Client contact name here",
              v-model="clientForm.billingContactName",
              :disabled="isLockedInputs",
              @input="errors.clear('billing_contact_name')"
            )
      span.help.is-danger(
        v-if="errors.has('billing_contact_name')",
        v-html="errors.get('billing_contact_name')"
      )
      p.control
        .field
          label.label Billing Contact Title
          p.control
            input.input(
              type="text",
              placeholder="Please enter Client contact title here",
              v-model="clientForm.billingContactTitle",
              :disabled="isLockedInputs",
              @input="errors.clear('billing_contact_title')"
            )
      span.help.is-danger(
        v-if="errors.has('billing_contact_title')",
        v-html="errors.get('billing_contact_title')"
      )
      p.control
        .field
          label.label Billing Contact E-mail
          p.control
            input.input(
              type="text",
              placeholder="Please enter Client e-mail here",
              v-model="clientForm.billingContactEmail",
              :disabled="isLockedInputs",
              @input="errors.clear('billing_contact_email')"
            )
      span.help.is-danger(
        v-if="errors.has('billing_contact_email')",
        v-html="errors.get('billing_contact_email')"
      )
      p.control
        .field
          label.label Phone
          p.control
            input.input(
              type="text",
              placeholder="Please enter Client Phone here",
              v-model="clientForm.phone",
              :disabled="isLockedInputs"
            )
      span.help.is-danger(
        v-if="errors.has('phone')",
        v-html="errors.get('phone')"
      )
  .buttons-centered
    template(v-if="gPermissions.canClientProfileEdit")
      button.button.is-generic-app-blue.is-caps-lock(
        type="button",
        :disabled="isSavingClient || !isLockedInputs",
        v-if="isLockedInputs",
        @click.prevent="isLockedInputs = false"
      )
        span Unlock
      button.button.is-generic-app-blue.is-caps-lock(
        type="submit",
        :disabled="isSavingClient || isLockedInputs",
        v-if="!isLockedInputs",
        @click.prevent="onClientSubmit"
      )
        img.btn-loader(
          src="../../assets/images/comps/loader.svg",
          v-if="isSavingClient"
        )
        span Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Errors } from "../../lib/helpers/Errors.js";
import { RATE_TYPE_OPTIONS } from "../in-modal/location-client-mgmt/rateData.js";
import MultiSelect from "vue-multiselect";
export default {
  name: "ClientProfileInfo",
  components: {
    MultiSelect,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      clientForm: {},
      isRateClientLoading: false,
      isSavingClient: false,
      selectedRateType: null,
      selectedRateClient: null,
      rateClientsOptions: [],
      isLockedInputs: true,
      errors: new Errors(),
    };
  },
  computed: {
    ...mapGetters({
      counties: "locationClientManagement/getCounties",
      sectors: "locationClientManagement/getFlatSectors",
      // allowances: "locationClientManagement/getAllowances",
      agencies: "locationClientManagement/getAgencies",
      // feeTypes: "locationClientManagement/getServiceFeeTypes",
    }),
    getEirCodeLabelKey() {
      return this.$store.getters.getColumnNameLocl("usr_eircode");
    },
    isRateTypeSelectedValid() {
      if (this.selectedRateType && this.selectedRateType.id) {
        const validRateTypeIdsForNextSection = [1, 2];
        return validRateTypeIdsForNextSection.includes(
          this.selectedRateType.id
        );
      }
      return false;
    },
    rateClientOptionsFiltered() {
      return RATE_TYPE_OPTIONS.filter((rt) => rt.id !== 2).map((rt) => ({
        ...rt,
        name: rt.altName || rt.name,
      }));
    },
    isDisabledClientId() {
      return !this.$can("edit-client-custom-id");
    },
  },
  mounted() {
    this.fetchRegions();
    this.fetchSectors();
    // this.fetchAllowances();
    this.fetchAgencies();
    // this.fetchServiceFeeTypes()
    this.setupClientDataForm();
    this.setupInitialClientRate();
  },
  methods: {
    ...mapActions({
      putClientData: "locationClientManagement/putClientData",
      fetchRegions: "locationClientManagement/fetchRegions",
      fetchAgencies: "locationClientManagement/fetchAgencies",
      fetchSectors: "locationClientManagement/fetchSectors",
      // fetchAllowances: "locationClientManagement/fetchAllowances",
      // fetchServiceFeeTypes: "locationClientManagement/fetchServiceFeeTypes",
      getRatesClientsForClientManagement: "getRatesClientsForClientManagement",
    }),
    setupClientDataForm() {
      this.clientForm = { ...this.clientData };
    },
    async setupInitialClientRate() {
      const rateId = this.clientData.useRatesFrom;
      if (rateId) {
        this.selectedRateType = this.rateClientOptionsFiltered.find(
          (rt) => rt.id === 1
        );
        await this.fetchRateClients();
        const foundObj = this.rateClientsOptions.find((rc) => rc.id === rateId);
        if (foundObj) {
          this.selectedRateClient = foundObj;
        }
      } else {
        this.selectedRateType = this.rateClientOptionsFiltered.find(
          (rt) => rt.id === 3
        );
      }
    },
    async fetchRateClients() {
      const id = this.selectedRateType && this.selectedRateType.id;
      this.rateClientsOptions = [];
      this.isRateClientLoading = true;
      const params = {};
      if (id === 1) {
        params.system = 1;
      }
      try {
        const res = await this.getRatesClientsForClientManagement(params);
        this.rateClientsOptions = res.data.data;
      } catch (err) {
        console.log("Error >> ", err.message);
      } finally {
        this.isRateClientLoading = false;
      }
    },
    async onClientSubmit() {
      let postParams = {
        clientId: this.clientData.id,
        params: {
          region_id: this.clientForm.region && this.clientForm.region.id,
          sectors:
            Array.isArray(this.clientForm.sectors) &&
            this.clientForm.sectors.map((sec) => ({ id: sec.id })),
          // type: this.selectedType.id,
          // calcengine_id: this.clientForm.calcEngine && this.clientForm.calcEngine.id, // Not used anymore
          name: this.clientForm.name,
          // custom_client_id: this.clientForm.custom_client_id,
          address_line_1: this.clientForm.addressLine1,
          address_line_2: this.clientForm.addressLine2,
          eircode: this.clientForm.eircode,
          billing_contact_name: this.clientForm.billingContactName,
          billing_contact_title: this.clientForm.billingContactTitle,
          billing_contact_email: this.clientForm.billingContactEmail,
          phone: this.clientForm.phone,
          // service_fee_calculation_type_id: this.selectedServiceFeeCalculationType.id,
          rate_client_action: this.selectedRateType && this.selectedRateType.id,
          rate_client_id: this.selectedRateClient && this.selectedRateClient.id,
        },
      };
      if(this.clientForm.custom_client_id) {
        postParams.params.custom_client_id = this.clientForm.custom_client_id;
      }

      this.isSavingClient = true;

      try {
        await this.putClientData(postParams);
        this.$toasted.success("Client updated").goAway(2000);
        this.isSavingClient = false;
        this.isLockedInputs = true;
        this.$emit("client-updated", this.clientData);
      } catch (errors) {
        this.errors.record(errors);
        this.isSavingClient = false;
        this.$toasted.error("Failed updating client data").goAway(2000);
      }
    },
    onSelectRateType() {
      this.selectedRateClient = null;
      this.errors.clear("rate_type_id");
      this.$nextTick(async () => {
        // This is because the computed is done after the event
        if (this.isRateTypeSelectedValid) {
          await this.fetchRateClients();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#client-profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  overflow: hidden;

  .form {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
    overflow: auto;
    height: 100%;

    .left-side,
    .right-side {
      width: 100%;
      max-width: 350px;
    }
  }

  input {
    height: 40px;
  }
}
</style>
