export function cssVar (name, value) {
  if (name[0] !== '-') name = '--' + name; // allow passing with or without --
  if (value !== null && value !== undefined) document.documentElement.style.setProperty(name, value);
  return getComputedStyle(document.documentElement).getPropertyValue(name);
}

export function getHexColor (colorStr) {
  var a = document.createElement('div');
  a.style.color = colorStr;
  var colors = window.getComputedStyle(document.body.appendChild(a)).color.match(/\d+/g).map(function (a) { return parseInt(a, 10); });
  document.body.removeChild(a);
  return (colors.length >= 3) ? '#' + (((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).substr(1)) : false;
}

export function hexToHSLArr (H) {
  // Convert hex to RGB first
  // eslint-disable-next-line one-var
  let r = 0, g = 0, b = 0;
  if (H.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  // eslint-disable-next-line one-var
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) { h = 0; } else if (cmax === r) { h = ((g - b) / delta) % 6; } else if (cmax === g) { h = (b - r) / delta + 2; } else { h = (r - g) / delta + 4; }

  h = Math.round(h * 60);

  if (h < 0) { h += 360; }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [h, s, l];
}

export function hexToHSL (H) {
  const arr = hexToHSLArr(H);
  return 'hsl(' + arr[0] + ',' + arr[1] + '%,' + arr[2] + '%)';
}
