<template lang="pug">
.sort
  i.fa.fa-long-arrow-up.fa-rotate-180(
    :class="{ active: isAsc }",
    @click.stop="sort(DIRECTIONS.ASC)"
  )
  i.fa.fa-long-arrow-up(
    :class="{ active: isDesc }",
    @click.stop="sort(DIRECTIONS.DESC)"
  )
</template>

<script>
export default {
  name: "SortButtons",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    columnName: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: () => {
        return {
          column: "",
          direction: "",
        };
      },
      required: true,
    },
  },
  data() {
    return {
      direction: "",
    };
  },
  computed: {
    isAsc() {
      if (!this.value) {
        return false;
      }
      return (
        this.columnName === this.value.column &&
        this.value.direction === this.DIRECTIONS.ASC
      );
    },
    isDesc() {
      if (!this.value) {
        return false;
      }
      return (
        this.columnName === this.value.column &&
        this.value.direction === this.DIRECTIONS.DESC
      );
    },
  },
  methods: {
    sort(direction) {
      this.direction =
        this.direction === direction && this.columnName === this.value.column
          ? ""
          : direction;
      let column = this.direction !== "" ? this.columnName : "";

      this.$emit("change", {
        column,
        direction: this.direction,
      });
    },
  },
  created() {
    this.DIRECTIONS = {
      ASC: "asc",
      DESC: "desc",
    };
  },
};
</script>

<style lang="scss" scoped>
.sort {
  display: inline-block;
  vertical-align: sub;
  margin-left: 2px;
  color: #aebbcc;

  > i {
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    width: 10px;

    &.active {
      color: var(--color-primary);
    }
  }

  > i:first-child {
    margin-top: 1px;
  }
}
</style>
