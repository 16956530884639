<template lang="pug">
.notification-email-from-list
  ul
    li
      input.input(
        v-model="newEmail",
        type="text",
        placeholder="New Email From"
        @keyup.enter='onAddToList'
      )
      button.button.is-small.is-generic-app-blue.is-caps-lock(
        :disabled='!isEmailValid'
        @click="onAddToList"
      )
        span Add to list
    li(
      v-for="(item, index) in emailFromList",
      :key="index",
      :class="{ pending: !item.id, modified: isItemModified(item) }"
    )
      input.input(
        v-model="item.email",
        type="text",
      )
      button.button.is-small.is-danger.is-caps-lock(
        @click="onRemoveFromList(item)"
      )
        span Remove from list
    li(style='align-self: center;' v-if='isAnyModified')
      button.button.is-small.is-generic-app-blue.is-caps-lock(
          @click="onRestoreList"
        )
        span UNDO changes
  .legend
    .color.pending
      .palette
      span Pending
    .color.modified
      .palette
      span Modified
  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      :disabled="!isAnyModified",
      type="submit",
      @click.prevent="onCommitList"
    ) Save
    //- button.button.is-small.is-outlined.is-caps-lock(
    //-   @click.prevent="callModalClose"
    //- ) Cancel
</template>

<script>
import { mapActions } from "vuex";
import { parseErrors } from "../../lib/helpers/function";
export default {
  data() {
    return {
      originalEmailList: [],
      emailFromList: [],
      newEmail: "",
    };
  },
  computed: {
    isEmailValid() {
      return this.validateEmail(this.newEmail);
    },
    modifiedItems() {
      const newListMapped = this.emailFromList.map(
        (emailObj) => emailObj.email
      );
      const ogListMapped = this.originalEmailList.map(
        (emailObj) => emailObj.email
      );

      const newListFiltered = this.emailFromList.filter(
        (emailObj) => !ogListMapped.includes(emailObj.email)
      );

      return newListFiltered.concat(
        this.originalEmailList.filter((emailObj) => {
          const isAlreadyAdded = newListFiltered
            .map((newEmailObj) => newEmailObj.id)
            .includes(emailObj.id);
          return !isAlreadyAdded && !newListMapped.includes(emailObj.email);
        })
      );
    },
    isAnyModified() {
      return (
        this.emailFromList.some((email) => !email.id) ||
        this.modifiedItems.length
      );
    },
  },
  mounted() {
    this.fetchEmailFrom();
  },
  methods: {
    ...mapActions({
      getEmailFrom: "getEmailFrom",
      postEmailFrom: "postEmailFrom",
    }),
    onAddToList() {
      if (!this.isEmailValid) {
        return;
      }

      const isSameEmail = this.emailFromList.some(
        (emailObj) => emailObj.email === this.newEmail
      );
      if (isSameEmail) {
        this.$toasted.error("You can't add the same email twice").goAway(4500);
        return;
      }
      this.emailFromList.push({
        email: this.newEmail,
      });
      this.newEmail = "";
    },
    async fetchEmailFrom() {
      try {
        const res = await this.getEmailFrom();
        this.emailFromList = res.data.data || [];
        this.originalEmailList = JSON.parse(JSON.stringify(this.emailFromList));
      } catch (err) {
        console.warn(err.message);
      }
    },
    async onRemoveFromList(item) {
      this.emailFromList = this.emailFromList.filter(
        (emailObj) => emailObj.email !== item.email
      );
    },
    async onCommitList() {
      await this.saveemailFromList();
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async saveemailFromList() {
      const params = {
        emails: this.emailFromList,
      };
      try {
        const res = await this.postEmailFrom(params);
        this.$toasted
          .success(res.data.message || "Success saving email from list")
          .goAway(2000);
        // this.$emit("commit-list", this.emailFromList);
        this.originalEmailList = [];
        this.newEmail = "";
        this.fetchEmailFrom();
      } catch (err) {
        console.warn(err.message);
        let errs = parseErrors(err, "Error saving email from list", true);
        console.warn(err.response.data.message);
        this.$toasted.error(errs).goAway(4500);
      }
    },
    isItemModified(item) {
      return item.id
        ? this.modifiedItems.map((email) => email.id).includes(item.id)
        : false;
    },
    onRestoreList() {
      this.emailFromList = JSON.parse(JSON.stringify(this.originalEmailList));
    },
    callModalClose() {
      this.$emit("dispose-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-email-from-list {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 20px 0;
  gap: 20px;

  $pending-color: #fff9dc;
  $modified-color: #d0e9ff;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;

    li {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 30px;
    }

    input {
      width: 100%;
    }

    .pending {
      input {
        background: $pending-color;
      }
    }

    .modified {
      input {
        background: $modified-color;
      }
    }
  }

  .legend {
    display: flex;
    align-items: center;
    gap: 15px;
    width: fit-content;
    border-radius: 5px;
    padding: 5px 10px;
    align-self: center;

    .color {
      display: flex;
      align-items: center;
      gap: 10px;

      &.pending {
        .palette {
          background: $pending-color;
        }
      }

      &.modified {
        .palette {
          background: $modified-color;
        }
      }

      .palette {
        width: 15px;
        height: 15px;
        border: 1px solid #cdcdcd;
      }
    }
  }
}
</style>
