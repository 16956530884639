var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"main-app"}},[(_vm.isProgressRefreshToken || _vm.isLoadingDomainConfig || !_vm.canLoadContent)?_c('progress-block'):_vm._e(),(!_vm.isProgressRefreshToken && !_vm.isLoadingDomainConfig && _vm.canLoadContent)?[_c('transition',{attrs:{"name":"bounceUp"}},[(_vm.showSettingsModal)?_c('modal',{attrs:{"scrollable":_vm.isModalScrollable,"title":"Settings","icon":"cog","size":"459"},on:{"close":function($event){_vm.showSettingsModal = false}}},[_c('user-settings-form')],1):_vm._e()],1),(_vm.auth.user.authenticated)?_c('nav',{staticClass:"nav has-shadow"},[_c('div',{staticClass:"nav-has-nothing"}),_c('div',{staticClass:"nav-has-logo"},[_c('div',{staticClass:"nav-item"},[_c('a',[_c('img',{attrs:{"src":_vm.getGlobalImageLogoApi,"alt":""}})])])]),_c('div',{staticClass:"nav-right nav-menu"},[(_vm.auth.user.authenticated)?_c('a',{staticClass:"nav-item nav-item--expanded",on:{"click":function($event){$event.preventDefault();_vm.userActions = !_vm.userActions}}},[_vm._v(" "+_vm._s(_vm.auth.user.profile.name)+" "),_c('i',{staticClass:"fa drop-arrow",class:{
              'fa-angle-up': !_vm.userActions,
              'fa-angle-down': _vm.userActions,
            }})]):_vm._e(),(!_vm.auth.user.authenticated)?_c('router-link',{staticClass:"nav-item nav-item--expanded",attrs:{"to":{ name: 'signin' }}},[_vm._v("Sign in")]):_vm._e(),_c('transition',{attrs:{"name":""}},[(_vm.userActions)?_c('div',{staticClass:"action-cont"},[(_vm.auth.user.authenticated)?_c('a',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();_vm.showSettingsModal = true;
                _vm.userActions = !_vm.userActions;}}},[_vm._v(" Settings "),_c('i',{staticClass:"fa fa-cog alignment"})]):_vm._e(),(_vm.auth.user.authenticated)?_c('a',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.signout.apply(null, arguments)}}},[_vm._v(" Logout "),_c('i',{staticClass:"fa fa-share alignment"})]):_vm._e()]):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"main-content",class:{
        isOpen: _vm.zapOpened,
        'is-fixed-page-height': _vm.isFixedPageComp,
        'is-100-wid': _vm.isFixedWidthComp,
        'login-background': _vm.hasSpecialBackground(),
        'modern-main-content': _vm.modernUiComps,
      }},[(!_vm.modernUiComps)?_c('div',{staticClass:"container is-fluid"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column col-12"},[_c('transition',{attrs:{"name":"fadeCustom","mode":"out-in"}},[_c('keep-alive',{attrs:{"include":_vm.compsToKeep}},[_c('router-view')],1)],1)],1)])]):(_vm.modernUiComps)?_c('div',{staticClass:"modern-wrapper"},[_c('keep-alive',{attrs:{"include":_vm.compsToKeep}},[_c('router-view')],1)],1):_vm._e()]),(_vm.auth.user.authenticated)?_c('zap-slideout'):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }