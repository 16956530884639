<template lang="pug">
#client-finances-info
  .top-action
    .bar.left
      p
        label.label Category
        multi-select.category-select(
          :options="categories",
          :searchable="true",
          :options-limit="999",
          select-label="",
          selected-label="",
          deselect-label="",
          track-by="id",
          label="name",
          :multiple="false",
          :loading="!categories.length",
          placeholder="FILTER CATEGORY",
          :value="selectedCategory",
          @select="onSelectCategory"
        )
      //- p(
      //-   v-if="selectedCategory && selectedCategory.id && initialStatus.servicefee_show_subcategories"
      //- )
      //-   label.label Filter by category or subcategory
      //-   multi-select.subcategory-select(
      //-     :options="filteredSubcategoriesWithGroups",
      //-     :searchable="true",
      //-     :options-limit="999",
      //-     select-label="",
      //-     selected-label="",
      //-     deselect-label="",
      //-     selectGroupLabel="",
      //-     deselectGroupLabel="",
      //-     track-by="id",
      //-     label="name",
      //-     :multiple="false",
      //-     :loading="!filteredCategories.length",
      //-     placeholder="FILTER BY CATEGORY",
      //-     :value="selectedSubcategoryOrCatFilter",
      //-     @select="onSelectSubcategory",
      //-     @remove="onRemoveSubcategory"
      //-   )
        //- group-label="name",
        //- group-values="subcategories",
        //- :group-select="true",
      .switch-placeholder(v-if="selectedCategory && selectedCategory.id")
        .control.action.switch-changed-only
          .switch-wrap
            label.lbl Show future fees
            switches(
              v-model="isShowFutureFees",
              theme="bulma",
              color="blue",
              :emitOnMount="false",
              @input="onChangeFutureFees"
            )
      p.action.action-2(
        v-if="selectedCategory && selectedCategory.id && initialStatus.can_copy_service_fees_from_rates"
      )
        button.button.is-generic-app-teal(
          title="Copy from rates",
          @click="onCopyFromRates"
        )
          //- img(src="../../assets/images/comps/Plus.svg")
          span(style="margin-left: 5px") Copy from rates
    .bar.right(v-if="selectedCategory && selectedCategory.id")
      template(v-if="isAddingRow")
        p.action
          button.button.is-generic-app-teal(@click="onSaveRow") Create
        p.action
          button.button.is-generic-app-teal(@click="onCancelNewRow") Cancel
      p.action.action-2
        button.button.is-generic-app-teal(
          :disabled="isAddingRow",
          title="Create new service fee",
          @click="onAddNewRow"
        )
          img(src="../../assets/images/comps/Plus.svg")
          span(style="margin-left: 5px") New Service Fee
      p.action.action-2(v-if="totalInactiveRowsNum && !isAddingRow")
        button.button.is-generic-app-teal(
          title="Clicking this button will make the changes made in blue rows effective.",
          @click="onActivateServiceFees"
        )
          span(style="margin-left: 5px") Activate
      p.action.action-2(v-if="allPendingChangedRows.length && !isAddingRow")
        button.button.is-generic-app-teal(
          title="Clicking this button will make the changes made in yellow rows effective.",
          @click="onApplyChanges"
        )
          span(style="margin-left: 5px") Save changes
  template(v-if="selectedCategory && selectedCategory.id")
    img.loader-small(
      src="../../assets/images/comps/loader.svg",
      v-if="isLoadingFees || isLoadingPatchingFees || isLoadingDeletingRows"
    )
    .finances-table
      client-finances-table(
        :clientData="clientData",
        :is-adding-row="isAddingRow",
        :incrementOptions="incrementOptions",
        :serviceFeeRowsFiltered="serviceFeeRowsFiltered",
        :pendingRows="pendingRows",
        :newRowData="newRowData",
        :categoriesOnly="filteredCategories",
        :dayTypeOptions="dayTypeOptions",
        :filteredSubcategoriesWithGroups="filteredSubcategoriesWithGroups",
        :selectedCategory="selectedCategory",
        @changeRowData="onChangeRowData",
        @undo-row="onUndoRowChange",
        @delete-row="onDeleteFeeRow",
        v-if="!isLoadingFees && !isLoadingPatchingFees"
      )
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
// import _ from 'underscore'
import ClientFinancesTable from "./Finances/ClientFinancesTable.vue";
import MultiSelect from "vue-multiselect";
import { mapActions, mapGetters, mapState } from "vuex";
import { parseErrors } from "../../lib/helpers/function";
import moment from "moment";
// import { parseErrors, parseDateObjWithTimeZone } from '../../../../lib/helpers/function';
export default {
  components: {
    ClientFinancesTable,
    MultiSelect,
  },
  props: {
    categoryId: {
      type: String,
      required: false,
    },
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serviceFeeRows: [],
      originalServiceFeesData: [],
      pendingRows: [],
      incrementOptions: [],
      dayTypeOptions: [],
      selectedCategory: null,
      // selectedSubcategoryOrCatFilter: null,
      newRowData: {},
      isAddingRow: false,
      isShowFutureFees: false,
      isLoadingFees: false,
      isLoadingPatchingFees: false,
      isLoadingDeletingRows: false,
      fixedChangedDate: "3000-01-01 00:00:00",
      numberOfInactiveRowsDb: 0,
    };
  },
  computed: {
    ...mapGetters({
      categories: "getAllCategories",
    }),
    filteredCategories() {
      const currentId = this.selectedCategory && this.selectedCategory.id;
      return this.categories.filter((cat) => cat.id === currentId);
    },
    // filteredSubcategories () {
    //   const currentId = this.selectedCategory && this.selectedCategory.id
    //   if (currentId) {
    //     return this.filteredCategories.filter(cat => cat.id === currentId)
    //   }
    //   return this.filteredCategories
    // },
    filteredSubcategoriesWithGroups() {
      const subCatMapped = this.filteredCategories
        .map((fc) => {
          return [fc, ...fc.subcategories];
        })
        .flat();
      return subCatMapped;
    },
    serviceFeeRowsConcated() {
      return this.serviceFeeRows.concat(this.pendingRows);
    },
    serviceFeeRowsFiltered() {
      const selCat = this.selectedCategory || {};
      const catSubIds =
        selCat.subcategories && selCat.subcategories.map((sub) => sub.id);
      const filteredByCategoryArr = this.serviceFeeRowsConcated.filter((ma) => {
        // Filter by categories and its subcategories
        const isCategory = ma.category_id === selCat.id;
        const isSubcategory =
          catSubIds.includes(ma.category_id) ||
          catSubIds.includes(ma.subcategory_id);
        return isCategory || isSubcategory;
      });

      // Filter by date [Func]
      const isValidDateByFilter = (isFuture = false, dateStr) => {
        return isFuture
          ? moment(dateStr).isAfter()
          : moment(dateStr).isBefore();
      };

      // Filter by Cat/Sub filter
      // const subFilterId = this.selectedSubcategoryOrCatFilter && this.selectedSubcategoryOrCatFilter.id
      // const filterBySpecificCatOrSubCat = filteredByCategoryArr.filter(fc => {
      //   if (subFilterId) {
      //     return fc.category_id === subFilterId || fc.subcategory_id === subFilterId
      //   }
      //   return true
      // })

      return filteredByCategoryArr.filter((fc) => {
        // Filter by date
        const isFilteredValidFrom =
          fc.valid_from &&
          isValidDateByFilter(this.isShowFutureFees, fc.valid_from);
        const isInactiveRow = fc.created_at === this.fixedChangedDate;
        return isFilteredValidFrom || isInactiveRow;
      });
    },
    allPendingChangedRows() {
      // All pending, not just filtered
      return this.serviceFeeRowsConcated.filter((sf) => sf.__isPending);
    },
    allInactiveRows() {
      // All inactive, not just filtered
      return this.serviceFeeRowsConcated.filter(
        (sf) => sf.created_at === this.fixedChangedDate
      );
    },
    totalInactiveRowsNum() {
      return this.numberOfInactiveRowsDb || this.allInactiveRows.length;
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchAllIncrements();
    this.fetchServiceFeeTypes();
    this.fetchDayTypeOptions();
  },
  methods: {
    ...mapActions({
      getServiceFeeCalcItems: "getServiceFeeCalcItems",
      fetchServiceFeeTypes: "locationClientManagement/fetchServiceFeeTypes",
      getRatesIncrementsCategory: "getRatesIncrementsCategory",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      patchServiceFees: "patchServiceFees",
      getIncrements: "getIncrements",
      getServiceFees: "getServiceFees",
      deleteServiceFees: "deleteServiceFees",
      postMergeRatesServiceFees: "postMergeRatesServiceFees",
      postActivateFees: "postActivateFees",
      getCheckInactiveFees: "getCheckInactiveFees",
    }),
    async onCopyFromRates() {
      const label = "copy data from rates?";
      try {
        await this.confirmApplyChanges(label);
        const params = {
          client_id: this.clientData.id,
        };
        const res = await this.postMergeRatesServiceFees(params);
        this.$toasted.success(res.data.message).goAway(2000);
        this.fetchServiceFeesWithFilters();
      } catch (err) {
        console.log("Error", err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(3500);
      }
    },
    onAddNewRow() {
      this.isAddingRow = true;

      // Set initial values
      this.newRowData = {
        // __isPending: true,
        random_id: parseInt(Math.random() * 10000),
        category_id: this.selectedCategory && this.selectedCategory.id,
        old_valid_from: null,
        valid_from: "",
        service_fee_calculation_type_id: 1,
        value: 0,
      };
    },
    onCancelNewRow() {
      this.isAddingRow = false;
      this.newRowData = {};
    },
    async onSaveRow() {
      await this.addRowToDatabase([this.newRowData]);

      // Legacy code
      // Add the object to the other data
      // this.pendingRows.push(this.newRowData);
    },
    async addRowToDatabase(rowDataArr) {
      const params = this.generatePatchingFeeParams(rowDataArr);
      try {
        const res = await this.patchServiceFees(params);
        this.$toasted.success(res.data.message).goAway(2000);

        // Reset new row form and fetch new data
        this.onCancelNewRow();
        this.fetchServiceFeesWithFilters();
      } catch (err) {
        console.log("Error", err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(3500);
      }
    },
    onSelectCategory(evtObj) {
      this.$router.replace({
        name: "ClientFinancesInfoCategory",
        params: {
          categoryId: `${evtObj.id}`,
        },
      });

      this.setCategoryEvent(evtObj);
    },
    setCategoryEvent(evtObj) {
      this.selectedCategory = evtObj;
      // this.selectedSubcategoryOrCatFilter = null;
      this.fetchServiceFeesWithFilters();
      this.fetchAnyInactiveFees();
    },
    // onSelectSubcategory (evtObj) {
    //   console.log('Select Cat/Sub filter', evtObj);
    //   this.selectedSubcategoryOrCatFilter = evtObj
    // },
    // onRemoveSubcategory () {
    //   this.selectedSubcategoryOrCatFilter = null
    // },
    onChangeFutureFees() {
      this.fetchServiceFeesWithFilters();
    },
    onChangeRowData(evt) {
      if (evt.feeRowData.id) {
        // Current rows
        const currentRowIndex = this.serviceFeeRows.findIndex(
          (sf) => sf.id === evt.feeRowData.id
        );
        if (currentRowIndex !== -1) {
          // console.log('Saving current value', currentRowIndex, evt.key, evt.value);
          this.$set(this.serviceFeeRows[currentRowIndex], evt.key, evt.value);
          this.$set(this.serviceFeeRows[currentRowIndex], "__isPending", true);
        } else {
          console.warn("Current row not found");
        }
      } else {
        // Pending rows
        this.$set(evt.feeRowData, evt.key, evt.value);
        this.$set(evt.feeRowData, "__isPending", true);
      }
    },
    onUndoRowChange(evtPayload) {
      // This is when the API data needs to be reverted
      const currentModifiedRowId = evtPayload.row.id;
      const originalData = this.originalServiceFeesData.find(
        (osf) => osf.id === currentModifiedRowId
      );
      if (originalData) {
        const indexCurrentRow = this.serviceFeeRows.findIndex(
          (sf) => sf.id === currentModifiedRowId
        );
        if (indexCurrentRow !== -1) {
          const dataNoRef = JSON.parse(JSON.stringify(originalData));
          this.serviceFeeRows.splice(indexCurrentRow, 1, dataNoRef);
        } else {
          console.log("[SFee] Invalid id", indexCurrentRow, originalData);
        }
      } else {
        console.error("[SFee] Row not found", currentModifiedRowId, evtPayload);
      }
    },
    async onDeleteFeeRow(evtPayloadArr) {
      let isSuccessMsg = "";
      this.isLoadingDeletingRows = true;
      for (const evtPayload of evtPayloadArr) {
        const params = { id: evtPayload.row.id };
        try {
          const res = await this.deleteServiceFees(params);
          isSuccessMsg = res.data.message;
        } catch (err) {
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(3500);
          isSuccessMsg = "";
          break;
        }
      }
      this.isLoadingDeletingRows = false;
      if (isSuccessMsg) {
        this.$toasted.success(isSuccessMsg).goAway(2000);
        this.fetchServiceFeesWithFilters();
      }
    },
    async onApplyChanges() {
      let label = undefined; // Using default parameter for label
      if (this.allPendingChangedRows.length > 0) {
        label = `save these changes? Any changes to the active service fees will become effective momentarily.`;
        // label = `apply changes on ${this.allPendingChangedRows.length} rows`;
      }

      try {
        await this.confirmApplyChanges(label);
        const params = this.generatePatchingFeeParams(
          this.allPendingChangedRows
        );
        this.isLoadingPatchingFees = true;
        const res = await this.patchServiceFees(params);
        console.log(res.data.message);
        this.$toasted
          .success("Service fees successfully updated.")
          .goAway(2000);
        this.serviceFeeRows = [];
        this.originalServiceFeesData = [];
        this.pendingRows = [];
        this.removePendingTagFromCurrentRows();
        this.fetchServiceFeesWithFilters();
        this.isLoadingPatchingFees = false;
      } catch (err) {
        this.isLoadingPatchingFees = false;
        console.log("Error", err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(3500);
        // this.handleAllErrorsToast(errs, "Invalid values set while trying to save fees")
      }
    },
    async onActivateServiceFees() {
      // Refetch inactive rows so we get proper count
      await this.fetchAnyInactiveFees();

      let label = "activate inactive fees?";
      if (this.totalInactiveRowsNum > 1) {
        label = `activate ${this.totalInactiveRowsNum} fees?`;
      }

      try {
        await this.confirmApplyChanges(label);
        this.numberOfInactiveRowsDb = 0;
        const params = { client_id: this.clientData.id };
        this.isLoadingPatchingFees = true;
        const res = await this.postActivateFees(params);
        this.$toasted.success(res.data.message).goAway(2000);
        this.fetchServiceFeesWithFilters();
        this.isLoadingPatchingFees = false;
      } catch (err) {
        this.isLoadingPatchingFees = false;
        console.log("Error", err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(3500);
      }
    },
    confirmApplyChanges(name = `apply changes?`) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${name}`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
    async fetchDayTypeOptions() {
      const params = {};
      try {
        const res = await this.getServiceFeeCalcItems(params);
        this.dayTypeOptions = res.data.data;
      } catch (err) {
        console.log("Error", err.message);
      }
    },
    async fetchAnyInactiveFees() {
      // Load on category change, because we don't keep inactive rows per category
      const params = { client_id: this.clientData.id };
      try {
        const res = await this.getCheckInactiveFees(params);
        this.numberOfInactiveRowsDb = res.data.data;
      } catch (err) {
        console.error(err.message);
      }
    },
    removePendingTagFromCurrentRows() {
      for (const sfRow of this.serviceFeeRows) {
        this.$delete(sfRow, "__isPending");
      }
    },
    generatePatchingFeeParams(serviceFeeRows) {
      return {
        client_id: this.clientData.id,
        servicefees: serviceFeeRows.map((pr) => {
          return {
            id: pr.id,
            subcategory_id: pr.subcategory_id || pr.category_id,
            increment_id: pr.increment_id,
            calcitem_id: pr.calcitem_id,
            service_fee_calculation_type_id: pr.service_fee_calculation_type_id,
            valid: pr.valid_from,
            value: pr.value,
          };
        }),
      };
    },
    handleAllErrorsToast(errors, fallback) {
      // Not used.. yet
      let errorMsg = "Unexpected error.";
      console.log(errors, 222);

      if (errors.errors) {
        const errs = parseErrors(errors.errors);
        console.log(errs, 111);
        if (errs) {
          errorMsg = errs;
        }
        this.errors.record(errors.errors);
      } else if (errors.message) {
        errorMsg = errors.message;
      } else if (fallback) {
        errorMsg = fallback;
      }
      this.$toasted.error(errorMsg).goAway(3000);
    },
    async fetchServiceFeesWithFilters() {
      const params = {
        client_id: this.clientData.id,
        category_id: this.selectedCategory && this.selectedCategory.id,
        // subcategory_id: this.selectedSubcategoryOrCatFilter && this.selectedSubcategoryOrCatFilter.id,
        show_future: this.isShowFutureFees,
        show_inactive: 1, // Hardcoded, may change in future
      };
      this.isLoadingFees = true;
      try {
        const res = await this.getServiceFees(params);
        this.saveOriginalServiceFees(res.data.data);
        this.updateOnlyUnchangedRows(res.data.data);
        this.isLoadingFees = false;
      } catch (err) {
        this.isLoadingFees = false;
        console.log("Error", err.message);
      }
    },
    saveOriginalServiceFees(sfResponseArr) {
      // Removes objects refs when updating
      this.originalServiceFeesData = JSON.parse(JSON.stringify(sfResponseArr));
    },
    updateOnlyUnchangedRows(sfResponseArr) {
      // *** This logic was added because changes are stored on backend
      // Remove rows not in db
      const dbFees = sfResponseArr.map((sfr) => sfr.id);
      this.serviceFeeRows = this.serviceFeeRows.filter((sfr) => {
        const isRowPendingActive =
          sfr.__isPending && sfr.created_at !== this.fixedChangedDate;
        return isRowPendingActive || dbFees.includes(sfr.id);
      });

      // Add / Replace
      const currentPendingRowsId = this.allPendingChangedRows
        .filter((pr) => pr.id)
        .map((pr) => pr.id);
      for (const sfRow of sfResponseArr) {
        if (currentPendingRowsId.includes(sfRow.id)) {
          // Skip pending rows
          continue;
        }
        const sfExistingRowIndex = this.serviceFeeRows.findIndex(
          (sf) => sf.id === sfRow.id
        );
        if (sfExistingRowIndex !== -1) {
          // Update
          this.serviceFeeRows.splice(sfExistingRowIndex, 1, sfRow);
        } else {
          // Append
          this.serviceFeeRows.push(sfRow);
        }
      }
    },
    async fetchCategories() {
      const params = {};
      try {
        await this.fetchAllCategoriesList(params);

        if (Array.isArray(this.categories) && this.categories.length) {
          this.checkSelectRouterCategory();
          await this.fetchAllIncrements();
        }
      } catch (err) {
        console.log("Error", err.message);
      }
    },
    async fetchAllIncrements(cat_ids = []) {
      console.log("CatIDS", cat_ids);

      // Parent categories
      const catIds = this.categories.map((cat) => cat.id);
      const params = { cat_ids: catIds };
      try {
        const res = await this.getRatesIncrementsCategory(params);
        this.incrementOptions = res.data.data;
      } catch (err) {
        console.log("Error", err.message);
      }
    },
    checkSelectRouterCategory() {
      const catId = this.$route.params.categoryId;
      if (catId) {
        const parsedCatId = parseInt(catId, 10);
        const foundCat = this.categories.find((cat) => cat.id === parsedCatId);
        if (foundCat) {
          this.setCategoryEvent(foundCat);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#client-finances-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  width: 100%;
  gap: 15px;
  align-items: center;
  height: 100%;
  overflow: hidden;

  .finances-table {
    display: flex;
    overflow: auto;
    height: 100%;
    align-items: flex-start;
  }

  .top-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 51;

    .bar {
      display: flex;
      align-items: flex-end;
      gap: 10px;
    }

    .category-select,
    .subcategory-select {
      width: auto;
      min-width: 300px;
    }

    .switch-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 10px;
    }
  }
}
</style>
