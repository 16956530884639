<template lang='pug'>
    .confirm-wrap
        .text Are you sure you want to delete user?
        .action
            .button.is-danger.is-caps-lock.is-tiny(@click='confirm') Delete
            .button.is-caps-lock.is-tiny(@click='decline') Cancel
</template>

<script>
import { Evt } from '../../lib/helpers/Evt.js';
import { mapActions } from 'vuex';
export default {
  props: {
    user: {
      type: Object,
      default: () => { return {}; },
    },
  },
  methods: {
    ...mapActions({
      deleteUser: 'deleteUser',
    }),
    async confirm () {
      if (this.user && this.user.id) {
        try {
          await this.deleteUser({ id: this.user.id });
          this.$toasted.info('User deleted.').goAway(1500);
          Evt.fire('confirmDeleteUser');
        } catch (err) {
          this.$toasted.error('There was an error deleting the user.').goAway(1500);
          this.$emit('close');
        }
      }

      this.$emit('close');
    },
    decline () {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-wrap {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  .text {
    margin-bottom: 20px;
    text-align: center;
  }

  .action {
    display: flex;
    justify-content: center;

    > * {
      margin-left: 1rem;
    }
  }
}
</style>
