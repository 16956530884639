<template lang="pug">
.time-warn
  .body
    p.info You are about to create a shift that lasts for more than 24 hours. Do you want to proceed?
    .options
      button.button.is-danger(@click="confirm")
        span Yes
      button.button.is-generic-app-blue(@click="closeModal")
        span No
</template>

<script>
import { Evt } from '../../../lib/helpers/Evt.js';
export default {
  methods: {
    confirm () {
      Evt.fire('confirmShift');
    },
    closeModal () {
      // this.$emit('close-modal')
      this.$emit('cancel-modal');
      Evt.fire('disposeModal');
    },
  },
};
</script>

<style lang='scss' scoped>
.time-warn {
  height: 180px;

  .body {
    padding: 2.5em;
    font-size: 1.2em;

    .info {
      text-align: center;
    }

    .options {
      margin-top: 1em;
      text-align: center;

      .button {
        margin: 5px;
        min-width: 8em;
      }
    }
  }
}
</style>
