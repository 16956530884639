import { render, staticRenderFns } from "./NotificationCentrePage.vue?vue&type=template&id=00f7aaae&scoped=true&lang=pug"
import script from "./NotificationCentrePage.vue?vue&type=script&lang=js"
export * from "./NotificationCentrePage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_2c597f7d109fe0052e634bf327ce7ff6/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f7aaae",
  null
  
)

export default component.exports