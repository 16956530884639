<template lang="pug">
#rates-finalize
  .text Choose a date to apply the rates from:
  flat-pickr.input.column.is-5(
    placeholder="Select Date/Time",
    :config="flatpickrConfig",
    v-model="validFromPicker",
    @input="errors.clear('valid_from')"
  )
  span.help.is-danger.has-text-centered.time-errors(
    v-if="errors.has('valid_from')",
    v-html="errors.get('valid_from')",
    slot="valid_from"
  )
  .action
    button.button.is-generic-app-teal.is-caps-lock.is-tiny(
      @click="finalizeChanges"
    ) Save
    button.button.is-caps-lock.is-tiny(@click="closeForm") Cancel
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { parseErrors } from "../../../../lib/helpers/function";
import { mapActions } from "vuex";
import { Errors } from "../../../../lib/helpers/Errors";
export default {
  components: {
    flatPickr,
  },
  props: {
    uptId: {
      required: true,
    },
    uptCode: {
      required: true,
    },
  },
  data() {
    return {
      validFromPicker: null,
      flatpickrConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i:S",
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        enableSeconds: true,
        time_24hr: true,
      },
      errors: new Errors(),
    };
  },
  methods: {
    ...mapActions({
      postUserPaymentFinalize: "postUserPaymentFinalize",
    }),
    async finalizeChanges() {
      const params = {
        upt_id: this.uptId,
        upt_code: this.uptCode,
        upr_from: this.validFromPicker,
      };
      try {
        const res = await this.postUserPaymentFinalize(params);
        this.$toasted.success(res.data.message).goAway(2500);
        this.$emit("rates-finalized");
      } catch (err) {
        console.log(err.message);
        if (err.response?.data?.errors) {
          this.errors.record(err.response.data.errors);
        }
        let errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    closeForm() {
      this.$emit("cancel-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
#rates-finalize {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;

  .text {
    margin: 20px 0;
  }

  .action {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    > *:first-child {
      margin-right: 10px;
      padding: 0 16px;
    }
  }
}
</style>
<style lang="scss">
#rates-finalize {
  .mx-datepicker,
  input {
    width: 100%;
  }
}
</style>
