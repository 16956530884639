<template lang="pug">
div
  <modal v-if="modalVisible" @close="modalVisible = false" :title="modalTitle" icon="" size="350">
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false",
      @get-documents="getDocs(currentPage)"
    ) 
  </modal>

  edit-popup(
    v-if="popup.show",
    @close="popupClose",
    @editPopup="popup.save",
    :data="popup.data",
    :levels="popup.levels",
    :offset="popup.offset",
    :target="popup.element",
    :isLoading="popup.isLoading",
    ref="popup"
  )
  transition(name="fade-cubic")
    uni-popup(
      v-if="newPopup.calleEl && newPopup.content",
      :callee-el="newPopup.calleEl",
      :offset="newPopup.offset",
      header-label="Edit Mode",
      @close="onClosePopup"
    )
      template(#content)
        location-popup(
          v-if="newPopup.content === 'location'",
          :data="newPopup.data",
          :is-loading="newPopup.isLoading",
          @save="onSavePopup",
          @close="onClosePopup",
          @new-location="onNewLocation",
          @change-compliance-allowance="onChangeAllowance"
        )
        category-popup(
          v-else-if="newPopup.content === 'category'",
          :data="newPopup.data",
          :is-loading="newPopup.isLoading",
          @save="onSavePopup",
          @close="onClosePopup",
          @new-category="onNewCategory"
        )

  section.section
    h1.title.is-3
      i.fa.icon-Compliance-documents
      | Documents
    .block.is-pulled-right.controls
      <transition name="fade">
        span(v-show="add_new")
          button.button.is-generic-app-blue(
            :class="{ 'is-loading': isCreatingDocument }",
            :disabled="isCreatingDocument",
            @click="create",
            data-cell="save"
          ) SAVE
          button.button.is-generic-app-blue(
            @click="add_new = false",
            :disabled="isCreatingDocument",
            data-cell="cancel"
          ) CANCEL
      </transition>
      span
        button.button.is-generic-app-light-blue.new-document(
          @click="add_new = true",
          :disabled="add_new",
          data-cell="new-document"
        )
          img(src="../../assets/images/comps/Plus.svg")
          span NEW DOCUMENT
    table.table.is-striped(@click="info($event.target)")
      thead
        tr
          th Document name
          th Applicable locations
          th Applicable categories
          th Visibility
          //- th  Validation type
          //- th  Limit hours?
          th Has expiry?
          th Automatic expiry?
          //- th  Effects if expired?
          th Deadline for push/email?
          th Is optional?
          th Info link
      tfoot
      tbody
        transition(name="fade")
          tr(v-show="add_new")
            td
              .flexer
                span(@click="add_new = false")
                  img(src="../../assets/images/comps/remove.svg")
                input.input(
                  v-model="newDocumentTemplate.name",
                  placeholder="Type in document name"
                )
            td
              span.generic-app-tag.is-wide-tag.popup-hook(
                @click="showNewPopup($event, 'location', 0)",
                v-tooltip.info.top.bounce="'click to fill in'"
              ) <strong>{{ freshValues.locations }}</strong>
                //- @click="showPopup(['locations'], $event.target)",
                img(src="../../assets/images/comps/Olovka.svg")
            td
              span.generic-app-tag.is-wide-tag.popup-hook(
                v-tooltip.info.top.bounce="'click to fill in'",
                @click="showNewPopup($event, 'category', 0)"
              ) <strong>{{ freshValues.categories }}</strong>
                //- @click="showPopup(['categories'], $event.target)"
                img(src="../../assets/images/comps/Olovka.svg")
            td(data-cell="add-new-visibility")
              span.generic-app-tag.is-wide-tag.popup-hook(
                v-tooltip.info.top.bounce="'click to fill in'",
                @click="showPopup(['roles'], $event.target)"
              ) <strong>{{ freshValues.roles }}</strong>
                img(src="../../assets/images/comps/Olovka.svg")
            //- td(style="min-width: 160px; max-width: 180px")
            //-   multi-select(
            //-       :options='optionsValidationTypes',
            //-       v-model="newDocumentTemplate.validationType",
            //-       :allow-empty="false",
            //-       hide-selected=true,
            //-       selectLabel='',
            //-       track-by="type",
            //-       label="label",
            //-       placeholder="Validation Type"
            //-   )
            //-     span(slot="noResult") Nothing found.
            td
              input(type="checkbox", v-model="newDocumentTemplate.hasExpiry")
            td(v-if="newDocumentTemplate.hasExpiry")
              input.input(
                placeholder="(months)",
                v-model="newDocumentTemplate.expirationLength",
                @keypress="isNumber"
              )
            td(v-else)
              span -
            //- td(v-if="newDocumentTemplate.hasExpiry")
            //-   multi-select(
            //-       :options='optionsExpirationEffects',
            //-       v-model='newDocumentTemplate.expirationEffect',
            //-       :allow-empty="true",
            //-       track-by="type",
            //-       label="label",
            //-       hide-selected=true,
            //-       selectLabel='',
            //-       placeholder="Effects"
            //-   )
            //-     span(slot="noResult") Nothing found.
            //- td(v-else)
            //-   span -
            td(v-if="newDocumentTemplate.hasExpiry")
              input.input(
                placeholder="(days)",
                v-model="newDocumentTemplate.notificationDeadline",
                @keypress="isNumber"
              )
            td(v-else)
              span -
            td
              input(
                type="checkbox",
                :checked="newDocumentTemplate.optional",
                @change="newDocumentTemplate.optional = !newDocumentTemplate.optional"
              )
            td
              input.input(
                placeholder="(infolink)",
                v-model="newDocumentTemplate.infoLink"
              )
        tr(
          v-if="docs && !loading",
          :id="id",
          v-for="{ id, name, locationCount, categoryCount, categories, roles, hasAllowancies, compliance_type, expirationEffect, expirationLength, hasExpiry, notificationDeadline, hoursLimited, optional, infoLink } in docs"
        )
          td(data-cell="name")
            .flexer
              span(@click="remove(id)")
                img(src="../../assets/images/comps/remove.svg")
              span
              | {{ name }}
          td(data-cell="locations")
            span.generic-app-tag.is-wide-tag.popup-hook(
              v-tooltip.info.top.bounce="'locations'",
              v-if="compliance_type !== 'GNIB'",
              @click="showNewPopup($event, 'location', id)"
            ) <strong>{{ locationCount }}</strong>
              //- @click="showPopup(['locations'], $event.target)"
              img(src="../../assets/images/comps/Olovka.svg")
          td(data-cell="categories")
            span.generic-app-tag.is-wide-tag.popup-hook(
              @click="showNewPopup($event, 'category', id)",
              v-if="compliance_type !== 'GNIB'"
            ) <strong>{{ categoryCount }}</strong>
              //- @click="showPopup(['categories'], $event.target)",
              img(src="../../assets/images/comps/Olovka.svg")
          td(data-cell="visibility")
            span.generic-app-tag.is-wide-tag.popup-hook(
              @click="showPopup(['roles'], $event.target)"
            ) <strong>{{ roles.length }}</strong>
              img(src="../../assets/images/comps/Olovka.svg")
          //- td(style="min-width: 160px; max-width: 180px")
            multi-select(
                :options='validationTypes',
                :allow-empty="false",
                hide-selected=true,
                selectLabel='',
                :id="id",
                track-by="type",
                label="label",
                v-model="selectedValidationTypes[id]",
                @select="vTypeSelected",
                placeholder="Validation Type"
            )
              span(slot="noResult") Nothing found.
          //- td {{ hoursLimited? hoursLimited + ' hours' : '-' }}
          td(data-cell="has-expiry") {{ hasExpiry ? "Yes" : "No" }}
          td(data-cell="automatic-expiry")
            span.generic-app-tag.generic-app-tag--expiry {{ expirationLength ? +expirationLength + " months" : "-" }}
          //- td(style="") {{expirationEffectLabel(expirationEffect)}}
          td(data-cell="deadline")
            span.generic-app-tag.generic-app-tag--deadline {{ notificationDeadline ? +notificationDeadline + " days" : "-" }}
          td(data-cell="is-optional")
            input(
              type="checkbox",
              :checked="optional",
              @change="setOptionality(id, optional)"
            )
          td(data-cell="info-link")
            span.clickable(
              @click="showModal('edit-info-link', { docId: id, infoLink: infoLink }, 'Edit Info Link')"
            )
              img(src="../../assets/images/comps/info.svg")
        tr(v-if="loading")
          td.center-text(colspan="12")
            img(src="../../assets/images/comps/loader.svg")
        tr(v-if="!loading && docs && docs.length === 0")
          td.center-text(colspan="12")
            | No data in table.
    pagination(
      v-if="pagination",
      :total-pages="pagination.total_pages",
      :current-page="currentPage",
      @page-changed="pageHasChanged"
    )
</template>
<script>
/* eslint camelcase: "off" */
import _ from "underscore";
import MultiSelect from "vue-multiselect";
import EditInfoLink from "../in-modal/compliances/EditInfoLink.vue";
import LocationPopup from "./LocationPopup.vue";
import CategoryPopup from "./CategoryPopup.vue";
import Pagination from "../Pagination.vue";

import EditPopup from "../EditPopup.vue";

import { mapActions, mapGetters } from "vuex";
// import { VALIDATION_TYPES, EXPIRATION_EFFECT_TYPES } from '../config/compliance.js'

import { parseErrors, flatten } from "../../lib/helpers/function.js";

export default {
  name: "Documents",
  components: {
    EditInfoLink,
    MultiSelect,
    EditPopup,
    Pagination,
    LocationPopup,
    CategoryPopup,
  },
  data() {
    return {
      newPopup: {
        isLoading: true,
        calleEl: null,
        content: "",
        data: {
          docId: 0,
          list: [],
          checkedList: [],
        },
        offset: {
          offsetX: 20,
          offsetY: 55,
        },
      },
      loading: true,
      debugInfo: {},
      isCreatingDocument: false,
      currentPage: 1,
      newDocumentTemplate: {
        id: 0,
        name: "",
        // --------
        locations: [],
        categories: [],
        roles: [],
        // --------
        hoursLimited: 0,
        hasExpiry: false,
        expirationLength: null,
        expirationEffect: {
          label: "No Effect",
          type: 1,
        },
        infoLink: "",
        notificationDeadline: null,
        // validationType: {
        //   label: 'ONLINE',
        //   type: 1
        // },
        optional: false,
      },
      penBoxRequest: {
        locationsIds: [],
        categoriesIds: [],
        rolesIds: [],
      },
      freshValues: {
        locations: 0,
        categories: 0,
        roles: 0,
      },
      popup: {
        active_document_id: null,
        offset: {
          offsetX: 20,
          offsetY: 55,
        },
        element: null,
        show: false,
        data: null,
        levels: [],
        save: (data, totalCount) => {
          return this.refreshPopupState(data, totalCount);
        },
        isLoading: false,
      },
      add_new: false, // add new document row
      // optionsValidationTypes: VALIDATION_TYPES,
      // optionsExpirationEffects: EXPIRATION_EFFECT_TYPES,
      modalVisible: false,
      rowHeight: "",
      // validationTypes: VALIDATION_TYPES,
      // selectedValidationTypes: {},
      filters: {
        name: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getComplianceDocs: "getComplianceDocs",
      clients: "getAllClients",
      categories: "getAllCategories", // job types
      roles: "getAllRoles", // Roles aka Visibility
      pagination: "getPagination",
    }),
    parsedCats() {
      return this.getCategoriesByCategory(this.categories);
    },
    docs() {
      const dcArr = this.getComplianceDocs;
      const tempArr = [];
      for (const dc of dcArr) {
        const transformedObj = {
          ...dc,
          // compliance_type is added above
          id: dc.id,
          name: dc.name,
          categories: dc.categories,
          roles: dc.roles,
          hasAllowancies: null, // PRESET
          expirationEffect: true, // PRESET > Not used?
          expirationLength: dc.expiration_months,
          hasExpiry: dc.expiration_months > 0, // PRESET
          notificationDeadline: dc.notification_days,
          hoursLimited: dc.expiration_months,
          optional: dc.optional,
          infoLink: dc.info_link,
        };
        tempArr.push(transformedObj);
      }
      return tempArr;
    },
  },
  mounted() {
    this.currentPage = parseInt(this.$route.query.page, 10) || 1;
    this.getDocs(this.currentPage);

    const clientIncludes = ["locations"].join();
    this.fetchClientsList({
      includes: clientIncludes,
      per_page: 999,
    }).then(() => {
      this.newDocumentTemplate.locations = this.getLocationsByClients(
        this.clients
      );
    });

    this.fetchAllCategoriesList().then(() => {
      this.newDocumentTemplate.categories = this.parsedCats;
    });

    this.fetchAllRolesList().then(() => {
      this.newDocumentTemplate.roles = this.getRoles(this.roles);
    });
  },
  methods: {
    ...mapActions([
      "fetchCompliances",
      "editComplianceOptional",
      "createDocument",
      "patchDocument",
      "fetchClientsList",
      "fetchAllCategoriesList",
      "fetchAllRolesList",
      "removeDocument",
      "fetchDocumentLocations",
    ]),
    async getDocs(pageNum = 1) {
      this.loading = true;
      try {
        const params = {
          includes: ["locations", "categories", "roles"].join(","),
          page: pageNum,
          optimized: true,
        };
        await this.fetchCompliances(params);
        // this.setValidationTypesDrop
        this.loading = false;
      } catch (err) {
        console.log("ERR", err.message);
        this.loading = false;
      }
    },
    isNumber: function (evt) {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    forceRefreshSamePage() {
      this.pageHasChanged(this.currentPage);
    },
    pageHasChanged(pageNum) {
      this.loading = true;
      this.fetchCompliances({
        includes: ["locations", "categories", "roles"].join(","),
        page: pageNum || 1,
        optimized: true,
      }).then(() => {
        // this.setValidationTypesDrop(data)
        this.loading = false;
        this.currentPage = this.pagination?.current_page || this.currentPage;
      });
    },

    setOptionality(id, val) {
      val = !val;
      this.patchDocument({
        docId: id,
        optional: val,
      });

      this.editComplianceOptional({
        id,
        val,
      });
    },
    // setValidationTypesDrop ({ data: { data } }) {
    //   _.each(data, ({ id, validationType }) => {
    //     /**
    //      * Construct our multi-model selectedValidationTypes
    //      * using custom literal object, so that it can be used by vue-multiselect
    //      *   {label: vTypeLabel(api), type: validationType(api)}
    //      * id - obtained from api
    //      * validationType - obtained from api
    //      */
    //     this.$set(this.selectedValidationTypes, id, {
    //       label: _.findWhere(VALIDATION_TYPES, { type: validationType }).label,
    //       type: validationType
    //     })
    //     this.loading = false
    //   })
    //   this.loading = false
    // },
    /**
     * Reset all fields after creating a new document
     */
    resetCreateNewFields() {
      this.newDocumentTemplate = Object.assign({}, this.newDocumentTemplate, {
        id: 0,
        name: "",
        locations: this.getLocationsByClients(this.clients),
        categories: this.parsedCats,
        roles: this.getRoles(this.roles),
        hoursLimited: 0,
        hasExpiry: false,
        expirationLength: null,
        expirationEffect: { label: "No Effect", type: 1 },
        infoLink: "",
        notificationDeadline: null,
        // validationType: { label: 'ONLINE', type: 1 },
        optional: false,
      });
      this.freshValues = Object.assign({}, this.freshValues, {
        locations: 0,
        categories: 0,
        roles: 0,
      });
    },

    async create() {
      this.isCreatingDocument = true;
      if (!this.newDocumentTemplate.hasExpiry) {
        this.newDocumentTemplate.notificationDeadline = null;
        this.newDocumentTemplate.expirationLength = null;
        this.newDocumentTemplate.expirationEffect.type = null;
      }

      try {
        const response = await this.createDocument({
          name: this.newDocumentTemplate.name,
          // validation_type: this.newDocumentTemplate.validationType.type,
          has_expiry: this.newDocumentTemplate.hasExpiry,
          expiration_effect: this.newDocumentTemplate.expirationEffect.type,
          notification_deadline: this.newDocumentTemplate.notificationDeadline,
          expiration_length: this.newDocumentTemplate.expirationLength,
          locations: this.penBoxRequest.locationsIds.map((loc) => ({
            id: loc.id,
            has_allowance: Boolean(loc.hasComplianceAllowance),
          })),
          categories: this.penBoxRequest.categoriesIds,
          roles: this.penBoxRequest.rolesIds,
          info_link: this.newDocumentTemplate.infoLink,
          optional: this.newDocumentTemplate.optional,
        });

        this.getDocs(this.currentPage);
        this.isCreatingDocument = false;
        this.add_new = false;
        this.resetCreateNewFields();
        this.$toasted
          .info(response.data.message, { theme: "outline" })
          .goAway(1500);
      } catch (err) {
        this.isCreatingDocument = false;
        this.handleErrors(err);
      }
    },
    async remove(id) {
      if (confirm("Are you sure you want to delete a document!?")) {
        try {
          const response = await this.removeDocument({ id });
          this.$toasted
            .info(response.data.message, { theme: "outline" })
            .goAway(1500);
        } catch (err) {
          this.handleErrors(err);
        }
      }
    },
    // vTypeSelected (selectedOption, id) {
    //   this.patchDocument({
    //     docId: id,
    //     validation_type: selectedOption.type
    //   }).then(() => {
    //     this.$toasted.info('Validation type updated!', { theme: 'outline' }).goAway(1500)
    //   })
    // },
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    // expirationEffectLabel: function (type) {
    //   if (_.isNull(type)) {
    //     type = 1
    //   }
    //   return _.findWhere(EXPIRATION_EFFECT_TYPES, { type: type }).label
    // },

    popupClose() {
      this.popup.show = false;
    },
    updateTotalCount(prop, count) {
      this.freshValues[prop] = count;
    },

    refreshPopupState({ data, entity }, totalCount) {
      console.log("ENTITY :: ", entity[0]);
      if (entity[0] === "roles") {
        const ids = data[0].roles
          .filter((role) => {
            if (role.checked === true) return role.id;
          })
          .map((role) => role.id);

        /**
         * Prepare for api
         */
        const roles = flatten(ids).map((id) => ({ id: id }));

        if (this.popup.active_document_id !== 0) {
          this.popup.isLoading = true;
          this.patchDocument({
            docId: this.popup.active_document_id,
            roles: roles,
          })
            .then(() => {
              this.$toasted.info("Roles Updated!").goAway(1500);
              this.popup.isLoading = false;
              this.popupClose();
            })
            .catch((error) => {
              this.handleErrors(error);
              this.popup.isLoading = false;
            });
        } else {
          // new doc
          this.newDocumentTemplate.roles = data[0].roles;
          this.updateTotalCount("roles", totalCount);
          /**
           * Keep roles ids, for post request!
           */
          this.penBoxRequest.rolesIds = roles;
          this.popupClose();
        }

        return; // safety measure
      } else if (entity[0] === "categories") {
        console.warn(data);

        const ids = data.map((category) => {
          return category.categories
            .filter((subcat) => {
              if (subcat.checked === true) {
                return subcat.id;
              }
            })
            .map((subcat) => subcat.id);
        });

        const categories = flatten(ids).map((id) => ({ id: id }));

        if (this.popup.active_document_id !== 0) {
          this.popup.isLoading = true;
          this.patchDocument({
            docId: this.popup.active_document_id,
            categories,
          })
            .then(() => {
              this.popup.isLoading = false;
              this.popupClose();
              this.$toasted
                .info("Categories updated successfully!")
                .goAway(1500);
              this.forceRefreshSamePage();
            })
            .catch((error) => {
              this.handleErrors(error);
              this.popup.isLoading = false;
            });
        } else {
          /**
           * NEW DOCUMENT
           */
          this.newDocumentTemplate.categories = data;
          this.updateTotalCount("categories", totalCount);
          this.penBoxRequest.categoriesIds = categories;
          this.popupClose();
        }

        return; // safety measure
      } else if (entity[0] === "locations") {
        // TODO send allowances alongside locations ids
        // take cc ids from data where checked property is true...
        const ids = data.map((client) => {
          return client.locations
            .filter((cc) => {
              if (cc.checked === true) {
                return cc.id;
              }
            })
            .map((cc) => ({
              id: cc.id,
              hasAllowance: cc.hasComplianceAllowance,
            }));
        });
        const centres = flatten(ids).map((o) => ({
          id: o.id,
          has_allowance: o.hasAllowance,
        }));
        /**
         * If we are not creating NEW DOCUMENT, patch resource.
         */
        if (this.popup.active_document_id !== 0) {
          this.popup.isLoading = true;
          this.patchDocument({
            docId: this.popup.active_document_id,
            locations: centres,
          })
            .then(() => {
              this.popup.isLoading = false;
              this.$toasted.info("Wards Updated!").goAway(1500);
              this.forceRefreshSamePage();
              this.popupClose();
            })
            .catch((error) => {
              this.handleErrors(error);
              this.popup.isLoading = false;
            });
        } else {
          /**
           * NEW DOCUMENT
           */
          console.log(data);
          this.newDocumentTemplate.locations = data;
          this.updateTotalCount("locations", totalCount);
          this.penBoxRequest.locationsIds = centres;
          this.popupClose();
        }
        return; // safety measure
      }
    },

    /**
     * Set status `checked` for ward that have checked state for this document
     * @param docId Document id for which we collect data into 'cc'
     */
    async waitForDataArrToLoad(variableToCheckPaths, cancelTokenFuncCheck) {
      // Generic reusable func
      function deepFind(obj, path) {
        const paths = path.split(".");
        let current = obj;

        for (let i = 0; i < paths.length; ++i) {
          if (current[paths[i]] == undefined) {
            return undefined;
          } else {
            current = current[paths[i]];
          }
        }
        return current;
      }

      return new Promise((resolve, reject) => {
        const recheckFunc = () => {
          const tVal = setTimeout(() => {
            const dataToLoadArr = deepFind(this, variableToCheckPaths);
            if (
              !dataToLoadArr ||
              (dataToLoadArr && dataToLoadArr.length === 0)
            ) {
              if (cancelTokenFuncCheck && cancelTokenFuncCheck()) {
                clearTimeout(tVal);
                reject();
              } else {
                console.log("Recheck data");
                recheckFunc();
              }
            } else {
              resolve();
            }
          }, 1000);
        };
        recheckFunc();
      });
    },
    getLocationsByClients(clients, ids = []) {
      if (!clients || !Array.isArray(clients)) {
        console.warn("clients >> ", clients);
        return clients;
      }
      console.log("param ids[]: ", ids);

      const noRefClients = JSON.parse(JSON.stringify(clients));
      _.each(noRefClients, (_client) =>
        _client.locations.map((cc) => {
          cc.checked = false;
          cc.hasComplianceAllowance = false;
          _.each(ids, function (o) {
            if (o.id === cc.id) {
              cc.checked = true;
              cc.hasComplianceAllowance = o.hasComplianceAllowance;
            }
          });
        })
      );
      return noRefClients;
    },
    getCategoriesByCategory(categories = [], checkedIds = []) {
      const newCats = JSON.parse(JSON.stringify(categories));
      newCats.forEach((_category) => {
        // Copy-rename
        _category.categories = _category.subcategories;

        _category.categories.map((subcat) => {
          subcat.checked = _.contains(checkedIds, subcat.id);
          if (subcat.checked) {
            // console.log('subcat id :: ', subcat.id)
          }
        });
      });

      return newCats;
    },
    /**
     * When ids param not passed, all values will be deselected
     */
    getRoles(roles, ids = []) {
      const _roles = JSON.parse(JSON.stringify(roles));
      _.each(_roles, (_role) => {
        _role.checked = _.contains(ids, _role.id);
      });
      return _roles;
    },
    showNewPopup(evt, contentName, docId = 0) {
      this.popupClose();
      const elHook = evt.target.closest(".popup-hook");

      let api_checked_ids = [];

      if (contentName === "location") {
        if (docId !== 0) {
          this.newPopup.isLoading = true;
          this.$nextTick(async () => {
            const response = await this.fetchDocumentLocations({ id: docId });
            const doc = _.findWhere(this.docs, { id: docId });
            doc.locations = response.data;
            // Find checked wards for this document id
            api_checked_ids = this.findCheckedItemsImproved("locations", docId);
            console.log("LOC > API CHECKED IDS :: ", api_checked_ids);
            // finds matches for wards that have selected state set to true
            await this.waitForDataArrToLoad("clients");
            const clientLocations = await this.getLocationsByClients(
              this.clients,
              api_checked_ids
            );
            this.newPopup.data.checkedList = api_checked_ids;
            this.newPopup.data.list = clientLocations;
            this.newPopup.isLoading = false;
          });
        } else {
          this.newPopup.isLoading = true;
          this.$nextTick(async () => {
            await this.waitForDataArrToLoad("newDocumentTemplate.locations");
            this.newPopup.data.checkedList = this.penBoxRequest.locationsIds;
            this.newPopup.data.list = this.newDocumentTemplate.locations;
            this.newPopup.isLoading = false;
          });
        }
      } else if (contentName === "category") {
        this.newPopup.isLoading = true;

        if (docId !== 0) {
          api_checked_ids = this.findCheckedItems("categories", docId);
          console.log("CAT > API CHECKED IDS :: ", api_checked_ids);

          this.newPopup.data.checkedList = api_checked_ids.map((id) => ({
            id,
          }));
          this.newPopup.data.list = this.getCategoriesByCategory(
            this.categories,
            api_checked_ids
          );
        } else {
          console.log(this.newDocumentTemplate.categories);
          this.newPopup.data.checkedList = this.penBoxRequest.categoriesIds;
          this.newPopup.data.list = this.newDocumentTemplate.categories;
        }
        this.newPopup.isLoading = false;
      } else {
        console.warn("Not implemented");
      }

      this.newPopup.data.docId = docId;
      this.newPopup.calleEl = elHook ? elHook : evt.target;
      this.newPopup.content = contentName;
    },
    async showPopup(levels, e) {
      this.onClosePopup();

      // find the row we are acting upon...
      const docIdStr = e.closest("tr").getAttribute("id");
      let doc_id = 0;
      if (docIdStr) {
        doc_id = Number.parseInt(docIdStr);
      }
      let api_checked_ids = [];

      /**
       * First PENBOX, locations within clients logic
       */
      if (_.indexOf(levels, "locations") >= 0) {
        /**
         * If not NEW DOCUMENT
         */
        if (doc_id !== 0) {
          this.popup.isLoading = true;
          this.$nextTick(async () => {
            const response = await this.fetchDocumentLocations({ id: doc_id });
            const doc = _.findWhere(this.docs, { id: doc_id });
            doc.locations = response.data;
            // Find checked wards for this document id
            api_checked_ids = this.findCheckedItemsImproved(
              "locations",
              doc_id
            );
            // finds matches for wards that have selected state set to true
            await this.waitForDataArrToLoad("clients");
            const clientLocations = await this.getLocationsByClients(
              this.clients,
              api_checked_ids
            );
            this.$set(this.popup, "data", clientLocations);
            this.popup.isLoading = false;
          });
        } else {
          this.popup.isLoading = true;
          this.$nextTick(async () => {
            await this.waitForDataArrToLoad("newDocumentTemplate.locations");
            this.popup.data = this.newDocumentTemplate.locations;
            this.popup.isLoading = false;
          });
        }
      } else if (_.indexOf(levels, "categories") >= 0) {
        if (doc_id !== 0) {
          api_checked_ids = this.findCheckedItems("categories", doc_id);
          console.log("API CHECKED IDS :: ", api_checked_ids);

          this.popup.data = this.getCategoriesByCategory(
            this.categories,
            api_checked_ids
          );
        } else {
          console.log(this.newDocumentTemplate.categories);
          this.popup.data = this.newDocumentTemplate.categories;
        }
      } else if (_.indexOf(levels, "roles") >= 0) {
        /**
         * third penbox, roles
         */
        if (doc_id !== 0) {
          // console.log('not new row')
          /**
           * collect ids for checked states from api
           */
          api_checked_ids = this.findCheckedItems("roles", doc_id);

          this.popup.data = [
            {
              id: 0,
              name: "Roles",
              roles: this.getRoles(this.roles, api_checked_ids),
            },
          ];
        } else {
          // console.log('new row')
          this.popup.data = [
            {
              id: 0,
              name: "Roles",
              roles: this.newDocumentTemplate.roles,
            },
          ];
        }
      }

      this.popup.levels = levels;
      this.popup.active_document_id = doc_id;
      this.popup.show = true;
      this.popup.element = e;
    },

    findCheckedItemsImproved(type, id) {
      try {
        const res = _.findWhere(this.docs, { id: id })[type].map((t) => ({
          id: t.id,
          hasComplianceAllowance: t.hasComplianceAllowance,
        }));
        return res;
      } catch (err) {
        console.warn("ERR", err.message, type, id);
      }
      return null;
    },
    findCheckedItems(type, id) {
      return _.findWhere(this.docs, { id: id })[type].map((t) => t.id);
    },

    // eslint-disable-next-line no-unused-vars
    info(e) {
      // this.debugInfo = e
    },
    handleErrors(error) {
      const errs = parseErrors(error);
      if (Array.isArray(errs)) {
        for (let i = 0; i < errs.length; i++) {
          this.$toasted.error(errs[i]).goAway(3000);
        }
      } else {
        this.$toasted.error(errs).goAway(3000);
      }
    },
    onClosePopup() {
      this.newPopup.data = {
        docId: 0,
        list: [],
        checkedList: [],
      };
      this.newPopup.content = "";
      this.newPopup.calleEl = null;
      this.newPopup.isLoading = true;
    },
    onSavePopup() {
      // this.refreshPopupState(data, totalCount);
      // Refresh data
      this.forceRefreshSamePage();

      this.onClosePopup();
    },
    onNewLocation(evt) {
      const { data, totalCount, centres } = evt;
      this.newDocumentTemplate.locations = data;
      this.updateTotalCount("locations", totalCount);
      this.penBoxRequest.locationsIds = centres;
      this.onClosePopup();
    },
    onNewCategory(evt) {
      const { totalCount, categories } = evt;
      // this.newDocumentTemplate.categories = data;
      this.updateTotalCount("categories", totalCount);
      this.penBoxRequest.categoriesIds = categories;
      this.onClosePopup();
    },
    onChangeAllowance(evt) {
      this.newPopup.data.list = this.recursiveSetChecked(evt.list, evt);
    },
    recursiveSetChecked(arr = [], evt) {
      const { id, val: hasComplianceAllowance } = evt;
      return arr.reduce((acc, curr) => {
        if (curr) {
          const payload = {
            ...curr,
          };
          if (curr.locations) {
            const locations = this.recursiveSetChecked(curr.locations, evt);
            payload.locations = locations;
          }
          if (curr.id === id && "hasComplianceAllowance" in curr) {
            if (hasComplianceAllowance) {
              payload.checked = true;
            }
            payload.hasComplianceAllowance = hasComplianceAllowance;
          }
          acc.push(payload);
        }
        return acc;
      }, []);
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}

.fade-cubic-enter-active {
  transition: all 0.1s ease;
}

.fade-cubic-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-cubic-enter,
.fade-cubic-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.controls {
  .button {
    margin-left: 0.5em;
  }
}

td {
  vertical-align: middle;

  .flexer {
    display: flex;
    align-items: center;

    span:first-child {
      align-self: center;
      display: flex;
      flex: 0 0 15px;
      align-items: center;
      margin-right: 1em;
      cursor: pointer;
    }
  }
}

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.generic-app-tag.generic-app-tag--deadline {
  background-color: #d2ecf5;
}

.generic-app-tag.generic-app-tag--expiry {
  background-color: #ffcc6a;
}

.new-document {
  img {
    margin-right: 15px;
  }
}
</style>
<style lang="scss">
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.center-text {
  text-align: center;
  height: 150px;
  background: #f0f6f6;
  img {
    width: 50px;
  }
}
</style>
