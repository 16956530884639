<template lang="pug">
section#security-section.section
  h1.title.is-3
    i.fa.icon-Security
    | Settings
  MultiSelect(
    ref="settingsPageChanger",
    :options="tabs",
    track-by="label",
    label="label",
    :value="selectedPage",
    :allow-empty="false",
    select-label="",
    deselect-label="",
    :max-height="400",
    @select="onSelectPage"
  )
  router-view
</template>

<script>
import { mapState } from "vuex";
import MultiSelect from "vue-multiselect";
export default {
  name: "SecuritySection",
  components: {
    MultiSelect,
  },
  computed: {
    ...mapState({
      initialStatus: (state) => state.initialStatus,
    }),
    selectedPage() {
      return this.tabs.find((tab) =>
        this.$route.matched.some((match) => match.name === tab.routeName)
      );
    },
    tabs() {
      const tabs = [
        {
          label: "Admin deactivation",
          routeName: "SecurityAdminDeactivationPage",
        },
        {
          label: "Contact Titles",
          routeName: "SecurityContactPage",
        },
        {
          label: "Candidate Tags",
          routeName: "SecurityTagsPage",
        },
        {
          label: "Journal Subjects",
          routeName: "SecurityJournalPage",
        },
        {
          label: "Email from list",
          routeName: "NotificationEmailFromList",
        },
        {
          label: "Weekly Increment Counter",
          routeName: "IncrementWeeksSettings",
          systemPermissions: ["can_use_increment_counter"],
        },
        {
          label: "Holiday Request",
          routeName: "HolidayRequestSettings",
          systemPermissions: ["can_use_holiday_requests"],
        },
        {
          label: "Security",
          routeName: "PermissionSettingsPage",
          // systemPermissions: ["can_use_permissions"],
        },
        {
          label: "Shift Activity Tags",
          routeName: "ShiftActivityTags",
          systemPermissions: ["can_use_shift_activity_tags"],
          permission: "manage-security"

        },
        {
          label: "General receipts",
          routeName: "GeneralReceiptPage",
          systemPermissions: ["can_use_expenses"],
          permission: "manage-general-receipts",
        },
      ];

      return tabs.filter((tab) => {
        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
  },
  methods: {
    onSelectPage(evt) {
      this.$router.push({
        name: evt.routeName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#security-section {
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem 0;

  .title {
    display: flex;

    .right-text {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .status-btn {
      margin-left: 20px;
    }
  }

  .multiselect {
    width: 300px;
    z-index: 2; // This is used because some tables have headers zi50
  }
}
</style>
