<template lang="pug">
.error-list
  table.table
    thead
      tr(v-if="progressData.total")
        //- th(colspan="1") Shild upload result: {{ progressData.completed }} / {{ progressData.total }}
        th(colspan="100") 
          .flex-wrap(style="display: flex; gap: 10px")
            .completed
              span Completed:&nbsp;
              span(style="color: green") {{ progressData.completed }}
            .errors
              span Errors:&nbsp;
              span(style="color: darkred") {{ progressData.error }}
            .total
              span Total:&nbsp;
              span {{ progressData.total }}
      tr
        th(v-for="(column, index) in filteredColumns", :key="index")
          span {{ column.label }}
        th.status-msg(:class="{ 'has-error': hasAnyErrorMessage }") 
          span Status
    tbody
      tr(v-for="(item, index) in list", :key="index")
        td(
          v-for="(formattedCellText, cellIndex) in getTableDataForDisplay(item)",
          :key="cellIndex"
        )
          span {{ formattedCellText }}
        td.status-msg(:class="{ 'has-error': hasAnyErrorMessage }")
          template(v-if="item.bud_status === 3 && !item.jct_errors")
            i.fa.fa-times.error
          template(v-else-if="item.bud_status === 3 && item.jct_errors")
            span.error {{ item.jct_errors }}
          template(v-else)
            i.fa.fa-check.success
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    progressData: {
      type: Object,
      default: () => ({}),
    },
    shiftType: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasAnyErrorMessage () {
      return this.list.some((item) => item.jct_errors);
    },
    allColumns () {
      return [
        { label: "Agency Worker Name", key: "bud_tempname", type: "timesheet" },
        {
          label: "External System ID",
          key: "bud_temptssid",
          type: "timesheet",
        },
        { label: "Client Name", key: "bud_cliname", type: "all" },
        { label: "Ward", key: "bud_locname", type: "all" },
        { label: "Gender", key: "bud_gender", type: "open-shifts" },
        { label: "Subcategory", key: "bud_catname", type: "all" },
        { label: "Date of shift", key: "bud_startdate", type: "all" },
        {
          label: "Start time of shift",
          key: "bud_starttime",
          format: "time",
          type: "all",
        },
        {
          label: "End time of shift",
          key: "bud_endtime",
          format: "time",
          type: "all",
        },
        {
          label: "Number of shifts",
          key: "bud_numberofshifts",
          type: "open-shifts",
        },
        { label: "Special shift", key: "bud_specialshift", type: "all" },
        { label: "PO Number", key: "bud_ponumber", type: "all" },
      ];
    },
    filteredColumns () {
      if (this.shiftType === "timesheet") {
        return this.allColumns.filter(
          (ac) => ac.type === "all" || ac.type === "timesheet"
        );
      } else if (this.shiftType === "open-shifts") {
        return this.allColumns.filter(
          (ac) => ac.type === "all" || ac.type === "open-shifts"
        );
      }
      return [];
    },
  },
  methods: {
    formatTime (str) {
      return str && str.slice(0, 5);
    },
    getTableDataForDisplay (tableRowObj) {
      const keyCol = this.filteredColumns.map((fc) => fc.key);
      const keysFormatTime = this.filteredColumns.filter(fc => fc.format === 'time');
      const availableRowKeysArr = Object.entries(tableRowObj)
        .filter(([tKey]) => {
          return keyCol.includes(tKey);
        })
        .sort(([aKey,], [bKey,]) => {
          // Needs to sort according to the columns
          const aIndex = keyCol.indexOf(aKey);
          const bIndex = keyCol.indexOf(bKey);
          if (aIndex < bIndex) {
            return -1;
          } else if (aIndex > bIndex) {
            return 1;
          }
          return 0;
        })
        .map(([tKey, tVal]) => {
          // Format data and format for display
          if (keysFormatTime.includes(tKey)) {
            return this.formatTime(tVal);
          }
          return tVal;
        });

      return availableRowKeysArr;
    },
  },
};
</script>

<style lang="scss" scoped>
.error-list {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  min-height: 400px;
  height: 100%;

  .table {
    border-top: unset;
  }

  thead {
    tr:first-child {
      th {
        top: 0px;
        height: 36.2px; // So it goes behind other row
      }
    }

    tr:not(:first-child) {
      th {
        top: 36px;
        border-bottom: 1px solid #dde6eb;
      }
    }
  }

  th {
    border-top: 1px solid #dde6eb;
    border-bottom: unset;
    position: sticky;
    z-index: 50;

    &.status-msg.has-error {
      min-width: 420px;
      max-width: 600px;
    }
  }

  td {
    &.status-msg {
      &.has-error {
        max-width: 600px;
        word-break: break-word;
      }

      .error {
        color: darkred;
      }

      .success {
        color: green;
      }
    }
  }
}
</style>
