export const STANDARD_SHIFT_ID = 1;
export const ON_CALL_SHIFT_ID = 2;
export const SLEEPOVER_SHIFT_ID = 3;

export const shiftTypes = [
    {
        id: STANDARD_SHIFT_ID,
        code: 'NRML',
        name: 'Standard',
    },
    {
        id: ON_CALL_SHIFT_ID,
        code: 'ONCL',
        name: 'Standby / On Call',
    },
    {
        id: SLEEPOVER_SHIFT_ID,
        code: 'SLPO',
        name: 'Sleepover',
    },
];