<template lang="pug">
.wrapper
  .msg
    span Client created. Please make sure the service fee is set before any shifts are created.
  .button-wrap
    button.button.is-generic-app-blue(@click="redirectAndEmitModalClose")
      span SET SERVICE FEES
</template>

<script>
export default {
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    redirectAndEmitModalClose() {
      this.$router.push({
        name: "ClientFinancesInfo",
        params: { id: this.clientData.id },
      });
      this.$emit("modal-close");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 1.5em;
  .msg {
    padding: 15px;
    text-align: center;
  }
  .button-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
    button {
      font-size: 0.6em;
    }
  }
}
</style>
