<template lang='pug'>
div
  .inputs
    .field.p-right10
      label.label Start time
      p.control
        date-picker(:date="dt.from", :option="dateTimeOption")
    .field.p-left10
      label.label End time
      p.control
        date-picker(
          :isDisabled="dt.to.time === null",
          :date="dt.to",
          :option="customDateTimeOption"
        )
      p.control
      // button.button.is-tiny.is-danger.is-outlined(
      //   @click="clearEndTime"
      // ) Escort
  span.help.is-danger(
    v-if="errors.has('end_time')",
    v-html="errors.get('end_time')"
  )
  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      :disabled="isSaveLoading",
      @click.prevent="saveTimes"
    ) SUBMIT
    button.button.is-outlined.is-caps-lock(@click.prevent="callModalClose") CANCEL
</template>
<script>
import calendarOptions from '../common/calendarOptions.js';
import { getFormattedTime, parseDateObjWithTimeZone } from '../../lib/helpers/function.js';
// import moment from 'moment'

import { Errors } from '../../lib/helpers/Errors.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['modalProps', 'isSaveLoading'],
  data () {
    return {
      dateTimeOption: null,
      MultiShift: null,

      shiftId: null,
      dt: {
        from: { time: '' },
        to: { time: '' },
      },
      errors: new Errors(),
    };
  },
  created () {
    this.setupDateTimePickerOpt();
  },
  mounted () {
    this.dt.from = { time: this.modalStartTime };
    this.dt.to = { time: this.modalEndTime };

    this.shiftId = this.modalProps.shiftId;
  },
  methods: {
    ...mapActions([
      'patchShift'
    ]),

    setupDateTimePickerOpt () {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = 'min';
      this.dateTimeOption.format = 'DD/MM/YYYY HH:mm';
    },
    revertCalendarMode (mode) {
      this.dateTimeOption.type = mode;
      this.dateTimeOption.format = 'DD/MM/YYYY';
    },
    saveTimes () {
      this.$emit('is-save-loading', true);
      /**
       * DEBUG
       */
      // console.log(`PATCH: /shifts/${this.shiftId} route...
      //     changing times... to... ${this.dt.from.time} and ${this.dt.to.time}`)
      /**
       * API change shift times
       * PATCH
       * shiftId - id for a shift to be changed
       * params - object that contains new start_time and end_time values
       */
      // Do this, if needed to avoid sending end_time parameter
      // let endTime = this.serverEndTime ? { end_time: this.serverEndTime } : null

      this.patchShift({
        id: this.shiftId,
        data: {
          start_time: this.serverStartTime,
          end_time: this.serverEndTime,
        },
      })
        .then(() => {
          /**
           * change like this, or propagate an event?
           * update state for the affected shift object ASK QUESTION
           */
          this.$emit('is-save-loading', false);
          this.revertCalendarMode('day'); // find the right place for this stmt.
          this.callModalClose();
          this.$toasted.info(
            'Time changed.',
            { theme: 'outline' }
          ).goAway(1500);
        })
        .catch(error => {
          this.$emit('is-save-loading', false);
          let errorsObject = error.response.data;
          if (errorsObject.errors) {
            this.errors.record(errorsObject.errors);
          } else {
            // for cases when toaster is used, do like this!
            this.$toasted.error(errorsObject.message).goAway(10000);
          }
        });
    },
    clearEndTime () {
      this.dt.to.time = null;
    },
    callModalClose () {
      this.revertCalendarMode('day');
      this.$emit('dispose-modal');
    },
  },
  computed: {
    ...mapGetters([
      'getShift'
    ]),
    customDateTimeOption () {
      return Object.assign({}, this.dateTimeOption, { placeholder: 'ESCORT?' });
    },
    modalStartTime () {
      // returns datepicker time format from server times
      const date = parseDateObjWithTimeZone(this.modalProps.startDateTime);
      return getFormattedTime(date, 'DD/MM/YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss');
    },
    modalEndTime () {
      // returns datepicker time format from server times
      const date = parseDateObjWithTimeZone(this.modalProps.endDateTime);
      return getFormattedTime(date, 'DD/MM/YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss');
    },
    serverStartTime () {
      // const date = parseDateObjWithTimeZone(this.modalProps.endDateTime)
      return getFormattedTime(
        this.dt.from.time, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm');
    },
    serverEndTime () {
      // returns null or valid date
      // null needed for the ability to send ESCORT in patch
      // const date = parseDateObjWithTimeZone(this.modalProps.endDateTime)
      return getFormattedTime(
        this.dt.to.time, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm');
    },
  },
};
</script>
<style scoped lang="scss">
.inputs {
  display: flex;
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

.p-left10 {
  padding-left: 10px;
}

.p-right10 {
  padding-right: 10px;
}
</style>
