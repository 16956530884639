<template lang="pug">
.dialog-container
  .inputs
    .field
      label.label Please Slect A Reason For Rejecting
      p.control
        multi-select(
          :options="rejectionReasons",
          :close-on-select="true",
          :searchable="true",
          :options-limit="4",
          :loading="isLoading",
          track-by="id",
          label="name",
          v-model="selectedRejectionReason",
          placeholder="REJECTION REASON"
        )
          span(slot="noResult") Nothing found.
        span.help.is-danger(
          v-if="errors.has('document_rejection_reason_id')",
          v-html="errors.get('document_rejection_reason_id')"
        )
  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      type="submit",
      @click.prevent="reject"
    ) Submit
    button.button.is-small.is-outlined.is-caps-lock(
      @click.prevent="callModalClose"
    ) Cancel
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { Errors } from '../../../lib/helpers/Errors.js';

import MultiSelect from 'vue-multiselect';

export default {

  props: ['modalProps'],

  data () {
    return {
      isLoading: false,
      errors: new Errors(),
      selectedRejectionReason: null,
    };
  },
  computed: {
    ...mapGetters({
      rejectionReasons: 'getRejectionReasons',
    }),
  },
  methods: {
    ...mapActions(['rejectTempDocument']),
    callModalClose () {
      this.$emit('cancel-modal');
    },
    reject () {
      /**
       * For this method to be reusable it must accept one more param
       * saying from what type of documents in state should the document be rejected!
       * ...called from documents awaiting approval, has to be called from temp's documents as well!
       */
      this.rejectTempDocument({
        tempId: this.modalProps.tempId,
        documentId: this.modalProps.documentId,
        documentRejectionReasonId: this.selectedRejectionReason && this.selectedRejectionReason.id,
        modifiers: this.modalProps.modifiers,
      })
        .then(() => {
          this.$toasted.info('Success!', { theme: 'outline' }).goAway(1500);
          this.callModalClose();
        })
        .catch(error => this.errors.record(error.response.data.errors));
    },
  },
  components: { MultiSelect },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
}

.inputs {
  display: flex;
  justify-content: center;

  .field {
    width: 100%;
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}
</style>
