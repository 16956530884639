<template lang="pug">
div
  table.table
    thead
      th
        span INVOICE ID
      th
        span DATE
      th
        span TOTAL AMOUNT
      th
        span CREATED AT
      th.is-narrow(v-if="$can('invoice-download-single-backup')")
        span REQUEST INVOICE
    tbody(v-if="!loading")
      tr(v-for="(data, index) in filteredList")
        td(data-cell="number")
          span # {{ data.number }}
        td(data-cell="name")
          span {{ data.name }}
        td(data-cell="amount")
          span {{ data.totalAmount }}
        td(data-cell="createdAt")
          span {{ data.createdAt }}
        td.is-narrow(
          data-cell="download",
          v-if="$can('invoice-download-single-backup')"
        )
          button.button.is-tiny.is-generic-app-blue(
            :title="getLastDownloadDate(data)",
            :class="{ 'is-loading': data.id === downloadingItemId }",
            :disabled="downloadingItemId",
            @click="onClickDownload(data, index)"
          ) DOWNLOAD
      tr.empty(v-if="filteredList.length === 0")
        td(colspan="100", style="text-align: center") No data in table
    tbody(v-if="loading")
      tr
        td.center-text(colspan="10")
          img(src="@/assets/images/comps/loader.svg")
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import moment from "moment";
import axios from "axios";
import { parseErrors } from "@/lib/helpers/function";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      downloadingItemId: null,
    };
  },
  computed: {
    filteredList() {
      return this.list;
    },
  },
  methods: {
    async onClickDownload(item) {
      this.downloadingItemId = item.id;
      const apiUrl = `/api/invoices/holiday-request/${item.id}/backup.csv`;
      const a = document.createElement("a");
      document.body.appendChild(a);
      try {
        const response = await axios.get(apiUrl, {
          responseType: "arraybuffer",
          params: {
            timestamp: new Date().getTime(),
          },
        });
        let blob = new Blob([response.data], { type: "application/csv" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `backup.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.downloadingItemId = null;
      } catch (err) {
        console.error(err.message);
        this.downloadingItemId = null;
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
      document.body.removeChild(a);
    },
    getLastDownloadDate(item) {
      const dateObj = item.lastDownload;
      if (!dateObj) {
        return "";
        // return "Not downloaded yet";
      }
      return (
        "Last downloaded at:\n" +
        moment(dateObj.date).format("DD/MM/YY HH:mm:ss")
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.columns {
  padding-left: 10px;
}

td.is-narrow {
  width: 120px !important;
}

th.is-narrow {
  width: 120px !important;
}

$color--dark-gray: #7c91ae;

th {
  span {
    margin-right: 5px;
    color: $color--dark-gray;
  }
}

td {
  button {
    span {
      position: relative;
      left: 15px;
    }
  }
}
</style>
