<template lang="pug">
.cc-pref-container-dialog
  template(v-if="isLoading")
    .loading-wrapper
      img(src="../../../assets/images/comps/loader.svg")
    .buttons-centered(v-if="isCancelPresent")
      button.button.is-generic-app-blue.is-caps-lock(
        @click="$emit('cancel-modal')"
      ) Cancel
  location-pref(v-bind="$props", v-on="$listeners", v-if="!isLoading")
</template>

<script>
import LocationPref from "./LocationPref.vue";
export default {
  name: "LocationPrefDialog",
  components: {
    LocationPref,
  },
  props: {
    initialSectors: {
      type: Array,
      default: () => [],
    },
    manageAction: Boolean,
    modalProps: Object,
  },
  computed: {
    isCancelPresent() {
      return this.modalProps.isCancelPresent || false;
    },
    isLoading() {
      return !this.initialSectors.some(
        (sec) => Array.isArray(sec.clients) && sec.clients.length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.cc-pref-container-dialog {
  width: 100%;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.loading-wrapper {
  text-align: center;
  margin: 20px 0;
}
</style>
<style lang="scss">
.all-candidates {
  .panel-block {
    overflow: auto;
  }
}
</style>
