<template lang="pug">
div
  table.table.is-striped(@click="catchChange")
    thead
      tr
        th.check
          .around
            label.ctrl.ctrl--checkbox
              input#select_all.ctrl--checkbox(
                type="checkbox",
                :checked="allTempsSelected"
              )
              span Select All
              .ctrl__status
        th.name(width="15%")
          abbr(title="Name") Name
        th.sub
          abbr(title="Categories") Subcategories
        th.spec
          abbr(title="Specialities") Specialities
        th.preferred(v-if="$can('view-client-assigned-report')")
          abbr(title="Preferred county") Preferred county
      tr(v-show="(temps && temps.length) || prefTempName || !loaded")
        th
        th
          input.input.mt(
            type="text",
            :value="prefTempName",
            placeholder="Type to search...",
            @keydown="onInputSearchTemp",
            @input="asyncFindTemp"
          )
        th
        th
        th
        //- th
    tfoot
    tbody
      tr(v-if="temps && loaded", v-for="temp in temps")
        td
          .around
            label.ctrl.ctrl--checkbox
              input.ctrl--checkbox(
                :id="temp.id",
                type="checkbox",
                :value="{ id: temp.id }",
                :checked="isWorkerChecked(temp.id)",
                @change="tempChanged(temp.id, $event)"
              )
              //- :value="{ id: temp.id }",
              .ctrl__status
        td {{ temp.name }}
        td {{ temp.subcategories | commaSeparated }}
        td {{ temp.specialities | commaSeparated }}
        td(v-if="$can('view-client-assigned-report')") {{ temp.preferredRegions | commaSeparated }}
      tr(v-if="!temps")
        td.has-text-centered(colspan="100") Results will be available after you press the above SEARCH button.
      tr(v-if="!loaded")
        td.center-text(colspan="100")
          img(src="../../assets/images/comps/loader.svg")
</template>
<script>
// import { diffArrayOfObjects } from '../../lib/helpers/function.js'
import _ from "underscore";
export default {
  props: ["temps", "prefTempName", "selectedTemps", "actualPage", "loaded"],

  data() {
    return {
      allTempsSelected: false,
      // selectedTemps: [],
      // checkedOrDecheked: null
    };
  },
  watch: {
    loaded(val, oldVal) {
      if (oldVal === false && val === true) {
        // Reset table data after reload
        this.resetTable();
        this.allTempsSelected = false;
      }
    },
    actualPage() {
      this.allTempsSelected = false;
    },
  },
  methods: {
    catchChange(e) {
      if (e.srcElement.type === "checkbox") {
        if (e.srcElement.id === "select_all") {
          console.log("SELECT ALL");
          this.toggleSelectAllInPage();
        } else {
          console.log("CHILD SELECT");
        }
      }
    },
    onInputSearchTemp(evt) {
      if (!this.loaded) {
        evt.preventDefault();
      }
    },
    asyncFindTemp: _.debounce(function (query) {
      const value = query.target.value;
      // console.log('search val:', value);
      this.$emit("search", value);
    }, 400),
    isWorkerChecked(id) {
      return this.selectedTemps.some((temp) => temp.id === id);
    },
    tempChanged(id, evt) {
      // const isChecked = evt.target.checked
      const temps = [...this.selectedTemps];
      const isChkAlreadyIndex = temps.findIndex((temp) => temp.id === id);
      if (isChkAlreadyIndex !== -1) {
        temps.splice(isChkAlreadyIndex, 1);
      } else {
        temps.push({ id });
      }

      // let a = temps.map(t => t.id)
      // let b = this.temps.map(t => t.id)
      // console.log(a, b)
      // console.warn('intersection: ', _.intersection(a, b))
      // console.log('checkedAll/decheckedAll', _.intersection(this.temps.map(t => t.id), temps.map(t => t.id)).length === this.temps.length)
      this.$emit("tempSelected", temps);
      // this.checkedOrDecheked = _.intersection(this.temps.map(t => t.id), this.selectedTemps.map(t => t.id)).length === this.temps.length
      // console.warn('temp id changed: ', id, evt.target.checked)
    },

    toggleSelectAllInPage() {
      this.allTempsSelected = !this.allTempsSelected;
      const hasTemps = Array.isArray(this.temps) && this.temps.length;
      if (hasTemps) {
        let checkedTemps = [...this.selectedTemps];
        for (const temp of this.temps) {
          const tempCheckedIndex = checkedTemps.findIndex(
            (checkTemp) => checkTemp.id === temp.id
          );

          if (this.allTempsSelected) {
            if (tempCheckedIndex !== -1) {
              continue;
            } else {
              // Check all (that are not checked already)
              checkedTemps.push({ id: temp.id });
            }
          } else {
            if (tempCheckedIndex !== -1) {
              // Uncheck all (that are checked already)
              checkedTemps.splice(tempCheckedIndex, 1);
            } else {
              continue;
            }
          }
        }
        this.$emit("tempSelected", checkedTemps);
      }
    },
    resetTable() {
      // this.selectedTemps = []
      // this.allTempsSelected = []
      this.$emit("reset-workers");
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  border: 1px solid #dde6eb;
  border-collapse: separate;
}

table th {
  background-color: #f2f7fa;
}

table thead th {
  &.check {
    width: 150px;
  }
  &.name {
    width: 250px;
  }
  &.spec {
    width: 25%;
  }
  &.preferred {
    width: 200px;
  }
}

table td {
  font-weight: 500;
  color: #405168;
  vertical-align: middle;
  border-bottom: 1px solid #dde6eb;
}

em {
  color: #405168;
}
</style>
