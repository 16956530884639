<template>
  <label :class="classObject" class="tri-state-switch--bold" @click="trigger">
    <span class="tri-state-switch__label" v-if="shouldShowLabel">
      <span v-if="label" v-text="label"></span>
      <span v-if="!label && value" v-text="textEnabled"></span>
      <span v-if="!label && !value" v-text="textDisabled"></span>
    </span>
    <div></div>
  </label>
</template>

<script>
export default {
  name: "new-tri-state-switch",
  props: {
    value: {
      default: false,
    },
    disabled: {
      default: false,
    },
    label: {
      default: "",
    },
    textEnabled: {
      default: "",
    },
    textDisabled: {
      default: "",
    },
    color: {
      default: "default",
    },
    emitOnMount: {
      default: false,
    },
  },
  mounted () {
    if (this.emitOnMount) {
      this.$emit("input", this.value);
    }
  },
  methods: {
    trigger () {
      var valueToSet;
      if (this.value === "mix") {
        valueToSet = false;
      } else {
        valueToSet = !this.value;
      }

      this.$emit("input", valueToSet);
    },
  },
  computed: {
    classObject () {
      const { color, value, disabled } = this;
      var themeKey = "tri-state-switch-theme--bulma";
      var colorKey = "tri-state-switch-color--" + color;

      var classObj = {
        "tri-state-switch": true,
        "tri-state-switch--disabled": disabled,
        "tri-state-switch--unchecked": typeof value !== "string" && !value,
        "tri-state-switch--mixed": typeof value === "string",
      };

      classObj[themeKey] = color;
      classObj[colorKey] = color;
      return classObj;
    },
    shouldShowLabel () {
      return (
        this.label !== "" || this.textEnabled !== "" || this.textDisabled !== ""
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$color-bulma-default: #f5f5f5;
$color-bulma-primary: #00d1b2;
$color-bulma-blue: #3273dc;
$color-bulma-red: #ff3860;
$color-bulma-yellow: #ffdd57;
$color-bulma-green: #22c65b;
$color-bulma-generic-app-unchecked: #3f5273;
$color-bulma-accent: #30bb26;
$color-bulma-generic-app-mixed: #9fa3aa;
$color-bulma-generic-app-knobcolor: #ffffff;
$theme-bulma-colors: (
  default: $color-bulma-default,
  primary: $color-bulma-primary,
  blue: $color-bulma-blue,
  red: $color-bulma-red,
  yellow: $color-bulma-yellow,
  green: $color-bulma-green,
  accent: $color-bulma-accent,
);
.tri-state-switch {
  position: relative;
  display: inline-block;
  &__label {
    display: block;
    font-size: 10px;
    margin-bottom: 5px;
  }
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
  div {
    height: 10px;
    width: 40px;
    position: relative;
    border-radius: 30px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: all ease 0.4s;
    &:after {
      content: "";
      height: 18px;
      width: 18px;
      border-radius: 100px;
      display: block;
      transition: all ease 0.3s;
      position: absolute;
      left: 100%;
      margin-left: -17px;
      cursor: pointer;
      top: -4px;
    }
  }
  &--unchecked {
    div {
      justify-content: flex-end;
      &:after {
        left: 26px;
      }
    }
  }
  &--disabled {
    div {
      opacity: 0.3;
    }
    input {
      cursor: not-allowed;
    }
  }
  &--mixed {
    div {
      justify-content: flex-end;
      &:after {
        left: 38px;
      }
    }
  }
  &--bold {
    div {
      margin-top: 6px;
      height: 26px;
      width: 51px;
      &:after {
        margin-left: -22px;
        top: 4px;
      }
    }
    &--unchecked {
      div {
        &:after {
          left: 26px;
        }
      }
    }
    &--mixed {
      div {
        justify-content: flex-end;
        &:after {
          left: 38px;
        }
      }
    }
    .tri-state-switch__label {
      span {
        padding-bottom: 7px;
        display: inline-block;
      }
    }
  }
  &-theme--bulma {
    @each $colorName, $color in $theme-bulma-colors {
      &.tri-state-switch-color--#{""+$colorName} {
        div {
          @if $colorName == "default" {
            background-color: darken($color, 10%);
          } @else if $colorName == "accent" {
            background-color: $color-bulma-accent;
          } @else {
            background-color: lighten($color, 10%);
          }
          &:after {
            @if $colorName == "accent" {
              background-color: $color-bulma-generic-app-knobcolor;
            } @else {
              background-color: $color;
            }
          }
        }
        &.tri-state-switch--unchecked {
          div {
            @if $colorName == "default" or $colorName == "yellow" {
              background-color: darken($color, 30%);
            } @else if $colorName == "accent" {
              background-color: $color-bulma-generic-app-unchecked;
            } @else {
              background-color: lighten($color, 30%);
            }
            &:after {
              @if $colorName == "default" {
                background-color: $color;
              } @else if $colorName == "accent" {
                background-color: $color-bulma-generic-app-knobcolor;
              } @else {
                background-color: lighten($color, 10%);
              }
            }
          }
        }
        &.tri-state-switch--mixed {
          div {
            @if $colorName == "default" or $colorName == "yellow" {
              background-color: darken($color, 50%);
            } @else if $colorName == "accent" {
              background-color: $color-bulma-generic-app-mixed;
            } @else {
              background-color: lighten($color, 50%);
            }
            &:after {
              @if $colorName == "default" {
                background-color: $color;
              } @else if $colorName == "accent" {
                background-color: $color-bulma-generic-app-knobcolor;
              } @else {
                background-color: lighten($color, 30%);
              }
            }
          }
        }
      }
    }
  }
}
</style>
