<template lang="pug">
#paybill-holiday-balances.section
  .columns(data-attr="filters")
    .field.right
      button.button.is-generic-app-teal(
        data-action,
        :class="{ 'is-loading': isLoadingCreatingInvoice }",
        v-if="$can('create-holiday-requests-billing')",
        @click="onCreateInvoice"
      )
        img(
          src="../../assets/images/comps/Plus.svg",
          v-if="!isLoadingCreatingInvoice"
        )
        span CREATE INVOICE
  PayBillHolidayTable(:list="holidayInvoices", :loading="isLoadingTable")
  pagination(
    v-if="pagination",
    :total-pages="pagination.total_pages",
    :current-page="pagination.current_page",
    @page-changed="pageHasChanged"
  )
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import PayBillHolidayTable from "@/components/PayBill/Holiday/PayBillHolidayTable.vue";
import Pagination from "../Pagination.vue";
import { mapActions } from "vuex";
import { parseErrors } from "../../lib/helpers/function";

export default {
  components: {
    PayBillHolidayTable,
    Pagination,
  },
  data() {
    return {
      holidayInvoices: [],
      isLoadingTable: true,
      isLoadingCreatingInvoice: false,
      pagination: {
        total: 0,
        total_pages: 0,
        current_page: parseInt(this.$route.query?.page, 10) || 1,
      },
    };
  },
  mounted() {
    this.fetchPayBillHolidayReq({});
  },
  methods: {
    ...mapActions({
      getPayBillHolidayReq: "getPayBillHolidayReq",
      postPayBillHolidayReq: "postPayBillHolidayReq",
    }),
    async fetchPayBillHolidayReq({ pageNum = 1 }) {
      const params = {
        page: pageNum,
      };
      try {
        const res = await this.getPayBillHolidayReq(params);
        this.holidayInvoices = res.data.data;
        if (res.data.meta?.pagination) {
          this.pagination = res.data.meta.pagination;
        }
        this.isLoadingTable = false;
      } catch (err) {
        console.warn(err.message);
        this.isLoadingTable = false;
      }
    },
    async onCreateInvoice() {
      const message =
        "create holiday requests invoice? This will invoice all the pending requests submitted by Monday noon this week.";

      try {
        await this.confirmApplyChanges(message);
        this.isLoadingCreatingInvoice = true;
        try {
          const params = {};
          const res = await this.postPayBillHolidayReq(params);
          console.warn(res.data);
          this.isLoadingCreatingInvoice = false;
          this.fetchPayBillHolidayReq({
            pageNum: this.pagination.current_page,
          });
        } catch (err) {
          console.error(err.message);
          this.isLoadingCreatingInvoice = false;
          const errs = parseErrors(err);
          if (errs) {
            this.$toasted.error(errs).goAway(1500);
          }
        }
      } catch (err) {
        // ignored
      }
    },
    pageHasChanged(pageNum) {
      this.fetchPayBillHolidayReq({ pageNum });
    },
    confirmApplyChanges(name = `apply changes?`) {
      return new Promise((resolve) => {
        let alert = {
          title: `Are you sure you want to ${name}`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#paybill-holiday-balances {
  .columns {
    padding: 10px 10px 0;
  }

  .right {
    margin-left: auto;
  }

  button {
    display: flex;
    gap: 10px;
  }

  .disabled {
    opacity: 0.5;
  }

  .field {
    display: flex;
    margin-bottom: 30px;
    align-items: center;

    label {
      margin: 0 1em;
    }
  }

  .label {
    color: #7c91ae;
  }
}
</style>
