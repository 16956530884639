<template lang="pug">
div
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="'Warning'",
    :size="500"
  )
    time-warning
  section.section
    h1.title.is-3
      i.fa.icon-Submit-shift
      | Book shifts
    BookShiftTabs
</template>
<script>
import BookShiftTabs from './BookShiftTabs.vue';
import TimeWarning from './in-modal/bookshifts/TimeWarning.vue';
import { Evt } from '../lib/helpers/Evt.js';
export default {
  name: 'submitShift',
  components: {
    BookShiftTabs,
    TimeWarning,
  },
  data () {
    return {
      modalVisible: false,
    };
  },
  mounted () {
    this.$store.commit('SET_ACTIVE_TAB', {
      name: 'Single Shifts',
      status: null,
    });
    Evt.listen('openModal', () => {
      this.modalVisible = true;
    });
    Evt.listen('disposeModal', () => {
      console.error('called outdated bus');
      this.modalVisible = false;
    });
  },
  beforeDestroy () {
    Evt.off('openModal');
    Evt.off('disposeModal');
  },
};
</script>
