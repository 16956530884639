<template lang="pug">
.manage-regions-modal
  .header
    span Manage regions, client, locations, categories, and subcategories
  .content
    .left-side
      span Regions | Clients | Locations
      .loading-wrap(v-if="isLoadingSectors")
        .loading-wrapper
          img.client-loading(src="@/assets/images/comps/loader.svg")
      .chlist(v-if="!isLoadingSectors")
        checkbox-list-menu(
          :list="transformedListRegions",
          :checked-list="checkedItemIdsRegions",
          :expanded-items="expandedItemsRegions",
          :disabled-items="disabledItemsRegions",
          :is-solo-check-valid="true",
          :is-disabled-list="isSavingRegions",
          :useWholeCheckedList="true",
          @click="onClickedItemRegions",
          @checked="onCheckedItemRegions"
        )
        .buttons-centered
          button.button.is-small.is-generic-app-blue.is-caps-lock(
            :class="{ 'is-loading': isSavingRegions }",
            :disabled="isLoadingSectors || isSavingRegions",
            v-if="canEditPermPerEntity",
            @click.prevent="onClickSaveRegions"
          ) Save
    .right-side
      span Categories | Subcategories
      .loading-wrap(v-if="isLoadingCategories")
        .loading-wrapper
          img.client-loading(src="@/assets/images/comps/loader.svg")
      .chlist(v-if="!isLoadingCategories")
        checkbox-list-menu(
          :list="transformedListSubcategories",
          :checked-list="checkedItemIdsCategories",
          :expanded-items="expandedItemsCategories",
          :is-solo-check-valid="true",
          :is-disabled-list="isSavingCategories",
          :useWholeCheckedList="true",
          @click="onClickedItemCategories",
          @checked="onCheckedItemCategories"
        )
        .buttons-centered
          button.button.is-small.is-generic-app-blue.is-caps-lock(
            :class="{ 'is-loading': isSavingCategories }",
            :disabled="isLoadingCategories || isSavingCategories",
            v-if="canEditPermPerEntity",
            @click.prevent="onClickSaveCategories"
          ) Save
  footer
    button.button.is-generic-app-blue.is-outlined.is-low(@click="onClickClose") Close
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import { mapActions } from "vuex";
import { parseErrors } from "../../lib/helpers/function";
export default {
  props: {
    modalProps: {
      type: Object,
    },
  },
  data() {
    return {
      user: {},
      isLoadingSectors: false,
      isLoadingCategories: false,
      isSavingRegions: false,
      isSavingCategories: false,
      checkedItemIdsRegions: [],
      checkedItemIdsCategories: [],
      expandedItemsRegions: [],
      expandedItemsCategories: [],
      disabledItemsRegions: [],
      wholeSectorsData: [],
      wholeCategoriesData: [],
    };
  },
  computed: {
    canEditPermPerEntity() {
      return true;
    },
    transformedListRegions() {
      // const searchLower = this.search.trim().toLowerCase();

      const recFilter = (
        arr = [],
        includeParentNode = true,
        hideEmptyPar = false
      ) => {
        return arr.reduce((acc, item) => {
          if (includeParentNode) {
            // if (item.name.toLowerCase().includes(searchLower)) {
            acc.push({
              ...item,
              children: item.children,
            });
            // }
          } else if (item.children?.length) {
            const filteredSubs = recFilter(item.children);
            if (
              filteredSubs.length ||
              (!hideEmptyPar && !filteredSubs.length)
            ) {
              const newCat = {
                ...item,
                children: filteredSubs,
              };
              acc.push(newCat);
            }
          }
          return acc;
        }, []);
      };

      return recFilter(this.wholeSectorsData, true, true);
    },
    transformedListSubcategories() {
      // const searchLower = this.search.trim().toLowerCase();

      const recFilter = (
        arr = [],
        includeParentNode = true,
        hideEmptyPar = false
      ) => {
        return arr.reduce((acc, item) => {
          if (includeParentNode) {
            // if (item.name.toLowerCase().includes(searchLower)) {
            acc.push({
              ...item,
              children: item.children,
            });
            // }
          } else if (item.children?.length) {
            const filteredSubs = recFilter(item.children);
            if (
              filteredSubs.length ||
              (!hideEmptyPar && !filteredSubs.length)
            ) {
              const newCat = {
                ...item,
                children: filteredSubs,
              };
              acc.push(newCat);
            }
          }
          return acc;
        }, []);
      };

      return recFilter(this.wholeCategoriesData, true, true);
    },
  },
  mounted() {
    this.user = this.modalProps.user || {};

    this.fetchSectorsLists();
    this.fetchCategoriesLists();
  },
  methods: {
    ...mapActions({
      getWholeAdminRegions: "getWholeAdminRegions",
      getWholeAdminCategories: "getWholeAdminCategories",
      getUserSecCliLocPerm: "getUserSecCliLocPerm",
      getUserCatPerm: "getUserCatPerm",
      postUserSecCliLocPerm: "postUserSecCliLocPerm",
      postUserCatPerm: "postUserCatPerm",
    }),
    onClickedItemRegions(evt, payload) {
      if (evt.children?.length) {
        const itemIndex = this.expandedItemsRegions.findIndex(
          (eItem) => evt.id === eItem
        );
        if (itemIndex !== -1) {
          this.expandedItemsRegions.splice(itemIndex, 1);
        } else {
          this.expandedItemsRegions.push(evt.id);
        }
      } else {
        // QoL toggle
        this.onCheckedItemRegions({
          item: evt,
        });
      }
    },
    onCheckedItemRegions(evt, payload) {
      const isItemChecked =
        this.checkedItemIdsRegions.findIndex(
          (cItem) => cItem === evt.item.id
        ) !== -1;

      let childrenIds = [];
      const children = evt.item.children;
      if (children?.length) {
        childrenIds = this.getAllIdsRec(children);
      }
      const isAllChildrensChecked = childrenIds.every((childId) =>
        this.checkedItemIdsRegions.includes(childId)
      );

      const checkItemAndChildren = () => {
        const selSet = new Set(this.checkedItemIdsRegions);
        selSet.add(evt.item.id);

        for (const id of childrenIds) {
          selSet.add(id);
        }
        this.checkedItemIdsRegions = Array.from(selSet);
      };

      const uncheckItemAndChildren = () => {
        this.checkedItemIdsRegions = this.checkedItemIdsRegions.filter(
          (cItem) => cItem !== evt.item.id && !childrenIds.includes(cItem)
        );
      };

      if (evt.checked) {
        checkItemAndChildren();
      } else if (evt.checked === false) {
        uncheckItemAndChildren();
      } else if (!isItemChecked) {
        if (children?.length && !isAllChildrensChecked) {
          checkItemAndChildren();
        } else if (!children?.length) {
          checkItemAndChildren();
        }
      } else {
        uncheckItemAndChildren();
      }
    },
    onClickedItemCategories(evt, payload) {
      if (evt.children?.length) {
        const itemIndex = this.expandedItemsCategories.findIndex(
          (eItem) => evt.id === eItem
        );
        if (itemIndex !== -1) {
          this.expandedItemsCategories.splice(itemIndex, 1);
        } else {
          this.expandedItemsCategories.push(evt.id);
        }
      } else {
        // QoL toggle
        this.onCheckedItemCategories({
          item: evt,
        });
      }
    },
    onCheckedItemCategories(evt, payload) {
      const isItemChecked =
        this.checkedItemIdsCategories.findIndex(
          (cItem) => cItem === evt.item.id
        ) !== -1;

      let childrenIds = [];
      const children = evt.item.children;
      if (children?.length) {
        childrenIds = this.getAllIdsRec(children);
      }
      const isAllChildrensChecked = childrenIds.every((childId) =>
        this.checkedItemIdsCategories.includes(childId)
      );

      const checkItemAndChildren = () => {
        const selSet = new Set(this.checkedItemIdsCategories);
        selSet.add(evt.item.id);

        for (const id of childrenIds) {
          selSet.add(id);
        }
        this.checkedItemIdsCategories = Array.from(selSet);
      };

      const uncheckItemAndChildren = () => {
        this.checkedItemIdsCategories = this.checkedItemIdsCategories.filter(
          (cItem) => cItem !== evt.item.id && !childrenIds.includes(cItem)
        );
      };

      if (evt.checked) {
        checkItemAndChildren();
      } else if (evt.checked === false) {
        uncheckItemAndChildren();
      } else if (!isItemChecked) {
        if (children?.length && !isAllChildrensChecked) {
          checkItemAndChildren();
        } else if (!children?.length) {
          checkItemAndChildren();
        }
      } else {
        uncheckItemAndChildren();
      }
    },
    getAllIdsRec(arr = []) {
      return arr.reduce((acc, curr) => {
        acc.push(curr.id);
        if (curr.children?.length) {
          const ids = this.getAllIdsRec(curr.children);
          acc.push(...ids);
        }
        return acc;
      }, []);
    },
    async onClickSaveRegions() {
      try {
        await this.confirmActionAlert("save region entities");
        const props = {};
        try {
          this.isSavingRegions = true;
          const keys = {
            sec: "sectors",
            cli: "clients",
            loc: "locations",
          };
          const params = {
            ...this.formatOutputParams(keys, this.checkedItemIdsRegions),
            usr_id: this.user.id,
          };
          const res = await this.postUserSecCliLocPerm(params);
          this.$toasted.success(res.data.message || "Success").goAway(2500);
          this.isSavingRegions = false;
          this.$emit("save-modal-reg", props);
        } catch (err) {
          console.warn(err.message);
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(2500);
          this.isSavingRegions = false;
        }
      } catch (err) {
        // ignored
      }
    },
    async onClickSaveCategories() {
      try {
        await this.confirmActionAlert("save category entities");
        const props = {};
        try {
          this.isSavingCategories = true;
          const keys = {
            cat: "categories",
            sub: "subcategories",
          };
          const params = {
            ...this.formatOutputParams(keys, this.checkedItemIdsCategories),
            usr_id: this.user.id,
          };
          const res = await this.postUserCatPerm(params);
          this.$toasted.success(res.data.message || "Success").goAway(2500);
          this.isSavingCategories = false;
          this.$emit("save-modal-cat", props);
        } catch (err) {
          console.warn(err.message);
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(2500);
          this.isSavingCategories = false;
        }
      } catch (err) {
        // ignored
      }
    },
    formatOutputParams(keys, arrayId) {
      const tempObj = {};

      // In future, use keys to trim excess part, instead of parseInt
      for (const [key, val] of Object.entries(keys)) {
        tempObj[val] = arrayId.reduce((arr, curr) => {
          // ID has to be a string!
          if (curr.includes(key)) {
            arr.push({ id: parseInt(curr, 10) });
          }
          return arr;
        }, []);
      }
      return tempObj;
    },
    onClickClose() {
      this.$emit("cancel-modal");
    },
    async fetchSectorsLists() {
      this.isLoadingSectors = true;
      await this.fetchAdminSectors();
      await this.fetchUserSectors();
      this.isLoadingSectors = false;
    },
    async fetchCategoriesLists() {
      this.isLoadingCategories = true;
      await this.fetchAdminCategories();
      await this.fetchUserCategories();
      this.isLoadingCategories = false;
    },
    async fetchUserSectors() {
      const params = {
        usr_id: this.user.id,
      };
      try {
        const res = await this.getUserSecCliLocPerm(params);
        const keys = {
          clients: "cli",
          sectors: "sec",
          locations: "loc",
        };
        this.checkedItemIdsRegions = this.formatValuesForLocal(
          res.data.data,
          keys
        );
      } catch (err) {
        console.warn(err.message);
      }
    },
    async fetchUserCategories() {
      const params = {
        usr_id: this.user.id,
      };
      try {
        const res = await this.getUserCatPerm(params);
        const keys = {
          categories: "cat",
          subcategories: "sub",
        };
        this.checkedItemIdsCategories = this.formatValuesForLocal(
          res.data.data,
          keys
        );
      } catch (err) {
        console.warn(err.message);
      }
    },
    formatValuesForLocal(apiRes, keys) {
      const tempArr = [];
      for (const [key, val] of Object.entries(keys)) {
        tempArr.push(...apiRes[key].map((el) => `${el.id}-${val}`));
      }
      return tempArr;
    },
    async fetchAdminSectors() {
      const params = {
        include: ["withArchived"].join(),
      };
      try {
        const res = await this.getWholeAdminRegions(params);
        const keys = [
          {
            name: "clients-optimized",
            short: "sec",
          },
          {
            name: "locations-optimized",
            short: "cli",
          },
          {
            name: "",
            short: "loc",
          },
        ];

        this.wholeSectorsData = this.formatWholeDataRec(keys, res.data.data);
        // this.disabledItemsRegions = this.getDisabledItems(
        //   this.wholeSectorsData
        // );
      } catch (err) {
        console.warn(err.message);
      }
    },
    // getDisabledItems(array) {
    //   // For disabling archived wards (locations)
    //   // TODO [4] Maybe in future
    //   return array;
    // },
    async fetchAdminCategories() {
      const params = {};
      try {
        const res = await this.getWholeAdminCategories(params);
        const keys = [
          {
            name: "subcategories",
            short: "cat",
          },
          {
            name: "",
            short: "sub",
          },
        ];
        this.wholeCategoriesData = this.formatWholeDataRec(keys, res.data.data);
      } catch (err) {
        console.warn(err.message);
      }
    },
    formatWholeDataRec(keysArr, array, depth = 0) {
      return array.map((item) => {
        const keyEl = keysArr[depth];
        const idAppend = `${item.id}-${keyEl.short}`;
        if (keyEl.name in item) {
          const payload = {
            name: item.name,
            id: idAppend,
            children: this.formatWholeDataRec(
              keysArr,
              item[keyEl.name],
              depth + 1
            ),
          };
          item.archived && (payload.disabled = true);

          return payload;
        } else {
          const payload = {
            name: item.name,
            id: idAppend,
          };
          item.archived && (payload.disabled = true);

          return payload;
        }
      });
    },
    confirmActionAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-regions-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .content {
    display: flex;
    gap: 30px 40px;
    margin: 20px 0;
    width: 100%;

    .left-side,
    .right-side {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: center;
      width: 100%;
    }

    .chlist {
      > .checkbox-list-menu {
        overflow: auto;
        // height: 100%;
        height: 60vh;
        padding-right: 4px;
        width: 100%;
      }
    }
  }

  footer {
    display: flex;
    gap: 20px;
    margin: 20px 0 0;
  }
}
</style>
