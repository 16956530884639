<template lang="pug">
#candidate-holiday-request
  .table-wrap
    holiday-requests-table(:holidayRequests="holidayRequests")
    pagination(
      v-if="pagination && pagination.total > 0",
      :current-page="pagination.current_page",
      :total-pages="pagination.total_pages",
      @page-changed="pageHasChanged"
    )
</template>

<script>
import HolidayRequestsTable from "./HolidayRequestsTable.vue";
import Pagination from "@/components/Pagination.vue";
import { mapActions } from "vuex";
export default {
  name: "CandidateHolidayRequests",
  components: {
    HolidayRequestsTable,
    Pagination,
  },
  data() {
    return {
      tempId: null,
      holidayRequests: [],
      pagination: {
        total: 0,
        total_pages: 0,
        current_page: parseInt(this.$route.query?.page, 10) || 1,
        currentPageData: {},
      },
    };
  },
  mounted() {
    this.tempId = this.$route.params.tempId;
    if (this.tempId) {
      this.fetchHolidayRequests(this.tempId);
    } else {
      console.error("Invalid temp id", this.$route);
    }
  },
  methods: {
    ...mapActions({
      getHolidayRequests: "getHolidayRequests",
    }),
    async fetchHolidayRequests(tempId) {
      const params = {
        id: tempId,
        page: this.pagination.current_page,
      };
      try {
        const res = await this.getHolidayRequests(params);
        this.holidayRequests = res.data.data || [];
        if (res.data.meta?.pagination) {
          this.pagination = res.data.meta.pagination;
        } else {
          console.warn("Pagination not available");
        }
      } catch (err) {
        console.warn(err.message);
      }
    },
    pageHasChanged(pageNum) {
      this.pagination.current_page = pageNum;
      this.fetchHolidayRequests(this.tempId);
    },
  },
};
</script>

<style lang="scss" scoped>
#candidate-holiday-request {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .table-wrap {
    display: flex;
    flex-direction: column;
  }

  .pagination {
    align-self: flex-end;
  }
}
</style>
