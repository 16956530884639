import { render, staticRenderFns } from "./Switches.vue?vue&type=template&id=bcc0b3f0"
import script from "./Switches.vue?vue&type=script&lang=js"
export * from "./Switches.vue?vue&type=script&lang=js"
import style0 from "./switches.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_2c597f7d109fe0052e634bf327ce7ff6/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports