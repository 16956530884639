<template lang="pug">
.confirm-wrap
  .text Are you sure you want to assign candidate to this shift?
  .action
    .button.is-generic-app-blue.is-caps-lock.is-tiny.copy-shift(
      @click="confirm"
    ) Accept
    .button.is-caps-lock.is-tiny.copy-shift(@click="decline") Cancel
</template>

<script>
// import { Evt } from '../../lib/helpers/Evt.js'
import { parseErrors } from "../../lib/helpers/function.js";
import { mapActions } from "vuex";
export default {
  props: {
    shift: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    ...mapActions(["patchShift"]),
    async bookTemp() {
      const tempId = this.$route.params.tempId;
      if (!tempId || !this.shift.id) {
        return;
      }

      const params = {
        id: this.shift.id,
        data: {
          temp_id: tempId,
        },
      };
      try {
        await this.patchShift(params);
        return true;
      } catch (error) {
        const errs = parseErrors(error);
        this.$toasted.error(errs).goAway(4500);
      }
      return false;
    },
    async confirm() {
      const isDone = await this.bookTemp();
      if (isDone) {
        this.$emit("confirm");
      }
    },
    decline() {
      this.$emit("decline");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-wrap {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  .text {
    margin-bottom: 20px;
    text-align: center;
  }

  .action {
    display: flex;
    justify-content: center;

    > * {
      margin-left: 1rem;
    }
  }
}
</style>
