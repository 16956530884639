
// import _ from 'underscore'
import moment from 'moment';

class CalendarUtils {
  constructor () {
    this.current = moment();
  }

  getCurrentMonth () {
    const intMonth = this.M.month();
    return this.M.month(intMonth);
  }

  static getDatesBetween (startDate, endDate, transformToDate = false) {
    let now = startDate.clone();
    let dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {
      dates.push(transformToDate ? now.toDate() : now);
      now.add(1, 'days');
    }
    return dates;
  }

  static getHoursAndMinutesFromString (timeString) {
    let timeParts = timeString.split(':');
    if (timeParts.length !== 2) {
      throw Error('Time is not in valid format');
    }
    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);
    if (isNaN(hours) || isNaN(minutes)) {
      throw Error('Time is not in valid format');
    }
    return {
      hours,
      minutes,
    };
  }
}

export default CalendarUtils;
