import { render, staticRenderFns } from "./NewClient.vue?vue&type=template&id=429dd304&scoped=true&lang=pug"
import script from "./NewClient.vue?vue&type=script&lang=js"
export * from "./NewClient.vue?vue&type=script&lang=js"
import style0 from "./NewClient.vue?vue&type=style&index=0&id=429dd304&prod&lang=scss"
import style1 from "./NewClient.vue?vue&type=style&index=1&id=429dd304&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_2c597f7d109fe0052e634bf327ce7ff6/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "429dd304",
  null
  
)

export default component.exports