import AllCandidates from "../../components/AllCandidates.vue";
import Auth from "../../components/auth/Auth.vue";
import ForgotPassword from "../../components/auth/ForgotPassword.vue";
import PasswordRecovery from "../../components/auth/PasswordRecovery.vue";
// import Register from '../../components/auth/Register.vue'
import Signin from "../../components/auth/Signin.vue";
import Calendar from "../../components/Calendar/Calendar.vue";
import CandidatePage from "../../components/Candidate/CandidatePage.vue";
import ClientApproversInfo from "../../components/Client/ClientApproversInfo.vue";
import ClientContactsInfo from "../../components/Client/ClientContactsInfo.vue";
import ClientDetailsPage from "../../components/Client/ClientDetailsPage.vue";
import ClientDocumentsInfo from "../../components/Client/ClientDocumentsInfo.vue";
import ClientFinancesInfo from "../../components/Client/ClientFinancesInfo.vue";
import ClientInvoiceInfo from "../../components/Client/ClientInvoiceInfo.vue";
import ClientJournalInfo from "../../components/Client/ClientJournalInfo.vue";
import ClientProfileInfo from "../../components/Client/ClientProfileInfo.vue";
import ClientUserManagement from "../../components/ClientUserManagement.vue";
import ClientContactsDefaultPage from "../../components/Client/Contacts/ClientContactsDefaultPage.vue";
import ClientContactsShiftConfirmPage from "../../components/Client/Contacts/ClientContactsShiftConfirmPage.vue";
import Compliance from "../../components/Compliances/Compliance.vue";
import DocumentsTable from "../../components/Compliances/DocumentsTable.vue";
import TempCompliance from "../../components/Compliances/TempCompliance.vue";
import Dashboard from "../../pages/DashboardPage.vue";
import EditProfile from "../../components/EditProfile.vue";
import CandidateHolidayRequests from "@/components/Candidate/CandidateHolidayRequests.vue";
import EditUser from "../../components/EditUser.vue";
import BulkUploadOpenShifts from "../../components/FileUploaderPage/BulkUploadOpenShifts.vue";
import BulkUploadTimesheetShifts from "../../components/FileUploaderPage/BulkUploadTimesheetShifts.vue";
import FileUploader from "../../components/FileUploaderPage/FileUploader.vue";
import SubmitMultipleShiftsForm from "../../components/forms/SubmitMultipleShiftsForm.vue";
import SubmitShiftForm from "../../components/forms/SubmitShiftForm.vue";
import TimesheetEntry from "../../components/forms/TimesheetEntry.vue";
import Home from "../../components/Home.vue";
import Invoices from "../../components/Invoices/Invoices.vue";
import PayBillHolidayBalances from "@/components/PayBill/PayBillHolidayBalances.vue";
import PayBillExpenses from "@/components/PayBill/PayBillExpenses.vue";
import TabInvoices from "@/components/Invoices/TabInvoices.vue";
import LocationClientManagementTabs from "../../components/LocationClientManagement/LocationClientManagementTabs.vue";
import RatesManageCategories from "../../components/Rates/components/RatesManageCategories.vue";
import RatesManageIncrements from "../../components/Rates/components/RatesManageIncrements.vue";
import RatesManageRatesPage from "../../components/Rates/components/RatesManageRatesPage.vue";
import RatesManageExpenses from "../../components/Rates/components/RatesManageExpenses.vue";
import RatesManagePublicHolidays from "../../components/Rates/components/RatesManagePublicHolidays.vue";
import ManageRates from "../../components/Rates/ManageRates.vue";
import Reports from "../../components/Reports/Reports.vue";
import SecurityAdminDeactivationPage from "../../components/Security/AdminDeactivation/SecurityAdminDeactivationPage.vue";
import SecurityContactPage from "../../components/Security/Contacts/SecurityContactPage.vue";
import SecurityJournalPage from "../../components/Security/Journal/SecurityJournalPage.vue";
import NotificationEmailFromList from "@/components/NotificationCentre/NotificationEmailFromList.vue";
import IncrementWeeksSettings from "@/components/Security/Increment/IncrementWeeksSettings.vue";
import HolidayRequestSettings from "@/components/Security/HolidayRequest/HolidayRequestSettingsPage.vue";
import PermissionSettingsPage from "@/components/Security/Permissions/PermissionSettingsPage.vue";
import PermissionListManage from "@/components/Security/Permissions/PermissionListManage.vue";
import GeneralReceiptsManage from "@/components/Security/GeneralReceipts/GeneralReceiptsManage.vue";
import SecuritySection from "../../components/Security/SecuritySection.vue";
import SecurityTagsPage from "../../components/Security/Tags/SecurityTagsPage.vue";
import ShiftActivityTags from "@/components/Security/ShiftsActivityTags/ActivityTagsPage.vue";
import ShiftSchedule from "../../components/ShiftSchedule.vue";
import ShiftDetails from "../../components/ShiftScheduleViews/ShiftDetails.vue";
import ShiftScheduleTabs from "../../components/ShiftScheduleViews/ShiftScheduleTabs.vue";
import SubmitShift from "../../components/SubmitShift.vue";
import OneTempCalendarWrap from "../../components/TempCalendar/OneTempCalendarWrap.vue";
import NotificationCentrePage from "../../components/NotificationCentre/NotificationCentrePage.vue";
import NotificationCentreForm from "../../components/NotificationCentre/NotificationCentreForm.vue";
import ExpensesPage from "../../components/Expenses/ExpensesPage.vue";
import ListOfWorkers from "../../components/NotificationCentre/ListOfWorkers.vue";
import CandidateJournalsSystem from "../../components/Client/ClientJournalInfo/CandidateJournalsSystem.vue";
import CandidateJournalLogs from "../../components/Client/ClientJournalInfo/CandidateJournalLogs.vue";

const NotFound = { template: "<h2>Page not found.</h2>" };
const Error = { template: "<h2>Not allowed.</h2>" };

export default [
  {
    path: "/auth",
    component: Auth,
    redirect: "/auth/signin",
    children: [
      {
        path: "signin",
        name: "signin",
        component: Signin,
        meta: {
          requiresGuest: true,
          permission: "",
          authComp: true,
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "forgot.password",
    component: ForgotPassword,
    meta: {
      requiresGuest: true,
      permission: "",
      authComp: true,
      fail: "/error",
    },
  },
  {
    path: "/password/reset/:hash",
    name: "password.recovery",
    component: PasswordRecovery,
    meta: {
      permission: "",
      // permission: 'any',
      authComp: true,
      fail: "/error",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      permission: "view-dashboard",
      fail: "/error",
    },
  },
  {
    path: "/submit-shift",
    redirect: "/submit-shift/single",
    name: "submitShift",
    component: SubmitShift,
    meta: {
      requiresAuth: true,
      permission: "view-shifts",
      fail: "/error",
    },
    children: [
      {
        path: "single",
        name: "submitShiftSingle",
        component: SubmitShiftForm,
        meta: {
          requiresAuth: true,
          permission: "view-shifts",
          fail: "/error",
        },
      },
      {
        path: "line",
        name: "submitShiftLine",
        component: SubmitMultipleShiftsForm,
        meta: {
          requiresAuth: true,
          permission: "view-shifts",
          fail: "/error",
        },
      },
      {
        path: "timesheet",
        name: "submitShiftTimesheet",
        component: TimesheetEntry,
        meta: {
          requiresAuth: true,
          // permission: 'create-shift',
          permission: "create-timesheet-line-of-shifts",
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/shift-schedule",
    component: ShiftSchedule,
    children: [
      {
        path: "",
        name: "shiftSchedule",
        component: ShiftScheduleTabs,
        meta: {
          requiresAuth: true,
          permission: "view-shifts|view-temp-shifts",
          fail: "/error",
        },
      },
      {
        name: "shiftDetails",
        path: "details/:shiftId",
        component: ShiftDetails,
        props: true,
        meta: {
          requiresAuth: true,
          permission: "view-shifts|view-temp-shifts",
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/manage-rates",
    redirect: "/manage-rates/categories",
    name: "ManageRates",
    component: ManageRates,
    meta: {
      requiresAuth: true,
      permission: "manage-rates",
      fail: "/error",
    },
    children: [
      {
        path: "categories",
        name: "RatesManageCategories",
        component: RatesManageCategories,
        meta: {
          requiresAuth: true,
          permission: "manage-rates",
          fail: "/error",
        },
      },
      {
        path: "increments",
        name: "RatesManageIncrements",
        component: RatesManageIncrements,
        meta: {
          requiresAuth: true,
          permission: "manage-rates",
          fail: "/error",
        },
      },
      {
        path: "rates/:type?/:client_id?/:cat_id?",
        name: "RatesManageRatesPage",
        component: RatesManageRatesPage,
        meta: {
          requiresAuth: true,
          permission: "manage-rates",
          fail: "/error",
        },
      },
      {
        path: "expenses/:code?",
        name: "RatesManageExpenses",
        component: RatesManageExpenses,
        meta: {
          requiresAuth: true,
          permission: "manage-expense-rates",
          fail: "/error",
        },
      },
      {
        path: "public",
        name: "RatesManagePublicHolidays",
        component: RatesManagePublicHolidays,
        meta: {
          requiresAuth: true,
          permission: "manage-rates",
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/documents",
    name: "documents",
    component: DocumentsTable,
    meta: {
      requiresAuth: true,
      permission: "view-documents",
      fail: "/error",
    },
  },
  {
    path: "/compliance",
    name: "compliance",
    component: Compliance,
    meta: {
      requiresAuth: true,
      permission: "view-documents",
      fail: "/error",
    },
  },
  {
    path: "/location-client-management",
    name: "locationClientManagement",
    component: LocationClientManagementTabs,
    meta: {
      "has-keep-alive": ["locationClientManagement"],
      requiresAuth: true,
      permission: "view-client-management-page",
      fail: "/error",
    },
  },
  {
    path: "/temp-compliance/:tempId",
    name: "temp-compliance",
    component: TempCompliance,
    meta: {
      "has-keep-alive": ["allCandidates"],
      requiresAuth: true,
      permission: "view-documents",
      fail: "/error",
    },
  },
  {
    path: "/edit-profile",
    name: "editProfile",
    component: EditProfile,
    meta: {
      requiresAuth: true,
      permission: "view-profile|update-profile",
      fail: "/error",
    },
  },
  {
    path: "/settings",
    redirect: "/settings/admin-deactivation",
    name: "securityPage",
    component: SecuritySection,
    meta: {
      requiresAuth: true,
      permission: "manage-security",
      fail: "/error",
    },
    children: [
      {
        path: "admin-deactivation",
        name: "SecurityAdminDeactivationPage",
        component: SecurityAdminDeactivationPage,
        meta: {
          requiresAuth: true,
          permission: "manage-security",
          fail: "/error",
        },
      },
      {
        path: "contacts",
        name: "SecurityContactPage",
        component: SecurityContactPage,
        meta: {
          requiresAuth: true,
          permission: "manage-security",
          fail: "/error",
        },
      },
      {
        path: "tags",
        name: "SecurityTagsPage",
        component: SecurityTagsPage,
        meta: {
          requiresAuth: true,
          permission: "manage-security",
          fail: "/error",
        },
      },
      {
        path: "journal",
        name: "SecurityJournalPage",
        component: SecurityJournalPage,
        meta: {
          requiresAuth: true,
          permission: "manage-security",
          fail: "/error",
        },
      },
      {
        path: "email-from",
        name: "NotificationEmailFromList",
        component: NotificationEmailFromList,
        meta: {
          requiresAuth: true,
          permission: "manage-security",
          fail: "/error",
        },
      },
      {
        path: "increment-weeks",
        name: "IncrementWeeksSettings",
        component: IncrementWeeksSettings,
        meta: {
          requiresAuth: true,
          permission: "manage-security",
          fail: "/error",
        },
      },
      {
        path: "holiday-request",
        name: "HolidayRequestSettings",
        component: HolidayRequestSettings,
        meta: {
          requestAuth: true,
          permission: "manage-security",
          systemPermissions: ["can_use_holiday_requests"],
          fail: "/error",
        },
      },
      {
        path: "permissions",
        name: "PermissionSettingsPage",
        component: PermissionSettingsPage,
        meta: {
          requestAuth: true,
          permission: "manage-security",
          fail: "/error",
        },
        children: [
          {
            path: ":id",
            name: "PermissionListManage",
            component: PermissionListManage,
            props: true,
            meta: {
              requestAuth: true,
              permission: "manage-security",
              fail: "/error",
            },
          },
        ],
      },
      {
        path: "general-receipts",
        name: "GeneralReceiptPage",
        component: GeneralReceiptsManage,
        meta: {
          requestAuth: true,
          permission:
            "manage-general-receipts|view-expenses|view-temp-expenses",
          // systemPermissions: ["can_use_expenses"],
          fail: "/error",
        },
      },
      {
        path: "shift-activity-tags",
        name: "ShiftActivityTags",
        component: ShiftActivityTags,
        meta: {
          requestAuth: true,
          permission: "",
          // systemPermissions: ["can_use_expenses"],
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/file-upload",
    redirect: "/file-upload/timesheet",
    name: "fileUploaderPage",
    component: FileUploader,
    meta: {
      requiresAuth: true,
      permission: "bulk-upload-timesheet",
      fail: "/error",
    },
    children: [
      {
        path: "timesheet",
        name: "bulkUploadTimesheetShifts",
        component: BulkUploadTimesheetShifts,
        meta: {
          requiresAuth: true,
          permission: "bulk-upload-timesheet",
          fail: "/error",
        },
      },
      {
        path: "open-shifts",
        name: "bulkUploadOpenShifts",
        component: BulkUploadOpenShifts,
        meta: {
          requiresAuth: true,
          permission: "bulk-upload-open-shifts",
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/invoices",
    name: "InvoicesPage",
    component: Invoices,
    meta: {
      requiresAuth: true,
      permission:
        "view-billing|view-holiday-requests-billing|view-expenses-billing",
      // permission: "view-billing|delete-billing",
      fail: "/error",
    },
    children: [
      {
        path: "main",
        name: "tab-invoices",
        component: TabInvoices,
        meta: {
          requiresAuth: true,
          // permission: "view-billing|delete-billing",
          permission: "view-billing",
          fail: "/error",
        },
      },
      {
        path: "holiday-balances",
        name: "PayBillHolidayBalances",
        component: PayBillHolidayBalances,
        meta: {
          requiresAuth: true,
          permission: "view-holiday-requests-billing",
          systemPermissions: ["can_use_holiday_requests"],
          fail: "/error",
        },
      },
      {
        path: "expenses",
        name: "PayBillExpenses",
        component: PayBillExpenses,
        meta: {
          requiresAuth: true,
          permission: "view-expenses-billing",
          systemPermissions: ["can_use_expenses"],
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      permission: "view-report|schedule-report",
      fail: "/error",
    },
  },
  {
    path: "/all-candidates",
    name: "allCandidates",
    component: AllCandidates,
    props: (route) => ({
      page: route.query.page,
    }),
    meta: {
      "has-keep-alive": ["allCandidates"],
      requiresAuth: true,
      permission: "view-all-candidates-page",
      fail: "/error",
    },
  },
  {
    path: "/temp-calendar/:tempId",
    name: "view-temp-calendar",
    component: OneTempCalendarWrap,
    meta: {
      "has-keep-alive": ["allCandidates"],
      requiresAuth: true,
      permission: "view-temp-calendar",
      fail: "/error",
    },
  },
  {
    path: "/candidate/:tempId",
    redirect: "/candidate/:tempId/profile",
    name: "CandidatePage",
    component: CandidatePage,
    meta: {
      "has-keep-alive": ["allCandidates"],
      requiresAuth: true,
      permission: "view-temp-details",
      fail: "/error",
    },
    children: [
      {
        path: "profile",
        name: "edit-candidate",
        component: EditProfile,
        meta: {
          "has-keep-alive": ["allCandidates"],
          requiresAuth: true,
          permission: "view-temp-details",
          fail: "/error",
        },
      },
      {
        path: "journals",
        name: "CandidateJournals",
        component: ClientJournalInfo,
        props: true,
        redirect: {
          name: "CandidateJournalsSystem",
        },
        meta: {
          "has-keep-alive": ["allCandidates"],
          requiresAuth: true,
          permission: "view-temp-journal",
          fail: "/error",
        },
        children: [
          {
            path: "system",
            name: "CandidateJournalsSystem",
            component: CandidateJournalsSystem,
            props: true,
            meta: {
              "has-keep-alive": ["allCandidates"],
              requiresAuth: true,
              permission: "view-temp-journal",
              fail: "/error",
            },
          },
          {
            path: "logs",
            name: "CandidateJournalLogs",
            component: CandidateJournalLogs,
            props: true,
            meta: {
              "has-keep-alive": ["allCandidates"],
              requiresAuth: true,
              permission: "view-temp-journal",
              fail: "/error",
            },
          },
        ],
      },
      {
        path: "holiday-requests",
        name: "CandidateHolidayRequests",
        component: CandidateHolidayRequests,
        props: true,
        meta: {
          "has-keep-alive": ["allCandidates"],
          requiresAuth: true,
          permission: "view-holiday-requests",
          systemPermissions: ["can_use_holiday_requests"],
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/client-user-management",
    name: "clientUserManagement",
    component: ClientUserManagement,
    meta: {
      "has-keep-alive": ["clientUserManagement"],
      requiresAuth: true,
      permission: "create-user",
      fail: "/error",
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    meta: {
      requiresAuth: true,
      permission: "view-calendar",
      fail: "/error",
    },
  },
  {
    path: "/edit-user/:userId",
    name: "edit-user",
    component: EditUser,
    meta: {
      "has-keep-alive": ["clientUserManagement"],
      requiresAuth: true,
      permission: "edit-user",
      fail: "/error",
    },
  },
  {
    path: "/client/:id",
    redirect: "/client/:id/info",
    name: "ClientDetailsPage",
    component: ClientDetailsPage,
    meta: {
      "has-keep-alive": ["locationClientManagement"],
      requiresAuth: true,
      permission: "view-client",
      fail: "/error",
    },
    children: [
      {
        path: "info",
        name: "ClientProfileInfo",
        component: ClientProfileInfo,
        meta: {
          "has-keep-alive": ["locationClientManagement"],
          requiresAuth: true,
          permission: "view-client",
          fail: "/error",
        },
      },
      {
        path: "approvers",
        name: "ClientApproversInfo",
        component: ClientApproversInfo,
        meta: {
          "has-keep-alive": ["locationClientManagement"],
          requiresAuth: true,
          permission: "view-client-admins",
          fail: "/error",
        },
      },
      {
        path: "documents",
        name: "ClientDocumentsInfo",
        component: ClientDocumentsInfo,
        meta: {
          "has-keep-alive": ["locationClientManagement"],
          requiresAuth: true,
          permission: "view-documents-on-client-page",
          fail: "/error",
        },
      },
      {
        path: "contacts",
        name: "ClientContactsInfo",
        component: ClientContactsInfo,
        redirect: {
          name: "ClientContactsDefaultPage",
        },
        meta: {
          "has-keep-alive": ["locationClientManagement"],
          requiresAuth: true,
          permission: "view-contacts",
          fail: "/error",
        },
        children: [
          {
            path: "all",
            name: "ClientContactsDefaultPage",
            component: ClientContactsDefaultPage,
            meta: {
              "has-keep-alive": ["locationClientManagement"],
              requiresAuth: true,
              permission: "view-contacts",
              fail: "/error",
            },
          },
          {
            path: "shift-confirmation",
            name: "ClientContactsShiftConfirmPage",
            component: ClientContactsShiftConfirmPage,
            meta: {
              "has-keep-alive": ["locationClientManagement"],
              requiresAuth: true,
              permission: "view-contacts",
              fail: "/error",
            },
          },
        ],
      },
      {
        path: "finances",
        name: "ClientFinancesInfo",
        component: ClientFinancesInfo,
        meta: {
          "has-keep-alive": ["locationClientManagement"],
          requiresAuth: true,
          permission: "edit-service-fees",
          fail: "/error",
        },
        children: [
          {
            path: ":categoryId",
            name: "ClientFinancesInfoCategory",
            component: ClientFinancesInfo,
            meta: {
              "has-keep-alive": ["locationClientManagement"],
              requiresAuth: true,
              permission: "edit-service-fees",
              fail: "/error",
            },
          },
        ],
      },
      {
        path: "invoices",
        name: "ClientInvoiceInfo",
        component: ClientInvoiceInfo,
        meta: {
          "has-keep-alive": ["locationClientManagement"],
          requiresAuth: true,
          permission: "split-invoice-by-subcategory",
          fail: "/error",
        },
      },
      {
        path: "journals",
        name: "ClientJournalInfo",
        component: ClientJournalInfo,
        props: true,
        redirect: {
          name: "ClientCandidateJournalsSystem",
        },
        meta: {
          "has-keep-alive": ["locationClientManagement"],
          requiresAuth: true,
          permission: "view-client-journal",
          fail: "/error",
        },
        children: [
          {
            path: "system",
            name: "ClientCandidateJournalsSystem",
            component: CandidateJournalsSystem,
            props: true,
            meta: {
              "has-keep-alive": ["allCandidates"],
              requiresAuth: true,
              permission: "view-temp-journal",
              fail: "/error",
            },
          },
          {
            path: "logs",
            name: "ClientCandidateJournalLogs",
            component: CandidateJournalLogs,
            props: true,
            meta: {
              "has-keep-alive": ["allCandidates"],
              requiresAuth: true,
              permission: "view-temp-journal",
              fail: "/error",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/notification-centre",
    redirect: "/notification-centre/email",
    name: "NotificationCentrePage",
    component: NotificationCentrePage,
    meta: {
      requiresAuth: true,
      permission: "view-notificationcentre-messages",
      fail: "/error",
    },
    children: [
      {
        path: "email",
        name: "NotificationCentreEmail",
        component: NotificationCentreForm,
        props: { isEmail: true },
        meta: {
          requiresAuth: true,
          permission: "view-notificationcentre-messages",
          fail: "/error",
        },
      },
      {
        path: "message-centre",
        name: "NotificationCentreMessage",
        component: NotificationCentreForm,
        props: { isPushMessage: true },
        meta: {
          requiresAuth: true,
          permission: "view-notificationcentre-messages",
          fail: "/error",
        },
      },
    ],
  },
  {
    path: "/expenses/awaiting",
    name: "ExpensesPageAwaiting",
    component: ExpensesPage,
    meta: {
      requiresAuth: true,
      permission: "view-expenses|view-temp-expenses",
      fail: "/error",
    },
  },
  {
    path: "/expenses/approved",
    name: "ExpensesPageApproved",
    component: ExpensesPage,
    meta: {
      requiresAuth: true,
      permission: "view-expenses|view-temp-expenses",
      fail: "/error",
    },
  },
  {
    path: "/expenses/rejected",
    name: "ExpensesPageRejected",
    component: ExpensesPage,
    meta: {
      requiresAuth: true,
      permission: "view-expenses|view-temp-expenses",
      fail: "/error",
    },
  },
  {
    path: "/notification-centre/list-of-workers",
    name: "ListOfWorkers",
    component: ListOfWorkers,
    meta: {
      requiresAuth: true,
      permission: "",
      fail: "/error",
    },
  },
  /*
    {
      path: '/shift-schedule/vend-off/:token',
      name: 'magickLink',
      component: MagickLink,
      meta: {
        requiresAuth: false
      }
    },
    */
  {
    path: "/",
    name: "home",
    redirect: "/auth",
    component: Home,
  },
  {
    path: "/error",
    redirect: "/", // Error comp is not used anymore
    component: Error,
    meta: {
      permission: "",
    },
  },
  {
    path: "*",
    component: NotFound,
  },
];
