<template lang="pug">
.increment-weeks-settings
  .title Update increment counter range
  .content
    p.control
      label.label From
      input.is-medium.input.is-medium(
        type="number",
        placeholder="Change from",
        v-model="form.from"
      )
      span.help.is-danger(
        v-if="errors.has('from')",
        v-html="errors.get('from')"
      )
    p.control
      label.label To
      input.is-medium.input.is-medium(
        type="number",
        placeholder="Change to",
        v-model="form.to"
      )
      span.help.is-danger(v-if="errors.has('to')", v-html="errors.get('to')")
    span.help.is-danger(
      data-cell="locations-error",
      v-if="errors.has('locations')",
      v-html="errors.get('locations')"
    )
  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      type="submit",
      @click.prevent="onClickSave"
    ) Save
</template>

<script>
import { mapActions } from "vuex";
import { Errors } from "../../../lib/helpers/Errors";
import { findRecordErrors, parseErrors } from "../../../lib/helpers/function";
export default {
  data() {
    return {
      form: {},
      errors: new Errors(),
    };
  },
  mounted() {
    this.fetchIncrementWeeks();
  },
  methods: {
    ...mapActions({
      getIncrementWeeksCounter: "getIncrementWeeksCounter",
      postIncrementWeeksCounter: "postIncrementWeeksCounter",
    }),
    onClickSave() {
      this.setIncrementWeeks();
    },
    async fetchIncrementWeeks() {
      try {
        const res = await this.getIncrementWeeksCounter();
        this.form = res.data.increment_counter_range;
      } catch (err) {
        console.warn(err.message);
      }
    },
    async setIncrementWeeks() {
      const params = this.form;
      try {
        const res = await this.postIncrementWeeksCounter(params);
        this.errors.clear();
        this.$toasted.info(res.data.message).goAway(2500);
        this.fetchIncrementWeeks();
      } catch (err) {
        console.warn(err.message);
        const errors = findRecordErrors(err);
        if (errors) {
          this.errors.record(errors);
          this.$toasted
            .show("Please correct the following errors.")
            .goAway(2500);
          this.errors.all().map((e) => {
            this.$toasted.error(e).goAway(2500);
          });
        } else {
          let errs = parseErrors(
            err,
            "Error saving weekly increment counter",
            true
          );
          console.warn(err.response.data.message);
          this.$toasted.error(errs).goAway(4500);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.increment-weeks-settings {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 20px 0;
  gap: 20px;

  .title {
    display: flex;
  }

  .content {
    display: flex;
    gap: 20px;
  }
}
</style>
