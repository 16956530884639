
/**
 * Event dispatcher
 *
 * use it this way, in any of your components:
 *
 * - Event.fire(event[, data]) to emit an event, along with optional data sent through..
 * - Event.listen(event, callback) to listen for an emitted event, and trigger the callback func..
 * - When using the global event bus, bound event listeners need to be manually switched off on beforeDestruction() else they will stack
 */
import Vue from 'vue';

export class Hub {
  constructor () {
    this.vue = new Vue();
  }
  /**
   * event - How you wanna call your custom event
   * data - Data you pass along event to a subscriber
   * native - optionally send native event
   */
  fire (event, data = null, native = null) {
    this.vue.$emit(event, data, native);
  }

  listen (event, callback) {
    this.vue.$on(event, callback);
  }

  off (event = null) {
    this.vue.$off(event);
  }
}

// We will always get the same instance
// later when we do import { Evt } from Evt.js
export let Evt = new Hub();
