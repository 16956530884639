<template lang="pug">
.is-composite
  .sidebar-info(v-if="hasSidebar")
    .upper
      span upper
    .lower
      span lower
  .comment-box
    p.control(v-if="progressCount")
      span.tag.is-light(:class="{ 'is-danger': value.length == maxChars }") {{ maxChars - value.length }} chars left.
    textarea.textarea(
      ref="input",
      placeholder="NOTES",
      :value="value",
      @input="updateNotes($event.target.value)",
      @keypress="checkLength",
      @keydown.ctrl.86.prevent="",
      @keydown.shift.45.prevent=""
    )
    .progress
      .line(:class="progressWaring", :style="getProgressWidth")
    p.control
      //- progress.progress.is-small(
      //-   v-if="progressBar",
      //-   :value="100 - (value.length/maxChars)*100", max="100",
      //-   :class="progressWaring"
      //- )
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
    'max-chars': {
      default: 500,
    },
    'progress-bar': {
      default: false,
    },
    'progress-count': {
      default: true,
    },
    'has-sidebar': {
      default: false,
    },
  },
  methods: {
    updateNotes: function (value, evt) {
      this.$emit('input', value);
      console.log(evt);
    },
    checkLength (evt) {
      if (this.value.length >= this.maxChars) {
        evt.preventDefault();
      }
    },
  },
  computed: {
    getProgressWidth () {
      const width = `${100 - (this.value.length / this.maxChars) * 100}%`;
      if (parseInt(width) < 0) {
        return { width: 0 };
      }
      return { width };
    },
    progressWaring () {
      return {
        'high': (this.value.length < this.maxChars / 2),
        'med': (this.value.length >= this.maxChars / 2),
        'low': (this.value.length >= this.maxChars - 8),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.is-composite {
  display: flex;

  .sidebar-info {
    display: flex;
    flex-direction: column;
    display: flex;
    background-color: #eef5f5;
    border: 1px solid #cdd5dd;
    width: 15em;

    .upper {
      border-top-left-radius: 8px;
      flex: 1;
    }

    .lower {
      border-bottom-left-radius: 8px;
      flex: 1;
    }
  }

  .comment-box {
    flex: 1;
  }

  .progress {
    background: #dbdbdbdc;
    height: 0.38em;
    border-radius: 0;
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;

    .line {
      height: 100%;

      &.high {
        background: var(--color-primary-10);
      }

      &.med {
        background: #bdbd5b;
      }

      &.low {
        background: red;
      }
    }
  }
}
textarea {
  border-radius: 0;
  // border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.fa.fa-check {
  margin-right: 0.5em;
}
</style>
