<template lang="pug">
.register-temp-increments
  //- No need for 'canViewIncrementWeekCounter' here
  .increment-weeks-counter-section(
    v-if="gPermissions.canEditIncrementWeekCounter && canUseIncrementCo"
  )
    label.label Weekly Increment Counter
    .bottom-bar
      multi-select(
        :options="incrementWeekList",
        :multiple="false",
        :close-on-select="true",
        track-by="weeks_counter",
        label="weeks_counter",
        :value="selectedIncrementWeek",
        placeholder="Select increment week",
        @select="onSelectIncrementWeek"
      )
  manage-increments(
    :modalProps="modalProps",
    :allIncrementsPoints="allIncrementsPoints",
    :currentIncrementData="currentIncrementData",
    @add-increment="onAddIncrement",
    @delete-increment="onDeleteIncrement"
  )
  .buttons
    button.button.is-info.is-low(@click="onConfirm")
      span Confirm
    button.button.is-info.is-low(@click="onCancel")
      span Cancel
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
// import _ from 'underscore'
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { Errors } from "../../../lib/helpers/Errors.js";
import ManageIncrements from "./ManageIncrements.vue";
import MultiSelect from "vue-multiselect";
export default {
  components: {
    ManageIncrements,
    MultiSelect,
  },
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Deep-clone the data so we don't end up messing with
      // vuex when we modify data for our purposes
      incrementData: this.modalProps,
      errors: new Errors(),
      currentIncrementData: [],
      allIncrementsPoints: [],
      // preparedData: {},
      errorText: [],
      selectedIncrementWeek: null,
      incrementWeekList: [],
    };
  },
  computed: {
    ...mapState({
      initialStatus: (state) => state.initialStatus,
    }),
    categoryId() {
      if (
        this.incrementData.subcategory &&
        this.incrementData.subcategory.catParentId
      ) {
        return this.incrementData.subcategory.catParentId;
      }
      return null;
    },
    subCatId() {
      if (this.incrementData.subcategory && this.incrementData.subcategory.id) {
        return this.incrementData.subcategory.id;
      }
      return null;
    },
    canUseIncrementCo() {
      return this.initialStatus?.can_use_increment_counter;
    },
  },
  created() {
    if (this.gPermissions.canEditTempIncrement) {
      this.checkFetchIncrementsPoints();
    }
  },
  mounted() {
    if (this.gPermissions.canEditTempIncrement) {
      this.setupCurrentIncrementsData();
    }

    if (
      this.canUseIncrementCo &&
      this.gPermissions.canViewIncrementWeekCounter
    ) {
      this.fetchIncrementWeeksData();
    }
  },
  methods: {
    ...mapActions([
      "deleteTempIncrement",
      "getIncrements",
      "postUpdateForCategory",
      "getIncrementWeeksCounter",
    ]),
    async fetchIncrementWeeksData() {
      await this.fetchIncrementWeeksRange();
      this.checkSetCurrentIncrementValue();
    },
    async fetchIncrementWeeksRange() {
      try {
        const res = await this.getIncrementWeeksCounter();
        const { from, to } = res.data.increment_counter_range || {};
        const leng = to - from + 1;
        this.incrementWeekList = Array.from({ length: leng }).map(
          (val, ind) => {
            return {
              subcategory_id: this.subCatId,
              weeks_counter: ind + from,
            };
          }
        );
      } catch (err) {
        console.warn(err.message);
      }
    },
    checkSetCurrentIncrementValue() {
      const currentIncWeek = this.incrementData.currentIncrementWeek;
      if (currentIncWeek) {
        this.selectedIncrementWeek = currentIncWeek;
      } else {
        this.selectedIncrementWeek = null;
      }
    },
    checkFetchIncrementsPoints() {
      if (this.categoryId) {
        this.fetchIncrementsPoints(this.categoryId);
      }
    },
    setupCurrentIncrementsData() {
      let originalList = [];
      if (this.subCatId) {
        const subCatId = this.subCatId;
        // Filter by cats
        originalList = this.incrementData.increments.filter(
          (el) => el.category_id === subCatId
        );
      }
      this.currentIncrementData = [...originalList];
    },
    async fetchIncrementsPoints(catId) {
      const params = {
        category_id: catId,
      };
      try {
        const res = await this.getIncrements(params);
        this.allIncrementsPoints = this.formatIncrements(res.data.data).sort();
        // console.log('increment points', this.allIncrementsPoints);
      } catch (err) {
        console.warn(err.message);
      }
    },
    formatIncrements(dataArr) {
      // Sort increments
      return dataArr.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
    },
    onAddIncrement(params) {
      console.log(params);
      const addFunc = () => {
        const currDate = moment(params.date.time, "DD/MM/YYYY");
        const slicedDate = currDate.format("YYYY-MM-DD").slice(0, 10);
        // Keys must not be changed here
        const incrementData = {
          category_id: this.subCatId,
          start_date: slicedDate,
          increment_id: params.incrementPoint.id,
        };

        const foundInc = this.currentIncrementData.find(
          (inc) => inc.increment_id === incrementData.increment_id
        );
        if (foundInc) {
          // This shouldn't occur
          // this.$set(foundInc, '')
          console.log("ERROR found same obj");
        } else {
          this.currentIncrementData.push(incrementData);
        }
      };

      // this.confirmAlert('create a new increment').then(() => {
      addFunc();
      // });
    },
    async onDeleteIncrement(incObj) {
      this.removeRefreshIncrement(incObj);
    },
    removeRefreshIncrement(incObj) {
      const remFunc = async () => {
        const incObjIndex = this.currentIncrementData.findIndex(
          (inc) => inc.increment_id === incObj.increment_id
        );
        if (incObjIndex !== -1) {
          this.currentIncrementData.splice(incObjIndex, 1);
        }
      };

      this.confirmAlert("delete this increment").then(() => {
        remFunc();
      });
    },
    confirmAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    onSelectIncrementWeek(evt) {
      this.selectedIncrementWeek = evt;
    },
    onConfirm() {
      // Send the list to parent
      const payload = {
        increments: this.currentIncrementData,
      };
      if (this.canUseIncrementCo) {
        payload.incWeeks = this.selectedIncrementWeek;
      }
      this.$emit("confirm-increments", payload);
    },
    onCancel() {
      this.$emit("cancel-form");
    },
  },
};
</script>

<style lang="scss" scoped>
.register-temp-increments {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}
</style>
