<template lang="pug">
.uni-tabs-wrap(ref="tabsWrapper")
  .uni-tabs.is-fullwidth(ref="uniTabs")
    router-link(
      :class="{ 'native-active': !hasCustomActive, 'is-custom-active': isActiveTab(tab), inactive: isTabInactive(tab) }",
      :to="{ name: tab.routeName, params: getRouteParams(tab), query: getRouteQuery(tab) }",
      v-for="(tab, index) in tabs",
      :key="index",
      @click.native="onTabClick(tab)"
    )
      span {{ tab.label }}
      .line(v-if="!modernUi")
  .line-wrapper(v-if="modernUi")
    .line(:style="dynamicLineStyle")
</template>

<script>
import _ from "underscore";
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: Object,
      required: false,
    },
    modernUi: {
      default: false,
    },
  },
  data() {
    return {
      tabStyleProps: {},
    };
  },
  computed: {
    hasCustomActive() {
      return this.activeTab && Boolean(Object.keys(this.activeTab).length);
    },
    dynamicLineStyle() {
      return this.tabStyleProps;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.modernUi) {
        this.setActiveModernLine();
        this.setResizeObserver();
      }
    });
  },
  methods: {
    getRouteParams(tab) {
      const params = {
        ...this.$route.params,
        ...tab.routeParams,
      };
      return params;
    },
    getRouteQuery(tab) {
      const query = {
        ...this.$route.query,
        ...tab.routeQuery,
      };
      return query;
    },
    isActiveTab(tab) {
      if (this.hasCustomActive) {
        return (
          (tab.name && tab.name === this.activeTab?.name) ||
          (tab.label && tab.label === this.activeTab?.label) ||
          JSON.stringify(tab) === JSON.stringify(this.activeTab)
        );
      }
      return false;
    },
    isTabInactive(tab) {
      return tab.isInactive;
    },
    onTabClick(tab) {
      this.setActiveModernLine();

      this.$emit("tab-clicked", tab);
    },
    setActiveModernLine() {
      if (this.modernUi) {
        const parentOffsetLeft = this.$refs.uniTabs?.offsetLeft || 0;
        const el = this.$el.querySelector(".is-active > span");
        if (el) {
          this.tabStyleProps = {
            width: `${el.offsetWidth}px`,
            left: `${parentOffsetLeft + el.offsetLeft}px`,
          };
        } else {
          console.warn(
            "Tab Element not found!",
            parentOffsetLeft,
            this.$route.path,
            this.$refs.uniTabs.querySelectorAll("*")
          );
        }
      }
    },
    setResizeObserver() {
      if (this.modernUi) {
        const divElem = this.$refs.uniTabs;
        if (divElem) {
          const resizeObserver = new ResizeObserver((entries) => {
            this.setActiveModernLine();
          });

          resizeObserver.observe(divElem);
        }

        window.onresize = _.debounce(this.modernResizeLogic, 500);
      }
    },
    modernResizeLogic(evt) {
      this.setActiveModernLine();
    },
  },
};
</script>
<style lang="scss" scoped>
.uni-tabs {
  $gray-color: #7c7d86;

  user-select: none;
  align-items: stretch;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  // overflow: hidden;
  // overflow-x: auto;
  white-space: nowrap;
  position: relative;

  a {
    width: 100%;
    text-align: center;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    gap: 5px;

    align-items: center;
    justify-content: center;
    color: $gray-color;
    margin-bottom: -1px;
    padding: 0.5em 0;

    &.inactive {
      opacity: 0.4;
    }

    &:hover {
      color: lighten($color: $gray-color, $amount: 10);
      transition: all 0.2s ease;
    }

    .line {
      height: 3px;
      width: 100%;
      background-color: $gray-color !important;
    }
  }

  @mixin colorTab {
    color: var(--color-primary-10) !important;

    .line {
      background-color: var(--color-primary-10) !important;
    }
  }

  a {
    &.is-active.native-active:not(.is-custom-active) {
      @include colorTab;
    }

    &.is-custom-active {
      @include colorTab;
    }
  }

  &::-webkit-scrollbar {
    height: 0.7em;
  }
}
</style>
<style lang="scss">
.modern-ui-22 {
  .uni-tabs-wrap {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }

  .uni-tabs {
    display: inline-flex;
    gap: 6em;

    a {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }

  @mixin colorTab {
    color: var(--color-primary-10) !important;

    .line {
      background-color: var(--color-primary-10) !important;
    }
  }

  .line-wrapper {
    display: flex;
    height: 3px;
    width: 100%;
    margin-top: 8px;

    .line {
      position: relative;
      height: 3px;
      transition: all 0.3s ease;
      background-color: var(--color-primary-10) !important;
    }
  }
}
</style>
