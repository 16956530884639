<template lang="pug">
#client-contacts-default-page
  .sticky-top
    .field.right
      button.button.is-success.is-caps-lock.is-tiny(
        v-if="gPermissions.canAddContact",
        @click="onAdd"
      ) Add new
  section
    client-contacts-table(
      :loading="loading",
      :contacts="contacts",
      :contact-types="contactTypes",
      :includes="includes.join(',')",
      @patch-user="onPatchUser",
      @contact-edit="onContactEdit"
    )
    pagination(
      v-if="pagination",
      :total-pages="pagination.total_pages",
      :current-page="currentPage",
      @page-changed="onPageHasChanged"
    )
  modal(
    v-if="modalVisible",
    :title="modalTitle",
    icon="",
    :size="modalSize",
    :scrollable="true",
    @close="modalVisible = false"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false",
      @refresh-data="onRefreshData"
    )
</template>

<script>
import _ from "underscore";
import ClientContactsTable from "./ClientContactsTable.vue";
import ClientContactAdd from "./ClientContactAdd.vue";
import ClientContactEdit from "./ClientContactEdit.vue";
import Pagination from "../../Pagination.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ClientContactsInfo",
  components: {
    ClientContactsTable,
    ClientContactAdd,
    ClientContactEdit,
    Pagination,
  },
  data() {
    return {
      searchName: "",
      contacts: [],
      contactTypes: [],
      loading: false,
      currentPage: 1,
      includes: ["locations"],
      selectedSortColumn: {
        column: "name", // Back requires this sort for double sorting
        // column: 'status',
        // column: 'active',
        direction: "asc",
      },
      modalSize: 750,
      modalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      pagination: "getPagination",
    }),
    clientId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.fetchContacts({});
    this.fetchContactTypes({ getAllContactTypes: true });
  },
  methods: {
    ...mapActions({
      getClientContactsList: "getClientContactsList",
      getClientContactsTypesList: "getClientContactsTypesList",
    }),
    async fetchContacts({ page = 1 }) {
      this.currentPage = page;
      this.$set(this.$route.query, "page", page);

      const fetchParams = {
        statusOverrideSort: true, // Special sort by >> ['action', 'name']
        sort_by_field: this.selectedSortColumn.column || null,
        sort_by_direction: this.selectedSortColumn.direction || null,
        client_id: this.clientId,
        include: this.includes.join(","),
        name: this.searchName || null,
        page,
      };

      const formatResponseContacts = (contactArr) => {
        return contactArr.map((ct) => {
          return {
            ...ct,
            note: ct.note || "",
          };
        });
      };

      try {
        this.loading = true;
        const res = await this.getClientContactsList(fetchParams);
        this.contacts = formatResponseContacts(res.data.data);
        this.loading = false;
      } catch (err) {
        console.log("Error", err.message);
        this.loading = false;
      }
    },
    async fetchContactTypes(params = {}) {
      try {
        const res = await this.getClientContactsTypesList(params);
        this.contactTypes = res.data.data;
      } catch (err) {
        console.log(err.message);
      }
    },
    onCallSearchFilter: _.debounce(function () {
      this.callSearchApi();
    }, 500),
    onPatchUser() {
      const payload = {
        page: this.currentPage,
      };
      this.fetchContacts(payload);
    },
    async onAdd() {
      await this.fetchContactTypes({ getAllContactTypes: true });
      this.showModal(
        "client-contact-add",
        {
          client_id: this.clientId,
          contactTypes: this.contactTypes,
        },
        `Create a new contact`,
        450
      );
    },
    async onContactEdit(payload) {
      await this.fetchContactTypes({ getAllContactTypes: true });
      this.showModal(
        "client-contact-edit",
        {
          client_id: this.clientId,
          contact: payload.contact,
          contactTypes: this.contactTypes,
        },
        `Edit a contact`,
        450
      );
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = "750"
    ) {
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalSize = modalSize;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    onRefreshData() {
      this.fetchContacts({ page: this.currentPage });
      this.modalVisible = false;
    },
    onPageHasChanged(pageNum) {
      this.currentPage = pageNum;
      this.fetchContacts({ page: this.currentPage });
    },
    async callSearchApi() {
      this.fetchContacts({});
    },
  },
};
</script>

<style lang="scss" scoped>
#client-contacts-default-page {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 100%;

  .sticky-top {
    display: flex;
    // width: 100%; // Don't use max width
    position: absolute;
    right: 0;
    top: -40px;

    .right {
      margin-left: auto;
    }
  }
}
</style>
