<script>
import JournalTable from "../../Journals/JournalTable.vue";
export default {
  components: {
    JournalTable,
  },
  props: [
    "id",
    "journalList",
    "subjectTypes",
    "clients",
    "tempId",
    "clientId",
    "journalPagination",
    "tableFilter",
    "isLoadingJournals",
  ],
  computed: {
    isTempPage() {
      return Boolean(this.tempId);
    },
    isClientPage() {
      return Boolean(this.clientId);
    },
    canViewPrivateNotesPerm() {
      if (this.isClientPage) {
        return this.gPermissions.canJournalClientViewPrivate;
      } else if (this.isTempPage) {
        return this.gPermissions.canJournalTempViewPrivate;
      }
      return false;
    },
    canViewPrivateNotesLogType() {
      return this.isSystemJournal ? false : true;
    },
    isSystemJournal() {
      return (
        this.$route.name === "CandidateJournalsSystem" ||
        this.$route.name === "ClientCandidateJournalsSystem"
      );
    },
  },
  methods: {
    onChangeFilterPrivateTable(evt) {
      this.$emit("change-filter-private", evt);
    },
  },
};
</script>
<template lang="pug">
.journal-table
  template(v-if="canViewPrivateNotesPerm && canViewPrivateNotesLogType")
    label.label Include private notes
      input(
        :checked="tableFilter.isprivate",
        type="checkbox",
        style="margin-left: 10px",
        @change="onChangeFilterPrivateTable"
      )
  .spinner-wrap(v-if="isLoadingJournals")
    img(src="../../../assets/images/comps/loader.svg")
  journal-table(
    v-else,
    :journalList="journalList",
    :subjectTypes="subjectTypes",
    :clients="clients",
    :temp-id="tempId",
    :client-id="clientId",
    :journalPagination="journalPagination",
    key="manual",
    @edit-row="$emit('edit-row', $event)",
    @file-check="$emit('file-check', $event)",
    @show-html-body="$emit('show-html-body', $event)",
    @clicked-journal-page="$emit('clicked-journal-page-manual-logs', $event)"
  )
</template>
<style lang="scss">
.journal-table {
  width: 100%;
  padding: 0 0 20px 0;
}
</style>
