<template lang="pug">
.expense-details-wrap
  table.table.is-bordered(v-if="expense")
    tbody
      tr(v-if="canViewFields.canViewId")
        th ID
        td {{ expense.id }}
      tr(v-if="canViewFields.canViewClient")
        th Client Name
        td {{ expense.client && expense.client.name }}
      tr(v-if="canViewFields.canViewTemp")
        th Temp Name
        td {{ expense.temp.name }}
      tr(v-if="canViewFields.canViewDate")
        th Date
        //- th Date Of Expense
        td {{ parseDate(expense.date) }}
      tr(v-if="canViewFields.canViewStartTime")
        th Start Time
        //- th Date Of Expense
        td {{ parseDate(expense.startTime) }}
      tr(v-if="canViewFields.canViewEndTime")
        th End Time
        td {{ parseDate(expense.endTime) }}
      tr(v-if="canViewFields.canViewType")
        th Type
        td {{ expense.expenseType && expense.expenseType.label }}
      tr(v-if="canViewFields.canViewEngineType")
        th Engine type
        td {{ expense.engine_type }}
      tr(v-if="canViewFields.canViewDesc")
        th Description
        td {{ expense.description }}
      tr(v-if="canViewFields.canViewLocationFrom")
        th Location From
        td {{ expense.locationfrom }}
      tr(v-if="canViewFields.canViewLocationTo")
        th Location To
        td {{ expense.locationto }}
      tr(v-if="canViewFields.canViewLocation")
        th Location
        td {{ expense.location }}
      tr(v-if="canViewFields.canViewDistance")
        th Distance
        td {{ parseDistance(expense.distance) }}
      tr(v-if="canViewFields.canViewStatus")
        th Status
        td {{ expense.status }}
      tr(v-if="canViewFields.canViewApprovedBy")
        th Approver
        td {{ expense.userProcessed.name }}
      tr(v-if="canViewFields.canViewRejectedBy")
        th Rejected by
        td {{ expense.userRejected.name }}
      tr(v-if="canViewFields.canViewReason")
        th Reason
        td {{ expense.reason }}
      tr(v-if="canViewFields.canViewRejectReason")
        th Reject reason
        td {{ expense.rejectreason }}
      tr(
        v-if="canViewFields.canViewInitialAmount"
        class="initial-value"
      )
        th Provisionally calculated amount
        td {{ parseExpenseValue(expense.old_value) }}
      tr(v-if="canViewFields.canViewAmountPaid")
        th {{ getAmountLabel }}
        td {{ parseExpenseValue(expense.value) }}
      tr(v-if="canViewFields.canViewCreatedType")
        th Submitted at
        td {{ parseDate(expense.created_at) }}
  .button-line
    button.button.is-generic-app-blue(@click="closeModal") Close
</template>

<script>
import moment from "moment";
export default {
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  computed: {
    expense() {
      return this.modalProps.expense;
    },
    parentType() {
      return this.modalProps.parentType;
    },
    activeTab() {
      return this.modalProps.activeTab;
    },
    getAmountLabel () {
      switch(this.activeTab.id) {
        case 1: return 'Amount';
        case 2: return 'Approved amount';
        case 3: return 'Rejected amount';
        default: return 'Amount';
      }
    },
    canViewFields() {
      return {
        canViewId: true,
        canViewClient: true,
        canViewTemp: true,
        canViewDate: 'date' in this.expense,
        canViewStartTime: 'startTime' in this.expense,
        canViewEndTime: 'endTime' in this.expense,
        canViewType: true,
        canViewDesc: "description" in this.expense,
        canViewLocationFrom: "locationfrom" in this.expense,
        canViewLocationTo: "locationto" in this.expense,
        canViewLocation: "location" in this.expense,
        canViewApprovedBy: "userProcessed" in this.expense,
        canViewRejectedBy: "userRejected" in this.expense,
        canViewReason: "reason" in this.expense,
        canViewRejectReason: "rejectreason" in this.expense,
        canViewDistance: "distance" in this.expense,
        canViewInitialAmount: "old_value" in this.expense,
        canViewAmountPaid: true,
        canViewStatus: true,
        canViewEngineType: 'engine_type' in this.expense,
        canViewCreatedType: 'created_at' in this.expense,
      };
    },
  },
  methods: {
    closeModal() {
      this.$emit("dispose-modal");
    },
    parseDate(dateStr, includeTime = true) {
      const timeFormat = includeTime ? " HH:mm" : "";
      return moment(dateStr).format(`DD-MM-YYYY${timeFormat}`);
    },
    parseExpenseValue(val) {
      const sfMonetaryValue = this.initialStatus.monetary_symbol;
      const shouldAddDecimals = val % 1 !== 0;
      const formatedVal = shouldAddDecimals ? val.toFixed(2) : val;
      return `${sfMonetaryValue} ${formatedVal || 0}`;
    },
    parseDistance(distance) {
      return distance? `${distance} km` : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.expense-details-wrap {
  display: flex;
  flex-direction: column;
  .button-line {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  table {
    .initial-value {
      background-color: lightyellow;
    }
  }
}
</style>
