<template lang="pug">
.edit-comp
  transition(name="fade")
    modal(
      v-if="modalVisible",
      @close="modalVisible = false",
      :title="modalTitle",
      icon="",
      :size="modalSize",
      :scrollBlock="modalAdditionalParams.scrollBlock",
      :scrollable="false"
    )
      component(
        :is="inmodal",
        :modalProps="modalData",
        @save-next-of-kin="onSaveNextOfKin",
        @cancel-modal="modalVisible = false"
      )
  section.section
    h1.title.is-3
      i.fa.icon-Edit-profile
      | Edit Profile
    form.mt(autocomplete="off", @keydown="errors.clear($event.target.name)")
      .lost-center
        .lost-shift
          p.control(data-cell="name")
            label.label Name
            input.input.is-medium(
              v-model="user.name",
              type="text",
              placeholder="NAME",
              disabled
            )
          p.control(data-cell="email")
            label.label Email
            input.input.is-medium(
              v-model="user.email",
              type="text",
              placeholder="EMAIL",
              autocomplete="off",
              disabled
            )
          p.control
            label.label Gender
            multi-select(
              :options="genderOptions",
              :close-on-select="true",
              track-by="key",
              label="label",
              select-label="",
              selected-label="",
              deselect-label="",
              :value="getSelectedGender",
              placeholder="Select gender",
              @select="onSelectGender"
            )
            span.help.is-danger(
              v-if="errors.has('gender')",
              v-html="errors.get('gender')"
            )
          p.control(data-cell="home-address")
            label.label Home Address
            input.input.is-medium(
              v-model="user.homeAddress",
              type="text",
              placeholder="HOME ADDRESs"
            )
          p.control(data-cell="agency-worker")
            label.label Agency Worker #:
            input.input.is-medium(
              v-model="user.id",
              :disabled="true",
              type="text",
              placeholder="WORKER NUMBER",
              disabled
            )
          p.control(data-cell="date-of-birth")
            label.label Date of birth:
            input.input.is-medium(
              v-model="user.dateOfBirth",
              type="text",
              placeholder="DATE OF BIRTH",
              disabled
            )
            span.help.is-danger(
              v-if="errors.has('date_of_birth')",
              v-html="errors.get('date_of_birth')"
            )
          p.control(data-cell="mobile")
            label.label Mobile:
            input.input.is-medium(
              v-model="user.mobilePhone",
              type="text",
              placeholder="MOBILE"
            )
          p.control(data-cell="phone")
            label.label Phone:
            input.input.is-medium(
              v-model="user.homePhone",
              type="text",
              placeholder="PHONE"
            )
          p.control(data-cell="eircode")
            label.label {{ getEirCodeLabelKey }}:
            input.input.is-medium(
              v-model="user.eircode",
              type="text",
              :placeholder="getEirCodeLabelKey",
              disabled
            )
          p.control(data-cell="tss")
            label.label External system id number:
            input.input.is-medium(
              v-model="user.tssId",
              type="text",
              placeholder="External system id number",
              disabled
            )
          p.control(data-cell="subcategories")
            label.label Subcategories:
            multi-select(
              :options="optionsSubcategories",
              :disabled="true",
              :multiple="true",
              :close-on-select="false",
              track-by="id",
              label="name",
              group-label="category",
              group-values="subs",
              v-model="selectedSubcategories",
              placeholder="PICK A SUBCATEGORY",
              @select="errors.clear('subcategories')"
            )
            span.help.is-danger(
              v-if="errors.has('categories')",
              v-text="errors.get('categories')"
            )
          p.control(data-cell="specialities")
            label.label Specialities:
            multi-select(
              :options="optionsSpecialities",
              :disabled="true",
              :multiple="true",
              :close-on-select="false",
              track-by="id",
              label="name",
              v-model="selectedSpecialities",
              placeholder="PICK A FEW"
              @select="errors.clear('speciality')",
            )
            span.help.is-danger(
              v-if="errors.has('speciality')",
              v-text="errors.get('speciality')"
            )
          .control(v-if="gPermissions.canRelativeView")
            label.label Next of kin:
            template(v-for="relativePerson in user.relatives")
              .blue-button-action(@click="onAddNewRelative(relativePerson)") 
                span.label(:title="relativePerson.name") {{ (relativePerson.name || "").slice(0, 50) }}
            .blue-button-action(
              @click="onAddNewRelative",
              v-if="!user.relatives || user.relatives.length === 0"
            )
              span.label Add new
          p.control
          button.button.is-generic-app-teal.is-fullwidth.is-caps-lock(
            @click.prevent="submitEdit",
            :class="{ 'is-disabled': false }"
          )
            i.fa.fa-check
            | Submit
          profile-shifts-table(:logs="userInfo.shiftLogs")
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import MultiSelect from "vue-multiselect";
import ProfileShiftsTable from "../tables/ProfileShiftsTable.vue";
import {
  pickNonFalsyKeepZero,
  getFormattedTime,
} from "../../lib/helpers/function.js";
import { Errors } from "../../lib/helpers/Errors.js";
import { default as axios } from "../../api/config.js";
import ManageNextOfKin from "../in-modal/all-candidates/ManageNextOfKin.vue";
import { GENDER_MAP } from "../../shared/mapData.js";

export default {
  name: "editProfileTemp",
  components: {
    MultiSelect,
    ProfileShiftsTable,
    ManageNextOfKin,
  },
  data() {
    return {
      user: {},
      selectedSpecialities: [],
      selectedSubcategories: [],
      errors: new Errors(),
      genderOptions: GENDER_MAP,
      modalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      /**
       * Options for vue-multiselect
       */
      optionsSubcategories: "getCategoriesWithSubcategories",
      /**
       * Options for vue-multiselect
       */
      optionsSpecialities: "getAllSpecialities",
      /**
       * My profile
       */
      userInfo: "getUserProfile",
    }),
    getEirCodeLabelKey() {
      return this.$store.getters.getColumnNameLocl("usr_eircode");
    },
    getSelectedGender() {
      if (!this.user.gender) {
        return null;
      }
      return this.genderOptions.find((go) => go.key === this.user.gender);
    },
  },
  created() {
    this.fetchTempData();

    this.fetchAllCategoriesList();
    this.fetchAllSpecialitiesList();
  },
  methods: {
    ...mapActions([
      "fetchAllCategoriesList",
      "fetchAllSpecialitiesList",
      "fetchUserProfile",
    ]),

    resetCategories() {
      console.log("reset categories");
      this.$store.commit("CLEAR_CATEGORIES");
      this.selectedSubcategories = null;
    },
    fillFields() {
      this.user = this.userInfo;
      this.selectedSubcategories = this.userInfo.subcategories;
      this.selectedSpecialities = this.user.specialities;
    },
    formatDateLocToIso(isoDate) {
      return getFormattedTime(isoDate, "YYYY-MM-DD", "DD/MM/YYYY");
    },
    async submitEdit() {
      let postParams = {
        id: this.user.id,
        name: this.user.name,
        home_address: this.user.homeAddress,
        mobile_phone: this.user.mobilePhone,
        home_phone: this.user.homePhone,
        eircode: this.user.eircode,
        date_of_birth: this.formatDateLocToIso(this.user.dateOfBirth),
        subcategories: this.selectedSubcategories,
        specialities: this.selectedSpecialities,
        gender: this.user.gender,
      };

      if (this.gPermissions.canRelativeView) {
        postParams.relatives = this.user.relatives;
      }

      console.log(postParams);

      try {
        const data = axios.patch("profile", pickNonFalsyKeepZero(postParams));
        console.log(data);
        this.$toasted.info("Profile updated!").goAway(1500);
      } catch (error) {
        this.errors.record(error.response.data.errors);
      }
    },
    async fetchTempData() {
      const includes = [
        "subcategories",
        "locations",
        "specialities",
        "shiftLogs",
        "shiftLogs.shift.location.client",
        "preferredRegions",
      ];

      if (this.gPermissions.canRelativeView) {
        includes.push("relatives");
      }
      const paramsIncludes = includes.join(",");

      try {
        await this.fetchUserProfile({
          include: paramsIncludes,
        });
        this.fillFields();
      } catch (err) {
        // ignored
      }
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = "800",
      params = {}
    ) {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalSize = modalSize;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalAdditionalParams = params;
      this.modalVisible = true;
    },
    onAddNewRelative(relativePerson) {
      console.log("Person clicked", relativePerson);
      const payload = {
        relatives: JSON.parse(JSON.stringify(this.user.relatives)),
        person: relativePerson,
      };
      this.showModal(
        "manage-next-of-kin",
        payload,
        "Next of kin management",
        750,
        false
      );
    },
    onSaveNextOfKin(evt) {
      this.modalVisible = false;
      this.user.relatives = evt;
    },
    onSelectGender(evt) {
      this.$set(this.user, "gender", evt.key);
      this.errors.clear("gender");
    },
  },
};
</script>
<style lang="scss">
$lightgray: #7c91ae;
$border-color: #9bb4c1;

.edit-comp {
  form.mt {
    .label {
      color: $lightgray;
      font-size: 12px;
    }
    .input {
      box-sizing: border-box;
      border: 1px solid $border-color;
      border-radius: 4px;
      box-shadow: none;
    }
  }
}
</style>
