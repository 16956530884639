<template lang="pug">
#notification-filters-used
  .content-wrap
    .textarea(
      v-html="modalProps"
    )
  .button-line
    button.button.is-generic-app-blue.is-caps-lock(
      @click.prevent="$emit('dispose-modal');"
    )
      | Cancel
</template>

<script>
export default {
  props: {
    modalProps: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#notification-filters-used {
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 35vh;
  padding: 1em 0 2em 0;
  .content-wrap {
    display: flex;
    flex-direction: column;
    .textarea {
      height: 500px;
      overflow: auto;
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
  h2 {
    text-align: center;
  }
}
</style>

<style lang="scss">
.custom-filters-list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  ul {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      padding-left: 10px;
      list-style-type: none;
    }
  }

  label {
    border-bottom: 1px solid silver;
  }
}
</style>
