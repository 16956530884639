<template lang='pug'>
#category-tree
  ul
    li(v-for="(category, index) in categories", :key="index")
      category-tree-node(
        :category="category",
        :node-select-evt="nodeSelectEvt",
        :selected-category="selectedCategory",
        :is-parent="true",
        @change="$emit('change', $event)",
        @edit-category="$emit('edit-category', $event)"
      )
</template>

<script>
import CategoryTreeNode from './CategoryTreeNode.vue';
export default {
  components: {
    CategoryTreeNode,
  },
  props: {
    nodeSelectEvt: Function,
    categories: {
      type: Array,
      required: true,
    },
    selectedCategory: [Object, null],
  },
};
</script>

<style lang="scss" scoped>
#category-tree {
  overflow: auto;
}
</style>