<template lang='pug'>
#security-journals-page
  .table-wrap
    security-journal-notes-table(
      :isClient="true"
    )
    security-journal-notes-table(
      :isClient="false"
    )
</template>

<script>
import SecurityJournalNotesTable from './SecurityJournalNotesTable.vue';
export default {
  components: {
    SecurityJournalNotesTable,
  },
};
</script>

<style lang="scss" scoped>
#security-journals-page {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  .table-wrap {
    display: flex;
    padding: 1em 0;
    width: 70%;
  }
}
</style>