<template lang='pug'>
.client-copy-cost-center
  .header-title
    span Are you sure you want to copy ward?
  .input-wrap
    label Enter a new name for ward
    input.input(
      v-model="params.name",
      @keyup.enter="onCreate",
      placeholder="Enter a new name for ward",
      type="text"
    )
  .button-wrap
    button.button.is-generic-app-blue(@click="onCreate") SAVE
    button.button.is-generic-app-blue(@click="$emit('cancel-modal')") CANCEL
</template>

<script>
import { mapActions } from 'vuex';
import { parseErrors } from '../../lib/helpers/function';
export default {
  props: ['modalProps'],
  data () {
    return {
      params: {
        // Backend expects [has_allowance], but has [hasLocationAllowance]
        // Backend expects snake case, gives camel case
        clientId: this.modalProps.client.id,
        id: this.modalProps.location.id,
        name: this.modalProps.location.name,

        // has_community_allowance: this.modalProps.location.hasCommunityAllowance || false,
        // has_allowance: this.modalProps.location.hasLocationAllowance || false,
        // has_secure_allowance: this.modalProps.location.hasSecureAllowance || false,
        // subcategories: this.getSubcategories(this.modalProps.location.categories) || [],
        // temps: this.modalProps.location.temps || [],
        // users: this.modalProps.location.users || [],
      },
    };
  },
  methods: {
    ...mapActions({
      'createCostCenterForClient': 'locationClientManagement/createCostCenterForClient',
      'postCloneLocation': 'locationClientManagement/postCloneLocation',
    }),
    async onCreate () {
      const params = { ...this.params };
      try {
        await this.postCloneLocation(params);
        this.$toasted.info('Ward copied successfully!').goAway(1500);
        this.$emit('cancel-modal');
      } catch (err) {
        console.warn(err.message);
        let errs = parseErrors(err);
        this.$toasted.error(errs).goAway(3500);
      }
    },
    getSubcategories (categories) {
      return categories.map(cat => cat.subcategories).flat();
    },
  },
};
</script>

<style lang="scss" scoped>
.client-copy-cost-center {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;

  .header-title {
    text-align: center;
  }

  .input-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .button-wrap {
    display: flex;
    gap: 15px;
    justify-content: center;
  }
}
</style>