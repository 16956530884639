<template lang="pug">
#notitcation-centre-save-templates
  .template-name
    h2 Choose a template name
    input.input.is-medium(
      v-model="templateName"
    )
  .template-name
    h2 Template subject
    input.input.is-medium(
      :value="modalProps.subject"
      disabled
    )
  .content-wrap
    h2 Preview template content
    .textarea(
      v-html="modalProps.message"
    )
  .files(
    v-if="modalProps.emailAttachments.length > 0"
  )
    h2 Attachments
    .attachments
      .attachment(
        v-for="(file, index) in modalProps.emailAttachments"
      )
        span {{ file.name }}
  .button-line
    button.button.is-generic-app-blue.is-caps-lock(
      v-if="templateName !== ''"
      @click.prevent="onSaveTemplate"
    )
      | Save template
    button.button.is-generic-app-blue.is-caps-lock(
      @click.prevent="$emit('dispose-modal');"
    )
      | Cancel
</template>

<script>
export default {
  props: {
    modalProps: {
      required: true,
    },
  },
  data () {
    return {
      templateName: '',
      subject: '',
    };
  },
  methods: {
    onSaveTemplate () {
      this.$emit("save-template", {
        name: this.templateName,
      });
      this.$emit("dispose-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
#notitcation-centre-save-templates {
  display: flex;
  flex-direction: column;
  gap: 3em;
  min-height: 35vh;
  padding: 1em 0 2em 0;
  .template-name {
    display: flex;
    flex-direction: column;
  }
  .content-wrap {
    display: flex;
    flex-direction: column;
    .textarea {
      height: 300px;
      overflow: auto;
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
  // h2 {
  //   text-align: center;
  // }
  textarea {
    resize: none;
  }
}
</style>