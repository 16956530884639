<template lang="pug">
#expense-rates
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .filters
    .group
      label.label SELECT EXPENSE TYPE
      multi-select(
        :options="userPaymentRateTypesConfig",
        select-label="",
        deselect-label="",
        track-by="code",
        label="label",
        :allow-empty="false",
        :multiple="false",
        placeholder="SELECT EXPENSE TYPE",
        :value="selectedExpenseType",
        @select="onSelectExpenseType",
        @remove="onRemoveExpenseType"
      )
  .top(v-if="selectedExpenseType")
    .bar.left
      template(
        v-if="isShouldShowRates && getMaxEffectiveDate && !isLoadingMaxEffectiveDate && canDisplayColumn('maxEffectiveDate')"
      )
        p.control
          label.label Max effective date
          span {{ getMaxEffectiveDate }}
      .loading-wrapper.mer(v-if="isLoadingMaxEffectiveDate")
        img(src="@/assets/images/comps/loader.svg")
    .bar.right(v-if="isShouldShowRates")
      //- .switch-placeholder
      //-   .control.action.switch-changed-only
      //-     .switch-wrap
      //-       label.lbl Show changed rates only
      //-       switches(
      //-         v-model="isShowChangedOnly",
      //-         :disabled="isChangingRowsDisplay",
      //-         theme="bulma",
      //-         color="blue",
      //-         :emitOnMount="false"
      //-       )
      //-     .loader(v-if="isChangingRowsDisplay")
      //- template(v-if="isAddingNewRow")
      //-   p.control.action
      //-     button.button.is-generic-app-teal(
      //-       @click="saveRow",
      //-       title="Apply and save pending row ('CTRL + ALT + S')"
      //-     ) Save
      //-   p.control.action
      //-     button.button.is-generic-app-teal(@click="isAddingNewRow = false") Cancel
      //- p.control.action.action-2
      //-   button.button.is-generic-app-teal(
      //-     :disabled="isAddingNewRow",
      //-     title="Create a new rate ('CTRL + ALT + N')",
      //-     @click="addNewRateRow"
      //-   )
      //-     img(src="@/assets/images/comps/Plus.svg")
      //-     span(style="margin-left: 5px") New Rate

      p.control.action
        button.button.is-danger(
          v-if="canFinalizeChanges",
          @click="onDeleteTable"
        ) Delete rate
      p.control.action
        button.button.is-generic-app-teal(
          v-if="isChangedForm",
          @click="onResetChanges"
        ) Undo changes
      p.control.action
        button.button.is-generic-app-teal(
          :disabled="!isChangedForm",
          @click="onApplyChanges"
        ) Update changes
      p.control.action
        button.button.is-generic-app-teal(
          :disabled="!canFinalizeChanges",
          @click="onFinalizeChanges"
        ) Finalise changes

  .table-wrap
    rates-expenses-table(
      :table-data="activeTableDataInstance",
      :selectedExpenseType="selectedExpenseType",
      :dataIsLoading="dataIsLoading",
      :availableHeaders="availableHeaders",
      :availableRows="availableRows",
      @add-row="addNewRow",
      @add-column="addNewColumn",
      @remove-row="onRemoveRow",
      @remove-column="onRemoveColumn",
      @form-changed="isChangedForm = true",
      @column-range-changed="onChangedColumnRange"
    )
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    icon="",
    size="650",
    :scrollable="true"
  )
    component(
      :is="inmodal",
      :uptId="getIdFromCode",
      :uptCode="selectedExpenseType && selectedExpenseType.code",
      @cancel-modal="modalVisible = false",
      @rates-finalized="onRatesFinalized"
    )
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions } from "vuex";
import {
  momentDateTimeFromApi,
  parseErrors,
} from "../../../lib/helpers/function";
import ExpensesManageFinalize from "./Expenses/ExpensesManageFinalize.vue";
import RatesExpensesTable from "./Expenses/RatesExpensesTable.vue";
import { ExpensesTableData } from "./Expenses/expensesDataClass.ts";
import {
  ExpensesTableColumn,
  ExpensesTableRow,
} from "./Expenses/expensesDataClass";

export default {
  components: {
    MultiSelect,
    ExpensesManageFinalize,
    RatesExpensesTable,
  },
  data() {
    return {
      userPaymentRateTypesConfig: [],
      userPaymentRateTables: [],
      expenseTypes: {},
      dataIsLoading: false,
      maxEffectiveDate: null,
      isLoadingMaxEffectiveDate: false,
      isChangingRowsDisplay: false,
      isAddingNewRow: false,
      modalVisible: false,
      isChangedForm: false,
      isShowChangedOnly: false,
      fixedChangedDate: "3000-01-01 00:00:00",
    };
  },
  computed: {
    selectedExpenseType() {
      const pathCode = this.$route.params?.code;
      return (
        this.userPaymentRateTypesConfig.find(
          (item) => item.code === pathCode
        ) || null
      );
    },
    filterExpenseTypes() {
      return this.expenseTypes.children
        ? this.getRecChildren(this.expenseTypes.children)
        : [];
    },
    getIdFromCode() {
      return this.selectedExpenseType
        ? this.filterExpenseTypes.find(
            (it) => it.upt_code === this.selectedExpenseType.code
          )?.upt_id
        : null;
    },
    userPaymentsRatesRow() {
      return this.activeTableDataInstance?.rate_values || [];
    },
    isShouldShowRates() {
      return true;
    },
    canFinalizeChanges() {
      return (
        this.userPaymentRateTables?.length &&
        this.userPaymentRateTables.some((table) => {
          return table.upr_from === this.fixedChangedDate;
        })
      );
    },
    activeTableDataInstance() {
      const rTables = this.userPaymentRateTables;
      if (rTables.length === 1) {
        return rTables[0];
      } else if (rTables.length > 1) {
        // Returns only the active table
        return (
          this.userPaymentRateTables.find(
            (table) => table.upr_from === this.fixedChangedDate
          ) || {}
        );
      }
      return {};
    },
    getMaxEffectiveDate() {
      let mad = "";
      if (this.maxEffectiveDate) {
        mad = this.formatDateToLocal(this.maxEffectiveDate);
      } else if (this.activeTableDataInstance?.upr_from) {
        if (this.activeTableDataInstance.upr_from !== this.fixedChangedDate) {
          mad = this.formatDateToLocal({
            date: this.activeTableDataInstance.upr_from,
          });
        }
      }
      return mad !== this.fixedChangedDate ? mad : "";
    },
    availableHeaders() {
      const definition = this.selectedExpenseType?.rate_info.column;
      if (!definition) return [];

      const keysToIgnore = ["urv_value"];
      const availableKeys = Object.entries(definition).reduce((acc, curr) => {
        if (curr[1] && !keysToIgnore.includes(curr[0])) {
          acc.push(curr[0]);
        }
        return acc;
      }, []);
      return availableKeys;
    },
    availableRows() {
      const definition = this.selectedExpenseType?.rate_info.row;
      if (!definition) return [];

      const keysToIgnore = ["urv_value"];
      const availableKeys = Object.entries(definition).reduce((acc, curr) => {
        if (curr[1] && !keysToIgnore.includes(curr[0])) {
          acc.push(curr[0]);
        }
        return acc;
      }, []);
      return availableKeys;
    },
  },
  watch: {
    selectedExpenseType(val) {
      if (val) {
        this.onResetChanges();
      }
    },
  },
  mounted() {
    this.fetchExpensesConfig();
    this.fetchExpensesTypes();
  },
  methods: {
    ...mapActions({
      getUserPaymentRatesConfig: "getUserPaymentRatesConfig",
      getUserPaymentRates: "getUserPaymentRates",
      getExpensesTypes: "getExpensesTypes",
      getUserPaymentRatesMaxEffectiveDate:
        "getUserPaymentRatesMaxEffectiveDate",
      postUserPaymentRates: "postUserPaymentRates",
      deleteUserPaymentRates: "deleteUserPaymentRates",
    }),
    async fetchExpensesConfig() {
      const params = {};
      try {
        const res = await this.getUserPaymentRatesConfig(params);
        this.userPaymentRateTypesConfig =
          res.data.data?.expense_rate_types || [];
      } catch (err) {
        console.warn(err.message);
      }
    },
    async fetchUserPaymentData() {
      const params = {
        upt_code: this.selectedExpenseType?.code,
        upt_id: this.getIdFromCode,
      };
      this.dataIsLoading = true;
      try {
        const res = await this.getUserPaymentRates(params);
        const tablesData = res.data.data || [];
        this.userPaymentRateTables = tablesData.map((tableData) => {
          return new ExpensesTableData(tableData);
        });
        if (tablesData.length) {
          this.dataIsLoading = false;

          // Only if we need separate API
          // const hasAnyUnfinished = tablesData.some(
          //   (table) => table.upr_from === this.fixedChangedDate
          // );
          // if (hasAnyUnfinished) {
          //   this.maxEffectiveDate = null;
          // } else {
          //   await this.fetchEffectiveDate();
          // }
        } else {
          const tableInstance = new ExpensesTableData({
            upt_code: this.selectedExpenseType?.code,
            upt_id: this.getIdFromCode,
          });
          this.userPaymentRateTables.push(tableInstance);
          this.dataIsLoading = false;
        }
      } catch (error) {
        console.warn(error);
        this.dataIsLoading = false;
      }
    },
    async fetchExpensesTypes() {
      try {
        const res = await this.getExpensesTypes();
        this.expenseTypes = res.data.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async fetchEffectiveDate(payload = {}) {
      const params = {
        upt_code: this.selectedExpenseType?.code,
        upt_id: this.getIdFromCode,
        ...payload,
      };

      this.isLoadingMaxEffectiveDate = true;
      try {
        const res = await this.getUserPaymentRatesMaxEffectiveDate(params);
        this.maxEffectiveDate = res.data.upr_from;
        this.isLoadingMaxEffectiveDate = false;
      } catch (err) {
        this.isLoadingMaxEffectiveDate = false;
        this.maxEffectiveDate = null;
        console.log("Error", err.message);
      }
    },
    async removeUserPaymentRates() {
      const params = {
        // upt_code: this.selectedExpenseType?.code,
        upr_id: this.activeTableDataInstance.upr_id,
      };
      try {
        const res = await this.deleteUserPaymentRates(params);
        this.$toasted.success(res.data.message).goAway(2500);
        this.onResetChanges();
      } catch (err) {
        console.warn(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    onSelectExpenseType(val) {
      this.$router.replace({
        params: {
          code: val.code,
        },
      });
    },
    onRemoveExpenseType(val) {
      this.$router.replace({
        params: {
          code: null,
        },
      });
    },
    addNewColumn(index, heading = {}) {
      const nextElIndex = index + 1;
      const nextRangeObj = this.userPaymentsRatesRow?.[0]
        ? this.userPaymentsRatesRow[0].columns[index + 1] || {}
        : {};

      if ("urv_range2from" in heading && !heading.urv_range2to) {
        // Mutate current column to add column to
        this.activeTableDataInstance.updateColumnValue(
          index,
          "urv_range2to",
          heading.urv_range2from + 1
        );
      }

      const from =
        heading.urv_range2to != void 0
          ? heading.urv_range2to
          : heading.urv_range2from != void 0
          ? heading.urv_range2from
          : 0;
      const to =
        "urv_range2from" in nextRangeObj || nextRangeObj.urv_range2from > 0
          ? nextRangeObj.urv_range2from
          : null;

      const columnInstance = new ExpensesTableColumn(
        from,
        to,
        null,
        heading.urv_variant
      );
      this.activeTableDataInstance.addColumnInstance(
        nextElIndex,
        columnInstance
      );

      this.isChangedForm = true;
    },
    addNewRow(index, row = {}) {
      const nextElIndex = index + 1;
      const nextRangeObj = this.userPaymentsRatesRow?.[index + 1] || {};

      if ("urv_range1from" in row && !row.urv_range1to) {
        // Mutate current column to add column to
        this.$set(row, "urv_range1to", row.urv_range1from + 1);
      }

      const from =
        row.urv_range1to != void 0
          ? row.urv_range1to
          : row.urv_range1from != void 0
          ? row.urv_range1from
          : 0;
      const to =
        "urv_range1from" in nextRangeObj || nextRangeObj.urv_range1from > 0
          ? nextRangeObj.urv_range1from
          : null;

      const getColumns = () => {
        if (Array.isArray(row.columns)) {
          return (
            row.columns?.map(
              (column) =>
                new ExpensesTableColumn(
                  column.urv_range2from,
                  column.urv_range2to,
                  null,
                  column.urv_variant,
                  column.urv_id || null
                )
            ) || []
          );
        }

        const hasFrom = this.availableHeaders.includes("urv_range2from");
        const hasTo = this.availableHeaders.includes("urv_range2to");
        // const hasVariant = this.availableHeaders.includes("urv_variant");
        if (hasFrom && hasTo) {
          return [new ExpensesTableColumn(0, null, null, null)];
        } else {
          return [new ExpensesTableColumn(null, null, null, null)];
        }
      };
      const columns = getColumns();

      const rowInstance = new ExpensesTableRow(from, to, columns);
      this.activeTableDataInstance.addRowInstance(nextElIndex, rowInstance);

      this.isChangedForm = true;
    },
    onRemoveRow(index) {
      const isSuccess = this.activeTableDataInstance.removeRow(index);
      if (isSuccess) {
        this.isChangedForm = true;
      }
    },
    onRemoveColumn(index) {
      const isSuccess = this.activeTableDataInstance.removeColumn(index);
      if (isSuccess) {
        this.isChangedForm = true;
      }
    },
    onChangedColumnRange(obj) {
      this.activeTableDataInstance.updateColumnValue(
        obj.index,
        obj.key,
        obj.val
      );
    },
    confirmAction(title = ``) {
      return new Promise((resolve, reject) => {
        const alert = {
          title: title,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
    async onDeleteTable() {
      try {
        await this.confirmAction(
          "Are you sure you want to delete rates table?"
        );
        await this.removeUserPaymentRates();
      } catch (err) {
        // ignored
      }
    },
    onResetChanges() {
      this.fetchUserPaymentData();
      this.isChangedForm = false;
    },
    async onApplyChanges() {
      const params = {
        data: this.userPaymentRateTables,
      };
      try {
        const res = await this.postUserPaymentRates(params);
        this.$toasted.success(res.data.message).goAway(2500);
        this.onResetChanges();
      } catch (err) {
        console.log(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
        return false;
      }
    },
    async onFinalizeChanges() {
      // Initial custom checks to see if you can apply
      if (this.selectedExpenseType?.code === "ESUB") {
        const warningMsg =
          "Make sure that the reduced and detention rates are assigned only for the 24+ time band.";

        try {
          await this.confirmAction(warningMsg);
        } catch (err) {
          // ignored
        }
        // const hasSomeInvalidVariant =
        //   this.activeTableDataInstance.rate_values.some((row) => row.columns?.);
      }

      // FINALIZE
      this.isAddingNewRow = false;

      this.showModal(
        "expenses-manage-finalize",
        {},
        "Finalise and apply changes"
      );
    },
    canDisplayColumn(key) {
      return true;
    },
    formatDateToLocal(dateObj) {
      return momentDateTimeFromApi(dateObj.date, undefined, true);
    },
    getRecChildren(arr = []) {
      const selKey = "upt_selectable";
      if (Array.isArray(arr)) {
        const recSer = arr.reduce((acc, item) => {
          if (item[selKey]) {
            acc.push(item);
          }
          if (item.children) {
            const childArr = this.getRecChildren(item.children);
            acc.push(...childArr);
          }
          return acc;
        }, []);
        return recSer;
      }
      return [];
    },
    addNewRateRow() {
      this.isAddingNewRow = !this.isAddingNewRow;
    },
    async onRatesFinalized() {
      this.modalVisible = false;
      // this.fetchRateDataCo++;

      // Only if we need separate API
      // this.fetchEffectiveDate();
      this.onResetChanges();
    },
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    // async saveRow() {
    //   // POST
    //   // console.log('rowdata', this.rowData);

    //   const params = {};

    //   try {
    //     const res = await this.postUserPaymentRates(params);
    //     // this.addNewRowToTableAfterCreate(res.data.data);
    //     console.warn(res.data);
    //     this.$toasted.success("Rate has been created.").goAway(2500);
    //     this.isAddingNewRow = false;
    //     return true;
    //   } catch (err) {
    //     console.log(err.message);
    //     let errs = parseErrors(err);
    //     this.$toasted.error(errs).goAway(2500);
    //     return false;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
#expense-rates {
  display: flex;
  flex-direction: column;

  .filters {
    display: flex;
    margin: 0 0 8px;
  }

  .input {
    width: 80px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    min-height: 49px;

    .client-select {
      width: auto;
      min-width: 350px;
    }

    .category-select {
      width: auto;
      min-width: 220px;
    }

    .bar {
      display: flex;
    }

    .bar.left {
      gap: 10px;
      align-items: flex-start;

      .control {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }

    .bar.right {
      gap: 10px;
      align-items: flex-end;
    }

    .control {
      margin: 0;
    }

    .switch-changed-only {
      .loader {
        border: 5px solid #dbdbdb;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        width: 2rem;
        height: 2rem;
      }

      .switch-wrap {
        margin-right: 5px;
        height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .vue-switcher {
        margin-left: 10px;
      }
    }

    .action {
      display: flex;
      align-items: center;

      &.action-2 {
        margin-left: 10px;
      }
    }
  }

  .table-wrap {
    overflow: auto;
    margin-top: 1em;
    height: 100%;
  }
}
</style>
