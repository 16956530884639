<template lang="pug">
.shift-row(v-if="shift")
  modal(
    v-if="isBookConfirmVisible",
    @close="isBookConfirmVisible = false",
    title="Confirm booking shift",
    :show-close-button="true",
    size="450"
  )
    component(
      is="ConfirmBooking",
      :shift="shift",
      @confirm="confirmedBook",
      @decline="closeBookPopup"
    )
  span.legend-icon(
    :class="getShiftLegendIcon",
    :title="formatText(getShiftLegendIcon)"
  )
  span.icon(v-if="shift.partOfTheDay === 1")
    img(src="../../assets/images/comps/day.svg")
  span.icon(v-if="shift.partOfTheDay === 2")
    img(src="../../assets/images/comps/night.svg")
  //- // span.status(v-tooltip="'Shift id'") {{ id_of_a_shift }}
  span.id {{ shift.id }}
  span.separator
  span.status {{ shift.status }}
  template(
    v-if="shift.temp === null || (shift.temp && shift.temp.name !== '')"
  )
    span.separator
    span(v-if="shift.temp") {{ shift.temp.name }}
    span(v-if="!shift.temp")
      i(style="font-size: small; color: #aaa") Not assigned
  span.separator
  span {{ shiftCatName(shift) }}
  span.separator
  span.date-time {{ getStartTime }} - {{ getEndTime }}
  //- span.name {{ shift.temp && shift.temp.name }}
  // span.separator
  // span {{ startTime }} to {{ endTime ? endTime : 'ESCORT' }} <em style="font-size: smaller">(duration: {{ shiftSpan }})</em>
  span.separator
  span {{ shift.client.name }}
  span.separator
  span {{ shift.location.name }}
    template(v-if="!showAdditionalInfo")
      span.separator
      //- span.last-pushed-by {{  }}
      span.created-by {{ shift.shiftRequest && shift.shiftRequest.createdBy.name }}
  span.right-side
    router-link.redirect.fa.fa-arrow-right(
      title="Go to shift schedule",
      :to="{ name: 'shiftSchedule', params: { shift }, query: getShiftQuery(shift) }",
      target="_blank"
    ) 
    template(v-if="$can('view-temps')")
      router-link.button.is-generic-app-blue.is-caps-lock.is-tiny.copy-shift(
        title="Copy shift details",
        :to="{ name: 'submitShiftLine', params: { shift }, query: { calendar: true } }",
        @mousedown.native="setLsShift",
        target="_blank"
      ) Copy
      .button.is-caps-lock.is-tiny.book-shift(
        title="Book shift",
        @click="bookShiftPopup",
        v-if="isShiftAvailableToBook"
      ) Book
</template>
<script>
// import { Evt } from '../../lib/helpers/Evt.js'
import {
  momentTimeFromApi,
  momentDiff,
  parseDateObjWithTimeZone,
  parseObjWithStartEnd,
} from "../../lib/helpers/function.js";
import { STATUS } from "../config/shifts.js";
import ConfirmBooking from "./ConfirmBooking.vue";
var moment = require("moment");
export default {
  props: ["shift", "showAdditionalInfo", "queryParams"],
  components: {
    ConfirmBooking,
  },
  data() {
    return {
      startTime: null,
      endTime: null,
      isBookConfirmVisible: false,
    };
  },
  computed: {
    getStartTime() {
      const time = parseDateObjWithTimeZone(this.shift.startTime);
      return moment(time).format("HH:mm");
    },
    getEndTime() {
      const time = parseDateObjWithTimeZone(this.shift.endTime);
      return moment(time).format("HH:mm");
    },
    shiftSpan() {
      let timespan = momentDiff(this.getStartTime, this.getEndTime);
      return timespan === null ? "Unknown" : timespan;
    },
    id_of_a_shift() {
      return `(${this.shift.id})`;
    },
    getRouteQuery() {
      let defaultPar = {
        calendar: true,
      };
      if (this.queryParams) {
        defaultPar = Object.assign(defaultPar, this.queryParams);
      }

      return defaultPar;
    },
    getShiftLegendIcon() {
      switch (this.shift._shiftStatus) {
        case "worked":
          return "worked";
        case "booked":
          return "booked";
        case "approval-needed":
          return "approval-needed";
        case "open":
          // Client cal
          return "open";
        case "filled":
          // Client cal
          return "filled";
        default:
          return this.shift._shiftStatus;
      }
    },
    isShiftAvailableToBook() {
      return this.shift._shiftStatus === "available";
    },
  },
  watch: {
    id_of_a_shift() {
      // Recalc time when shift changes
      this.startTime = momentTimeFromApi(this.getStartTime);
      this.endTime = momentTimeFromApi(this.getEndTime);
    },
  },
  mounted() {
    // TODO show date if the endtime falls on next day!
    setTimeout(() => {
      // If watcher didn't fire
      if (!this.startTime) {
        this.startTime = momentTimeFromApi(this.getStartTime);
        this.endTime = momentTimeFromApi(this.getEndTime);
      }
    }, 150);
  },
  methods: {
    // getIcon: function (shift) {
    //   let iconPath = '../src/assets/images/comps/'
    //   let iconName = (shift.partOfTheDay === 1) ? 'day' : 'night'
    //   let icon = iconPath + iconName + '.svg'
    //   return `<img src="${icon}" />`
    // },
    formatText(text) {
      if (text) {
        const clean = text
          .split("-")
          .map((tx) => tx.slice(0, 1).toUpperCase() + tx.slice(1))
          .join(" ");
        return clean;
      }
    },
    setLsShift() {
      console.log("setting shift for new tab", this.shift);
      localStorage.shiftRowDetailsOnce = JSON.stringify(this.shift);
      localStorage.shiftRowDetailsOnceTimestamp = moment().format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    bookShiftPopup() {
      this.isBookConfirmVisible = true;
      this.$emit("openBookConfirmPopup");
    },
    closeBookPopup() {
      this.isBookConfirmVisible = false;
    },
    confirmedBook() {
      this.closeBookPopup();
      this.$emit("refresh-data");
      // console.log('Confirmed booking', this.shift);
    },
    shiftCatName(shift) {
      if (
        shift.shiftRequest &&
        Array.isArray(shift.shiftRequest.subcategories)
      ) {
        const subs = shift.shiftRequest.subcategories;
        return subs.map((sub) => sub.name).join(",");
      }
      return "";
    },
    getShiftQuery(shift) {
      return {
        ...this.getRouteQuery,
        shift_id: shift.id,
        status: this.getShiftSchedulePage(shift),
      };
    },
    getShiftSchedulePage(shift) {
      const parsedTime = parseObjWithStartEnd(shift);
      const shiftStartTime = parsedTime.startTime;
      const shiftEndTime = parsedTime.endTime;

      let upperStatus = shift.status.toUpperCase();
      const startAhead =
        shiftStartTime &&
        moment(shiftStartTime, "YYYY-MM-DD HH:mm:ss") > moment();
      const endAhead =
        shiftEndTime && moment(shiftEndTime, "YYYY-MM-DD HH:mm:ss") > moment();

      // Rework later
      // console.warn(
      //   endAhead,
      //   startAhead,
      //   "end start",
      //   shift.invoiceId,
      //   shift.temp
      // );
      if (shift.status === "Cancelled") {
        // Automatic
      } else if (!shift.invoiceId && startAhead === false && endAhead) {
        upperStatus = "ACTIVE";
      } else if (
        endAhead === false &&
        startAhead === false &&
        !shift.invoiceId &&
        !shift.temp
      ) {
        // Test if repush
        upperStatus = "NEVER_FILLED";
      } else if (startAhead && endAhead) {
        // Completed [Start time after present]
        upperStatus = "PENDING";
      } else if (
        shift.invoiceId ||
        (endAhead === false && startAhead === false)
      ) {
        // Test if repush
        upperStatus = "COMPLETED";
      }
      return STATUS[upperStatus];
    },
  },
};
</script>
<style lang="scss" scoped>
$temp-calendar-worked: #000;
$temp-calendar-booked: #2caa1a;
$temp-calendar-open: #ffee00;
$temp-calendar-filled: #005ce6;
$temp-calendar-available: #3999fa;
$temp-calendar-approval-needed: #e41616;

.shift-row {
  display: flex;
  min-height: 3em;
  align-items: center;

  &:hover {
    background: rgb(245, 245, 245);
    background: linear-gradient(
      90deg,
      rgba(245, 245, 245, 0.2) 0%,
      rgba(245, 245, 245, 0.8) 25%,
      rgba(245, 245, 245, 1) 50%,
      rgba(245, 245, 245, 0.8) 90%,
      rgba(245, 245, 245, 0.2) 100%
    );
  }
}
.legend-icon {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 1em;
  border: 1px solid #80808066;

  &.worked {
    background: $temp-calendar-worked;
  }
  &.booked {
    background: $temp-calendar-booked;
  }
  &.open {
    background: $temp-calendar-open;
  }
  &.filled {
    background: $temp-calendar-filled;
  }
  &.approval-needed {
    background: $temp-calendar-approval-needed;
  }
  &.available {
    background: $temp-calendar-available;
  }
}
.status {
  font-weight: bold;
  margin-right: 0.2em;
}
.separator::before {
  content: "/";
  margin: 0 0.5em;
}
.icon {
  margin-right: 1em;
  min-width: 1.5rem;
  min-height: 1.5rem;
}
.created-by {
  color: #384453;
  margin-right: 1rem;
}
.right-side {
  margin-left: auto;
  display: flex;
  align-items: center;

  > * {
    margin-left: 1rem;
  }

  .book-shift {
    font-weight: bold;
    border: thin solid #858585;

    &:hover {
      transition: all 0.1s ease;
      background: darken($color: #fff, $amount: 5);
    }
  }
}

.redirect {
  color: gray;
  cursor: pointer;

  &:hover {
    color: silver;
  }
}
</style>
