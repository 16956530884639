<template lang="pug">
div
  <modal v-if="modalVisible" @close="modalVisible = false" :title="modalTitle" icon="" :size="modalSize" :scrollable="modalScrollable">
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false"
    ) 
  </modal>
  section.section
    h1.title.is-3
      i.fa.icon-Client-user-management
      | User Management
    .spacer-top
      p.control
        .columns
          .column.is-3
            .field
              p.control
                input.input(
                  v-model="searchName",
                  placeholder="Search by name",
                  @input="callSearchFilter"
                )
                i.fa.fa-search
          .column.is-3
            .field
              p.control
                input.input(
                  data-cell="email-input",
                  @input="callSearchFilter",
                  v-model="searchEmail",
                  placeholder="Search by email"
                )
                i.fa.fa-search
          .column.is-2
            multi-select(
              :options="statusesOptions",
              :multiple="false",
              :close-on-select="true",
              select-label="",
              deselect-label="",
              :allow-empty="false",
              track-by="id",
              label="name",
              v-model="searchStatus",
              placeholder="Filter by status",
              title="Filter by status",
              @select="fireSearch"
            )
          .column.is-4
            button#register.button.is-generic-app-teal.is-caps-lock.is-pulled-right(
              @click="registerNewUser"
            ) Register New User
      client-user-management-table(
        :loading="loading",
        :users="users",
        :search-status="searchStatus",
        :includes="includes.join(',')",
        @sort-results="sortResults",
        @refresh-data="pageHasChanged()"
      )
      pagination(
        v-if="pagination",
        :total-pages="pagination.total_pages",
        :current-page="currentPage",
        @page-changed="pageHasChanged"
      )
</template>
<script>
import axios from "axios";
// import auth from '../lib/auth.js'
import MultiSelect from "vue-multiselect";
import Treeselect from "@riophae/vue-treeselect";
// import { pickNonFalsyKeepZero, nonNull } from './../lib/helpers/function.js'
import Pagination from "./Pagination.vue";
import ClientUserManagementTable from "./ClientUserManagement/ClientUserManagementTable.vue";

import EditPinModalContent from "./in-modal/client-user-management/EditPin.vue";
import EditUser from "./EditUser.vue";

import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "clientUserManagement",
  components: {
    MultiSelect,
    Pagination,
    ClientUserManagementTable,
    EditPinModalContent,
    EditUser,
    Treeselect,
  },
  data() {
    return {
      includes: ["roles", "locationCount", "subcategoryCount"],
      // includes: ["roles", "locations", "subcategories"],
      modalVisible: false,
      modalSize: 350,
      modalScrollable: false,

      apiParams: {},
      currentPage: 1,
      user: {
        name: "",
        email: "",
        password: "",
        confirmpass: "",
        userclass: [], // User type
        jobs: [], // Job categories, subcategories
        locations: [], // Job locations
        signoff: false,
        pin: "",
        pinArr: [],
        timesheet: false,
      },
      cancelToken: null,
      searchName: null,
      searchEmail: null,
      canLoadAlive: false,
      loading: false,

      selectedSortColumn: {
        column: "",
        direction: "",
      },

      searchStatus: {
        id: 1,
        value: null,
        name: "All",
      },
      statusesOptions: [
        {
          id: 1,
          value: null,
          name: "All",
        },
        {
          id: 2,
          value: true,
          name: "Active",
        },
        {
          id: 3,
          value: false,
          name: "Inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      users: "getUsers",
      categories: "getAllCategories", // job types
      getCategories: "getCategories",
      roles: "getMinRoles", // Roles aka Visibility
      clients: "getAllClients",
      // sectors: 'locationClientManagement/getFlatSectors',
      pagination: "getPagination",
    }),
  },
  created() {
    this.clearUsers();
  },
  mounted() {
    this.currentPage = parseInt(this.$route.query.page, 10) || 1;
    const fetchParams = Object.assign({}, this.apiParams, {
      include: this.includes.join(","),
      page: this.currentPage,
    });

    this.loading = true;
    this.fetchUsers(fetchParams).then(() => {
      this.loading = false;
    });

    this.fetchAllCategoriesList();
    this.fetchAllRolesList();

    // this.fetchSectors({ includes: 'clients.locations' }).then(() => {
    // console.log('Fetched Sectors.');
    // this.allLocations = this.getLocationsFromSectors(this.sectors);
    const clientIncludes = ["locations", "withArchived"].join();
    this.fetchClientsList({
      includes: clientIncludes,
      per_page: 999,
    });
  },
  activated() {
    if (this.canLoadAlive) {
      this.callSearchApi();
    } else {
      this.canLoadAlive = true;
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: "fetchUsers",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchAllRolesList: "fetchMinRoles",
      fetchClientsList: "fetchClientsList",
      // 'fetchSectors': 'locationClientManagement/fetchSectors',
      clearUsers: "clearUsers",
      fetchUsersCancellable: "fetchUsersCancellable",
    }),
    registerNewUser() {
      this.showModal("edit-user", {}, "Create new user", 600, true);
    },
    // editPinUser: function (userId) {
    //   this.showModal(
    //     'edit-pin-modal-content',
    //     { userId: userId, includes: this.includes.join(',') },
    //     'Change PIN'
    //   );
    // },
    showModal: function (
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = 350,
      modalScrollable = false
    ) {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalSize = modalSize;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalScrollable = modalScrollable;
      this.modalVisible = true;
    },
    pageHasChanged(pageNum = this.pagination?.current_page) {
      console.log("Page Change Invoked");
      this.currentPage = pageNum;
      const params = Object.assign(
        {},
        this.apiParams,
        this.generateFilterParams({ page: pageNum })
      );
      this.loading = true;
      this.fetchUsers(params).then(() => {
        this.loading = false;
      });
    },
    /**
     * Sort object is an object that contains sort data passed by sort component
     */
    sortResults(sortData) {
      this.selectedSortColumn.column = sortData.column;
      this.selectedSortColumn.direction = sortData.direction;

      this.apiParams.sort_by_field = sortData.column;
      this.apiParams.sort_by_direction = sortData.direction;
      if (sortData.column === "") {
        delete this.apiParams.sort_by_field;
        delete this.apiParams.sort_by_direction;
      }
      const params = Object.assign(
        {},
        this.apiParams,
        this.generateFilterParams()
      );
      this.loading = true;
      this.fetchUsers(params).then(() => {
        this.loading = false;
      });
    },
    async callSearchApi() {
      this.currentPage = 1;
      this.$set(this.$route.query, "page", 1);

      this.loading = true;
      if (this.cancelToken !== null) {
        this.cancelToken.cancel();
        this.cancelToken = null;
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelToken = source;
      const defParams = {
        cancelTokenSource: source,
        sort_by_field: this.selectedSortColumn.column || null,
        sort_by_direction: this.selectedSortColumn.direction || null,
      };
      const params = Object.assign({}, defParams, this.generateFilterParams());
      try {
        await this.fetchUsersCancellable(params);
        this.loading = false;
      } catch (err) {
        console.log("Re-searching users");
        // this.loading = false
      }
    },
    callSearchFilter: _.debounce(function () {
      this.callSearchApi();
    }, 500),
    fireSearch: _.debounce(function () {
      this.callSearchApi();
    }, 300),
    generateFilterParams({ page } = {}) {
      return {
        page: page || this.currentPage || 1,
        active: this.searchStatus && this.searchStatus.value,
        include: this.includes.join(","),
        name: this.searchName || null,
        email: this.searchEmail || null,
      };
    },
  },
};
</script>

<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>

<style lang="scss" scoped>
.spacer-top {
  input {
    box-shadow: none;
    border: 1px solid #9bb4c1;
    height: 37px;
  }

  .control {
    i {
      top: 10px;
      position: absolute;
      right: 15px;
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
.vue-treeselect__placeholder {
  color: #75757d;
  font-size: 1.1rem !important;
  font-family: "Lato", sans-serif;
}

.vue-treeselect__arrow-wrapper {
  width: 30px;
}
</style>

<style lang="scss">
.client-management {
  // Used in other components
  .spacer-top {
    margin-top: 50px;
  }

  p.control {
    display: flex;
    align-items: center;
    margin-top: 20px;

    span {
      color: #405168;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }

    span.group {
      margin-left: auto;
    }

    span.is-danger {
      color: #ff3860;
    }
  }

  .radio {
    input {
      display: none;
    }
  }

  .check {
    border: 2px solid #aaaaaa;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 30px;
    left: 20px;
    margin-right: 10px;

    &:before {
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 15px;
      width: 15px;
      top: 5px;
      left: 5px;
      margin: auto;
      transition: background 0.25s linear;
    }
  }

  input[type="radio"]:checked ~ .check {
    border: 2px solid #2669b0;
  }

  input[type="radio"]:checked ~ .check:before {
    background: #2669b0;
  }

  input[type="radio"]:checked ~ label {
    color: #0dff92;
  }

  .rectangle {
    input {
      color: #2669b0;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      height: 40px;
      width: 40px;
      border: 1px solid #2669b0;
      border-radius: 3px;
      background-color: #ffffff;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
</style>
