<template lang='pug'>
.signin
  header
    img(:src="getGlobalImageLogoApi", alt="")
    //- img(src='../../assets/images/cpltabs.png' alt='')
    p Please login below.
  hr
  //- // <div class="notification is&#45;danger" v&#45;if="errors.any()">
  //- // <p>There was an error, unable to sign in with those credentials.</p>
  //- // </div>
  div
    section.section--inputs
      p.control
        input.input.is-medium(
          type="email",
          name="email",
          :class="{ 'is-danger': errors.get('email') }",
          :readonly="disableInputsStartup",
          placeholder="Email",
          v-model="email",
          autocomplete="off"
        )
        span.help.is-danger(
          v-if="errors.has('email')",
          v-html="errors.get('email')"
        )
      p.control
        input.input.is-medium(
          type="password",
          name="password",
          v-model="password",
          :readonly="disableInputsStartup",
          placeholder="Password",
          autocomplete="password",
          @keyup.enter="signin"
        )
        span.help.is-danger(
          v-if="errors.has('password')",
          v-html="errors.get('password')"
        )
      .field.control.is-pulled-right
        router-link(:to="{ name: 'forgot.password' }") Forgot Your Password?
      button.button.is-generic-app-blue(
        @click="signin",
        @keydown="errors.clear($event.target.name)"
      ) LOGIN
</template>
<script>
import auth from '../../lib/auth.js';
import { Errors } from '../../lib/helpers/Errors.js';

export default {
  name: 'sign_in',
  data () {
    return {
      email: null,
      password: null,
      errors: new Errors(),
      disableInputsStartup: true,
    };
  },
  mounted () {
    if (sessionStorage.showSignInMessage) {
      // Shows message from config & auth [js]
      const TIMEOUT_TIME = 7000;
      this.$toasted.error(sessionStorage.showSignInMessage).goAway(TIMEOUT_TIME);
      setTimeout(() => {
        // Test in future
        sessionStorage.removeItem('showSignInMessage');
      }, TIMEOUT_TIME);
    }

    // To disable input autocomplete
    setTimeout(() => {
      this.disableInputsStartup = false;
    }, 600);
  },
  methods: {
    async signin () {
      try {
        await auth.signin(this, this.email, this.password);
      } catch (err) {
        this.errors.all().forEach(error => {
          if (error === '401 Unauthorized') {
            this.$toasted.error('You have entered the incorrect username/password').goAway(2500);
          } else {
            this.$toasted.error(error).goAway(3000);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.forgot-pass {
  color: #2669b0;
  font-size: 13px;
}

input:read-only {
  background: rgba(233, 233, 233, 0.23);
}
</style>
