<template lang="pug">
.shift-times-books
  .h4.card-header-icon SHIFTS
  .shifts-table
    .shifts-table--header
      .shift-row
        .shift-cell.id ID
        .shift-cell.date Shift Start Time
        .shift-cell.time Shift End Time
        .shift-cell.total-hours Total hours
        .shift-cell.req-shift(colspan="2", width="15%") Number of required shifts
        .shift-cell.button-cell
    .shifts-table--body
      .shift-row(v-for="(row, index) in rows")
        .shift-cell.id {{ row.number }}
        .shift-cell.start-date
          //- uni-date-picker(
          //-   :date="row.start_time",
          //-   :mode="mode",
          //-   :option="dateTimeOption",
          //-   :disabled="isDisabledEndTime",
          //-   :limit="datePickerLimit",
          //-   @change="onInputChange($event, 'start_time', row)"
          //- )
          //- uni-date-picker(
          //-   :date="pendingRow.start_time",
          //- )
          flat-pickr.input.is-medium.column.is-5(
            placeholder="Select Date/Time",
            @on-change="onInputChange($event, 'start_time', row, index)",
            :config="flatpickrConfigShared",
            :value="row.start_time.time",
            :key="'a' + index"
          )
        .shift-cell.end-date
          //- uni-date-picker(
          //-   :date="row.end_time",
          //-   :mode="modeEnd || mode",
          //-   :option="dateTimeOptionEnd",
          //-   :disabled="isDisabledEndTime",
          //-   :limit="datePickerLimit",
          //-   @change="onInputChange($event, 'end_time', row)"
          //- )
          flat-pickr.input.is-medium.column.is-5(
            placeholder="Select Date/Time",
            @on-change="onInputChange($event, 'end_time', row, index)",
            :config="flatpickrConfigShared",
            :value="row.end_time.time",
            :key="'b' + index"
          )
        .shift-cell.total-hours(v-text="getShiftTotalHours(row)")
        .shift-cell.req-shift
          input.input(
            type="number",
            :value="row.number_of_shifts",
            min="1",
            max="50",
            @change="onNumberOfShiftsChange($event, row)"
          )
        .shift-cell.button-cell
          img.remove-shift-btn(
            src="./../../assets/images/comps/delete-larger.svg",
            @click="removeShift(row.number)"
          )
      .shift-row.new(key="asdasda")
        .shift-cell.id
        .shift-cell.start-date
          //- uni-date-picker(
          //-   :date="pendingRow.start_time",
          //-   :mode="mode",
          //-   :option="dateTimeOption",
          //-   :disabled="isDisabledEndTime",
          //-   :limit="datePickerLimit",
          //-   @change="onInputChange($event, 'start_time', pendingRow)"
          //- )
          flat-pickr.input.is-medium.column.is-5(
            placeholder="Select Date/Time",
            @on-change="onInputChange($event, 'start_time', pendingRow, 0, true)",
            :config="flatpickrConfigShared",
            :value="pendingRow.start_time.time",
            key="first-pen"
          )
        .shift-cell.end-date
          //- uni-date-picker(
          //-   :date="pendingRow.end_time",
          //-   :mode="modeEnd || mode",
          //-   :option="dateTimeOptionEnd",
          //-   :disabled="isDisabledEndTime",
          //-   :limit="datePickerLimit",
          //-   @change="onInputChange($event, 'end_time', pendingRow)"
          //- )
          flat-pickr.input.is-medium.column.is-5(
            placeholder="Select Date/Time",
            :value="pendingRow.end_time.time",
            :config="flatpickrConfigShared",
            @on-close="onInputChange($event, 'end_time', pendingRow, 0, true)",
            key="second-pen"
          )
        .shift-cell.total-hours(v-text="getShiftTotalHours(pendingRow)")
        .shift-cell.req-shift
          input.input(
            type="number",
            :value="pendingRow.number_of_shifts",
            min="1",
            max="50",
            @change="onNumberOfShiftsChange($event, pendingRow, true)"
          )
        .shift-cell.button-cell
          //- img.remove-shift-btn(
          //-   title="Clear pending row data",
          //-   src="./../../assets/images/comps/delete-larger.svg",
          //-   @click="resetPendingRow()"
          //- )
</template>

<script>
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    flatPickr,
  },
  props: {
    rows: {
      type: Array,
      default: () => [
        {
          start_time: {
            time: "",
          },
          end_time: {
            time: "",
          },
        },
      ],
    },
    isDisabledEndTime: {
      type: Boolean,
      default: false,
      required: false,
    },
    format: {
      type: String,
      default: "DD/MM/YYYY HH:mm",
      required: false,
    },
    formatEnd: {
      default: "",
    },
    mode: {
      // day | hour
      type: String,
      default: "day",
      required: false,
    },
    modeEnd: {
      default: "",
    },
  },
  data() {
    return {
      canTriggerChangeEvent: true,
      flatpickrConfigShared: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        dateFormat: "d/m/Y H:i",
        enableTime: true,
        time_24hr: true,
        minDate: "today",
      },
      defaultNewRow: {
        number: 0,
        start_time: { time: "" },
        end_time: { time: "" },
        number_of_shifts: 1,
      },
      pendingRow: {
        start_time: { time: "" },
        end_time: { time: "" },
        number_of_shifts: 1,
      },
      datePickerLimit: [
        {
          type: "fromto",
          from: moment(),
        },
      ],
      // fromToTime: {
      //   startTime: { time: "" },
      //   endTime: { time: "" },
      // },
    };
  },
  computed: {
    // flatpickrConfig() {
    //   return {
    //     ...this.flatpickrConfigShared,
    //   };
    // },
    dateTimeOption() {
      return { format: this.format };
    },
    dateTimeOptionEnd() {
      return { format: this.formatEnd || this.format };
    },
  },
  mounted() {
    this.resetPendingRow();
  },
  methods: {
    getShiftTotalHours(rowData) {
      function getShiftTotalMinutes(startTime, endTime) {
        return moment(endTime).diff(startTime, "minutes");
      }

      function generateTotalHoursString(mins) {
        if (mins) {
          const hours = Math.floor(mins / 60)
            .toString()
            .padStart(2, 0);
          const minsRem = (mins % 60).toString().padStart(2, 0);
          return `${hours}h : ${minsRem}m`;
        }
        return "";
      }

      function generateTotalHours(startTime, endTime) {
        if (startTime && endTime) {
          const mins = getShiftTotalMinutes(startTime, endTime);
          return generateTotalHoursString(mins);
        }
        return "";
      }

      const startIso =
        rowData.start_time?.time &&
        moment(rowData.start_time.time, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DD HH:mm"
        );
      const endIso =
        rowData.end_time?.time &&
        moment(rowData.end_time.time, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DD HH:mm"
        );

      if (startIso && endIso) {
        return generateTotalHours(startIso, endIso);
      }
      return "";
    },
    onInputChange(evt, key, rowData, rowIndex, isPendingRow) {
      if (!this.canTriggerChangeEvent) {
        return;
      }
      if (!evt[0]) {
        console.warn(`No date ${evt.length} >>`, evt);
        return;
      }

      // moment(evt[0], "DD/MM/YYYY");
      const formatedEvt = moment(evt[0]).format("DD/MM/YYYY HH:mm");
      if (isPendingRow) {
        this.$set(this.pendingRow[key], "time", formatedEvt);
        // this.$set(this.pendingRow, key, { time: formatedEvt });
        if (
          this.pendingRow.start_time?.time &&
          this.pendingRow.end_time?.time
        ) {
          this.$emit("change-input", { key, evt: formatedEvt, rowData });

          // this.resetPendingRow();
          setTimeout(() => {
            // NOTE: Some bug when clearing exit
            this.resetPendingRow();
          }, 100);
        }
      } else {
        this.$emit("change-input", { key, evt: formatedEvt, rowData });
      }

      // if (isPendingRow) {
      //   this.$set(this.pendingRow, key, { time: formatedEvt });
      //   if (
      //     this.pendingRow.start_time?.time &&
      //     this.pendingRow.end_time?.time
      //   ) {
      //     this.$emit("change-input", { key, formatedEvt, rowData });
      //     this.resetPendingRow();
      //   }
      // } else {
      // }
    },
    onNumberOfShiftsChange(evt, rowData, isPendingRow = false) {
      const numberOfShifts = Number(evt.target.value);
      if (isPendingRow) {
        this.$set(this.pendingRow, "number_of_shifts", numberOfShifts);
      } else {
        const id = rowData?.number;
        this.$emit("change-num-shifts", {
          value: numberOfShifts,
          id: id,
        });
      }
    },
    removeShift(id) {
      this.$emit("remove-row", { id });
    },
    resetPendingRow() {
      this.canTriggerChangeEvent = false;
      this.pendingRow = JSON.parse(JSON.stringify(this.defaultNewRow));
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.canTriggerChangeEvent = true;
        });
      });
    },
  },
};
</script>

<style lang="scss">
.shift-times-books {
  .shifts-table--body {
    height: auto !important;
    max-height: 150px;

    .shift-row {
      height: 36px;

      input {
        width: 144px;
        cursor: pointer;
      }
      .is-medium {
        font-size: 1rem;
      }

      &.new {
        background-color: rgb(247, 247, 216);
      }
    }
    .shift-row.new {
      input {
        width: 144px;
        cursor: pointer;
      }
      .is-medium {
        font-size: 1rem;
      }
    }
  }

  input {
    align-items: center;
    border: none;
    border-radius: 4px;
    box-shadow: none !important;
    display: inline-flex;
    font-size: 1rem;
    justify-content: flex-start;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    position: relative;
    vertical-align: top;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    color: #363636;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  .cov-vue-date,
  .datepickbox {
    height: 100%;
    width: 100%;
  }

  .cov-picker-box {
    display: flex !important;
    flex-wrap: wrap;

    > div:first-child {
      width: 100%;
    }

    &.date-list {
      display: flex !important;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}
</style>
