<template lang="pug">
.wrap
  file-list-management(
    :files="files",
    :fileLink="fileLink",
    :pendingDeleteFiles="pendingDeleteFiles",
    :canDeleteFiles="canDeleteFiles",
    :canOpenFile="canOpenFiles",
    @open-file="onOpenFile",
    @download-file="onDownloadFile",
    @delete-file="onDeleteFile"
  )
  transition(name="fade")
    .delete-section(v-if="deleteDialogActive")
      span.action-message Are you sure you want to delete this file?
      span.action-buttons
        a.button.is-small.is-confirm(@click.once="deleteFile") YES
        a.button.is-small.is-deny(
          @click="deleteDialogActive = false; fileToBeDeleted = null"
        ) NO
  footer
    button.button.is-generic-app-blue.is-outlined.is-low(
      @click="callModalClose"
    ) CANCEL
</template>
<script>
import FileListManagement from "@/components/shared/FileListManagement.vue";
import { mapActions } from "vuex";
import _ from "underscore";

export default {
  components: {
    FileListManagement,
  },
  props: ["modalProps"],
  data() {
    return {
      deleteDialogActive: false,
      files: [],
      deleteRequest: {},
      afterFileDeleteResponse: null,

      fileToBeDeleted: null,
      pendingDeleteFiles: [],
      fileLink: "",
      previousModal: {},
    };
  },
  computed: {
    canDeleteFiles() {
      if (this.modalProps.clientId) {
        return this.gPermissions.canJournalClientEdit;
      } else if (this.modalProps.tempId) {
        return this.gPermissions.canJournalTempEdit;
      }
      return false;
    },
    canOpenFiles() {
      return true;
    },
  },
  mounted() {
    this.files = this.modalProps.files;
  },
  methods: {
    ...mapActions(["getFileBlob", "deleteNoteFile"]),
    onDeleteFile(fileId) {
      this.confirmDelete(fileId);
    },
    confirmDelete(fileId) {
      // show/activate file deletion dialog
      this.deleteDialogActive = true;
      // keep id of a file to be deleted for a reference
      this.fileToBeDeleted = fileId;

      /**
       * Form the request for when actual file deletion takes place
       */
      this.deleteRequest = {
        files: [{ id: fileId }],
        include: "files",
      };
      /**
       * Console log the request for file deletion
       */
      console.error("DELETE REQUEST: ", this.deleteRequest);
    },
    async deleteFile() {
      console.log("about to delete a file...", this.fileToBeDeleted);

      let idx = _.findIndex(this.files, { id: this.fileToBeDeleted });

      this.pendingDeleteFiles = [this.fileToBeDeleted];
      for await (const file of this.deleteRequest.files) {
        const params = {
          id: file.id,
          for_client_journal: Boolean(this.clientId),
        };
        try {
          const response = await this.deleteNoteFile(params);
          this.afterFileDeleteResponse = response.data;
          this.files.splice(idx, 1);

          this.deleteDialogActive = false;
          this.pendingDeleteFiles = [];
        } catch (err) {
          console.warn(err.message);
        }
      }
    },
    revokeLink() {
      if (this.fileLink) {
        console.log(">>> Revoking link");
        URL.revokeObjectURL(this.fileLink);
      }
    },
    callModalClose() {
      this.revokeLink();
      this.$emit("cancel-modal");
    },
    async onOpenFile(evt, fileObj) {
      // const fileName = file.fileName;
      try {
        const res = await this.getFileBlob(evt);
        const file = res.data;
        this.fileLink = URL.createObjectURL(file);
        window.open(this.fileLink);
      } catch (err) {
        console.warn(err.message);
      }
    },
    async onDownloadFile(evt, fileObj) {
      // const fileName = file.fileName;
      try {
        const res = await this.getFileBlob(evt);
        const file = res.data;
        const url = URL.createObjectURL(file);

        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileObj.fileName;
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.warn(err.message);
      }
    },
    previewFile(file) {
      // -- Unused --
      // // const file = this.blobToFile(res.data, fileName);
      // const el = document.querySelector(".modal-content");
      // if (el) {
      //   this.previousModal = {
      //     width: el.style.width,
      //     height: el.style.height,
      //   };
      //   el.style.width = "100%";
      //   el.style.height = "100%";
      //   const cel = el.querySelector(".panel-block");
      //   if (cel) {
      //     cel.style.width = "100%";
      //     cel.style.height = "100%";
      //   }
      // }
    },
    blobToFile(theBlob, fileName) {
      const options = {
        // type: 'application/vnd.ms-excel',
      };
      const file = new File([theBlob], fileName, options);
      // console.warn(file, fileName);
      // theBlob.lastModifiedDate = new Date();
      // theBlob.name = fileName;
      // theBlob.type = 'xlsx';
      return file;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

.wrap {
  width: 100%;
}

.caption {
  padding: 1em;
  display: flex;
  justify-content: center;
}

.is-confirm,
.is-deny {
  font-weight: 900;
  font-size: 1.1em;
}

.is-confirm {
  color: #ff3b3b;
}

.is-deny {
  color: #2669b0;
}

div.delete-section {
  display: flex;
  border-top: 1px solid rgba(#405168, 0.15);
  border-bottom: 1px solid rgba(#405168, 0.15);
  align-items: center;
  justify-content: center;

  .action-message {
    margin-right: 1em;
    color: #405168;
  }

  .action-buttons {
    a.button {
      border: none;
      margin: 0 0.3em;
    }
  }
}

footer {
  padding: 1.5em 0;
  display: flex;
  justify-content: center;
}
</style>
