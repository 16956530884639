<template lang="pug">
.signature-table-wrap
  table.table
    thead
      tr
        th
          span Signature name
        th
          span Use
        th
          span Edit
        th
          span Delete
    tbody(
      v-if="signatures && signatures.length > 0 && !isLoading"
    )
      tr(v-for="(signature, index) in signatures", :key="index")
        td
          p.control
            | {{ signature.name }}
        td
          p.control
            button.button.is-generic-app-blue.is-caps-lock.is-tiny(
              @click="onUseSignature(signature)"
            ) Use signature
        td
          p.control
            button.button.is-caps-lock.is-tiny.is-success(
              @click="onEditSignature(signature)"
            ) Edit
        td
          p.control
            button.button.is-caps-lock.is-tiny.is-danger(
              @click="onDeleteSignature(signature)"
            ) Delete
      tr
        td
          button.button.is-generic-app-blue.is-caps-lock(
            @click.prevent="onAddSignature"
          )
            | Create signature
    tbody(
        v-if="isLoading"
      )
        tr
          td
            img(src="../../assets/images/comps/loader.svg")
    tbody(
      v-if="signatures.length === 0 && !isLoading"
    )
      tr
        td
          | No data in table
        td
        td
        td
      tr
        td
          button.button.is-generic-app-blue.is-caps-lock(
            @click.prevent="onAddSignature"
          )
            | Create signature
        td
        td
        td
</template>

<script>
export default {
  props: {
    signatures: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onUseSignature (signature) {
      this.$emit('use-signature', signature);
    },
    onAddSignature () {
      this.$emit('add-signature');
    },
    onEditSignature (signature) {
      this.$emit('edit-signature', signature);
    },
    onDeleteSignature (signature) {
      this.$emit('delete-signature', signature);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>