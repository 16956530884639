<template lang='pug'>
.bulk-progress-wrap
  .progress-data
    template(v-if="progressData.pending")
      .loading-wrapper
        img(src="../../../assets/images/comps/loader.svg")
    bulk-upload-progress(:progressData="progressData")
  .progress-label
    .completed
      span {{ getDoneItem }}
    //- .pending
      span {{ progressData.total }}
    span /
    .total
      span {{ progressData.total }}
</template>

<script>
import BulkUploadProgress from './BulkUploadProgress.vue';
export default {
  components: {
    BulkUploadProgress,
  },
  props: {
    progressData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getDoneItem () {
      if (this.progressData.pending) {
        return this.progressData.total - this.progressData.pending;
      }
      return this.progressData.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-progress-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .progress-data {
    display: flex;
    align-items: center;
    gap: 20px;

    .loading-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .progress-label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
}
</style>