<template lang="pug">
#general-receipt-manage
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  ul
    li(v-if="canManageReceipts")
      input.input(
        v-model="pendingInput",
        type="text",
        placeholder="New Receipt",
        :disabled="isLockedInputs",
        @keyup.enter="onAddToList"
      )
      button.button.is-small.is-generic-app-blue.is-caps-lock(
        :disabled="isLockedInputs || !pendingInput",
        @click="onAddToList"
      )
        span Add to list
    li(v-for="(item, index) in mapGeneralReceipts", :key="item.id")
      input.input(
        :data-id="item.id",
        v-model="item.label",
        :class="{ changed: changedInputs.includes(item.id) }",
        type="text",
        :disabled="isLockedInputs",
        @input="bindValue($event)"
      )
      button.button.is-small.is-success.is-caps-lock(
        :disabled="isLockedInputs",
        v-if="canManageReceipts && changedInputs.includes(item.id)",
        @click="onClickEdit(item)"
      )
        span Edit
      button.button.is-small.is-danger.is-caps-lock(
        :disabled="isLockedInputs",
        v-if="canManageReceipts",
        @click="onRemoveFromList(item)"
      )
        span Remove from list

  .buttons-centered(v-if="canManageReceipts")
    button.button.is-generic-app-blue.is-caps-lock(
      type="button",
      :disabled="isSavingData || !isLockedInputs",
      v-if="isLockedInputs && !isLoading",
      @click.prevent="isLockedInputs = false"
    )
      span Unlock

    button.button.is-generic-app-blue.is-caps-lock(
      type="button",
      :disabled="isSavingData || isLockedInputs",
      v-if="!isLockedInputs",
      @click.prevent="isLockedInputs = true"
    )
      span Lock

    //- button.button.is-generic-app-blue.is-caps-lock(
    //-   type="submit",
    //-   :disabled="isSavingData || isLockedInputs",
    //-   v-if="!isLockedInputs",
    //-   @click.prevent="onDataSubmit"
    //- )
    //-   img.btn-loader(
    //-     src="../../../assets/images/comps/loader.svg",
    //-     v-if="isSavingData"
    //-   )
    //-   span Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    generalReceiptList: {},
    changedInputs: [],
    isLockedInputs: true,
    isLoading: false,
    isSavingData: false,
    pendingInput: "",
  }),
  computed: {
    mapGeneralReceipts() {
      return this.generalReceiptList.children
        ? this.getRecChildren(this.generalReceiptList.children)
        : [];
    },
    canManageReceipts() {
      return this.$can("manage-general-receipts");
    },
  },
  mounted() {
    this.fetchGeneralReceipts();
  },
  methods: {
    ...mapActions({
      getGeneralReceipts: "getGeneralReceipts",
      postGeneralReceipts: "postGeneralReceipts",
      putGeneralReceipts: "putGeneralReceipts",
      deleteGeneralReceipts: "deleteGeneralReceipts",
    }),
    // onDataSubmit() {
    //   console.log("Saving data");
    // },
    async fetchGeneralReceipts() {
      const params = {};
      try {
        const res = await this.getGeneralReceipts(params);
        this.generalReceiptList = res.data.data;
      } catch (err) {
        console.warn(err.message);
      }
    },
    async createGeneralReceipt(pendingStr) {
      try {
        const res = await this.postGeneralReceipts({ label: pendingStr });
        this.$toasted.success(res.data.data).goAway(2500);
        this.fetchGeneralReceipts();
      } catch (err) {
        console.warn(err.message);
        this.$toasted.error(err.message).goAway(4500);
      }
    },
    async editGeneralReport(item) {
      const params = {
        id: item.id,
        label: item.label,
      };
      try {
        const res = await this.putGeneralReceipts(params);
        this.$toasted.success(res.data.data).goAway(2500);
        this.changedInputs = this.changedInputs.filter((id) => id !== item.id);
        // this.isLockedInputs = true;
        this.fetchGeneralReceipts();
      } catch (err) {
        console.warn(err.message);
        this.$toasted.error(err.message).goAway(4500);
      }
    },
    async onAddToList() {
      if (!this.pendingInput) {
        return;
      }

      const isExistingRow = this.mapGeneralReceipts.some(
        (item) => item.email === this.pendingInput
      );
      if (isExistingRow) {
        this.$toasted
          .error("You can't add the same receipt twice")
          .goAway(4500);
        return;
      }
      // this.mapGeneralReceipts.push({
      //   label: this.pendingInput,
      // });
      await this.createGeneralReceipt(this.pendingInput);
      this.pendingInput = "";
    },
    async onRemoveFromList(item) {
      try {
        await this.confirmDelete(item.label);
        const res = await this.deleteGeneralReceipts({ id: item.id });
        this.$toasted.success(res.data.data).goAway(2500);
        this.fetchGeneralReceipts();
      } catch (err) {
        this.$toasted.error(err.message).goAway(4500);
      }
    },
    confirmDelete(name = "this receipt") {
      return new Promise((resolve) => {
        let alert = {
          title: `Do you wish to remove "${name}"?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    onClickEdit(item) {
      this.editGeneralReport(item);
    },
    getRecChildren(arr = []) {
      const selKey = "selectable";
      if (Array.isArray(arr)) {
        const recSer = arr.reduce((acc, item) => {
          if (item[selKey]) {
            acc.push(item);
          }
          if (item.children) {
            const childArr = this.getRecChildren(item.children);
            acc.push(...childArr);
          }
          return acc;
        }, []);
        return recSer;
      }
      return [];
    },
    bindValue(evt) {
      this.changedInputs = Array.from(
        new Set([
          ...this.changedInputs,
          parseInt(evt.target.getAttribute("data-id")),
        ])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#general-receipt-manage {
  display: flex;
  flex-direction: column;
  padding: 20px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: auto;
    max-width: 400px;
    width: 100%;
    margin: auto;

    li {
      list-style-type: none;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}
</style>
