const NotFound = { template: "<h2>Page not found.</h2>" };
const ErrorComp = { template: "<h2>Not allowed.</h2>" };

export default [
  {
    path: "/error",
    redirect: "/", // Error comp is not used anymore
    component: ErrorComp,
    meta: {
      permission: "",
    },
  },
  {
    path: "*",
    component: NotFound,
  },
];
