import moment from "moment";

import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

// import CalendarUtils from '../../lib/helpers/CalendarUtils.js'
import ShiftRow from "../Calendar/ShiftRow.vue";
import MultiSelect from "vue-multiselect";
import { STATUS } from "../config/shifts";

export default {
  components: {
    ShiftRow,
    MultiSelect,
  },
  data() {
    return {
      isLoading: false,
      weekdays: [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ],
      beginFromDay: null,
      activeCellShiftsArr: [],
      activeFilter: "filter_all",
      // currently selected day (which triggers showing of shift details below the table)
      // TODO set this property on mount() for day `today`
      activeDay: null,

      cursor: 0,
      isLoadingClient: false,
      dateRange: {
        dateFrom: "",
        dateTo: "",
      },
      access: this.$access,
      toggleShiftList: true,

      selectedClient: null,
      selectedLocation: [],
      locationApiArr: [],

      cancelToken: null,

      isInputDateVisible: false,
      isShiftRowLoading: false,
      filterBtns: [
        {
          name: "filter_all",
          label: "All",
          classIcon: "",
        },
        {
          name: "filter_open",
          label: "Open",
          classIcon: "is-open",
        },
        {
          name: "filter_filled",
          label: "Filled",
          classIcon: "is-filled",
        },
        {
          name: "filter_approval_needed",
          label: "Approval needed",
          // classIcon: "temp-calendar-approval-needed",
          classIcon: "is-approval",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      days: "getCalendarDays", // api
      daysFormatted: "getCalendarDaysFormatted",
      cellData: "mapDateStringsToApi", // data to be rendered into calendar cells
      clients: "getAllClients",
      clientsWithLocations: "getClientsWithLocations",
    }),
    isValidDateRedirect() {
      const isBeforeToday = moment(this.getActiveDayStr).isBefore(moment());
      const isApprovalFilter = this.activeFilter === "filter_approval_needed";
      if (!isBeforeToday && isApprovalFilter) {
        return false;
      }
      return true;
    },
    getBaseRouteParams() {
      const query = {
        day: this.getActiveDayStr,
      };

      if (this.selectedClient && this.selectedClient.id) {
        query.client = this.selectedClient.id;
      }
      if (this.selectedLocation?.length === 1) {
        query.cc = this.selectedLocation[0].id;
      }

      // Status
      if (this.activeFilter === "filter_open") {
        query["shift-status"] = "open";
      } else if (this.activeFilter === "filter_filled") {
        query["shift-status"] = "filled";
      }

      return query;
    },
    getDayRouteParams() {
      const query = Object.assign({}, this.getBaseRouteParams);
      const dayInPast = this.activeDay[this.getActiveDayStr]["in-past"];
      if (this.activeDay && this.activeDay[this.getActiveDayStr]) {
        let setQueryTab = false;

        if (
          Array.isArray(this.activeCellShiftsArr) &&
          this.activeCellShiftsArr.length
        ) {
          // Testing shift statuses
          const isAllCanc = this.activeCellShiftsArr.every(
            (shift) => shift.status === "Cancelled"
          );
          if (isAllCanc) {
            query.status = STATUS.CANCELLED;
            setQueryTab = true;
          } else {
            const isAllActive = this.activeCellShiftsArr.every((shift) => {
              const startAhead =
                shift.startTime &&
                moment(shift.startTime, "YYYY-MM-DD HH:mm:ss") > moment();
              const endAhead =
                shift.endTime &&
                moment(shift.endTime, "YYYY-MM-DD HH:mm:ss") > moment();
              return (
                !shift.invoiceId &&
                shift.temp !== null &&
                startAhead === false &&
                endAhead
              );
            });
            if (isAllActive) {
              query.status = STATUS.ACTIVE;
              setQueryTab = true;
            }
          }
        }

        if (dayInPast && !setQueryTab) {
          // Before today [Move to completed]
          if (this.activeFilter === "filter_open") {
            // Never filled
            query.status = STATUS.NEVER_FILLED;
          } else {
            query.status = STATUS.COMPLETED;
          }
        } else {
          if (this.activeFilter === "filter_approval_needed") {
            // Happens only for current day [future is disabled]
            query.status = STATUS.COMPLETED;
          }
        }
      }

      return query;
    },
    getActiveDayStr() {
      if (this.activeDay) {
        let dayString = Object.keys(this.activeDay)[0];
        return dayString;
      }
      return "";
    },
    filterLocation() {
      if (this.selectedClient && this.selectedClient.name) {
        return this.clientsWithLocations.filter(
          (cc) => cc.client === this.selectedClient.name
        );
      }
      return this.clientsWithLocations;
    },
    offsetDays() {
      if (this.beginFromDay) {
        return _.findIndex(this.weekdays, (weekday) => {
          return weekday.toLowerCase() === this.beginFromDay.toLowerCase();
        });
      }
      return [];
    },
    calendarYearLabel() {
      return this.askedMonth(this.cursor).format("YYYY");
    },
    calendarMonthLabel() {
      return this.askedMonth(this.cursor).format("MMMM");
    },
    /**
     * Colouring of cells, based on a filter choosen and if shifts > 0.
     */
    classNameFilter() {
      /**
       * Calculate the name of the class based on the filter in use.
       * day is entire cell data object that holds shifts property
       * partOfTheDay is additional property to indicate if it is day or night part of the day
       *
       */
      return function (day, partOfTheDay) {
        return {
          [" visual__day--" + this.activeFilter.substring(7)]:
            partOfTheDay === 1 &&
            this.$options.filters.filterThrough(
              this.shiftsCounter(day, 1),
              this.activeFilter,
              day,
              1
            ),
          [" visual__night--" + this.activeFilter.substring(7)]:
            partOfTheDay === 2 &&
            this.$options.filters.filterThrough(
              this.shiftsCounter(day, 2),
              this.activeFilter,
              day,
              2
            ),
        };
      };
    },
    shiftsCounter() {
      return function (day, partOfTheDay) {
        let currentDay = day ? day[this.getProp("root", day)] : null;

        let open = currentDay && currentDay["open"] ? currentDay["open"] : null;
        let filled =
          currentDay && currentDay["filled"] ? currentDay["filled"] : null;
        let approvalNeeded =
          currentDay && currentDay["approvalNeeded"]
            ? currentDay["approvalNeeded"]
            : null;

        if (partOfTheDay === 1) {
          let dayOpen = open && open.dayShiftsCount ? open.dayShiftsCount : 0;
          let dayFilled =
            filled && filled.dayShiftsCount ? filled.dayShiftsCount : 0;
          let dayApprovalNeeded =
            approvalNeeded && approvalNeeded.dayShiftsCount
              ? approvalNeeded.dayShiftsCount
              : 0;

          return dayOpen + dayFilled + dayApprovalNeeded;
        } else if (partOfTheDay === 2) {
          let nightOpen =
            open && open.nightShiftsCount ? open.nightShiftsCount : 0;
          let nightFilled =
            filled && filled.nightShiftsCount ? filled.nightShiftsCount : 0;
          let nightApprovalNeeded =
            approvalNeeded && approvalNeeded.nightShiftsCount
              ? approvalNeeded.nightShiftsCount
              : 0;

          return nightOpen + nightFilled + nightApprovalNeeded;
        }
      };
    },
  },
  filters: {
    /**
     * This is the main filtering engine that will filter out
     * shift objects being booked (filled), available (open), action_needed (approval_needed) or none (meaning all)
     *
     *  Shift has a TEMP → filter state is `Booked`
     *  Shift does not have a TEMP → filter state is `Available`
     *  Shift has isConfirmedByClient set to `false` → filter state is `Action Needed`
     *
     */
    filterThrough: function (shifts, filter, day, partOfTheDay) {
      if (shifts) {
        let currentDay = day ? day[Object.keys(day)[0]] : {};
        /**
         * Filter action needed
         * IMPORTANT this must be the first filter in order to check, because
         * having temp or not is irrelevant here...
         */
        let filterMap = {
          filter_approval_needed: "approvalNeeded",
          filter_filled: "filled",
          filter_open: "open",
        };

        let partOfDayMap = {
          1: "dayShiftsCount",
          2: "nightShiftsCount",
        };

        let shiftType = filterMap[filter];
        let partOfDay = partOfDayMap[partOfTheDay];

        if (shiftType && partOfDay) {
          // If there are shifts for current day and for the filter
          if (currentDay && currentDay[shiftType]) {
            return currentDay[shiftType][partOfDay] > 0
              ? currentDay[shiftType][partOfDay]
              : "";
          }
          // Otherwise, return empty string
          return "";
        }
        /**
         * If none of the above applies, return them all !
         */
        return shifts;
      }
    },

    /**
     * Just returns count of what ever value comes in
     * shifts will be previously filtered by all | booked | available or action_needed (approval_needed)
     */
    count: function (value) {
      // return number of shifts or ''(empty cell) when none.
      return value.length ? value.length : "";
    },
  },
  watch: {
    cellData() {
      this.beginFromDay = this.getProp("weekday", this.cellData[0]);
    },
    /**
     * Update date below calendar, for selected cell
     */
    activeFilter() {
      this.shiftRowUpdate(); // apply filter in use
    },
    cursor() {
      this.resetActiveDay();
      this.renderCalendar();
    },
  },
  mounted() {
    this.renderCalendar();
    this.getClients();
  },
  methods: {
    // Get shifts in date range from api
    ...mapActions([
      "getShiftsForDateRange",
      "getShiftsForSingleDay",
      "fillMonthDateStrings",
      "fetchClientsList",
      "setCalendarDaysArr",
    ]),
    isSelectedCell(day) {
      if (this.activeDay) {
        return Object.keys(this.activeDay)[0] === Object.keys(day)[0];
      }
      return false;
    },
    putTodaySelection() {
      this.cursor = 0;
      let today = this.getTodayCell();
      if (today) {
        today.classList.add("is-selected-cell");
        today.click();
      }
      /**
       * Initiate <shift-row> component by triggering click event
       * this will also set instance activeDay property to a day object
       */
    },
    getTodayCell() {
      let todayString = moment().format("YYYY-MM-DD");
      let todayEl = document.getElementById(todayString);
      return todayEl;
    },
    checkApprovalNeeded(day, partOfDay, openFilledStr) {
      const currentDay = day ? day[Object.keys(day)[0]] : {};
      const approvalDay = currentDay.approvalNeeded;
      const isApprovalFilter = this.activeFilter === "filter_approval_needed";

      if (approvalDay && isApprovalFilter) {
        let partOfDayMap = {
          1: "dayShiftsCount",
          2: "nightShiftsCount",
        };

        const currentShiftDayNight = approvalDay[partOfDayMap[partOfDay]];
        if (currentShiftDayNight) {
          const val = currentShiftDayNight[openFilledStr];
          if (val) {
            return "has-approval";
          }
        }
      }
      return null;
    },
    changeYear(evt) {
      const setYear = parseInt(evt.target.value);
      console.log("YEAR --", setYear);
      const originalYear = parseInt(this.calendarYearLabel);
      const MONTHS = 12;
      const yearDiffCursor = (setYear - originalYear) * MONTHS;
      this.cursor = this.cursor + yearDiffCursor;
    },
    async getClients() {
      this.isLoadingClient = true;
      try {
        await this.fetchClientsList({
          includes: ["locations"].join(","),
          per_page: 999,
        });
        // If Client admin preselect first client
        const adminSuperAgency = this.$can("view-temps");
        if (!adminSuperAgency) {
          const firstClient = this.clients?.[0];
          this.fireSearch(firstClient, "client");
        }
        this.isLoadingClient = false;
      } catch (err) {
        this.isLoadingClient = false;
        console.warn(err.message);
      }
    },
    getClassNameFilter(day, partOfTheDay) {
      if (partOfTheDay === 1) {
        // Day
        const classNamePart = `visual__day--${this.activeFilter.substring(7)}`;
        return classNamePart;
      } else if (partOfTheDay === 2) {
        // Night
        const classNamePart = `visual__night--${this.activeFilter.substring(
          7
        )}`;
        return classNamePart;
      }
    },
    getDayNightOpenFilledCo(day, partOfDay, openFilledStr) {
      const currentDay = day ? day[Object.keys(day)[0]] : {};
      const currentShiftType = currentDay[openFilledStr];

      let partOfDayMap = {
        1: "dayShiftsCount",
        2: "nightShiftsCount",
      };

      const getApprovalNeeded = () => {
        const approvalObj = currentDay.approvalNeeded;
        if (approvalObj) {
          const dayApproval = approvalObj[partOfDayMap[partOfDay]];
          if (dayApproval) {
            return dayApproval[openFilledStr] ? dayApproval[openFilledStr] : 0;
          }
        }

        return 0;
      };

      if (this.activeFilter === "filter_open") {
        if (openFilledStr === "filled") {
          return "";
        }
      } else if (this.activeFilter === "filter_filled") {
        if (openFilledStr === "open") {
          return "";
        }
      } else if (this.activeFilter === "filter_approval_needed") {
        if (["open", "filled"].includes(openFilledStr)) {
          const approvalCo = getApprovalNeeded();
          return approvalCo > 0 ? approvalCo : "";
        }
      }

      if (currentShiftType) {
        const totalOpenAndFilled = currentShiftType[partOfDayMap[partOfDay]];

        let approvalCo = 0;
        // if (this.activeFilter === 'filter_all') {
        //   approvalCo = getApprovalNeeded()
        // }
        const totalCo = totalOpenAndFilled + approvalCo;
        return totalCo > 0 ? totalCo : "";
      }
    },
    filterByAction(filterBtn) {
      this.activeFilter = filterBtn.name;
      if (this.activeFilter === "filter_available") {
        // Temp calendar trigger check
        this.renderCalendar(false);
      }
    },
    fireSearch: _.debounce(function (selected, id) {
      switch (id) {
        case "client":
          this.selectedClient = selected || null;
          this.selectedLocation = [];
          this.locationApiArr = [];
          break;
      }
      this.invokeSearch();
    }, 300),
    fireSearchMultiple: _.debounce(function (selected, id) {
      switch (id) {
        case "location":
          this.locationApiArr = this.formatLocationMultApi(selected);
          break;
      }
      this.invokeSearch();
    }, 300),
    formatLocationMultApi(objChange) {
      const isFoundObj = this.selectedLocation.find(
        (cc) => cc.id === objChange.id
      );
      if (isFoundObj) {
        // Remove
        this.selectedLocation = this.selectedLocation.filter(
          (cc) => cc.id !== objChange.id
        );
      } else {
        // Add
        this.selectedLocation.push(objChange);
      }

      const locationApiIds = [];
      for (const cc of this.selectedLocation) {
        locationApiIds.push({ id: cc.id });
      }
      return locationApiIds;
    },
    invokeSearch() {
      this.renderCalendar(false);
    },
    removeFilter(removed, id) {
      if (id === "location") {
        // this.selectedLocation = []
        this.fireSearchMultiple(removed, id);
      } else if (id === "client") {
        this.selectedClient = null;
        this.selectedLocation = [];
        this.fireSearch();
      }
    },

    /**
     * Updates cell shifts details view, below the calendar
     */
    shiftRowUpdate() {
      if (this.activeDay) {
        let dayString = Object.keys(this.activeDay)[0];

        const params = {
          date: dayString,
        };
        // Add client, WARD
        if (this.selectedClient && this.selectedClient.id) {
          params["clients[][id]"] = this.selectedClient.id;
        }
        if (this.locationApiArr && this.locationApiArr.length) {
          params.location = this.locationApiArr;
        }

        this.isShiftRowLoading = true;
        this.getShiftsForSingleDay(params)
          .then((data) => {
            this.activeCellShiftsArr = [];
            let singleDayShifts = data.data.data;

            const addShiftStatus = (shift) => {
              return Object.assign(shift, {
                _shiftStatus: shiftStatusTemporary,
              });
            };

            // Setting open
            let shiftStatusTemporary = "open";
            let openShifts = this.extractingShifts("open", singleDayShifts).map(
              addShiftStatus
            );

            // Setting filled
            shiftStatusTemporary = "filled";
            let filledShifts = this.extractingShifts(
              "filled",
              singleDayShifts
            ).map(addShiftStatus);

            let approvalNeededArrDayNight = singleDayShifts.approvalNeeded;
            const approvalNeededShifts = [];
            if (approvalNeededArrDayNight) {
              const apprDay = approvalNeededArrDayNight.dayShifts;
              const apprNight = approvalNeededArrDayNight.nightShifts;
              if (apprDay) {
                if (apprDay.open) {
                  approvalNeededShifts.push(...apprDay.open);
                }
                if (apprDay.filled) {
                  approvalNeededShifts.push(...apprDay.filled);
                }
              }
              if (apprNight) {
                if (apprNight.open) {
                  approvalNeededShifts.push(...apprNight.open);
                }
                if (apprNight.filled) {
                  approvalNeededShifts.push(...apprNight.filled);
                }
              }
            }

            // Setting approvalNeeded
            shiftStatusTemporary = "approval-needed";
            const approvalShiftFormatted =
              approvalNeededShifts.map(addShiftStatus);

            if (this.activeFilter === "filter_open") {
              this.activeCellShiftsArr = openShifts;
            } else if (this.activeFilter === "filter_filled") {
              console.warn("filled shifts", filledShifts);
              this.activeCellShiftsArr = filledShifts;
            } else if (this.activeFilter === "filter_approval_needed") {
              this.activeCellShiftsArr = approvalShiftFormatted;
            } else {
              const approvalIds = approvalShiftFormatted.map(
                (shift) => shift.id
              );
              const openWithoutApproval = openShifts.filter(
                (shift) => !approvalIds.includes(shift.id)
              );
              const filledWithoutApproval = filledShifts.filter(
                (shift) => !approvalIds.includes(shift.id)
              );
              const sortAscId = (a, b) => {
                if (a.endTime < b.endTime && a.id < b.id) {
                  return -1;
                } else if (a.endTime > b.endTime && a.id > b.id) {
                  return 1;
                }
                return 0;
              };

              this.activeCellShiftsArr = [
                ...openWithoutApproval,
                ...filledWithoutApproval,
                ...approvalShiftFormatted,
              ].sort(sortAscId);
            }
            this.isShiftRowLoading = false;
          })
          .catch(() => {
            this.isShiftRowLoading = false;
          });
      }
    },
    resetActiveDay() {
      this.activeDay = null;
      // Reset active cell
      this.activeCellShiftsArr = [];
    },
    extractingShifts(filter, singleDayShifts) {
      let shiftArray = [];
      let areThereShifts =
        singleDayShifts && singleDayShifts[filter]
          ? singleDayShifts[filter]
          : [];

      if (areThereShifts) {
        let dayShifts = areThereShifts.dayShifts
          ? areThereShifts.dayShifts
          : null;
        let nightShifts = areThereShifts.nightShifts
          ? areThereShifts.nightShifts
          : null;

        if (dayShifts) {
          for (let i = 0; i < dayShifts.length; i++) {
            shiftArray.push(dayShifts[i]);
          }
        }

        if (nightShifts) {
          for (let i = 0; i < nightShifts.length; i++) {
            shiftArray.push(nightShifts[i]);
          }
        }
      }
      return shiftArray;
    },

    show(day) {
      // console.log('day in show() : ', day)
      /**
       * Keep a reference of currently selected cell
       */
      this.activeDay = day;
      this.shiftRowUpdate();
    },
    /**
     * dayOrNight can be 1 or 2, 1 for day, 2 for night
     * based on which one is passed, returns shifts where day or night
     */
    getShifts(shifts, dayOrNight) {
      if (shifts) {
        return _.filter(shifts, (shift) => {
          return shift.partOfTheDay === dayOrNight;
        });
      }
    },

    getProp(prop, obj) {
      let root = Object.keys(obj)[0];
      let unknown = obj[root];

      if (prop === "root") {
        let _m = moment(root, "YYYY-MM-DD");
        return _m.format("YYYY-MM-DD").toString();
      }

      if (unknown != null) {
        if (prop in unknown) {
          // console.warn('prop exists')
          return unknown[prop];
        } else {
          // console.warn('prop does not exists') // TODO wtf?
          return "-";
        }
      }
    },

    /**
     * Retruns moment object with offset by [cursor]
     */
    askedMonth(cursor) {
      let m;
      if (cursor < 0) {
        m = moment().subtract(Math.abs(cursor), "months");
      } else if (cursor > 0) {
        m = moment().add(Math.abs(cursor), "months");
      } else {
        m = moment();
      }
      return m;
    },

    /**
     * Draws calendar
     */
    async renderCalendar(isChangeDayFocus = true, moreParams = {}) {
      try {
        // on mount this is always 0 (meaning current month) (+n adds n months, -n subtracts n months)
        await this.fillMonthDateStrings({ cursor: this.cursor });
        let m = this.askedMonth(this.cursor);

        this.dateRange.dateFrom = m.startOf("month").format("YYYY-MM-DD");
        this.dateRange.dateTo = m.endOf("month").format("YYYY-MM-DD");

        const isTempRoute = this.$route.name === "temp-calendar";
        if (
          isTempRoute &&
          !this.$route.params.tempId &&
          !this.$can("edit-profile")
        ) {
          // Inital load [don't fetch cal data]
          // console.log('>> Clearing cal');
          this.setCalendarDaysArr({});
          return;
        }

        // Setting params
        const tempIdObj = {};
        let clCoCC = {};
        if (this.$route.params.tempId) {
          tempIdObj.tempId = this.$route.params.tempId;
        } else {
          // Add client, WARD
          clCoCC = {
            "clients[][id]": this.selectedClient && this.selectedClient.id,
            location: this.locationApiArr,
          };
        }

        // Additional checks [for available]
        if (this.activeFilter === "filter_available") {
          // Temp calendar only
          tempIdObj.show_available_shifts = true;
        }

        // If there is a request pending, cancel it
        if (this.cancelToken !== null) {
          this.cancelToken.cancel();
          this.cancelToken = null;
        }
        let CancelToken = axios.CancelToken;
        let source = CancelToken?.source();

        // Remember the token for cancellation
        this.cancelToken = source;
        const payload = {
          ...moreParams,
          ...this.dateRange,
          ...tempIdObj,
          ...clCoCC,
        };
        const apiPayload = {
          payload,
          cancelTokenSource: source,
        };

        this.isLoading = true;
        try {
          await this.getShiftsForDateRange(apiPayload);
          this.isLoading = false;

          if (isChangeDayFocus) {
            if (this.cursor === 0) {
              // Switches to current day (invokes day selection) [Curr month]
              // put is-selected-cell on cell which has an id of 'YYYY-MM-DD'
              this.putTodaySelection();
            } else {
              // Removes selection
              let today = this.getTodayCell();
              if (!today) {
                let notToday = document.querySelector(".is-selected-cell");
                if (notToday) {
                  notToday.classList.remove("is-selected-cell");
                }
              }
            }
          } else {
            // Re-invoke day select function
            let daySelected = document.querySelector(".is-selected-cell");
            if (daySelected) {
              daySelected.click();
            }
          }
        } catch (err) {
          console.warn(err.message);
        }
      } catch (err) {
        this.isLoading = false;
        console.log(err.message);
      }
    },
  },
};
