<template>
  <div class="one-temp-calendar">
    <temp-calendar :initialTempData="initialTempData" />
  </div>
</template>

<script>
import Calendar from './Calendar.vue';
import { mapActions } from 'vuex';
export default {
  name: 'OneTempCalendarWrap',
  components: {
    TempCalendar: Calendar,
  },
  data () {
    return {
      initialTempData: {},
    };
  },
  mounted () {
    this.fetchTempInfo();
  },
  methods: {
    ...mapActions({
      fetchTempProfile: 'fetchTempProfile',
    }),
    async fetchTempInfo () {
      const params = {
        tempId: this.$route.params.tempId,
      };
      try {
        const res = await this.fetchTempProfile(params);
        if (res && res.data && res.data.data) {
          this.initialTempData = res.data.data;
        }
      } catch (err) {
        console.warn(err.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
