<template lang="pug">
div
  .inputs
    .field
      label.label Expiry date
      p.control
        date-picker(:date="dt.expiry", :option="dateTimeOption")
  span.help.is-danger(
    v-if="errors.has('end_time')",
    v-text="errors.get('end_time')"
  ) 
  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      @click.prevent="saveTime"
    ) SUBMIT
    button.button.is-outlined.is-caps-lock(@click.prevent="callModalClose") CANCEL
</template>
<script>
import calendarOptions from '../../common/calendarOptions.js';
import { Errors } from '../../../lib/helpers/Errors.js';
import moment from 'moment';
import { getFormattedTime, parseErrors } from '../../../lib/helpers/function.js';
import { mapActions } from 'vuex';

export default {
  props: ['modalProps'],
  data () {
    return {
      errors: new Errors(),
      dateTimeOption: null,
      MultiShift: null,
      dt: {
        expiry: { time: '' },
      },
    };
  },
  created () {
    this.setupDateTimePickerOpt();
  },
  mounted () {
    console.log(this.modalExpiryTime);
    this.dt.expiry = { time: this.modalExpiryTime };
  },
  computed: {
    modalExpiryTime () {
      if (this.modalProps.expiryDate === null) {
        return '';
      }

      return moment(this.modalProps.expiryDate, 'YYYY-MM-DD')
        .format('DD/MM/YYYY').valueOf();
    },
  },
  methods: {
    ...mapActions(['changeExpiryDate', 'updateExpiryDate']),
    setupDateTimePickerOpt () {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = 'day';
      this.dateTimeOption.format = 'DD/MM/YYYY';
    },
    async saveTime () {
      let { id, temp } = this.modalProps; // these two come from a document
      let context = this.modalProps.context;
      console.log(context);
      let newExpiryDate = getFormattedTime(this.dt.expiry.time, 'YYYY-MM-DD', 'DD/MM/YYYY'); // this on is set in datepicker
      // if (context !== 'tempDocuments') {
      //   try {
      //     await this.changeExpiryDate({
      //       id, temp, newExpiryDate, modifiers: { context: context }
      //     })
      //     this.callModalClose()
      //     this.$toasted.info(
      //       'Expiry time changed.',
      //       { theme: 'outline' }
      //     ).goAway(2000)
      //   } catch (error) {
      //     this.$toasted.error(parseErrors(error)).goAway(4500)
      //     return
      //   }
      // } else {
      try {
        await this.updateExpiryDate({
          id, temp, newExpiryDate, modifiers: { context: context },
        });
        this.callModalClose();
        this.$toasted.info(
          'Expiry time changed.',
          { theme: 'outline' }
        ).goAway(2000);
      } catch (error) {
        this.$toasted.error(parseErrors(error)).goAway(4500);
        return;
      }
      // }
    },
    callModalClose () {
      this.$emit('cancel-modal');
    },
  },
};
</script>
<style scoped lang="scss">
.inputs {
  display: flex;
  justify-content: center;

  .field {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.cov-vue-date,
.datepickbox input {
  display: block;
  width: 100% !important;
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}
</style>
