<template>
  <div class="custom-flatpickr"></div>
</template>

<script>
import Flatpickr from "flatpickr";
import CalendarUtils from "../lib/helpers/CalendarUtils";
import moment from "moment";
import * as _ from "underscore";

export default {
  name: "flatpickr",
  props: [
    "noDisableDate",
    "startTime",
    "endTime",
    "selectedDates",
    "hcStartTime",
    "hcEndTime",
    "config",
    "onChange",
  ],
  data() {
    return {
      selectedDays: this.selectedDates,
      dayElements: [],
    };
  },
  watch: {
    startTime: {
      handler() {
        this.checkForTime();
      },
      deep: true,
    },
    endTime: {
      handler() {
        this.checkForTime();
      },
      deep: true,
    },
    selectedDates(values) {
      // Not sure why this block is needed
      this.selectedDays = values;
      this.Flatpickr.selectedDates = _.map(values, "date");
      this.redraw();
    },
  },
  methods: {
    clearTime(dayElem) {
      dayElem.classList.remove("is-for-change");
      let dayIndex = _.findIndex(this.selectedDays, {
        id: dayElem.getAttribute("aria-label"),
      });
      this.selectedDays.splice(dayIndex, 1);
      this.Flatpickr.selectedDates = _.map(this.selectedDays, "date");
      this.redraw();
      this.$emit("days-selected", this.selectedDays);
    },
    styleDays() {
      this.dayElements.forEach((dayElem) => {
        if (dayElem.classList.contains("selected")) {
          let day = _.find(this.selectedDays, {
            id: dayElem.getAttribute("aria-label"),
          });
          if (!day) {
            return;
          }

          dayElem.classList.remove("with-time");
          dayElem.classList.remove("is-for-change");

          if (day.startTime && day.endTime) {
            dayElem.classList.add("with-time");

            if (day.isForChange) {
              dayElem.classList.add("is-for-change");
            }
          }
        }
      });
    },
    redraw() {
      this.Flatpickr.redraw();
      this.styleDays();
    },
    getStartAndEndTimeDateObjects(date) {
      let startHoursAndMinutes = CalendarUtils.getHoursAndMinutesFromString(
        this.startTime.time
      );
      let momentStartTime = moment(date.toISOString()).set({
        hour: startHoursAndMinutes.hours,
        minute: startHoursAndMinutes.minutes,
      });
      let endHoursAndMinutes = CalendarUtils.getHoursAndMinutesFromString(
        this.endTime.time
      );
      let momentEndTime = moment(date.toISOString()).set({
        hour: endHoursAndMinutes.hours,
        minute: endHoursAndMinutes.minutes,
      });
      if (this.startTime.time && momentStartTime.isAfter(momentEndTime)) {
        momentEndTime.add(1, "day");
      }
      return {
        startTime: momentStartTime.toDate(),
        endTime: momentEndTime.toDate(),
      };
    },
    checkForTime() {
      if (!_.isEmpty(this.startTime.time) && !_.isEmpty(this.endTime.time)) {
        let daysWithEmptyTime = _.filter(this.selectedDays, (day) => {
          return (
            (!_.isDate(day.startTime) && !_.isDate(day.endTime)) ||
            day.isForChange
          );
        });

        _.each(daysWithEmptyTime, (day) => {
          let startTimeAndEndTime = this.getStartAndEndTimeDateObjects(
            day.date
          );
          day.startTime = startTimeAndEndTime.startTime;
          day.endTime = startTimeAndEndTime.endTime;
        });
        this.styleDays();
      }
    },
  },
  mounted() {
    this.Flatpickr = Flatpickr(this.$el, {
      mode: "range",
      inline: true,
      appendTo: this.$el,
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["S", "M", "T", "W", "T", "F", "S"],
        },
      },
      disable: [
        (date) => {
          if (this.noDisableDate) {
            // All dates available
            return false;
          }

          let now = new Date();
          now.setHours(0, 0, 0, 0);
          return date < now;
        },
      ],
      onDayCreate: (dObj, dStr, fp, dayElem) => {
        let clearDayElement = document.createElement("img");
        clearDayElement.src = require("./../assets/images/comps/delete-larger.svg");
        clearDayElement.className = "flatpickr__clear-date";
        clearDayElement.onmousedown = (evt) => {
          evt.preventDefault();
          event.stopPropagation();
          this.clearTime(dayElem);
        };
        dayElem.appendChild(clearDayElement);
        if (
          dayElem.className.indexOf("startRange") === -1 &&
          dayElem.className.indexOf("endRange") === -1 &&
          this.dayElements.indexOf(dayElem) === -1
        ) {
          this.dayElements.push(dayElem);
        }
        dayElem.onmousedown = (event) => {
          if (dayElem.classList.contains("with-time")) {
            // Needed for unchecking of "is-for-change" elements
            fp.setDate(dObj);

            event.preventDefault();
            event.stopPropagation();

            // Remove all before adding one
            this.$el
              .querySelectorAll(".flatpickr-day.with-time")
              .forEach((el) => {
                el.classList.remove("is-for-change");
              });

            // Toggle selection
            let isDayElementSelected =
              dayElem.classList.contains("is-for-change");
            if (isDayElementSelected) {
              dayElem.classList.remove("is-for-change");
            } else {
              dayElem.classList.add("is-for-change");
            }

            this.selectedDays.forEach((day) => {
              day.isForChange =
                day.id === dayElem.getAttribute("aria-label") &&
                !isDayElementSelected;
              if (day.isForChange) {
                this.$emit("day-for-change", day);
              }
            });

            // Needed to update for change value
            this.redraw();
          }
        };
      },
      onChange: (selectedDates, dateStr, instance) => {
        if (dateStr.indexOf("to") > -1) {
          let datesMapped = _.map(selectedDates, (d) => moment(d));
          instance.config.mode = "multiple";
          instance.selectedDates = CalendarUtils.getDatesBetween(
            ...datesMapped,
            true
          );
        }

        this.selectedDays = _.map(instance.selectedDates, (day) => {
          let id = moment(day.toISOString()).format("MMMM D, YYYY");
          let old = _.find(this.selectedDays, { id: id });

          let oldStartTime = old ? old.startTime : null;
          let oldEndTime = old ? old.endTime : null;

          if (
            !_.isDate(oldStartTime) &&
            !_.isDate(oldEndTime) &&
            !_.isEmpty(this.startTime.time) &&
            !_.isEmpty(this.endTime.time)
          ) {
            let startTimeAndEndTime = this.getStartAndEndTimeDateObjects(day);
            oldStartTime = startTimeAndEndTime.startTime;
            oldEndTime = startTimeAndEndTime.endTime;
          }

          const payload = {
            id: id,
            date: day,
            startTime: oldStartTime || null,
            endTime: oldEndTime || null,
            isForChange: old ? old.isForChange : false,
            numberOfShifts: old ? old.numberOfShifts : 1,
          };

          if (old && old.shift_parts && old.shift_parts_time) {
            payload.shift_parts = old.shift_parts;
            payload.shift_parts_time = old.shift_parts_time;
          } else if (!old && this.hcStartTime?.time && this.hcEndTime?.time) {
            payload.shift_parts_time = {
              from: this.hcStartTime.time,
              to: this.hcEndTime.time,
            };
            payload.shift_parts = `${this.hcStartTime.time} - ${this.hcEndTime.time}`;
          }

          return payload;
        });

        this.redraw();
        this.$emit("days-selected", this.selectedDays);
      },
      onMonthChange: () => {
        this.redraw();
      },
      onYearChange: () => {
        this.redraw();
      },
    });

    if (this.config) {
      this.Flatpickr.config = {
        ...this.Flatpickr.config,
        ...this.config,
      };
    }
  },
};
</script>

<style lang="scss">
.custom-flatpickr {
  .flatpickr-calendar {
    width: 100%;

    .flatpickr-month {
      border-bottom: 1px solid #cdcdcd;
      height: 50px;
      padding-top: 8px;

      .cur-month {
        font-weight: 100;
      }
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      z-index: 1; // z-0 doesn't work for prev. month arrow
      top: 8px;
      padding: 10px calc(3.57% - 1.5px);
    }

    .flatpickr-rContainer {
      margin-left: auto;
      margin-right: auto;

      .flatpickr-weekdays {
        height: 60px;
        font-size: 20px;
        font-weight: 100;
      }

      .flatpickr-days {
        width: 100%;
        padding-top: 10px;

        .dayContainer {
          width: 100%;
          min-width: 100%;
        }
      }
    }

    &.arrowTop,
    &.arrowBottom {
      &::before,
      &::after {
        content: none;
      }
    }

    .flatpickr__clear-date {
      display: none;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      width: 21px;
      height: 21px;
      top: -9px;
      right: -9px;
      cursor: pointer;
    }

    .flatpickr-day {
      border-radius: 3px;
      margin-bottom: 5px;
      margin-right: 3px;
      font-size: 19px;
      max-width: 36px;
      height: 36px;
      line-height: 33px;

      &.selected {
        background-color: #74b5fd;

        &.with-time {
          background-color: #1173d6;

          &.is-for-change {
            background-color: #e3111b;
          }

          &:hover {
            .flatpickr__clear-date {
              display: block;
            }
          }
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .flatpickr-calendar {
      .flatpickr-day {
        color: rgba(57, 57, 57, 0.1);
        // background-color: rgba(57, 57, 57, 0.1);
      }
    }
  }

  // handling different screen sizes
  @media screen and (min-width: 560px) and (max-width: 960px) {
    .flatpickr-rContainer {
      width: 432px;

      .dayContainer {
        padding-right: 10px;
        padding-left: 10px;

        .flatpickr-day {
          margin-right: 18.24px;

          &:nth-child(7n + 0) {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1221px) {
    .flatpickr-rContainer {
      width: 600px;

      .dayContainer {
        padding-left: 24px;
        padding-right: 24px;

        .flatpickr-day {
          margin-right: 46px;

          &:nth-child(7n + 0) {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1222px) {
    .flatpickr-rContainer {
      width: 740px;

      .dayContainer {
        padding-left: 29px;
        padding-right: 29px;

        .flatpickr-day {
          margin-right: 58px;

          &:nth-child(7n + 0) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
