<template lang="pug">
.table-wrap
  table.table.sticky
    thead
      tr
        th.is-narrow
          span Request ID
        th
          span Requested Amount
        th.is-narrow
          span Request Status
        th.is-narrow
          span Created at
        th.is-narrow
          span Updated at
        th.is-narrow
          span Cancelled at
    tbody
      tr(
        :class="{ private: holidayReqItem.isPrivate }",
        v-for="(holidayReqItem, index) in filteredHolidayRequestList",
        :key="index"
      )
        td
          span {{ holidayReqItem.id }}
        td
          span {{ holidayReqItem.value }}
        td
          span {{ holidayReqItem.status }}
        td.is-narrow
          span {{ getTime(holidayReqItem.created_at) }}
        td.is-narrow
          span {{ getTime(holidayReqItem.updated_at) }}
        td.is-narrow
          span {{ getTime(holidayReqItem.deleted_at) }}
      tr.empty(v-if="filteredHolidayRequestList.length === 0")
        td(colspan="100", style="text-align: center") No data in table
</template>

<script>
import {
  momentDateTimeFromApi,
  parseDateObjWithTimeZone,
} from "../../lib/helpers/function";
export default {
  props: {
    holidayRequests: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredHolidayRequestList() {
      return this.holidayRequests || [];
    },
  },
  methods: {
    getTime(dateUsed) {
      const dateIso = parseDateObjWithTimeZone(dateUsed);
      const parsedDate = momentDateTimeFromApi(dateIso);
      return parsedDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrap {
  display: flex;
  overflow: auto;

  .table {
    tr.private {
      background: #f7f7f7;
    }

    &.sticky {
      border-collapse: separate;
      border-spacing: 0;
    }
  }
}
</style>
