<template lang="pug">
.multiple-wrap
  .table-wrap(ref="list")
    table.table
      thead
        tr
          th
            span Shift ID
          th
            span Name
          th
            span Client
          th
            span Ward
          th
            span Subcategories
          th
            span Start Time
          th
            span End Time
          template(v-if="isAnyShiftOnCall")
            th
              span On Call Start Time
            th
              span On Call End Time
          template(v-if="isAnyShiftSleeopver")
            th
              span Sleepover Start Time
            th
              span Sleepover End Time
          template(v-if="isAnyShiftSleeopver")
            th
              span Was the sleeping time interrupted?
          template(v-if="isAnyShiftAwaken")
            th
              span Awaken Start Time
            th
              span Awaken End Time
          th
            span Break
          th
            span Status
      tbody(v-if="localShifts && localShifts.length > 0")
        tr(v-for="(shift, index) in localShifts", :key="shift.id")
          td
            span {{ shift.id }}
          td
            span {{ shift.temp.name }}
          td
            span {{ shift.location.client.name }}
          td
            multi-select(
              :id="shift.id",
              :options="shift.location.client.locations",
              :value="{ id: shift.id, name: shift.location.name }",
              track-by="id",
              label="name",
              @select="onChangeCostCentre(shift, $event, index)"
            )
          td
            span {{ shift.shiftRequest.subcategories.map((subCat) => subCat.name).join(", ") }}
          td
            date-picker(
              :date="{ time: formatDate(shift.startTime.date) }",
              :option="dateTimeOption",
              @change="onChangeStartDate(index, 'startTime', $event)"
            )
          td
            date-picker(
              :isDisabled="shift.endTime.date === null",
              :option="dateTimeOption",
              :date="{ time: formatDate(shift.endTime.date) }",
              @change="setDate(index, 'endTime', $event)"
            )
          //- ONCALL
          template(v-if="isAnyShiftOnCall")
            td
              date-picker(
                :date="{ time: parsePartDate(shift, 'from', SHIFT_TYPES.oncall.key) }",
                :option="dateTimeOption",
                v-if="shift.type === SHIFT_TYPES.oncall.key",
                @change="onChangeDatePart(index, 'from', $event, SHIFT_TYPES.oncall.key)"
              )
            td
              date-picker(
                :option="dateTimeOption",
                :date="{ time: parsePartDate(shift, 'to', SHIFT_TYPES.oncall.key) }",
                v-if="shift.type === SHIFT_TYPES.oncall.key",
                @change="onChangeDatePart(index, 'to', $event, SHIFT_TYPES.oncall.key)"
              )
          //- SLEEPOVER
          template(v-if="isAnyShiftSleeopver")
            td
              date-picker(
                :date="{ time: parsePartDate(shift, 'from', SHIFT_TYPES.sleepover.key) }",
                :option="dateTimeOption",
                v-if="shift.type === SHIFT_TYPES.sleepover.key",
                @change="onChangeDatePart(index, 'from', $event, SHIFT_TYPES.sleepover.key)"
              )
            td
              date-picker(
                :option="dateTimeOption",
                :date="{ time: parsePartDate(shift, 'to', SHIFT_TYPES.sleepover.key) }",
                v-if="shift.type === SHIFT_TYPES.sleepover.key",
                @change="onChangeDatePart(index, 'to', $event, SHIFT_TYPES.sleepover.key)"
              )
          template(v-if="isAnyShiftSleeopver")
            td(style="text-align: center")
              input(
                v-if="shift.type === SHIFT_TYPES.sleepover.key",
                v-model="shift.isAwaken",
                type="checkbox"
              )
          //- AWAKEN
          template(v-if="isAnyShiftAwaken")
            td
              date-picker(
                v-if="shift.isAwaken",
                :date="{ time: parsePartDate(shift, 'from', SHIFT_TYPES.awaken.key) }",
                :option="dateTimeOption",
                @change="onChangeDatePart(index, 'from', $event, SHIFT_TYPES.awaken.key)"
              )
            td
              date-picker(
                v-if="shift.isAwaken",
                :date="{ time: parsePartDate(shift, 'to', SHIFT_TYPES.awaken.key) }",
                :option="dateTimeOption",
                @change="onChangeDatePart(index, 'to', $event, SHIFT_TYPES.awaken.key)"
              )
          td
            .control
              input.input(
                :value="shift.breakMinutes",
                :disabled="shift.type === SHIFT_TYPES.oncall.key || shift.type === SHIFT_TYPES.sleepover.key",
                :style="{ width: '80px' }",
                @input="setBreakMinutes(index, $event.target.value)"
              )
              span.is-suffix in minutes
          td
            span(
              v-if="shift.error",
              style="color: darkred; display: block; min-width: 150px"
            ) {{ shift.error }}
            span(
              v-if="shift.success",
              style="color: green; display: block; min-width: 150px"
            ) {{ shift.success }}
      tbody(v-if="localShifts.length === 0")
        tr
          td
            | No data in table
  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(
      v-if="!isAllShiftsSubmittedSuccessfully",
      @click.prevent="onSubmitShifts",
      :disabled="disableButton"
    ) 
      img.btn-loader(
        src="../../assets/images/comps/loader.svg",
        v-if="disableButton"
      )
      span SIGN OFF SHIFTS
    button.button.is-outlined.is-caps-lock(
      @click.prevent="$parent.$emit('close-modal')"
    ) {{ isAllShiftsSubmittedSuccessfully ? "OK" : "CANCEL" }}
</template>

<script>
import MultiSelect from "vue-multiselect";
import calendarOptions from "../common/calendarOptions.js";
import { Errors } from "../../lib/helpers/Errors.js";
import { parseErrors } from "../../lib/helpers/function.js";
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import { SHIFT_TYPES } from "../../shared/mapData.js";
export default {
  components: {
    MultiSelect,
  },
  props: {
    modalProps: Array,
  },
  data() {
    return {
      localShifts: [],
      oldLocalShifts: [],
      dateTimeOption: null,
      MultiShift: null,
      errors: new Errors(),
      disableButton: false,
      isDataChanged: false,
      isAllShiftsSubmittedSuccessfully: false,
      SHIFT_TYPES,
    };
  },
  computed: {
    ...mapState({
      initialStatus: (state) => state.initialStatus,
    }),
    canShowWarningMessage() {
      return this.initialStatus.shift_sign_off_warning;
    },
    ...mapGetters({
      getShift: "getShift",
      getActiveTab: "getActiveTab",
    }),
    isAnyShiftOnCall() {
      return this.localShifts.some(
        (shift) => shift.type === SHIFT_TYPES.oncall.key
      );
    },
    isAnyShiftSleeopver() {
      return this.localShifts.some(
        (shift) => shift.type === SHIFT_TYPES.sleepover.key
      );
    },
    isAnyShiftAwaken() {
      return this.localShifts.some((shift) => shift.isAwaken);
    },
    // isAllShiftsSubmittedSuccessfully () {
    //   return this.localShifts.every(shiftObj => {
    //     console.log(shiftObj);
    //   })
    // }
  },
  watch: {
    isDataChanged: {
      deep: true,
      handler() {
        //Send data to modal component
        this.$parent.$emit("has-changed", this.isDataChanged);
      },
    },
  },
  created() {
    this.init();
    this.setupDateTimePickerOpt();
  },
  methods: {
    ...mapActions(["clientSignOff", "postMultipleShiftsDay"]),
    // init() creates shiftPart objects if they weren't created already (on timesheet entry)
    init() {
      const editedShifts = this.modalProps.map((el) => {
        // ONCALL
        if (el.type === SHIFT_TYPES.oncall.key) {
          const oncallPartObj = el.shiftParts.find(
            (partObj) => partObj.type === SHIFT_TYPES.oncall.key
          );
          if (!oncallPartObj) {
            const newOncallPartObj = this.createEmptyShiftPartObj(
              SHIFT_TYPES.oncall.key
            );
            el.shiftParts.push(newOncallPartObj);
          }
        }
        // SLEEPOVER / AWAKEN
        if (el.type === SHIFT_TYPES.sleepover.key) {
          const awakenPartObj = el.shiftParts.find(
            (partObj) => partObj.type === SHIFT_TYPES.awaken.key
          );
          el.isAwaken = !!awakenPartObj;

          if (!awakenPartObj) {
            const newAwakenPartObj = this.createEmptyShiftPartObj(
              SHIFT_TYPES.awaken.key
            );
            el.shiftParts.push(newAwakenPartObj);
          }

          const sleepoverPartObj = el.shiftParts.find(
            (partObj) => partObj.type === SHIFT_TYPES.sleepover.key
          );
          if (!sleepoverPartObj) {
            const newSleepoverPartObj = this.createEmptyShiftPartObj(
              SHIFT_TYPES.sleepover.key
            );
            el.shiftParts.push(newSleepoverPartObj);
          }
        }

        return el;
      });

      this.localShifts = JSON.parse(JSON.stringify(editedShifts));
      this.oldLocalShifts = JSON.parse(JSON.stringify(this.localShifts));
    },
    createEmptyShiftPartObj(type) {
      return {
        from: {
          date: "",
          // Below hardcoded
          timezone: "Europe/London",
          timezone_type: 3,
        },
        to: {
          date: "",
          // Below hardcoded
          timezone: "Europe/London",
          timezone_type: 3,
        },
        type: type,
      };
    },
    onChangeCostCentre(shift, evt, index) {
      shift.location = {
        client: shift.location.client,
        ...evt,
      };

      //check if data is changed
      if (
        this.oldLocalShifts[index].location.id ===
        this.localShifts[index].location.id
      )
        this.isDataChanged = false;
      else this.isDataChanged = true;
    },
    onChangeStartDate(index, key, newDate) {
      this.setDate(index, key, newDate);
      this.fetchMultipleShiftsDay(this.localShifts[index]);
    },
    parsePartDate(shift, key, shiftType) {
      const partTimeObj = shift.shiftParts.find(
        (partObj) => partObj.type === shiftType
      );
      let dateTime;
      if (partTimeObj) {
        dateTime = partTimeObj[key].date;
      }
      return dateTime && this.formatDate(dateTime);
    },
    setDate(index, key, newDate) {
      this.localShifts[index][key].date = this.serverStartTime(newDate);

      //check if data is changed
      if (
        this.oldLocalShifts[index][key].date ===
        this.localShifts[index][key].date
      )
        this.isDataChanged = false;
      else this.isDataChanged = true;
    },
    onChangeDatePart(index, key, newDate, shiftType) {
      // if (!this.localShifts[index].shiftParts[0]) {
      //   this.localShifts[index].shiftParts = [{}];
      // }

      const shiftPartObj = this.localShifts[index].shiftParts.find(
        (partObj) => partObj.type === shiftType
      );

      if (shiftPartObj) {
        // ITS MUTATING OBJ
        shiftPartObj[key] = {
          date: this.formatDateApi(newDate),
          // Below hardcoded
          timezone: "Europe/London",
          timezone_type: 3,
        };
      }
    },
    setBreakMinutes(index, newVal) {
      this.localShifts[index].breakMinutes = newVal;

      //check if data is changed
      if (
        this.oldLocalShifts[index].breakMinutes ==
        this.localShifts[index].breakMinutes
      )
        this.isDataChanged = false;
      else this.isDataChanged = true;
    },
    async fetchMultipleShiftsDay(shift) {
      if (!this.canShowWarningMessage) {
        // Agency denied
        return;
      }

      const params = {
        shift_id: shift.id,
        start_time: shift.startTime.date,
      };
      try {
        const res = await this.postMultipleShiftsDay(params);
        this.warningMessageText = res.data.message;
      } catch (err) {
        this.$toasted.error(parseErrors(err)).goAway(3500);
      }
    },
    serverStartTime(newDate) {
      if (newDate) {
        return moment(newDate, "DD/MM/YYYY HH:mm")
          .format("YYYY-MM-DD HH:mm:ss")
          .valueOf();
      }
      return null;
    },
    async onSubmitShifts() {
      this.disableButton = true;
      await this.submitShifts();
      this.disableButton = false;
    },
    async submitShifts() {
      let errors = 0;
      for (const obj of this.localShifts) {
        delete obj.success;
        delete obj.error;
        try {
          const params = {
            url: obj.signedOffAt
              ? `/shifts/${obj.id}/confirm-sign-off`
              : `/shifts/${obj.id}/sign-off`,
            data: this.generateParamsForSave(obj),
            tab: this.getActiveTab.name,
          };
          await this.clientSignOff(params);
          obj.success = "Shift signed off";
        } catch (error) {
          console.log(error);
          errors++;
          let responseObject = error.response.data;
          let errorObject = {
            message: parseErrors(error),
            time: 2500,
          };

          if (responseObject.errors) {
            this.errors.record(responseObject.errors);
          } else {
            errorObject.message = responseObject.message;
            errorObject.time = 10000;
          }
          if (Array.isArray(errorObject.message))
            obj.error = errorObject.message[0];
          else obj.error = errorObject.message;
        }
      }

      this.$emit("multiple-submitted");
      this.isAllShiftsSubmittedSuccessfully = errors === 0 ? true : false;
    },
    generateParamsForSave(obj) {
      const payload = {
        start_time: obj.startTime.date,
        end_time: obj.endTime.date,
        location_id: obj.location && obj.location.id,
        break_minutes: obj.breakMinutes,
        subcategories: obj.shiftRequest.subcategories.map((subcat) => ({
          id: subcat.id,
        })),
        checkSixMonthsOld: true,
      };

      if (
        [SHIFT_TYPES.oncall.key, SHIFT_TYPES.sleepover.key].includes(
          obj.type
        ) &&
        obj.shiftParts[0]
      ) {
        payload.shift_parts = [];
        for (const partObj of obj.shiftParts) {
          // special check if awaken part is filled but the checkbox is false
          if (!obj.isAwaken && partObj.type === SHIFT_TYPES.awaken.key)
            continue;

          payload.shift_parts.push({
            from: partObj?.from?.date,
            to: partObj?.to?.date,
            type: partObj?.type,
          });
        }
      }

      return payload;
    },
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "min";
      this.dateTimeOption.format = "DD/MM/YYYY HH:mm";
    },
    formatDate(stringTime) {
      let m = moment(stringTime, "YYYY-MM-DD HH:mm:ss");
      return m.format("DD/MM/YYYY HH:mm");
    },
    formatDateApi(stringTime) {
      let m = moment(stringTime, "DD/MM/YYYY HH:mm");
      return m.format("YYYY-MM-DD HH:mm:ss");
    },
    callModalClose() {
      this.revertCalendarMode("day");
      this.$emit("dispose-modal");
      this.$emit("get-data");
    },
    revertCalendarMode(mode) {
      this.dateTimeOption.type = mode;
      this.dateTimeOption.format = "DD/MM/YYYY";
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-wrap {
  display: flex;
  flex-direction: column;
  gap: 2em 0;
  max-height: 50vh;
  overflow: auto;

  .control {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
  }

  .buttons-centered {
    position: sticky;
    bottom: 0;
    background: #fff;
  }

  .table-wrap {
    display: flex;

    .table {
      width: 100%;
    }
  }
}

.shift {
  display: flex;
  flex-direction: column;
  gap: 0.5em 0;

  .row {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
    width: 100%;

    .control.error {
      color: red;
    }

    .control.success {
      color: green;
    }

    .field {
      width: 50%;
    }
  }
}

.is-suffix {
  white-space: nowrap;
}
</style>
<style lang="scss">
.multiple-wrap {
  .cov-datepicker {
    width: 100%;
    min-width: 145px;
  }
}
</style>
