<template lang="pug">
.details.default-shift-modal(@click.stop="", style="margin-top: 20px")
  template(v-for="(shift, index) in shiftTimes")
    .control.is-grouped
      .field.shift-name
        label.label &nbsp
        input.input(
          type="text",
          placeholder="Please enter Shift name here",
          v-model="shift.name"
        )

      .field
        label.label(for="start_time") START TIME
        date-picker(
          :date="shift.startTime",
          :option="dateTimeOption",
          mode="hour"
        )

      .field
        label.label(for="end_time") END TIME
        date-picker(
          :date="shift.endTime",
          :option="dateTimeOption",
          mode="hour"
        )

      .field
        label.label &nbsp
        span.cancel(
          @click.stop="deleteRow(index)",
          v-if="gPermissions.canDeleteDefaultShiftTimes"
        )
          img(src="./../../../assets/images/comps/delete-larger.svg")

  span
    button.button.is-generic-app-light-blue(
      @click="addDefaultShiftRow",
      :disabled="add_new",
      v-if="gPermissions.canCreateDefaultShiftTimes"
    )
      img(src="../../../assets/images/comps/Plus.svg")
      span Add New

  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      @click.prevent="defaultShiftsSubmit"
    ) SAVE
    button.button.is-outlined.is-caps-lock(@click.prevent="callModalClose") CANCEL
</template>

<script>
import calendarOptions from "../../common/calendarOptions.js";
import moment from "moment";
import { mapActions } from "vuex";
import { Evt } from "../../../lib/helpers/Evt.js";

export default {
  props: ["modalProps"],
  data() {
    return {
      ...calendarOptions,
      shiftName: "",
      startTime: { time: "" },
      endTime: { time: "" },
      currtime: moment(),
      add_new: false,

      shiftTimes: [],
      shiftTimesToDeleteIds: [],
      clientId: this.modalProps.clientId,
    };
  },
  created() {
    this.dateTimeOption.format = "HH:mm";
    this.shiftTimes =
      this.modalProps.defaultShifts !== undefined
        ? JSON.parse(JSON.stringify(this.modalProps.defaultShifts))
        : [];
    if (this.shiftTimes.length > 0) {
      // Prepare the dates to be in format for datepicker
      var len = this.shiftTimes.length;
      for (var i = 0; i < len; i++) {
        var aShift = this.shiftTimes[i];
        aShift.startTime = { time: aShift.startTime.substring(0, 5) };
        aShift.endTime = { time: aShift.endTime.substring(0, 5) };
      }
    }
  },
  mounted() {
    if (this.shiftTimes.length === 0) {
      this.addDefaultShiftRow();
    }
  },
  methods: {
    ...mapActions({
      createDefaultShiftTime: "locationClientManagement/createDefaultShiftTime",
      deleteDefaultShiftTime: "locationClientManagement/deleteDefaultShiftTime",
    }),
    deleteDefaultShiftCall(shiftId) {
      console.log("Delete invoked for :: ", shiftId);
      this.deleteDefaultShiftTime({
        clientId: this.clientId,
        shiftId: shiftId,
      });
    },

    createNewDefaultShift(shift) {
      console.log("Creating new shift.");
      var payload = {};
      payload.name = shift.name;
      payload.startTime = shift.startTime.time + ":00";
      payload.endTime = shift.endTime.time + ":00";
      this.createDefaultShiftTime({
        clientId: this.clientId,
        payload: payload,
      });
    },

    addDefaultShiftRow() {
      console.log("Default shift add invoked");
      this.shiftTimes.push({
        id: -1,
        name: "",
        startTime: { time: "" },
        endTime: { time: "" },
      });
    },

    deleteRow(index) {
      var aShift = this.shiftTimes[index];
      if (aShift.id !== -1) {
        this.shiftTimesToDeleteIds.push(aShift.id);
      }
      this.shiftTimes.splice(index, 1);
    },

    resetFields() {
      this.shiftName = "";
      this.startTime.time = "";
      this.endTime.time = "";
    },

    callModalClose() {
      this.$emit("cancel-modal");
      Evt.fire("disposeModal");
    },

    defaultShiftsSubmit() {
      console.log("Save fired.");
      for (var i = 0; i < this.shiftTimes.length; i++) {
        var aShift = this.shiftTimes[i];
        if (aShift.name.trim().length > 0 && aShift.id === -1) {
          console.log("Saving new", aShift);
          this.createNewDefaultShift(aShift);
        }
        if (aShift.name.trim().length > 0 && aShift.id !== -1) {
          console.log("Editing... NO API YET.", aShift);
        }
      }

      for (i = 0; i < this.shiftTimesToDeleteIds.length; i++) {
        console.log("Deleting ", this.shiftTimesToDeleteIds[i]);
        this.deleteDefaultShiftCall(this.shiftTimesToDeleteIds[i]);
      }

      this.callModalClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
}

.cancel {
  border-radius: 20px;
  width: 24px;
  height: 24px;
  font-size: 9px;
  line-height: 24px;
  margin-top: 14px;
  color: white;
  display: block;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.confirm {
  border-radius: 20px;
  background-color: #3eef76;
  width: 24px;
  height: 24px;
  font-size: 9px;
  line-height: 24px;
  margin-top: 12px;
  color: white;
  display: block;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.field {
  margin-right: 5px;

  .input {
    height: 36px;
  }

  &.shift-name {
    flex-grow: 2;
  }
}
</style>
<style lang="scss">
.default-shift-modal {
  .datepickbox {
    .cov-datepicker {
      width: 90px;
    }
  }
}
</style>
