<template lang="pug">
tbody(:class="{ active: active_row }")
  modal(
    v-if="modalVisible",
    :title="modalTitle",
    icon="",
    :size="modalSize",
    :scrollable="isModalScrollable",
    stopEventPropagation="true",
    @close="modalVisible = false",
    @click.native.stop=""
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false",
      @changed-status="onChangedStatus"
    )
  tr(@click="$emit('activate-row')")
    //- td(style="width: 1%")
      //- input(type="checkbox")
    td(style="width: 15%")
      template(v-if="gPermissions.canClientProfileView")
        i.fa.fa-caret-up(v-if="active_row")
        i.fa.fa-caret-down(v-if="!active_row")
        span(style="margin-left: 15px")
      | {{ client.name }}
    td.has-button(data-cell="status", @click.stop="")
      span.button.is-caps-lock.is-tiny(
        @click.stop="changeClientStatus(client)",
        :class="[setStatus(client.active), { 'is-loading': loadingClientStatus }]",
        :disabled="loadingClientStatus"
      ) {{ getStatusText(client.active) }}
    td {{ getSectorNames }}
    //- td
    td {{ client.category_type }}
    //- td
    td.allowances(@click.stop="")
      multi-select(
        :options="allowances",
        :id="getRandomId",
        track-by="id",
        select-label="",
        selected-label="",
        deselect-label="",
        :allow-empty="false",
        :disabled="!gPermissions.canEditRulesCalcEng",
        label="name",
        placeholder="",
        :value="client.calcEngine",
        @input="pushAllowanceToServer(client, $event)",
        v-if="gPermissions.canViewRulesCalcEng || gPermissions.canEditRulesCalcEng"
      )
    td
    td
    td(data-cell="default-shift-time")
      span.generic-app-tag.is-wide-tag.popup-hook(
        v-tooltip="'Click to fill in'",
        v-if="gPermissions.canViewDefaultShiftTimes",
        @click.stop="showPopup(['defaultShiftTimes'], $event.target, client)"
      ) <strong>{{ client.defaultShiftTimes ? client.defaultShiftTimes.length : "0" }}</strong>
        img(src="../../assets/images/comps/Olovka.svg")
    //- td
      span(@click="startClientUserManagement(client, $event)")
        img(src="../../assets/images/comps/cum-icon.svg")
    td {{ client.custom_client_id }}
    td
      //- && client.category_type != null"
      span.generic-app-tag.is-wide-tag.popup-hook(
        v-tooltip="'Click to fill in'",
        v-if="gPermissions.canEditBreakTimes",
        @click.stop="showBreakTimeModal(client)"
      ) 
        strong Edit
        img(src="../../assets/images/comps/Olovka.svg")
    td.has-button.rate(@click.stop="")
      template(v-if="gPermissions.canManageRates && rateResolvedLink")
        //- router-link.is-tiny(
        //-   :to="{ name: 'RatesManageRatesPage', params: { type: client.rate_type_unique_code, client_id: client.useRatesFrom } }"
        //- )
        a(target="_blank", :href="rateResolvedLink")
          span.g-letter(v-if="client.useRatesFrom")
            img(src="../../assets/images/comps/g_letter.svg")
          i.fa.fa-calculator(v-else)
    td
      //- && client.category_type != null"
      span.generic-app-tag.is-wide-tag.popup-hook(
        v-tooltip="'Click to fill in'",
        v-if="(gPermissions.canEditShiftConfirmInterval && canUseShiftConfirm) || shiftConfirmEnablement",
        @click.stop="showShiftConfirmInterval(client)"
      ) 
        strong Edit
        img(src="../../assets/images/comps/Olovka.svg")
    td.has-button.auto-repush(data-cell="auto-repush", @click.stop="")
      span.button.is-caps-lock.is-tiny(
        title="Change auto-repush interval",
        :class="[client.autorepushactive ? 'is-success' : 'is-gray-btn']",
        v-if="gPermissions.canSetAutorepush",
        @click.stop="openAutoRepush"
      )
        span(v-if="client.autorepushactive") On
        span(v-else) Off
      //- i.fa.fa-refresh()
    td.has-button(@click.stop="")
      router-link(
        :to="{ name: 'ClientDetailsPage', params: { id: client.id, client } }",
        v-if="gPermissions.canClientProfileView"
      )
        span.button.is-generic-app-blue.is-caps-lock.is-tiny(
          data-cell="edit-clients-button"
        ) Profile
      //- is-low
  tr(@click.stop="")
  simplert(
    :useRadius="true",
    :useIcon="true",
    ref="simplert",
    key="simplert",
    @click.native.stop=""
  )
</template>

<script>
import _ from "underscore";
import { mapGetters, mapActions } from "vuex";
import ClientSubTable from "./ClientSubTable.vue";
import MultiSelect from "vue-multiselect";
import EditPopup from "../EditPopup.vue";
import DefaultShiftTimesModalContent from "../in-modal/location-client-mgmt/DefaultShiftTimes.vue";
// import FeeCalculationModal from "../in-modal/location-client-mgmt/FeeCalculation.vue"
import EditClientStatus from "./EditClientStatus.vue";
import ChangeAutoRepush from "./ChangeAutoRepush.vue";
import EditBreakTimes from "./EditBreakTimes.vue";
import EditShiftConfirmInterval from "./EditShiftConfirmInterval.vue";

export default {
  components: {
    ClientSubTable,
    MultiSelect,
    EditPopup,
    DefaultShiftTimesModalContent,
    // FeeCalculationModal,
    EditClientStatus,
    ChangeAutoRepush,
    EditBreakTimes,
    EditShiftConfirmInterval,
  },
  props: ["client", "active_row"],
  data() {
    return {
      modalSize: 650,
      modalVisible: false,
      isModalScrollable: true,
      popup: {
        active_client_id: null,
        offset: {
          offsetX: 20,
          offsetY: 55,
        },
        element: null,
        show: false,
        data: null,
        levels: [],
        save: (data, totalCount) => {
          return this.refreshPopupState(data, totalCount);
        },
      },
      loadingClientStatus: false,
    };
  },
  computed: {
    ...mapGetters({
      allowances: "locationClientManagement/getAllowances",
      // serviceTypeCalc: 'locationClientManagement/getServiceTypeCalc'
    }),
    rateResolvedLink() {
      const obj = this.$router.resolve({
        name: "RatesManageRatesPage",
        params: {
          type: this.client.rate_type_unique_code,
          client_id: this.client.useRatesFrom || this.client.id,
        },
      });

      return obj.href;
    },
    getRandomId() {
      try {
        return `id-${(Math.random() * 5).toFixed(5).replace(".", "")}`;
      } catch (err) {
        return "id";
      }
    },
    getSectorNames() {
      // Only if has selectable
      return (
        this.client.sectors &&
        this.client.sectors.map((sec) => sec.name).join(", ")
      );
    },
    // clientShiftTypes () {
    //   return 'shift types'
    // },
    // clientApplicableCategories () {
    //   // If it has no locations
    //   if (this.client['locations'].length === 0 ||
    //     this.client['locations'][0]['categories'].length === 0) {
    //     return ''
    //   }

    //   var aCategory = this.client['locations'][0]['categories'][0]['name']
    //   var sameCategories = true
    //   for (var i = 1; i < this.client['locations'].length; i++) {
    //     for (var j = 0; j < this.client['locations'][i]['categories'].length; j++) {
    //       if (this.client['locations'][i]['categories'][j]['name'] !== aCategory) {
    //         // If the category is different than the assumed first category, set that the categories are mixed
    //         sameCategories = false
    //         break
    //       }
    //     }
    //   }

    //   if (sameCategories) {
    //     return aCategory
    //   }
    //   return 'mixed'
    // }
  },
  methods: {
    ...mapActions({
      updateClientAllowance: "locationClientManagement/updateClientAllowance",
      putClientStatus: "locationClientManagement/putClientStatus",
    }),
    onChangedStatus() {
      this.$emit("changed-status");
    },
    showBreakTimeModal(client) {
      console.log(client);
      const data = { ...client };
      this.showModal("edit-break-times", data, "Break times", false, 500);
    },
    showShiftConfirmInterval(client) {
      console.log("Shift confirm interval", client);
      const data = { ...client };
      this.showModal(
        "edit-shift-confirm-interval",
        data,
        "Shift confirm interval",
        false,
        500
      );
    },
    startClientUserManagement: function (client, event) {
      console.log("Started CUM for :: ", client);
      event.stopPropagation();
    },
    openAutoRepush() {
      const params = {
        client: this.client,
      };
      this.showModal("change-auto-repush", params, "Auto repush", false);
    },
    confirmActionAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async pushAllowanceToServer(client, evt) {
      try {
        await this.confirmActionAlert("update client rules");

        this.$set(client, "calcEngine", evt);
        if (client.calcEngine?.id) {
          await this.updateClientAllowance({
            clientId: this.client.id,
            calcengine_id: client.calcEngine.id,
          });
          this.$toasted.success("Success").goAway(1500);
        } else {
          console.error("Error changing calc engine", client, evt);
        }
      } catch (err) {
        const errMsg =
          err.response?.data?.message || "Failed to update client allowances";
        this.$toasted.error(errMsg).goAway(3000);
      }
    },
    showPopup(levels, e, client) {
      console.log(levels);
      this.showModal(
        "default-shift-times-modal-content",
        {
          clientId: this.client.id,
          defaultShifts: this.client.defaultShiftTimes,
        },
        "Default Shifts"
      );
      // Triggers a modal only
      // Kept only in case tomorrow this might become a editPopup box.
      // Unused otherwise
      // let apiCheckedIds = []

      this.popup.active_client_id = client.id;
      console.log(client.id);
      if (_.indexOf(levels, "service_fee") >= 0) {
        this.popup.data = this.serviceFee;
      }
      this.popup.levels = levels;
      // we collected the data we need, safe to show popup
      this.popup.show = true;
      this.popup.element = e;
    },

    popupClose() {
      this.popup.show = false;
    },

    // eslint-disable-next-line no-unused-vars
    refreshPopupState({ data, entity }, totalCount) {
      console.log("entity", entity[0]);
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      isModalScrollable = true,
      modalSize = 650
    ) {
      this.isModalScrollable = isModalScrollable;
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalSize = modalSize;
      this.modalVisible = true;
    },
    changeClientStatus(client) {
      // If using modal
      this.showModal("edit-client-status", { client }, `Client's status`, 650);

      // Directly changing
      // const changeStatusApi = async (status) => {
      //   this.loadingClientStatus = true
      //   const apiParams = {
      //     clientId: client.id,
      //     params: {
      //       active: status
      //     }
      //   }
      //   try {
      //     await this.putClientStatus(apiParams)
      //     // If: the store doesn't get updated fast / API doesn't return the value
      //     // Then: update the value manually
      //     this.loadingClientStatus = false
      //   } catch (err) {
      //     console.log(err.message);
      //     this.loadingClientStatus = false
      //   }
      // }
      // if (client.active === false) {
      //   // Enable
      //   changeStatusApi(true)
      // } else {
      //   // Disable
      //   changeStatusApi(false)
      // }
    },
    setStatus(s) {
      let c;
      switch (s) {
        case true:
        default:
          c = "is-success";
          break;
        // case 2:
        //   c = 'is-warning'
        //   break
        case false:
          c = "is-danger";
          break;
      }
      return c;
    },
    getStatusText(s) {
      let c;
      switch (s) {
        case true:
        default:
          c = "Active";
          break;
        // case 2:
        //   c = 'Review'
        //   break
        case false:
          c = "Inactive";
          break;
        // case 4:
        //   c = 'Approval'
        //   break
      }
      return c;
    },
  },
};
</script>

<style lang="scss" scoped>
.table td.no-padding {
  padding: 0;
}

tbody.active {
  td {
    border-bottom: 1px solid #dbdbdbdc;
  }
}

.tbody {
  z-index: 200;
}

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.generic-app-tag.generic-app-tag--deadline {
  background-color: #d2ecf5;
}

.generic-app-tag.generic-app-tag--expiry {
  background-color: #ffcc6a;
}

tbody.generic-app-subtable {
  z-index: 500;
  background-color: red;

  tr {
    background-color: #f2f7fa;
  }
}

i {
  margin-right: 0;
}

.g-letter {
  opacity: 0.5;

  &:hover {
    opacity: 0.4;
  }

  img {
    width: 70%;
  }
}

.allowances {
  // max-width: 200px;
  min-width: 210px;
}

.has-button {
  &.rate,
  &.auto-repush {
    $icon-col: gray;
    text-align: center;

    i {
      color: $icon-col;
      cursor: pointer;

      &:hover {
        color: lighten($color: $icon-col, $amount: 20);
      }
    }
  }

  &.rate {
    a {
      display: flex;
      flex-direction: column;

      i {
        margin: 0;
      }

      * {
        pointer-events: none;
      }
    }
  }
}
</style>
