<template lang="pug">
<div>
  <div class="tabs is-fullwidth">
    ul
      li(v-for="(link, index) in filteredLinks", :key="index")
        router-link(
          :to="{ name: link.route }",
          target,
          @click.native="storeTabInState(link)"
        ) 
          .spin-loader(
            v-if="loading",
            style="width: 20px; display: inline-block"
          )
          span {{ link.name }}
  </div>

  <div class="tabs-details">
    router-view
    //- <slot></slot>
  </div>
</div>
</template>
<script>
import { Evt } from "../lib/helpers/Evt.js";

export default {
  data() {
    return {
      loading: true,
      links: [
        {
          name: "Single Shifts",
          route: "submitShiftSingle",
        },
        {
          name: "Line Of Shifts",
          route: "submitShiftLine",
        },
        {
          name: "Timesheet entry",
          route: "submitShiftTimesheet",
          // perm: 'create-shift',
          perm: "create-timesheet-line-of-shifts",
        },
      ],
    };
  },
  computed: {
    filteredLinks() {
      return this.links.filter((link) => {
        if (link.perm) {
          try {
            return this.$can(link.perm);
          } catch (err) {
            // If $can is undefined at start
          }
        }
        return true;
      });
    },
  },
  mounted() {
    Evt.listen("data-is-loading", (loading) => {
      this.loading = loading;
    });
  },
  methods: {
    storeTabInState(selectedTab) {
      this.$store.commit("SET_ACTIVE_TAB", {
        name: selectedTab.name,
        status: null,
      });
      this.$emit("tab-is-clicked");
    },
  },
};
</script>

<style lang="scss">
.tabs {
  a {
    text-transform: uppercase;
    font-weight: 900;
    border-bottom: 3px solid;

    &.is-active {
      border-bottom-color: var(--color-primary-10);
      color: var(--color-primary-10);
    }
  }
}
</style>
<style>
.spin-loader {
  background-color: green;
  /* height: 20px; */
}
</style>
