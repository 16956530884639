<template lang="pug">
.checkbox-list-menu(
  :class="[{ root: depth === 0, vertical: isVerticalList }, depthClasses[depth]]"
)
  .node(
    :class="{ expanded: isExpandedChild(node) }",
    v-for="(node, index) in sortedList",
    :key="getNodeKey(node, index)"
  )
    .node-content(
      :class="{ changed: getChangedRow(node) }",
      @click="onClickNode(node)"
    )
      .arrow-wrap(v-if="hasArrow && hasListAnyChildren")
        .arrow(
          v-if="(node.children && node.children.length) || forceDisplayExpand >= depth",
          @click.stop="onClickNode(node, true)"
        )
          img(src="@/assets/images/comps/forms/triangle-select.svg")
      input(
        type="checkbox",
        :checked="isNodeChecked(node)",
        :indeterminate.prop="isNodeIndeterminate(node)",
        :disabled="isDisabledItemList(node)",
        v-if="hasCheckbox",
        @click.stop="onInputCheckbox($event, node)"
      )
      span {{ node.name }}
      slot(name="right-side", :props="node", :depth="depth")
        .right-side
          //- | {{ node.id }}
          switches(
            v-if="isSpecialKey === 'documents-locations' && depth === 1 && isNodeChecked(node)",
            theme="bulma",
            color="blue",
            :emitOnMount="false",
            :value="node.hasComplianceAllowance",
            @input="onChangeCompliance($event, node)",
            @click.stop.native
          )
            //- v-if="slotProps.checked",
      //- .right-side
      span(
        v-if="displayChildrenCount && node.children && node.children.length"
      )
        | ( {{ getCheckedList(node).length }} / {{ node.children.length }} )
    transition(name="slide-down")
      .children(
        v-if="isExpandedChild(node) && node.children && node.children.length"
      )
        checkbox-list-menu(
          :original-checked-list="originalCheckedList",
          :changed-checked-list="changedCheckedList",
          :expanded-items="expandedItems",
          :disabled-items="disabledItems",
          :has-arrow="hasArrow",
          :is-solo-check-valid="isSoloCheckValid",
          :list="node.children",
          :checked-list="getCheckedList(node)",
          :is-disabled-list="isDisabledList",
          :is-vertical-list="isVerticalList",
          :depth="depth + 1",
          :display-children-count="displayChildrenCount",
          :depth-classes="depthClasses",
          :force-display-expand="forceDisplayExpand",
          :isSpecialKey="isSpecialKey",
          :shouldSortList="shouldSortList",
          v-on="$listeners"
        )
</template>

<script>
// List has "children"
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    originalCheckedList: {
      type: Array,
      default: () => [],
    },
    changedCheckedList: {
      type: Array,
      default: () => [],
    },
    checkedList: {
      type: Array,
      required: true,
    },
    checkedListObj: {
      type: Array,
      default: () => [],
    },
    expandedItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabledItems: {
      type: Array,
      default: () => [],
    },
    hasArrow: {
      type: Boolean,
      default: true,
    },
    isDisabledList: {
      type: Boolean,
      default: false,
    },
    hasCheckbox: {
      type: Boolean,
      default: true,
    },
    isSoloCheckValid: {
      type: Boolean,
      default: false,
    },
    isVerticalList: {
      type: Boolean,
      default: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
    displayChildrenCount: {
      default: false,
    },
    depthClasses: {
      type: Object,
      default: () => ({}),
    },
    useWholeCheckedList: {
      default: false,
    },
    forceDisplayExpand: {
      // Used for dynamic loading of nested elements
      // Usage: display up to specified depth
      type: Number,
      default: -1,
    },
    isSpecialKey: {
      type: String,
      default: "",
    },
    shouldSortList: {
      default: true,
    },
    canSelectWholeNode: {
      default: true,
    },
    useNewExpand: {
      default: false,
    },
  },
  emits: ["click", "checked", "expand", "change-compliance-allowance"],
  computed: {
    sortedList() {
      if (this.shouldSortList) {
        return [...this.list].sort((aItem, bItem) => {
          if (aItem.name && bItem.name) {
            if (aItem.name < bItem.name) {
              return -1;
            } else if (aItem.name > bItem.name) {
              return 1;
            }
          }
          return 0;
        });
      }
      return this.list;
    },
    hasAnyInvalidId() {
      // Checks nodes for invalid / duplicated ids
      for (let index = 0; index < this.list.length; index++) {
        const listItemId = this.list[index]?.id;
        if (listItemId) {
          for (let jIndex = index + 1; jIndex < this.list.length; jIndex++) {
            const jListItemId = this.list[jIndex].id;
            if (listItemId === jListItemId) {
              console.error(
                "Array has duplicated IDs",
                listItemId,
                index,
                this.list[index]
              );
              return true;
            }
          }
        } else {
          return true;
        }
      }
      return false;
    },
    hasListAnyChildren() {
      if (this.hasArrow) {
        if (this.depth === 0) {
          return this.list.some((item) => item.children?.length);
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    onClickNode(node, forceAllow = false) {
      if (!this.canSelectWholeNode && !forceAllow) {
        return false;
      }

      const payload = {
        depth: this.depth,
      };

      if (!node.children?.length) {
        if (this.isDisabledItemList(node)) {
          return false;
        }
      } else if (this.useNewExpand) {
        this.$emit("expand", { node, payload });
        return false;
      } else {
        console.log("Using old expand");
      }

      this.$emit("click", node, payload);
    },
    onInputCheckbox(evt, node) {
      // Prevent blocks checked prop
      // if (this.isSoloCheckValid) {
      //   evt.preventDefault();
      // }

      if (this.isDisabledItemList(node)) {
        evt.preventDefault();
        return false;
      }

      const payload = {
        depth: this.depth,
      };
      this.$emit(
        "checked",
        {
          item: node,
          checked: evt.target.checked,
        },
        payload
      );
    },
    getCheckedList(node) {
      if (this.useWholeCheckedList) {
        // Whole list, but needs to make sure that parent and children have all unique keys
        return this.checkedList;
      } else {
        const nodeChildrenIds = node.children?.map((child) => child.id);
        return this.checkedList.filter((cItem) =>
          nodeChildrenIds.includes(cItem)
        );
      }
    },
    getNodeKey(node, index) {
      return this.hasAnyInvalidId ? index : node.id || index;
    },
    isNodeChecked(node) {
      const isMainChecked =
        this.checkedList.findIndex((cItem) => cItem === node.id) !== -1;
      if (this.isSoloCheckValid) {
        return isMainChecked; // Untested
        // return isMainChecked || isEveryChildrenChecked(); // Untested
      } else if (node.children) {
        return (
          (isMainChecked && this.isSomeChildrenChecked(node)) ||
          this.isEveryChildrenChecked(node)
        );
      }
      return isMainChecked;
    },
    isNodeIndeterminate(node) {
      if (this.isSoloCheckValid) {
        const isMainChecked =
          this.checkedList.findIndex((cItem) => cItem === node.id) !== -1;
        return !isMainChecked && this.isSomeChildrenChecked(node);
        // return false;
      }
      return (
        !this.isEveryChildrenChecked(node) && this.isSomeChildrenChecked(node)
      );
    },
    isDisabledItemList(node) {
      if (this.isDisabledList || node.disabled) {
        return true;
      }
      return this.disabledItems.includes(node.id);
    },
    isExpandedChild(node) {
      return this.expandedItems.findIndex((eItem) => eItem === node.id) !== -1;
    },
    isEveryChildrenChecked(node) {
      if (node.children?.length) {
        const recSearch = (arr = []) => {
          return arr.every((item) => {
            if (item.children?.length) {
              return recSearch(item.children);
            } else {
              return this.checkedList.includes(item.id);
            }
          });
        };

        return recSearch(node.children);
      }
      return false;
    },
    isSomeChildrenChecked(node) {
      if (node.children?.length) {
        const recSearch = (arr = []) => {
          return arr.some((item) => {
            if (item.children?.length) {
              return recSearch(item.children);
            } else {
              return this.checkedList.includes(item.id);
            }
          });
        };

        return recSearch(node.children);
      }
      return false;
    },
    getChangedRow(node) {
      if (this.changedCheckedList.length) {
        return this.changedCheckedList.includes(node.id);
      } else if (this.originalCheckedList.length) {
        const firstChanged = this.originalCheckedList.filter(
          (id) => !this.checkedList.includes(id)
        );
        const secondChanged = this.checkedList.filter(
          (id) => !this.originalCheckedList.includes(id)
        );
        return firstChanged.concat(secondChanged).includes(node.id);
      } else {
        return false;
      }
    },
    onChangeCompliance(evtVal, node) {
      // SPECIFIC FEATURE
      const val = evtVal ? 1 : 0;
      // this.$set(node, "hasComplianceAllowance", val);
      const payload = {
        id: node.id,
        val: Boolean(val),
      };
      this.$emit("change-compliance-allowance", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-list-menu {
  $gray-element: rgba(10, 10, 10, 0.2);
  $red-element: rgba(238, 96, 124, 0.733);

  display: flex;

  &.flex-row {
    flex-direction: row;

    .node {
      min-width: 300px; // Columns are not resized when width over specified
    }
  }

  &:not(.flex-row) {
    flex-direction: column;
  }

  .node {
    display: flex;
    flex-direction: column;
    padding: 0 1px 0 0; // Because of scroll overlapping

    &:nth-child(even) {
      > .node-content {
        background: rgb(248, 248, 248);
      }
    }

    .node-content {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 10px;
      min-height: 30px;
      height: auto;
      width: 100%;
      min-width: 200px;
      user-select: none;
      z-index: 1;
      cursor: pointer;

      .right-side {
        margin-left: auto;
      }

      &.changed {
        color: blue;
        font-weight: bold;
      }

      &:hover {
        background: darken($color: #e0e0e0ce, $amount: 5);
      }

      .arrow-wrap {
        width: 10px;
        display: flex;

        > .arrow {
          display: flex;
          transform: rotate(-90deg);
          transition: transform 0.3s ease;
          filter: grayscale(1);
        }
      }
    }

    &.expanded {
      > .node-content {
        .arrow-wrap {
          .arrow {
            transform: rotate(0deg);
          }
        }
      }
    }

    .action {
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      gap: 10px;

      .btn {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa {
          color: darken($color: $gray-element, $amount: 10);
        }

        &:hover {
          .fa {
            color: lighten($color: royalblue, $amount: 10);
          }
        }
      }

      .delete {
        margin-left: 5px;
        background: $gray-element;

        &:hover {
          background: $red-element;
        }
      }
    }

    &.active {
      background: darken($color: #e0e0e0ce, $amount: 10);
    }

    &.hidden {
      background: rgba(219, 219, 219, 0.335);
      opacity: 0.4;
      color: rgb(39, 39, 39);
    }
  }

  .children {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 0 0 20px;
    border-left: 1px solid #e0e0e0;
  }

  &:not(.vertical) {
    .node {
      min-height: 30px;
    }

    .children {
      overflow: auto;
    }

    &:not(.root) {
      flex-wrap: wrap;
      height: 100%;
      align-content: baseline;

      .node {
        overflow: auto;
      }
    }
  }

  .slide-down-enter-active {
    transition: all 0.4s;
    z-index: 0;
  }

  .slide-down-leave-active {
    transition: none;
  }

  .slide-down-enter {
    transform: translateY(-30px);
    opacity: 0;
  }

  .slide-down-leave-to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
</style>
