<template lang="pug">
div
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :size="getModalSize",
    :scrollable="isModalScrollable",
    :overflowVisible="getOverflowVisible"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      :isSaveLoading="isSaveLoading",
      @dispose-modal="disposeModalFunc",
      @is-save-loading="isSaveLoading = $event",
      @evtCancelShift="shiftCancellation",
      @cancel-modal="modalVisible = false",
      @get-data="onEmitGetData",
      @multiple-submitted="onMultipleSubmitted"
    )
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  table.table.is-striped(@click="info($event.target)")
    thead
      //- tr(@click="filters.visible = !filters.visible")
      tr
        th
          input(
            type="checkbox",
            v-model="allShiftsSelected",
            ref="selectAllCheckbox"
          )
          | Select All
        th
          .flex-header
            span.column-label SHIFT TYPE
            span.sort-buttons
              sort-buttons(column-name="type", v-model="selectedSortColumn")
        th(
          v-if="initialStatus.can_use_shift_activity_tags && $can('view-shift-activity-tag')"
        )
          .flex-header
            span.column-label ACTIVITY
        th
          .flex-header
            span.column-label STATUS
            span.sort-buttons
              sort-buttons(column-name="status", v-model="selectedSortColumn")
        th(v-if="visibleColumns.name")
          .flex-header
            span.column-label(
              @click.prevent="filterShow('filters.name', $event)"
            ) NAME
            span.sort-buttons
              sort-buttons(column-name="name", v-model="selectedSortColumn")
        //- th(v-if="$can('copy-shift')")
        //- th(v-if='enableLanePre')
          .flex-header
            span.column-label COPY
        th
          .flex-header
            span.column-label SUBCATEGORY
            span.sort-buttons
              sort-buttons(
                column-name="subcategory",
                v-model="selectedSortColumn"
              )
        th
          .flex-header
            span.column-label DATE
            span.sort-buttons
              sort-buttons(
                column-name="start_time",
                v-model="selectedSortColumn"
              )
        th
          .flex-header
            span.column-label TIME
            // column name start space is intentionally left
            // because we have date column that use
            // same field for sort
            span.sort-buttons
              sort-buttons(
                column-name=" start_time",
                v-model="selectedSortColumn"
              )
        th(style="min-width: 90px")
          .flex-header
            span.column-label TOTAL HOURS
        th(v-if="auth.user.role !== 'client-admin'")
          .flex-header
            span.column-label CLIENT
            span.sort-buttons
              sort-buttons(column-name="client", v-model="selectedSortColumn")
        th
          .flex-header
            span.column-label WARD
            span.sort-buttons
              sort-buttons(
                column-name="location",
                v-model="selectedSortColumn"
              )
        th
          .flex-header
            span.column-label LAST PUSHED BY
            span.sort-buttons
              sort-buttons(
                column-name="created_by",
                v-model="selectedSortColumn"
              )
        th
          .flex-header
            span.column-label SHIFT ID
            span.sort-buttons
              sort-buttons(column-name="id", v-model="selectedSortColumn")
        th(
          v-if="(canUseShiftConfirm && activeTab.name === 'PENDING') || shiftConfirmEnablement"
        )
          span CONFIRMED STATUS
        th(v-if="visibleColumns.action && !isTemp")
          abbr(title="Action") STATUS
        th
          abbr(title="View") VIEW
      tr(v-show="filters.visible && auth.user.role !== 'temp'")
        th(colspan="2")
        th(colspan="2")
          input.input(
            type="text",
            v-model="filterBy.externalTssId",
            @input="fireSearch($event.target.value, 'externalTssId')",
            placeholder="Worker External ID"
          )
          //- v-facade="'###########'"
        th(colspan="1")
          multi-select#agencyWorker(
            :options="agencyWorkers",
            :close-on-select="true",
            :searchable="true",
            :options-limit="50",
            select-label="",
            track-by="id",
            label="name",
            :loading="isLoadingAgencyWorker",
            :value="filterBy.selectedAgencyWorker",
            placeholder="Search worker",
            :internal-search="false",
            @search-change="asyncFindWorker",
            @select="fireSearch",
            @remove="removeFilter"
          )
            span(slot="noResult") Nothing found.
        //- th(:colspan="enableLanePre ? 4 : 2")
        th
          multi-select#subcategory(
            :options="optionsSubcategories",
            :close-on-select="true",
            :searchable="true",
            :options-limit="50",
            select-label="",
            track-by="id",
            label="name",
            group-label="category",
            group-values="subs",
            :value="filterBy.selectedSubcategory",
            :multiple="true",
            placeholder="Filter by subcategory",
            @input="onMultiselectInput"
          )
            span(slot="noResult") Nothing found.
        th.mid-span(:colspan="visibleColumns.name ? 3 : 2")
          //- th(v-if="$can('copy-shift')")
          input.input(
            type="text",
            v-model="filterBy.client_external_id",
            @input="fireSearch($event.target.value, 'client_external_id')",
            placeholder="Client External ID"
          )
        th
          multi-select#client.client-select(
            style="width: 280px",
            :options="clients",
            :searchable="true",
            :options-limit="999",
            select-label="",
            :close-on-select="false",
            track-by="id",
            label="name",
            :multiple="true",
            v-model="filterBy.selectedClient",
            :loading="isLoadingClient",
            placeholder="Filter by client",
            @select="fireSearchMultiple",
            @remove="removeFilter"
          )
            span(slot="noResult") Nothing found.
              //- :value="filterBy.selectedClient",
        th
          multi-select#location.fix-min-width(
            :options="locationsOptions",
            :close-on-select="true",
            :searchable="true",
            :options-limit="999",
            select-label="",
            track-by="id",
            group-label="client",
            group-values="subs",
            label="name",
            :value="filterBy.selectedLocation",
            placeholder="Filter by ward",
            @select="fireSearch",
            @remove="removeFilter"
          )
            span(slot="noResult") Nothing found.
        th
        th
          input.input(
            type="text",
            v-model="filterBy.shiftId",
            @input="fireSearch($event.target.value, 'shift-id')",
            v-facade="'###########'"
          )
        th(:colspan="100")
    //- tfoot
    tbody
      tr(v-if="dataIsLoading")
        td.center-text(colspan="100")
          img(src="../../assets/images/comps/loader.svg")
      tr(v-if="shifts.length === 0 && !dataIsLoading")
        td.center-text(colspan="100")
          span No data in table.
      tr(v-if="shifts && !dataIsLoading", v-for="shift in shifts")
        td(data-cell="checkbox")
          input(
            :id="shift.id",
            type="checkbox",
            :value="{ id: shift.id }",
            v-model="selectedShifts",
            @change="shiftChanged($event.target.checked, shift.id)"
          )
        td.shift-type-td
          span(v-tooltip="getShiftRequestTypeImage(shift, 'tooltip')")
            template(v-if="getShiftRequestTypeImage(shift, 'sym')")
              img(
                :src="getShiftRequestTypeImage(shift, 'sym')",
                :class="getShiftRequestTypeImage(shift, 'class')"
              )
            template(v-else)
              span.fa(
                :class="`fa-${getShiftRequestTypeImage(shift, 'faIcon')}`"
              )
        td(
          data-cell="activity-tags",
          v-if="initialStatus.can_use_shift_activity_tags && $can('edit-shift-activity-tag') && $can('view-shift-activity-tag') && shift.tags",
          style="min-width: 140px"
        )
          multi-select(
            :disabled="false",
            :id="shift.id",
            :options="activityTagsList",
            :value="shiftTagValue(shift)",
            select-label="",
            @select="updateTags($event, shift)",
            track-by="id",
            label="name",
            placeholder="CHANGE TAG",
            style="width: 140px"
          )
            span(slot="noResult") Nothing found.
        td(
          data-cell="activity-tags",
          v-else-if="initialStatus.can_use_shift_activity_tags && $can('view-shift-activity-tag')"
        ) {{ shift.tags?.length > 0 ? shift.tags[0].name : "" }}

        td(data-cell="status")
          .flexer
            span.is-generic-app-tag(
              :style="colorStatus(shift)",
              v-if="shift.status !== 'Repush'"
            ) {{ shift.status }}
            button.book-shift.button.is-generic-app-blue.is-tiny.is-outlined(
              v-if="shift.status == 'Repush'",
              @click="showModal('repush-for-temps', { shift: shift }, 'Select agency workers')"
            ) Book
            span.indicator(
              v-if="shift.previousTemp && !shift.temp",
              v-tooltip="shift.previousTemp.name"
            )
              svg(
                xmlns="http://www.w3.org/2000/svg",
                width="11",
                height="13",
                viewbox="0 0 11 13"
              )
                g(fill="none", fill-rule="evenodd")
                  path(
                    :fill="getGlobalPrimaryColorHex",
                    d="M5.43426833,6.36330855 C7.01239608,6.36330855 8.29184184,4.93884758 8.29184184,3.18167286 C8.29184184,1.42446097 7.87178101,0 5.43426833,0 C2.99675566,0 2.57661372,1.42446097 2.57661372,3.18167286 C2.57661372,4.93884758 3.85605948,6.36330855 5.43426833,6.36330855 Z"
                  )
                  path(
                    fill="#000000",
                    d="M.0432300725 11.6662418C.0372300725 10.6933135.0312300725 11.3921204.0432300725 11.6662418L.0432300725 11.6662418zM10.8314295 11.7076616C10.8504295 11.5569685 10.8379295 10.6621685 10.8314295 11.7076616L10.8314295 11.7076616z"
                  )
                  path(
                    :fill="getGlobalPrimaryColorHex",
                    d="M10.8253464,11.0928253 C10.7724231,8.03178439 10.3363028,7.1595539 6.99897263,6.60743494 C6.99897263,6.60743494 6.52919229,7.156171 5.43422778,7.156171 C4.33926326,7.156171 3.86940182,6.60743494 3.86940182,6.60743494 C0.568489355,7.1535316 0.10584657,8.01282528 0.0450152078,10.9933829 C0.0400270362,11.2367658 0.0377154444,11.2495539 0.0368232511,11.2213011 C0.0370260223,11.2742379 0.0372693478,11.3721561 0.0372693478,11.5428996 C0.0372693478,11.5428996 0.831808043,13.0111524 5.43422778,13.0111524 C10.0365664,13.0111524 10.8311862,11.5428996 10.8311862,11.5428996 C10.8311862,11.433197 10.8312673,11.3569145 10.831389,11.3050186 C10.8304968,11.3224907 10.8287124,11.2886245 10.8253464,11.0928253 Z"
                  )
            span.indicator(
              v-if="shift.previousTemp && shift.temp",
              v-tooltip="shift.previousTemp.name"
            )
              svg(
                xmlns="http://www.w3.org/2000/svg",
                width="12",
                height="12",
                viewbox="0 0 12 12"
              )
                g(
                  fill="#7C91AE",
                  fill-rule="evenodd",
                  transform="translate(.17)",
                  opacity=".6"
                )
                  path(
                    d="M1.02413185 4.91239254C1.43208652 4.91239254 1.80058438 4.68551754 1.95838554 4.33696711 2.6099306 2.89915132 4.14197434 1.88584868 5.92802612 1.88584868 6.91241469 1.88584868 7.81489458 2.19835965 8.53842552 2.7166864L7.9713154 2.79800439C7.77759944 2.82581798 7.62690832 2.96816667 7.60065588 3.14807456 7.57435134 3.32788596 7.67899651 3.50234211 7.85763294 3.5766886L10.9763088 4.87623246C11.1130143 4.93308991 11.2713884 4.92216228 11.3973897 4.84716447 11.5231827 4.77204605 11.5995961 4.64313377 11.6004295 4.50442763L11.6166029 1.32159211C11.6176967 1.14052632 11.490263.980399123 11.3034487.927618421 11.1163219.874837719 10.9141676.941826754 10.8059023 1.09254605L10.3760446 1.69091228C9.22997431.645960526 7.65956761 0 5.92802612 0 3.3025472 0 1.04522757 1.48504825.0824816411 3.59574561-.05122873 3.88830702-.0175276743 4.22330044.171786834 4.48797588.361101343 4.75274781.681105108 4.91239254 1.02413185 4.91239254zM11.6842915 7.26540351C11.4949509 7.00060746 11.1749211 6.84098684 10.8319204 6.84098684 10.4239657 6.84098684 10.0554679 7.06786184 9.89766671 7.41641228 9.2460956 8.85422807 7.71405186 9.8675307 5.92802612 9.8675307 4.94361151 9.8675307 4.04113162 9.55501974 3.31757464 9.03669298L3.8847108 8.95542325C4.07840071 8.92760965 4.22909183 8.78523684 4.25539637 8.60530482 4.28167486 8.42551754 4.17705574 8.25103728 3.99841931 8.17669079L.879743402 6.87714693C.743011916 6.8203136.584663833 6.83119298.458636469 6.90621491.332869547 6.98133333.256430135 7.11024561.255622768 7.24892763L.239423343 10.4317632C.238329491 10.6128289.365737191 10.7729561.552577511 10.8257368.739704316 10.8785175.941884606 10.8115044 1.05012385 10.660761L1.4799816 10.0623947C2.62602585 11.1073947 4.19645859 11.7533553 5.92800008 11.7533553 8.55353109 11.7533553 10.8107726 10.2683311 11.7734925 8.15763377 11.907281 7.86507237 11.8735799 7.53010307 11.6842915 7.26540351z"
                  )
        td.name(data-cell="name", v-if="shift.temp && visibleColumns.name")
          .flexer
            span {{ shift.temp.name }}
            span(
              v-if="visibleToolIcons.tempEditingIcon && !isTemp && isNotTempAssigned(shift) && gPermissions.canAssignTempToShift",
              @click="showModal('temp-shift-name-manage', { shift: shift, temp: { name: shift.temp.name, id: shift.temp.id } }, 'Edit Temp Name')"
            )
              //- @click="showModal('edit-temp-name', { shift: shift, temp: { name: shift.temp.name, id: shift.temp.id } }, 'Edit Temp Name')"
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <g :fill='getGlobalPrimaryColorHex'>
                  <polygon points="10.383 3.089 8.886 1.593 4.25 6.234 5.764 7.713"/>
                  <path d="M10.174549,0.309843137 L9.4074902,1.07707843 L10.9043137,2.57360784 L11.6713725,1.80635294 C12.0839608,1.393 12.0847059,0.723176471 11.6713725,0.309823529 C11.2581176,-0.103411765 10.5878039,-0.103137255 10.174549,0.309843137 Z"/>
                  <polygon points="2.731 9.25 5.285 8.174 3.798 6.72"/>
                  <polygon points="10.467 4.394 9.023 5.817 9.023 10.519 1.399 10.519 1.399 2.925 6.195 2.925 7.531 1.514 0 1.514 0 11.981 10.467 11.981"/>
                </g>
              </svg>
            span(
              v-if="visibleToolIcons.tempUnassignIcon && !isTemp && isNotTempAssigned(shift)",
              @click="unassignTemp(shift.id)"
            )
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                <image width="24" height="24" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAmVBMVEUAAAD/////3Nz/kJD/ W1v/Q0P/RET/XFz/kZH/39//+vr/jIz/PT3/Ozv/+/v/bW3/cXH/i4v/QkL/WVn/Pj7/4OD/8PD/ y8v/z8//7+//WFj/lZX/QUH/xMT/wsL/QED/YGD/x8f/ycn/SUn/RUX/yMj/ysr/Skr/xcX/YWH/ Wlr/Pz//7e3/V1f/lpb/4+P/dXX//PwAAADg9STDAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgA AAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhBhkNEyd/oOiYAAAAvUlEQVQoz72QaxKCMAyE Dc+CFBFBHkVAEAFRkPtfzhRnSjmA7q/NfjOZbHa7PwlQiqrphkks7iVg7x26yFFdGdgHKuS5EjhS SWQFCt+j+ZTqJzRBKIDK83Pk63GSomUCaDhlF4hiyAu0pQAGX11cASqe01qA2wIagPsCWgFMnPQG ugq6DG0vAMHpkeRD0T1faEcBLH5uimsyng/TWlCVCzKpueut+XuWn+iS4BsPbN58FyBkZd3247R5 +4/1Aa3zD4n3YPR+AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA2LTI1VDEzOjE5OjM5KzAyOjAw gFnURAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wNi0yNVQxMzoxOTozOSswMjowMPEEbPgAAAA3 dEVYdHNvZnR3YXJlAGZpbGU6Ly8vdXNyL3NoYXJlL2RvYy9JbWFnZU1hZ2ljay02L2luZGV4Lmh0 bWx0LPowAAAAGHRFWHRUaHVtYjo6RG9jdW1lbnQ6OlBhZ2VzADGn/7svAAAAF3RFWHRUaHVtYjo6 SW1hZ2U6OkhlaWdodAAxNh2vXm8AAAAWdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgAMTblAJ7iAAAA GXRFWHRUaHVtYjo6TWltZXR5cGUAaW1hZ2Uvc3Znv+9wTgAAABd0RVh0VGh1bWI6Ok1UaW1lADE0 OTUyMjE3OTVK3a8kAAAAEXRFWHRUaHVtYjo6U2l6ZQA1MzNCQma+YMMAAAAcdEVYdFRodW1iOjpV UkkAZmlsZTovL2RlbGV0ZS5zdmeve4pMAAAAAElFTkSuQmCC"/>
              </svg>
        td.no-name(
          data-cell="no-name",
          v-if="!shift.temp && visibleColumns.name"
        ) -
          span(
            v-if="visibleToolIcons.tempEditingIcon && !isTemp && gPermissions.canAssignTempToShift",
            @click="showModal('temp-shift-name-manage', { shift: shift, temp: { name: null, id: null } }, 'Choose Temp')"
          )
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <g :fill='getGlobalPrimaryColorHex'>
                <polygon points="10.383 3.089 8.886 1.593 4.25 6.234 5.764 7.713"/>
                <path d="M10.174549,0.309843137 L9.4074902,1.07707843 L10.9043137,2.57360784 L11.6713725,1.80635294 C12.0839608,1.393 12.0847059,0.723176471 11.6713725,0.309823529 C11.2581176,-0.103411765 10.5878039,-0.103137255 10.174549,0.309843137 Z"/>
                <polygon points="2.731 9.25 5.285 8.174 3.798 6.72"/>
                <polygon points="10.467 4.394 9.023 5.817 9.023 10.519 1.399 10.519 1.399 2.925 6.195 2.925 7.531 1.514 0 1.514 0 11.981 10.467 11.981"/>
              </g>
            </svg>
        //- td(v-if="$can('copy-shift')")
        //- td(v-if='enableLanePre')
          span
            router-link.button.is-generic-app-blue.is-caps-lock.is-tiny.copy-shift(
              title="Copy shift details",
              :to="{ name: 'submitShiftLine', params: { shift }, query: { calendar: true } }",
              @mousedown.native="copyShiftDetailsFunc(shift)",
              target="_blank"
            ) Copy
        td.subcat(data-cell="subcat")
          span.generic-app-tag {{ getShiftAbbreviation(shift) }}
        td.date(data-cell="date") {{ getShiftDate(shift.startTime) }}
          span(
            v-if="!isTemp",
            @click="showModal('edit-time', { shiftId: shift.id, startDateTime: shift.startTime, endDateTime: shift.endTime }, 'Edit Time')"
          )
            <svg v-if="visibleToolIcons.timeEditingIcon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <g :fill='getGlobalPrimaryColorHex'>
                <polygon points="10.383 3.089 8.886 1.593 4.25 6.234 5.764 7.713"/>
                <path d="M10.174549,0.309843137 L9.4074902,1.07707843 L10.9043137,2.57360784 L11.6713725,1.80635294 C12.0839608,1.393 12.0847059,0.723176471 11.6713725,0.309823529 C11.2581176,-0.103411765 10.5878039,-0.103137255 10.174549,0.309843137 Z"/>
                <polygon points="2.731 9.25 5.285 8.174 3.798 6.72"/>
                <polygon points="10.467 4.394 9.023 5.817 9.023 10.519 1.399 10.519 1.399 2.925 6.195 2.925 7.531 1.514 0 1.514 0 11.981 10.467 11.981"/>
              </g>
            </svg>
        td.time(data-cell="time")
          .flexer
            span {{ getShiftTimeRange(shift) }}
            //- span(v-if="!isTemp" @click="showModal('edit-time', { shiftId: shift.id, startDateTime: shift.startTime, endDateTime: shift.endTime  }, 'Edit Time')")
              <svg v-if="visibleToolIcons.timeEditingIcon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <g :fill='getGlobalPrimaryColorHex'>
                  <polygon points="10.383 3.089 8.886 1.593 4.25 6.234 5.764 7.713"/>
                  <path d="M10.174549,0.309843137 L9.4074902,1.07707843 L10.9043137,2.57360784 L11.6713725,1.80635294 C12.0839608,1.393 12.0847059,0.723176471 11.6713725,0.309823529 C11.2581176,-0.103411765 10.5878039,-0.103137255 10.174549,0.309843137 Z"/>
                  <polygon points="2.731 9.25 5.285 8.174 3.798 6.72"/>
                  <polygon points="10.467 4.394 9.023 5.817 9.023 10.519 1.399 10.519 1.399 2.925 6.195 2.925 7.531 1.514 0 1.514 0 11.981 10.467 11.981"/>
              </g>
              </svg>
        td(data-cell="total-hours")
          span(:title="'Break times: ' + (shift.breakMinutes || 0) + ' min'") {{ getShiftTotalHours(shift) }}
        td.client(data-cell="client", v-if="auth.user.role !== 'client-admin'") {{ shift.location.client.name }}
        td(
          data-cell="cost-centre",
          v-if="$can('edit-shift')",
          style="min-width: 250px"
        )
          multi-select(
            :disabled="chgCostDisabled",
            :id="shift.id",
            :options="getLocationsFromClient(shift.location.client)",
            :value="{ id: shift.id, name: shift.location.name }",
            select-label="",
            @select="updateLocation",
            track-by="id",
            label="name",
            placeholder="CHANGE WARD"
          )
            span(slot="noResult") Nothing found.
        td(data-cell="cost-centre-label", v-else) {{ shift.location.name }}
        td(data-cell="last-pushed") {{ shift.shiftRequest.createdBy.name }}
        td(data-cell="shift-id") {{ shift.id }}
        td(
          data-cell="action-temp",
          v-if="visibleColumns.action && isTemp && signOffStatus(shift)"
        )
          .columns
            .column.is-narrow
              button.await-diag-btn.button.is-generic-app-blue.is-outlined.is-tiny(
                :disabled="signOffStatus(shift).disabled",
                @click.prevent="showModal('AwaitingDialog', { url: signOffStatus(shift).url, shift: shift, shiftId: shift.id, startDateTime: shift.startTime, endDateTime: shift.endTime, breakMinutes: shift.breakMinutes, location: shift.location, shiftRequest: shift.shiftRequest, temp: shift.temp }, signOffStatus(shift).text)"
              ) {{ signOffStatus(shift) ? signOffStatus(shift).text : "" }}
        td(
          data-cell="confirmed-status",
          v-if="canUseShiftConfirm || shiftConfirmEnablement"
        )
          span.confirmed-status-text(
            :title="shift.confirmationStatus?.label",
            :style="sceStyles(shift)"
          ) {{ shift.confirmationStatus?.name || shift.confirmationStatus?.code }}
        td(data-cell="action", v-if="visibleColumns.action && !isTemp")
          .columns
            .column.is-narrow(v-if="!isProcessed(shift)")
              button.cancel-res-btn.button.is-generic-app-blue.is-caps-lock.is-tiny.is-outlined(
                @click.prevent="showModal('CancellationReason', { shiftIds: [{ id: shift.id }] }, 'Cancellation Reason')"
              ) cancel
            .column.is-narrow(v-if="isProcessed(shift)")
              span.generic-app-tag.is-caps-lock PROCESSED
            .column.is-narrow(
              v-if="isProcessed(shift) && $can('recalculate-shift')"
            )
              button.recalc-btn.button.is-generic-app-blue.is-outlined.is-tiny(
                @click.prevent="recalculateShift(shift)"
              ) RECALC
            .column.is-narrow(v-if="btnSignOff && !isProcessed(shift)")
              button.await-switch-btn.button.is-generic-app-blue.is-outlined.is-tiny(
                @click.prevent="switchAwaitingStatus(shift.id)"
              )
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                  <g fill="#7C91AE" fill-rule="evenodd" transform="translate(.17)" opacity=".6">
                    <path d="M1.02413185 4.91239254C1.43208652 4.91239254 1.80058438 4.68551754 1.95838554 4.33696711 2.6099306 2.89915132 4.14197434 1.88584868 5.92802612 1.88584868 6.91241469 1.88584868 7.81489458 2.19835965 8.53842552 2.7166864L7.9713154 2.79800439C7.77759944 2.82581798 7.62690832 2.96816667 7.60065588 3.14807456 7.57435134 3.32788596 7.67899651 3.50234211 7.85763294 3.5766886L10.9763088 4.87623246C11.1130143 4.93308991 11.2713884 4.92216228 11.3973897 4.84716447 11.5231827 4.77204605 11.5995961 4.64313377 11.6004295 4.50442763L11.6166029 1.32159211C11.6176967 1.14052632 11.490263.980399123 11.3034487.927618421 11.1163219.874837719 10.9141676.941826754 10.8059023 1.09254605L10.3760446 1.69091228C9.22997431.645960526 7.65956761 0 5.92802612 0 3.3025472 0 1.04522757 1.48504825.0824816411 3.59574561-.05122873 3.88830702-.0175276743 4.22330044.171786834 4.48797588.361101343 4.75274781.681105108 4.91239254 1.02413185 4.91239254zM11.6842915 7.26540351C11.4949509 7.00060746 11.1749211 6.84098684 10.8319204 6.84098684 10.4239657 6.84098684 10.0554679 7.06786184 9.89766671 7.41641228 9.2460956 8.85422807 7.71405186 9.8675307 5.92802612 9.8675307 4.94361151 9.8675307 4.04113162 9.55501974 3.31757464 9.03669298L3.8847108 8.95542325C4.07840071 8.92760965 4.22909183 8.78523684 4.25539637 8.60530482 4.28167486 8.42551754 4.17705574 8.25103728 3.99841931 8.17669079L.879743402 6.87714693C.743011916 6.8203136.584663833 6.83119298.458636469 6.90621491.332869547 6.98133333.256430135 7.11024561.255622768 7.24892763L.239423343 10.4317632C.238329491 10.6128289.365737191 10.7729561.552577511 10.8257368.739704316 10.8785175.941884606 10.8115044 1.05012385 10.660761L1.4799816 10.0623947C2.62602585 11.1073947 4.19645859 11.7533553 5.92800008 11.7533553 8.55353109 11.7533553 10.8107726 10.2683311 11.7734925 8.15763377 11.907281 7.86507237 11.8735799 7.53010307 11.6842915 7.26540351z"/>
                  </g>
                </svg>
            .column.is-marginless(v-if="btnSignOff && !isProcessed(shift)")
              button.await-diag-btn.button.is-generic-app-blue.is-caps-lock.is-tiny(
                :disabled="signOffStatus(shift).disabled",
                @click.prevent="showModal('AwaitingDialog', { url: signOffStatus(shift).url, shift: shift, shiftId: shift.id, startDateTime: shift.startTime, endDateTime: shift.endTime, breakMinutes: shift.breakMinutes, location: shift.location, shiftRequest: shift.shiftRequest, temp: shift.temp }, signOffStatus(shift).text)"
              ) {{ signOffStatus(shift).text }}
        td(data-cell="shift-details")
          router-link.button.is-generic-app-blue.is-caps-lock.is-tiny(
            :to="{ name: 'shiftDetails', params: { shiftId: shift.id, rememberTab: rememberTab }, query: { status: activeTab } }"
          ) details
  .button-wrap
    transition(name="fade")
      button.button.is-generic-app-blue(
        v-if="selectedShifts.length",
        @click.prevent="showModal('CancellationReason', { shiftIds: selectedShifts }, 'Cancellation Reason')"
      ) CANCEL ALL
    transition(name="fade")
      button.button.is-generic-app-blue(
        v-if="selectedShifts.length && hasPermissionAddToInvoice && isActiveTabCompleted",
        @click.prevent="onSetShiftsToInvoice"
      ) ADD TO INVOICE
    transition(name="fade")
      button.button.is-generic-app-blue(
        v-if="selectedShiftObjs.length && isActiveTabCompleted",
        @click.prevent="showModal('MultipleSignOff', selectedShiftObjs, 'Multiple Sign Off')"
      ) MULTIPLE SIGN OFF
</template>
<script>
/* eslint camelcase: "off" */
import {
  SIGN_OFF_IN_ARBITRATION,
  STATUS,
  VISIBLE_COLUMNS,
  VISIBLE_TOOL_ICONS,
} from "../config/shifts.js";
import {
  diffArrayOfObjects,
  momentDiff,
  parseDateObjWithTimeZone,
  parseErrors,
  parseObjWithStartEnd,
} from "../../lib/helpers/function.js";
import _ from "underscore";
import moment from "moment";

import MultiSelect from "vue-multiselect";
import EditTime from "../in-modal/EditTime.vue";
import AwaitingDialog from "../in-modal/AwaitingDialog.vue";
import MultipleSignOff from "../in-modal/MultipleSignOff.vue";
import EditTempName from "../in-modal/EditTempName.vue";
import TempShiftNameManage from "../in-modal/TempShiftNameManage.vue";
import RepushForTemps from "../in-modal/RepushForTemps.vue";
import CancellationReason from "../in-modal/CancellationReason.vue";
import ShiftScheduleInvoiceErrors from "@/components/tables/ShiftScheduleInvoiceErrors.vue";

import { Evt } from "../../lib/helpers/Evt.js";
import auth from "../../lib/auth.js";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "ShiftTable",
  components: {
    MultiSelect,
    EditTime,
    AwaitingDialog,
    ShiftScheduleInvoiceErrors,
    MultipleSignOff,
    EditTempName,
    TempShiftNameManage,
    CancellationReason,
    RepushForTemps,
  },
  props: {
    shifts: { required: true },
    dataIsLoading: { required: false },
    filtersTable: { required: false },
    rememberTab: { required: false },
    resetClientCo: { required: false },
    visibleColumns: { required: false, default: () => VISIBLE_COLUMNS },
    visibleToolIcons: { required: false, default: () => VISIBLE_TOOL_ICONS },
    isLoadingClient: { default: false },
    shiftFiltersInstance: { required: true },
  },
  data() {
    return {
      auth: auth,
      isLoadingAgencyWorker: false,
      isSaveLoading: false,
      modalData: null, // will fetch dynamically, depending on where clicked and which modal
      modalVisible: false,
      inmodal: "", // will switch depending on which modal we are showing
      allShiftsSelected: false,
      selectedShifts: [],
      selectedShiftObjs: [],
      rowHeight: "",
      baseShiftSpecialData: {
        notifications: {
          sym: require("./../../assets/images/comps/shift_schedule/2.svg"),
          tooltip: "Push via notifications",
        },
        clientAssigned: {
          sym: require("./../../assets/images/comps/shift_schedule/1.svg"),
          tooltip: "Client assigned",
        },
        bulkUpload: {
          faIcon: "upload",
          tooltip: "Bulk upload",
        },
        tempAssigned: {
          sym: require("./../../assets/images/comps/shift_schedule/temps-shift.svg"),
          tooltip: "Temp assigned",
        },
        onCall: {
          sym: require("./../../assets/images/comps/shift_schedule/STBY.svg"),
          tooltip: "Standby / On call",
          class: "onCall",
        },
        sleepover: {
          sym: require("./../../assets/images/comps/shift_schedule/sleep.png"),
          tooltip: "Sleepover",
          class: "sleepover",
        },
      },
      filters: {
        visible: true,
      },
      locationsOptions: [],
      filterBy: {
        selectedAgencyWorker: null,
        selectedClient: [],
        selectedLocation: null,
        selectedSubcategory: null,
        selectedPayrollStatuses: null,
        shiftId: null,
        externalTssId: "",
        client_external_id: "",
      },
      selectedSortColumn: {
        column: "",
        direction: "",
      },
      // isWorkerOnce: false
      activityTagsList: [],
    };
  },
  computed: {
    ...mapGetters({
      activeTab: "getActiveTab",
      agencyWorkers: "getAgencyWorkers",
      clientsWithLocations: "getClientsWithLocations",
      clients: "getAllClients",
      optionsSubcategories: "getCategoriesWithSubcategories",
    }),
    btnSignOff() {
      return (
        this.activeTab.name === "ACTIVE" || this.activeTab.name === "COMPLETED"
      );
    },
    isActiveTabCompleted() {
      return this.activeTab.name === "COMPLETED";
    },
    chgCostDisabled() {
      return this.btnSignOff && this.activeTab.name !== "ACTIVE";
      // return this.btnSignOff;
    },
    getModalSize() {
      if (this.inmodal === "MultipleSignOff") {
        return 1920;
      } else if (this.inmodal === "ShiftScheduleInvoiceErrors") {
        return 680;
      } else if (this.inmodal === "edit-time") {
        return 600;
      } else if (this.inmodal === "AwaitingDialog") {
        return 576;
      } else if (this.inmodal === "AfterApiModal") {
        return 576;
      } else if (this.inmodal === "BeforeApiModal") {
        return 576;
      }
      return 550;
    },
    /**
     * check is temp allowed to see this!?
     */
    isTemp() {
      if (this.$user) {
        return this.$user.role === "temp";
      }
      return true;
    },
    isModalScrollable() {
      return this.inmodal === "repush-for-temps";
    },
    getOverflowVisible() {
      return this.inmodal === "CancellationReason";
    },
    isAnyChecked() {
      return (
        this.shifts.length &&
        this.shifts.some((shift) =>
          this.selectedShifts.map((selShift) => selShift.id).includes(shift.id)
        )
      );
    },
    isAllChecked() {
      // [].every() returns true
      if (this.shifts.length === 0) {
        return false;
      } else {
        return this.shifts.every((shift) =>
          this.selectedShifts.map((selShift) => selShift.id).includes(shift.id)
        );
      }
    },
    hasPermissionAddToInvoice() {
      return this.$can("add-shifts-to-invoice");
    },
    clientLocationLength() {
      return this.clientsWithLocations.length || 0;
    },
    agencyWorkersLength() {
      return this.agencyWorkers.length || 0;
    },
  },
  watch: {
    allShiftsSelected() {
      console.log("WATCH: all shifts selection triggered");
      if (!this.shifts) {
        return;
      }

      if (!this.allShiftsSelected) {
        this.selectedShifts = diffArrayOfObjects(
          this.selectedShifts,
          this.shifts
        );

        this.selectedShiftObjs = diffArrayOfObjects(
          this.selectedShiftObjs,
          this.shifts
        );
      } else {
        this.shifts.map((e) => {
          if (!_.findWhere(this.selectedShifts, { id: e.id })) {
            this.selectedShifts.push({ id: e.id });
            if (!this.isProcessed(e)) this.selectedShiftObjs.push(e);
          }
        });
      }
      this.$emit("shiftSelected", this.selectedShifts);
    },
    selectedSortColumn(value) {
      this.$emit("sort-results", value);
    },
    resetClientCo() {
      console.log("Resetting table client");
      this.removeFilter(null, "client");
    },
    isAnyChecked(val) {
      // console.log("isAnyChecked", val);
      if (val) {
        if (!this.isAllChecked) {
          this.$refs.selectAllCheckbox.indeterminate = true;
        }
      } else {
        this.$refs.selectAllCheckbox.indeterminate = false;
        this.allShiftsSelected = false;
      }
    },
    isAllChecked(val) {
      // console.log("isAllChecked", val);
      if (val) {
        this.allShiftsSelected = true;
        this.$refs.selectAllCheckbox.indeterminate = false;
      } else {
        if (this.isAnyChecked) {
          this.$refs.selectAllCheckbox.indeterminate = true;
        }
      }
    },
    agencyWorkersLength: {
      handler() {
        const tempName = this.$route.query.temp;
        if (tempName) {
          const foundObj = this.agencyWorkers.find((el) => el.name == tempName);
          if (foundObj) {
            const parsedVal = foundObj;
            this.$set(this.filterBy, "selectedAgencyWorker", parsedVal);
          }
        }
      },
      immediate: true,
    },
    clientLocationLength: {
      handler() {
        if (this.auth.user.role && this.auth.user.role !== "temp") {
          if (this.clientsWithLocations?.length) {
            this.locationsOptions = this.clientsWithLocations;
            this.setupFiltersStartup();

            this.preselectFromCalendar();
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    Evt.listen("disposeModal", () => {
      console.error("called outdated bus");
      this.modalVisible = false;
    });
  },
  mounted() {
    if (this.auth.user.role && this.auth.user.role === "temp") {
      this.setupFiltersStartup();
    }
    if (
      this.initialStatus.can_use_shift_activity_tags &&
      (this.$can("view-shift-activity-tag") ||
        this.$can("edit-shift-activity-tag"))
    ) {
      this.shiftsActivityTags();
    }
    this.checkIfFromOtherPageInfoPop();
  },
  beforeDestroy() {
    Evt.off("disposeModal");
  },
  methods: {
    ...mapActions([
      "cancelShift",
      "patchShift",
      "findAgencyWorker",
      "switchAwaitingStatusForShift",
      "recalculateShift",
      "addShiftsToInvoices",
      "getShiftsActivityTags",
    ]),
    shiftTagValue(shift) {
      return shift.tags?.length > 0
        ? { id: shift.id, name: shift.tags[0].name }
        : { id: null, name: null };
    },
    async shiftsActivityTags() {
      try {
        const res = await this.getShiftsActivityTags();
        this.activityTagsList = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async updateTags(e, shift) {
      if (shift.tags?.map((tag) => tag.id).includes(e.id)) {
        console.log("Same tag, skipping", e.id);
        return;
      }

      const isRound3 =
        this.activityTagsList.find((obj) => obj.name === "Round 3")?.id ===
        e.id;

      if (isRound3) {
        try {
          await this.confirmAction(
            "Please note that once a shift has been set to Round 3, it cannot be reversed to another tag"
          );
        } catch (err) {
          console.warn(err.message);
          return;
        }
      }

      const shiftId = shift.id;
      console.log(JSON.stringify(e) + shiftId);
      this.patchShift({
        id: shiftId,
        data: {
          shift_activity_tag_id: e.id,
        },
      })
        .then(() => {
          this.$toasted.info("Activity tag changed.").goAway(1500);
        })
        .catch((error) => {
          const errs = parseErrors(error, "Error changing activity tag");
          this.$toasted.error(errs).goAway(2500);
        });
    },
    async onSetShiftsToInvoice() {
      const isSuccess = await this.postShiftsToInvoices();
      if (isSuccess) {
        this.selectedShifts = [];
      }
    },
    async postShiftsToInvoices() {
      await this.generateMsgAndConfirmPost();

      const params = {
        shifts: this.selectedShifts,
      };

      const parseAndShowErrorModal = (err, msg) => {
        const customPayload = {
          ...err,
          response: {
            ...err?.response,
            data: {
              ...err?.response?.data,
              ...err?.response?.data?.data,
            },
          },
        };
        const errs = parseErrors(customPayload, msg, true);

        this.showModal(
          "ShiftScheduleInvoiceErrors",
          {
            data: customPayload,
          },
          "Shift invoice statuses"
        );
        return errs;
      };

      this.isSaveLoading = true;
      try {
        const res = await this.addShiftsToInvoices(params);
        this.isSaveLoading = false;
        const customPayload = {
          response: {
            data: res.data,
          },
        };
        const statusMsg = parseAndShowErrorModal(
          customPayload,
          "Shifts added to invoices."
        );
        this.$toasted.info(statusMsg).goAway(1500);
        return true;
      } catch (err) {
        this.isSaveLoading = false;
        const statusMsg = parseAndShowErrorModal(
          err,
          "Error adding shifts to invoices."
        );
        console.warn(statusMsg);
        this.$toasted.info(statusMsg).goAway(1500);
        return false;
      }
    },
    async generateMsgAndConfirmPost() {
      const msg = `
        Are you sure you want to add
        ${this.selectedShifts.length}
        shift${this.selectedShifts.length > 1 ? "s" : ""}
        to
        ${this.selectedShifts.length > 1 ? "their" : "its"}
        invoice${this.selectedShifts.length > 1 ? "s" : ""}?
      `;
      await this.confirmAction(msg);
    },
    disposeModalFunc() {
      this.modalVisible = false;
      this.isSaveLoading = false;
    },
    isNotTempAssigned(shift) {
      const TEMP_ASSIGNED_STATUS = 6;
      return shift.shiftRequest.type !== TEMP_ASSIGNED_STATUS;
    },
    getShiftDate(shiftStartTime) {
      const date = parseDateObjWithTimeZone(shiftStartTime);
      const dateTimeObj = this.timeParts(date);
      return dateTimeObj.date;
    },
    getLocationsFromClient(client) {
      // So it doesn't crash the multiselect comp
      // console.log(client);
      return client.locations || [];
    },
    getShiftTimeRange(shift) {
      const dateStart = parseDateObjWithTimeZone(shift.startTime);
      const dateEnd = parseDateObjWithTimeZone(shift.endTime);
      let str = `${this.timeParts(dateStart).time}h`;
      if (dateEnd) {
        str += `- ${this.timeParts(dateEnd).time}h`;
      } else {
        str += " ESCORT";
      }
      return str;
    },
    getShiftTotalHours(shift) {
      const dateObj = shift;
      const startTime = dateObj.startTime?.date;
      const endTime = dateObj.endTime?.date;
      const breakMinutes = dateObj.breakMinutes;
      if (this.isProcessed(shift)) {
        return this.generateTotalHours(startTime, endTime, breakMinutes);
      }
      return this.generateTotalHours(startTime, endTime, 0);
    },
    getShiftTotalMinutes(startTime, endTime, breakMinutesInt) {
      return moment(endTime)
        .subtract(breakMinutesInt, "minutes")
        .diff(startTime, "minutes");
    },
    generateTotalHours(startTime, endTime, breakMinutes) {
      const generateTotalHoursString = (mins) => {
        if (mins) {
          const hours = Math.floor(mins / 60)
            .toString()
            .padStart(2, 0);
          const minsRem = (mins % 60).toString().padStart(2, 0);
          return `${hours}h : ${minsRem}m`;
        }
        return "";
      };

      if (startTime && endTime) {
        const breakMinutesParsed = parseInt(breakMinutes) || 0;
        const mins = this.getShiftTotalMinutes(
          startTime,
          endTime,
          breakMinutesParsed
        );
        return generateTotalHoursString(mins);
      }
      return "";
    },
    setTempFromOptionsDropdown(query) {
      let foundObj = null;
      if (query) {
        foundObj = this.agencyWorkers.find((el) => el.name === query);
      }
      // else if (tempName) {
      //   foundObj = this.agencyWorkers.find((el) => el.id == tempName);
      // }
      if (foundObj) {
        const parsedVal = foundObj;
        this.$set(this.filterBy, "selectedAgencyWorker", parsedVal);
        return true;
      }
      return false;
    },
    setupFiltersStartup() {
      const tempName = this.$route.query.temp;
      const map = {
        name: "selectedAgencyWorker",
        "locations[][id]": "selectedLocation",
        // "subcategories[][id]": "selectedSubcategory",
        subcategories: "selectedSubcategory",
        clients: "selectedClient",
        id: "shiftId",
        tss_id: "externalTssId",
      };

      const fetchTemp = (val) => {
        const query = tempName || val;
        this.findAgencyWorker({
          query,
          originallySelectedWorker: "",
        }).then(() => {
          this.setTempFromOptionsDropdown(query);
        });
      };

      if (this.filtersTable && typeof this.filtersTable === "object") {
        const setupId = () => {
          const val = this.filtersTable.id;
          this.$set(this.filterBy, map["id"], val);
        };
        const setupTssId = () => {
          const val = this.filtersTable.tss_id;
          this.$set(this.filterBy, map["tss_id"], val);
        };

        const setupName = () => {
          const val = this.filtersTable.name;
          const isAlreadySet = this.setTempFromOptionsDropdown(val);
          if (!isAlreadySet) {
            if (val) {
              fetchTemp(val);
            } else {
              this.$set(this.filterBy, map["name"], val);
            }
          }
        };

        const setupSubcats = () => {
          let val = this.filtersTable.subcategories;
          console.warn("Subcats", val);
          if (val && Array.isArray(val)) {
            const mappedItems = val.map((el) => el.id);
            val = this.optionsSubcategories.reduce((acc, curr) => {
              if (curr.subs?.length) {
                for (const sub of curr.subs) {
                  if (mappedItems.includes(sub.id)) {
                    console.warn(sub.id);
                    acc.push(sub);
                  }
                }
              }
              return acc;
            }, []);
          }
          this.$set(this.filterBy, map["subcategories"], val);
        };

        const setupClients = () => {
          let val = this.filtersTable.clients;
          if (val && Array.isArray(val)) {
            const mappedClients = val.map((el) => el.id);
            val = this.clients.filter((el) => mappedClients.includes(el.id));
          }
          this.$set(this.filterBy, map["clients"], val);
          this.filterLocationsFunc();
        };

        const setupLocations = () => {
          let val = this.filtersTable["locations[][id]"];
          for (const el of this.locationsOptions) {
            const foundObj = el.subs.find((el) => el.id === val);
            if (foundObj) {
              val = foundObj;
              break;
            }
          }
          this.$set(this.filterBy, map["locations[][id]"], val);
        };

        setupId();
        setupTssId();
        setupName();
        setupClients();
        setupLocations();
        setupSubcats();
      }
    },
    sceStyles(shift) {
      const obj = shift.confirmationStatus;
      if (obj) {
        if (obj.style) {
          return obj.style;
        } else {
          const bcolor = obj.background_color;
          const color = obj.color;
          return {
            color,
            backgroundColor: bcolor,
          };
        }
      }
      return {};
    },
    copyShiftDetailsFunc(shift) {
      // WIP [4 Delayed] Needs to be reworked to be same as the obj from calendar
      // console.log("setting shift for new tab", shift);
      // localStorage.shiftRowDetailsOnce = JSON.stringify(shift);
      // localStorage.shiftRowDetailsOnceTimestamp = moment().format(
      //   "YYYY-MM-DD HH:mm:ss"
      // );
    },
    preselectFromCalendar() {
      // Preselect from calendar client & ward
      if (this.$route.query.client) {
        const clientId = parseInt(this.$route.query.client, 10);
        const clientObj = this.clients.find((client) => client.id === clientId);
        // Only accepts a single client from calendar
        this.filterBy.selectedClient = [clientObj];
        console.log(clientObj, this.clients);
        this.fireSearchMultiple(clientObj, "client");
      }
      if (this.$route.query.cc) {
        const locationId = parseInt(this.$route.query.cc, 10);
        let locationObj = null;
        for (const el of this.locationsOptions) {
          const foundObj = el.subs.find((el) => el.id === locationId);
          if (foundObj) {
            locationObj = foundObj;
            break;
          }
        }
        if (locationObj) {
          // this.filterBy.selectedLocation = locationObj
          console.log(locationObj);
          this.fireSearch(locationObj, "location");
        } else {
          console.warn("No ward >>", this.locationsOptions);
        }
      }
    },
    checkIfFromOtherPageInfoPop() {
      if (this.$route.query.shift_id) {
        this.$set(this.filterBy, "shiftId", this.$route.query.shift_id);
      }
      if (this.$route.query.tss) {
        this.$set(this.filterBy, "externalTssId", this.$route.query.tss);
      }
      if (this.$route.query.cliExtI) {
        this.$set(
          this.filterBy,
          "client_external_id",
          this.$route.query.cliExtI
        );
      }
      //  else {
      //   this.$set(this.filterBy, "shiftId", null);
      // }
    },
    filterLocationsFunc(isReset = false) {
      // remove selected ward and show in dropdown only those related to the selected client
      this.filterBy.selectedLocation = null;
      const filterLocations = () => {
        const arr = this.filterBy.selectedClient;
        if (typeof arr === "object" && Array.isArray(arr) && arr.length) {
          return arr.map((el) => {
            return { client: el.name, subs: el.locations };
          });
        }
        if (isReset) {
          return this.clientsWithLocations;
        }
        return null;
      };

      const filterRes = filterLocations();
      if (filterRes !== null && Array.isArray(filterRes)) {
        this.locationsOptions = filterRes;
      }
    },
    filterPayrollStatuses(value) {
      this.filterBy.selectedPayrollStatuses = value;
      this.shiftFiltersInstance.updateVal?.({
        payroll_shift_statuses: value,
      });
    },
    filterSubcat(value) {
      this.filterBy.selectedSubcategory = value;
      this.shiftFiltersInstance.updateVal?.({
        subcategory: value,
      });
    },
    async switchAwaitingStatus(id) {
      // Switches Awaiting client to Awaiting candidate and vice-versa
      // post('shifts/{shift}/toggle-sign-off);
      // Sending includes along, to be able to switch shift object in state
      // from response
      const includes = [
        "shiftRequest.subcategories",
        "shiftRequest.createdBy",
        // "shiftRequest.gender",
        "location.client.locations",
        "previousTemp",
        "signedOffBy",
        "temp",
        "shiftParts",
        "tags",
      ].join(",");
      const payload = { include: includes, id, tab: this.activeTab.name };
      try {
        const id = await this.switchAwaitingStatusForShift(payload);
        console.log(`shift ${id} should be switched`);
      } catch (err) {
        console.warn("ERROR", err.message);
      }
    },
    recalculateShift(shift) {
      const status = this.signOffStatus(shift);
      this.showModal(
        "AwaitingDialog",
        {
          url: status.url,
          shift: shift,
          shiftId: shift.id,
          startDateTime: shift.startTime,
          endDateTime: shift.endTime,
          breakMinutes: shift.breakMinutes,
          location: shift.location,
          shiftRequest: shift.shiftRequest,
          temp: shift.temp,
          isRecalc: true,
        },
        status.text
      );
    },
    colorStatus(shift) {
      const colorObj = {
        "font-weight": "bold",
      };
      if (shift.status !== "Cancelled") {
        return;
      }
      colorObj.color = shift.isConfirmedByTemp ? "green" : "red";
      return colorObj;
    },
    unassignTemp(shiftId) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      const alert = {
        title: "Unassign this temp?",
        message: "",
        type: "warning",
        useConfirmBtn: true,
        customConfirmBtnText: "Confirm",
        customConfirmBtnClass: "button is-danger",
        customCloseBtnText: "Cancel",
        customCloseBtnClass: "button is-outlined",
        onConfirm() {
          console.log("unassigning temp... for shift id : ", shiftId);
          vm.patchShift({
            id: shiftId,
            data: {
              temp_id: null,
            },
          })
            .then(() => {
              vm.$toasted
                .info(
                  "Temp unassigned from shift! Please be sure to repush this shift."
                )
                .goAway(5500);
            })
            .catch((error) => {
              const errs = parseErrors(error, "Error unassigning temp");
              this.$toasted.error(errs).goAway(2500);
            });
        },
      };
      this.$refs.simplert.openSimplert(alert);
    },
    updateLocation(location, shiftId) {
      console.log(`Update ward for shift ${shiftId}`, location);
      this.patchShift({
        id: shiftId,
        data: {
          location_id: location.id,
        },
      })
        .then(() => {
          this.$toasted.info("Ward changed.").goAway(1500);
        })
        .catch((error) => {
          const errs = parseErrors(error, "Error changing ward");
          this.$toasted.error(errs).goAway(2500);
        });
    },

    /**
     * this will be for filters
     */
    asyncFindWorker: _.debounce(async function (query) {
      if (query === "") return;
      this.isLoadingAgencyWorker = true;
      await this.findAgencyWorker({ query, originallySelectedWorker: "" });
      this.isLoadingAgencyWorker = false;
    }, 400),

    /**
     * `selected` is only the currently triggered filter passed-in as an argument
     */
    fireSearch: _.debounce(function (selected, id) {
      console.log("debounced query on its way...", selected);
      /**
       * Instead, we give an object that contains all currently set filters!
       */
      switch (id) {
        case "externalTssId": {
          this.filterBy.externalTssId = selected;
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                tss: selected,
              },
            })
            .catch(() => ({}));
          this.shiftFiltersInstance.updateVal?.({ externalTssId: selected });
          break;
        }
        case "client_external_id": {
          this.filterBy.client_external_id = selected;
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                cliExtI: selected,
              },
            })
            .catch(() => ({}));
          this.shiftFiltersInstance.updateVal?.({
            client_external_id: selected,
          });
          break;
        }
        case "agencyWorker":
          this.filterBy.selectedAgencyWorker = selected;
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                temp: selected.name,
              },
            })
            .catch(() => ({}));
          this.shiftFiltersInstance.updateVal?.({
            temp: [selected.id],
          });

          break;
        case "subcategory":
          this.filterBy.selectedSubcategory = selected;
          // this.$router
          //   .replace({
          //     query: {
          //       ...this.$route.query,
          //       subcat: selected.id,
          //     },
          //   })
          //   .catch(() => ({}));
          this.shiftFiltersInstance.updateVal?.({
            subcategories: [selected.id],
          });
          break;
        case "location":
          this.filterBy.selectedLocation = selected;
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                loc: selected.id,
              },
            })
            .catch(() => ({}));
          this.shiftFiltersInstance.updateVal?.({
            locations: [selected.id],
          });
          break;
        // case "payroll_shift_statuses":
        //   this.filterBy.selectedPayrollStatuses = selected;
        //   this.shiftFiltersInstance.updateVal?.({
        //     payroll_shift_statuses: selected.id,
        //   });
        //   break;
        case "shift-id":
          this.$set(this.filterBy, "shiftId", selected);
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                shiftId: selected.id,
              },
            })
            .catch(() => ({}));
          // this.filterBy.shiftId = selected
          this.shiftFiltersInstance.updateVal?.({ shiftId: selected.id });
          break;
      }
      this.$emit("search-by-filter", this.filterBy);
    }, 300),
    fireSearchMultiple: _.debounce(function (selected, id) {
      switch (id) {
        case "client":
          this.filterLocationsFunc(true);
          this.$emit("search-by-filter", this.filterBy, "client");
          break;
      }
    }, 300),
    onMultiselectInput(value, id) {
      switch (id) {
        case "payroll_shift_statuses":
          this.filterPayrollStatuses(value);
          this.$emit("search-by-filter", this.filterBy, id);
          break;
        case "subcategory":
          this.filterSubcat(value);
          this.$emit("search-by-filter", this.filterBy, id);
          break;
        default:
          break;
      }
    },
    removeFilter(removed, id) {
      if (id === "agencyWorker") {
        this.filterBy.selectedAgencyWorker = null;
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              temp: undefined,
            },
          })
          .catch(() => ({}));
        this.fireSearch();
      } else if (id === "location") {
        this.filterBy.selectedLocation = null;
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              loc: undefined,
            },
          })
          .catch(() => ({}));
        this.fireSearch();
      } else if (id === "payroll_shift_statuses") {
        this.fireSearchMultiple(removed, id);
      } else if (id === "client") {
        this.filterBy.selectedClient = [];
        this.filterBy.selectedLocation = null;
        this.fireSearchMultiple(removed, id);
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              cli: undefined,
            },
          })
          .catch(() => ({}));
      } else if (id === "subcategory") {
        this.filterBy.selectedSubcategory = null;
        // this.$router
        //   .replace({
        //     query: {
        //       ...this.$route.query,
        //       subcat: undefined,
        //     },
        //   })
        //   .catch(() => ({}));
        this.fireSearch();
      }
    },

    // Processed shifts can't be cancelled, others can.
    isProcessed(shift) {
      return Boolean(shift.signedOffBy);
      // return _.has(shift, 'signedOffBy')
    },

    /**
     * Admin and Temp side of a login logic(SIGN OFF)
     */
    signOffStatus(shift) {
      const { signedOffAt, signedOffBy } = shift;

      // Will set two different routes for url, depending on whether
      // the Client is doing sign-off or the temp himself.

      // POST /shifts/{id}/sign-off
      // Sign off shift
      //
      // POST /shifts/{id}/confirm-sign-off
      // Confirm sign off shift

      if (
        this.activeTab.name === "COMPLETED" ||
        this.activeTab.name === "ACTIVE"
      ) {
        if (shift.arbitrationState === SIGN_OFF_IN_ARBITRATION) {
          return {
            text: "In Arbitration",
            disabled: true,
          };
        } else if (signedOffBy) {
          return {
            // text:status.payroll_status
            text: "Processed",
            disabled: true,
            url: `/shifts/${shift.id}/recalculate`,
          };
        } else if (!signedOffAt) {
          return {
            text: "Awaiting candidate",
            disabled: false,
            url: `/shifts/${shift.id}/sign-off`,
          };
        } else if (signedOffAt) {
          return {
            text: "Awaiting client",
            disabled: this.isTemp,
            url: `/shifts/${shift.id}/confirm-sign-off`,
          };
        }
      }
    },
    /**
     * CHeck if we have a temp object contained in shifts
     * so that we know if we should draw the 'name' column
     */
    hasTemp() {
      return this.shifts.length ? this.shifts[0].temp : false;
    },

    timeParts(stringTime) {
      const m = moment(stringTime, "YYYY-MM-DD HH:mm:ss");

      return {
        date: m.isValid() ? m.format("DD/MM/YY") : null,
        time: m.isValid() ? m.format("HH:mm") : null,
      };
    },
    shiftChanged(isChecked, shiftId) {
      this.$emit("shiftSelected", this.selectedShifts);

      const shiftObj = this.shifts.find((shift) => shift.id === shiftId);
      if (!this.isProcessed(shiftObj)) {
        if (isChecked) {
          this.selectedShiftObjs.push(shiftObj);
        } else {
          const shiftIndex = this.selectedShiftObjs.findIndex(
            (shift) => shift.id === shiftId
          );
          this.selectedShiftObjs.splice(shiftIndex, 1);
        }
      }
      console.log(this.selectedShiftObjs);
    },
    getShiftAbbreviation(shift) {
      return shift.shiftRequest.subcategories
        .map((sub) => sub.abbreviation)
        .join(",");
    },

    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      // console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    shiftCancellation(cancellation) {
      this.isSaveLoading = true;
      console.warn("CANCELLATION ARG", cancellation);
      this.cancelShift(cancellation.data)
        .then(() => {
          this.isSaveLoading = false;
          this.$toasted.info("This shift has been cancelled.").goAway(1500);
          this.modalVisible = false;
          this.selectedShifts = [];
          this.selectedShiftObjs = [];
        })
        .catch((error) => {
          this.isSaveLoading = false;
          this.$toasted.error("Error cancelling shift.").goAway(1500);
          cancellation.context.errors.record(error.response.data.errors);
          // With Toaster?
          // let errs = parseErrors(error);
          // this.$toasted.error(errs).goAway(4500);
        });
    },
    /**
     * Shows column filters
     */
    filterShow(filter, event) {
      /**
       * get the height of a clicked column label row,
       *  so that we can position drop-down accordingly
       */
      const el =
        event.target.tagName === "th"
          ? event.target
          : event.target.parentElement;
      this.rowHeight = el.offsetHeight;

      const filter_name = filter.split(".")[1];
      this.filters[filter_name] = !this.filters[filter_name];
    },
    info() {
      /* console.log(e, e.offsetHeight) */
    },
    sort(data) {
      console.log("SORT", data);
    },
    resetSort(data) {
      console.log("RESET SORT", data);
    },
    getShiftRequestTypeImage(shift, key) {
      const shiftReqType = shift.shiftRequest.type;
      const shiftType = shift.type;

      const parseImage = (imageObj, key) => {
        // key === "sym" is fallback
        return (
          imageObj?.[key] || (key === "sym" ? imageObj?.image || null : null)
        );
      };

      if (shiftType === "ONCL") {
        return parseImage(this.baseShiftSpecialData.onCall, key);
      }

      if (shiftType === "SLPO") {
        return parseImage(this.baseShiftSpecialData.sleepover, key);
      }

      const shiftRequestTypeImage = {
        1: this.baseShiftSpecialData.notifications,
        2: this.baseShiftSpecialData.clientAssigned,
        3: this.baseShiftSpecialData.notifications,
        4: this.baseShiftSpecialData.bulkUpload,
        5: this.baseShiftSpecialData.bulkUpload,
        6: this.baseShiftSpecialData.tempAssigned,
        // 999: this.baseShiftSpecialData.onCall,
      };
      const imageObj = shiftRequestTypeImage[shiftReqType];
      return parseImage(imageObj, key);
    },
    confirmAction(msg) {
      return new Promise((resolve) => {
        const alert = {
          title: msg,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    onEmitGetData() {
      this.selectedShifts = [];
      this.$emit("get-data");
    },
    onMultipleSubmitted() {
      this.selectedShifts = [];
      this.selectedShiftObjs = [];
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  font-weight: 400;
}

.shift-type-td {
  color: #aeafb7;

  img {
    max-width: 27px;
    max-height: 27px;
    width: auto;

    &.onCall {
      $width: 40px;
      width: $width;
      max-width: $width;
      max-height: $width;
      margin-bottom: -5px;
      margin-left: -7px;
    }
  }
}

em {
  color: #333;
}

svg {
  margin-left: 0.5em;
  cursor: pointer;

  // *:not(:first-child) {
  //   margin-left: 0.5em;
  // }
}

.filter_dropdown {
  z-index: 2;
  position: absolute;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0 5px 0 rgba(black, 0.3);
  left: 0;
  background-color: white;
  padding: 1em;
}
.set-width {
  display: flex;
  width: 140px;
  text-align: center;
}

.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}
.fix-min-width {
  min-width: 200px;
}
.center-text {
  text-align: center;
  height: 150px;
  background: #f0f6f6;

  img {
    width: 50px;
  }
}

.button-wrap {
  display: flex;
  gap: 10px;
}

// Fixes table headers with sort buttons layout.
th,
.flex-header.column-label {
  font-size: 13px;
}

.flex-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .column-label {
    flex: 0 1;
  }

  .sort-buttons {
    flex: 1 0 auto;
    margin-left: 3px;
  }
}
</style>
<style lang="scss">
table {
  border: 1px solid #dde6eb;
  border-collapse: separate;
}

table th {
  position: relative;
  background-color: #f2f7fa;
  vertical-align: middle !important;
}

table td {
  color: #405168;
  vertical-align: middle !important;
  border-bottom: 1px solid #dde6eb;

  span.indicator:last-child {
    margin-left: auto;
  }

  .flexer {
    display: flex;
    align-items: center;

    span:first-child {
      margin-right: auto;
    }

    span {
      display: flex;
      align-items: center;
    }
  }
}

.client-select {
  .multiselect__tag {
    white-space: normal;
  }
}

.confirmed-status-text {
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 9px;
  width: 50px;
  display: block;
  text-align: center;
  user-select: none;
}
</style>
