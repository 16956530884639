<template lang="pug">
.control.is-grouped
  p.control
    label.label(for="start_time") {{ startLabel }}
    date-picker(
      :date="value.startTime",
      :mode="mode",
      :option="dateTimeOption",
      :disabled="isDisabledStartTime",
      @change="$emit('change-picker', $event)"
    )
    slot(name="start-time-errors")
  p.control(v-show="isEndDateTimeVisible")
    label.label(for="end_time") {{ endLabel }}
    date-picker(
      :date="value.endTime",
      :mode="rightMode || mode",
      :option="dateTimeOptionEnd",
      :disabled="isDisabledEndTime",
      @change="$emit('change-picker', $event)"
    )
    slot(name="end-time-errors")
</template>

<script>
import moment from "moment";
import DatePicker from "./Datepicker.vue";
import calendarOptions from "./../common/calendarOptions";

export default {
  name: "FromToDatepicker",
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        startTime: { time: "" },
        endTime: { time: "" },
      }),
      required: true,
    },
    format: {
      type: String,
      default: "DD/MM/YYYY HH:mm",
      required: false,
    },
    mode: {
      // day | hour
      type: String,
      default: "day",
      required: false,
    },
    isDisabledStartTime: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDisabledEndTime: {
      type: Boolean,
      default: false,
      required: false,
    },
    isEndDateTimeVisible: {
      type: Boolean,
      default: true,
      required: false,
    },
    startLabel: {
      default: "START TIME",
    },
    endLabel: {
      default: "END TIME",
    },
    rightFormat: {
      type: String,
      default: "",
      required: false,
    },
    rightMode: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      ...calendarOptions,
      dateTimeOptionEnd: {
        format: "",
      },
    };
  },
  computed: {
    rightFormatComp() {
      return this.rightFormat || this.format;
    },
  },
  watch: {
    "value.startTime.time": function (newValue, oldValue) {
      this.$emit("change-start-time", newValue, oldValue);
      if (newValue === "" || this.value.endTime.time === "") {
        return;
      }
      let currentStartTime = moment(newValue, this.format);
      let currentEndTime = moment(
        this.value.endTime.time,
        this.rightFormatComp
      );

      if (
        currentEndTime.add(1, "s").isBefore(currentStartTime) &&
        this.mode === "day"
      ) {
        let hours = moment(this.value.endTime.time, this.format).get("hour");
        let minutes = moment(this.value.endTime.time, this.rightFormatComp).get(
          "minute"
        );
        let end = moment(this.value.startTime.time, this.format).add(1, "d");
        this.value.endTime.time = end
          .hour(hours)
          .minutes(minutes)
          .format(this.rightFormatComp);
      }
    },
    "value.endTime.time": function (newValue, oldValue) {
      this.$emit("change-end-time", newValue, oldValue);
      if (newValue === "" || this.value.startTime.time === "") {
        return;
      }
      let currentStartTime = moment(this.value.startTime.time, this.format);
      let currentEndTime = moment(newValue, this.rightFormatComp);
      if (
        currentEndTime.add(1, "s").isBefore(currentStartTime) &&
        this.mode === "day"
      ) {
        this.value.startTime.time = newValue;
      }
    },
  },
  created() {
    this.dateTimeOption.format = this.format;
    this.dateTimeOptionEnd = { ...this.dateTimeOption };
    this.dateTimeOptionEnd.format = this.rightFormat || this.format;
  },
};
</script>

<style lang="scss" scoped></style>
