<template lang="pug">
.shift-schedule-invoice-errors
  template(v-if='!isInvalidErrorListing')
    span.center {{ `${successList.length} / ${totalNumberOfShifts}` }} shifts successfully added
    ul
      li.header
        div.id Shift id
        div.msg Message
      li.item.success(v-for='(item,index) in successList' :key='index')
        div.id {{item}}
        div.msg Successfully added
      li.item.error(v-for='error in errorList' :key='error.shift_id')
        div.id {{error.shift_id}}
        div.msg(:title='getMessage(error) ') {{ getMessage(error) }}
  .error-listing.center(v-if='isInvalidErrorListing')
    span {{errorGlobalMessage || "There was an error addings shift(s) to invoice"}}
  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(@click.prevent="callModalClose") CLOSE
  //-   button.button.is-generic-app-blue.is-caps-lock(
  //-     @click.prevent="onSubmit",
  //-   ) SUBMIT
</template>

<script>
export default {
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errorList: [],
      successList: [],
      errorMessages: {},
      errorGlobalMessage: null,
      isInvalidErrorListing: false,
    };
  },
  computed: {
    totalNumberOfShifts() {
      return this.errorList.length + this.successList.length;
    },
  },
  mounted() {
    this.displayList();
  },
  methods: {
    displayList() {
      this.errorGlobalMessage = this.modalProps.data.response.data?.message;
      this.successList =
        this.modalProps.data.response.data?.success_shifts || [];
      if (this.modalProps.data.response.data.errors) {
        this.errorList = this.modalProps.data.response.data.errors || [];
        this.errorMessages =
          this.modalProps.data.response.data.error_messages || {};
      } else {
        this.isInvalidErrorListing = true;
      }
    },
    getMessage(error) {
      return this.errorMessages[error.type];
    },
    callModalClose() {
      this.$emit("dispose-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.shift-schedule-invoice-errors {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px 0;
    padding: 0;
    overflow: auto;
    max-height: 70vh;

    li {
      // display: flex;
      min-height: 30px;
      height: 30px;
      align-items: center;
      padding: 0 10px;
      display: inline-flex;
      white-space: nowrap;
      min-width: 100%;

      &.item {
        &.error {
          background: #f5003169;
          color: black;
        }

        &.success {
          background: #00f51469;
          color: black;
        }
      }

      &.header,
      &.item {
        div {
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.id {
            min-width: 120px;
          }

          &.msg {
            width: 100%;
          }
        }
      }
    }
  }

  .center {
    text-align: center;
  }

  .buttons-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    button.button {
      margin: 17px 0.3em;
      min-width: 90px;
      height: 30px;
    }
  }
}
</style>
