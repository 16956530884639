<template lang="pug">
#security-journals-notes
  h1 {{ isClient ? "Client Note Types" : "Candidate Note Types" }}
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .loading-wrap(v-if="isLoading")
    .loading-wrapper
      img(src="../../../assets/images/comps/loader.svg")
  .table-wrap(ref="list", v-if="!isLoading")
    table.table(:style="!isLockedInputs ? 'border-bottom: none;' : ''")
      thead
        tr
          th(colspan="100") 
            .flex-wrap(style="display: flex; gap: 10px")
              .completed
                span To be edited:&nbsp;
                span(style="color: black") {{ toBeEditedCount }}
              .errors
                span Success:&nbsp;
                span(style="color: green") {{ successfullCount }}
              .total
                span Errors:&nbsp;
                span(style="color: darkred") {{ errorsCount }}
        tr
          th
            span Note type
          th
            span Hidden
          th
            span Status
      tbody(v-if="notes && notes.length > 0")
        tr(v-for="(note, index) in notes", :key="index")
          td
            input.input(
              :value="note.name",
              type="text",
              :disabled="isLockedInputs",
              :style="!note.id ? 'width: calc(100% - 22px)' : ''",
              @input="bindValue(index, 'name', $event.target.value)"
            )
            .remove-wrap(:style="!note.id ? 'display:inline;' : ''")
              span.fa.fa-times(
                v-if="!note.id",
                @click="onRemoveEmptyRow(index)"
              )
          td
            //- button.button.is-danger.is-caps-lock.is-tiny(
            //-   v-if="note.id"
            //-   @click="onDeleteNote(note)"
            //-   :disabled="isLockedInputs"
            //- )
            //-   | DELETE
            input(
              :true-value="false",
              :false-value="true",
              :disabled="isLockedInputs && note.id",
              v-if="note.id",
              type="checkbox",
              :checked="note.hidden",
              @change="bindValue(index, 'hidden', $event.target.checked)"
            )
          td
            span(v-if="note.error", style="color: darkred") {{ note.error }}
            span(v-if="note.success", style="color: green") {{ note.success }}
      tbody(v-if="notes.length === 0 && !isLoading")
        tr
          td
            | No data in table
      tfoot(v-if="!isLockedInputs")
        tr.add-row
          td
            button.button.is-generic-app-blue.is-caps-lock(
              type="button",
              :disabled="isSavingData",
              @click.prevent="onAddNew"
            )
              span Add new
          td
          td
  .buttons-centered(v-if="$can('view-note-types')")
    button.button.is-generic-app-blue.is-caps-lock(
      type="button",
      :disabled="isSavingData || !isLockedInputs",
      v-if="isLockedInputs && !isLoading",
      @click.prevent="isLockedInputs = false"
    )
      span Unlock

    button.button.is-generic-app-blue.is-caps-lock(
      type="button",
      :disabled="isSavingData || isLockedInputs",
      v-if="!isLockedInputs && notes.length",
      @click.prevent="isLockedInputs = true"
    )
      span Lock

    button.button.is-generic-app-blue.is-caps-lock(
      type="submit",
      :disabled="isSavingData || isLockedInputs || toBeEditedCount === 0",
      v-if="!isLockedInputs",
      @click.prevent="onDataSubmit"
    )
      img.btn-loader(
        src="../../../assets/images/comps/loader.svg",
        v-if="isSavingData"
      )
      span Save
</template>

<script>
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";
export default {
  props: {
    isClient: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      isSavingData: false,
      isLockedInputs: true,
      successfullCount: 0,
      errorsCount: 0,
      notes: [],
      oldNotes: [],
    };
  },
  computed: {
    toBeEditedCount() {
      let count = 0;
      for (let i = 0; i < this.notes.length; i++) {
        if (this.notes[i].id === undefined) {
          count++;
        } else {
          for (const key in this.notes[i]) {
            if (this.notes[i][key] !== this.oldNotes[i][key]) {
              count++;
              break;
            }
          }
        }
      }
      return count;
    },
  },
  mounted() {
    if (this.$can("view-note-types")) this.fetchNoteTypes();
  },
  methods: {
    ...mapActions({
      getNoteTypes: "getNoteTypes",
      postNoteType: "postNoteType",
      patchNoteType: "patchNoteType",
    }),
    async fetchNoteTypes() {
      this.isLoading = true;
      try {
        const params = {
          hidden: true,
          isClient: this.isClient,
        };
        const res = await this.getNoteTypes(params);
        console.log(res.data.data);
        this.notes = res.data.data;
        this.oldNotes = JSON.parse(JSON.stringify(this.notes));
        this.isLoading = false;
      } catch (error) {
        console.warn(error);
        this.isLoading = false;
      }
    },
    onAddNew() {
      this.notes.push({
        name: "",
        hidden: false,
      });

      this.$nextTick(() => {
        // Scroll to bottom
        const sh = this.$refs.list.scrollHeight;
        const ch = this.$refs.list.clientHeight;
        this.$refs.list.scrollTop = sh - ch;
      });
    },
    onRemoveEmptyRow(index) {
      this.notes.splice(index, 1);
    },
    onDataSubmit() {
      this.submitData();
    },
    async submitData() {
      let newOrEditedNoteTypes = this.getNewOrEditedNoteTypesArr();

      this.isSavingData = true;
      let successfullCountTemp = 0;
      let errorsCountTemp = 0;
      for (let i = 0; i < newOrEditedNoteTypes.length; i++) {
        let index = newOrEditedNoteTypes[i].index;
        delete newOrEditedNoteTypes[i].index;
        let res;
        try {
          let newNoteType;
          if (newOrEditedNoteTypes[i].id && this.$can("manage-security")) {
            res = await this.patchNoteType(newOrEditedNoteTypes[i]);
            newNoteType = res.data.data;
            newNoteType.success = "Successfully edited";
          } else if (
            !newOrEditedNoteTypes[i].id &&
            this.$can("manage-security")
          ) {
            res = await this.postNoteType(newOrEditedNoteTypes[i]);
            newNoteType = res.data.data;
            newNoteType.success = "Successfully added";
          } else {
            //IF USER HAS NO PERMISSION FOR POST/PATCH
            const oldNote = this.oldNotes.find(
              (noteObj) => noteObj.id === newOrEditedNoteTypes[i].id
            );
            newNoteType = {
              ...oldNote,
              error: "No permission to perform this action",
            };
          }
          this.notes.splice(index, 1, newNoteType);
          this.oldNotes.splice(index, 1, { ...newNoteType });
          successfullCountTemp++;
        } catch (err) {
          errorsCountTemp++;
          this.isSavingData = false;
          const errs = parseErrors(err);
          let newNoteType = { ...this.notes[index] };
          if (errs && errs.length > 0) {
            newNoteType.error = errs;
          } else if (err.response.data.message) {
            newNoteType.error = err.response.data.message;
          }
          this.notes.splice(index, 1, newNoteType);
        }
      }
      this.successfullCount = successfullCountTemp;
      this.errorsCount = errorsCountTemp;
      this.isSavingData = false;
    },
    getNewOrEditedNoteTypesArr() {
      let newArr = [];
      for (let i = 0; i < this.notes.length; i++) {
        //clear statuses
        delete this.notes[i].success;
        delete this.notes[i].error;
        let ntObj = {
          ...this.notes[i],
          isClient: this.isClient,
        };
        //index key is used to target errors
        ntObj.index = i;
        //new contact
        if (this.notes[i].id === undefined) {
          newArr.push(ntObj);
          //editing contact
        } else {
          for (const key in this.notes[i]) {
            if (this.notes[i][key] !== this.oldNotes[i][key]) {
              newArr.push(ntObj);
              break;
            }
          }
        }
      }
      return newArr;
    },
    bindValue(index, key, value) {
      this.notes[index][key] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
#security-journals-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  h1 {
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    width: 80%;
  }

  .table-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    width: 80%;
    max-height: 60vh;

    table {
      border-top: none;
      position: relative;
      margin-bottom: 0;
      .remove-wrap {
        .fa {
          margin-left: 5px;
          margin-top: 4px;
          cursor: pointer;
        }
      }
      tbody {
        tr {
          border: 1px solid #dde6eb;
        }
      }
      td {
        min-height: 100%;
      }
    }

    thead {
      tr:first-child {
        th {
          width: 20%;
          height: 36.2px;
          top: 0px;
        }
      }
      tr {
        th {
          top: 36px;
          height: 36.2px; // So it goes behind other row
        }
        th:not(:first-child) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td:not(:first-child) {
          text-align: center;
        }
      }
    }
    tfoot,
    tfoot th,
    tfoot td {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #dde6eb;
      border-bottom: 1px solid #dde6eb;
    }

    th {
      border-top: 1px solid #dde6eb;
      border-bottom: unset;
      position: sticky;
      z-index: 1;
    }
  }
  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
}
</style>
