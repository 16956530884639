import api from "../../api";

import * as types from "../mutation_types.js";

const state = {
  cards: [],
};

const getters = {
  cards: (state) => state.cards,
};

const actions = {
  fetchCards: ({ commit }) => {
    return api
    .fetchCards()
    .then((response) => {
        commit(types.FETCH_CARDS, response.data);
      })
      .catch((error) => console.error("error", error));
  },
};

const mutations = {
  [types.FETCH_CARDS] (state, { data }) {
    state.cards = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
