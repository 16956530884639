<template lang="pug">
router-view(
  :filtersGlobal="filtersGlobal",
  :actionArr="actionArr",
  :shift-filters-instance="shiftFiltersInstance",
  @update-filter-global="updateFilterGlobal",
  @tab-is-clicked="tabChanged"
)
</template>

<script>
import { ShiftScheduleFilters } from "@/TS/classes";
import { mapActions } from "vuex";

export default {
  name: "ShiftScheduleMain",
  data() {
    return {
      filtersGlobal: null,
      shiftFiltersInstance: new ShiftScheduleFilters(),
      actionArr: [],
    };
  },
  created() {
    this.setupFetchSectors();
  },
  methods: {
    ...mapActions({
      fetchSectors: "locationClientManagement/fetchSectors",
    }),
    setupFetchSectors() {
      // Fetch Sectors list, for ThreeDropFilter
      if (this.$can("view-sectors")) {
        this.fetchSectors({
          includes: "clients",
          forClient: 1,
        });
      }
    },
    updateFilterGlobal(paramsObj) {
      this.filtersGlobal = Object.assign({}, paramsObj);
    },
    tabChanged() {
      // Reset to first page on tab change
      this.$set(this.filtersGlobal, "page", 1);
    },
  },
};
</script>
