<template lang="pug">
.wrap
  // p {{ params }}
  upload-form(
    :params="params",
    ref="uploader",
    @upload-change="isUploadInProcess = $event"
  ) 
  .stripe.stripe--expiry.date-picker--override(v-show="hasExpiry")
    p Expiry Date:
    span.help.is-danger(
      v-if="errors.has('expiry_date')",
      v-text="errors.get('expiry_date')"
    )
    .container--datepicker
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" class="align--svg">
        <g :fill='getGlobalPrimaryColorHex'>
          <polygon points="10.383 3.089 8.886 1.593 4.25 6.234 5.764 7.713"/>
          <path d="M10.174549,0.309843137 L9.4074902,1.07707843 L10.9043137,2.57360784 L11.6713725,1.80635294 C12.0839608,1.393 12.0847059,0.723176471 11.6713725,0.309823529 C11.2581176,-0.103411765 10.5878039,-0.103137255 10.174549,0.309843137 Z"/>
          <polygon points="2.731 9.25 5.285 8.174 3.798 6.72"/>
          <polygon points="10.467 4.394 9.023 5.817 9.023 10.519 1.399 10.519 1.399 2.925 6.195 2.925 7.531 1.514 0 1.514 0 11.981 10.467 11.981"/>
        </g>
      </svg>
      date-picker(:date="dt.to", :option="dateTimeOption", v-show="hasExpiry") 
  footer.footer--above
    button.button.is-generic-app-teal.is-low(
      @click="uploadAll",
      :disabled="(dt.to.time === null && hasExpiry) || isUploadInProcess"
    ) UPLOAD ALL
    button.button.is-generic-app-teal.is-outlined.is-low(@click="cancelAll") CANCEL ALL
    button.button.is-generic-app-reject.is-low(@click="removeAll") REMOVE ALL
  footer
    button.button.is-generic-app-blue.is-outlined.is-low(
      @click="callModalClose"
    ) CLOSE
</template>
<script>
import _ from "underscore";
import moment from "moment";

import calendarOptions from "../../common/calendarOptions.js";

import { Evt } from "../../../lib/helpers/Evt.js";
import UploadForm from "../../Uploader/UploadForm.vue";

import { mapState } from "vuex";
import { Errors } from "../../../lib/helpers/Errors.js";
import { getFormattedTime } from "../../../lib/helpers/function.js";

export default {
  components: { UploadForm },
  props: ["modalProps"],
  data () {
    return {
      dateTimeOption: null,
      MultiShift: null,

      hasExpiry: null,
      expiryDate: null,
      dt: {
        to: { time: "" },
      },
      params: {
        docId: null,
        tempId: null,
      },
      errors: new Errors(),
      isUploadInProcess: false,
    };
  },
  computed: {
    ...mapState({
      tempDocuments: (state) => state.compliance.tempDocuments,
    }),
    /**
     * Expiry date format for datetime picker to set value from
     */
    formattedExpiryDate: function () {
      if (this.expiryDate) {
        let f = getFormattedTime(this.expiryDate, "DD/MM/YYYY", "YYYY-MM-DD");
        console.log("expiry date: ", this.expiryDate);
        console.log("this is f: ", f);
        return f;
      } else {
        return null;
      }
    },
    /**
     * Datepicker related computed values.
     */
    serverExpiryTime: function () {
      return moment(this.dt.to.time, "DD/MM/YYYY")
        .format("YYYY-MM-DD HH:mm:ss")
        .valueOf();
    },
  },
  watch: {
    "dt.to.time": function () {
      console.log("this.dt.to.time", this.dt.to.time);
      Evt.fire("set-expiry-date", this.serverExpiryTime);
    },
  },
  created () {
    this.setupDateTimePickerOpt();
  },
  mounted () {
    this.params.docId = this.modalProps.docId;
    this.params.tempId = this.modalProps.tempId;

    this.hasExpiry = this.modalProps.hasExpiry;
    this.expiryDate = this.modalProps.expiryDate;

    /**
     * Set expiry date field to an existing expiry date, if present
     */
    // -- FIX --
    // api can send expiryDate set, even when hasExpiry is false
    // So we set expiryDate manually to an empty string, otherwise this field gets sent to API
    // and 422 error occurs ('The expiry date is not a valid date.')
    if (this.hasExpiry && this.expiryDate != null) {
      this.dt.to.time = this.formattedExpiryDate;
    } else {
      this.dt.to.time = "";
    }

    /**
     * Refresh state here to reflect newly uploaded files.
     */
    Evt.listen("shouldRefreshFilesUploaded", (response) => {
      this.replaceTempDocument(response);
    });
  },
  beforeDestroy () {
    Evt.off("shouldRefreshFilesUploaded");
  },
  methods: {
    setupDateTimePickerOpt () {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "DD/MM/YYYY";
    },
    uploadAll () {
      this.$refs.uploader.uploadStart(this);
    },

    cancelAll () {
      this.$refs.uploader.cancelAll(this);
    },

    removeAll () {
      this.$refs.uploader.removeAll(this);
    },

    replaceTempDocument (documentObject) {
      let idx = _.findIndex(this.tempDocuments, (doc) => {
        return (
          doc.id === this.params.docId && doc.temp.id === this.params.tempId
        );
      });
      // dispatch an action to change state in store.compliance.tempDocuments!
      this.$store.dispatch("replaceTempDocument", {
        idx: idx,
        data: documentObject.data,
      });
    },

    lastPathSegment (path) {
      return path.split("/").pop();
    },

    callModalClose () {
      this.$emit("cancel-modal");
    },
  },
};
</script>
<style lang="scss">
.stripe.stripe--expiry.date-picker--override {
  .cov-datepicker {
    background-color: #e5ecf0;
    font-weight: bold;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    text-align: right;
  }
}
</style>
<style lang="scss" scoped>
.wrap {
  width: 100%;
}

.caption {
  padding: 1em;
  display: flex;
  justify-content: center;
}

.stripe.stripe--expiry {
  height: 40px;
  background-color: #e5ecf0;
  margin: 0 -1.3em;
  margin-top: 1em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #7c91ae;
  padding: 0 1.3em;

  .container--datepicker {
    position: relative;
    margin-left: auto;
  }

  .align--svg {
    pointer-events: none;
    position: absolute;
    top: 10px;
    right: 100px;
  }

  .help {
    font-size: 10px;
  }
}

footer {
  margin: 0 -1.3em;
  padding: 1.5em 0;
  display: flex;
  justify-content: center;

  button.button {
    margin: 0 0.5em;
    font-weight: bold;
  }

  &.footer--above {
    border-bottom: 1px solid #e5ecf0;
  }
}

table {
  width: 100%;
  margin-bottom: 0;
  padding: 1em 0;
  border-radius: 0;

  td:first-child {
    padding-left: 2em;
  }

  thead td {
    border: none;
  }
}
</style>
