<template lang="pug">
#rates-finalize
  .text Choose date range to activate rates for:
  .notice
    .text When selecting dates using the date picker:
    .text The start date you choose will be included in the date range. However, the end date you select will not be included in the range.
    .text This means that the date range you pick will cover all the dates starting from the chosen start date up to, but not including, the selected end date.
  .date-section
    .start-date
      uni-date-picker.is-5(
        v-model="dateValue.from",
        :prop-config="dateConfig",
        :isFormatToLocal="true",
        @input="errors.clear('valid_from')",
        placeholder="Start Date"
      )
      span.help.is-danger.time-errors(
        v-if="errors.has('valid_from')",
        v-html="errors.get('valid_from')",
        slot="valid_from"
      )
    .end-date
      uni-date-picker.is-5(
        :class="{ 'hide-text': dateValue.isIndefinite }",
        v-model="dateValue.to",
        :is-disabled="dateValue.isIndefinite",
        :prop-config="dateConfig",
        :isFormatToLocal="true",
        @input="errors.clear('valid_to')",
        placeholder="End Date"
      )
      //- REVIEW - added @change for indefinite check
      label.indef-ckbox
        input(type="checkbox", v-model="dateValue.isIndefinite")
        span Set to indefinite
      span.help.is-danger.time-errors(
        v-if="errors.has('valid_to')",
        v-html="errors.get('valid_to')",
        slot="valid_to"
      )
  .list(v-if="validateIds.length && !isProductionApp")
    .item(v-for="id in validateIds", :key="id")
      span.error Rate ID not valid: {{ id }}

  .action
    .top
      rates-gaps-report-download(
        :type="type",
        :client="client",
        :dateValue="dateValue",
        :canShowGapsReport="canShowGapsReport",
        :canShowCitReport="Boolean(Object.keys(this.validateIds).length)",
        @error-download="onErrorDownload"
      )
    .bottom
      button.button.is-generic-app-teal.is-caps-lock.is-tiny(
        :class="{ 'is-loading': isLoadingSaveChanges }",
        :disabled="!isValidFields || isLoadingSaveChanges",
        @click="onSaveChanges"
      ) activate rates
      button.button.is-caps-lock.is-tiny(@click="closeForm") Cancel
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import RatesGapsReportDownload from "./RatesGapsReportDownload.vue";
import { parseErrors } from "../../../../lib/helpers/function";
import { mapActions } from "vuex";
import { Errors } from "../../../../lib/helpers/Errors";
import moment from "moment";
export default {
  components: {
    flatPickr,
    RatesGapsReportDownload,
  },
  props: {
    client: {
      required: false,
    },
    type: {
      required: true,
    },
  },
  data() {
    return {
      dateValue: {
        from: null,
        to: null,
        isIndefinite: true,
      },
      dateConfig: { altFormat: "d/m/Y", allowInput: true, enableTime: false },
      validateIds: [],
      isLoadingSaveChanges: false,
      canShowGapsReport: false,
      errors: new Errors(),
      hardcodedInfTime: "01/01/2900 00:00",
    };
  },
  computed: {
    endDateParsed() {
      return this.dateValue.isIndefinite
        ? this.hardcodedInfTime
        : this.dateValue.to;
    },
    isValidFields() {
      return this.dateValue.from && this.endDateParsed;
    },
  },
  methods: {
    ...mapActions({
      postRatesFinalize: "postRatesFinalize",
      postGlobalRatesFinalize: "postGlobalRatesFinalize",
      getRatesValidate: "getRatesValidate",
      getRatesGaps: "getRatesGaps",
      getGlobalRatesValidate: "getGlobalRatesValidate",
      getGlobalRatesGaps: "getGlobalRatesGaps",
    }),
    async finalizeChanges() {
      const params = {
        cli_id: this.client?.id,
        valid_from: this.formatIsoDate(this.dateValue.from),
        valid_to: this.formatIsoDate(this.endDateParsed),
        type: this.type.type,
      };

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML"
          ? "postRatesFinalize"
          : "postGlobalRatesFinalize";
      try {
        const res = await this[getApiActionKey](params);
        this.$toasted.success(res.data.message).goAway(2500);
        this.$emit("rates-finalized");
      } catch (err) {
        console.log(err.message);
        if (err.response?.data?.errors) {
          this.errors.record(err.response.data.errors);
        }
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    formatIsoDate(input) {
      return moment(input, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD");
    },
    onErrorDownload(err) {
      this.errors.record(err.response.data.errors);
    },
    closeForm() {
      this.$emit("cancel-modal");
    },
    onSaveChanges() {
      this.isLoadingSaveChanges = true;
      const promiseOne = this.validateCheck();
      const promiseTwo = this.fetchGaps();

      Promise.all([promiseOne, promiseTwo])
        .then((res) => {
          console.warn("Apply rates [Needs true both]", res);
          if (res.every((oneRes) => oneRes !== false)) {
            // If not some false
            this.finalizeChanges();
          } else {
            const errMsg =
              "Please review the report for any errors while applying rates";
            this.$toasted.error(errMsg).goAway(2500);
          }
        })
        .catch((err) => {
          console.warn("Error", err.message);
        })
        .finally(() => {
          this.isLoadingSaveChanges = false;
        });
    },
    async validateCheck() {
      const params = {
        cli_id: this.client?.id,
        // valid_from: this.formatIsoDate(this.dateValue.from),
        // valid_to: this.formatIsoDate(this.endDateParsed),
        type: this.type.type,
      };
      const getApiActionKey =
        this.type.unique_code !== "GLBNRML"
          ? "getRatesValidate"
          : "getGlobalRatesValidate";
      try {
        const res = await this[getApiActionKey](params);
        this.validateIds = res.data.data;
        if (Object.keys(this.validateIds).length) {
          return false;
        }
      } catch (err) {
        console.warn("Error", err.message);
      }
      return true;
    },
    async fetchGaps() {
      const params = {
        cli_id: this.client?.id,
        valid_from: this.formatIsoDate(this.dateValue.from),
        valid_to: this.formatIsoDate(this.endDateParsed),
        type: this.type.type,
      };
      // REVIEW - added optional chaining because it was crashing without it
      // tbh not sure if this if is needed since it's covered by the optional chaining
      // in the params object
      if (this.client?.id) {
        params.cli_id = this.client.id;
      }

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML"
          ? "getRatesGaps"
          : "getGlobalRatesGaps";

      try {
        const res = await this[getApiActionKey](params);
        this.canShowGapsReport = Boolean((res.data.data || []).length);
      } catch (err) {
        console.warn("Error", err.message);
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
#rates-finalize {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;

  .date-section {
    display: flex;
    justify-content: center;
    gap: 10px;

    .start-date,
    .end-date {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .hide-text {
        ::v-deep input {
          text-indent: 10000px;
        }
      }
    }

    .uni-date-picker {
      width: 100%;
      height: auto;
    }
  }

  .notice {
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: column;
    gap: 3px;

    .text {
      margin: 0;
    }
  }

  .text {
    margin: 20px 0;
    text-align: left;
  }

  .list {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: rgb(255, 255, 156);

    .item {
      .error {
        color: crimson;
      }
    }
  }

  .action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 20px;

    .top {
      display: flex;

      ::v-deep {
        .report {
          gap: 10px;
        }

        button {
          width: 200px;
        }
      }
    }

    .bottom {
      display: flex;
      gap: 10px;

      button {
        width: 100px;
      }
    }
  }
}

.indef-ckbox {
  display: flex;
  // gap: 10px;
  height: 12px;
  align-items: center;
  justify-content: flex-start;

  input {
    width: auto !important;
  }
}
</style>
<style lang="scss">
#rates-finalize {
  .mx-datepicker,
  input {
    width: 100%;
  }
}
</style>
