import _ from "underscore";
export default {
  /**
   * Greet user with a msg.
   */
  getVuexMessage: (state) => state.message,

  /**
   * Wll get all Clients available
   */
  getAllClients: (state) => state.clients || [],
  /**
   * Wll get all Clients with shift activity tags info
   */
  getAllClientsActivityTags(state) {
    return state.clientsShiftActivityTags
  },
  /**
   * Get Costs bases on Client
   */
  getCosts: (state) => state.costs,
  /**
   * Formatted list (custom) for vue-multiselect, categories->subcategories groups.
   */
  getCategories: (state) => {
    const options = [];
    state.categories.map((cat) => {
      options.push({
        category: cat.name,
        subs: cat.subcategories.map((subcat) => {
          return {
            name: subcat.name,
            id: subcat.id,
          };
        }),
      });
    });
    state.categories = options;
    return state.categories;
  },
  /**
   * Formatted list (custom) for vue-multiselect, categories -> subcategories groups.
   * This is practically variation of the above TODO refactor later
   */
  getCategoriesWithSubcategories: (state, getters) => {
    const options = [];
    getters.getAllCategories.map((cat) => {
      options.push({
        id: cat.id,
        category: cat.name,
        subs: cat.subcategories.map((subcat) => {
          return {
            catParentId: cat.id,
            name: subcat.name,
            id: subcat.id,
          };
        }),
      });
    });
    return options;
  },
  /*
   * Formatted list for vue-multiselect, clients -> locations
   *
   */
  getClientsWithLocations: (state, getters) => {
    const clientsFiltered = getters.getAllClients.reduce((acc, client) => {
      // see why it throws error, gets called when clients do not include locations
      // NOTE TO MYSELF this getter shoudn't trigger in Documents view, but it does, hence the error!
      if (client.locations) {
        acc.push({
          client: client.name,
          subs: client.locations.map((location) => {
            return {
              name: location.name,
              id: location.id,
            };
          }),
        });
      }
      return acc;
    }, []);
    return clientsFiltered;
  },

  getAllCategories: (state) => state.allCategories,
  getAllRoles: (state) => state.allRoles,
  getMinRoles: (state) => state.minRoles,
  getAllSpecialities: (state) => state.allSpecialities,
  getUserProfile: (state) => state.userProfile,
  getTempProfile: (state) => state.tempProfile,

  /**
   * Will fetch dependant lists for locations based on a selected Client
   * SELF REMINDER you have ability to pass arguments to getter, by returning a function callback
   */
  fillRelatedFields:
    (state) =>
      (selected = "") => {
        state.costs = [];

        state.clients.map(({ id, locations }) => {
          if (selected.id === id) {
            state.costs = locations.map((c) => {
              return {
                name: c.name,
                id: c.id,
                categories: c.categories,
              };
            });
          }
        });
      },
  /**
   * Will fetch dependant lists for Categories based on a selected Location
   */
  fillCategories:
    (state) =>
      (selected = "") => {
        state.categories = _.findWhere(state.costs, {
          id: selected.id,
        }).categories;
      },

  /**
   * Gives back all temps from state
   */
  getTemps: (state) => state.temps,
  /**
   * Pull out categories from specific Shift, by shift id.
   */
  getShiftCategories: (state, getters) => (shiftId) => {
    // returns undefined if not found
    return _.findWhere(getters.getShifts, { id: shiftId }).shiftRequest
      .categories;
  },
  /**
   * Gives back a pagination object for a set of returned temps
   * TODO make pagination be shared across all entities
   */
  getPagination: (state) => state.pagination,

  getAgencyWorkers: (state) => state.agency_workers,

  getUsers: (state) => state.users,

  getColumnNameLocl: (state) => (key) => {
    if (key) {
      if (
        Array.isArray(state.columnNamesLocl.api) &&
        state.columnNamesLocl.api.length
      ) {
        const foundObj = state.columnNamesLocl.api.find(
          (cnl) => cnl.key === key
        );
        if (foundObj) {
          return foundObj.value;
        }
      }
      return state.columnNamesLocl.default[key];
    }
    console.error("Invalid key", key);

    return null;
  },
};
