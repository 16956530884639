<template lang="pug">
.awaiting-dialog
  .inputs
    .field.p-right10
      label.label Start time
      p.control
        uni-date-picker(
          v-model="dt.from.time",
          :isFormatToLocal="true",
          @change="onChangeStartDate"
        )
        span.help.is-danger(
          v-if="errors.has('start_time')",
          v-html="errors.get('start_time')"
        ) 
    .field.p-left10
      label.label End time
      p.control
        uni-date-picker(
          v-model="dt.to.time",
          :isDisabled="dt.to.time === null",
          :isFormatToLocal="true",
          placeholder="ESCORT?"
        )
        span.help.is-danger(
          v-if="errors.has('end_time')",
          v-html="errors.get('end_time')"
        )
  label.label Ward
  p.control
    multi-select(
      :options="locations",
      :allow-empty="false",
      deselect-label="",
      track-by="id",
      label="name",
      placeholder="SELECT WARD",
      :value="selectedLocation",
      @select="onChangeSelLocationProp"
    )
      span(slot="noResult") Nothing found.
  template
    p.control
      label.label Subcategories
      multi-select(
        :options="optionsSubcategories",
        :multiple="true",
        :close-on-select="false",
        track-by="id",
        label="name",
        placeholder="PICK A SUBCATEGORY",
        v-model="selectedCategories",
        :max="modalProps.shift.type === 'ONCL' ? 1 : 999",
        @select="errors.clear('subcategories')"
      )
        //- v-model="selectedCategory",
        //- group-label="category",
        //- group-values="subs",
        //- :group-select="true",
      span.help.is-danger(
        v-if="errors.has('subcategories')",
        v-html="errors.get('subcategories')"
      )
  .inputs(v-if="isOnCall")
    .field.p-right10
      label.label On Call Start time
      p.control
        uni-date-picker(
          v-model="oncallDate.from.time",
          :isFormatToLocal="true"
        )
        span.help.is-danger(
          v-if="errors.has('start_time')",
          v-html="errors.get('start_time')"
        ) 
    .field.p-left10
      label.label On Call End time
      p.control
        uni-date-picker(
          v-model="oncallDate.to.time",
          :isDisabled="oncallDate.to.time === null",
          :isFormatToLocal="true"
        )
        span.help.is-danger(
          v-if="errors.has('end_time')",
          v-html="errors.get('end_time')"
        )
  template(v-if="isSleepover")
    .inputs
      .field.p-right10
        label.label Sleepover Start time
        p.control
          uni-date-picker(
            v-model="sleepoverDate.from.time",
            :isFormatToLocal="true"
          )
          span.help.is-danger(
            v-if="errors.has('start_time')",
            v-html="errors.get('start_time')"
          ) 

      .field.p-left10
        label.label Sleepover End time
        p.control
          uni-date-picker(
            v-model="sleepoverDate.to.time",
            :isDisabled="sleepoverDate.to.time === null",
            :isFormatToLocal="true"
          )
          span.help.is-danger(
            v-if="errors.has('end_time')",
            v-html="errors.get('end_time')"
          )
    .inputs.is-awaken
      input#awaken(type="checkbox", v-model="isAwaken")
      label.label(for="awaken") Was the sleeping time interrupted?
    .inputs(v-if="isAwaken")
      .field.p-right10
        label.label Awaken Start time
        p.control
          uni-date-picker(
            v-model="awakenDate.from.time",
            :isFormatToLocal="true"
          )
          span.help.is-danger(
            v-if="errors.has('start_time')",
            v-html="errors.get('start_time')"
          ) 
      .field.p-left10
        label.label Awaken End time
        p.control
          uni-date-picker(
            v-model="awakenDate.to.time",
            :isDisabled="awakenDate.to.time === null",
            :isFormatToLocal="true"
          )
          span.help.is-danger(
            v-if="errors.has('end_time')",
            v-html="errors.get('end_time')"
          )
  label.label Breaks
  p.control
    .has-suffix
      input.input(v-model="breakMinutes", :disabled="isOnCall || isSleepover")
      span.is-suffix in minutes
  .buttons-centered(v-if="!isWarningMessageVisible")
    button.button.is-generic-app-blue.is-caps-lock(
      @click.prevent="onButtonSubmitTimes",
      :disabled="disableButton"
    ) SUBMIT
    button.button.is-outlined.is-caps-lock.cancel-btn(
      @click.prevent="callModalClose"
    ) CANCEL
  .delete-section(v-if="canShowWarningMessage && isWarningMessageVisible")
    span.action-message
      span(v-if="errorMessage") {{ errorMessage }}
    span.action-buttons
      a.button.is-small.is-confirm(@click="areYouSureSixMonths") Accept
      a.button.is-small.is-deny(@click="callModalClose") Refuse
</template>
<script>
import MultiSelect from "vue-multiselect";
import calendarOptions from "../common/calendarOptions.js";
import moment from "moment";
import {
  parseErrors,
  getFormattedTime,
  parseDateObjWithTimeZone,
} from "../../lib/helpers/function.js";

import { Errors } from "../../lib/helpers/Errors.js";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  props: ["modalProps"],
  components: {
    MultiSelect,
  },
  data() {
    return {
      arrayWarrningsMessages: [],
      count: 0,
      errorMessage: "",
      shiftId: null,
      flatpickrConfigShared: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        time_24hr: true,
      },
      dt: {
        from: { time: "" },
        to: { time: "" },
      },
      oncallDate: {
        from: { time: "" },
        to: { time: "" },
      },
      sleepoverDate: {
        from: { time: "" },
        to: { time: "" },
      },
      isAwaken: false,
      awakenDate: {
        from: { time: "" },
        to: { time: "" },
      },
      selectedLocation: this.modalProps.location,
      // selectedCategory: this.modalProps.subcategory || null,
      selectedCategories: this.modalProps.shiftRequest.subcategories || [],
      temp: this.modalProps.temp,
      breakMinutes: this.modalProps.breakMinutes,
      errors: new Errors(),
      url: this.modalProps.url,
      disableButton: false,
      isRecalc: false,
      optionsSubcategories: [],
      originalCategoryVals:
        [...this.modalProps.shiftRequest.subcategories] || [],
      isWarningMessageVisible: false,
      warningMessageText: {},
      conformationShift6Months: false,
      numberOfKeysInData: 0,
      isConfirmedSixMonthRule: false,
    };
  },
  computed: {
    ...mapGetters({
      getShift: "getShift",
      getActiveTab: "getActiveTab",
    }),
    canShowWarningMessage() {
      return this.initialStatus.shift_sign_off_warning;
    },
    locations() {
      return this.modalProps.location?.client?.locations;
    },
    startDateTimeParsed() {
      return parseDateObjWithTimeZone(this.modalProps.startDateTime);
    },
    endDateTimeParsed() {
      return parseDateObjWithTimeZone(this.modalProps.endDateTime);
    },
    modalStartTime() {
      return moment(this.startDateTimeParsed, "YYYY-MM-DD HH:mm:ss")
        .format("DD/MM/YYYY HH:mm")
        .valueOf();
    },
    modalEndTime() {
      return getFormattedTime(
        this.endDateTimeParsed,
        "DD/MM/YYYY HH:mm",
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    serverStartTime() {
      if (this.dt.from.time) {
        return moment(this.dt.from.time, "DD/MM/YYYY HH:mm")
          .format("YYYY-MM-DD HH:mm:ss")
          .valueOf();
      }
      return null;
    },
    serverEndTime() {
      if (this.dt.to.time) {
        return moment(this.dt.to.time, "DD/MM/YYYY HH:mm")
          .format("YYYY-MM-DD HH:mm:ss")
          .valueOf();
      }
      return null;
    },
    isOnCall() {
      return this.modalProps.shift.type === "ONCL";
    },
    isSleepover() {
      return this.modalProps.shift.type === "SLPO";
    },
  },
  mounted() {
    this.dt.from = { time: this.modalStartTime };
    this.dt.to = { time: this.modalEndTime };
    this.oncallDate.from = { time: this.getShiftPartTime("ONCL", "from") };
    this.oncallDate.to = { time: this.getShiftPartTime("ONCL", "to") };
    this.sleepoverDate.from = { time: this.getShiftPartTime("SLPO", "from") };
    this.sleepoverDate.to = { time: this.getShiftPartTime("SLPO", "to") };
    this.awakenDate.from = { time: this.getShiftPartTime("AWKE", "from") };
    this.awakenDate.to = { time: this.getShiftPartTime("AWKE", "to") };

    if (this.awakenDate.from.time && this.awakenDate.to.time)
      this.isAwaken = true;

    this.setupShiftPartsPicker();

    this.shiftId = this.modalProps.shiftId;
    this.isRecalc = Boolean(this.modalProps.isRecalc);
    this.fetchViableSubcategories();
    this.fetchMultipleShiftsDay();
  },
  methods: {
    ...mapActions([
      "clientSignOff",
      "clientRecalculateShift",
      "getViableSubcategories",
      "postMultipleShiftsDay",
    ]),
    onChangeStartDate(evt) {
      this.fetchMultipleShiftsDay();
    },
    setupShiftPartsPicker() {
      const fromTime = this.modalProps?.shift?.shiftParts?.[0]?.from?.date;
      const toTime = this.modalProps?.shift?.shiftParts?.[0]?.to?.date;
      if (fromTime) {
        const formatted = moment(fromTime, "YYYY-MM-DD HH:mm:ss")
          .format("DD/MM/YYYY HH:mm")
          .valueOf();
        this.oncallDate.from = { time: formatted };
      }
      if (toTime) {
        const formatted = moment(toTime, "YYYY-MM-DD HH:mm:ss")
          .format("DD/MM/YYYY HH:mm")
          .valueOf();
        this.oncallDate.to = { time: formatted };
      }
    },
    async fetchViableSubcategories() {
      const params = {
        id: this.modalProps.shiftId,
        location_id: this.selectedLocation.id,
      };
      try {
        const res = await this.getViableSubcategories(params);
        this.optionsSubcategories = res.data.data || [];
      } catch (err) {
        console.warn(err.message);
      }
    },
    async onChangeSelLocationProp(evt) {
      // Filter only available subcategories
      this.selectedLocation = evt; // used because v-model delays commit
      await this.fetchViableSubcategories();
      const availableSubsId = this.optionsSubcategories.map(
        (subCat) => subCat.id
      );
      this.selectedCategories = this.selectedCategories.filter((selCat) => {
        return availableSubsId.includes(selCat.id);
      });
    },
    async fetchMultipleShiftsDay() {
      if (!this.canShowWarningMessage) {
        // Agency denied
        return;
      }

      const params = {
        shift_id: this.shiftId,
        start_time: this.serverStartTime,
      };
      try {
        const res = await this.postMultipleShiftsDay(params);
        const responseData = res.data.data;
        if (Array.isArray(responseData) || !responseData) {
          this.warningMessageText = {};
        } else {
          this.warningMessageText = res.data.data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    getShiftPartTime(code, key) {
      const partDateObj = this.modalProps.shift.shiftParts?.find(
        (partObj) => partObj.type === code
      );
      if (this.modalProps.shift.shiftParts?.length > 0 && partDateObj) {
        const parsedDateObj = parseDateObjWithTimeZone(partDateObj[key]);

        return moment(parsedDateObj, "YYYY-MM-DD HH:mm:ss")
          .format("DD/MM/YYYY HH:mm")
          .valueOf();
      } else {
        return "";
      }
    },
    onButtonSubmitTimes() {
      this.disableButton = true;
      if (
        !this.isRecalc &&
        this.canShowWarningMessage &&
        Object.keys(this.warningMessageText).length
      ) {
        // Agency specific warning message
        this.arrayWarrningsMessages = Object.entries(this.warningMessageText);
        this.errorMessage = this.arrayWarrningsMessages[0][1][0];
        this.isWarningMessageVisible = true;
      } else {
        this.onSaveLogicFunc();
      }
    },
    onSaveLogicFunc() {
      if (this.isRecalc) {
        this.isWarningMessageVisible = false;

        this.recalcShift();
      } else {
        this.isWarningMessageVisible = false;

        this.clientSignOffFunc();
      }
    },
    areYouSureSixMonths() {
      this.count++;
      if (this.count != this.arrayWarrningsMessages.length) {
        this.errorMessage = this.arrayWarrningsMessages[this.count][1][0];
      } else {
        this.onSaveLogicFunc();
        this.count = 0;
      }
    },
    generateParamsForSave() {
      const payload = {
        start_time: this.serverStartTime,
        end_time: this.serverEndTime,
        location_id: this.selectedLocation?.id,
        break_minutes: this.breakMinutes,
        shift_parts: [],
      };

      if (this.isOnCall) {
        const fromTime =
          this.oncallDate.from.time &&
          moment(this.oncallDate.from.time, "DD/MM/YYYY HH:mm")
            .format("YYYY-MM-DD HH:mm:ss")
            .valueOf();

        const toTime =
          this.oncallDate.to.time &&
          moment(this.oncallDate.to.time, "DD/MM/YYYY HH:mm")
            .format("YYYY-MM-DD HH:mm:ss")
            .valueOf();

        if (fromTime && toTime) {
          payload.shift_parts = [
            {
              from: fromTime,
              to: toTime,
              type: "ONCL",
            },
          ];
        }
      } else if (this.isSleepover) {
        const fromTime =
          this.sleepoverDate.from.time &&
          moment(this.sleepoverDate.from.time, "DD/MM/YYYY HH:mm")
            .format("YYYY-MM-DD HH:mm:ss")
            .valueOf();

        const toTime =
          this.sleepoverDate.to.time &&
          moment(this.sleepoverDate.to.time, "DD/MM/YYYY HH:mm")
            .format("YYYY-MM-DD HH:mm:ss")
            .valueOf();

        if (fromTime && toTime) {
          payload.shift_parts = [
            {
              from: fromTime,
              to: toTime,
              type: "SLPO",
            },
          ];
        }
        // dont include awke params if its turned off
        if (this.isAwaken) {
          const fromAwakenTime =
            this.awakenDate.from.time &&
            moment(this.awakenDate.from.time, "DD/MM/YYYY HH:mm")
              .format("YYYY-MM-DD HH:mm:ss")
              .valueOf();

          const toAwakenTime =
            this.awakenDate.to.time &&
            moment(this.awakenDate.to.time, "DD/MM/YYYY HH:mm")
              .format("YYYY-MM-DD HH:mm:ss")
              .valueOf();

          if (fromAwakenTime && toAwakenTime) {
            payload.shift_parts.push({
              from: fromAwakenTime,
              to: toAwakenTime,
              type: "AWKE",
            });
          }
        }
      }

      const originalCatIds = this.originalCategoryVals.map((cat) => cat.id);
      const newCatIds = this.selectedCategories.map((cat) => cat.id);
      const isPendingSubcatChange =
        originalCatIds.length !== newCatIds.length ||
        !originalCatIds.every((catId) => newCatIds.includes(catId));

      if (!this.isRecalc || (this.isRecalc && isPendingSubcatChange)) {
        // subcategory_id: this.selectedCategory && this.selectedCategory.id,
        payload.subcategories = this.selectedCategories.map((cat) => {
          return { id: cat.id };
        }); // Multiple
      }

      return payload;
    },
    async clientSignOffFunc() {
      /**
       * API client sign off shift
       * POST
       * shiftId - id for a shift to be changed
       * params - object that contains new start_time and end_time values and break minutes
       */
      try {
        const params = {
          url: this.url,
          data: this.generateParamsForSave(),
          tab: this.getActiveTab.name,
        };
        await this.clientSignOff(params);

        this.$toasted.success("Shift signed off").goAway(2000);

        /**
         * change like this, or propagate an event?
         * update state for the affected shift object ASK QUESTION
         */
        this.callModalClose();
      } catch (error) {
        console.log(error);
        const responseObject = error.response.data;
        const errorObject = {
          message: parseErrors(error),
          time: 2500,
        };

        if (responseObject.errors) {
          this.errors.record(responseObject.errors);
        } else {
          errorObject.message = responseObject.message;
          errorObject.time = 10000;
        }
        // for cases when toaster is used, do like this!
        this.disableButton = false;
        this.$toasted.error(errorObject.message).goAway(errorObject.time);
      }
    },
    async recalcShift() {
      /**
       * API client recalculate shift
       * POST
       * shiftId - id for a shift to be changed
       * params - object that contains new start_time and end_time values and break minutes
       */
      try {
        const params = {
          url: this.url,
          data: this.generateParamsForSave(),
          tab: this.getActiveTab.name,
        };
        await this.clientRecalculateShift(params);
        this.$toasted.success("Shift recalculated").goAway(2000);

        /**
         * change like this, or propagate an event?
         * update state for the affected shift object ASK QUESTION
         */
        this.callModalClose();
      } catch (error) {
        const responseObject = error.response.data;
        const errorObject = {
          message: parseErrors(error),
          time: 2500,
        };

        if (responseObject.errors) {
          this.errors.record(responseObject.errors);
        } else {
          errorObject.message = responseObject.message;
          errorObject.time = 10000;
        }
        // for cases when toaster is used, do like this!
        this.disableButton = false;
        this.$toasted.error(errorObject.message).goAway(errorObject.time);
      }
    },
    callModalClose() {
      this.$emit("dispose-modal");
      this.isConfirmedSixMonthRule = false;
    },
  },
};
</script>
<style scoped lang="scss">
.inputs {
  display: flex;

  &.is-awaken {
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0;

    input {
      cursor: pointer;
      margin-top: 6px;
      margin-right: 10px;
    }

    label {
      cursor: pointer;
    }
  }
}

.has-suffix {
  display: flex;
  align-items: center;
}

input + span.is-suffix {
  font-size: smaller;
  flex: 1 0 auto;
  margin-left: 1em;
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

.p-left10 {
  padding-left: 10px;
}

.p-right10 {
  padding-right: 10px;
}

.delete-section {
  margin: 10px 0;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(#405168, 0.15);
  border-bottom: 1px solid rgba(#405168, 0.15);
  align-items: center;
  justify-content: center;
  user-select: none;

  .action-message {
    display: flex;
    gap: 5px;
    margin-right: 1em;
    color: #405168;
    text-align: center;
    font-weight: 600;

    .user-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
    }
  }

  .action-buttons {
    a.button {
      border: none;
      margin: 0 0.3em;
    }
  }

  .is-confirm,
  .is-deny {
    font-weight: 900;
    font-size: 1.1em;
  }

  .is-confirm {
    color: #ff3b3b;
  }

  .is-deny {
    color: #2669b0;
  }
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

.fade-enter-active {
  transition: all 20s ease;
}

.fade-leave-active {
  transition: all 0s ease;
}
</style>

<style lang="scss">
.awaiting-dialog {
  .cov-vue-date {
    width: 100%;
  }

  .cov-datepicker {
    width: 100%;
  }
}
</style>
