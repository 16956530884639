import { render, staticRenderFns } from "./CategoryTreeNode.vue?vue&type=template&id=0157670e&scoped=true&lang=pug"
import script from "./CategoryTreeNode.vue?vue&type=script&lang=js"
export * from "./CategoryTreeNode.vue?vue&type=script&lang=js"
import style0 from "./CategoryTreeNode.vue?vue&type=style&index=0&id=0157670e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_2c597f7d109fe0052e634bf327ce7ff6/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0157670e",
  null
  
)

export default component.exports