<template lang='pug'>
#rates-increments-node(
  :class="[{ active: isSelectedCategoryActive }, { hidden: increment.hidden }]"
) 
  .node-click(@click="nodeClicked")
    .name {{ increment.name }}
    .action
      .delete(title="Delete increment", @click.stop="deleteIncrement")
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import { parseErrors } from '../../../../lib/helpers/function';
import { mapActions } from 'vuex';
export default {
  props: {
    selectedIncrement: [Object, null],
    nodeSelectEvt: Function,
    category: {
      type: Object,
      required: true,
    },
    increment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSelectedCategoryActive () {
      return this.selectedIncrement && this.selectedIncrement.id === this.increment.id;
    },
  },
  methods: {
    ...mapActions({
      deleteRatesIncrements: 'deleteRatesIncrements',
    }),
    nodeClicked () {
      const params = {
        increment: this.increment,
        command: 'select',
      };
      this.nodeSelectEvt(params);
    },
    async deleteIncrement (defParams = {}, msg = '') {
      const params = {
        ...defParams,
        catId: this.category.id,
        incId: this.increment.id,
      };

      const name = this.increment.name;
      if (!msg) {
        msg = `Are you sure you want to delete increment: "${name}"?`;
      }

      try {
        await this.confirmDelete(msg);
        let response = await this.deleteRatesIncrements(params);
        const resMsg = response.data.message;
        this.$toasted.info(resMsg).goAway(2000);
        this.$emit('change');
        // console.log('Deleted node', this.increment.name);
      } catch (err) {
        console.log(err.message);
        let errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
        const confirmMsg = 'This increment is currently assigned to workers. If removed, those temps might have their increment or subcategory removed. Please confirm removal of this increment.';
        if (err.response && err.response.data && err.response.data.message === confirmMsg) {
          // Recursive call [Should not invoke more]
          const confirmMsg = `Please confirm that you want to delete increment: "${name}"?`;
          this.deleteIncrement({ confirm: true }, confirmMsg);
        }
      }
    },
    confirmDelete (msg = 'Are you sure you want to delete increment?') {
      return new Promise((resolve,) => {
        let alert = {
          title: msg,
          message: '',
          type: 'warning',
          useConfirmBtn: true,
          customConfirmBtnText: 'Confirm',
          customConfirmBtnClass: 'button is-danger',
          customCloseBtnText: 'Cancel',
          customCloseBtnClass: 'button is-outlined',
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#rates-increments-node {
  $gray-element: rgba(10, 10, 10, 0.2);
  $red-element: rgba(238, 96, 124, 0.733);

  .node-click {
    display: flex;
    border-bottom: 1px solid rgba(192, 192, 192, 0.363);
    padding: 5px 10px;
    // border-left: unset;
    // border-right: unset;
  }

  &.active {
    background: darken($color: #e0e0e0ce, $amount: 10);
  }

  &.hidden {
    background: rgba(219, 219, 219, 0.335);
    opacity: 0.4;
    color: rgb(39, 39, 39);
  }

  &:hover {
    background: darken($color: #e0e0e0ce, $amount: 5);
  }

  .action {
    margin-left: auto;
  }

  .delete {
    background: $gray-element;

    &:hover {
      background: $red-element;
    }
  }
}
</style>