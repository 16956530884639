<template lang="pug">
#journal-entry-details
  .content-wrap
    label 
      span Subject:
      .input {{ modalProps.subject }}
    label
      span Sent from:
      .input {{ modalProps.created_by_email }}
    label
      span Note:
      .textarea(v-html="modalProps.note")
  .button-line
    button.button.is-generic-app-blue.is-caps-lock(
      @click.prevent="$emit('dispose-modal')"
    )
      | Close
</template>

<script>
export default {
  props: {
    modalProps: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#journal-entry-details {
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 35vh;
  padding: 1em 0 2em 0;

  .content-wrap {
    display: flex;
    flex-direction: column;
    .textarea {
      height: 500px;
      overflow: auto;
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
  h2 {
    text-align: center;
  }
}
</style>
