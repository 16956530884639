<template lang='pug'>
.bulk-download-csv
  .action
    button.button.is-generic-app-teal(@click="onDownloadUploadReport")
      span Export failed shifts to CSV
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    pageSub: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      csvList: [],
    };
  },
  methods: {
    ...mapActions({
      getCsvDataTimesheet: 'getCsvDataTimesheet',
      getCsvDataOpenShifts: 'getCsvDataOpenShifts',
    }),
    async onDownloadUploadReport () {
      let invokeFunc = null;
      if (this.pageSub === 'timesheet') {
        invokeFunc = 'getCsvDataTimesheet';
      } else if (this.pageSub === 'open-shifts') {
        invokeFunc = 'getCsvDataOpenShifts';
      } else {
        console.error('Invalid func', this.pageSub);
        return;
      }

      const params = {};
      try {
        const res = await this[invokeFunc](params);
        this.csvList = res.data.data;
        this.downloadCsvFile();
      } catch (err) {
        console.log(err.message);
        const defaultErrMsg = 'Unexpected error while trying to download CSV file.';
        const errorMsg = err.response && err.response.data && err.response.data.message || defaultErrMsg;
        this.$toasted.error(errorMsg).goAway(3500);
      }
    },
    downloadCsvFile () {
      const csvContent = this.csvList.map(e => e.join(",")).join("\n");
      let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      let encodedUri = URL.createObjectURL(blob);
      // let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Failed_shifts.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link); // Required for FF
    },
  },
};
</script>

<style lang="scss" scoped>
</style>