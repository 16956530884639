<template lang="pug">
.check-temp-doc
  label.label
    input(type="checkbox", v-model="isShowAll", @click="onChangeVal")
    span View all files
  Promised(:promise="filesPromise", :pending-delay="200")
    template(#combined="{ isPending, isDelayElapsed, data, error }")
      .data-handler
        file-list-management(
          :files="transformedDocumentData",
          :pendingDeleteFiles="pendingDeleteFiles",
          :canDeleteFiles="canDeleteFiles",
          :grouped-key-list="['doc_id']",
          @open-file="onOpenFile",
          @download-file="onDownloadFile",
          @delete-file="onDeleteFile"
        )
          template(#table-col)
            th(style="width: 140px") UPLOAD DATE
            th(style="width: 140px") EXPIRY DATE
          template(#table-body-cell="{ item }")
            td(data-cell="date") {{ getFormattedTime(item.createdAt) }}
            td(data-cell="expiry-date") {{ getFormattedTime(item.documentExpiry) }}
        .loading-wrap
          .loading-wrapper(v-if="isPending && isDelayElapsed")
            img.client-loading(src="@/assets/images/comps/loader.svg")
  transition(name="fade")
    .delete-section(v-if="deleteDialogActive")
      span.action-message Are you sure you want to delete this file?
      span.action-buttons
        a.button.is-small.is-confirm(@click.once="deleteFile") YES
        a.button.is-small.is-deny(
          @click="deleteDialogActive = false; fileToBeDeleted = null"
        ) NO
  footer
    button.button.is-generic-app-blue.is-outlined.is-low(
      @click="callModalClose"
    ) CANCEL
</template>
<script>
import FileListManagement from "@/components/shared/FileListManagement.vue";
import { Evt } from "../../../lib/helpers/Evt.js";
import { mapActions } from "vuex";
import _ from "underscore";
import { getFormattedTime } from "../../../lib/helpers/function.js";

export default {
  components: {
    FileListManagement,
  },
  props: ["modalProps"],
  data() {
    return {
      isShowAll: false,
      deleteDialogActive: false,
      query: {},
      deleteRequest: {},
      pendingDeleteFiles: [],
      /**
       * This will hold last response from server, when
       * all files deleted!
       */
      afterFileDeleteResponse: null,

      fileToBeDeleted: null,
      canCheckWatcher: false,
      filesPromise: null,
      documentData: null,
    };
  },
  computed: {
    canDeleteFiles() {
      return this.$can("delete-document-files");
    },
    transformedDocumentData() {
      return (
        this.documentData?.map((file) => {
          if (file.isLatest) {
            file.__rowClasses = ["highlight"];
          }
          return file;
        }) || []
      );
    },
  },
  mounted() {
    this.query = this.modalProps;
    this.$nextTick(() => {
      // Because of the watcher checking
      this.canCheckWatcher = true;
    });

    this.filesPromise = this.fetchDocs(false);
  },
  methods: {
    ...mapActions(["getFileBlob", "deleteFiles", "fetchOneTempDocuments"]),
    async onChangeVal(evt) {
      const isChecked = evt.target.checked;
      this.filesPromise = this.fetchDocs(isChecked);
    },
    async fetchDocs(isChecked) {
      const params = {
        temp_id: this.query.temp,
        docTypeId: this.query.document,
      };
      if (isChecked) {
        params.include = "files,viewOldFiles";
      } else {
        params.include = "files";
      }

      try {
        const res = await this.fetchOneTempDocuments(params);
        this.documentData = res.data.data?.files || [];
      } catch (err) {
        console.warn(err.message);
      }
    },
    confirmDelete(fileId) {
      // show/activate file deletion dialog
      this.deleteDialogActive = true;
      // keep id of a file to be deleted for a reference
      this.fileToBeDeleted = fileId;

      /**
       * Form the request for when actual file deletion takes place
       */
      this.deleteRequest = {
        documentId: this.query.document,
        tempId: this.query.temp,
        files: [{ id: fileId }],
        include: "files",
      };
      /**
       * Console log the request for file deletion
       */
      console.error("DELETE REQUEST: ", this.deleteRequest);
    },
    deleteFile() {
      console.log("about to delete a file...", this.fileToBeDeleted);

      let idx = _.findIndex(this.documentData, { id: this.fileToBeDeleted });

      this.pendingDeleteFiles = [this.fileToBeDeleted];
      this.deleteFiles(this.deleteRequest).then((response) => {
        /**
         * Remove file from queue
         */
        this.afterFileDeleteResponse = response.data;
        this.documentData.splice(idx, 1);

        this.deleteDialogActive = false;
        this.pendingDeleteFiles = [];
        if (this.documentData?.length === 0) {
          this.$emit("refresh-data");
        }
      });
    },
    callModalClose() {
      this.$emit("cancel-modal");
    },
    async onOpenFile(evt, fileObj) {
      // const fileName = file.fileName;
      try {
        const res = await this.getFileBlob(evt);
        const file = res.data;
        this.fileLink = URL.createObjectURL(file);
        window.open(this.fileLink);
      } catch (err) {
        console.warn(err.message);
      }
    },
    async onDownloadFile(evt, fileObj) {
      // const fileName = file.fileName;
      try {
        const res = await this.getFileBlob(evt);
        const file = res.data;
        const url = URL.createObjectURL(file);

        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileObj.fileName;
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.warn(err.message);
      }
    },
    onDeleteFile(fileId) {
      this.confirmDelete(fileId);
    },
    getFormattedTime(createdAt) {
      if (createdAt) {
        return getFormattedTime(createdAt, "DD/MM/YYYY HH:mm");
      }
      return "-";
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    documentData(filesNow, filesOld) {
      if (this.canCheckWatcher) {
        if (!filesNow.length) {
          // Tell parent we are done with files, so it can decide weather or not
          // to remove the affected row from table.
          // Pass in the id of the affected document.
          Evt.fire(
            "all-files-deleted",
            this.query,
            this.afterFileDeleteResponse
          );
          this.callModalClose();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.data-handler {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .file-list-management {
    max-height: 400px;
    overflow: auto;
  }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    min-height: 30px;
    height: 30px;
  }
}

.spinner-graphics {
  text-align: center;
  background: #f0f6f6;
  width: 50px;
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

.check-temp-doc {
  width: 100%;
}

.caption {
  padding: 1em;
  display: flex;
  justify-content: center;
}

.is-confirm,
.is-deny {
  font-weight: 900;
  font-size: 1.1em;
}

.is-confirm {
  color: #ff3b3b;
}

.is-deny {
  color: #2669b0;
}

div.delete-section {
  display: flex;
  border-top: 1px solid rgba(#405168, 0.15);
  border-bottom: 1px solid rgba(#405168, 0.15);
  align-items: center;
  justify-content: center;

  .action-message {
    margin-right: 1em;
    color: #405168;
  }

  .action-buttons {
    a.button {
      border: none;
      margin: 0 0.3em;
    }
  }
}

footer {
  padding: 1.5em 0;
  display: flex;
  justify-content: center;
}

table {
  width: 100%;
  border: 0;
  margin-bottom: 0;
  padding: 1em 0;
  border-radius: 0;

  td {
    a {
      color: #2669b0;
    }
  }

  td.deleting {
    a {
      color: red;
    }
  }

  thead td {
    color: #7c91ae;
    border: none;
    font-weight: 900;
    font-size: smaller;
  }
}
</style>

<style lang="scss">
.check-temp-doc {
  table {
    tr {
      &.highlight {
        background: #ffff2557;
      }
    }
  }
}
</style>
