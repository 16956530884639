<template>
  <div class="recoverypass">
    <header>
      <img :src="getGlobalImageLogoApi" alt />
      <!-- <img src="../../assets/images/cpltabs.png" alt=""> -->
      <p>Type in your new password.</p>
    </header>
    <hr />
    <form
      autocomplete="off"
      @submit.prevent="submit"
      @keydown="errors.clear($event.target.name)"
    >
      <section class="section--inputs">
        <p class="control">
          <input
            type="password"
            name="password"
            class="input is-medium"
            v-model="form.password"
            placeholder="Password"
          />
          <span class="validation-new-password"
            ><PasswordPolicy
              :passwordCharacters="form.password"
              @password-valid="handlePasswordIsValid"
            ></PasswordPolicy
          ></span>
          <span
            class="help is-danger"
            v-if="errors.has('password')"
            v-html="errors.get('password')"
          ></span>
        </p>

        <p class="control">
          <input
            type="password"
            name="password_confirmation"
            class="input is-medium"
            v-model="form.password_confirmation"
            placeholder="Password again"
          />
          <span
            class="help is-danger"
            v-if="errors.has('password_confirmation')"
            v-html="errors.get('password_confirmation')"
          ></span>
        </p>
        <p
          :class="{
            showMessagePasswordConfirmation: isShowMessagePasswordConfirmation,
            hideMessagePasswordConfirmation: isHideMessagePasswordConfirmation,
          }"
        >
          {{ passwordConformationMessage }}
        </p>
        <button
          type="submit"
          class="button is-generic-app-blue"
          :disabled="disableConfirm"
        >
          RESET PASSWORD
        </button>
      </section>
    </form>
  </div>
</template>
<script>
import auth from "../../lib/auth.js";
import { Errors } from "../../lib/helpers/Errors.js";
import PasswordPolicy from "../../components/forms/PasswordPolicyValidation.vue";

export default {
  name: "password-recovery",
  components: {
    PasswordPolicy,
  },
  data() {
    return {
      passwordConformationMessage: `The "RESET PASSWORD" button will become available once the password criteria is met and the identical password is entered in both fields.`,
      isShowMessagePasswordConfirmation: false,
      isHideMessagePasswordConfirmation: true,
      form: {
        email: null, // obtained from url
        token: null, // obtained from url
        password: null, // form
        password_confirmation: null, // form
        password_is_valid: false,
      },
      errors: new Errors(),
    };
  },
  computed: {
    disableConfirm() {
      if (!this.form.password) {
        this.isShowMessagePasswordConfirmation = false;
        return false;
      } else {
        this.isShowMessagePasswordConfirmation = true;
        if (
          !(
            this.form.password_is_valid &&
            this.form.password_confirmation === this.form.password
          )
        ) {
          return true;
        } else {
          this.isShowMessagePasswordConfirmation = false;
          return false;
        }
      }
    },
  },
  mounted() {
    this.form.token = this.$route.params.hash;
    this.form.email = this.$route.query.email;
  },
  methods: {
    submit() {
      auth
        .resetPassword(this, this.form)
        .then(() => {
          this.$access("any");
          this.$router.push({ name: "signin" });
        })
        .catch(() => {
          console.log(this.errors.all());
          this.errors.all().forEach((error) => {
            this.$toasted.error(error).goAway(2500);
          });
        });
    },
    handlePasswordIsValid(data) {
      this.form.password_is_valid = data.isPasswordValid;
    },
  },
};
</script>
<style lang="scss" scoped>
.hideMessagePasswordConfirmation {
  color: red;
  display: none;
}
.showMessagePasswordConfirmation {
  color: red;
  display: block;
  text-align: center;
}
</style>
