<script>
import JournalTable from "../../Journals/JournalTable.vue";
export default {
  components: {
    JournalTable,
  },
  props: [
    "journalList",
    "subjectTypes",
    "clients",
    "tempId",
    "clientId",
    "journalPagination",
    "isLoadingJournals",
  ],
};
</script>
<template lang="pug">
.journal-table
  .spinner-wrap(v-if="isLoadingJournals")
    img(src="../../../assets/images/comps/loader.svg")
  journal-table(
    v-else,
    :journalList="journalList",
    :journalPagination="journalPagination",
    :subjectTypes="subjectTypes",
    :clients="clients",
    :temp-id="tempId",
    :client-id="clientId",
    key="system",
    @edit-row="$emit('edit-row', $event)",
    @file-check="$emit('file-check', $event)",
    @show-html-body="$emit('show-html-body', $event)",
    @clicked-journal-page="$emit('clicked-journal-page-system-logs', $event)"
  )
</template>
<style lang="scss">
.journal-table {
  width: 100%;
  padding: 0 0 20px 0;
}
</style>
