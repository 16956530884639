<template lang="pug">
.report-preview-table
  table.table
    thead
      tr
        th(v-for="key in keys") {{ headers[key] }}
    tbody
      tr(v-for="row in rows")
        td(v-for="key in keys", v-if="hasAllKeys(row, keys)") {{ row[key] }}
        td(v-if="!hasAllKeys(row, keys)", v-for="item in row") {{ item }}
</template>

<script>
export default {
  data() {
    return {};
  },

  props: ["table"],

  computed: {
    headers() {
      return this.table["table_header"];
    },

    rows() {
      return this.table["rows"];
    },

    keys() {
      return this.table["keys"];
    },
  },

  methods: {
    hasAllKeys(object, keys) {
      const len = keys.length;
      for (let i = 0; i < len; i++) {
        if (!Object.prototype.hasOwnProperty.call(object, keys[i])) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-preview-table {
  display: flex;
  overflow: auto;

  .panel-block {
    margin: 0;
    padding: 0;
  }

  .modal-content {
    width: 80%;
    margin: 0 auto;
    padding: 0px;

    .table {
      margin-top: 20px;
      width: 100%;
    }
  }
}
</style>
