<template lang="pug">
#rates-categories
  .top
    p.control(v-if="!isLoadingSector && !isOnlyOneSector")
      label.label Sector
      rates-sector-select(
        :sectors="sectors",
        :selected-sector="rateSectorSelected",
        @select="selectSector",
        @remove="removeSector"
      )
      //- multi-select.sector-select(
        :options="sectors",
        :value="rateSectorSelected",
        placeholder="Select sector",
        track-by="id",
        label="name",
        select-label="",
        deselect-label="",
        @select="selectSector",
        @remove="removeSector"
      //- )
  .bottom
    .loader(v-if="isLoadingCats")
    template(v-if="!isLoadingCats")
      .tree-placeholder
        input.input.filter-cats(
          :value="filterCategoriesStr",
          type="text",
          placeholder="Filter subcategories",
          v-if="filteredCategories && filteredCategories.length",
          @input="changeCategoryFilter"
        )
        .row
          button.button.is-generic-app-teal.is-tiny.is-caps-lock(
            @click="onAddCategory"
          )
            span Add new category
        category-tree-list(
          :categories="filteredCategories",
          :selected-category="selectedFormData.category",
          :node-select-evt="setSubCategorySelected",
          @edit-category="onEditCategory",
          @change="refreshResetDataTree"
        )
      .form-placeholder(v-if="isManagingCategory")
        transition(name="slide-right", mode="out-in")
          .placeholder-loader(v-if="firstTimeLoad")
            category-form(
              :key="selectedFormData && selectedFormData.category && selectedFormData.category.name",
              :sector="rateSectorSelected",
              :form-data="selectedFormData",
              v-if="selectedFormData && selectedFormData.command",
              @change="refreshResetDataTree",
              @cancel="onCancelFormView"
            )
      .form-placeholder(v-else-if="!isManagingCategory")
        transition(name="slide-right", mode="out-in")
          .placeholer-loader(v-if="firstTimeLoad")
            sub-category-form(
              :key="selectedFormData && selectedFormData.category && selectedFormData.category.name",
              :sector="rateSectorSelected",
              :form-data="selectedFormData",
              v-if="selectedFormData && selectedFormData.command",
              @change="refreshResetDataTree",
              @cancel="onCancelFormView"
            )
</template>

<script>
import _ from "underscore";
import CategoryTreeList from "./Category/CategoryTreeList.vue";
import SubCategoryForm from "./Category/SubCategoryForm.vue";
import CategoryForm from "./Category/CategoryForm.vue";
import RatesSectorSelect from "./RatesSectorSelect.vue";
import { mapActions, mapState } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";
export default {
  name: "ratesManageCategories",
  components: {
    CategoryTreeList,
    SubCategoryForm,
    CategoryForm,
    RatesSectorSelect,
  },
  data() {
    return {
      filterCategoriesStr: "",
      sectors: [],
      categories: [],
      selectedFormData: {},
      isLoadingCats: false,
      isLoadingSector: true,
      firstTimeLoad: false,
      isManagingCategory: false,
    };
  },
  computed: {
    ...mapState({
      rateSectorSelected: (state) => state.rateSectorSelected,
      // sectors: 'locationClientManagement/getFlatSectors',
      // categories: 'getAllCategories',
    }),
    isOnlyOneSector() {
      return Array.isArray(this.sectors) && this.sectors.length === 1;
    },
    filteredCategories() {
      const filterVal = this.filterCategoriesStr.toLowerCase();

      const recFilter = (arr = [], includeParentNode = true) => {
        // Filter tree with nodes that match
        return arr.reduce((acc, category) => {
          if (includeParentNode) {
            if (category?.name?.toLowerCase().includes(filterVal)) {
              acc.push(category);
            }
          } else if (category.subcategories?.length) {
            const subArr = recFilter(category.subcategories);
            const newCat = { ...category, subcategories: subArr };
            acc.push(newCat);
          }
          return acc;
        }, []);
      };

      if (filterVal) {
        return recFilter(this.categories, false);
      }
      return this.categories;
    },
  },
  mounted() {
    // Categories are fetched later
    this.fetchRatesSectors();
  },
  methods: {
    ...mapActions({
      getRatesSectors: "getRatesSectors",
      getRatesCategories: "getRatesCategories",
      ratePickSector: "ratePickSector",
    }),
    refreshResetDataTree() {
      this.selectedFormData = {};
      this.fetchRatesCategories();
    },
    async fetchRatesSectors() {
      const params = {};
      try {
        const res = await this.getRatesSectors(params);
        this.sectors = res.data.data;
        if (Array.isArray(this.sectors) && this.sectors.length === 1) {
          // Auto select if only
          await this.ratePickSector(this.sectors[0]);
          await this.fetchRatesCategories();
        }
        this.isLoadingSector = false;
      } catch (err) {
        console.log(err.message);
        this.isLoadingSector = false;
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    async fetchRatesCategories() {
      if (!(Array.isArray(this.categories) && this.categories.length)) {
        // If only changing sectors [Reload tree]
        this.isLoadingCats = true;
      }
      const params = {
        sct_id: this.rateSectorSelected?.id,
      };
      try {
        const res = await this.getRatesCategories(params);
        this.categories = res.data.data;
        this.isLoadingCats = false;
      } catch (err) {
        console.log(err.message);
        this.isLoadingCats = false;
      }
    },
    selectSector(evt) {
      this.ratePickSector(evt);
      this.fetchRatesCategories();
    },
    removeSector() {
      this.ratePickSector(null);
      this.categories = [];
    },
    setFirstTimeLoad() {
      if (!this.firstTimeLoad) {
        this.$nextTick(() => {
          this.firstTimeLoad = true;
        });
      }
    },
    setSubCategorySelected(params) {
      this.isManagingCategory = false;
      this.selectedFormData = params;
      this.setFirstTimeLoad();
    },
    onCancelFormView() {
      this.selectedFormData = {};
      this.firstTimeLoad = false;
      this.isManagingCategory = false; // Reset's the view
    },
    onEditCategory(category) {
      const params = {
        category,
        command: "edit",
      };
      this.selectedFormData = params;
      this.isManagingCategory = true;
      this.setFirstTimeLoad();
    },
    onAddCategory() {
      const params = {
        command: "add",
      };
      this.selectedFormData = params;
      this.isManagingCategory = true;
      this.setFirstTimeLoad();
    },
    changeCategoryFilter: _.debounce(function (evt) {
      const val = evt.target.value;
      this.filterCategoriesStr = val;
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
#rates-categories {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: center;

    .sector-select {
      width: auto;
      min-width: 300px;
    }
  }

  .bottom {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;

    .tree-placeholder {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .filter-cats {
        min-height: 32px;
      }
    }

    .loader {
      width: 30px;
      height: 30px;
      max-height: 30px;
      max-width: 30px;
    }

    > *:not(.loader) {
      flex: 0 0 40%;
      min-width: 350px;
      height: 100%;
      // max-height: calc(100vh - 400px); // Fixed
    }

    > *:first-child {
      margin-right: 20px;
    }

    > *:last-child {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .top,
    .bottom {
      overflow: auto;
      justify-content: flex-start;
    }
  }

  .slide-right-enter-active {
    transition: all 0.3s ease;
  }

  .slide-right-leave-active {
    transition: all 0.4s ease;
  }

  .slide-right-enter,
  .slide-right-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }
}
</style>
