<script>
import MultiSelect from "vue-multiselect";
export default {
  components: {
    MultiSelect,
  },
  props: {
    tableData: {
      required: true,
    },
    selectedExpenseType: {
      required: true,
    },
    dataIsLoading: Boolean,
    availableHeaders: {
      required: true,
    },
    availableRows: {
      required: true,
    },
  },
  data() {
    return {
      fixedChangedDate: "3000-01-01 00:00:00",
    };
  },
  computed: {
    getColumnsMeta() {
      return this.selectedExpenseType?.rate_info.columns_meta || {};
    },
    getRowMeta() {
      return this.selectedExpenseType?.rate_info.rows_meta || {};
    },
    hasColumnHeader() {
      return (
        this.availableHeaders.includes("urv_range2from") ||
        this.availableHeaders.includes("urv_range2to")
      );
    },
    hasRowHeader() {
      return (
        this.availableRows.includes("urv_range1from") ||
        this.availableRows.includes("urv_range1to")
      );
    },
    hasRowLimit() {
      return (
        this.availableRows.includes("row_limit") &&
        this.userPaymentsRatesRow.length === 0
      );
    },
    tableHeadings() {
      const cols = this.userPaymentsRatesRow?.[0]?.columns;
      if (!cols) return [];
      return cols;
    },
    userPaymentsRatesRow() {
      return this.tableData?.rate_values || [];
    },
    isNoColumns() {
      return this.tableHeadings?.length === 0;
    },
    isNoRows() {
      return this.userPaymentsRatesRow?.length === 0;
    },
    getVariantProps() {
      return this.selectedExpenseType?.rate_info.column?.urv_variant || {};
    },
    eSubInfoText() {
      return `When entering the from-to span, please note that the "to 10" implies "up to 10, not including 10"`;
    },
  },
  methods: {
    isTablePending(tableData) {
      return tableData.upr_from === this.fixedChangedDate;
    },
    addNewColumn(index, heading) {
      this.$emit("add-column", index, heading);
    },
    addNewRow(index, row) {
      this.$emit("add-row", index, row);
    },
    onRemoveColumn(index) {
      this.$emit("remove-column", index);
    },
    onRemoveRow(index) {
      this.$emit("remove-row", index);
    },
    onInputFormElement() {
      this.$emit("form-changed");
    },
    onBlurColumnRange(evt, index) {
      const val = evt.target.value;
      const key = evt.target.getAttribute("data-id");
      const parsedVal = parseInt(val, 10);
      this.$emit("column-range-changed", {
        index,
        key,
        val: parsedVal,
      });
    },
    onBlurRowFormElement(evt, row, index) {
      const val = evt.target.value;
      const key = evt.target.getAttribute("data-id");
      const parsedVal = parseInt(val, 10);
      if (isNaN(parsedVal)) {
        this.$set(row, key, null);
      } else {
        this.$set(row, key, parsedVal);
      }

      if (this.selectedExpenseType?.code === "ESUB") {
        if (key === "urv_range1from") {
          const from = parseInt(row.urv_range1from);
          if (from < 24) {
            // Revert variant to normal
            for (const cell of row.columns) {
              this.$set(cell, "urv_variant", "NRML");
            }
          }
        }
      }
    },
    isInputValValidFloat(inputVal, evt) {
      // const regex = new RegExp(/^[1-9]\d{0,2}(\.\d{3})*(,\d+)?$/u);
      // const parsedVal = parseFloat(inputVal);
      // const test = regex.test(parsedVal);
      // return test;
      return true;
    },
    onKeyDownCell(evt, cell) {
      if (!this.isInputValValidFloat(evt.target.value, evt)) {
        evt.preventDefault();
      }
    },
    onChangedCell(evt, cell) {
      const val = parseFloat(evt.target.value, 10) || 0;
      // console.warn(cell, evt);
      this.$set(cell, "urv_value", val);
      this.onInputFormElement();
    },
    onSelectCell(evt, cell) {
      this.$set(cell, "urv_variant", evt.urv_variant);
      this.onInputFormElement();
    },
    getCellVariant(variantCode) {
      return (
        this.getVariantProps?.dropdown.find(
          (item) => item.urv_variant === variantCode
        ) || null
      );
    },
    canViewToRange(item, index, key, availableArr, iterArr) {
      if (availableArr.includes(key)) {
        if (index === iterArr.length - 1) {
          return item[key];
        }
        return true;
      }
    },
    canDisplayVariantCustom(cell, row) {
      if (this.selectedExpenseType?.code === "ESUB") {
        return row.urv_range1from >= 24;
      }
      return true;
    },
  },
};
</script>

<template lang="pug">
table.table.is-striped.rates-expenses-table(
  v-if="selectedExpenseType",
  :class="{ 'is-pending': isTablePending(tableData), sticky: selectedExpenseType.code !== 'EMBC' }"
)
  thead(v-if="hasColumnHeader || hasRowHeader || hasRowLimit") 
    tr.column-meta(v-if="getColumnsMeta.label")
      th(v-if="getRowMeta.label")
      th(colspan="100", v-if="getColumnsMeta.label")
        .flex-headers-row
          span {{ getColumnsMeta.label }}
          template(v-if="isNoColumns && userPaymentsRatesRow.length")
            .action-col
              .circles(title="Add new column", @click="addNewColumn(0)")
                img(src="@/assets/images/comps/right.svg")
    tr.header-row
      th.row-head.is-narrow(v-if="getRowMeta.label")
        .flex-headers-row
          span {{ getRowMeta.label }}
          template(v-if="isNoRows")
            .action-col
              .circles(title="Add new row", @click="addNewRow(0)")
                img(src="@/assets/images/comps/down.svg")

          span.info(
            v-tooltip="{ content: eSubInfoText, classes: 'status-tooltip' }",
            v-if="selectedExpenseType && selectedExpenseType.code !== 'EMBC'",
            style="margin-right: 5px"
          )
            span.fa.fa-info.popup

      th(v-for="(heading, index) in tableHeadings")
        //- .flex-headers(v-if="getColumnsMeta.label")
        //-   | {{ getColumnsMeta.label }}
        .flex-headers-row
          template(v-if="hasColumnHeader")
            .group(v-if="availableHeaders.includes('urv_range2from')")
              span From
              input.input(
                data-id="urv_range2from",
                type="number",
                min="0",
                :value="heading.urv_range2from",
                @input="onInputFormElement",
                @blur="onBlurColumnRange($event, index)"
              )
            .group(
              v-if="canViewToRange(heading, index, 'urv_range2to', availableHeaders, tableHeadings)"
            )
              span To
              input.input(
                data-id="urv_range2to",
                type="number",
                min="0",
                :value="heading.urv_range2to",
                @input="onInputFormElement",
                @blur="onBlurColumnRange($event, index)"
              )
            .action-col
              .remove-wrap(title="Remove column", v-if="heading")
                span.fa.fa-times(@click="onRemoveColumn(index)")
              .circles(
                title="Add new column to the right",
                @click="addNewColumn(index, heading)"
              ) 
                img(src="@/assets/images/comps/right.svg")

  tbody
    tr(v-if="dataIsLoading")
      td.center-text(colspan="100")
        img(src="@/assets/images/comps/loader.svg")
    tr(
      v-if="!dataIsLoading",
      v-for="(row, index) in userPaymentsRatesRow",
      :key="index"
    )
      td.theading.is-narrow(v-if="getRowMeta.label") 
        .flex-headers(v-if="getRowMeta.label")
          //- | {{ getRowMeta.label }}
        .flex-headers-row
          template(v-if="hasRowHeader")
            .group(v-if="availableRows.includes('urv_range1from')")
              span From
              input.input(
                data-id="urv_range1from",
                type="number",
                min="0",
                :value="row.urv_range1from",
                @input="onInputFormElement",
                @blur="onBlurRowFormElement($event, row, index)"
              )
            template(
              v-if="canViewToRange(row, index, 'urv_range1to', availableRows, userPaymentsRatesRow)"
            )
              .group
                span To
                input.input(
                  data-id="urv_range1to",
                  type="number",
                  min="0",
                  :value="row.urv_range1to",
                  @input="onInputFormElement",
                  @blur="onBlurRowFormElement($event, row, index)"
                )
            .action-col
              .remove-wrap(title="Remove row")
                span.fa.fa-times(@click="onRemoveRow(index)")
              .circles(
                title="Add new row below",
                @click="addNewRow(index, row)"
              ) 
                img(src="@/assets/images/comps/down.svg")

          template(v-else)
            span {{ getRowMeta.label }}
      //- dynamic cells
      td(v-for="(cell, cellIndex) in row.columns", :key="cellIndex")
        .cell-group
          .ms-group(v-if="availableHeaders.includes('urv_variant')")
            template(v-if="canDisplayVariantCustom(cell, row)")
              span {{ getVariantProps.label }}
              multi-select(
                data-id="urv_variant",
                :options="getVariantProps.dropdown || []",
                select-label="",
                deselect-label="",
                track-by="urv_variant",
                label="label",
                :allow-empty="false",
                :multiple="false",
                :placeholder="getVariantProps.label",
                :title="getVariantProps.label",
                :value="getCellVariant(cell.urv_variant)",
                @select="onSelectCell($event, cell)"
              )
          input.input(
            type="text",
            :value="cell.urv_value",
            @keydown="onKeyDownCell($event, cell)",
            @input="onChangedCell($event, cell)"
          )
      td(v-if="!row.columns")
        span No data
</template>

<style lang="scss" scoped>
.rates-expenses-table {
  .input {
    width: 80px;
    height: 35px;
  }

  .circles {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: max-content;
    height: 29px;
    width: 29px;
    // background-color: rgb(239, 239, 239);
    transition: all 0.3s ease;
    padding: 2px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    // .circle {
    //   width: 3px;
    //   height: 3px;
    //   border-radius: 50%;
    //   background-color: rgba(0, 0, 0, 0.548);
    //   margin: 2px;
    // }

    &:hover {
      // background-color: silver;

      img {
        filter: brightness(2);
      }
    }
  }

  &.table {
    th {
      position: sticky;
      top: 0;
    }

    .header-row {
      th:not(.row-head):last-child {
        width: 100%;
      }

      .row-head {
        border-right: 1px solid silver;
      }
    }

    td {
      padding: 0.56em 0.65em;
    }

    tbody {
      tr {
        .theading {
          border-right: 1px solid silver;
        }

        td:not(.theading) {
          vertical-align: bottom !important;

          .cell-group {
            display: flex;
            gap: 10px;
            align-items: flex-end;
            // justify-content: flex-end;

            .ms-group {
              width: 200px;
              display: flex;
              flex-direction: column;

              .multiselect {
                width: 100%;
                min-height: 35px;
                height: 35px;
              }
            }
          }
        }
      }
    }
  }

  &.is-pending {
    tbody {
      tr {
        td:not(.theading) {
          background-color: #ffff2557;
        }
      }
    }
  }

  .flex-headers {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .flex-headers-row {
    display: flex;
    align-items: center;
    gap: 10px;

    .group {
      display: flex;
      flex-direction: column;
    }

    .action-col {
      display: flex;
      flex-direction: column;
      gap: 9px;
    }
  }

  // .info {
  //   margin-bottom: -20px;
  // }

  .remove-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;

    .fa {
      // margin-left: 5px;
      // margin-top: 4px;
      font-weight: 600;
      font-size: 1em;
      cursor: pointer;

      &:hover {
        color: rgb(219, 48, 48);
      }
    }
  }
}
</style>

<style lang="scss">
.rates-expenses-table {
  tbody {
    tr {
      td:not(.theading) {
        .cell-group {
          .multiselect__single {
            margin-bottom: 0;
          }

          .multiselect__tags {
            min-height: 35px;
          }
        }
      }
    }
  }
}
</style>
