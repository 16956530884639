<template lang="pug">
.edit-increments-table
  p.control
    .field.table-wrap
      table.table
        thead
          tr
            th.center EFFECTIVE DATE
            th.center INCREMENT POINT
            th.center ACTION
        tbody
          tr(v-if="gPermissions.canEditTempIncrement")
            td(style="width: 200px")
              date-picker(:date="dateObj", :option="dateTimeOption")
            td(style="width: 220px")
              multi-select(
                placeholder="Increment Point",
                :options="filterIncrementsPoints",
                track-by="id",
                label="name",
                :value="currentForm.incrementPoint",
                @select="setIncrementPoint"
              )
            td.editincremets-center
              button.button.is-generic-app-blue.add-action(
                title="Add increment",
                :disabled="!isValidIncRow",
                @click="onAddIncrement"
              )
                span.fa.fa-plus
          tr(
            :class="{ deleted: increment.deletedAt }",
            v-for="increment in incrementsListTable"
          )
            td.editincremets-center {{ increment.start_date }}
            td.editincremets-center {{ getIncName(increment.increment_id) }}
            td.editincremets-center
              button.button.remove-action(
                title="Delete increment",
                :style="{ visibility: increment.deletedAt ? 'hidden' : 'unset' }",
                v-if="gPermissions.canDeleteTempIncrement",
                @click="onRemoveIncrement(increment)"
              )
                span.fa.fa-times
</template>

<script>
import calendarOptions from "../../common/calendarOptions.js";
import MultiSelect from "vue-multiselect";

export default {
  components: {
    MultiSelect,
  },
  props: {
    modalProps: {
      type: Object,
      required: true,
    },
    allIncrementsPoints: {
      type: Array,
      required: true,
    },
    currentIncrementData: { type: Array },
  },
  data() {
    return {
      currentForm: {},
      dateObj: { time: "" },
      dateTimeOption: null,
      MultiShift: null,
    };
  },
  computed: {
    isValidIncRow() {
      const isIncPt = Boolean(this.currentForm.incrementPoint);
      const isStartDate = Boolean(this.currentForm.date);
      return isIncPt && isStartDate;
    },
    incrementsListTable() {
      return [...this.currentIncrementData].sort((a, b) => {
        if (a.start_date < b.start_date) {
          return 1;
        } else if (b.start_date < a.start_date) {
          return -1;
        }
        return 0;
      });
    },
    filterIncrementsPoints() {
      const usedIncrementsIds = this.currentIncrementData.map(
        (inc) => inc.increment_id
      );
      return this.allIncrementsPoints.filter((aip) => {
        return !usedIncrementsIds.includes(aip.id);
      });
    },
  },
  watch: {
    dateObj: {
      handler() {
        this.$set(this.currentForm, "date", this.dateObj);
      },
      deep: true,
    },
  },
  created() {
    this.setupDateTimePickerOpt();
  },
  methods: {
    onAddIncrement() {
      this.$emit("add-increment", this.currentForm);

      // Reset after use
      this.currentForm = {};
      this.dateObj = { time: "" };
    },
    onRemoveIncrement(increment) {
      this.$emit("delete-increment", increment);
    },
    handleNoStr(str) {
      return str || "";
    },
    getIncName(incrementId) {
      const incObj =
        this.allIncrementsPoints.find((aip) => aip.id === incrementId) || {};
      return incObj.name;
    },
    setIncrementPoint(evt) {
      this.$set(this.currentForm, "incrementPoint", evt);
    },
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "DD/MM/YYYY";
      this.dateTimeOption.placeholder = "Select date";
    },
  },
};
</script>

<style lang="scss" scoped>
.text-error {
  color: crimson;
}

.sub-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

td.editincremets-center {
  text-align: center;
}

th.center {
  text-align: center;
}

.modal-content {
  width: 80%;
  margin: 0 auto;
  padding: 0px;
}
</style>

<style lang="scss">
.edit-increments-table {
  .cov-vue-date {
    display: flex;
    justify-content: center;

    input {
      font-size: 14px !important;
      font-weight: 400;
      background: white;
    }
  }
  .cov-datepicker {
    width: 180px;
    border: 1px solid #e8e8e8 !important;
    box-shadow: unset !important;
    height: 40px;
  }

  .table-wrap {
    max-height: calc(100vh - 200px);
    overflow: auto;
    min-height: 400px;

    tr {
      &.deleted {
        background: rgb(241, 241, 241);
        td {
          color: gray;
        }
      }
    }

    .fa-info-circle {
      transition: 0.2s ease all;
      // &:hover {
      //   color: royalblue;
      //   cursor: pointer;
      // }
    }
  }

  button {
    &.add-action {
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }

    &.remove-action {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: 0.2s ease all;

      &:hover {
        background: lightcoral;
        color: white;
      }
    }
  }
}
</style>
