<template lang="pug">
table.table.is-striped
  thead
    tr
      th SHIFT ID
      th CLIENT NAME
      th RESPONSE
      th DATE
  tfoot
  tbody(v-if="isLoading")
    tr
      td.text-center(colspan="4")
        img(src="../../assets/images/comps/loader.svg")
  tbody(v-else)
    tr(v-for="log in logs")
      td 
        router-link(
          :to="{ name: 'shiftDetails', params: { shiftId: log.shift.id } }"
        ) {{ log.shift.id }}
      td {{ log.shift.location.client.name }}
      td
        span.generic-app-tag {{ message(log.type) }}
      td {{ m(log.createdAt) }}
</template>
<script>
// import { LOG_TYPES } from '../config/shifts.js'
// import _ from 'underscore'
import { momentDateTimeFromApi } from '../../../src/lib/helpers/function.js';

export default {
  props: ['logs', 'isLoading'],
  data () {
    return {
    };
  },
  methods: {
    m: momentDateTimeFromApi,
    message: function (logType) {
      return logType;
      // console.log(_.findKey(LOG_TYPES, { type: logType }))
      // return _.findKey(LOG_TYPES, { type: logType })
    },
  },
};

</script>
<style lang="scss" scoped>
table {
  margin-top: 20px;
  border: 1px solid #dde6eb;
  border-collapse: separate;
}

table th {
  color: #7c91ae;
  background-color: #f2f7fa;
}

table td {
  color: #405168;
  vertical-align: middle;
  border-bottom: 1px solid #dde6eb;
}

em {
  color: #333;
}

.text-center {
  text-align: center;
  background: #fafafa;
}
</style>
