<template lang="pug">
div
  p.control
    span ASSIGN PIN NUMBER
  p.control
    span.rectangle.group
      input(type="text" v-on:keyup="$event.target.nextElementSibling.focus()" v-on:focus="$event.target.value = ''" maxlength="1" v-model="user.pinArr[0]")
      input(type="text" v-on:keyup="$event.target.nextElementSibling.focus()" v-on:focus="$event.target.value = ''" maxlength="1" v-model="user.pinArr[1]")
      input(type="text" v-on:keyup="$event.target.nextElementSibling.focus()" v-on:focus="$event.target.value = ''" maxlength="1" v-model="user.pinArr[2]")
      input(type="text" v-on:focus="$event.target.value = ''" maxlength="1" v-model="user.pinArr[3]")

  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(type="submit", @click.prevent="pinSubmit") CHANGE
    button.button.is-outlined.is-caps-lock(@click.prevent="callModalClose") CANCEL
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: ['modalProps'],
  data () {
    return {
      testData: 'test',
      userId: this.modalProps.userId,
      includes: this.modalProps.includes,

      user: {
        pinArr: [],
      },
    };
  },
  methods: {
    ...mapActions({
      'patchUsers': 'patchUsers',
    }),
    pinSubmit () {
      var payload = {};
      payload = { 'pin': this.user.pinArr.join('') };
      this.patchUsers({
        id: this.userId,
        data: payload,
        query: { include: this.includes },
      })
        .then(() => {
          this.$toasted.info('PIN changed').goAway(1500);
          this.callModalClose();
        })
        .catch(() => {
          this.$toasted.error('Invalid PIN. Please try again.').goAway(1500);
        });
    },

    callModalClose () {
      this.$emit('cancel-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.rectangle {
  input {
    color: #2669b0;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    height: 40px;
    width: 40px;
    border: 1px solid #2669b0;
    border-radius: 3px;
    background-color: #ffffff;

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
