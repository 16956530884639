<template lang="pug">
.file-list-management
  //- iframe(v-if="fileLink", :src="fileLink")
  //- table.table.is-striped(v-if="!fileLink")
  table.table.sticky
    thead
      tr
        th NAME
        th SIZE
        slot(name="table-col")
        th(colspan="2", style="width: 80px") ACTIONS
    tfoot(v-show="false")
      tr
        td(colspan="4", style="text-align: center; padding: 1.5em 0")
    tbody
      template(v-for="(file, index) in filteredFiles")
        slot(name="table-row", :item="file", :index="index")
          tr.fileRow(
            :class="[setGroupedClass(file, index), file.__rowClasses]",
            :key="index"
          )
            td(
              data-cell="name",
              :class="{ deleting: isFileBeingDeleted(file) }"
            )
              span(style="cursor: pointer", @click="onOpenFile(file)") {{ file.fileName }}
            td(data-cell="size") {{ file.fileSize + " bytes" }}
            slot(name="table-body-cell", :item="file", :index="index")
            td.has-icon(data-cell="download-col")
              span(@click="onDownloadFile(file)")
                svg(
                  xmlns="http://www.w3.org/2000/svg",
                  width="24",
                  height="24",
                  viewbox="0 0 24 24"
                )
                  path(
                    fill="#0468AF",
                    d="M19.37 9.105c-.327-3.41-3.167-6.087-6.663-6.087-2.706 0-5.022 1.606-6.09 3.91-.32-.103-.656-.172-1.01-.172-1.858 0-3.364 1.507-3.364 3.364 0 .37.074.718.183 1.05C.98 12.01 0 13.557 0 15.35c0 2.685 2.175 4.86 4.86 4.86h13.454c3.097 0 5.605-2.51 5.605-5.607 0-2.733-1.96-5.003-4.55-5.498zm-3.846 4.844l-2.826 2.785c-.57.572-.922.553-1.476 0L8.395 13.95c-.405-.662-.074-1.203.738-1.203h1.332v-3c0-.413.335-.75.747-.75h1.495c.414 0 .748.337.748.75v3h1.33c.81 0 1.145.54.74 1.202z"
                  )
            td.has-icon(data-cell="delete-col", v-if="canDeleteFiles")
              span(@click="onDeleteFile(file)")
                svg(
                  xmlns="http://www.w3.org/2000/svg",
                  width="24",
                  height="24",
                  viewbox="0 0 24 24"
                )
                  path(
                    fill="#FF3B3B",
                    d="M11.987 3C7.024 3 3 7.025 3 11.99c0 4.963 4.024 8.987 8.987 8.987 4.966 0 8.99-4.025 8.99-8.988 0-4.965-4.024-8.99-8.99-8.99zm3.865 11.423l-1.432 1.43s-2.264-2.43-2.434-2.43c-.166 0-2.43 2.43-2.43 2.43l-1.434-1.43s2.433-2.232 2.433-2.43c0-.203-2.433-2.435-2.433-2.435l1.433-1.433s2.284 2.43 2.43 2.43c.15 0 2.435-2.43 2.435-2.43l1.432 1.433s-2.433 2.265-2.433 2.434c0 .162 2.432 2.43 2.432 2.43z"
                  )
</template>
<script>
import { getFormattedTime } from "../../lib/helpers/function.js";
import _ from "underscore";

export default {
  props: {
    files: Array,
    // fileLink: String,
    pendingDeleteFiles: Array,
    canDeleteFiles: {
      default: true,
    },
    canOpenFile: {
      default: true,
    },
    groupedKeyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fileToBeDeleted: null,
    };
  },
  computed: {
    filteredFiles() {
      return this.files;
    },
  },
  methods: {
    onOpenFile(file) {
      if (this.isFileBeingDeleted(file) || !this.canOpenFile) {
        console.warn("Canceled action");
        return;
      }

      const params = {
        link: file.fileUrl,
      };

      this.$emit("open-file", params, file);
    },
    onDownloadFile(file) {
      if (this.isFileBeingDeleted(file)) {
        console.warn("Canceled action");
        return;
      }

      const params = {
        link: file.fileUrl,
      };

      this.$emit("download-file", params, file);
    },
    onDeleteFile(file) {
      if (this.isFileBeingDeleted(file)) {
        console.warn("Canceled action");
        return;
      }

      const fileId = file.id;
      this.$emit("delete-file", fileId);
    },
    isFileBeingDeleted(file) {
      return this.pendingDeleteFiles.includes(file.id);
    },
    getFormattedTime(createdAt) {
      if (createdAt) {
        return getFormattedTime(createdAt, "DD/MM/YYYY HH:mm");
      }
      return null;
    },
    setGroupedClass(item, currIndex) {
      // v1.1
      const hasInitialValues =
        this.groupedKeyList.length &&
        this.groupedKeyList.every((key) => item[key]);
      if (!hasInitialValues) {
        return [];
      }

      const checkSameParameter = (key, currentRow) => {
        return item[key] === currentRow[key];
      };

      const array = this.filteredFiles;
      const totalLeng = array.length;

      const classSet = new Set();
      let hasPrepend = false;
      let hasPostpend = false;
      let startIndex = Math.max(currIndex - 1, 0);
      let endIndex = Math.min(currIndex + 2, totalLeng);

      for (let localIndex = startIndex; localIndex < endIndex; localIndex++) {
        if (localIndex === currIndex) {
          continue;
        }
        const currentRowEl = array[localIndex];
        const isAllKeysSame = this.groupedKeyList.every((key) =>
          checkSameParameter(key, currentRowEl)
        );

        if (isAllKeysSame) {
          classSet.add("grouped");
          if (currIndex < localIndex) {
            hasPrepend = true;
          } else if (currIndex > localIndex) {
            hasPostpend = true;
          }
        }
      }

      if (hasPrepend && !hasPostpend) {
        classSet.add("first");
      } else if (!hasPrepend && hasPostpend) {
        classSet.add("last");
      }

      return Array.from(classSet);
    },
  },
};
</script>
<style lang="scss" scoped>
.file-list-management {
  // height: 100%; // Don't use this, it breaks modal
  width: 100%;
  padding: 10px 0;

  tr {
    &.grouped {
      &:not(.first):not(.last) {
        td {
          border-top: none;
          border-bottom: none;
        }
      }

      &.first {
        td {
          border-bottom: none;
        }
      }

      &.last {
        td {
          border-top: none;
        }
      }
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .fileRow {
    td:first-child {
      font-size: 1.1em;
    }
  }

  table {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;

    td.deleting {
      span {
        color: red;
        text-decoration: line-through;
      }
    }

    .has-icon {
      > span {
        cursor: pointer;
      }
    }

    thead td {
      color: #7c91ae;
      border: none;
      font-weight: 900;
      font-size: smaller;
    }
  }
}
</style>
