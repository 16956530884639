<template lang="pug">
.public-holidays-wrapper
  modal.standard-modal(
    v-if="modalVisible",
    @close="cancelModal",
    :title="modalTitle",
    :scrollable="false",
    :size="modalSize"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="cancelModal",
      @refresh-data="refreshData"
    )
  .public-title 
    span Public Holidays Maintenance
  .public-year-and-holiday
    .year
      label.label Year
      multi-select#years-select(
        :options="filtratedYears",
        track-by="id",
        label="label",
        v-model="selectedYear",
        placeholder="SELECT YEAR",
        :multiple="false",
        @input="getHolidaysByYear(selectedYear)"
      )
    .holiday(v-if="$can('manage-public-holidays')")
      label.label Create a Holiday
      button(@click="createNewHoliday") Create a Holiday
    .copy-holiday(v-if="$can('manage-public-holidays')")
      label.label Copy Holidays
      button(@click="copyHoliday") Copy Holidays
  .public-dates(v-if="selectedYear")
    .left
      .title
        p Public Holiday Name
      .content(
        v-for="(holiday, index) in holidays",
        :class="{ toggle: index % 2 ? true : false }"
      )
        ul
          li {{ holiday.hld_name }}
    .right
      .title
        p Date
      .content(
        v-for="(holiday, index) in holidays",
        :class="{ toggle: index % 2 ? true : false }"
      )
        ul
          span.holiday-date
            li {{ formatDate(holiday.hld_date) }}
            img(
              v-if="$can('manage-public-holidays')",
              src="../../../assets/images/comps/Olovka.svg",
              @click="showEdit(holiday, index)"
            )
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapGetters, mapActions } from "vuex";
import CreatePublicHoliday from "../../in-modal/rate-management-public-holidays/CreateHoliday.vue";
import EditPublicHoliday from "../../in-modal/rate-management-public-holidays/EditPublicHoliday.vue";
import CopyPublicHoliday from "../../in-modal/rate-management-public-holidays/CopyPublicHoliday.vue";
import moment from "moment";

export default {
  name: "RatesManagePublicHolidays",
  components: {
    MultiSelect,
    CreatePublicHoliday,
    EditPublicHoliday,
    CopyPublicHoliday,
  },
  data() {
    return {
      modalVisible: false,
      modalTitle: "Test",
      modalSize: "600",
      years: [],
      selectedYear: "",
      holidays: [],
      warnMessage: "",
    };
  },
  computed: {
    filtratedYears() {
      return this.years.map((year, index) => {
        return { id: index, label: year };
      });
    },
  },
  mounted() {
    this.getPublicYears();
  },
  methods: {
    ...mapActions({
      getPublicHolidaysYears: "getPublicHolidaysYears",
      getPublicHolidaysByYear: "getPublicHolidaysByYear",
    }),
    formatDate(date) {
      const formatted = moment(date).format("DD-MM-YYYY");
      return formatted;
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = "600"
    ) {
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle;
      this.modalVisible = true;
      this.modalSize = modalSize;
    },
    cancelModal() {
      this.modalVisible = false;
      this.getPublicYears();
      this.getHolidaysByYear();
    },
    async getPublicYears() {
      try {
        const res = await this.getPublicHolidaysYears();
        this.years = res.data.data;
        this.warnMessage = res.data.warning_message;
      } catch (err) {
        console.log(err.message);
      }
    },
    async getHolidaysByYear(year) {
      if (year && year.label) {
        const params = {
          year: year.label,
        };
        try {
          const res = await this.getPublicHolidaysByYear(params);
          this.holidays = res.data.data;
        } catch (err) {
          console.log(err.message);
        }
      }
    },
    createNewHoliday() {
      const data = {
        year:
          this.selectedYear && this.selectedYear.label
            ? this.selectedYear.label
            : "",
        warnMsg: this.warnMessage,
      };
      this.showModal("CreatePublicHoliday", data, "Create a Holiday");
    },
    showEdit(date, index) {
      const modData = {
        index: index,
        hld_date: moment(date.hld_date).format("DD-MM-YYYY"),
        hld_name: date.hld_name,
        year: this.selectedYear.label,
        warnMsg: this.warnMessage,
      };
      this.showModal("EditPublicHoliday", modData, "Edit public holiday", 1000);
    },
    copyHoliday() {
      const modData = {
        year:
          this.selectedYear && this.selectedYear.label
            ? this.selectedYear.label
            : "",
        warnMsg: this.warnMessage,
        years: this.years,
      };
      this.showModal(
        "CopyPublicHoliday",
        modData,
        "Copy public holidays",
        1000
      );
    },
    async refreshData(year) {
      await this.getPublicYears();
      if (year.newYear) {
        const new_year_obj = this.filtratedYears.find(
          (el) => Number(el.label) === Number(year.newYear)
        );
        const data = {
          label: year.newYear,
        };
        await this.getHolidaysByYear(data);
        this.selectedYear = {};
        this.selectedYear.label = new_year_obj.label;
        this.selectedYear.id = new_year_obj.id;
      } else {
        const data = {
          label: year.year,
        };
        this.getHolidaysByYear(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.public-holidays-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  .public-title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .public-year-and-holiday {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    // min-width: 300px;
    .year {
      display: flex;
      flex-direction: column;
      //   background-color: purple;
      color: black;
      #years-select {
        min-height: 40px;
        display: block;
        padding: 8px 40px 0 8px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px;
      }
    }
    .holiday {
      display: flex;
      flex-direction: column;

      .label {
        // visibility: hidden;
      }
      button {
        cursor: pointer;
        background-color: #625efd;
        color: white;
        border-radius: 10px;
        border: none;
        padding: 11px;
        font-weight: bold;
        font-size: 14px;
      }
      button:hover {
        background-color: #4c48b4;
      }
    }
    .copy-holiday {
      .label {
      }
      button {
        cursor: pointer;
        background-color: #2669b0;
        color: white;
        border-radius: 10px;
        border: none;
        padding: 11px;
        font-weight: bold;
        font-size: 14px;
      }
      button:hover {
        background-color: #1f4b7a;
      }
    }
  }
  .public-dates {
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding: 0 4px 0 0;
    .left {
      margin: 0 10px 0 0;
      .title {
        background-color: #f0f8fa;
        color: black;
        font-size: 1.2rem;
      }
      .toggle {
        background-color: #f7f7f7;
      }
      .content {
        margin: 10px 0 10px 0;
        // padding: 5px 0 5px 0;
        ul {
          margin: 0 0 0 0;
          .toggle {
            background-color: #f7f7f7;
          }
          li {
            font-size: 1.3rem;
          }
        }
      }
    }
    .right {
      .title {
        background-color: #f0f8fa;
        color: black;
        font-size: 1.2rem;
      }
      .toggle {
        background-color: #f7f7f7;
      }
      .content {
        margin: 10px 0 10px 0;
        // padding: 5px 0 5px 0;
        ul {
          margin: 0 0 0 0;
          .holiday-date {
            display: flex;
            img {
              margin: 0 0 0 10px;
              width: 12px;
              cursor: pointer;
            }
            li {
              list-style: none;
              font-size: 1.3rem;
              // margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }
}
</style>
