<template lang="pug">
table#service-fee-table.table.is-striped
  thead
    tr
      th(style="width: 100px")
        label(
          style="display: flex; align-items: center; gap: 3px",
          v-if="allServiceFeesFilteredSorted.length"
        )
          input(
            :checked="isSelectedAllRows",
            ref="selectAllCheckbox",
            type="checkbox",
            @click="onCheckedSelectAll"
          )
          span Select all
      th.header-category(
        v-if="initialStatus.servicefee_show_subcategories",
        style="width: 230px"
      )
        multi-select.subcategory-select(
          :options="filteredSubcategoriesWithGroups",
          :searchable="true",
          :options-limit="999",
          select-label="",
          selected-label="",
          deselect-label="",
          selectGroupLabel="",
          deselectGroupLabel="",
          track-by="id",
          label="name",
          :multiple="false",
          :loading="!filteredSubcategoriesWithGroups.length",
          placeholder="FILTER BY CATEGORY",
          :value="selectedSubcategoryOrCatFilter",
          @select="onSelectSubcategory",
          @remove="onRemoveSubcategory"
        )
        //- span Subcategory
      th(v-if="initialStatus.servicefee_show_increments", style="width: 220px")
        span Increment
      th(style="width: 280px")
        span Type
      th(style="width: 170px; min-width: 125px")
        span Date
      th.value-header-type-day(
        v-if="initialStatus.servicefee_show_calcitems",
        style="width: 210px"
      )
        //- span Part of the day
        multi-select(
          :options="dayTypeOptions",
          label="name",
          track-by="id",
          select-label="",
          selected-label="",
          deselect-label="",
          placeholder="Filter by part of the day",
          v-model="partOfTheDayFilter"
        )
      th(style="width: 110px")
        span Value
      th(style="width: 40px")
      th(style="width: 40px")
      th(style="width: 40px")
  tbody
    transition(name="fade", @after-enter="onAfterEnterTableRow")
      tr.new-row(v-if="isAddingRow")
        td(data-cell="new-checkbox")
        td.category-select-wrap(
          data-cell="new-category-select",
          v-if="initialStatus.servicefee_show_subcategories"
        )
          multi-select.category-select(
            :options="filteredSubcategoriesWithGroups",
            select-label="",
            selected-label="",
            deselect-label="",
            track-by="id",
            label="name",
            :multiple="false",
            :allow-empty="false",
            placeholder="SELECT SUBCATEGORY",
            :value="getCategoryFromServiceFee(newRowData, false)",
            @select="onSetNewCategory($event, newRowData, false)"
          )
          //- @remove="onRemoveCategory($event, newRowData, false)"
        td.increments-select-wrap(
          data-cell="new-increment-select",
          v-if="initialStatus.servicefee_show_increments"
        )
          multi-select.increments-select(
            :options="filteredIncrements",
            :searchable="true",
            select-label="",
            selected-label="",
            deselect-label="",
            track-by="id",
            label="name",
            :multiple="false",
            group-label="name",
            group-values="increments",
            placeholder="SELECT INCREMENT",
            :value="getIncrementFromServiceFee(newRowData, false)",
            @select="onSetNewIncrement($event, newRowData, false)",
            @remove="onRemoveIncrement($event, newRowData, false)"
          )
        td(data-cell="new-fee-types")
          multi-select(
            :options="feeTypes",
            label="name",
            track-by="id",
            select-label="",
            selected-label="",
            deselect-label="",
            :value="getFeeType(newRowData)",
            @select="onSelectFeeType($event, newRowData)",
            @remove="onRemoveFeeType($event, newRowData)"
          )
        td(data-cell="new-select-date")
          date-picker(
            placeholder="Select date",
            :date="getNewRowDate",
            :option="dateTimeOption",
            @change="onSelectDate($event, newRowData)"
          )
          //- v-if="checkDate(newRowData.old_valid_from, fee)",
        td(v-if="initialStatus.servicefee_show_calcitems")
          multi-select(
            :options="dayTypeOptions",
            label="name",
            track-by="id",
            select-label="",
            selected-label="",
            deselect-label="",
            :value="getCalcItem(newRowData)",
            @select="onSelectCalcItem($event, newRowData)",
            @remove="onRemoveCalcItem($event, newRowData)"
          )
        td(data-cell="new-value")
          .value-wrap
            input.input(
              :value="newRowData.value",
              type="number",
              :placeholder="getFeeTitle(newRowData)",
              :title="getFeeTitle(newRowData)",
              min="0",
              @input="onInputNewRowValue($event, newRowData)"
            )
            span(style="margin-left: 2px") {{ getDefaultValueSign(newRowData) }}
        td(data-cell="new-info-tooltip")
          span.info(
            style="margin-left: 10px",
            v-tooltip="{ content: generateTitle(newRowData), classes: 'report-status-tooltip' }"
          )
            span.fa.fa-info.popup
        td
        td
    tr(
      v-for="(servicefee, index) in allServiceFeesFilteredSorted",
      :key="index",
      :class="serviceFeeClasses(servicefee, index)"
    )
      td.single-checkbox-select(data-cell="checkbox-select")
        label(title="Hold CTRL to select multiple values")
          input(
            v-model="servicefee.__isCheckBoxSelected",
            type="checkbox",
            @click="onClickSingleCheckbox($event, servicefee, index)"
          )
          span
          //- span Select
      td.category-select-wrap(
        data-cell="category-select",
        v-if="initialStatus.servicefee_show_subcategories"
      )
        multi-select.category-select(
          :options="filteredSubcategoriesWithGroups",
          select-label="",
          selected-label="",
          deselect-label="",
          track-by="id",
          label="name",
          :multiple="false",
          :allow-empty="false",
          placeholder="SELECT SUBCATEGORY",
          :disabled="servicefee.disabled",
          :value="getCategoryFromServiceFee(servicefee)",
          @select="onSetNewCategory($event, servicefee)"
        )
        //- @remove="onRemoveCategory($event, servicefee)"
      td.increments-select-wrap(
        data-cell="increment-select",
        v-if="initialStatus.servicefee_show_increments"
      )
        multi-select.increments-select(
          :options="filteredIncrements",
          :searchable="true",
          select-label="",
          selected-label="",
          deselect-label="",
          track-by="id",
          label="name",
          :multiple="false",
          group-label="name",
          group-values="increments",
          placeholder="SELECT INCREMENT",
          :disabled="servicefee.disabled",
          :value="getIncrementFromServiceFee(servicefee)",
          @select="onSetNewIncrement($event, servicefee)",
          @remove="onRemoveIncrement($event, servicefee)"
        )
      td(data-cell="fee-type")
        multi-select(
          :options="feeTypes",
          label="name",
          track-by="id",
          select-label="",
          selected-label="",
          deselect-label="",
          :disabled="servicefee.disabled",
          :value="getFeeType(servicefee)",
          @select="onSelectFeeType($event, servicefee, index)",
          @remove="onRemoveFeeType($event, servicefee)"
        )
      td(data-cell="select-date")
        date-picker(
          placeholder="Select date",
          :date="getServiceFeeTime(servicefee)",
          :option="dateTimeOption",
          @change="onSelectDate($event, servicefee, index)"
        )
      td(v-if="initialStatus.servicefee_show_calcitems")
        multi-select(
          :options="dayTypeOptions",
          label="name",
          track-by="id",
          select-label="",
          selected-label="",
          deselect-label="",
          :disabled="servicefee.disabled",
          :value="getCalcItem(servicefee)",
          @select="onSelectCalcItem($event, servicefee, index)",
          @remove="onRemoveCalcItem($event, servicefee)"
        )
      td(data-cell="value")
        .value-wrap
          input.input(
            :value="servicefee.value",
            type="number",
            :disabled="servicefee.disabled",
            :placeholder="getFeeTitle(servicefee)",
            :title="getFeeTitle(servicefee)",
            min="0",
            @change="onChangeValueCell($event, servicefee, index, 'value')",
            @paste="onPasteValueCell($event, servicefee, index, 'value')"
          )
          span(style="margin-left: 2px") {{ getDefaultValueSign(servicefee) }}
      td(data-cell="info-tooltip")
        span.info(
          style="margin-left: 10px",
          v-tooltip="{ content: generateTitle(servicefee), classes: 'report-status-tooltip' }"
        )
          span.fa.fa-info.popup
      td(data-cell="undo-col")
        button.button.is-danger.is-low.is-tiny(
          title="This action will undo the unapplied changes for this row.",
          v-if="isRowModified(servicefee)",
          @click="onClickUndoRow($event, servicefee)"
        ) Undo
      td(data-cell="delete-col")
        button.button.is-danger.is-low.is-tiny(
          title="This action will delete the service fee from the database.",
          v-if="isRowInactive(servicefee) && servicefee.id",
          @click="onClickDeleteRow($event, servicefee)"
        ) Delete
</template>

<script>
// import _ from 'underscore'
import MultiSelect from "vue-multiselect";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import calendarOptions from "../../common/calendarOptions";
export default {
  components: {
    MultiSelect,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    newRowData: {
      type: Object,
    },
    incrementOptions: {
      type: Array,
    },
    isAddingRow: Boolean,
    serviceFeeRowsFiltered: Array,
    pendingRows: Array,
    dayTypeOptions: Array,
    filteredSubcategoriesWithGroups: Array,
    categoriesOnly: Array,
    selectedCategory: Object,
  },
  data() {
    return {
      dateTimeOption: null,
      isSelectedAllRows: false,
      lastCheckedIndex: null,
      partOfTheDayFilter: null,
      selectedSubcategoryOrCatFilter: null,
      fixedChangedDate: "3000-01-01 00:00:00",
    };
  },
  computed: {
    ...mapState({
      initialStatus: (state) => state.initialStatus,
    }),
    ...mapGetters({
      feeTypes: "locationClientManagement/getServiceFeeTypes",
      getServiceCategoryFeeTypes:
        "locationClientManagement/getServiceCategoryFeeTypes",
    }),
    filteredIncrements() {
      return this.incrementOptions.filter(
        (cat) => cat.id === this.selectedCategory.id
      );
    },
    getNewRowDate() {
      const formatToHumanDate = (str) => {
        if (!str) {
          return "";
        }
        return moment(str, "YYYY-MM-DD HH:mm:ss")
          .format("DD/MM/YYYY HH:mm:ss")
          .valueOf();
      };

      return {
        time:
          formatToHumanDate(this.newRowData.valid_from) ||
          formatToHumanDate(this.newRowData.old_valid_from) ||
          "",
      };
    },
    getFlatIncrements() {
      return this.incrementOptions.map((cat) => cat.increments).flat();
    },
    isNotWholeCheckboxState() {
      const isEveryCheckedLen = this.allServiceFeesFilteredSorted.reduce(
        (acc, curr) => {
          if (curr.__isCheckBoxSelected) {
            acc++;
          }
          return acc;
        },
        0
      );

      return (
        isEveryCheckedLen !== 0 &&
        this.allServiceFeesFilteredSorted.length !== isEveryCheckedLen
      );
    },
    allServiceFeesFilteredSorted() {
      let sfrArray = [...this.serviceFeeRowsFiltered];
      const subFilterId =
        this.selectedSubcategoryOrCatFilter &&
        this.selectedSubcategoryOrCatFilter.id;

      // Filter by Cat/Sub filter
      if (subFilterId) {
        sfrArray = sfrArray.filter((sfr) => {
          return (
            sfr.category_id === subFilterId ||
            sfr.subcategory_id === subFilterId
          );
        });
      }
      // Filter by part of day
      if (this.partOfTheDayFilter && this.partOfTheDayFilter.id) {
        sfrArray = sfrArray.filter(
          (sfr) => sfr.calcitem_id === this.partOfTheDayFilter.id
        );
      }

      /*** Backend sorts the data ***/

      // Sort by date
      // const sortByDate = (a, b) => {
      //   if (a.valid_from && b.valid_from) {
      //     if (a.valid_from < b.valid_from) {
      //       return -1;
      //     } else if (a.valid_from > b.valid_from) {
      //       return 1;
      //     }
      //   }
      //   return 0;
      // };

      // Sort logic
      // sfrArray.sort((a, b) => {
      //   // Sort by parent cat
      //   const isFirstParent = this.isRowParent(a);
      //   const isSecondParent = this.isRowParent(b);
      //   if (isFirstParent && isSecondParent) {
      //     return sortByDate(a, b);
      //   } else if (!isFirstParent && !isSecondParent) {
      //     return sortByDate(a, b);
      //   } else if (isFirstParent) {
      //     return -1;
      //   } else if (isSecondParent) {
      //     return 1;
      //   }
      //   return 0;
      // });

      return sfrArray;
    },
    dayTypeOptionsFiltered() {
      const parseCalcItemsStatus = (keyStr) => {
        if (keyStr && typeof keyStr === "string") {
          const splittedKeys = keyStr.split(",");
          return splittedKeys;
        }
        console.log("Invalid keys", keyStr);
        return [];
      };
      const includedKeys = parseCalcItemsStatus(
        this.initialStatus.servicefee_calcitems
      );
      return this.dayTypeOptions.filter((dt) => includedKeys.includes(dt.code));
    },
  },
  watch: {
    isNotWholeCheckboxState(val) {
      // For toggling select all checkbox
      const isEverySelected = this.allServiceFeesFilteredSorted.every(
        (sr) => sr.__isCheckBoxSelected
      );
      if (isEverySelected) {
        this.$refs.selectAllCheckbox.indeterminate = false;
        this.isSelectedAllRows = true;
      } else {
        this.$refs.selectAllCheckbox.indeterminate = val;
        this.isSelectedAllRows = false;
      }
    },
  },
  created() {
    this.setupDateTimePickerOpt();
  },
  methods: {
    getCategoryFromServiceFee(feeRowData = {}) {
      const key = "subcategory_id";
      const keyParent = "category_id";

      const catSubId = feeRowData[key] || feeRowData[keyParent];
      return this.filteredSubcategoriesWithGroups.find(
        (sc) => sc.id === catSubId
      );
    },
    getIncrementFromServiceFee(feeRowData = {}) {
      const key = "increment_id";
      return this.getFlatIncrements.find((inc) => inc.id === feeRowData[key]);
    },
    getFeeType(feeRowData = {}) {
      const key = "service_fee_calculation_type_id";
      return this.feeTypes.find((ft) => ft.id === feeRowData[key]);
    },
    getDefaultValueSign(feeRowData = {}) {
      const sfMonetaryValue = this.initialStatus.monetary_symbol;
      const ftObj = this.getFeeType(feeRowData) || {};
      if (ftObj.ispercentage === 1) {
        return "%";
      } else if (ftObj.ispercentage === 0 && sfMonetaryValue) {
        return sfMonetaryValue;
      }
      // console.log("New unknown service fee sign", sfMonetaryValue, ftObj)
      return "";
    },
    getFeeTitle(feeRowData = {}) {
      const sfMonetaryValue = this.initialStatus.monetary_symbol;
      const ftObj = this.getFeeType(feeRowData) || {};
      if (ftObj.ispercentage === 1) {
        return "Fee percentage";
      } else if (ftObj.ispercentage === 0 && sfMonetaryValue) {
        return "Monetary value";
      }
      // console.log("New unknown service fee sign", sfMonetaryValue, ftObj)
      return "";
    },
    onSelectSubcategory(evtObj) {
      console.log("Select Cat/Sub filter", evtObj);
      this.selectedSubcategoryOrCatFilter = evtObj;
    },
    onRemoveSubcategory() {
      this.selectedSubcategoryOrCatFilter = null;
    },
    getCalcItem(feeRowData = {}) {
      const key = "calcitem_id";
      return this.dayTypeOptions.find((dto) => dto.id === feeRowData[key]);
    },
    onSelectCalcItem(valObj, feeRowData) {
      const key = "calcitem_id";
      this.updateSpeedPasteSingleMultipleVals(feeRowData, null, key, valObj.id);
    },
    onRemoveCalcItem(evt, feeRowData) {
      const key = "calcitem_id";
      this.updateSpeedPasteSingleMultipleVals(
        feeRowData,
        null,
        key,
        null,
        true
      );
    },
    onSetNewCategory(valObj, feeRowData) {
      if (feeRowData.parent_id) {
        this.setRowDataValue(feeRowData, "subcategory_id", valObj.id);
      } else {
        this.setRowDataValue(feeRowData, "category_id", valObj.id);
        this.removeValSetPending(feeRowData, "subcategory_id");
      }
    },
    onRemoveCategory(evt, feeRowData) {
      // Unused
      this.removeValSetPending(feeRowData, "subcategory_id");
    },
    onSetNewIncrement(valObj, feeRowData) {
      this.setRowDataValue(feeRowData, "increment_id", valObj.id);
    },
    onRemoveIncrement(evt, feeRowData) {
      this.removeValSetPending(feeRowData, "increment_id");
    },
    onSelectFeeType(valObj, feeRowData, index) {
      const key = "service_fee_calculation_type_id";
      this.updateSpeedPasteSingleMultipleVals(
        feeRowData,
        index,
        key,
        valObj.id
      );
    },
    onRemoveFeeType(evt, feeRowData) {
      const key = "service_fee_calculation_type_id";
      this.updateSpeedPasteSingleMultipleVals(
        feeRowData,
        null,
        key,
        null,
        true
      );
    },
    onInputNewRowValue(evt, feeRowData) {
      const parsedValue = parseFloat(evt.target.value, 10);
      this.setRowDataValue(feeRowData, "value", parsedValue);
    },
    removeValSetPending(feeRowData, key) {
      this.$delete(feeRowData, key);
      this.$set(feeRowData, "__isPending", true);
    },
    setRowDataValue(feeRowData, key, value) {
      const payload = {
        feeRowData,
        key,
        value,
      };
      this.$emit("changeRowData", payload);

      // this.$set(feeRowData, key, value);
      // this.$set(feeRowData, '__isPending', true)
    },
    onCheckedSelectAll(evt) {
      // evt.preventDefault();
      this.isSelectedAllRows = evt.target.checked;
      this.lastCheckedIndex = null;

      for (const sfRow of this.allServiceFeesFilteredSorted) {
        if (this.isSelectedAllRows) {
          this.$set(sfRow, "__isCheckBoxSelected", true);
        } else {
          this.$delete(sfRow, "__isCheckBoxSelected");
        }
      }
    },
    checkDate(date, fee) {
      // Unused apt
      const currentDateMidnight =
        moment(new Date()).format("YYYY-MM-DD") + " 00:00:00";
      if (moment(currentDateMidnight).isBefore(date) || date == null) {
        return true;
      } else {
        fee.disabled = true;
        return false;
      }
    },
    onClickSingleCheckbox(evt, feeRowData, index) {
      // Select multiple rows when [CTRL + Click] on a row
      const isCurrentChecked = evt.target.checked;
      if (isCurrentChecked) {
        const setLastIndex = () => {
          this.lastCheckedIndex = index;
        };

        if (evt.ctrlKey) {
          if (this.lastCheckedIndex !== null) {
            // Get the diff between indexes
            const getNums = {
              low: Math.min(this.lastCheckedIndex, index),
              high: Math.max(this.lastCheckedIndex, index),
            };
            const range = (start, stop, step = 1) =>
              Array(stop + 1 - start)
                .fill(start)
                .map((x, y) => x + y * step);

            const rangeMappedIndex = range(getNums.low, getNums.high);
            for (const indexToSelect of rangeMappedIndex) {
              this.$set(
                this.allServiceFeesFilteredSorted[indexToSelect],
                "__isCheckBoxSelected",
                true
              );
            }
          } else {
            setLastIndex();
          }
        } else {
          setLastIndex();
        }
      } else {
        this.lastCheckedIndex = null;
      }
    },
    onSelectDate(timeValue, feeRowData, index) {
      const formattedTime = moment(timeValue, "DD/MM/YYYY HH:mm:ss")
        .format("YYYY-MM-DD HH:mm:ss")
        .valueOf();
      const key = "valid_from";

      this.updateSpeedPasteSingleMultipleVals(
        feeRowData,
        index,
        key,
        formattedTime
      );
      // this.removeError(category, feeRowData)
    },
    onChangeValueCell(evt, feeRowData, index, key) {
      let value = evt.target.value;
      if (key === "value") {
        // Parse to number [Remove leading zeros]
        value = parseFloat(value, 10);
      }

      this.updateSpeedPasteSingleMultipleVals(feeRowData, index, key, value);
    },
    updateSpeedPasteSingleMultipleVals(
      feeRowData,
      index,
      key,
      value,
      isRemove = false
    ) {
      const selectedRowsArr = this.serviceFeeRowsFiltered.filter(
        (sf) => sf.__isCheckBoxSelected
      );
      if (feeRowData.__isCheckBoxSelected && selectedRowsArr.length) {
        // Update selected rows
        for (const sfRow of selectedRowsArr) {
          if (isRemove) {
            this.removeValSetPending(sfRow, key);
          } else {
            this.setRowDataValue(sfRow, key, value);
          }
        }
      } else {
        // Single update
        if (isRemove) {
          this.removeValSetPending(feeRowData, key);
        } else {
          this.setRowDataValue(feeRowData, key, value);
        }
      }
    },
    getServiceFeeTime(feeRowData) {
      const formatToHumanDate = (str) => {
        if (!str) {
          return "";
        }
        return moment(str, "YYYY-MM-DD HH:mm:ss")
          .format("DD/MM/YYYY HH:mm:ss")
          .valueOf();
      };

      return {
        time:
          formatToHumanDate(feeRowData.valid_from) ||
          formatToHumanDate(feeRowData.old_valid_from) ||
          "",
      };
    },
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.dateTimeOption.type = "min";
      this.dateTimeOption.format = "DD/MM/YYYY HH:mm:ss";
    },
    generateTitle(feeObj = {}) {
      const formatDate = (str) => moment(str).format("DD/MM/YYYY HH:mm:ss");
      const formatKeys = (str) =>
        (str[0].toUpperCase() + str.slice(1)).replace("_", " ");

      const dateUsed = feeObj.updated_at || feeObj.created_at;
      const obj = {
        name: feeObj.user_name,
        updated_at: formatDate(dateUsed),
        // created_at: formatDate(feeObj.created_at)
      };
      const jString = Object.entries(obj)
        .filter((el) => el[1])
        .map((el) => `${formatKeys(el[0])}: ${el[1] || ""}`)
        .join("</br>");
      return jString;
    },
    isRowModified(feeObj = {}) {
      return feeObj.__isPending === true;
    },
    isRowInactive(feeObj = {}) {
      return feeObj.created_at === this.fixedChangedDate;
    },
    isRowParent(feeObj = {}) {
      // Has to check for parent and for child key
      if (feeObj.subcategory_id) {
        return false;
      }
      return this.categoriesOnly.some((cat) => cat.id === feeObj.category_id);
    },
    isLastParentCat(index) {
      const parents = this.allServiceFeesFilteredSorted.filter(
        this.isRowParent
      );
      return parents.length ? index + 1 === parents.length : false;
    },
    createOrUpdatePendingRowVals(sfRowData, index, key, value) {
      console.log(
        "Create or update pending rows",
        sfRowData,
        index,
        key,
        value
      );

      this.$set(sfRowData, key, value);
      this.$set(sfRowData, "__isPending", true);
    },
    valueIsDifferent(originalServiceFeeRow, keyToCheck, newValue) {
      const originalValue = originalServiceFeeRow[keyToCheck];
      return originalValue !== newValue;
    },
    onPasteValueCell(evt, rowData, index, key) {
      // console.warn(evt.target.value);
      // this.$set(rowData, key, evt.target.value)

      evt.preventDefault();
      const pastedValue = (evt.clipboardData || window.clipboardData).getData(
        "text"
      );
      const pastedValueCleaned = pastedValue.trim();

      const checkAndChangeValueRow = (servicefeeDataFromRow, value) => {
        if (servicefeeDataFromRow) {
          const isChangedInput = this.valueIsDifferent(
            servicefeeDataFromRow,
            key,
            value
          );
          // console.log('<< changed >>', key, value, isChangedInput);
          if (isChangedInput) {
            this.createOrUpdatePendingRowVals(
              servicefeeDataFromRow,
              index,
              key,
              value
            );
          }
        } else {
          console.log("End of the row count", value);
        }
      };

      // Used for updating all the column rows from the start index
      if (pastedValue.split("\n").length === 2) {
        // Show the confirm dialog
        const isConfirm = confirm(
          "Do you want to update all the rows in the column with the same value starting from this row?"
        );
        if (isConfirm) {
          // Could be slow going with all servicefees
          for (
            let oneValIndex = index;
            oneValIndex < this.allServiceFeesFilteredSorted.length;
            oneValIndex++
          ) {
            const servicefeeDataFromRow =
              this.allServiceFeesFilteredSorted[oneValIndex];
            checkAndChangeValueRow(servicefeeDataFromRow, pastedValueCleaned);
          }
          // Early exit
          return;
        }
      }

      if (pastedValueCleaned.includes("\n")) {
        // Copied from excel sheet [new lines]
        const splittedVals = pastedValueCleaned.split("\n").filter(Boolean);
        for (
          let oneValIndex = 0;
          oneValIndex < splittedVals.length;
          oneValIndex++
        ) {
          const oneVal = splittedVals[oneValIndex];
          // Needs to work with all rows so they can be updated
          const servicefeeDataFromRow =
            this.allServiceFeesFilteredSorted[index + oneValIndex];
          checkAndChangeValueRow(servicefeeDataFromRow, oneVal);
        }
      } else if (pastedValueCleaned) {
        // Regular copy
        const servicefeeDataFromRow = this.allServiceFeesFilteredSorted[index];
        checkAndChangeValueRow(servicefeeDataFromRow, pastedValueCleaned);
      } else {
        // Ignore if empty
      }
    },
    onClickUndoRow(evt, sfRowData) {
      const isRowChecked = sfRowData.__isCheckBoxSelected;

      const undoSingleRow = (sfRowData, isMulti = false) => {
        if (sfRowData.id) {
          const payload = {
            evt,
            row: sfRowData,
            isMulti,
          };
          this.$emit("undo-row", payload);
        } else {
          const randomId = sfRowData.random_id;
          const index = this.pendingRows.findIndex(
            (pr) => pr.random_id === randomId
          );
          if (index !== -1) {
            this.pendingRows.splice(index, 1);
          } else {
            console.warn("Error finding the pending row", sfRowData);
          }
        }
      };

      const checkedRows = this.serviceFeeRowsFiltered.filter(
        (sf) => sf.__isCheckBoxSelected && this.isRowModified(sf)
      );

      const isMultipleOtherChecked = isRowChecked && checkedRows.length > 1;
      const labelMsg = isMultipleOtherChecked
        ? `Are you sure you want to undo ${checkedRows.length} rows?`
        : "Are you sure you want to undo this row?";

      const isConfirm = confirm(labelMsg);
      if (isConfirm) {
        // Support for multiple undo
        if (isMultipleOtherChecked) {
          for (const sfRow of checkedRows) {
            undoSingleRow(sfRow, true);
          }
        } else {
          undoSingleRow(sfRowData);
        }
      }
    },
    async onClickDeleteRow(evt, sfRowData) {
      const isRowChecked = sfRowData.__isCheckBoxSelected;

      const getDeleteRowPayload = (sfRowData, isMulti = false) => {
        return {
          evt,
          row: sfRowData,
          isMulti,
        };
      };
      const checkedRows = this.serviceFeeRowsFiltered.filter(
        (sf) => sf.__isCheckBoxSelected && this.isRowInactive(sf)
      );

      const isMultipleOtherChecked = isRowChecked && checkedRows.length > 1;
      const labelMsg = isMultipleOtherChecked
        ? `Are you sure you want to delete ${checkedRows.length} rows?`
        : "Are you sure you want to delete this row?";

      const isConfirm = confirm(labelMsg);
      if (isConfirm) {
        // Support for multiple delete
        const payload = [];
        if (isMultipleOtherChecked) {
          for (const sfRow of checkedRows) {
            payload.push(getDeleteRowPayload(sfRow, true));
          }
        } else {
          payload.push(getDeleteRowPayload(sfRowData));
        }
        this.$emit("delete-row", payload);
      }
    },
    onAfterEnterTableRow(evt) {
      // Focus the first input after display
      const firstInput = evt.querySelector("input");
      if (firstInput) {
        firstInput.focus();
      }
    },
    serviceFeeClasses(servicefee, index) {
      return [
        {
          changed: this.isRowModified(servicefee),
          inactive: this.isRowInactive(servicefee),
          parent: this.isRowParent(servicefee),
          lastparent: this.isLastParentCat(index),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
#service-fee-table {
  width: auto;
  border-top: unset;

  tr {
    &.new-row {
      background: whitesmoke;
    }

    &:not(.parent):not(&.changed):not(&.inactive) {
      background: #ececec;
    }

    &.lastparent {
      td {
        padding-bottom: 10px;
      }
    }

    &.changed {
      background: #ffff2557;
    }

    &.inactive {
      background: #7796e057;
    }

    &.inactive.changed {
      background: #7266c057;
    }

    th {
      padding: 7px 2px 7px 5px;
      position: sticky;
      top: 0;
      z-index: 50;
      border-top: 1px solid #dbdbdb;

      &.header-category,
      &.value-header-type-day {
        font-weight: 400;
      }
    }

    td {
      padding: 2px 2px 2px 5px;
    }

    input[type="text"],
    input[type="number"] {
      height: 40px;
      background: transparent;
      box-shadow: none;
      padding: 0 5px;
      border: 1px solid rgba(192, 192, 192, 0.4);
    }

    .single-checkbox-select {
      label {
        display: flex;
        align-items: center;
        gap: 3px;
        padding: 0 4px;
        user-select: none;
      }
    }

    .value-wrap {
      display: flex;
      align-items: center;
      padding: 0 5px 0 0;

      input {
        min-width: 50px;
        background: white;
      }
    }
  }

  .fade-enter-active {
    transition: all 0.3s ease;
  }

  .fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateX(30px);
    opacity: 0;
  }
}
</style>

<style lang="scss">
#service-fee-table {
  .multiselect {
    min-height: 40px;
    height: 40px;
    font-size: 14px;

    .multiselect__single {
      white-space: nowrap;
    }
  }

  .multiselect__tags {
    min-height: 40px;
    height: 40px;
    min-width: 200px;
  }

  .cov-vue-date {
    width: 100%;

    input {
      width: 100%;
      height: 40px;
      background: transparent;
      box-shadow: none !important;
      padding: 0 5px;
      border: 1px solid rgba(192, 192, 192, 0.4) !important;
      font-size: 14px !important;
      background: white;
    }
  }
}
</style>
