<template lang="pug">
.journal-filters
  .left-side
    .date-filter
      button.button.is-generic-app-blue.is-caps-lock(
        @click="onClickResetFilters"
      ) Reset filters
      .field.shift-datetime
        label.label From
        p.control
          date-picker(
            :date="filter.from",
            :option="dateTimeOption",
            @change="dateInputChanged($event, 'from')"
          )
      .field.shift-datetime
        label.label To
        p.control
          date-picker(
            :date="filter.to",
            :option="dateTimeOption",
            @change="dateInputChanged($event, 'to')"
          )

  .right-side
    template(v-if="isTempPage")
      p.control
        label.label Client
        multi-select(
          :options="clients",
          track-by="id",
          label="name",
          :multiple="true",
          select-label="",
          selected-label="",
          deselect-label="",
          placeholder="Filter by client",
          :close-on-select="false",
          :value="filter.clients",
          @select="onSelectClient",
          @remove="onRemoveClient"
        )
    template(v-if="isClientPage")
      p.control
        label.label Temp
        multi-select(
          :options="formattedAgencyWorker",
          :loading="isLoadingTemp",
          track-by="id",
          label="name",
          :searchable="true",
          :multiple="true",
          select-label="",
          selected-label="",
          deselect-label="",
          placeholder="Filter by temp",
          :close-on-select="false",
          :value="filter.temps",
          @select="onSelectTemp",
          @remove="onRemoveTemp",
          @search-change="asyncFindWorker"
        )
    p.control
      label.label Subject
      multi-select(
        :options="subjectTypes",
        track-by="id",
        label="name",
        :multiple="true",
        select-label="",
        selected-label="",
        deselect-label="",
        placeholder="Filter by subject",
        :value="filter.notetypes",
        @select="onSelectSubject",
        @remove="onRemoveSubject"
      )
    p.control.note
      label.label Note
      input.input(
        :value="filter.note",
        type="text",
        placeholder="Search by note",
        @input="onFilterNote"
      )
</template>

<script>
import _ from "underscore";
import MultiSelect from "vue-multiselect";
import calendarOptions from "../common/calendarOptions";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    MultiSelect,
  },
  props: {
    filter: {
      type: Object,
      requred: true,
    },
    subjectTypes: {
      type: Array,
      default: () => [],
    },
    clients: {
      type: Array,
      default: () => [],
    },
    // temps: {
    //   type: Array,
    //   default: () => [],
    // },
    clientId: {
      type: [Number, String],
    },
    tempId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      dateTimeOption: null,
      isLoadingTemp: false,
    };
  },
  computed: {
    ...mapGetters({
      agencyWorkers: "getAgencyWorkers",
    }),
    formattedAgencyWorker() {
      return this.agencyWorkers.map((aw) => {
        return {
          ...aw,
          name: `${aw.name} (${aw.tssId || "No external system id"})`,
        };
      });
    },
    isClientPage() {
      return Boolean(this.clientId);
    },
    isTempPage() {
      return Boolean(this.tempId);
    },
  },
  created() {
    this.setupDateTimePickerOpt();
  },
  beforeDestroy() {
    this.dateTimeOption.type = "min";
  },
  methods: {
    ...mapActions({
      findAgencyWorker: "findAgencyWorker",
    }),
    onSelectClient(evt) {
      if (Array.isArray(this.filter.clients)) {
        this.filter.clients.push(evt);
      } else {
        this.filter.clients = [evt];
      }
      this.debounceRefresh();
    },
    onRemoveClient(evt) {
      const index = this.filter.clients.findIndex(
        (client) => client.id === evt.id
      );
      this.filter.clients.splice(index, 1);
      this.debounceRefresh();
    },
    onSelectTemp(evt) {
      if (Array.isArray(this.filter.temps)) {
        this.filter.temps.push(evt);
      } else {
        this.filter.temps = [evt];
      }
      this.debounceRefresh();
    },
    onRemoveTemp(evt) {
      const index = this.filter.temps.findIndex((temp) => temp.id === evt.id);
      this.filter.temps.splice(index, 1);
      this.debounceRefresh();
    },
    onSelectSubject(evt) {
      if (Array.isArray(this.filter.notetypes)) {
        this.filter.notetypes.push(evt);
      } else {
        this.filter.notetypes = [evt];
      }
      this.debounceRefresh();
    },
    onRemoveSubject(evt) {
      const index = this.filter.notetypes.findIndex((nt) => nt.id === evt.id);
      this.filter.notetypes.splice(index, 1);
      this.debounceRefresh();
    },
    onClickResetFilters() {
      this.$emit("reset-filters", true);
      // this.filter.from.time = "";
      // this.filter.to.time = "";
      this.debounceRefresh();
    },
    onFilterNote(evt) {
      this.filter.note = evt.target.value;
      this.debounceRefresh();
    },
    asyncFindWorker: _.debounce(async function (query) {
      if (query === "") return;
      this.isLoadingTemp = true;
      const params = {
        ...{ query },
        originallySelectedWorker: "",
      };
      await this.findAgencyWorker(params);
      this.isLoadingTemp = false;
    }, 400),
    debounceRefresh: _.debounce(function () {
      this.$emit("refresh-fetch");
    }, 300),
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "DD/MM/YYYY";
    },
    dateInputChanged(evt, key) {
      this.filter[key].time = evt;
      this.debounceRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.journal-filters {
  display: flex;
  // flex-wrap: wrap;
  gap: 20px;

  .control {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    input {
      height: 100%;
    }
  }

  .left-side,
  .right-side {
    display: flex;
    gap: 20px;
    align-items: flex-end;
  }

  .date-filter {
    display: flex;
    gap: 10px;
    align-items: flex-end;

    .button {
      align-self: flex-end;
    }
  }

  .note {
    input {
      height: 37px;
    }
  }

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
}
</style>

<style lang="scss">
.journal-filters {
  .date-filter {
    .field {
      width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      input {
        width: 100%;
      }
    }
  }

  .multiselect {
    width: 200px;
  }
}
</style>
