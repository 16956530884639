<template lang="pug">
#rates-manage-tabs
  .text
  uni-tabs(:tabs="filteredTabs")
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ratesPageTabs",
  computed: {
    ...mapState({
      categories: (state) => state.rateCategories,
      rateSectorSelected: (state) => state.rateSectorSelected,
    }),
    tabs() {
      return [
        {
          id: 1,
          name: "categories",
          label: "Categories",
          routeName: "RatesManageCategories",
        },
        {
          id: 2,
          name: "increments",
          label: "Increments",
          routeName: "RatesManageIncrements",
          isInactive: !this.categories?.length || !this.rateSectorSelected,
        },
        {
          id: 3,
          name: "rates",
          label: "Rates",
          routeName: "RatesManageRatesPage",
          isInactive: !this.categories?.length || !this.rateSectorSelected,
        },
        {
          id: 4,
          name: "expenses",
          label: "Expenses",
          routeName: "RatesManageExpenses",
          permission: "manage-expense-rates",
          systemPermissions: ["can_use_expenses"],
          // isInactive: !this.categories?.length || !this.rateSectorSelected,
        },
        {
          id: 5,
          name: "RatesManagePublicHolidays",
          label: "Public Holidays",
          routeName: "RatesManagePublicHolidays",
          // permission: "manage-public-holidays",
          systemPermissions: ["can_manage_public_holidays"],
          // isInactive: !this.categories?.length || !this.rateSectorSelected,
        },
      ];
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  .tab {
    &.inactive {
      opacity: 0.4;
    }
  }

  a {
    text-transform: uppercase;
    font-weight: 900;
    border-bottom: 3px solid;

    &.is-active {
      border-bottom-color: var(--color-primary-10);
      color: var(--color-primary-10);
    }
  }
}
</style>
