import Vue from "vue";
import VueRouter from "vue-router";

// import MagickLink from  '../components/MagicLink/MagickLink.vue'

import adminRoutes from "@/lib/routes/adminRoutes.js";
import tempRoutes from "@/lib/routes/tempRoutes.js";
import otherRoutes from "@/lib/routes/otherRoutes.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "is-active",
  routes: [...adminRoutes, ...tempRoutes, ...otherRoutes],
});

export default router;
